import React, { useEffect, useState } from 'react';
import './App.css';
import { Navigate, Outlet, Route, RouteProps, Routes, useLocation, useNavigate } from 'react-router-dom';
import RegisterPage from './components/pages/public/register-page';
import Layout from './components/layout';
import HomePage from './components/pages/protected/home-page';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { getAuthenticatedUser, setAuthenticatedUser } from './redux/slices/auth.slice';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css'
import LoginPage from './components/pages/public/login-page';
import ProfilePage from './components/pages/protected/profile-page';
import LandingPage from './components/pages/public/landing-page';
import { useGetUserMeQuery } from './services/tenant/user/user.service';
import Loader from './components/atoms/loader/loader';
import { selectGeneral } from './redux/slices/general.slice';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import NotFoundPage from './components/pages/public/not-found-page';
import { getCurrentUser, setCurrentUser } from './redux/slices/user.slice';
import { getTenant, setTenant } from './redux/slices/tenant.slice';
import ErrorPage from './components/pages/public/error-page';
import EmailVerificationPage from './components/pages/public/auth/email-verification-page';
import { NotificationManager } from 'react-notifications';
import UsersPage from './components/pages/protected/users-page';
import RegisterInvitedPage from './components/pages/public/auth/register-invited-page';
import PropertiesPage from './components/pages/protected/properties-page';
import LogsPage from './components/pages/protected/logs-page';
import CompanyPage from './components/pages/protected/company-page';
import LanguageSelector from './components/atoms/language-selector';
import { useLogoutQuery } from './services/management/management.service';
import { useTranslation } from 'react-i18next';
import { Roles } from './services/enum/roles';
import RightsPage from './components/pages/protected/rights-page';
import ConnectionPage from './components/pages/protected/connections-page';
import { getNotificationState } from './redux/slices/notification.slice';
import { useNotifications } from './redux/hooks/useNotifications';
import { LoadScriptNext } from '@react-google-maps/api';

const googleLibraries: any = ['geometry'];

function App() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const general = useAppSelector(selectGeneral);
  const { authUser, access_token } = useAppSelector(getAuthenticatedUser);
  const { currentUser } = useAppSelector(getCurrentUser);
  const { tenant } = useAppSelector(getTenant);
  const location = useLocation();
  const [trackLocation, setTrackLocation] = useState<string>('');

  const notifications = useNotifications();

  const [userMeSkipToken, setUserMeSkipToken] = useState<any>(skipToken);
  const { data: userMe, error: userMeError, isLoading: userMeIsLoading } = useGetUserMeQuery(userMeSkipToken);

  // STATE
  const [finishedInitialize, setFinishedInitialize] = useState<boolean>(true);

  // User is authenticated
  const PrivateRoute = ({ ...rest }: RouteProps): React.ReactElement | null => {
    return authUser?.name ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />
  }

  // Role is either Admin or SuperAdmin
  const AdminRoute = ({ ...rest }: RouteProps): React.ReactElement | null => {
    return (currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) ? <Outlet /> : <Navigate to="/home" state={{ from: location }} replace />
  }

  // Email verified
  const VerifiedRoute = ({ ...rest }: RouteProps): React.ReactElement | null => {
    if (currentUser.email_verified) {
      return <Outlet />
    }
    else {
      return <Navigate to="/home" state={{ from: location }} replace />
    }
  }

  const AuthorizedRoute = ({ ...rest }: RouteProps): React.ReactElement | null => {
    /*
      IF tenant email is verified, check user role
        IF user role is -1, a new user has been created within an existing company and the user need to verify email to confirm their validity
        IF user role is not -1, the user has been created with the tenant or an administrator has assigned a different role meaning they are validated

      ELSE IF tenant email is not verified, show email verification box and limit access to page
    */

    if (tenant.email_verified) {
      // If user role is either not -1 or the user email is verified, show nav items
      if (currentUser.role !== Roles.None || currentUser.email_verified) {
        return <Outlet />
      }
      // If user role is -1 and user email is not verified, hide nav items
      else {
        return <Navigate to="/home" state={{ from: location }} replace />
      }
    }
    // If tenant email is not verified, hide nav items
    else {
      return <Navigate to="/home" state={{ from: location }} replace />
    }
  }

  // If path starts with /auth, don't try to refetch user data
  const authPath = location.pathname.startsWith('/auth');

  useEffect(() => {
    if (authPath) {
      setUserMeSkipToken(skipToken);
      setFinishedInitialize(true);
    }

    // Auth user exist, user data do not exist. User is authenticated, fetch data
    else if (authUser.name && !currentUser.name) {
      const userInLocalStorage = localStorage.getItem('user');

      // If name is in localStorage, we try to reauth
      if (userInLocalStorage) {
        setUserMeSkipToken(true);
      }
      else {
        setUserMeSkipToken(skipToken);
      }
    }

    // Auth user do not exist, path do not start with '/auth'
    else if (!authUser?.name && !authPath) {
      const userInLocalStorage = localStorage.getItem('user');

      // If name is in localStorage, we try to reauth
      if (userInLocalStorage) {
        setFinishedInitialize(false);
        setUserMeSkipToken(true);
      }
      // Name not in localStorage, don't try to reauth
      else {
        setUserMeSkipToken(skipToken);
        setFinishedInitialize(true);

        /* 
          Get path from localStorage
          If path exists the user was previously logged in, show notification
          If path does not exist, don't show notification
        */
        const existingPath = localStorage.getItem('path');
        if (existingPath && location.pathname === '/login') {
          NotificationManager.info(t('common:notifications.sessionExpired'));
          localStorage.setItem('path', ''); // Reset path
        }
      }
    }

    // Set state if data is returned
    if (authUser.name) {
      // If data from getUserMe
      if (userMe) {

        setFinishedInitialize(true);
        if (userMe.user && userMe.tenant) {
          dispatch(setCurrentUser(userMe));
          dispatch(setTenant(userMe));
          navigate(getHistoryRoute());
        }
      }
      // If error from getUserMe
      else if (userMeError) {
        if ('status' in userMeError) {
          const err = (userMeError.data as any);

          if (err && err.message === 'User not found') {
            // This means there's nothing wrong with the request, we should log the user out because of this
            navigate('/home');
          }
          else {
            /* 
              If any other error, remove user from localStorage so we don't fetch again
              If getUserMe fails, this will be triggered. Should be handled better
            */
            localStorage.setItem('user', '');
            localStorage.setItem('path', '');
            NotificationManager.warning(t('common:notifications.somethingWentWrong'));
            navigate('/login');
          }
        }
      }
    }
    // First time auth fails, this will execute
    else if (userMeError) {
      setFinishedInitialize(true);
      localStorage.setItem('user', '');
      // localStorage.setItem('path', '');
    }
  }, [authUser, userMe, userMeError, trackLocation])

  // Set current route
  useEffect(() => {
    if (
      location.pathname !== '/login' &&
      location.pathname !== '/register' &&
      location.pathname !== '/auth/email'
    ) {
      localStorage.setItem('path', location.pathname);
    }
    if (location.pathname !== trackLocation) {
      setTrackLocation(location.pathname);
    }
  }, [location])

  // Return previously accessed route
  const getHistoryRoute = () => {
    const route = localStorage.getItem('path');
    if (route) {
      if (route !== '' && route !== '/home' && route !== '/register' && route !== '/login' && route !== '/catalog/auth/verify/email') {
        return route;
      }
    }
    return '/home';
  }

  return (
    <>
      {finishedInitialize ? (
        <>
          <Provider store={store}>
            <NotificationContainer />
            <LoadScriptNext
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY || ''}
              libraries={googleLibraries}
            >
              <Layout>
                <Routes>
                  { /* PUBLIC ROUTES */}

                  {/* <Route index element={<LandingPage />} /> */}
                  <Route index element={access_token !== '' ? <Navigate to="/home" /> : <LoginPage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/register" element={<RegisterPage />} />
                  <Route path="/login" element={access_token !== '' ? <Navigate to="/home" /> : <LoginPage />} />

                  <Route path="/auth/email" element={<EmailVerificationPage />} /> {/* Auth email verification token */}
                  <Route path="/auth/register-invited" element={<RegisterInvitedPage />} /> {/* Register invited user */}
                  <Route path="/error/:parameter" element={<ErrorPage />} /> {/* Error page */}

                  { /* PROTECTED ROUTES */}

                  { /* User needs to be authenticated */}
                  <Route element={<PrivateRoute />}>
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/profile" element={<ProfilePage />} />

                    {/* User email needs to be verified  */}
                    <Route element={<VerifiedRoute />}>
                      <Route path="/logs" element={<LogsPage />} />
                      {/* User needs to be Admin or Superadmin */}
                      <Route element={<AdminRoute />}>
                        {/* Routes in here is only available for admins */}
                        <Route path="/properties/:path?" element={<PropertiesPage />} />
                        <Route path="/users" element={<UsersPage />} />
                        <Route path="/company" element={<CompanyPage />} />
                        <Route path="/rights" element={<RightsPage />} />
                        <Route path="/connections" element={<ConnectionPage />} />
                      </Route>

                      <Route element={<AuthorizedRoute />}>
                        {/* Routes in here is only available if tenant and user has confirmed email as expected */}

                      </Route>
                    </Route>
                  </Route>

                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Layout>
            </LoadScriptNext>
          </Provider>
        </>

      ) : (
        <Loader show={true} align='center' top='xlarge' size='large' />
      )}
    </>
  )
}

export default App;
