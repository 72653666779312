import React, { useEffect, useState } from 'react'
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { PropertyDto } from '../../../../services/tenant/property/dto/property.dto';
import { propertyAPI, useUpdatePropertyMutation } from '../../../../services/tenant/property/property.service';
import Loader from '../../../atoms/loader/loader';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import PropertyFileUpload from '../../property/property-file-upload';
import { PropertyTypes } from '../../../../services/enum/property-types';
import SubsidiarySelect from '../../subsidiary/subsidiary-select';
import { useDispatch } from 'react-redux';
import { SelectCustomStyles } from '../../../atoms/select-custom-styles/select-custom-styles';
import PrimaryButton from '../../../atoms/primary-button';
import { getLatLng } from '../../../../utils/getLatLng';

export type UpdatePropertyFormType = {
    id: string;
    name?: string | '';
    property_type?: PropertyTypes;
    label?: string;
    object_number?: string | '';
    subsidiary_id?: string | null;
    address?: string | '';
    city?: string | '';
    zip?: string | '';
    country?: string | '';
    image?: string | '';
    longitude?: number | null;
    latitude?: number | null;
}

type Props = {
    property?: PropertyDto;
    // showPropertyModal: (x: boolean) => void;
}

const UpdatePropertyForm = ({ property }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [updateProperty, response] = useUpdatePropertyMutation();
    const [options, setOptions] = useState<{ label: string, value: string, subsidiary_id: string | null }[]>();
    const [error, setError] = useState<string>();

    const { control, handleSubmit, setValue, formState: { errors } } = useForm<UpdatePropertyFormType>({
        defaultValues: {
            id: property?.id || '',
            name: property?.name || '',
            property_type: property?.property_type || PropertyTypes.Apartment,
            label: property?.label || '',
            object_number: property?.object_number || '',
            subsidiary_id: property?.subsidiary_id || null,
            address: property?.address || '',
            city: property?.city || '',
            zip: property?.zip || '',
            country: property?.country || '',
            image: property?.image || '',
            longitude: property?.longitude || null,
            latitude: property?.latitude || null,
        }
    });

    useEffect(() => {
        if (property?.property_type) {
            setValue('property_type', property.property_type);
        }
    }, [property?.property_type]);

    const handleSelectedSubsidiary = (selectOption: { label: string, value: string }) => {
        if (selectOption.value && selectOption.value.length > 1) {
            setValue('subsidiary_id', selectOption.value);
        }
        else {
            setValue('subsidiary_id', null);
        }
    }

    const onSubmit = async (formData: UpdatePropertyFormType) => {

        // Fetch lat and long if address or city was updated
        if ((formData.address) && (formData.address !== property?.address || formData.city !== property?.city)) {
            const latLng = await getLatLng(formData.address, formData.city);
            
            if (latLng) {
                formData.latitude = Number(latLng.lat.toFixed(6));
                formData.longitude = Number(latLng.lng.toFixed(6));
            }
        }

        try {
            const response = await updateProperty(formData).unwrap();

            if (response.success) {
                setError('');
                NotificationManager.success(t('page:properties.form.propertyUpdated'));
                dispatch(propertyAPI.util.invalidateTags(['Properties']));
            }
            else {
                setError(t(`common:server.${response.message}`));
                NotificationManager.error(t('page:properties.form.propertyNotUpdated'));
            }
        }
        catch (error) {
            setError('');
            NotificationManager.error(t('page:properties.form.propertyNotUpdated'));
        }
    }

    return (
        <>
            <PropertyFileUpload property={property} />
            <div className="sm:mx-auto sm:w-full">
                <form className="space-y-2" onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                        <label htmlFor='label' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:label')}<span className="text-status-critical ">*</span>
                        </label >
                        <Controller
                            name="label"
                            control={control}
                            rules={{
                                required: t('page:properties.form.labelNotEmpty'),
                                minLength: {
                                    value: 3,
                                    message: t('page:properties.form.labelMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:label')}
                                        className={`${errors.label ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                </>
                            )}
                        />
                        {errors.label && <p className="text-status-critical text-xs my-1 ml-1">{errors.label.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='property_type' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('page:properties.propertyType')}<span className="text-status-critical ">*</span>
                        </label>
                        <Controller
                            name="property_type"
                            control={control}
                            rules={{
                                // required: 'You need to select a property type',
                                // validate: (value) => typeof value === 'string' || 'You need to select a property type.',
                            }}
                            render={({ field }) => (
                                <>
                                    <Select
                                        name="property_type"
                                        className={`${errors.property_type ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical rounded-md' : ''} z-50`}
                                        value={(Object.entries(PropertyTypes) as [string, string][])
                                            .filter(([key, value]) => typeof value === 'string')
                                            .map(([key, value]) => ({ label: t(`common:enums.propertyTypes.${key}`), value: value.toString() }))
                                            .find(option => option.value === field.value?.toString())}
                                        onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                                        styles={SelectCustomStyles}
                                        options={(Object.values(PropertyTypes) as Array<keyof typeof PropertyTypes>)
                                            .filter((val) => typeof val === 'string' && val !== PropertyTypes.None)
                                            .map((val) => ({
                                                label: t(`common:enums.propertyTypes.${PropertyTypes[val]}`),
                                                value: val,
                                            }))
                                        }
                                        placeholder={t('common:select')}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        controlShouldRenderValue={true}
                                        isClearable={false}
                                        backspaceRemovesValue={false}
                                        noOptionsMessage={() => t('page:properties.noPropertyTypesFound')}
                                    />
                                </>
                            )}
                        />
                        {errors.property_type && <p className="text-status-critical text-xs my-1 ml-1">{errors.property_type.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='subsidiary_id' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('page:properties:propertyBelongsToSubsidiary')}
                            <span
                                className="material-symbols-outlined text-gray-600 ml-1 align-sub"
                                style={{ fontSize: '1.2rem' }}
                                title={t('page:properties.propertySubsidiaryInfo')}
                            >help</span>
                        </label>
                        <SubsidiarySelect
                            handleSelectedSubsidiary={handleSelectedSubsidiary}
                            id={property?.subsidiary_id ? property.subsidiary_id : undefined}
                        />
                        {errors.subsidiary_id && <p className="text-status-critical text-xs my-1 ml-1">{errors.subsidiary_id.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='name' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:name')}
                        </label >
                        <Controller
                            name="name"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 3,
                                    message: t('common:form.nameMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:name')}
                                        className={`${errors.name ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                </>
                            )}
                        />
                        {errors.name && <p className="text-status-critical text-xs my-1 ml-1">{errors.name.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='object_number' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:objectNumber')}
                        </label >
                        <Controller
                            name="object_number"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 3,
                                    message: t('page:properties.form.objectNumberMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:objectNumber')}
                                        className={`${errors.object_number ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                </>
                            )}
                        />
                        {errors.object_number && <p className="text-status-critical text-xs my-1 ml-1">{errors.object_number.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='address' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:address')}
                        </label >
                        <Controller
                            name="address"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 3,
                                    message: t('common:form.addressMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:address')}
                                        className={`${errors.address ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                </>
                            )}
                        />
                        {errors.address && <p className="text-status-critical text-xs my-1 ml-1">{errors.address.message}</p>}
                    </div >
                    <div className="">
                        <label htmlFor='city' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:city')}
                        </label >
                        <Controller
                            name="city"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 3,
                                    message: t('common:form.cityMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:city')}
                                        className={`${errors.city ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                </>
                            )}
                        />
                        {errors.city && <p className="text-status-critical text-xs my-1 ml-1">{errors.city.message}</p>}
                    </div >
                    <div className="">
                        <label htmlFor='zip' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:zip')}
                        </label >
                        <Controller
                            name="zip"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 5,
                                    message: t('common:form.zipMinLength5')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:zip')}
                                        className={`${errors.zip ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                </>
                            )}
                        />
                        {errors.zip && <p className="text-status-critical text-xs my-1 ml-1">{errors.zip.message}</p>}
                    </div >
                    <div className="">
                        <label htmlFor='country' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:country')}
                        </label >
                        <Controller
                            name="country"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 2,
                                    message: t('common:form.countryMinLength2')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:country')}
                                        className={`${errors.country ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                </>
                            )}
                        />
                        {errors.country && <p className="text-status-critical text-xs my-1 ml-1">{errors.country.message}</p>}
                    </div>
                    <div className="sr-only">
                        <label htmlFor='longitude' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:longitude')}
                        </label >
                        <Controller
                            name="longitude"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        value={field.value ?? ''}
                                        type='text'
                                        placeholder={t('common:longitude')}
                                        className={`${errors.country ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                </>
                            )}
                        />
                        {errors.longitude && <p className="text-status-critical text-xs my-1 ml-1">{errors.longitude.message}</p>}
                    </div>
                    <div className="sr-only">
                        <label htmlFor='latitude' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:latitude')}
                        </label >
                        <Controller
                            name="latitude"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        value={field.value ?? ''}
                                        type='text'
                                        placeholder={t('common:latitude')}
                                        className={`${errors.latitude ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                </>
                            )}
                        />
                        {errors.latitude && <p className="text-status-critical text-xs my-1 ml-1">{errors.latitude.message}</p>}
                    </div>

                    {error && (<p className="font-bold text-sm mt-2 text-center text-status-critical">{error}</p>)}
                    <div>
                        <PrimaryButton
                            text={t('common:update')}
                            size={'medium'}
                            fullWidth={true}
                        />
                        {response.isLoading && (<Loader show={response.isLoading} size='small' />)}
                    </div>
                </form>
            </div >
        </>
    )
}

export default UpdatePropertyForm;