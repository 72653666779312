import React, { useEffect, useState } from 'react'
import TableContainer from '../../../organisms/rights/table-container';
import RightsSelect from '../../../organisms/rights/rights-select';
import { useTranslation } from 'react-i18next';
import { SubsidiaryDto } from '../../../../services/tenant/subsidiary/dto/subsidiary.dto';
import { PropertyDto } from '../../../../services/tenant/property/dto/property.dto';
import { UserDto } from '../../../../services/tenant/user/dto/user.dto';
import { LogTemplateDto } from '../../../../services/tenant/log-template/dto/log-template.dto';
import { Grow } from '@mui/material';

type Props = {

};

const RightsPage = ({ }: Props) => {
    const { t } = useTranslation();

    const [selectedSubsidiaries, setSelectedSubsidiaries] = useState<SubsidiaryDto[]>();
    const [selectedProperties, setSelectedProperties] = useState<PropertyDto[]>();
    const [selectedUsers, setSelectedUsers] = useState<UserDto[]>();
    const [selectedLogTemplates, setSelectedLogTemplates] = useState<LogTemplateDto[]>();

    return (
        <Grow in={true} timeout={400} style={{ transformOrigin: '0 0 0' }}>
            <div className="w-full flex mx-auto p-2">
                <div className="w-full">
                    <div className="w-full xl:w-8/12 xl:max-w-6xl mx-auto flex-col justify-center">
                        <h3 className="text-2xl font-bold text-center">{t('page:rights.handleRights')}</h3>
                        {
                            (!selectedSubsidiaries || (selectedSubsidiaries && selectedSubsidiaries.length === 0)) &&
                            (!selectedProperties || (selectedProperties && selectedProperties.length < 1)) &&
                            (!selectedUsers || (selectedUsers && selectedUsers.length < 1)) &&
                            (!selectedLogTemplates || (selectedLogTemplates && selectedLogTemplates.length < 1)) &&
                            (
                                <div className="lg:w-10/12 font-medium mx-auto mt-2 text-sm shadow-sm p-2 rounded-md bg-primary-blue-5">
                                    <p className="">{t('page:rights.seeAndHandleOrganisation')}</p>
                                    <p className="mt-2">{t('page:rights.handleOrganisationExample')}</p>
                                </div>
                            )
                        }

                        <RightsSelect
                            setSelectedSubsidiaries={setSelectedSubsidiaries}
                            selectedSubsidiaries={selectedSubsidiaries}
                            setSelectedProperties={setSelectedProperties}
                            selectedProperties={selectedProperties}
                            setSelectedUsers={setSelectedUsers}
                            selectedUsers={selectedUsers}
                            setSelectedLogTemplates={setSelectedLogTemplates}
                            selectedLogTemplates={selectedLogTemplates}
                        />
                    </div>

                    <TableContainer
                        selectedSubsidiaries={selectedSubsidiaries}
                        selectedProperties={selectedProperties}
                        selectedUsers={selectedUsers}
                        selectedLogTemplates={selectedLogTemplates}
                    />
                </div>
            </div>
        </Grow>
    )
}

export default RightsPage