import { reAuthAPI } from "../../re-auth/re-auth";
import { PropertyUserAccessDto } from "./dto/property-user-access.dto";
import { UpdatePropertyUserAccessDto } from "./dto/update-property-user-access.dto";



export const propertyUserAccessAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Get all property user access relations
        getAllPropertyUserAccess: builder.query<PropertyUserAccessDto[], void>({
            query: () => ({
                url: '/property-user-access/users',
                method: 'GET',
            }),
            providesTags: ['PropertyUserAccess']
        }),
        // Get all property user access relations by ids (propertyIds or userIds)
        getAllPropertyUserAccessByIds: builder.query<PropertyUserAccessDto[], string[]>({
            query: (ids) => ({
                url: '/property-user-access/users/ids?ids=' + ids,
                method: 'GET',
            }),
            providesTags: ['PropertyUserAccess']
        }),
        // Get all property user access relations by id (propertyId or userId)
        getAllPropertyUserAccessById: builder.query<PropertyUserAccessDto[], string>({
            query: (id) => ({
                url: '/property-user-access/users/' + id,
                method: 'GET',
            }),
            providesTags: ['PropertyUserAccess']
        }),
        // Update many property user access relations
        updateManyPropertyUserAccess: builder.mutation<any, UpdatePropertyUserAccessDto[]>({
            query: (propertyUserAccess) => ({
                url: '/property-user-access/users',
                method: 'PUT',
                body: propertyUserAccess
            }),
            invalidatesTags: ['PropertyUserAccess', 'PropertyBuildingUserAccess', 'Properties', 'PropertyBuilding', 'PropertyObjectUserAccess', 'PropertyObject', 'Users']
        }),
    })
})

export const {
    useGetAllPropertyUserAccessQuery,
    useGetAllPropertyUserAccessByIdsQuery,
    useGetAllPropertyUserAccessByIdQuery,
    useUpdateManyPropertyUserAccessMutation,

} = propertyUserAccessAPI;