import React, { useEffect, useRef, useState } from 'react'
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { Switch, debounce } from '@mui/material';
import { useAppSelector } from '../../../../redux/hooks';
import { getCurrentUser } from '../../../../redux/slices/user.slice';
import Select from 'react-select';
import { useCreateUserAsAdminMutation, useCreateUserMutation, userAPI } from '../../../../services/tenant/user/user.service';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { stringify } from 'querystring';
import { Roles } from '../../../../services/enum/roles';
import { Order } from '../../../../services/enum/order';
import SubsidiarySelect from '../../subsidiary/subsidiary-select';
import { useDispatch } from 'react-redux';
import { SelectCustomStyles } from '../../../atoms/select-custom-styles/select-custom-styles';
import { UserDto } from '../../../../services/tenant/user/dto/user.dto';
import PrimaryButton from '../../../atoms/primary-button';

export type UserFormType = {
    tenant_id: string;
    active: boolean;
    name: string;
    email: string;
    password: string;
    phone: string;
    role: Roles;
    first_user?: boolean;
    address?: string;
    city?: string;
    zip?: string;
    country?: string;
    profile_picture?: string;
    subsidiary_id?: string | null;
    copy_user_id?: string;
}

type Props = {
    user?: UserDto
}

const CreateUserFormAdmin = ({
    user
}: Props) => {
    const { t } = useTranslation();
    const { currentUser } = useAppSelector(getCurrentUser);
    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [externalDomain, setExternalDomain] = useState<boolean>(false);
    const [error, setError] = useState<string>('')

    // // Options array for subsidiaries
    // const [options, setOptions] = useState<{ label: string, value: string, subsidiary_id: string | null }[]>();

    const [createUser, response] = useCreateUserAsAdminMutation();

    const { control, handleSubmit, setValue, getValues, formState: { errors } } = useForm<UserFormType>({
        defaultValues: {
            tenant_id: user ? user.tenant_id : '',
            active: user ? user.active : true,
            name: user ? user.name : '',
            email: user ? user.email.split('@')[0] : '',
            password: '',
            phone: user ? user.phone : '',
            role: user ? user.role : undefined,
            profile_picture: '',
            subsidiary_id: user ? user.subsidiary_id : null,
            copy_user_id: user ? user.id : ''
        }
    });

    const handleSelectedSubsidiary = (selectOption: { label: string, value: string }) => {
        if (selectOption.value && selectOption.value.length > 1) {
            setValue('subsidiary_id', selectOption.value);
        }
        else {
            setValue('subsidiary_id', null);
        }
    }

    // Create user
    const onSubmitUserForm = async (userFormData: UserFormType) => {
        // If internal user created, append tenant email domain to email
        if (!externalDomain) {
            userFormData.email = userFormData.email + '@' + currentUser.email.split('@')[1];
        }

        try {
            const response = await createUser(userFormData).unwrap();
            if (response.success) {
                NotificationManager.success(t('page:users.form.userCreated'));
                setError('');
                dispatch(userAPI.util.invalidateTags(['Users']));
            }
            else {
                NotificationManager.error(t('page:users.form.userNotCreated'));
                setError(t(`common:server.${response.message}`));
            }
        }
        catch (error: any) {
            NotificationManager.error(t('page:users.form.userNotCreated'));
            setError('');
        }
    }

    // If internal domain, append tenant email to end of email input
    const handleEmailInput = (value: string) => {
        if (externalDomain) {
            setValue('email', value);
        }
        else {
            const emailInput = value.split('@')[0];
            setValue('email', emailInput);
        }
    }

    return (
        <>
            <div className="mx-auto w-full">
                <Switch
                    color='primary'
                    onChange={() => setExternalDomain(!externalDomain)}
                    value={externalDomain}
                />
                <p className="inline align-middle text-sm font-semibold text-gray-600">{externalDomain ? t('page:users.internalUser') : t('page:users.externalUser')}</p>


                <form className="space-y-2" onSubmit={handleSubmit(onSubmitUserForm)}>
                    <div>
                        <label htmlFor='name' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:name')}<span className="text-status-critical">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: t('page:users.form.nameNotEmpty'),
                                    minLength: {
                                        value: 3,
                                        message: t('page:users.form.nameMinLength3')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={64}
                                            placeholder={t('common:name')}
                                            className={`${errors.name && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {errors.name && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.name.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='email' className={`block text-xs sm:text-sm font-semibold leading-6 text-gray-600`}>
                            {t('common:email')}<span className="text-status-critical">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: t('common:form.emailNotEmpty'),
                                    minLength: {
                                        value: 3,
                                        message: t('common:form.emailMinLength3')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            onChange={(e) => handleEmailInput(e.target.value)}
                                            value={externalDomain ? field.value : field.value + '@' + currentUser.email.split('@')[1]}
                                            type='text'
                                            maxLength={32}
                                            placeholder={t('common:email')}
                                            className={`${errors.email && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {errors.email && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.email.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='password' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:password')}<span className="text-status-critical">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="password"
                                control={control}
                                rules={{
                                    required: t('page:users.form.passwordNotEmpty'),
                                    minLength: {
                                        value: 8,
                                        message: t('page:users.form.passwordMinLength8')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type={showPassword ? 'text' : 'password'}
                                            maxLength={32}
                                            placeholder={t('common:password')}
                                            className={`${errors.password && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        <span
                                            onClick={() => setShowPassword(!showPassword)}
                                            className="material-symbols-outlined text-black cursor-pointer hover:text-gray-600 float-right mr-2 mt-[-33px]">
                                            {showPassword ? 'visibility' : 'visibility_off'}
                                        </span>
                                        {errors.password && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.password.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='phone' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:phone')}<span className="text-status-critical">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="phone"
                                control={control}
                                rules={{
                                    required: t('page:users.form.phoneNotEmpty'),
                                    minLength: {
                                        value: 10,
                                        message: t('page:users.form.phoneMinLength10')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={16}
                                            placeholder={t('common:phone')}
                                            className={`${errors.phone && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {errors.phone && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.phone.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='role' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:role')}<span className="text-status-critical ">*</span>
                        </label>
                        <Controller
                            name="role"
                            control={control}
                            rules={{
                                required: t('page:users.form.roleNotEmpty'),
                            }}
                            render={({ field }) => (
                                <>
                                    <Select
                                        name="role"
                                        className={`${errors.role ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical rounded-md' : ''} text-sm z-50 `}
                                        onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                                        styles={SelectCustomStyles}
                                        value={(Object.entries(Roles) as [string, string][])
                                            .filter(([key, value]) => typeof value === 'string')
                                            .map(([key, value]) => ({ label: t(`common:enums.roles.${key}`), value: value.toString() }))
                                            .find(option => option.value === field.value?.toString())}
                                        options={
                                            (Object.values(Roles) as Array<keyof typeof Roles>)
                                                .filter((val) => typeof val === 'string' && val !== Roles.None)
                                                .map((val) => ({
                                                    label: t(`common:enums.roles.${Roles[val]}`),
                                                    value: val,
                                                }))
                                        }
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        controlShouldRenderValue={true}
                                        isClearable={false}
                                        backspaceRemovesValue={false}
                                        placeholder={t('common:select')}
                                        noOptionsMessage={() => t('page:users.noRolesFound')}
                                    />
                                </>
                            )}
                        />
                        {errors.role && <p className="text-status-critical text-xs my-1 ml-1">{errors.role.message}</p>}
                    </div>
                    <div>
                        <label htmlFor='subsidiary_id' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('page:company.userBelongsToSubsidiary')}
                            <span
                                className="material-symbols-outlined text-gray-600 ml-1 align-sub"
                                style={{ fontSize: '1.2rem' }}
                                title={t('page:company.userSubsidiaryInfo')}
                            >help</span>
                        </label>
                        <SubsidiarySelect
                            handleSelectedSubsidiary={handleSelectedSubsidiary}
                            id={user?.subsidiary_id ? user.subsidiary_id : undefined}
                        />
                        {errors.subsidiary_id && <p className="text-status-critical text-xs my-1 ml-1">{errors.subsidiary_id.message}</p>}
                    </div>

                    {error && (<p className="font-bold text-sm text-center text-status-critical">{error}</p>)}
                    <div>
                        <PrimaryButton
                            text={t('common:create')}
                            size={'medium'}
                            fullWidth={true}
                        />
                    </div>
                </form>
            </div>

        </>
    )
}

export default CreateUserFormAdmin;