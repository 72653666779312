
const getImageDimensions = async (file: File) : Promise<{ width: number, height: number }> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve({ width: img.width, height: img.height });
        };
        img.onerror = reject;

        img.src = URL.createObjectURL(file);
    })
};

export default getImageDimensions;