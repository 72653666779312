import DefaultObject from '../../../../assets/default/object.png';
import { NotificationManager } from 'react-notifications';
import { useEffect, useState } from "react";
import { useDeleteFileMutation, useUploadFileMutation } from "../../../../services/tenant/media/media.service";
import { compressImage } from "../../../../utils/compress-image";
import { ImageCompressSettings } from "../../../../constants/image-compress-settings";
import { useTranslation } from "react-i18next";
import { PropertyObjectDto } from '../../../../services/tenant/property-object/dto/property-object.dto';
import { propertyObjectAPI, useUpdatePropertyObjectMutation } from '../../../../services/tenant/property-object/property-object.service';
import { UpdatePropertyObjectDto } from '../../../../services/tenant/property-object/dto/update-property-object.dto';
import { useDispatch } from 'react-redux';

type Props = {
  object?: PropertyObjectDto
}

const PropertyObjectFileUpload = ({ object }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [uploadFile, response] = useUploadFileMutation();
  const [updateObject, { isLoading, error }] = useUpdatePropertyObjectMutation();
  const [deleteFile] = useDeleteFileMutation();

  const [selectedFile, setSelectedFile] = useState<FileList | null>();
  const [fileError, setFileError] = useState<string>('');

  const [showDeleteBtn, setShowDeleteBtn] = useState<boolean>(false);
  const [showUploadBtn, setShowUploadBtn] = useState<boolean>(false);
  // const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);  

  // Upload object img
  const submitFile = async (files: FileList) => {

    if (object && object.id) {
      if (files && files.length > 0) {
        setSelectedFile(undefined);
        const formData = new FormData();
        const file = files[0];
        if ((file.size / 1024 / 1024) > 10) {
          setFileError(t('common:form.selectedFileMax10MB'));
        }
        else {
          setFileError('');

          const result = await compressImage(file, 'avatar', 0.8, 'cover');
          if (result.success) {
            formData.append('uploadType', 'object_image');
            formData.append('file', result.data);
            formData.append('existingFileName', object.image ? object.image : '');
            formData.append('originalName', file.name);

            // Upload file
            try {
              const response = await uploadFile(formData).unwrap();
              if (response.success) {
                // Update object data with new file name
                const updatePropertyObjectDto: UpdatePropertyObjectDto = {
                  id: object.id,
                  image: response.data, // File path to be saved
                };

                const propertyObjectUpdateResponse = await updateObject(updatePropertyObjectDto).unwrap();
                if (propertyObjectUpdateResponse && propertyObjectUpdateResponse.success) {
                  NotificationManager.success(t('common:server.file_uploaded'));
                  dispatch(propertyObjectAPI.util.invalidateTags(['PropertyObject']));
                }
                else {
                  NotificationManager.error(t('common:server.file_not_uploaded'));
                }
              }
            }
            catch (error) {
              NotificationManager.error(t('common:server.file_not_uploaded'));
            }
          }
          else {
            setFileError(t(`common:${result.data}`));
          }
        }
      }
    }
  }


  const handleDeleteImg = async (filePath?: string) => {
    if (!filePath || !object)
      return

    try {
      const response = await deleteFile({ filePath }).unwrap();
      if (response.success) {
        // Remove filepath from user
        const updatePropertyObjectDto: UpdatePropertyObjectDto = {
          id: object.id,
          image: ''
        };

        const propertyUpdateResponse = await updateObject(updatePropertyObjectDto).unwrap();
        if (propertyUpdateResponse && propertyUpdateResponse.success) {
          NotificationManager.success(t(`common:server.file_deleted`));
          dispatch(propertyObjectAPI.util.invalidateTags(['PropertyObject']));
        }
        else {
          NotificationManager.error(t(`common:server.file_not_deleted`))
        }
      }
    }
    catch (error) {
      NotificationManager.error(t(`common:server.file_not_deleted`))
    }
  };

  return (
    <div className="w-full text-center">
      <div
        className="w-20 md:w-28 h-20 md:h-28 rounded-full flex items-center mx-auto border relative"
        onMouseOver={() => setShowDeleteBtn(true)}
        onMouseLeave={() => setShowDeleteBtn(false)}>
        {object && object.image ? (
          <>
            <label htmlFor='image' className="cursor-pointer">
              <input
                type="file"
                multiple={false}
                accept={'.png, .jpeg, .jpg, .svg'}
                id="image"
                className="hidden"
                onChange={(e) => setSelectedFile(e.target.files)}
              />
              <img
                src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${object.tenant_id}/${object.image}`}
                // onError={e => e.currentTarget.src = DefaultObject}
                alt="Object image"
                className="w-20 md:w-28 h-20 md:h-28 rounded-full object-cover" />
            </label>
          </>
        ) : (
          <div className="flex items-center justify-center w-20 md:w-28 h-20 md:h-28">
            <span
              className="material-symbols-outlined"
              style={{ fontSize: '2.5rem' }}>
              meeting_room
            </span>
          </div>
        )}

        {!object?.image && (
          <label htmlFor='image' className="cursor-pointer">
            <input
              type="file"
              multiple={false}
              accept={'.png, .jpeg, .jpg, .svg'}
              id="image"
              className="hidden"
              onChange={(e) => setSelectedFile(e.target.files)}
            />
            <span className="material-symbols-outlined absolute mt-5 md:mt-7 ml-[-15px] md:ml-[-20px] p-0.5 rounded-full cursor-pointer text-primary-blue bg-secondary-blue hover:bg-secondary-blue-h self-center"
              style={{ fontSize: '1.1rem' }}>
              add
            </span>
          </label>
        )}

        {(showDeleteBtn && object?.image) && (
          <span
            className="material-symbols-outlined absolute cursor-pointer bg-white rounded-full p-1.5 text-status-critical hover:text-status-critical-h ml-14 md:ml-20 mb-14 md:mb-20"
            onClick={() => handleDeleteImg(object?.image)}
            style={{ fontSize: '1.4rem' }}
          >delete</span>
        )}
      </div>

      {(selectedFile && !response.isLoading) && (
        <>
          <p className="text-xs mt-1">({selectedFile[0].name})</p>
          <button
            onClick={() => submitFile(selectedFile)}
            className="rounded-md bg-primary-blue hover:bg-primary-blue-h px-2 mt-1 text-xs font-bold leading-6 text-secondary-blue shadow-sm"
          >{t('common:upload')}</button>
        </>
      )}

      {response.isLoading && (
        <div className="my-3 text-center">
          <span className="text-xs font-semibold text-black">{t('common:form.fileBeingUploaded')}</span>
          <span className="material-symbols-outlined animate-bounce text-primary-blue">cloud_upload</span>
        </div>
      )}

      {/* <ConfirmModal isOpen={showDeleteModal} close={() => setShowDeleteModal(false)} title='Confirm delete' size='xs' titleAlign='center' type='info' titleSize="xs">
        <p className="text-sm font-semibold">Are you sure you want to delete the image?</p>
        <button
          // onClick={deletePropertyConfirmed}
          className="bg-status-critical hover:bg-status-critical-h py-1 px-1 text-xs sm:px-6 mt-3 rounded mx-auto text-white font-semibold">Delete</button>
      </ConfirmModal> */}
    </div>
  )
}

export default PropertyObjectFileUpload;