import { useEffect, useState } from "react";
import { ErrorSelectType, FixedFieldProperties } from "../../../../molecules/create-custom-log";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../../atoms/checkbox";

export type SelectFieldType = FixedFieldProperties & {
    selectValues: string[];
}

type SelectProps = {
    field: SelectFieldType;
    handleUpdateGeneratedField: (selectField: SelectFieldType) => void;
    handleDeleteGeneratedField: (id: number) => void;
    error: ErrorSelectType;
    logCount?: number;
    newTemplate: boolean;
}

const DynamicSelect = ({
    field,
    handleUpdateGeneratedField,
    handleDeleteGeneratedField,
    error,
    logCount,
    newTemplate,
}: SelectProps) => {
    const { t } =  useTranslation();

    const [selectField, setSelectField] = useState<SelectFieldType>({
        type: field.type,
        id: field.id,
        fieldName: field.fieldName,
        selectValues: field.selectValues,
        isRequired: field.isRequired,
        isActive: field.isActive,
        existing_record: field.existing_record,
        existing_name: field.existing_name
    });
    const [selectItem, setSelectItem] = useState<string>(''); // State for current select item
    const [selectError, setSelectError] = useState<{ nameField: string, itemField: string }>({ nameField: '', itemField: '' });
    const [existingSelectValues, setExistingSelectValues] = useState<string[]>([]);

    useEffect(() => {
        if (field && existingSelectValues.length === 0) {
            setExistingSelectValues(field.selectValues);
        }
    }, [])

    useEffect(() => {
        handleUpdateGeneratedField(selectField)
    }, [selectField, field])

    useEffect(() => {
        if (error.nameField && error.id === field.id) {
            setSelectError({ ...selectError, nameField: error.nameField })
        }

        if (error.selectValues && error.id === field.id) {
            setSelectError({ ...selectError, itemField: error.selectValues })
        }

    }, [error])

    // Validate input and update state
    const handleNameField = (value: string) => {
        if (!/^(?![0-9\-_])[a-zA-ZåäöÅÄÖ0-9_-\s]*$/.test(value)) {
            setSelectError({ ...selectError, nameField: t('page:logs.form.fieldOnlyLetters') });
        }
        else if (value.length > 32) {
            setSelectError({ ...selectError, nameField: t('page:logs.form.fieldMaxLength32') });
        }
        else {
            setSelectError({ ...selectError, nameField: '' });
            setSelectField({ ...selectField, fieldName: value });
        }
    }

    // Validate input and update state
    const handleItemField = (value: string) => {
        if (!/^(?![0-9\-_])[a-zA-ZåäöÅÄÖ0-9_-\s]*$/.test(value)) {
            setSelectError({ ...selectError, itemField: t('page:logs.form.itemOnlyLetters') });
        }
        else if (value.length > 32) {
            setSelectError({ ...selectError, itemField: t('page:logs.form.itemMaxLength32') });
        }
        else {
            setSelectError({ ...selectError, itemField: '' });
            setSelectItem(value)
        }
    }

    // Add item to select list
    const handleAddValue = (key?: string) => {
        // If key is 'Enter' or no key is passed, add value to array
        if ((key && key === 'Enter') || !key) {

            if (selectItem.trim() !== '') {
                // Get existing items
                const existingItems = [...selectField.selectValues];

                // Add new item to array of existing items
                existingItems.push(selectItem.trim());

                // Update state
                setSelectField({ ...selectField, selectValues: existingItems })

                setSelectItem(''); // Reset select item state   
            }
            else {
                setSelectError({ ...selectError, itemField: t('page:logs.form.itemNotEmpty') });
            }
        }
    }

    // Delete item from select list
    const handleDeleteValue = (index: number) => {
        // Get existing items
        const existingItems = [...selectField.selectValues];
        // Remove item by index
        existingItems.splice(index, 1);
        // Update state
        setSelectField({ ...selectField, selectValues: existingItems })
    }

    return (
        <div className="w-full rounded border my-2 p-2">
            <div className="mx-auto flex flex-col lg:flex-row">
                <div className="w-11/12 lg:w-2/6 flex flex-col mb-1 lg:mb-0 lg:h-20">
                    <label className="block text-xs lg:text-sm font-semibold leading-6 text-gray-600" htmlFor="input_type">{t('page:logs.nameOfSelectField')}</label>
                    <input
                        type="text"
                        placeholder={t('common:name')}
                        required
                        name="field_name"
                        value={selectField.fieldName}
                        // onChange={(e) => setSelectField({ ...selectField, fieldName: e.target.value })}
                        onChange={(e) => handleNameField(e.target.value)}
                        className={`${selectError.nameField && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 lg:text-sm lg:leading-6`}
                    />
                    {selectError.nameField && (<span className="text-xs text-status-critical mt-1">{selectError.nameField}</span>)}
                </div>
                <div className="lg:w-3/6 lg:ml-5 flex flex-col lg:h-20 mb-1 lg:mb-0">
                    <label className="block text-xs lg:text-sm font-semibold leading-6 text-gray-600" htmlFor="input_type">{t('page:logs.itemInList')}</label>
                    <div className="flex flex-row">
                        <input
                            type="text"
                            placeholder={t('common:name')}
                            required
                            name="select_item"
                            value={selectItem}
                            onChange={(e) => handleItemField(e.target.value)}
                            onKeyUp={(e) => handleAddValue(e.key)}
                            className={`${selectError.itemField && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} w-2/4 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 lg:text-sm lg:leading-6`}
                        />
                        <button
                            className="material-symbols-outlined ml-2 mt-1.5 bg-primary-blue hover:bg-primary-blue-h text-white h-6 rounded"
                            style={{ fontSize: '1.4rem' }}
                            onClick={() => handleAddValue()}
                        >
                            add
                        </button>
                    </div>
                    {selectError.itemField && (<span className="block text-xs text-status-critical mt-1">{selectError.itemField}</span>)}
                </div>

                {/* Show active checkbox if existing template is being edited */}
                {!newTemplate && (
                    <div className="w-full lg:w-2/12 flex flex-row lg:flex-col lg:items-center lg:border-l-2 lg:justify-center lg:ml-4 gap-y-1 gap-x-2 md:gap-x-0 mt-2 md:mt-0">
                        <label className="text-xs font-semibold text-gray-600" htmlFor="input_type">
                            {t('common:active')}
                            <span
                                className="material-symbols-outlined align-sub ml-0.5"
                                title={t('page:logs.activeBoxHelp')}
                                style={{ fontSize: '1rem' }}
                            >help</span>
                        </label>
                        <Checkbox
                            size={'sm'}
                            checked={selectField.isActive}
                            onChange={(e) => setSelectField({ ...selectField, isActive: e.target.checked })}
                        />
                    </div>
                )}

                <div className="flex flex-row flex-1 justify-end lg:mb-1 mb-[-2rem]">
                    <button
                        className="material-symbols-outlined text-status-critical hover:text-red-600 disabled:text-zinc-300"
                        onClick={() => handleDeleteGeneratedField(field.id)}
                        disabled={((logCount && logCount > 0) && field.existing_record) ? true : false}
                    >delete</button>
                </div>
            </div>

            <div className="w-4/5 lg:w-2/5 mt-2 p-2 rounded">
                {field.selectValues.length > 0 ? (
                    <>
                        {field.selectValues.map((item, idx) => {
                            return (
                                <div className="block border-b border-gray-300 my-1" key={idx}>
                                    <span className="text-xs lg:text-sm font-semibold">{item}</span>
                                    <button
                                        className="material-symbols-outlined text-status-critical hover:text-red-600 float-right disabled:text-zinc-300"
                                        onClick={() => handleDeleteValue(idx)}
                                        style={{ fontSize: '1.2rem' }}
                                        disabled={((logCount && logCount > 0) && existingSelectValues.some((val) => val === item)) ? true : false}
                                    >delete</button>
                                </div>
                            )
                        })}
                    </>
                ) : (
                    <p className="text-xs">{t('page:logs.noSelectItems')}</p>
                )}
            </div>
        </div>
    )
}
export default DynamicSelect;