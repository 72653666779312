import { useState } from 'react';
import { components } from 'react-select';
import Checkbox from '../checkbox';

type InputProps = {
    getStyles: any;
    Icon: any;
    isDisabled: boolean;
    isFocused: boolean;
    isSelected: boolean;
    children: any;
    innerProps: any;

}

// Customize the select options
const InputCheckbox = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
}: InputProps) => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex",
        padding: "0px",
    }

    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
    }

    return (
        // @ts-ignore
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}

        >
            <div className="px-2 py-2.5">
                <Checkbox checked={isSelected} />
            </div>
            <label>{children}</label>
        </components.Option>
    )
}
export default InputCheckbox;