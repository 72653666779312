
// Date as 18 sep. 2023 10:15
export const dateToLocaleDateAndHour = (date: Date) => {
    const newDate = new Date(date);

    const formattedDate = newDate.toLocaleString('sv-SE', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
    })
    
    return formattedDate;

    // Below is to add ',' between year and time
    const splitDate = formattedDate.split(' ');
    const datePart = splitDate.splice(0, splitDate.length-1); // Get date part from splitDate
    const timePart = splitDate[splitDate.length-1]; // Get time part from splitDate
    const datePartJoined = datePart.join(' ');
    return `${datePartJoined}, ${timePart}`
}

// Date as 18 sep. 2023
export const dateToLocaleDate = (date: Date) => {
    const newDate = new Date(date);

    const formattedDate = newDate.toLocaleString('sv-SE', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    })
    
    return formattedDate;
}