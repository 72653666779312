import { debounce } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { NotificationManager } from 'react-notifications';
import Select, { GroupBase, MultiValue, OptionsOrGroups } from 'react-select';
import InputCheckbox from '../../../atoms/select-checkbox';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { PropertyDto } from '../../../../services/tenant/property/dto/property.dto';
import { UpdatePropertyUserAccessDto } from '../../../../services/tenant/property-user-access/dto/update-property-user-access.dto';
import { UpdateUserDto } from '../../../../services/tenant/user/dto/update-user.dto';
import { useGetAllPropertyUserAccessByIdQuery, useUpdateManyPropertyUserAccessMutation } from '../../../../services/tenant/property-user-access/property-user-access.service';
import { useTranslation } from 'react-i18next';
import { useGetAllPropertiesQuery } from '../../../../services/tenant/property/property.service';
import { PageDto } from '../../../../services/dto/page.dto';
import { Order } from '../../../../services/enum/order';
import Loader from '../../../atoms/loader/loader';
import { SelectCustomStyles } from '../../../atoms/select-custom-styles/select-custom-styles';
import PrimaryButton from '../../../atoms/primary-button';
import SecondaryButton from '../../../atoms/secondary-button';

type Props = {
    user?: UpdateUserDto;
}

type SelectOption = {
    label: string;
    value: string;
    existing_access: boolean; // Access when the records is fetched
    property_id: string;
    property_user_access_id?: string;
}

const UserPropertyAccess = ({ user }: Props) => {
    const { t } = useTranslation();

    const [allOptions, setAllOptions] = useState<SelectOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
    const [search, setSearch] = useState<string>();
    const [page, setPage] = useState<number>(1);

    const debouncedSearch = useRef(debounce((search: string) => setSearch(search), 500)).current;

    const [updatePropertyUserAccess, response] = useUpdateManyPropertyUserAccessMutation();

    // Get all properties for current tenant
    const { data: properties, isFetching } = useGetAllPropertiesQuery({
        take: 15,
        page: page,
        order: Order.ASC,
        sortBy: 'label',
        search: search,
    });

    // Skip token for fetching propertyUserAccess by id
    const [skipUserAccess, setSkipUserAcess] = useState<any>(skipToken);
    // Get all PropertyUserAccess relations by user id
    const { data: propertyUserAccess } = useGetAllPropertyUserAccessByIdQuery(skipUserAccess);

    useEffect(() => {
        if (user) {
            setSkipUserAcess(user.id);
        }
    }, [user])

    useEffect(() => {
        if (properties && propertyUserAccess) {
            // INIT
            if (allOptions.length === 0) {
                initOptions(properties);
            }
            // SEARCH
            else if (search) {
                handleSearchOptions(search, properties);
            }
            // PAGINATION
            else {
                handlePagination(properties);
            }
        }
    }, [properties, propertyUserAccess])

    // Set initial options and selected options
    const initOptions = (properties: PageDto<PropertyDto>) => {
        if (propertyUserAccess && allOptions.length === 0) {
            const options = properties.data.map(property => ({
                value: property.id,
                label: property.label,
                existing_access: propertyUserAccess.find(e => e.property_id === property.id) ? true : false,
                property_user_access_id: propertyUserAccess.find(e => e.property_id === property.id)?.id,
                property_id: property.id
            }))
            setAllOptions(options);
        }

        if (propertyUserAccess) {
            const selectOptions: SelectOption[] = propertyUserAccess.map(pua => ({
                value: pua.property_id,
                label: pua.property_label,
                existing_access: true,
                property_user_access_id: pua.id,
                property_id: pua.property_id
            }))
            setSelectedOptions(selectOptions);
        }
    }

    // Handle options when search query is provided
    const handleSearchOptions = (search: string, properties: PageDto<PropertyDto>) => {
        if (propertyUserAccess && search) {
            setPage(1);
            const allOptions: SelectOption[] = [];
            const selectedOptions: SelectOption[] = [];
            properties.data.forEach(property => {
                let tempObj: SelectOption = {
                    value: property.id,
                    label: property.label,
                    existing_access: propertyUserAccess.find(e => e.property_id === property.id) ? true : false,
                    property_user_access_id: propertyUserAccess.find(e => e.property_id === property.id)?.id,
                    property_id: property.id
                };
                allOptions.push(tempObj);
                // When match between user and subsidiary id, push to selectedOptions
                if (tempObj.existing_access) {
                    selectedOptions.push(tempObj);
                }
            })
            setAllOptions([...allOptions]);
            setSelectedOptions([...selectedOptions]);
        }
    }

    // Handling options with pagination
    const handlePagination = (properties: PageDto<PropertyDto>) => {
        if (propertyUserAccess) {
            // Get existing options
            const existingOptions = [...allOptions];
            // Get existing selected options
            const existingSelected = [...selectedOptions];

            // Remove duplicates
            const uniqueArr = existingOptions.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.property_id === value.property_id
                ))
            )

            // Add options
            properties.data.forEach(property => {
                const obj = {
                    value: property.id,
                    label: property.label,
                    existing_access: propertyUserAccess.find(e => e.property_id === property.id) ? true : false,
                    property_user_access_id: propertyUserAccess.find(e => e.property_id === property.id)?.id,
                    property_id: property.id
                }
                // Make sure no duplicates are added
                if (!uniqueArr.some(e => e.property_id === obj.property_id)) {
                    uniqueArr.push(obj)
                    if (obj.existing_access && !existingSelected.some(e => e.property_id === obj.property_id)) {
                        existingSelected.push(obj);
                    }
                }
            });
            setAllOptions(uniqueArr);
            setSelectedOptions(existingSelected);
        }
    }

    // Handle scroll event to fetch more options
    const handleScroll = (event: any) => {
        if (properties && properties.meta.hasNextPage) {
            if (!search) {
                setPage(page ? page + 1 : 1);
            }
        }
    }

    // Selected option(s) will get passed
    const handleSelectChange = (options: MultiValue<SelectOption>) => {
        const allSelectedOptions = [...selectedOptions]; // All selected options

        if (options.length > 0) {
            // If options array is shorter than selectedOptions array, a user has been removed
            if (options.length < allSelectedOptions.length) {

                allSelectedOptions.forEach((option: SelectOption) => {
                    // If current option not in passed options arr, remove user from selectedOptions array
                    if (!options.some(e => e.property_id === option.property_id)) {
                        const index = allSelectedOptions.findIndex((e) => e.value === option.value);
                        if (index !== -1) {
                            allSelectedOptions.splice(index, 1);
                        }
                    }
                })
                setSelectedOptions(allSelectedOptions); // Update selectedOptions state
            }
            // If options array is not shorter than selectedOptions array, add option to selectedOptions array
            else {
                options.forEach((option: SelectOption) => {
                    // Add option if not already exists in allSelectedOptions array
                    if (!allSelectedOptions.some(e => e.property_id === option.property_id)) {
                        let tempObj: SelectOption = {
                            label: option.label,
                            value: option.value,
                            existing_access: option.existing_access,
                            property_user_access_id: option.property_user_access_id,
                            property_id: option.property_id
                        };
                        allSelectedOptions.push(tempObj);
                    }
                });
                setSelectedOptions(allSelectedOptions);
            }
        }
        else {
            setSelectedOptions([])
        }
    }

    const handleUpdateUserAccess = async () => {
        let recordsToBeUpdated: UpdatePropertyUserAccessDto[] = [];
        let existingRecords: SelectOption[] = [];

        // Loop through selectedOptions to get records to be created
        selectedOptions.forEach((record) => {
            // If the property existing_access is not true, it's a completely new record to be added
            if (record.existing_access === false) {
                let tempObj: UpdatePropertyUserAccessDto = {
                    access: true,
                    user_id: user!.id,
                    property_id: record.property_id,
                    property_user_access_id: record.property_user_access_id,
                }
                recordsToBeUpdated.push(tempObj);
            }
            else if (record.existing_access === true) {
                existingRecords.push(record);
            }
        })

        // Loop through all options and find records with property existing_access true
        // If the records does not exist in selectedOptions, it means it has been removed and should be deleted
        allOptions.forEach((option) => {
            if (option.existing_access === true) {
                if (!existingRecords.some(e => e.property_id === option.property_id)) {
                    let tempObj: UpdatePropertyUserAccessDto = {
                        access: false,
                        user_id: user!.id,
                        property_id: option.property_id,
                        property_user_access_id: option.property_user_access_id,
                    }
                    recordsToBeUpdated.push(tempObj);
                }
            }
        });        

        try {
            const response = await updatePropertyUserAccess(recordsToBeUpdated).unwrap();
            setAllOptions([]); // Reset to trigger refetch
            setSelectedOptions([])
            setPage(1);

            if (response.success) {
                NotificationManager.success(t('common:notifications.updated'));
            }
            else {
                NotificationManager.info(t('common:notifications.updateFailed'));
            }
        }
        catch (error) {
            NotificationManager.error(t('common:notifications.updateFailed'))
        }
    }

    return (
        <>
            <div className="rounded-lg mx-auto mb-2 w-full">
                <>
                    <p className="my-2 text-xs sm:text-sm font-semibold">{t('page:users.selectPropertiesThat')} <span className="font-extrabold uppercase">{user?.name}</span> {t('page:users.canAccess')}</p>
                    <Select
                        className="z-50 capitalize mb-2"
                        value={selectedOptions}
                        options={allOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        onChange={(options) => handleSelectChange(options)}
                        controlShouldRenderValue={true}
                        isClearable={false}
                        backspaceRemovesValue={false}
                        maxMenuHeight={200}
                        placeholder={t('common:select')}
                        noOptionsMessage={() => t('page:properties.noPropertiesFound')}
                        components={{
                            // @ts-ignore
                            Option: InputCheckbox
                        }}
                        onInputChange={(value) => debouncedSearch(value)}
                        captureMenuScroll={true}
                        onMenuScrollToBottom={(val) => handleScroll(val)}
                        filterOption={null}
                        styles={SelectCustomStyles}
                    />

                    <SecondaryButton
                        text={t('common:update')}
                        size={'medium'}
                        align={'center'}
                        wide={true}
                        onClick={handleUpdateUserAccess}

                    />

                </>
            </div>
        </>
    )
}

export default UserPropertyAccess;