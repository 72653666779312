import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import pageEN from './public/locales/en/page.json';
import commonEN from './public/locales/en/common.json';
import pageSE from './public/locales/se/page.json';
import commonSE from './public/locales/se/common.json';

const resources = {
    en: {
        common: commonEN,
        page: pageEN
    },
    se: {
        common: commonSE,
        page: pageSE
    }
}

i18n
    // Detect user language
    .use(LanguageDetector)
    // Pass the i18n instance to react-u18next
    .use(initReactI18next)
    // Init i18next
    .init({
        debug: process.env.NODE_ENV !== 'production',
        fallbackLng: 'se',
        interpolation: {
            escapeValue: false, // Not needed for react as it escapes by default
        },
        ns: ['common', 'page'],
        resources: resources
    });

    export default i18n;