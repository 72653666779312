import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../redux/hooks';
import { getTenant } from '../../../../redux/slices/tenant.slice';
import { getCurrentUser } from '../../../../redux/slices/user.slice';
import UpdateUserForm from '../../../organisms/forms/user/update-user-form';
import UpdateTenantForm from '../../../organisms/forms/tenant/update-tenant-form';
import { Grow } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetSubsidiaryQuery } from '../../../../services/tenant/subsidiary/subsidiary.service';
import UpdateSubsidiaryForm from '../../../organisms/forms/subsidiary/update-subsidiary-form';
import { useTranslation } from 'react-i18next';
import ChangePasswordForm from '../../../organisms/forms/user/change-password-form';

type Props = {}

const ProfilePage = (props: Props) => {
    const { t } = useTranslation();

    const { currentUser } = useAppSelector(getCurrentUser);
    const { tenant } = useAppSelector(getTenant);

    const [skip, setSkip] = useState<any>(skipToken);

    // Get subsidiary if user belongs to subsidiary
    const { data: subsidiary } = useGetSubsidiaryQuery(skip);

    const [selectedTab, setSelectedTab] = useState<string>('profile');

    // If user belongs to subsidiary, get subsidiary
    useEffect(() => {
        if (currentUser.subsidiary_id) {
            setSkip(currentUser.subsidiary_id);
        }

    }, [])    

    return (
        <Grow in={currentUser ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
            <div className="pb-5 w-full mx-auto">
                <div className="ml-6 md:ml-10">
                    <ul className="flex flex-wrap mb-5 md:-mb-px justify-center sm:justify-normal">
                        <li onClick={() => setSelectedTab('profile')} className={`${selectedTab === 'profile' ? 'border-blue-600 active text-blue-600 border-b-2' : 'border-gray-300 border-b-2 hover:text-gray-600 hover:border-b-2 hover:border-gray-300 text-black'} pl-2 pr-4 pb-4 pt-4 rounded-t-lg mr-2 cursor-pointer`}>
                            <span className="material-symbols-outlined align-sub">person</span>
                            <span className="inline-block pl-1 text-sm">{t('common:profile')}</span>
                        </li>
                        <li onClick={() => setSelectedTab('company')} className={`${selectedTab === 'company' ? 'border-blue-600 active text-blue-600 border-b-2' : 'border-gray-300 border-b-2 hover:text-gray-600 hover:border-b-2 hover:border-gray-300 text-black'} pl-2 pr-4 pb-4 pt-4 rounded-t-lg mr-2 cursor-pointer`}>
                            <span className="material-symbols-outlined align-sub">work</span>
                            <span className="inline-block pl-1 text-sm">{t('common:company')}</span>
                        </li>
                    </ul>
                </div>
                <div className="mx-auto">
                    {selectedTab === 'profile' && (
                        <Grow in={selectedTab === 'profile' ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                            <div className="w-full md:w-8/12 xl:w-6/12 max-w-2xl mx-auto md:text-base">
                                {/* <h2 className="text-xl md:text-2xl mb-5 text-center">{t('common:profile')}</h2> */}
                                <UpdateUserForm user={currentUser} />

                                <hr className="w-10/12 h-[2px] my-10 mx-auto bg-gray-300 border-0 rounded" />

                                <ChangePasswordForm user={currentUser} />
                            </div>
                        </Grow>
                    )}

                    {selectedTab === 'company' && (
                        <Grow in={selectedTab === 'company' ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                            <div className="w-full md:w-8/12 xl:w-6/12 max-w-2xl mx-auto text-sm md:text-base">
                                {/* <h2 className="text-2xl mb-5 text-center">{t('common:company')}</h2> */}
                                { currentUser.subsidiary_id ? (
                                    <UpdateSubsidiaryForm subsidiary={subsidiary} />
                                ) : (
                                    <UpdateTenantForm tenant={tenant} />
                                )}
                            </div>
                        </Grow>
                    )}
                </div>
            </div>
        </Grow >
    )
}

export default ProfilePage;