export const arrayOfObjectsEqual = (array1: any, array2: any) => {

    // Check length of arrays
    if (array1.length !== array2.length) {
        return false;
    }

    for (let i = 0; i < array1.length; i++) {
        if (!areObjectsEqual(array1[i], array2[i])) {
            return false;
        }
    } 

    return true;
}


const areObjectsEqual = (obj1: any, obj2: any) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if both objects have the same number of keys
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Check if all keys and values are the same
    for (const key of keys1) {
        const val1 = obj1[key];
        const val2 = obj2[key];

        // Check for nested objects
        const areObjects = isObject(val1) && isObject(val2);
        if (
            areObjects && !areObjectsEqual(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
            return false
        }
    }
    return true;
}

const isObject = (obj: any) => {
    return Object.prototype.toString.call(obj) === '[object Object]'
}