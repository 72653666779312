
import { store } from '../../../redux/store';
import { reAuthAPI } from '../../re-auth/re-auth';

import axios from "axios";

export const mediaAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Upload file
        uploadFile: builder.mutation<{ success: boolean, data: string }, FormData>({
            query: (file) => ({
                url: '/media/one',
                method: 'POST',
                body: file,
            }),
        }),
        // Upload files
        uploadFiles: builder.mutation<{ success: boolean, data: string }[], FormData>({
            query: (files) => ({
                url: '/media/many',
                method: 'POST',
                body: files,
            }),
        }),
        // Upload files as contractor, pass property owner tenant id
        uploadFilesExternal: builder.mutation<{ success: boolean, data: string }[], { files: FormData, tenantId?: string }>({
            query: (options) => {
                let queryParams = '';
                // Property owner tenant id, passed when contractor upload files related to log
                if (options.tenantId) {
                    queryParams = `?tenantId=${options.tenantId}`
                }
                return {
                    url: `/media/many${queryParams}`,
                    method: 'POST',
                    body: options.files,
                }
            },
    }),
    // Delete file
    deleteFile: builder.mutation<any, { filePath: string }>({
        query: (filePath) => ({
            url: '/media/one/',
            method: 'DELETE',
            body: filePath
        }),
    }),
})
})

export const {
    useUploadFileMutation,
    useUploadFilesMutation,
    useUploadFilesExternalMutation,
    useDeleteFileMutation,
} = mediaAPI;

// AXIOS FOR DOWNLOADING FILES
const baseUrl = process.env.REACT_APP_BACKEND_URL;

export const getFile = async (fileName: string, tenantIdPropertyOwner?: string) => {
    const state = store.getState();
    const access_token = state.rootReducer.auth.access_token;

    let tenantIdPropertyOwnerParam = '';
    if (tenantIdPropertyOwner) {
        tenantIdPropertyOwnerParam = `/${tenantIdPropertyOwner}`
    }

    return await axios({
        url: `${baseUrl}/media/${fileName}${tenantIdPropertyOwnerParam}`,
        method: 'GET',
        headers: { 'Authorization': `Bearer ${access_token}` },
        withCredentials: true,
        responseType: 'blob'
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        })
}