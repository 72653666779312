import { debounce } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Order } from '../../../../services/enum/order';
import { PageDto } from '../../../../services/dto/page.dto';
import Select from 'react-select';
import { SelectCustomStyles } from '../../../atoms/select-custom-styles/select-custom-styles';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPropertyQuery } from '../../../../services/tenant/property/property.service';
import { useGetAllPropertyBuildingsByPropertyIdQuery, useGetPropertyBuildingQuery } from '../../../../services/tenant/property-building/property-building.service';
import { PropertyBuildingDto } from '../../../../services/tenant/property-building/dto/property-building.dto';

type SelectOption = {
    label: string;
    value: string;
}

type Props = {
    handleSelectedBuilding: (value: { label: string, value: string }) => void;
    id?: string; // Id passed to match on property building
    propertyId?: string | null;
}

const PropertyBuildingSelect = ({ handleSelectedBuilding, propertyId, id }: Props) => {
    const { t } = useTranslation();
    
    const [allOptions, setAllOptions] = useState<SelectOption[]>([]);
    const [selected, setSelected] = useState<SelectOption>();
    const [search, setSearch] = useState<string>();
    const [page, setPage] = useState<number>(1);

    const debouncedSearch = useRef(debounce((search: string) => setSearch(search), 500)).current;

    // Get property buildings by property id
    const [skipTokenPropertyId, setSkipTokenPropertyId] = useState<any>(skipToken);
    const { data: propertyBuildings } = useGetAllPropertyBuildingsByPropertyIdQuery(
        propertyId ?
            {
                propertyId: propertyId,
                pageOptionsDto: {
                    take: 15,
                    page: page,
                    order: Order.ASC,
                    sortBy: 'label',
                    search: search
                }
            }
            : skipTokenPropertyId
    )

    // If id is passed, get building by id
    const [skipTokenId, setSkipTokenId] = useState<any>(skipToken);
    const { data: matchedBuilding } = useGetPropertyBuildingQuery(skipTokenId);

    const prevPropertyIdRef = useRef<string>(); // Keep track of propertyId    

    useEffect(() => {
        if (id) setSkipTokenId(id);
    }, [id])

    useEffect(() => {
        if (propertyBuildings) {
            if (propertyId && propertyId !== prevPropertyIdRef.current) {
                if (propertyId !== prevPropertyIdRef.current && prevPropertyIdRef.current !== undefined) {
                    setSelected({ label: t('common:select'), value: '' });
                }

                initOptions(propertyBuildings);
                prevPropertyIdRef.current = propertyId;
            }
            else {
                // INIT
                if (allOptions.length === 0) {
                    initOptions(propertyBuildings);
                }
                // SEARCH
                else if (search) {
                    handleSearchOptions(search, propertyBuildings);
                }
                // PAGINATION
                else {
                    handlePagination(propertyBuildings);
                }
            }
        }
    }, [propertyBuildings])

    // Set initial options and selected options
    const initOptions = (buildings: PageDto<PropertyBuildingDto>) => {
        const allOptions: SelectOption[] = [];
        if (buildings && allOptions.length === 0) {
            allOptions.push({
                label: '-',
                value: ''
            })
            buildings.data.forEach(building => {
                let tempObj: SelectOption = {
                    label: building.label,
                    value: building.id,
                };
                allOptions.push(tempObj);
            })
            setAllOptions(allOptions);
        }
    }

    // Handle options when search query is provided
    const handleSearchOptions = (search: string, buildings: PageDto<PropertyBuildingDto>) => {
        if (buildings && search) {
            setPage(1);
            const options: SelectOption[] = buildings.data.map(building => ({
                label: building.label,
                value: building.id,
            }));
            setAllOptions([...options]);
        }
    }

    // Handling options with pagination
    const handlePagination = (buildings: PageDto<PropertyBuildingDto>) => {
        if (buildings) {
            // Get existing options
            const existingOptions = [...allOptions];
            existingOptions.push({
                label: '-',
                value: ''
            })

            // Remove duplicates
            const uniqueArr = existingOptions.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.value === value.value
                ))
            )

            // Add options
            buildings.data.forEach(building => {
                const obj = {
                    label: building.label,
                    value: building.id,
                }
                // Make sure no duplicates are added
                if (!uniqueArr.some(e => e.value === obj.value)) {
                    uniqueArr.push(obj);
                }
            });
            setAllOptions(uniqueArr);
        }
    }

    // Handle scroll event to fetch more options
    const handleScroll = (event: any) => {
        if (propertyBuildings && propertyBuildings.meta.hasNextPage) {
            if (!search) {
                setPage(page ? page + 1 : 1);
            }
        }
    }

    const handleChange = (selectOption: { value: string, label: string }) => {
        setSelected(selectOption);
        handleSelectedBuilding(selectOption);
    }

    return (
        <Select
            className="capitalize md:text-sm"
            value={{
                label: selected ? selected.label : matchedBuilding ? matchedBuilding.label : t('common:select'),
                value: selected ? selected.value : matchedBuilding ? matchedBuilding.id : ''
            }}
            options={allOptions}
            hideSelectedOptions={false}
            onChange={(val) => handleChange(val ? val : { label: '', value: '' })}
            maxMenuHeight={200}
            placeholder={t('common:select')}
            noOptionsMessage={() => t('page:properties.noBuildingsFound')}
            onInputChange={(value) => debouncedSearch(value)}
            captureMenuScroll={true}
            onMenuScrollToBottom={(val) => handleScroll(val)}
            filterOption={null}
            styles={SelectCustomStyles}
        />
    )
}

export default PropertyBuildingSelect;