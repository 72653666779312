import { PageOptionsDto } from "../../dto/page-options.dto";
import { PageDto } from "../../dto/page.dto";
import { reAuthAPI } from "../../re-auth/re-auth";
import { CreateLogEntryCustomExternalDto } from "../../tenant/log-entries/dto/create-log-entry-custom-external.dto";
import { LogEntryQueryParams } from "../../tenant/log-entries/dto/log-entry-query-params.dto";
import { LogEntryDto } from "../../tenant/log-entries/dto/log-entry.dto";
import { LogTemplateDto } from "../../tenant/log-template/dto/log-template.dto";
import { UserDto } from "../../tenant/user/dto/user.dto";

/*
    Service related to contractors making requests to retrieve 
    information related property owners who might not exist in the same database
*/

export const ContractorAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Get log entries by propertyId, propertyBuildingId, propertyObjectId and logTemplateId
        getLogEntriesContractor: builder.query<PageDto<LogEntryDto>, { pageOptions: PageOptionsDto, queryParamsLogEntry: LogEntryQueryParams }>({
            query: (options) => {
                const queryParamsPaginated = [];
                if (options.pageOptions.page !== undefined) queryParamsPaginated.push(`page=${options.pageOptions.page}`);
                if (options.pageOptions.take !== undefined) queryParamsPaginated.push(`take=${options.pageOptions.take}`);
                if (options.pageOptions.order !== undefined) queryParamsPaginated.push(`order=${options.pageOptions.order}`);
                if (options.pageOptions.search !== undefined && options.pageOptions.search.length > 0) queryParamsPaginated.push(`search=${options.pageOptions.search}`);
                if (options.pageOptions.sortBy !== undefined) queryParamsPaginated.push(`sort_by=${options.pageOptions.sortBy}`);

                // Contruct query string for paginated request
                const queryStringPaginated = queryParamsPaginated.length > 0 ? `?${queryParamsPaginated.join('&')}` : '';

                // Construct query for log entry
                const queryParamsLogEntry = [];
                if (options.queryParamsLogEntry.company_id) queryParamsLogEntry.push(`company_id=${options.queryParamsLogEntry.company_id}`)
                if (options.queryParamsLogEntry.log_template_id) queryParamsLogEntry.push(`log_template_id=${options.queryParamsLogEntry.log_template_id}`)
                if (options.queryParamsLogEntry.property_id) queryParamsLogEntry.push(`property_id=${options.queryParamsLogEntry.property_id}`);
                if (options.queryParamsLogEntry.property_building_id) queryParamsLogEntry.push(`property_building_id=${options.queryParamsLogEntry.property_building_id}`);
                if (options.queryParamsLogEntry.property_object_id) queryParamsLogEntry.push(`property_object_id=${options.queryParamsLogEntry.property_object_id}`);
                if (options.queryParamsLogEntry.archived) queryParamsLogEntry.push(`archived=${options.queryParamsLogEntry.archived}`);
                const queryStringLogEntry = queryParamsLogEntry.length > 0 ? `&${queryParamsLogEntry.join('&')}` : '';

                return {
                    url: `/external/contractors/property-owners/logs${queryStringPaginated}${queryStringLogEntry}`,
                    method: 'GET',
                }
            },
            providesTags: ['ExternalContractorLogs']
        }),
        // Get log template by id
        getLogTemplateByIdContractor: builder.query<LogTemplateDto, { tenantId: string, logTemplateId: string }>({
            query: (options) => {
                const queryParams = [];
                if (options.tenantId !== undefined) queryParams.push(`companyId=${options.tenantId}`);
                if (options.logTemplateId !== undefined) queryParams.push(`logTemplateId=${options.logTemplateId}`);

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    url: `/external/contractors/property-owners/log-template${queryString}`,
                    method: 'GET'
                }
            },
        }),
        // Create custom log entry as contractor
        createCustomLogEntryExternal: builder.mutation<any, CreateLogEntryCustomExternalDto>({
            query: (data) => ({
                url: '/external/contractors/property-owners/logs',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['ExternalContractorLogs']
        }),

    })
})

export const {
    useGetLogEntriesContractorQuery,
    useGetLogTemplateByIdContractorQuery,
    useCreateCustomLogEntryExternalMutation,
} = ContractorAPI;