
import { Fragment, useEffect, useRef, useState } from "react";
import { NotificationManager } from 'react-notifications';
import { useTranslation } from "react-i18next";
import { Input } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FriendshipTenantDto } from "../../../../../../services/catalog/friendship/dto/friendship-tenant.dto";
import { useAppSelector } from "../../../../../../redux/hooks";
import { getTenant } from "../../../../../../redux/slices/tenant.slice";
import { getCurrentUser } from "../../../../../../redux/slices/user.slice";
import { debounce } from "../../../../../../utils/debounce";
import { Order } from "../../../../../../services/enum/order";
import Loader from "../../../../../atoms/loader/loader";
import { LogTypes } from "../../../../../../services/enum/log-types";
import { LogCategories } from "../../../../../../services/enum/log-categories";
import AllLogCategories from "../../../../log-components/log-structure/all-log-categories";
import { useCreateContractorLogUserAccessMutation, useDeleteContractorLogUserAccessMutation, useGetContractorLogUserAccessByIdsQuery, useGetUniqueLogCategoriesByAccessQuery } from "../../../../../../services/tenant/contractor-log-user-access/contractor-log-user-access.service";
import { UserDto } from "../../../../../../services/tenant/user/dto/user.dto";
import { ContractorLogAccessDto } from "../../../../../../services/tenant/contractor-log-user-access/dto/contractor-log-access.dto";
import { CreateContractorLogUserAccessDto } from "../../../../../../services/tenant/contractor-log-user-access/dto/create-contractor-log-user-access.dto";
import LogFilterByEntities from "../../../../log-components/log-structure/log-filter-by-entities";
import { useGetAllLogAccessByTenantIdQuery, useGetUniqueEntitiesLogAccessByTenantIdQuery } from "../../../../../../services/tenant/property-owner-contractor-log-access/property-owner-contractor-log-access.service";
import Checkbox from "../../../../../atoms/checkbox";
import Table from "../../../../../molecules/table";
import Pagination from "../../../../../molecules/pagination";


type FilterType = {
    properties?: string;
    buildings?: string;
    objects?: string;
    category?: LogCategories;
}


type Props = {
    company?: FriendshipTenantDto;
    users?: UserDto[];
    setSelectedLog: (log: ContractorLogAccessDto) => void;
    selectedLog?: ContractorLogAccessDto | null;
}

const ExternalLogAccessContractorTable = ({
    company,
    users,
    setSelectedLog,
    selectedLog
}: Props) => {
    const { t } = useTranslation();

    const { currentUser } = useAppSelector(getCurrentUser);
    const { tenant } = useAppSelector(getTenant);

    const [createContractorLogUserAccess] = useCreateContractorLogUserAccessMutation();
    const [deleteContractorLogUserAccess] = useDeleteContractorLogUserAccessMutation();

    // Pagination
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>();
    const [hasNextPage, setHasNextPage] = useState<boolean>();
    const [hasPrevPage, setHasPrevPage] = useState<boolean>();

    // Table sort and search
    const [search, setSearch] = useState<string | undefined>('');
    const [searchOperation, setSearchOperation] = useState<string | undefined>();
    const [sortBy, setSortBy] = useState<string | undefined>('name'); // Column to sort by
    const [sortOrder, setSortOrder] = useState<Order>(Order.ASC); // Sort order
    const [showSortMenu, setShowSortMenu] = useState<boolean>(false); // Sort menu on small screens

    const [filterObject, setFilterObject] = useState<FilterType>();
    const [filterString, setFilterString] = useState<string>();

    // Filter based on property, building or object
    const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
    const [selectedBuildings, setSelectedBuildings] = useState<string[]>([]);
    const [selectedObjects, setSelectedObjects] = useState<string[]>([]);

    // State for selected category
    const [selectedCategory, setSelectedCategory] = useState<LogCategories>();

    const debouncedSearch = useRef(debounce((search: string) => setSearchOperation(search), 500)).current;

    const [skipTokenCompanyTenantId, setSkipTokenCompanyTenantId] = useState<any>(skipToken);
    const { data: tenantLogs, isFetching } = useGetAllLogAccessByTenantIdQuery(
        company ?
            {
                tenantId: company.tenant_id,
                pageOptions: {
                    page: page,
                    take: 25,
                    search: searchOperation,
                    sortBy: sortBy,
                    order: sortOrder,
                    filter: filterString
                }
            } : skipTokenCompanyTenantId
    );    

    // Get unique log categories by access
    const [skipTokenUserIdTenantId, setSkipTokenUserIdTenantId] = useState<any>(skipToken);
    const { data: logCategories } = useGetUniqueLogCategoriesByAccessQuery(skipTokenUserIdTenantId);

    // Get unique entites by log access
    const { data: uniqueEntities } = useGetUniqueEntitiesLogAccessByTenantIdQuery(skipTokenCompanyTenantId);

    // Get logUserAccess by userIds
    const [skipTokenUserIds, setSkipTokenUserIds] = useState<any>(skipToken);
    const { data: logUserAccessByIds } = useGetContractorLogUserAccessByIdsQuery(skipTokenUserIds);

    useEffect(() => {
        if (currentUser && company) {
            setSkipTokenUserIdTenantId({
                userId: currentUser.id,
                tenantId: company.tenant_id
            })
        }
        if (company) {
            setSkipTokenCompanyTenantId(company.tenant_id)
        }
        if (users && users.length > 0) {
            setSkipTokenUserIds(users.map(e => e.id).join(','));
        }

    }, [users, company, currentUser])

    useEffect(() => {
        if (tenantLogs) {
            setPage(tenantLogs.meta.page);
            setPageCount(tenantLogs.meta.pageCount);
            setHasNextPage(tenantLogs.meta.hasNextPage);
            setHasPrevPage(tenantLogs.meta.hasPreviousPage);
        }
    }, [tenantLogs])

    // Construct filterQuery
    useEffect(() => {
        let tempObj: FilterType = {};

        if (selectedProperties && selectedProperties.length > 0) {
            tempObj.properties = selectedProperties.map(e => e).join(',');
        }
        if (selectedBuildings && selectedBuildings.length > 0) {
            tempObj.buildings = selectedBuildings.map(e => e).join(',');
        }
        if (selectedObjects && selectedObjects.length > 0) {
            tempObj.objects = selectedObjects.map(e => e).join(',');
        }
        if (selectedCategory !== undefined && selectedCategory !== LogCategories.None) {
            tempObj.category = selectedCategory;
        }

        // Check if there's a change in the filter
        if (JSON.stringify(filterObject) !== JSON.stringify(tempObj)) {
            setFilterObject(tempObj);
            const encoded = encodeURIComponent(JSON.stringify(tempObj));
            setFilterString(encoded);
        }

    }, [selectedProperties, selectedBuildings, selectedObjects, selectedCategory])

    const handlePagination = (page: number) => {
        setPage(page);
    }

    // Handle sort by column and sort order
    const handleSort = (column: string, order: Order) => {
        setSortBy(column);
        setSortOrder(order === Order.ASC ? Order.DESC : Order.ASC)

    }

    const handleCreateLogUserAccess = async (createContractorLogUserAccessDto: CreateContractorLogUserAccessDto) => {
        // Create propertyOwnerContractorLogAccessDto
        try {
            const response = await createContractorLogUserAccess(createContractorLogUserAccessDto).unwrap();
            if (response.success) {
                NotificationManager.success(t('common:notifications.accessUpdated'));
                // dispatch(userAPI.util.invalidateTags(['Users']));
            }
            else {
                NotificationManager.error(t('common:notifications.updateFailed'))
            }
        }
        catch (error) {
            NotificationManager.error(t('common:notifications.updateFailed'))
        }
    }

    const handleDeleteLogUserAccess = async (id: string) => {
        // Delete propertyOwnerContractorLogAccessDto
        try {
            const response = await deleteContractorLogUserAccess(id).unwrap();
            if (response.success) {
                NotificationManager.success(t('common:notifications.accessRemoved'));
                // dispatch(userAPI.util.invalidateTags(['Users']));
            }
            else {
                NotificationManager.error(t('common:notifications.updateFailed'))
            }
        }
        catch (error) {
            NotificationManager.error(t('common:notifications.updateFailed'))
        }
    }

    const handleAccess = async (log: ContractorLogAccessDto, userId: string) => {
        if (tenant.id && logUserAccessByIds) {
            const logUserAccessMatch = logUserAccessByIds.find(e => e.property_owner_contractor_log_access_id === log.property_owner_contractor_log_access_id && e.user_id === userId);

            if (logUserAccessMatch) {
                // Remove access
                await handleDeleteLogUserAccess(logUserAccessMatch.id);
            }
            else {
                // Give access
                const createContractorLogUserAccessDto: CreateContractorLogUserAccessDto = {
                    tenant_id_contractor: tenant.id,
                    tenant_id_property_owner: log.tenant_id_property_owner,
                    user_id: userId,
                    property_owner_contractor_log_access_id: log.property_owner_contractor_log_access_id,
                }
                await handleCreateLogUserAccess(createContractorLogUserAccessDto);
            }
        }
    }

    return (
        <div className="w-full mx-auto rounded-md overflow-x-hidden">
            <div className="flex items-center justify-center sm:justify-normal mt-5">
                <h5 className="text-base font-semibold ml-2">
                    {company ? (
                        <>
                            {t('page:logs.showingLogsFor')} {company?.company_name}
                        </>
                    ) : (
                        <>
                            {t('common:logs')}
                        </>
                    )}
                </h5>
            </div>
            <div className="sm:w-6/12 max-w-2xl text-center sm:text-left">
                <Input
                    type="search"
                    name="search_name"
                    placeholder={t('common:search')}
                    disableUnderline={true}
                    value={search}
                    className="w-full border rounded-md text-base md:text-sm px-2 pt-2.5 pb-1 mt-3 bg-white"
                    onChange={(e) => {
                        setSearch(e.target.value)
                        debouncedSearch(e.target.value)
                    }}
                    endAdornment={
                        <span
                            className="material-symbols-outlined pb-1 text-gray-500 cursor-pointer"
                            style={{ fontSize: '1.3rem' }}
                            onClick={() => {
                                if (search) {
                                    setSearch('')
                                    debouncedSearch('')
                                }
                            }}
                        >
                            {search ? 'close' : 'search'}
                        </span>
                    }
                    size="small"
                />
            </div>
            <div className="w-full">
                <AllLogCategories logCategories={logCategories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} alignCenter={true} />
            </div>

            <div className="w-full mt-1 mb-2">
                {uniqueEntities && uniqueEntities.length > 0 && (
                    <LogFilterByEntities
                        allPropertyLabels={uniqueEntities.filter(e => e.property_label !== undefined).map(e => e.property_label as string)}
                        allBuildingLabels={uniqueEntities.filter(e => e.property_building_label !== undefined).map(e => e.property_building_label as string)}
                        allObjectNames={uniqueEntities.filter(e => e.property_object_name !== undefined).map(e => e.property_object_name as string)}
                        setSelectedProperties={setSelectedProperties}
                        selectedProperties={selectedProperties}
                        setSelectedBuildings={setSelectedBuildings}
                        selectedBuildings={selectedBuildings}
                        setSelectedObjects={setSelectedObjects}
                        selectedObjects={selectedObjects}
                    />
                )}
            </div>

            <div>
                {tenantLogs && tenantLogs.data.length > 0 && !isFetching && (
                    <Table>
                        <Table.Thead>
                            <tr className="">
                                <th scope="col" className="px-1 sm:px-6 py-2 sm:py-3 text-left font-bold min-w-[4rem]">
                                    <span className="sr-only">Log category</span>
                                </th>
                                <th
                                    scope="col"
                                    className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]"
                                    onClick={() => handleSort('name', sortOrder)}>
                                    {t('common:name')}
                                    {(sortBy === 'name') ? (
                                        <>
                                            {sortOrder === Order.ASC ? (
                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                            ) : (
                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                        </>
                                    )}
                                </th>
                                <th
                                    scope="col"
                                    className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]"
                                    onClick={() => handleSort('belongs_to', sortOrder)}
                                >
                                    {t('common:belongsTo')}
                                    {(sortBy === 'belongs_to') ? (
                                        <>
                                            {sortOrder === Order.ASC ? (
                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                            ) : (
                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                        </>
                                    )}
                                </th>
                                {users && [...users]
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map(user => {
                                        return (
                                            <th
                                                key={user.id}
                                                scope="col"
                                                className="py-1 pl-2 md:py-2 pr-2 sm:table-cell cursor-pointer font-bold min-w-[8rem] max-w-[8rem] sticky top-0 bg-white z-10">
                                                {user.name}
                                            </th>
                                        )
                                    })}
                            </tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {tenantLogs.data.map((log, idx) => {
                                return (
                                    <tr
                                        key={idx}
                                        className={`odd:hover:bg-primary-blue-10 even:hover:bg-gray-50 cursor-pointer text-xs text-black even:bg-white odd:bg-primary-blue-5`}
                                    >

                                        <td scope="row" className="px-1 py-2 sm:px-6 capitalize text-left">
                                            {log.log_category === LogCategories.Other && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>home_repair_service</span>)}
                                            {log.log_category === LogCategories.Fire && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>local_fire_department</span>)}
                                            {log.log_category === LogCategories.Security && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>encrypted</span>)}
                                            {log.log_category === LogCategories.Camera && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>photo_camera</span>)}
                                            {log.log_category === LogCategories.Access && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>vpn_key</span>)}
                                            {log.log_category === LogCategories.Maintenance && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>construction</span>)}
                                            {log.log_category === LogCategories.Cleaning && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>mop</span>)}
                                            {log.log_category === LogCategories.Rounding && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>shield_person</span>)}
                                            {log.log_category === LogCategories.Elevator && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>elevator</span>)}
                                            {log.log_category === LogCategories.Ventilation && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>mode_fan</span>)}
                                        </td>
                                        <td
                                            className="py-2 pl-1 sm:pl-2 text-xs capitalize font-semibold max-w-[5rem] min-w-[5rem] overflow-hidden whitespace-nowrap text-ellipsis cursor-pointer"
                                            title={log?.log_template_name}
                                            onClick={() => setSelectedLog(log)}>
                                            {log.log_template_name ? log.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${log.log_template_name.split('_').join('')}`) : log.log_template_name.split('_').join(' ') : '-'}
                                        </td>
                                        <td
                                            className="py-2 pl-1 sm:pl-2 text-xs capitalize font-semibold max-w-[5rem] min-w-[5rem] overflow-hidden whitespace-nowrap text-ellipsis cursor-pointer"
                                            title={log.property_object_id ? log.property_object_name : log.property_building_id ? log.property_building_label : log.property_id ? log.property_label : '-'}
                                            onClick={() => setSelectedLog(log)}>
                                            {log.property_object_id ? log.property_object_name : log.property_building_id ? log.property_building_label : log.property_id ? log.property_label : '-'}
                                        </td>

                                        {users && logUserAccessByIds && [...users]
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map(user => {
                                                return (
                                                    <td
                                                        key={user.id}
                                                        className="pl-4 text-xs capitalize font-semibold sm:font-normal max-w-[3rem] min-w-[3rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                                        <Checkbox
                                                            size={'md'}
                                                            checked={logUserAccessByIds.find(e => e.property_owner_contractor_log_access_id === log.property_owner_contractor_log_access_id && e.user_id === user.id) ? true : false}
                                                            onChange={() => handleAccess(log, user.id)}
                                                        />
                                                    </td>
                                                )
                                            })}
                                    </tr>
                                )
                            })}
                        </Table.Tbody>
                    </Table>
                )}
                {isFetching && (
                    <div className="mx-auto mb-5 mt-1">
                        <Loader show={isFetching} size='small' />
                    </div>
                )}
                {!tenantLogs || tenantLogs.data.length < 1 && (
                    <div className="mt-5">
                        <p className="text-sm ml-8 md:ml-5 font-semibold text-black">{t('page:properties.noLogsFound')}...</p>
                    </div>
                )}
            </div>
            {tenantLogs && tenantLogs?.meta?.hasNextPage && (
                <div className="flex justify-center py-2">
                    <Pagination
                        count={pageCount}
                        page={page}
                        defaultPage={1}
                        showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                        showLastButton={pageCount ? pageCount > 5 : false}
                        hidePrevButton={!hasPrevPage}
                        hideNextButton={!hasNextPage}
                        onChange={handlePagination}
                    />
                </div>
            )}
        </div>
    )
}

export default ExternalLogAccessContractorTable;