
import { useTranslation } from "react-i18next";
import { TenantStateType } from "../../../../services/dto/tenant-state-type";
import InviteUserForm from "../../forms/user/invite-user-form";
import InvitedUsersTable from "./invited-users-table";

type Props = {
    setShowInviteUsers: (flag: boolean) => void;
    showInviteUsers: boolean;
    tenant?: TenantStateType
}

const InviteUsers = ({
    setShowInviteUsers,
    showInviteUsers,
    tenant
}: Props) => {
    const { t } = useTranslation();

    return (
        <div className="w-full mx-auto rounded-lg bg-primary-blue-5 p-2 shadow-sm">
            <div className="flex items-center">
                <div className="flex w-11/12">
                    <p className="text-base font-bold text-primary-blue my-1">{t('page:users.inviteUser')}</p>
                </div>
                <div className="flex justify-end w-1/12">
                    <button
                        onClick={() => setShowInviteUsers(!showInviteUsers)}
                        className="bg-secondary-blue rounded hover:bg-secondary-blue-h px-3 py-1.5 flex items-center text-primary-blue">
                        <span className="material-symbols-outlined" style={{ fontSize: '1.4rem ' }}>{showInviteUsers ? 'remove' : 'add'}</span>
                    </button>
                </div>
            </div>
            {showInviteUsers && (
                <>
                    <InviteUserForm tenant={tenant} />
                    <InvitedUsersTable tenant={tenant} />
                </>
            )}
        </div >
    )
}

export default InviteUsers;