import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useGetLogTemplatesQuery, useGetLogTemplateQuery } from '../../../../../services/tenant/log-template/log-template.service';
import { Order } from '../../../../../services/enum/order';
import { PageDto } from '../../../../../services/dto/page.dto';
import { LogTemplateDto } from '../../../../../services/tenant/log-template/dto/log-template.dto';
import { LogTypes } from '../../../../../services/enum/log-types';
import { SelectCustomStyles } from '../../../../atoms/select-custom-styles/select-custom-styles';
import { debounce } from '../../../../../utils/debounce';

type SelectOption = {
    label: string;
    value: string;
}

type Props = {
    // handleSelectedLogTemplate: (option: { value: string, label: string }) => void;
    handleSelectedLogTemplate: (logTemplate: LogTemplateDto | undefined) => void;
    id?: string; // Id passed to match logTemplate;
    zIndex?: string;
    reset?: boolean;
}

const LogTemplateSelect = ({
    handleSelectedLogTemplate,
    id,
    zIndex,
    reset
}: Props) => {
    const { t } = useTranslation();

    const [allOptions, setAllOptions] = useState<SelectOption[]>([]);
    const [search, setSearch] = useState<string>();
    const [page, setPage] = useState<number>(1);

    const debouncedSearch = useRef(debounce((search: string) => setSearch(search), 500)).current;

    // Get log structure templates
    const { data: logTemplates } = useGetLogTemplatesQuery({
        take: 5,
        page: page,
        order: Order.ASC,
        sortBy: 'name',
        search: search,
    });

    const [selected, setSelected] = useState<SelectOption>();

    const [skipTokenLogTemplate, setSkipTokenLogTemplate] = useState<any>(skipToken);
    const { data: logTemplate } = useGetLogTemplateQuery(skipTokenLogTemplate);

    useEffect(() => {
        if (id) setSkipTokenLogTemplate(id);
        if (selected && selected.value.length > 1) setSkipTokenLogTemplate(selected.value);
    }, [id, selected])

    useEffect(() => {
        if (reset)
            setSelected({ label: t('common:select'), value: ''})
        
    }, [reset])

    useEffect(() => {
        if (logTemplate) {
            setSelected({
                label: logTemplate.name.split('_').join(' '),
                value: logTemplate.id
            })
        }
    }, [logTemplate])

    useEffect(() => {
        if (logTemplates) {
            // INIT
            if (allOptions.length === 0) {
                initOptions(logTemplates);
            }
            // SEARCH
            else if (search) {
                handleSearchOptions(search, logTemplates);
            }
            // PAGINATION
            else {
                handlePagination(logTemplates);
            }
        }
    }, [logTemplates])

    // Set initial options and selected options
    const initOptions = (logTemplates: PageDto<LogTemplateDto>) => {
        const allOptions: SelectOption[] = [];
        if (logTemplates && allOptions.length === 0) {
            if (!id) {
                allOptions.push({
                    label: '-',
                    value: ''
                })
            }
            logTemplates.data.forEach(template => {
                let tempObj: SelectOption;
                if (id) {
                    if (template.log_type === LogTypes.Custom) {
                        tempObj = {
                            label: template.name.split('_').join(' '),
                            value: template.id,
                        }
                        allOptions.push(tempObj);
                    }
                }
                else {
                    tempObj = {
                        label: template.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${template.name.split('_').join('')}`) : template.name.split('_').join(' '),
                        value: template.id,
                    }
                    allOptions.push(tempObj);
                }
            })
            setAllOptions(allOptions);
        }
    }

    // Handle options when search query is provided
    const handleSearchOptions = (search: string, logTemplates: PageDto<LogTemplateDto>) => {
        if (logTemplates && search) {
            setPage(1);
            let options: SelectOption[] = [];
            logTemplates.data.forEach(template => {
                if (id) {
                    if (template.log_type === LogTypes.Custom) {
                        options.push({
                            label: template.name.split('_').join(' '),
                            value: template.id,
                        });
                    }
                }
                else {
                    options.push({
                        label: template.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${template.name.split('_').join('')}`) : template.name.split('_').join(' '),
                        value: template.id,
                    });
                }
            })
            setAllOptions([...options]);
        }
    }

    // Handling options with pagination
    const handlePagination = (logTemplates: PageDto<LogTemplateDto>) => {
        if (logTemplates) {
            // Get existing options
            const existingOptions = [...allOptions];
            if (!id) {
                existingOptions.push({
                    label: '-',
                    value: ''
                })
            }

            // Remove duplicates
            const uniqueArr = existingOptions.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.value === value.value
                ))
            )

            // let options: SelectOption[];
            logTemplates.data.forEach(template => {
                if (id) {
                    if (template.log_type === LogTypes.Custom) {
                        if (!uniqueArr.some(e => e.value === template.id)) {
                            uniqueArr.push({
                                label: template.name.split('_').join(' '),
                                value: template.id,
                            });
                        }
                    }
                }
                else {
                    if (!uniqueArr.some(e => e.value === template.id)) {
                        uniqueArr.push({
                            label: template.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${template.name.split('_').join('')}`) : template.name.split('_').join(' '),
                            value: template.id,
                        });
                    }
                }
            })

            setAllOptions(uniqueArr);
        }
    }

    // Handle scroll event to fetch more options
    const handleScroll = (event: any) => {
        if (logTemplates && logTemplates.meta.hasNextPage) {
            if (!search) {
                setPage(page ? page + 1 : 1);
            }
        }
    }

    useEffect(() => {
        if (logTemplate?.id === selected?.value) {
            handleSelectedLogTemplate(logTemplate);
        }
        else {
            handleSelectedLogTemplate(undefined)
        }

    }, [logTemplate, selected])

    return (
        <Select
            className={`${zIndex ?? zIndex} capitalize md:text-sm`}
            value={{
                label: selected ? (selected.label === 'log fire' || selected.label === 'log camera') ? t(`page:logs.defaultLogs.${selected.label.split(' ').join('')}`) : selected.label : logTemplate ? logTemplate.name : t('common:select'),
                value: selected ? selected.value : logTemplate ? logTemplate.id : ''
            }}
            options={allOptions}
            hideSelectedOptions={false}
            onChange={(val) => setSelected(val ? val : { label: '', value: '' })}
            maxMenuHeight={200}
            placeholder={t('common:select')}
            noOptionsMessage={() => t('page:properties.noLogsFound')}
            onInputChange={(value) => debouncedSearch(value)}
            captureMenuScroll={true}
            onMenuScrollToBottom={(val) => handleScroll(val)}
            filterOption={null}
            styles={SelectCustomStyles}
        />
    )
}

export default LogTemplateSelect;