import { PageOptionsDto } from '../../dto/page-options.dto';
import { PageDto } from '../../dto/page.dto';
import { NotificationStatus } from '../../enum/notification-status';
import { reAuthAPI } from '../../re-auth/re-auth';
import { CreateNotificationDto } from './dto/create-notification.dto';
import { NotificationDto } from './dto/notification.dto';
import { UpdateNotificationDto } from './dto/update-notification.dto';


export const notificationAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Get received notifications
        getReceivedNotifications: builder.query<PageDto<NotificationDto>, { pageOptionsDto: PageOptionsDto, status?: NotificationStatus }>({
            query: (options) => {
                const queryParams = [];
                if (options.pageOptionsDto.page !== undefined) queryParams.push(`page=${options.pageOptionsDto.page}`);
                if (options.pageOptionsDto.take !== undefined) queryParams.push(`take=${options.pageOptionsDto.take}`);
                if (options.pageOptionsDto.order !== undefined) queryParams.push(`order=${options.pageOptionsDto.order}`);
                if (options.pageOptionsDto.search !== undefined && options.pageOptionsDto.search.length > 0) queryParams.push(`search=${options.pageOptionsDto.search}`);
                if (options.pageOptionsDto.sortBy !== undefined) queryParams.push(`sort_by=${options.pageOptionsDto.sortBy}`)

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                const queryStringStatus = options.status ? `&status=${options.status}` : '';

                return {
                    url: `catalog/notifications/received${queryString}${queryStringStatus}`,
                    method: 'GET',
                }
            },
            providesTags: ['Notifications']
        }),
        // Create notification
        createNotification: builder.mutation<any, CreateNotificationDto>({
            query: (data) => ({
                url: 'catalog/notifications',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Notifications']
        }),
        // Update notification
        updateNotification: builder.mutation<any, UpdateNotificationDto>({
            query: (data) => ({
                url: 'catalog/notifications/' + data.id,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Notifications']
        }),
        // Delete notification
        deleteNotification: builder.mutation<any, string>({
            query: (id) => ({
                url: 'catalog/notifications/' + id,
                method: 'DELETE',
            }),
            invalidatesTags: ['Notifications']
        })
    })
})

export const {
    useGetReceivedNotificationsQuery,
    useCreateNotificationMutation,
    useUpdateNotificationMutation,
    useDeleteNotificationMutation,
} = notificationAPI;