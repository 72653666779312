
import { Fragment, useEffect, useRef, useState } from "react";
import { dateToLocaleDate, dateToLocaleDateAndHour } from "../../../../utils/date-time";
import { NotificationManager } from 'react-notifications';

import Modal from "../../../molecules/modal";
import { getCurrentUser } from "../../../../redux/slices/user.slice";
import ConfirmModal from "../../../molecules/confirm-modal";
import { PropertyDto } from "../../../../services/tenant/property/dto/property.dto";
import { useDeletePropertyObjectMutation, useGetPropertyObjectsQuery } from "../../../../services/tenant/property-object/property-object.service";
import UpdatePropertyObjectForm from "../../forms/property-object/update-property-object-form";
import PropertyObjectLogAccess from "../property-object-log-access";
import PropertyObjectUserAccess from "../property-object-user-access";
import { useAppSelector } from "../../../../redux/hooks";
import { useTranslation } from "react-i18next";
import { ObjectTypes } from "../../../../services/enum/object-types";
import { PropertyObjectDto } from "../../../../services/tenant/property-object/dto/property-object.dto";
import { Order } from "../../../../services/enum/order";
import { debounce } from "../../../../utils/debounce";
import { Input } from "@mui/material";
import Loader from "../../../atoms/loader/loader";
import FilterTableColumns from "../../../molecules/filter-table-columns";
import { Menu, Transition } from "@headlessui/react";
import CreatePropertyObjectForm from "../../forms/property-object/create-property-object-form";
import Pagination from "../../../molecules/pagination";
import Table from "../../../molecules/table";

// PropertyObject type with property name added for display in table and sort
type PropertyObjectTableType = {
    id: string;
    tenant_id: string;
    property_id: string;
    property_label: string;
    name: string;
    type: ObjectTypes;
    property_building_id?: string;
    object_number?: string;
    floor?: string;
    address?: string;
    created_at: Date;
    updated_at: Date;
    image?: string;
}

type Props = {}

const PropertyObjectTable = ({ }: Props) => {
    const { t } = useTranslation();
    const { currentUser } = useAppSelector(getCurrentUser);

    // Delete property Object
    const [deletePropertyObject, { isLoading: deleteIsLoading, error: deleteError }] = useDeletePropertyObjectMutation();

    const [showCopyModal, setShowCopyModal] = useState<boolean>(false); // Toggle copy modal
    const [showModal, setShowModal] = useState<boolean>(false); // Toggle modal
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false); // Toggle confirm modal
    const [selectedPropertyObject, setSelectedPropertyObject] = useState<PropertyObjectDto>();
    const [tableHeaders, setTableHeaders] = useState<string[]>();

    // Pagination
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>();
    const [hasNextPage, setHasNextPage] = useState<boolean>();
    const [hasPrevPage, setHasPrevPage] = useState<boolean>();

    // Table sort and search
    const [search, setSearch] = useState<string | undefined>('');
    const [searchOperation, setSearchOperation] = useState<string | undefined>();
    const [sortBy, setSortBy] = useState<string | undefined>('name'); // Column to sort by
    const [sortOrder, setSortOrder] = useState<Order>(Order.ASC); // Sort order
    const [showSortMenu, setShowSortMenu] = useState<boolean>(false); // Sort menu on small screens

    const debouncedSearch = useRef(debounce((search: string) => setSearchOperation(search), 500)).current;

    // Get property objects paginated
    const { data: propertyObjects, isFetching } = useGetPropertyObjectsQuery({
        page: page,
        take: 25,
        search: searchOperation,
        sortBy: sortBy,
        order: sortOrder
    });


    // If selectedPropertyObject changes, set updated propertyObject
    useEffect(() => {
        if (selectedPropertyObject) {
            const match = propertyObjects?.data.find((e) => e.id === selectedPropertyObject.id);
            if (match) {
                setSelectedPropertyObject(match);
            }
        }
    }, [propertyObjects]);

    useEffect(() => {
        if (propertyObjects) {
            setPage(propertyObjects.meta.page);
            setPageCount(propertyObjects.meta.pageCount);
            setHasNextPage(propertyObjects.meta.hasNextPage);
            setHasPrevPage(propertyObjects.meta.hasPreviousPage);
        }
    }, [propertyObjects])

    const handlePagination = (page: number) => {
        setPage(page);
    };

    // Handle sort by column and sort order
    const handleSort = (column: string, order: Order) => {
        setSortBy(column);
        setSortOrder(order === Order.ASC ? Order.DESC : Order.ASC)
    }

    // Edit property object
    const handleEditPropertyObject = (propertyObject: PropertyObjectTableType) => {
        setShowModal(true); // Show modal
        setSelectedPropertyObject(propertyObject);
    }

    // Copy property object
    const handleCopyPropertyObject = (propertyObject: PropertyObjectTableType) => {
        setShowCopyModal(true);
        setSelectedPropertyObject(propertyObject);
    }

    // Toggle modal and setSelected user
    const handleDeletePropertyObject = (propertyObject: PropertyObjectTableType) => {
        setSelectedPropertyObject(propertyObject); // Set selected property object to be deleted if confirmed in modal
        setShowConfirmModal(true); // Show confirm modal
    }

    // Delete property Object after confirmation
    const deletePropertyObjectConfirmed = async () => {
        const propertyObjectToBeDeleted = selectedPropertyObject;
        if (propertyObjectToBeDeleted?.id) {

            const payload = {
                property_object_id: propertyObjectToBeDeleted.id,
            }

            try {
                const response = await deletePropertyObject(payload).unwrap();
                if (response.success) {
                    NotificationManager.success(t('page:properties.form.objectDeleted'));
                    setShowConfirmModal(false);
                }
                else {
                    NotificationManager.error(t('page:properties.form.objectNotDeleted'));
                }
            }
            catch (error) {
                NotificationManager.error(t('page:properties.form.objectNotDeleted'));
            }
        }
    }

    const handlePropertyObject = () => {

    }

    // Define available column headers to be sent to FilterTableColumns
    const columnHeaders = ['name', 'type', 'object_number', 'property', 'floor', 'address', 'created_at'];

    // Gets triggered by FilterTableColumns when columns change
    const handleColumnsToDisplay = (columns: string[]) => {
        setTableHeaders(columns);
    }

    return (
        <>
            {(propertyObjects && propertyObjects?.data.length < 1 && !searchOperation) ? (
                <>
                    <div className="w-10/12 xl:w-8/12 max-w-2xl mx-auto bg-primary-blue-5 text-black rounded-md px-2 py-4 mt-10 text-center">
                        <p className="text-lg font-semibold">{t('page:properties.noObjectsFound')}...</p>
                        <p className="mt-2">{t('page:properties.noObjectsFoundInfo')}</p>
                    </div>
                </>
            ) : (
                <div className="w-full mx-auto md:mt-1">
                    <div className="hidden md:block">
                        <div className="sm:w-6/12 max-w-2xl mt-2 text-center sm:text-left">
                            <Input
                                type="search"
                                name="search_name"
                                placeholder={t('common:search')}
                                disableUnderline={true}
                                value={search}
                                className="w-full border rounded-md text-base md:text-sm px-2 pt-2.5 pb-1 mt-3 bg-white"
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                    debouncedSearch(e.target.value)
                                }}
                                endAdornment={
                                    <span
                                        className="material-symbols-outlined pb-1 text-gray-500 cursor-pointer"
                                        style={{ fontSize: '1.3rem' }}
                                        onClick={() => {
                                            if (search) {
                                                setSearch('')
                                                debouncedSearch('')
                                            }
                                        }}
                                    >
                                        {search ? 'close' : 'search'}
                                    </span>
                                }
                                size="small"
                            />
                        </div>
                        <div className="w-full mt-1 mb-2">
                            <FilterTableColumns
                                tableColumns={columnHeaders}
                                tableType='pot_filter'
                                handleColumnsToDisplay={handleColumnsToDisplay}
                            />
                        </div>
                        <div className="overflow-x-scroll">
                            <Table>
                                <Table.Thead>
                                    <tr>
                                        <th scope="col" className="w-20 hidden sm:table-cell">
                                            <span className="sr-only">Object image</span>
                                        </th>

                                        {tableHeaders?.includes('name') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]"
                                                onClick={() => handleSort('name', sortOrder)}>
                                                {t('common:name')}
                                                {(sortBy === 'name') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </th>
                                        )}

                                        {tableHeaders?.includes('type') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 pr-2 cursor-pointer font-bold min-w-[4rem]">
                                                {t('common:type')}
                                            </th>
                                        )}

                                        {tableHeaders?.includes('object_number') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 pr-2 cursor-pointer font-bold min-w-[9rem]"
                                                onClick={() => handleSort('object_number', sortOrder)}>
                                                {t('common:objectNumber')}
                                                {(sortBy === 'object_number') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </th>
                                        )}
                                        {tableHeaders?.includes('property') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]">
                                                {t('common:property')}
                                            </th>
                                        )}
                                        {tableHeaders?.includes('floor') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 pr-2 cursor-pointer font-bold min-w-[4rem]">
                                                {t('common:floor')}
                                            </th>
                                        )}
                                        {tableHeaders?.includes('address') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 cursor-pointer font-bold min-w-[6rem]">
                                                {t('common:address')}
                                            </th>
                                        )}
                                        {tableHeaders?.includes('created_at') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 cursor-pointer font-bold min-w-[6rem]"
                                                onClick={() => handleSort('created_at', sortOrder)}>
                                                {t('common:createdAt')}
                                                {(sortBy === 'created_at') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </th>
                                        )}
                                        <th scope="col" className="">
                                            <span className="sr-only">Copy</span>
                                        </th>
                                        <th scope="col" className="">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                        <th scope="col" className="">
                                            <span className="sr-only">Delete</span>
                                        </th>
                                    </tr>
                                </Table.Thead>
                                {propertyObjects && propertyObjects.data.length > 0 && !isFetching && (
                                    <Table.Tbody>
                                        {propertyObjects.data.map((propertyObject, idx) => {
                                            return (
                                                <tr key={idx} className={`odd:hover:bg-primary-blue-10 even:hover:bg-gray-50 cursor-pointer text-xs text-black even:bg-white odd:bg-primary-blue-5`}>
                                                    <td scope="row" className="pl-0.5 capitalize hidden sm:table-cell py-1">
                                                        {propertyObject.image ? (
                                                            <div className="flex items-center w-9 lg:w-12 h-9 lg:h-12 rounded-full bg-white overflow-hidden">
                                                                <img
                                                                    src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${propertyObject.tenant_id}/${propertyObject.image}`}
                                                                    // onError={e => e.currentTarget.src = DefaultObject}
                                                                    alt="Building image"
                                                                    className="w-9 md:w-12 h-9 md:h-12 rounded-full object-cover lg:p-0.5" />
                                                            </div>
                                                        ) : (
                                                            <div className="flex items-center justify-center w-9 lg:w-12 h-9 lg:h-12 rounded-full border border-gray-100">
                                                                <span
                                                                    className="material-symbols-outlined"
                                                                    style={{ fontSize: '1.3rem' }}>
                                                                    meeting_room
                                                                </span>
                                                            </div>
                                                        )}
                                                    </td>
                                                    {tableHeaders?.includes('name') && (
                                                        <td
                                                            className="py-2 pr-2 pl-1 sm:pl-2 text-xs capitalize font-semibold sm:font-normal max-w-[9rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            onClick={handlePropertyObject}>
                                                            {propertyObject.name}
                                                        </td>
                                                    )}

                                                    {tableHeaders?.includes('type') && (
                                                        <td
                                                            className="py-2 pr-2 capitalize max-w-[4rem] min-w-[4rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            onClick={handlePropertyObject}>
                                                            {t(`common:enums.objectTypes.${ObjectTypes[propertyObject.type]}`) ?? '-'}
                                                        </td>
                                                    )}
                                                    {tableHeaders?.includes('object_number') && (
                                                        <td
                                                            className="py-2 pr-2 capitalize max-w-[9rem] min-w-[9rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            onClick={handlePropertyObject}>
                                                            {propertyObject.object_number ? propertyObject.object_number : '-'}
                                                        </td>
                                                    )}
                                                    {tableHeaders?.includes('property') && (
                                                        <td
                                                            className="py-2 pr-2 pl-1 sm:pl-2 text-xs capitalize font-semibold sm:font-normal max-w-[9rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            onClick={handlePropertyObject}>
                                                            {propertyObject.property_label ? propertyObject.property_label : '-'}
                                                        </td>
                                                    )}
                                                    {tableHeaders?.includes('floor') && (
                                                        <td
                                                            className="py-2 pr-2 capitalize max-w-[4rem] min-w-[4rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            onClick={handlePropertyObject}>
                                                            {propertyObject.floor ? propertyObject.floor : '-'}
                                                        </td>
                                                    )}
                                                    {tableHeaders?.includes('address') && (
                                                        <td
                                                            className="py-2 pr-2 max-w-[7rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            onClick={handlePropertyObject}>
                                                            {propertyObject.address ? propertyObject.address : '-'}
                                                        </td>
                                                    )}
                                                    {tableHeaders?.includes('created_at') && (
                                                        <td
                                                            className="py-2 max-w-[7rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            onClick={handlePropertyObject}>
                                                            {propertyObject.created_at ? dateToLocaleDate(propertyObject.created_at) : '-'}
                                                        </td>
                                                    )}
                                                    <td className="px-2 lg:px-1">
                                                        <button
                                                            onClick={() => handleCopyPropertyObject(propertyObject)}
                                                            className="text-black material-symbols-outlined"
                                                            style={{ fontSize: '1.2rem' }}
                                                            title={t('common:duplicate')}
                                                        >content_copy</button>
                                                    </td>
                                                    <td className="pb-1 px-2 lg:px-1">
                                                        <button
                                                            onClick={() => handleEditPropertyObject(propertyObject)}
                                                            className="text-black material-symbols-outlined"
                                                            style={{ fontSize: '1.2rem' }}
                                                            title={t('common:edit')}
                                                        >edit_square</button>
                                                    </td>
                                                    <td className="px-2 lg:px-1">
                                                        <button
                                                            onClick={() => handleDeletePropertyObject(propertyObject)}
                                                            className="material-symbols-outlined text-status-critical"
                                                            style={{ fontSize: '1.3rem' }}
                                                            title={t('common:delete')}
                                                        >delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </Table.Tbody>
                                )}
                            </Table>
                            {isFetching && (
                                <div className="mx-auto mb-5 mt-1">
                                    <Loader show={isFetching} size='small' />
                                </div>
                            )}

                            {!propertyObjects || propertyObjects.data.length < 1 && (
                                <div className="my-5">
                                    <p className="text-sm ml-5 font-semibold text-black">{t('page:properties.noObjectsFound')}...</p>
                                </div>
                            )}
                        </div>
                        {propertyObjects && propertyObjects.meta && (
                            <div className="flex justify-center py-2">
                                <Pagination
                                    count={pageCount}
                                    page={page}
                                    defaultPage={1}
                                    showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                                    showLastButton={pageCount ? pageCount > 5 : false}
                                    hidePrevButton={!hasPrevPage}
                                    hideNextButton={!hasNextPage}
                                    onChange={handlePagination}
                                />
                            </div>
                        )}
                    </div>

                    {/* Mobile screen  */}
                    <div className="block md:hidden w-full mx-auto mt-1">
                        <div className="pt-1">
                            <Input
                                type="search"
                                name="search_name"
                                placeholder={t('common:search')}
                                disableUnderline={true}
                                className="border rounded-md px-2 pt-2.5 pb-1 mt-3 w-10/12 bg-white"
                                onChange={(e) => debouncedSearch(e.target.value)}
                                endAdornment={<span className="material-symbols-outlined text-gray-500 pb-1" style={{ fontSize: '1.3rem' }}>search</span>}
                                size="small"
                            />
                            <Menu as="div" className="relative inline-block text-left cursor-pointer">
                                <div>
                                    <Menu.Button
                                        className="material-symbols-outlined text-black align-sub ml-3 rotate-90 border rounded-md px-1 py-1"
                                        style={{ fontSize: '1.4rem' }}
                                        onClick={() => setShowSortMenu(!showSortMenu)}>
                                        sync_alt
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className="absolute right-0 z-10 mt-2 w-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="">
                                            <Menu.Item>
                                                <div className="flex hover:bg-primary-blue-5"
                                                    onClick={(e) => handleSort('name', sortOrder)}>
                                                    <span className="w-1/2 block px-2 py-2 text-sm">{t('common:name')}</span>
                                                    <div className="w-1/2 py-2 pr-1">
                                                        {(sortBy === 'name') ? (
                                                            <>
                                                                {sortOrder === Order.ASC ? (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                                ) : (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <div className="flex hover:bg-primary-blue-5" onClick={() => handleSort('object_number', sortOrder)}>
                                                    <span className="w-1/2 block px-2 py-2 text-sm">{t('common:objectNumber')}</span>
                                                    <div className="w-1/2 py-2 pr-1">
                                                        {(sortBy === 'object_number') ? (
                                                            <>
                                                                {sortOrder === Order.ASC ? (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                                ) : (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <div className="flex hover:bg-primary-blue-5" onClick={() => handleSort('created_at', sortOrder)}>
                                                    <span className="w-1/2 block px-2 py-2 text-sm">{t('common:createdAt')}</span>
                                                    <div className="w-1/2 py-2 pr-1">
                                                        {(sortBy === 'created_at') ? (
                                                            <>
                                                                {sortOrder === Order.ASC ? (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                                ) : (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                        {propertyObjects?.data && propertyObjects.data.length > 0 && !isFetching && (
                            <div>
                                {propertyObjects?.data?.map((propertyObject, idx) => (
                                    <div key={idx} className="my-2 p-2 text-xs relative odd:bg-primary-blue-5 odd:hover:bg-primary-blue-10 even:border border-primary-blue-5 hover:even:bg-gray-50 rounded-md">
                                        <div className="flex flex-col flex-wrap gap-x-5 my-1">
                                            <div>
                                                <button
                                                    onClick={() => handleCopyPropertyObject(propertyObject)}
                                                    className="text-black material-symbols-outlined absolute right-[4.5rem] top-2"
                                                    style={{ fontSize: '1.2rem' }}
                                                    title={t('common:duplicate')}
                                                >content_copy</button>
                                                <button
                                                    onClick={() => handleEditPropertyObject(propertyObject)}
                                                    className="text-black material-symbols-outlined absolute right-10 top-1.5"
                                                    style={{ fontSize: '1.2rem' }}
                                                    title={t('common:edit')}
                                                >edit_square</button>
                                                <button
                                                    onClick={() => handleDeletePropertyObject(propertyObject)}
                                                    className="material-symbols-outlined text-status-critical disabled:text-gray-300 absolute right-2 top-2"
                                                    style={{ fontSize: '1.3rem' }}
                                                    title={t('common:delete')}
                                                >delete</button>
                                            </div>

                                            <div className="flex items-center">
                                                <div className="sm:ml-5">
                                                    {propertyObject.image ? (
                                                        <div className="flex items-center rounded-full bg-white overflow-hidden">
                                                            <img
                                                                src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${propertyObject.tenant_id}/${propertyObject.image}`}
                                                                // onError={e => e.currentTarget.src = DefaultPersonImg}
                                                                alt="Profile picture"
                                                                className="rounded-full w-12 sm:w-14 h-12 sm:h-14 object-cover" />
                                                        </div>
                                                    ) : (
                                                        <div className="flex items-center w-12 sm:w-14 h-12 sm:h-14 justify-center rounded-full border border-gray-100">
                                                            <span
                                                                className="material-symbols-outlined"
                                                                style={{ fontSize: '1.4rem' }}
                                                            >
                                                                meeting_room
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="ml-3 sm:ml-10">
                                                    <p className="font-semibold text-sm capitalize">{propertyObject.name}</p>
                                                    <p>{t(`common:enums.objectTypes.${ObjectTypes[propertyObject.type]}`) ?? '-'}</p>
                                                    <p>{t('common:belongsTo')}: {propertyObject.property_label ? propertyObject.property_label : '-'}</p>
                                                    <p>{propertyObject.object_number ? propertyObject.object_number : '-'}</p>
                                                    <p>{propertyObject.floor ? propertyObject.floor : ''}</p>
                                                    <p>{propertyObject.address ? propertyObject.address : ''}</p>
                                                </div>

                                                <p className="absolute right-2 bottom-2">{dateToLocaleDate(propertyObject.created_at)}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        )}
                        {isFetching && (
                            <div className="mx-auto mb-5 mt-1">
                                <Loader show={isFetching} size='small' />
                            </div>
                        )}

                        {!propertyObjects || propertyObjects.data.length < 1 && (
                            <div className="my-2">
                                <p className="text-sm ml-5 font-semibold text-black">{t('page:properties.noObjectsFound')}...</p>
                            </div>
                        )}
                        {propertyObjects && propertyObjects.meta && (
                            <div className="flex justify-center py-2">
                                <Pagination
                                    count={pageCount}
                                    page={page}
                                    defaultPage={1}
                                    showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                                    showLastButton={pageCount ? pageCount > 5 : false}
                                    hidePrevButton={!hasPrevPage}
                                    hideNextButton={!hasNextPage}
                                    onChange={handlePagination}
                                />
                            </div>
                        )}
                    </div>

                    <Modal isOpen={showCopyModal} close={() => setShowCopyModal(false)} title={`${t('page:properties.createObject')} (${t('common:duplicate')})`} size='sm' titleAlign='center'>
                        <CreatePropertyObjectForm propertyObject={selectedPropertyObject} />
                    </Modal>

                    <Modal isOpen={showModal} close={() => setShowModal(false)} title={`${selectedPropertyObject?.name}`} size='sm' titleAlign='center'>
                        <div>
                            <div className="flex flex-wrap justify-center mx-auto mb-5">
                                <div className="w-10/12 sm:w-full">
                                    <UpdatePropertyObjectForm propertyObject={selectedPropertyObject} />

                                    <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                                    <PropertyObjectLogAccess propertyObject={selectedPropertyObject} />

                                    <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                                    <PropertyObjectUserAccess propertyObject={selectedPropertyObject} />
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <ConfirmModal isOpen={showConfirmModal} close={() => setShowConfirmModal(false)} title={t('page:properties.deleteObject')} titleAlign='center' type='warning'>
                        <div className="bg-red-200 rounded p-1">
                            <p className="text-xs font-semibold my-1 text-black">{t('page:properties.deleteObjectLogInfo')}</p>
                        </div>
                        <p className="text-lg font-semibold mt-3">{t('common:confirmDelete')} {selectedPropertyObject?.name ? selectedPropertyObject.name : 'the selected object'}?</p>
                        <button
                            onClick={deletePropertyObjectConfirmed}
                            className="bg-status-critical hover:bg-status-critical-h py-2 px-6 mt-3 rounded mx-auto text-sm sm:text-base text-white font-semibold">{t('common:delete')}</button>
                    </ConfirmModal>
                </div>
            )}
        </>
    )
}

export default PropertyObjectTable;