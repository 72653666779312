import React, { useEffect, useState } from 'react'
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import { useCreatePropertyMutation } from '../../../../services/tenant/property/property.service';
import Loader from '../../../atoms/loader/loader';
import Select from 'react-select';
import { propertyObjectAPI, useCreatePropertyObjectMutation } from '../../../../services/tenant/property-object/property-object.service';
import { isString } from 'formik';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'react-i18next';
import { ObjectTypes } from '../../../../services/enum/object-types';
import { Order } from '../../../../services/enum/order';
import PropertySelect from '../../property/property-select';
import PropertyBuildingSelect from '../../property-building/property-building-select';
import { useDispatch } from 'react-redux';
import { SelectCustomStyles } from '../../../atoms/select-custom-styles/select-custom-styles';
import { PropertyObjectDto } from '../../../../services/tenant/property-object/dto/property-object.dto';
import PrimaryButton from '../../../atoms/primary-button';

export type PropertyObjectFormType = {
    name: string;
    type: ObjectTypes;
    property_id: string;
    property_building_id?: string;
    object_number?: string;
    floor?: string;
    address?: string;
    image?: string;
    copy_property_object_id?: string;
}

type Props = {
    propertyObject?: PropertyObjectDto;
}

const CreatePropertyObjectForm = ({
    propertyObject
}: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [error, setGeneralError] = useState('');

    // Create property object
    const [createPropertyObject, response] = useCreatePropertyObjectMutation();

    const { control, handleSubmit, reset, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<PropertyObjectFormType>({
        defaultValues: {
            name: propertyObject ? propertyObject.name : '',
            type: propertyObject ? propertyObject.type : ObjectTypes.Entrance,
            property_id: propertyObject ? propertyObject.property_id : '',
            property_building_id: propertyObject ? propertyObject.property_building_id : undefined,
            object_number: propertyObject ? propertyObject.object_number : '',
            floor: propertyObject ? propertyObject.floor : '',
            address: propertyObject ? propertyObject.address : '',
            image: '',
            copy_property_object_id: propertyObject ? propertyObject.id : '',
        }
    });

    // Create property object
    const onSubmit = async (formData: PropertyObjectFormType) => {
        if (formData.property_id.length < 1) {
            setError('property_id', { type: 'required', message: t('page:properties.propertyNotEmpty') });
            return;
        }

        try {
            const response = await createPropertyObject(formData).unwrap();

            if (response.success) {
                setGeneralError('');
                NotificationManager.success(t('page:properties.form.objectCreated'));
                dispatch(propertyObjectAPI.util.invalidateTags(['PropertyObject']));
                reset(); // Reset form
            }
            else {
                setGeneralError(t(`common:server.${response.message}`));
                NotificationManager.error(t('page:properties.form.objectNotCreated'))
            }
        }
        catch (error) {
            setGeneralError('');
            NotificationManager.error(t('page:properties.form.objectNotCreated'))
        }
    }

    const handleSelectedProperty = (selectOption: { label: string, value: string }) => {
        if (selectOption.value && selectOption.value.length > 1) {
            setValue('property_id', selectOption.value);
            clearErrors('property_id');
        }
        else {
            setError('property_id', { type: 'required', message: t('page:properties.propertyNotEmpty') });
        }
    }

    const handleSelectedBuilding = (selectOption: { label: string, value: string }) => {
        if (selectOption.value && selectOption.value.length > 1) {
            setValue('property_building_id', selectOption.value);
        }
        else {
            setValue('property_building_id', undefined)
        }
    }

    return (
        <>
            <div className="sm:mx-auto sm:w-full">
                <form className="space-y-2" onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                        <label htmlFor='name' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:name')}<span className="text-status-critical ">*</span>
                        </label >
                        <Controller
                            name="name"
                            control={control}
                            rules={{
                                required: t('common:form.nameNotEmpty'),
                                minLength: {
                                    value: 3,
                                    message: t('common:form.nameMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:name')}
                                        className={`${errors.name ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.name && <p className="text-status-critical text-xs my-1 ml-1">{errors.name.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='type' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('page:properties.objectType')}<span className="text-status-critical ">*</span>
                        </label>
                        <Controller
                            name="type"
                            control={control}
                            rules={{
                                required: t('page:properties.objectTypeNotEmpty'),
                                // validate: (value) => value in PropertyTypes || 'You need to select a property type.',
                            }}
                            render={({ field }) => (
                                <>
                                    <Select
                                        name="type"
                                        className={`${errors.type ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical rounded-md' : ''} z-50`}
                                        // FIX: When nothing is selected, send 0 to form so required doesn't work
                                        onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                                        styles={SelectCustomStyles}
                                        value={(Object.entries(ObjectTypes) as [string, string][])
                                            .filter(([key, value]) => typeof value === 'string')
                                            .map(([key, value]) => ({ label: t(`common:enums.objectTypes.${key}`), value: value.toString() }))
                                            .find(option => option.value === field.value?.toString())}
                                        options={(Object.values(ObjectTypes) as Array<keyof typeof ObjectTypes>)
                                            .filter((val) => typeof val === 'string' && val !== ObjectTypes.None)
                                            .map((val) => ({
                                                label: t(`common:enums.objectTypes.${ObjectTypes[val]}`),
                                                value: val,
                                            }))
                                        }
                                        placeholder={t('common:select')}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        controlShouldRenderValue={true}
                                        isClearable={false}
                                        backspaceRemovesValue={false}
                                        noOptionsMessage={() => t('page:properties.noObjectTypesFound')}
                                    />
                                </>
                            )}
                        />
                        {errors.type && <p className="text-status-critical text-xs my-1 ml-1">{errors.type.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='property_id' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('page:properties.objectBelongsToProperty')}<span className="text-status-critical ">*</span>
                        </label>
                        <PropertySelect
                            handleSelectedProperty={handleSelectedProperty}
                            id={propertyObject?.property_id ? propertyObject.property_id : ''}
                        />
                        {errors.property_id && <p className="text-status-critical text-xs my-1 ml-1">{errors.property_id.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='property_building_id' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('page:properties.objectBelongsToBuilding')}
                        </label>
                        <PropertyBuildingSelect
                            handleSelectedBuilding={handleSelectedBuilding}
                            propertyId={getValues('property_id')}
                            id={propertyObject?.property_building_id ? propertyObject.property_building_id : ''}
                        />
                        {errors.property_building_id && <p className="text-status-critical text-xs my-1 ml-1">{errors.property_building_id.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='object_number' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:objectNumber')}
                        </label >
                        <Controller
                            name="object_number"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 3,
                                    message: t('page:properties.form.objectNumberMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:objectNumber')}
                                        className={`${errors.object_number ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.object_number && <p className="text-status-critical text-xs my-1 ml-1">{errors.object_number.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='floor' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:floor')}
                        </label >
                        <Controller
                            name="floor"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 1,
                                    message: t('page:properties.form.floorMinLength1')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:floor')}
                                        className={`${errors.floor ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.floor && <p className="text-status-critical text-xs my-1 ml-1">{errors.floor.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='address' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:address')}
                        </label >
                        <Controller
                            name="address"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 3,
                                    message: t('common:form.addressMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:address')}
                                        className={`${errors.address ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.address && <p className="text-status-critical text-xs my-1 ml-1">{errors.address.message}</p>}
                    </div>

                    {error && (<p className="font-bold text-sm mt-2 text-center text-status-critical">{error}</p>)}
                    <div>
                        <PrimaryButton
                            text={t('common:create')}
                            size={'medium'}
                            fullWidth={true}
                        />
                        {response.isLoading && (<Loader show={response.isLoading} size='small' />)}
                    </div>
                </form>
            </div >
        </>
    )
}

export default CreatePropertyObjectForm;