

type LatLngType = {
    lat: number;
    lng: number;
}

export async function getLatLng(address: string, city?: string): Promise<LatLngType | null | undefined> {
    return new Promise((resolve, reject) => {
        if (window.google && window.google.maps && window.google.maps.Geocoder) {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({
                'address': address,
                'componentRestrictions': {
                    'locality': city || undefined
                }
            }, function (results, status) {
                if (status === 'OK' && results && results.length > 0) {
                    const lat = results[0].geometry.location.lat();
                    const lng = results[0].geometry.location.lng();
                    // console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
                    resolve({
                        lat,
                        lng
                    })
                }
                else {
                    // console.log('Geocode was not successful: ', status);
                    resolve(null);
                }
            })
        }
        else {
            // console.log('Google maps api not loaded');
            resolve(null)
        }
    })
}
