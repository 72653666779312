
import { Fragment, useEffect, useRef, useState } from "react";
import { dateToLocaleDate, dateToLocaleDateAndHour } from "../../../../utils/date-time";
import { NotificationManager } from 'react-notifications';

import Modal from "../../../molecules/modal";
import { getCurrentUser } from "../../../../redux/slices/user.slice";
import ConfirmModal from "../../../molecules/confirm-modal";
import { PropertyDto } from "../../../../services/tenant/property/dto/property.dto";
import { propertyObjectAPI, useDeletePropertyObjectMutation, useGetPropertyObjectsQuery, useUpdatePropertyObjectMutation } from "../../../../services/tenant/property-object/property-object.service";
import UpdatePropertyObjectForm from "../../forms/property-object/update-property-object-form";
import PropertyObjectLogAccess from "../property-object-log-access";
import PropertyObjectUserAccess from "../property-object-user-access";
import { useAppSelector } from "../../../../redux/hooks";
import { useTranslation } from "react-i18next";
import { ObjectTypes } from "../../../../services/enum/object-types";
import { PropertyObjectDto } from "../../../../services/tenant/property-object/dto/property-object.dto";
import { Order } from "../../../../services/enum/order";
import { debounce } from "../../../../utils/debounce";
import { Input } from "@mui/material";
import Loader from "../../../atoms/loader/loader";
import FilterTableColumns from "../../../molecules/filter-table-columns";
import { useDispatch } from "react-redux";
import { UpdatePropertyObjectDto } from "../../../../services/tenant/property-object/dto/update-property-object.dto";
import { useGetAllPropertyObjectUserAccessByIdsQuery, useUpdateManyPropertyObjectUserAccessMutation } from "../../../../services/tenant/property-object-user-access/property-object-user-access.service";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { UpdatePropertyObjectUserAccessDto } from "../../../../services/tenant/property-object-user-access/dto/update-property-object-user-access.dto";
import CreatePropertyObjectForm from "../../forms/property-object/create-property-object-form";
import { UserDto } from "../../../../services/tenant/user/dto/user.dto";
import { LogTemplateDto } from "../../../../services/tenant/log-template/dto/log-template.dto";
import { LogTypes } from "../../../../services/enum/log-types";
import PrimaryButton from "../../../atoms/primary-button";
import Checkbox from "../../../atoms/checkbox";
import Pagination from "../../../molecules/pagination";
import Table from "../../../molecules/table";
import { useGetAllPropertyObjectLogAccessByIdsQuery, useUpdateManyPropertyObjectLogAccessMutation } from "../../../../services/tenant/entity-log/entity-log.service";
import { UpdatePropertyObjectLogAccessDto } from "../../../../services/tenant/entity-log/dto/object-log-access/update-property-object-log-access.dto";

type Props = {
    properties?: PropertyDto[];
    users?: UserDto[];
    logTemplates?: LogTemplateDto[];
}

const PropertyObjectTableEdit = ({
    properties,
    users,
    logTemplates,
}: Props) => {
    const { t } = useTranslation();
    const { currentUser } = useAppSelector(getCurrentUser);
    const dispatch = useDispatch();

    // Delete property object
    const [deletePropertyObject, { isLoading: deleteIsLoading, error: deleteError }] = useDeletePropertyObjectMutation();
    // Update property object
    const [updatePropertyObject, { isLoading: updatePropertyObjectIsLoading, error: updatePropertyObjectError }] = useUpdatePropertyObjectMutation();
    const [updatePropertyObjectUserAccess] = useUpdateManyPropertyObjectUserAccessMutation();
    const [updatePropertyObjectLogAccess] = useUpdateManyPropertyObjectLogAccessMutation();

    const [showCreateModal, setShowCreateModal] = useState<boolean>(false); // Toggle create modal
    const [showCopyModal, setShowCopyModal] = useState<boolean>(false); // Toggle copy modal
    const [showModal, setShowModal] = useState<boolean>(false); // Toggle modal
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false); // Toggle confirm modal
    const [selectedPropertyObject, setSelectedPropertyObject] = useState<PropertyObjectDto>();
    const [tableHeaders, setTableHeaders] = useState<string[]>();

    // Pagination
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>();
    const [hasNextPage, setHasNextPage] = useState<boolean>();
    const [hasPrevPage, setHasPrevPage] = useState<boolean>();

    // Table sort and search
    const [search, setSearch] = useState<string | undefined>('');
    const [searchOperation, setSearchOperation] = useState<string | undefined>();
    const [sortBy, setSortBy] = useState<string | undefined>('name'); // Column to sort by
    const [sortOrder, setSortOrder] = useState<Order>(Order.ASC); // Sort order
    const [showSortMenu, setShowSortMenu] = useState<boolean>(false); // Sort menu on small screens

    const debouncedSearch = useRef(debounce((search: string) => setSearchOperation(search), 500)).current;

    // Get property objects paginated
    const { data: propertyObjects, isFetching } = useGetPropertyObjectsQuery({
        page: page,
        take: 25,
        search: searchOperation,
        sortBy: sortBy,
        order: sortOrder
    });


    // If selectedPropertyObject changes, set updated propertyObject
    useEffect(() => {
        if (selectedPropertyObject) {
            const match = propertyObjects?.data.find((e) => e.id === selectedPropertyObject.id);
            if (match) {
                setSelectedPropertyObject(match);
            }
        }
    }, [propertyObjects]);

    useEffect(() => {
        if (propertyObjects) {
            setPage(propertyObjects.meta.page);
            setPageCount(propertyObjects.meta.pageCount);
            setHasNextPage(propertyObjects.meta.hasNextPage);
            setHasPrevPage(propertyObjects.meta.hasPreviousPage);
        }
    }, [propertyObjects])

    const [skipTokenUserIds, setSkipTokenUserIds] = useState<any>(skipToken);
    const { data: propertyObjectUserAccessByIds } = useGetAllPropertyObjectUserAccessByIdsQuery(skipTokenUserIds);

    const [skipTokenLogTemplateIds, setSkipTokenLogTemplateIds] = useState<any>(skipToken);
    const { data: propertyObjectLogAccessByIds } = useGetAllPropertyObjectLogAccessByIdsQuery(skipTokenLogTemplateIds);

    useEffect(() => {
        if (users) {
            setSkipTokenUserIds(users.map(e => e.id).join(','));
        }
    }, [users])

    useEffect(() => {
        if (logTemplates) {
            setSkipTokenLogTemplateIds(logTemplates.map(e => e.id).join(','));
        }
    }, [logTemplates])

    const handlePagination = (page: number) => {
        setPage(page);
    };

    // Handle sort by column and sort order
    const handleSort = (column: string, order: Order) => {
        setSortBy(column);
        setSortOrder(order === Order.ASC ? Order.DESC : Order.ASC)
    }

    // Edit property
    const handleEditPropertyObject = (propertyObject: PropertyObjectDto) => {
        setShowModal(true); // Show modal
        setSelectedPropertyObject(propertyObject);
    }

    // Copy property
    const handleCopyPropertyObject = (propertyObject: PropertyObjectDto) => {
        setShowCopyModal(true);
        setSelectedPropertyObject(propertyObject);
    }

    // Toggle modal and setSelected user
    const handleDeletePropertyObject = (propertyObject: PropertyObjectDto) => {
        setSelectedPropertyObject(propertyObject); // Set selected property object to be deleted if confirmed in modal
        setShowConfirmModal(true); // Show confirm modal
    }

    // Delete property Object after confirmation
    const deletePropertyObjectConfirmed = async () => {
        const propertyObjectToBeDeleted = selectedPropertyObject;
        if (propertyObjectToBeDeleted?.id) {

            const payload = {
                property_object_id: propertyObjectToBeDeleted.id,
            }

            try {
                const response = await deletePropertyObject(payload).unwrap();
                if (response.success) {
                    NotificationManager.success(t('page:properties.form.objectDeleted'));
                    setShowConfirmModal(false);
                }
                else {
                    NotificationManager.error(t('page:properties.form.objectNotDeleted'));
                }
            }
            catch (error) {
                NotificationManager.error(t('page:properties.form.objectNotDeleted'));
            }
        }
    }

    const handlePropertyObject = () => {

    }

    const handleUpdatePropertyObject = async (updatePropertyObjectDto: UpdatePropertyObjectDto) => {
        try {
            const response = await updatePropertyObject(updatePropertyObjectDto).unwrap();
            if (response.success) {
                NotificationManager.success(t('page:properties.form.objectUpdated'));
                dispatch(propertyObjectAPI.util.invalidateTags(['PropertyObject']));
            }
            else {
                NotificationManager.error(t('page:properties.form.objectNotUpdated'))
            }
        }
        catch (error) {
            NotificationManager.error(t('page:properties.form.objectNotUpdated'));
        }
    }

    const handleUpdatePropertyObjectUserAccess = async (updatePropertyObjectUserAccessDto: UpdatePropertyObjectUserAccessDto[]) => {
        // Update property object user access
        try {
            const response = await updatePropertyObjectUserAccess(updatePropertyObjectUserAccessDto).unwrap();
            if (response.success) {
                NotificationManager.success(t('page:properties.form.objectUpdated'));
                // dispatch(userAPI.util.invalidateTags(['Users']));
            }
            else {
                NotificationManager.error(t('page:properties.form.objectNotUpdated'))
            }
        }
        catch (error) {
            NotificationManager.error(t('page:properties.form.objectNotUpdated'));
        }
    }

    const handleUpdatePropertyObjectLogAccess = async (updatePropertyObjectLogAccessDto: UpdatePropertyObjectLogAccessDto[]) => {
        // Update property object user access
        try {
            const response = await updatePropertyObjectLogAccess(updatePropertyObjectLogAccessDto).unwrap();
            if (response.success) {
                NotificationManager.success(t('page:properties.form.objectUpdated'));
                // dispatch(userAPI.util.invalidateTags(['Users']));
            }
            else {
                NotificationManager.error(t('page:properties.form.objectNotUpdated'))
            }
        }
        catch (error) {
            NotificationManager.error(t('page:properties.form.objectNotUpdated'));
        }
    }

    const handleAccess = async (propertyObject: PropertyObjectDto, id: string) => {
        if (properties) {
            let updatePropertyObjectDto: UpdatePropertyObjectDto = {
                id: propertyObject.id
            }
            const existingAccess = propertyObject.property_id === id;
            if (existingAccess) {
            }
            else {
                updatePropertyObjectDto.property_id = id;
                await handleUpdatePropertyObject(updatePropertyObjectDto);

            }
        }
        else if (users && propertyObjectUserAccessByIds) {
            const propertyObjectUserAccessMatch = propertyObjectUserAccessByIds.find(e => e.property_object_id === propertyObject.id && e.user_id === id);
            if (propertyObjectUserAccessMatch) {
                // Remove access
                let updateObj: UpdatePropertyObjectUserAccessDto = {
                    access: false,
                    user_id: id,
                    property_object_id: propertyObject.id,
                    property_object_user_access_id: propertyObjectUserAccessMatch.id
                }
                handleUpdatePropertyObjectUserAccess([updateObj]);
            }
            else {
                // Give access
                let updateObj: UpdatePropertyObjectUserAccessDto = {
                    access: true,
                    user_id: id,
                    property_object_id: propertyObject.id,
                }
                handleUpdatePropertyObjectUserAccess([updateObj]);
            }
        }
        else if (logTemplates && propertyObjectLogAccessByIds) {
            const propertyObjectLogAccessMatch = propertyObjectLogAccessByIds.find(e => e.property_object_id === propertyObject.id && e.log_template_id === id);
            if (propertyObjectLogAccessMatch) {
                // Remove access
                let updateObj: UpdatePropertyObjectLogAccessDto = {
                    access: false,
                    log_template_id: id,
                    property_object_id: propertyObject.id,
                    entity_log_id: propertyObjectLogAccessMatch.id
                }
                handleUpdatePropertyObjectLogAccess([updateObj]);
            }
            else {
                // Give access
                let updateObj: UpdatePropertyObjectLogAccessDto = {
                    access: true,
                    log_template_id: id,
                    property_object_id: propertyObject.id,
                }
                handleUpdatePropertyObjectLogAccess([updateObj]);
            }
        }
    }

    return (

        <div className="w-full mx-auto pb-5">
            <div className="relative">
                <div className="flex items-center">
                    <h5 className="text-base font-semibold ml-2 w-5/12">{t('common:objects')}</h5>
                    <div className="w-7/12 flex justify-end">
                        <PrimaryButton
                            text={t('page:properties.createObject')}
                            size={'medium'}
                            startAdornment={'add'}
                            onClick={() => setShowCreateModal(true)}
                        />
                    </div>
                </div>
                {(propertyObjects && propertyObjects.data.length < 1 && !searchOperation) ? (
                    <div className="w-10 xl:w-8/12 max-w-2xl mx-auto bg-primary-blue-5 text-black rounded-md px-2 py-4 text-center">
                        <p className="text-base font-semibold">{t('page:properties.noObjectsFound')}...</p>
                    </div>
                ) : (
                    <>
                        <div className="sm:w-6/12 max-w-2xl mt-2 text-center sm:text-left">
                            <Input
                                type="search"
                                name="search_name"
                                placeholder={t('common:search')}
                                disableUnderline={true}
                                value={search}
                                className="w-full border rounded-md text-base md:text-sm px-2 pt-2.5 pb-1 mt-3 bg-white"
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                    debouncedSearch(e.target.value)
                                }}
                                endAdornment={
                                    <span
                                        className="material-symbols-outlined pb-1 text-gray-500 cursor-pointer"
                                        style={{ fontSize: '1.3rem' }}
                                        onClick={() => {
                                            if (search) {
                                                setSearch('')
                                                debouncedSearch('')
                                            }
                                        }}
                                    >
                                        {search ? 'close' : 'search'}
                                    </span>
                                }
                                size="small"
                            />
                        </div>
                        <div className="overflow-x-scroll max-h-[45vh]">
                            {propertyObjects && propertyObjects.data.length > 0 && !isFetching && (
                                <Table>
                                    <Table.Thead>
                                        <tr>
                                            <th scope="col" className="w-20 sticky top-0 bg-white z-10">
                                                <span className="sr-only">Object image</span>
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-xs py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[8rem] max-w-[8rem] sticky top-0 bg-white z-10"
                                                onClick={() => handleSort('name', sortOrder)}>
                                                {t('common:name')}
                                                {(sortBy === 'name') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </th>
                                            {properties && [...properties]
                                                .sort((a, b) => a.label.localeCompare(b.label))
                                                .map(property => {
                                                    return (
                                                        <th
                                                            key={property.id}
                                                            scope="col"
                                                            className="py-1 pl-2 md:py-2 pr-2 sm:table-cell cursor-pointer font-bold min-w-[8rem] max-w-[8rem] text-xs sticky top-0 bg-white z-10">
                                                            {property.label}
                                                        </th>
                                                    )
                                                })}
                                            {users && [...users]
                                                .sort((a, b) => a.name.localeCompare(b.name))
                                                .map(user => {
                                                    return (
                                                        <th
                                                            key={user.id}
                                                            scope="col"
                                                            className="py-1 pl-2 md:py-2 pr-2 sm:table-cell cursor-pointer font-bold min-w-[8rem] max-w-[8rem] text-xs sticky top-0 bg-white z-10">
                                                            {user.name}
                                                        </th>
                                                    )
                                                })}
                                            {logTemplates && [...logTemplates]
                                                .sort((a, b) => a.name.localeCompare(b.name))
                                                .map(logTemplate => {
                                                    return (
                                                        <th
                                                            key={logTemplate.id}
                                                            scope="col"
                                                            className="py-1 pl-2 md:py-2 pr-2 sm:table-cell cursor-pointer font-bold min-w-[8rem] max-w-[8rem] text-xs sticky top-0 bg-white z-10">
                                                            {logTemplate.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${logTemplate.name.split('_').join('')}`) : logTemplate.name.split('_').join(' ')}
                                                        </th>
                                                    )
                                                })}
                                            <th scope="col" className="sticky top-0 bg-white z-10">
                                                <span className="sr-only">Copy</span>
                                            </th>
                                            <th scope="col" className="sticky top-0 bg-white z-10">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                            <th scope="col" className="sticky top-0 bg-white z-10">
                                                <span className="sr-only">Delete</span>
                                            </th>
                                        </tr>
                                    </Table.Thead>
                                    <Table.Tbody>
                                        {propertyObjects.data.map((propertyObject, idx) => {
                                            return (
                                                <tr key={idx} className={`odd:hover:bg-primary-blue-10 even:hover:bg-gray-50 cursor-pointer text-xs text-black even:bg-white odd:bg-primary-blue-5`}>
                                                    <td scope="row" className="w-14 md:w-20 pl-2 capitalize py-1">
                                                        {propertyObject.image ? (
                                                            <div className="flex items-center w-8 h-8 rounded-full bg-white overflow-hidden">
                                                                <img
                                                                    src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${propertyObject.tenant_id}/${propertyObject.image}`}
                                                                    // onError={e => e.currentTarget.src = DefaultObject}
                                                                    alt="Profile picture"
                                                                    className="w-8 h-8 rounded-full object-cover" />
                                                            </div>
                                                        ) : (
                                                            <div className="flex items-center justify-center w-8 h-8 rounded-full border border-gray-100">
                                                                <span
                                                                    className="material-symbols-outlined"
                                                                    style={{ fontSize: '1.2rem' }}>
                                                                    meeting_room
                                                                </span>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td
                                                        className="py-2 pr-2 pl-1 sm:pl-2 text-xs capitalize font-semibold max-w-[5rem] min-w-[5rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                        onClick={handlePropertyObject}>
                                                        {propertyObject.name}
                                                    </td>
                                                    {properties && [...properties]
                                                        .sort((a, b) => a.label.localeCompare(b.label))
                                                        .map(property => {
                                                            return (
                                                                <td
                                                                    key={property.id}
                                                                    className="pl-4 text-xs capitalize font-semibold sm:font-normal max-w-[3rem] min-w-[3rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                                                    <Checkbox
                                                                        size={'sm'}
                                                                        disabled={propertyObject.property_id === property.id}
                                                                        checked={property.id === propertyObject.property_id}
                                                                        onChange={() => handleAccess(propertyObject, property.id)}
                                                                    />
                                                                </td>
                                                            )
                                                        })}
                                                    {users && propertyObjectUserAccessByIds && [...users]
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map(user => {
                                                            return (
                                                                <td
                                                                    key={user.id}
                                                                    className="pl-4 text-xs capitalize font-semibold sm:font-normal max-w-[3rem] min-w-[3rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                                                    <Checkbox
                                                                        size={'sm'}
                                                                        checked={propertyObjectUserAccessByIds.find(e => e.property_object_id === propertyObject.id && e.user_id === user.id) ? true : false}
                                                                        onChange={() => handleAccess(propertyObject, user.id)}
                                                                    />
                                                                </td>
                                                            )
                                                        })}
                                                    {logTemplates && propertyObjectLogAccessByIds && [...logTemplates]
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map(logTemplate => {
                                                            return (
                                                                <td
                                                                    key={logTemplate.id}
                                                                    className="pl-4 text-xs capitalize font-semibold sm:font-normal max-w-[3rem] min-w-[3rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                                                    <Checkbox
                                                                        size={'sm'}
                                                                        checked={propertyObjectLogAccessByIds.find(e => e.property_object_id === propertyObject.id && e.log_template_id === logTemplate.id) ? true : false}
                                                                        onChange={() => handleAccess(propertyObject, logTemplate.id)}
                                                                    />
                                                                </td>
                                                            )
                                                        })}
                                                    <td className="text-right">
                                                        <button
                                                            onClick={() => handleCopyPropertyObject(propertyObject)}
                                                            className="text-black material-symbols-outlined"
                                                            style={{ fontSize: '1.2rem' }}
                                                            title={t('common:duplicate')}
                                                        >content_copy</button>
                                                    </td>
                                                    <td className="pb-1 text-center px-2">
                                                        <button
                                                            onClick={() => handleEditPropertyObject(propertyObject)}
                                                            className="text-black material-symbols-outlined"
                                                            style={{ fontSize: '1.2rem' }}
                                                            title={t('common:edit')}
                                                        >edit_square</button>
                                                    </td>
                                                    <td className="text-left">
                                                        <button
                                                            onClick={() => handleDeletePropertyObject(propertyObject)}
                                                            className="material-symbols-outlined text-status-critical"
                                                            style={{ fontSize: '1.3rem' }}
                                                            title={t('common:delete')}
                                                        >delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </Table.Tbody>
                                </Table>
                            )}
                            {isFetching && (
                                <div className="mx-auto mb-5 mt-1">
                                    <Loader show={isFetching} size='small' />
                                </div>
                            )}

                            {!propertyObjects || propertyObjects.data.length < 1 && (
                                <div className="mt-5">
                                    <p className="text-sm ml-5 font-semibold text-black">{t('page:properties.noObjectsFound')}...</p>
                                </div>
                            )}
                        </div>
                    </>
                )}

                {propertyObjects?.meta && propertyObjects?.meta?.hasNextPage && (
                    <div className="flex justify-center py-2">
                        <Pagination
                            count={pageCount}
                            page={page}
                            defaultPage={1}
                            showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                            showLastButton={pageCount ? pageCount > 5 : false}
                            hidePrevButton={!hasPrevPage}
                            hideNextButton={!hasNextPage}
                            onChange={handlePagination}
                        />
                    </div>
                )}
            </div>

            <Modal isOpen={showCreateModal} close={() => setShowCreateModal(false)} title={`${t('page:properties.createObject')}`} size='sm' titleAlign='center'>
                <CreatePropertyObjectForm />
            </Modal>

            <Modal isOpen={showCopyModal} close={() => setShowCopyModal(false)} title={`${t('page:properties.createObject')} (${t('common:duplicate')})`} size='sm' titleAlign='center'>
                <CreatePropertyObjectForm propertyObject={selectedPropertyObject} />
            </Modal>

            <Modal isOpen={showModal} close={() => setShowModal(false)} title={`${selectedPropertyObject?.name}`} size='sm' titleAlign='center'>
                <div>
                    <div className="flex flex-wrap justify-center mx-auto mb-5">
                        <div className="w-10/12 sm:w-full">
                            <UpdatePropertyObjectForm propertyObject={selectedPropertyObject} />

                            <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                            <PropertyObjectLogAccess propertyObject={selectedPropertyObject} />

                            <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                            <PropertyObjectUserAccess propertyObject={selectedPropertyObject} />
                        </div>
                    </div>
                </div>
            </Modal>

            <ConfirmModal isOpen={showConfirmModal} close={() => setShowConfirmModal(false)} title={t('page:properties.deleteObject')} titleAlign='center' type='warning'>
                <div className="bg-red-200 rounded p-1">
                    <p className="text-xs font-semibold my-1 text-black">{t('page:properties.deleteObjectLogInfo')}</p>
                </div>
                <p className="text-lg font-semibold mt-3">{t('common:confirmDelete')} {selectedPropertyObject?.name ? selectedPropertyObject.name : 'the selected object'}?</p>
                <button
                    onClick={deletePropertyObjectConfirmed}
                    className="bg-status-critical hover:bg-status-critical-h py-2 px-6 mt-3 rounded mx-auto text-sm sm:text-base text-white font-semibold">{t('common:delete')}</button>
            </ConfirmModal>
        </div>
    )
}

export default PropertyObjectTableEdit;