import React, { useState } from 'react'
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLoginMutation } from '../../../../services/catalog/auth/auth.service';
import { useDispatch } from 'react-redux';
import { setAuthenticatedUser } from '../../../../redux/slices/auth.slice';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../atoms/loader/loader';

export type LoginFormType = {
    email: string;
    password: string;
}

type Props = {
    setForgotPassword: (val: boolean) => void;
    setEmail: (val: string) => void;
    setShowOtpModal: (flag: boolean) => void;
}

const LoginForm = ({
    setForgotPassword,
    setEmail,
    setShowOtpModal
}: Props) => {
    const { t } = useTranslation();

    const [login, { isLoading }] = useLoginMutation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [error, setError] = useState<string>('');

    const { control, handleSubmit, formState: { errors } } = useForm<LoginFormType>({
        defaultValues: {
            email: '',
            password: '',
        }
    });

    const onSubmit = async (formValue: { email: string, password: string }) => {

        const { email, password } = formValue;
        setError(''); // Reset error state before request

        try {
            const response = await login({ email, password }).unwrap();

            // Successful response means we either log in the user or verify their phone
            if (response.success) {
                // If access_token is returned, the user is authenticated, proceed to login
                if (response.access_token) {

                    const authStateObj = {
                        authUser: {
                            name: response.user.name,
                            email: response.user.email,
                            role: response.user.role,
                            user_id: response.user.user_id,
                        },
                        access_token: response.access_token
                    };

                    localStorage.setItem('user', authStateObj.authUser.name);
                    dispatch(setAuthenticatedUser(authStateObj));

                    navigate('/home');
                }
                // If access_token is not returned, the user needs to verify OTP code
                else {
                    if (response.email) {
                        setEmail(response.email);
                        setShowOtpModal(true);
                    }
                    else {
                        // FIX: Handle
                    }
                }
            }
        }
        catch (error: any) {
            if (error.data && error.data.message) {
                setError(t(`common:server.${error.data.message}`));
            }
            else {
                setError(t('common:form.loginFailed'))
            }
        }
    }

    return (
        <>
            <div className="mt-5 sm:mx-auto w-full">
                <form className="space-y-3" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div className="mt-0.5">
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: t('common:form.emailNotEmpty'),
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='email'
                                            placeholder={t('common:email')}
                                            className={`
                                                ${errors.email ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} 
                                                w-full rounded-md border-0 p-3 sm:p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
                                                focus:ring-2 focus:ring-indigo-600 focus:outline-none text-base sm:leading-6
                                                `}
                                        />
                                    </>
                                )}
                            />
                            {errors.email && <p className="text-status-critical text-xs my-1 ml-1">{errors.email.message}</p>}
                        </div>
                    </div>
                    <div>
                        <div className="mt-0.5">
                            <Controller
                                name="password"
                                control={control}
                                rules={{
                                    required: t('page:users.form.passwordNotEmpty'),
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='password'
                                            placeholder={t('common:password')}
                                            inputMode='email'
                                            className={`
                                                ${errors.password ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} 
                                                w-full rounded-md border-0 p-3 sm:p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
                                                focus:ring-2 focus:ring-indigo-600 focus:outline-none text-base sm:leading-6
                                                `}
                                        />
                                    </>
                                )}
                            />
                            {errors.password && <p className="text-status-critical text-xs my-1 ml-1">{errors.password.message}</p>}
                        </div>
                        <span
                            className="flex justify-end text-xs mt-1 text-primary-blue hover:text-primary-blue-h font-semibold cursor-pointer"
                            onClick={() => setForgotPassword(true)}
                        >
                            {t('page:login.forgotPassword')}
                        </span>
                    </div>
                    {error && (<p className="text-sm my-4 font-bold text-status-critical text-center">{error}</p>)}

                    {isLoading ? (
                        <Loader show={isLoading} size='small' />
                    ) : (
                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-primary-blue hover:bg-primary-blue-h px-3 p-2 text-sm font-bold leading-6 text-secondary-blue shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                {t('common:login')}
                            </button>
                        </div>
                    )}
                </form>
            </div>

        </>
    )
}

export default LoginForm;