export const SelectCustomStyles = {
    menuList: (provided: any, state: any) => ({
        ...provided,
        paddingBottom: '5px',
        
    }),
    noOptionsMessage: (provided: any) => ({
        ...provided,
        textTransform: 'none'
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        padding: '5px 5px 5px 5px',
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        borderRadius: '0.375rem',
        backgroundColor: state.isDisabled ? 'rgb(243 244 246)' : '',
        color: state.isDisabled && 'rgb(107 114 128)',
    }),
    placeholder: (provided: any) => ({
        ...provided,
        color: 'rgb(156 163 175);'
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'rgb(227 241 255)' : '',
        color: state.isFocused ? 'rgb(70 87 219)' : '',

    }),
    multiValue: (provided: any) => ({
        ...provided,
        backgroundColor: '#EDEFFB',
    }),
    multiValueLabel: (provided: any) => ({
        ...provided,
        color: '#111',
    }),
    multiValueRemove: (provided: any, state: any) => ({
        ...provided,
        color: 'black',
        ':hover': {
            color: '',
            backgroundColor: '#DADDF8'
        }
    })
}