import { useEffect, useState } from "react";
import { ErrorCheckboxType, FixedFieldProperties } from "../../../../molecules/create-custom-log";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../../atoms/checkbox";

export type CheckboxFieldType = FixedFieldProperties & {
    flag: boolean;
}

type CheckboxProps = {
    field: CheckboxFieldType;
    handleUpdateGeneratedField: (checkboxField: CheckboxFieldType) => void;
    handleDeleteGeneratedField: (id: number) => void;
    error: ErrorCheckboxType;
    logCount?: number;
    newTemplate: boolean;
}
const DynamicCheckbox = ({
    field,
    handleUpdateGeneratedField,
    handleDeleteGeneratedField,
    error,
    logCount,
    newTemplate,
}: CheckboxProps) => {
    const { t } = useTranslation();

    const [checkboxField, setCheckboxField] = useState<CheckboxFieldType>({
        type: field.type,
        id: field.id,
        fieldName: field.fieldName,
        flag: field.flag,
        isRequired: field.isRequired,
        isActive: field.isActive,
        existing_record: field.existing_record,
        existing_name: field.existing_name,
    });
    const [checkboxError, setCheckboxError] = useState<string>('')

    useEffect(() => {
        handleUpdateGeneratedField(checkboxField)

    }, [checkboxField, field])

    useEffect(() => {
        if (error.nameField && error.id === field.id) {
            setCheckboxError(error.nameField);
        }
    }, [error])

    const handleNameField = (value: string) => {
        if (!/^(?![0-9\-_])[a-zA-ZåäöÅÄÖ0-9_-\s]*$/.test(value)) {
            setCheckboxError(t('page:logs.form.fieldOnlyLetters'));
        }
        else if (value.length > 32) {
            setCheckboxError(t('page:logs.form.fieldMaxLength32'))
        }
        else {
            setCheckboxError('');
            setCheckboxField({ ...checkboxField, fieldName: value })
        }
    }

    return (
        <div className="flex flex-row items-center w-full mx-auto border p-2 rounded my-4">
            <div className="w-8/12 lg:w-full">
                <label className="block text-xs lg:text-sm font-semibold leading-6 text-gray-600" htmlFor="input_type">{t('page:logs.checkboxText')}</label>
                <input
                    type="text"
                    placeholder={t('common:text')}
                    required
                    name="field_name"
                    value={checkboxField.fieldName}
                    // onChange={(e) => setCheckboxField({ ...checkboxField, fieldName: e.target.value })}
                    onChange={(e) => handleNameField(e.target.value)}
                    className={`${checkboxError && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} w-11/12 lg:w-9/12 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 lg:text-sm lg:leading-6`}
                />
                {checkboxError && (<span className="flex text-xs text-status-critical my-1">{checkboxError}</span>)}
            </div>

            {/* Show active checkbox if existing template is being edited */}
            {!newTemplate && (
                <div className="w-3/12 lg:w-2/12 flex flex-col items-center border-l-2 justify-center h-14 mr-2 gap-y-1">
                    <label className="text-xs font-semibold text-gray-600" htmlFor="input_type">
                        {t('common:active')}
                        <span
                            className="material-symbols-outlined align-sub ml-0.5"
                            title={t('page:logs.activeBoxHelp')}
                            style={{ fontSize: '1rem' }}
                        >help</span>
                    </label>
                    <Checkbox
                        size={'sm'}
                        checked={checkboxField.isActive}
                        onChange={(e) => setCheckboxField({ ...checkboxField, isActive: e.target.checked })}
                    />
                </div>
            )}
            <div className="flex flex-row flex-1 justify-end">
                <button
                    className="material-symbols-outlined text-status-critical hover:text-red-600 disabled:text-zinc-300"
                    onClick={() => handleDeleteGeneratedField(field.id)}
                    disabled={((logCount && logCount > 0) && field.existing_record) ? true : false}
                >delete</button>
            </div>
        </div>
    )
}
export default DynamicCheckbox;