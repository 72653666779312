import { PageOptionsDto } from '../../dto/page-options.dto';
import { PageDto } from '../../dto/page.dto';
import { reAuthAPI } from '../../re-auth/re-auth';
import { CreatePropertyDto } from './dto/create-property.dto';
import { PropertyDto } from './dto/property.dto';
import { UpdatePropertyDto } from './dto/update-property.dto';

export const propertyAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Create new property for given tenant
        createProperty: builder.mutation<any, CreatePropertyDto>({
            query: (property) => ({
                url: '/properties',
                method: 'POST',
                body: property,
            }),
        }),
        // Get one property by id
        getProperty: builder.query<PropertyDto, string>({
            query: (propertyId) => ({
                url: '/properties/' + propertyId,
                method: 'GET',
            })
        }),
        // Update property
        updateProperty: builder.mutation<any, UpdatePropertyDto>({
            query: (property) => ({
                url: '/properties/' + property.id,
                method: 'PUT',
                body: property
            }),
            invalidatesTags: ['Properties']
        }),
        // Update property
        updateProperties: builder.mutation<any, UpdatePropertyDto[]>({
            query: (property) => ({
                url: '/properties',
                method: 'PUT',
                body: property
            }),
            invalidatesTags: ['Properties']
        }),
        // Delete property with given id
        deleteProperty: builder.mutation<any, { property_id: string }>({
            query: (propertyId) => ({
                url: '/properties',
                method: 'DELETE',
                body: propertyId
            }),
            invalidatesTags: ['Properties', 'PropertyBuilding', 'PropertyObject']
        }),
        // Get number of properties for given tenant
        getPropertiesCount: builder.query<number, void>({
            query: () => ({
                url: '/properties/count/all',
                method: 'GET',
            }),
            providesTags: ['Properties']
        }),
        // Get properties paginated
        getAllProperties: builder.query<PageDto<PropertyDto>, PageOptionsDto>({
            query: (options) => {
                const queryParams = [];
                if (options.page !== undefined) queryParams.push(`page=${options.page}`);
                if (options.take !== undefined) queryParams.push(`take=${options.take}`);
                if (options.order !== undefined) queryParams.push(`order=${options.order}`);
                if (options.search !== undefined && options.search.length > 0) queryParams.push(`search=${options.search}`);
                if (options.sortBy !== undefined) queryParams.push(`sort_by=${options.sortBy}`)

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    url: `/properties${queryString}`,
                    method: 'GET',
                }
            },
            providesTags: ['Properties'],
        }),
        // Get properties by given ids 
        getPropertiesByIds: builder.query<PropertyDto[], string[]>({
            query: (propertyIds) => ({
                url: '/properties/ids?ids=' + propertyIds,
                method: 'GET',
            }),
            providesTags: ['Properties']
        }),
    })
})

export const {
    useCreatePropertyMutation,
    useGetPropertyQuery,
    useGetAllPropertiesQuery,
    useUpdatePropertyMutation,
    useUpdatePropertiesMutation,
    useDeletePropertyMutation,
    useGetPropertiesCountQuery,
    useGetPropertiesByIdsQuery,
} = propertyAPI;