import React, { useCallback, useEffect, useState } from 'react'
import { Grow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ContractorLogAccessDto } from '../../../../../services/tenant/contractor-log-user-access/dto/contractor-log-access.dto';
import { useGetPropertyOwnerIdsByUserIdQuery } from '../../../../../services/tenant/contractor-log-user-access/contractor-log-user-access.service';
import { useAppSelector } from '../../../../../redux/hooks';
import { getCurrentUser } from '../../../../../redux/slices/user.slice';
import ExternalCompaniesContractorSelect from '../external-companies-contractor-select';
import { FriendshipTenantDto } from '../../../../../services/catalog/friendship/dto/friendship-tenant.dto';
import PropertyLogsContractorTable from '../property-logs-contractor-table';
import { useGetLogTemplateByIdContractorQuery } from '../../../../../services/external/contractor/contractor.service';
import { DisplayNameType } from '../../../log-components/log-structure/log-entries';
import LogEntriesContractor from '../log-entries-contractor';
import { LogTemplateDto } from '../../../../../services/tenant/log-template/dto/log-template.dto';

type SelectTypeCompany = {
    label: string;
    value: string;
    data: FriendshipTenantDto | null;
}

type Props = {
    selectedTenantLog?: ContractorLogAccessDto | null;
    setSelectedTenantLog: (val: ContractorLogAccessDto | null) => void;
    displayName?: DisplayNameType;
};

const ExternalLogsContractorUser = ({
    selectedTenantLog,
    setSelectedTenantLog,
    displayName,
}: Props) => {

    const { t } = useTranslation();
    const { currentUser } = useAppSelector(getCurrentUser);

    const [skipTokenUserId, setSkipTokenUserId] = useState<any>(skipToken);
    // Get propertyOwnerIds by userId
    const { data: propertyOwnerIds } = useGetPropertyOwnerIdsByUserIdQuery(skipTokenUserId);

    const [skipTokenTenantIdLogTemplateId, setSkipTokenTenantIdLogTemplateId] = useState<any>(skipToken);
    const { data: logTemplate } = useGetLogTemplateByIdContractorQuery(skipTokenTenantIdLogTemplateId);

    const [selectedLogTemplate, setSelectedLogTemplate] = useState<LogTemplateDto | null>();

    useEffect(() => {
        if (currentUser) {
            setSkipTokenUserId(currentUser.id);
        }
    }, [])

    useEffect(() => {
        if (selectedTenantLog) {
            setSkipTokenTenantIdLogTemplateId({
                tenantId: selectedTenantLog.tenant_id_property_owner,
                logTemplateId: selectedTenantLog.log_template_id
            })
        }
    }, [selectedTenantLog])

    const [selectedCompany, setSelectedCompany] = useState<FriendshipTenantDto | undefined>(undefined);

    const handleSelectedCompany = useCallback((selectedCompany: SelectTypeCompany) => {
        if (selectedCompany && selectedCompany.data) {
            setSelectedCompany(selectedCompany.data);
        }
        else {
            setSelectedCompany(undefined)
        }
    }, [setSelectedCompany]);

    useEffect(() => {
        if (logTemplate && logTemplate.id) {
            setSelectedLogTemplate(logTemplate);
        }
        else {
            setSelectedLogTemplate(null);
        }

    }, [logTemplate])

    return (
        <>
            { !selectedTenantLog && (
                <>
                    {propertyOwnerIds && (
                        <div className="w-10/12 sm:w-8/12 md:w-6/12 xl:w-4/12 mx-auto sm:mx-0 mb-2">
                            <p className="text-sm p-1">{t('page:logs.selectCompanyToDisplayLogs')}</p>
                            <ExternalCompaniesContractorSelect handleSelectedCompany={handleSelectedCompany} companyIds={propertyOwnerIds} />
                        </div>
                    )}

                    {selectedCompany && (
                        <PropertyLogsContractorTable
                            selectedCompany={selectedCompany}
                            setSelectedTenantLog={setSelectedTenantLog}
                            selectedTenantLog={selectedTenantLog}
                            tenantIdPropertyOwner={selectedCompany.tenant_id}
                        />
                    )}
                </>
            )}

            {(selectedTenantLog && selectedLogTemplate) && (
                <LogEntriesContractor
                    selectedTenantLog={selectedTenantLog}
                    setSelectedTenantLog={setSelectedTenantLog}
                    logTemplate={selectedLogTemplate}
                    displayName={displayName}
                />
            )}
        </>
    )
}

export default ExternalLogsContractorUser;