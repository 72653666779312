import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { getAuthenticatedUser, setAuthenticatedUser } from '../../../../redux/slices/auth.slice';
import { NotificationManager } from 'react-notifications';
import { Link, useNavigate } from 'react-router-dom';
import { setShowLoader } from '../../../../redux/slices/general.slice';
import Loader from '../../../atoms/loader/loader';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { getCurrentUser, setCurrentUser } from '../../../../redux/slices/user.slice';
import { getTenant, setTenant } from '../../../../redux/slices/tenant.slice';
import OTPVerificationForm from '../../../organisms/forms/auth/otp-verification-form';
import { useResendEmailVerificationMutation } from '../../../../services/management/re-auth/management.service';
import ConfirmEmail from '../../../organisms/auth/confirm-email';
import { Grow, responsiveFontSizes } from '@mui/material';
import { useGetPropertiesCountQuery } from '../../../../services/tenant/property/property.service';
import { useGetUsersCountQuery } from '../../../../services/tenant/user/user.service';
import { useGetLogsCountByUserIdQuery, useGetLogsCountQuery } from '../../../../services/tenant/log-entries/log.service';
import { useTranslation } from 'react-i18next';
import { Roles } from '../../../../services/enum/roles';

type Props = {

}

const HomePage = ({ }: Props) => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { authUser, access_token } = useAppSelector(getAuthenticatedUser);
    const { currentUser } = useAppSelector(getCurrentUser);
    const { tenant } = useAppSelector(getTenant);

    // Don't make below requests unless current user is admin
    const [skipAdminRequests, setSkipAdminRequests] = useState<any>(skipToken);
    const { data: propertiesCount } = useGetPropertiesCountQuery(skipAdminRequests);
    const { data: usersCount } = useGetUsersCountQuery(skipAdminRequests);
    const { data: logsCount } = useGetLogsCountQuery(skipAdminRequests);

    const [skipUserId, setSkipUserId] = useState<any>(skipToken);
    const { data: userLogCount } = useGetLogsCountByUserIdQuery(skipUserId);


    // Handle skip flag for admin requests
    useEffect(() => {
        if (currentUser && (currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin)) {
            setSkipAdminRequests(true);
        }
    }, [currentUser])

    // Handle skip flag to fetch logs created by current user, if user is not admin
    useEffect(() => {
        if ((currentUser && currentUser.id && (currentUser.role === Roles.None || currentUser.role === Roles.User))) {
            setSkipUserId(currentUser.id);
        }
    })

    return (
        <Grow in={currentUser.id ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
            <div className="text-black text-sm">
                {currentUser.id ? (
                    <>
                        {/* EMAIL NOT VERIFIED */}
                        {!currentUser.email_verified && (
                            <>
                                <Grow in={currentUser.id ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                                    <div>
                                        <ConfirmEmail email={currentUser.email} name={currentUser.name} user_id={currentUser.id} type='user' />
                                    </div>
                                </Grow>
                            </>
                        )}

                        <h1 className="text-3xl px-1 py-5 font-extrabold">Startsida</h1>

                        <div className="flex flex-col gap-y-5">
                            {/* <div className="flex flex-row items-center text-xs">
                            LOGS DUE INTERVAL DATE
                            <div className="w-1/2 p-2 rounded-md">
                                <div className="px-1">
                                    <h4 className="text-lg font-bold">Sena loggar</h4>
                                </div>
                                <div className="bg-primary-blue-5 p-2 rounded-md">
                                    <table className="table w-full">
                                        <thead>
                                            <tr className="text-left">
                                                <th scope="col" className="">Namn</th>
                                                <th scope="col" className="">Skulle utförts</th>
                                                <th scope="col" className="">Dagar sen</th>
                                                <th scope="col" className="">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key={0} className="border-b">
                                                <td scope="row" className="py-2">Brandlogg - Granen</td>
                                                <td>2024-01-03</td>
                                                <td>14</td>
                                                <td className="text-left pl-2">
                                                    <span className="material-symbols-outlined bg-status-critical h-3 w-3 rounded-full"> </span>
                                                </td>

                                            </tr>
                                            <tr key={1} className="border-b">
                                                <td className="py-2">Kameralogg - Tallen</td>
                                                <td>2024-02-12</td>
                                                <td>11</td>
                                                <td className="text-left pl-2">
                                                    <span className="material-symbols-outlined bg-status-critical h-3 w-3 rounded-full"> </span>
                                                </td>
                                            </tr>
                                            <tr key={2} className="">
                                                <td className="py-2">Städlogg - Boken</td>
                                                <td>2024-03-17</td>
                                                <td>3</td>
                                                <td className="text-left pl-2">
                                                    <span className="material-symbols-outlined bg-orange-200 h-3 w-3 rounded-full"> </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            VISA KOMMANDE LOGGAR 
                            <div className="w-1/2">
                                <div className="px-1">
                                    <h4 className="text-lg font-bold">Kommande loggar</h4>
                                </div>
                                <div className="bg-primary-blue-5 p-2 rounded-md">
                                    <table className="table w-full">
                                        <thead>
                                            <tr className="text-left">
                                                <th scope="col" className="">Namn</th>
                                                <th scope="col" className="">Ska utföras</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key={0} className="border-b">
                                                <td scope="row" className="py-2">Brandlogg - Aspen</td>
                                                <td>2024-01-03</td>

                                            </tr>
                                            <tr key={1} className="border-b">
                                                <td className="py-2">Kameralogg - Eken</td>
                                                <td>2024-02-12</td>
                                            </tr>
                                            <tr key={2} className="">
                                                <td className="py-2">Städlogg - Björken</td>
                                                <td>2024-03-17</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> */}

                            {/* OM ROLL = USER, VISA PRIVAT DATA?  */}

                            {/* STATISTICS  */}
                            <div className="w-full flex flex-col gap-y-2 text-xs">
                                <div className="px-1">
                                    <h4 className="text-lg font-bold">Översikt</h4>
                                    <p className="text-xs">En översikt av företagets resurser</p>
                                </div>
                                <div className="flex flex-row gap-x-4 gap-y-4 md:gap-y-0 flex-wrap md:flex-nowrap md:justify-evenly ">
                                    <div className="flex flex-col gap-y-2 w-full sm:w-5/12 md:w-1/3 bg-primary-blue-5 p-4 rounded-md">
                                        <div className="flex items-center justify-between">
                                            <span className="material-symbols-outlined p-1" style={{ fontSize: '1.3rem' }}>note_stack</span>
                                            <span className="bg-white p-2 rounded-md">
                                                {t('common:developmentShort')} {t('common:during')} 48 {t('common:hourShort')}.
                                            </span>
                                        </div>
                                        <div className="">
                                            <div className="flex justify-between bg-primary-blue-10 p-2 rounded-md">
                                                <span className="">{t('page:home.newLogs')}</span>
                                                <span className="font-bold">10 st</span>
                                            </div>
                                            <div className="flex justify-between p-2 rounded-md">
                                                <span className="">{t('page:home.totalLogs')}</span>
                                                <span className="font-bold">173 st</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-y-2 w-full sm:w-5/12 md:w-1/3 bg-primary-blue-5 p-4 rounded-md">
                                        <div className="flex items-center justify-between">
                                            <span className="material-symbols-outlined p-1" style={{ fontSize: '1.3rem' }}>home_work</span>
                                            <span className="bg-white p-2 rounded-md">
                                                +23% {t('common:during')} 12 {t('common:monthsLower')}.
                                            </span>
                                        </div>
                                        <div className="">
                                            <div className="flex justify-between bg-primary-blue-10 p-2 rounded-md">
                                                <span className="">{t('common:properties')}</span>
                                                <span className="font-bold">10 st</span>
                                            </div>
                                            <div className="flex justify-between p-2 rounded-md">
                                                <span className="">{t('common:buildings')}</span>
                                                <span className="font-bold">5 st</span>
                                            </div>
                                            <div className="flex justify-between bg-primary-blue-10 p-2 rounded-md">
                                                <span className="">{t('common:objects')}</span>
                                                <span className="font-bold">2 st</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-y-2 w-full sm:w-5/12 md:w-1/3 bg-primary-blue-5 p-4 rounded-md">
                                        <div className="flex items-center justify-between">
                                            <span className="material-symbols-outlined p-1" style={{ fontSize: '1.3rem' }}>group</span>
                                            <span className="bg-white p-2 rounded-md">
                                                {t('common:developmentShort')} {t('common:during')} 1 {t('common:monthLower')}.
                                            </span>
                                        </div>
                                        <div className="">
                                            <div className="flex justify-between bg-primary-blue-10 p-2 rounded-md">
                                                <span className="">{t('page:home.newUsers')}</span>
                                                <span className="font-bold">4 st</span>
                                            </div>
                                            <div className="flex justify-between p-2 rounded-md">
                                                <span className="">{t('page:home.totalUsers')}</span>
                                                <span className="font-bold">17 st</span>
                                            </div>
                                            <div className="flex justify-between bg-primary-blue-10 p-2 rounded-md">
                                                <span className="">{t('page:home.deletedUsers')}</span>
                                                <span className="font-bold">-</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* SHORTCUTS  */}
                            <div className="flex flex-col gap-y-2">
                                <div className="w-full sm:w-10/12 md:w-8/12 flex flex-row flex-grow items-center">
                                    <div className="py-3 px-1">
                                        <h4 className="text-lg font-bold">{t('common:shortcuts')}</h4>
                                        <p className="text-xs">{t('page:home:shortcutsInfo')}</p>
                                    </div>
                                    <div className="h-full flex flex-row flex-1 justify-end items-end gap-x-2">
                                        <button className="bg-primary-blue-5 p-2 rounded-md text-xs">
                                            {t('common:arrange')}
                                        </button>
                                        <button className="material-symbols-outlined bg-primary-blue text-secondary-blue p-1.5 rounded-md" style={{ fontSize: '1.3rem' }}>
                                            add
                                        </button>
                                    </div>
                                </div>
                                <div className="w-full sm:w-10/12 md:w-8/12 flex flex-row gap-x-2 gap-y-2 flex-wrap text-xs">
                                    <button className="bg-primary-blue hover:bg-primary-blue-h py-2 px-4 rounded-md text-secondary-blue">
                                        <Link to="/logs">{t('common:logs')}</Link>
                                    </button>
                                    {(currentUser.role === Roles.Superadmin || currentUser.role === Roles.Admin) && (
                                        <>
                                            <button className="bg-secondary-blue py-2 px-4 rounded-md text-primary-blue">
                                                <Link to="/rights">{t('common:rights')}</Link>
                                            </button>
                                            <button className="bg-secondary-blue py-2 px-4 rounded-md text-primary-blue">
                                                <Link to="/users">{t('common:users')}</Link>
                                            </button>
                                            <button className="bg-secondary-blue py-2 px-4 rounded-md text-primary-blue">
                                                <Link to="/properties">{t('common:properties')}</Link>
                                            </button>
                                            <button className="bg-secondary-blue py-2 px-4 rounded-md text-primary-blue">
                                                <Link to="/properties/buildings">{t('common:buildings')}</Link>
                                            </button>
                                            <button className="bg-secondary-blue py-2 px-4 rounded-md text-primary-blue">
                                                <Link to="/properties/objects">{t('common:objects')}</Link>
                                            </button>
                                            <button className="bg-secondary-blue py-2 px-4 rounded-md text-primary-blue">
                                                <Link to="/connections">{t('common:connections')}</Link>
                                            </button>
                                        </>
                                    )}
                                    {currentUser.role === Roles.User && (
                                        <button className="bg-secondary-blue py-2 px-4 rounded-md text-primary-blue">
                                            <Link to="/profile">{t('common:settings')}</Link>
                                        </button>
                                    )}
                                </div>
                            </div>

                            {/* TODO  */}
                            <div className="flex flex-col gap-y-2">
                                <div className="w-full sm:w-10/12 md:w-8/12 flex flex-row flex-grow items-center">
                                    <div className="py-3 px-1">
                                        <h4 className="text-lg font-bold">Att göra</h4>
                                        <p className="text-xs">Din privata att-göra lista</p>
                                    </div>
                                    <div className="h-full flex flex-row flex-1 justify-end items-end gap-x-2">
                                        <button className="material-symbols-outlined bg-primary-blue text-secondary-blue p-1.5 rounded-md" style={{ fontSize: '1.3rem' }}>
                                            add
                                        </button>
                                    </div>
                                </div>
                                <div className="w-full sm:w-10/12 md:w-8/12 p-2 rounded-md bg-primary-blue-5 text-xs">
                                    <table className="table w-full">
                                        <thead>
                                            <tr className="text-left">
                                                <th scope="col" className="">#</th>
                                                <th scope="col" className="">Beskrivning</th>
                                                <th scope="col" className="">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key={0} className="border-b">
                                                <td scope="row" className="py-2">1</td>
                                                <td>Skapa nya fastigheter</td>
                                                <td className="text-left pl-2">
                                                    <span className="material-symbols-outlined bg-orange-200 h-3 w-3 rounded-full"> </span>
                                                </td>
                                            </tr>
                                            <tr key={1} className="border-b">
                                                <td className="py-2">2</td>
                                                <td>Skapa kontakt med Företag AB</td>
                                                <td className="text-left pl-2">
                                                    <span className="material-symbols-outlined bg-status-ok h-3 w-3 rounded-full"> </span>
                                                </td>
                                            </tr>
                                            <tr key={2} className="">
                                                <td className="py-2">3</td>
                                                <td>Delegera tillgång till loggar</td>
                                                <td className="text-left pl-2">
                                                    <span className="material-symbols-outlined bg-status-ok h-3 w-3 rounded-full"> </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>






                        {/* Admin View */}
                        {/* {(currentUser.email_verified && (currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin)) && (
                        <>
                            <Grow in={currentUser.email_verified ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                                <div className="w-full xl:w-10/12 mx-auto">

                                    <div className="flex flex-col items-center sm:justify-center sm:flex-row w-11/12 mx-auto sm:w-10/12 md:items-center gap-y-3 gap-x-3 sm:gap-x-5 md:gap-x-4 mt-5 mb-5 sm:mb-14">
                                        <div className="bg-primary-blue w-11/12 sm:w-4/12 md:w-full rounded-2xl py-3 px-8 md:px-4 flex flex-col h-26 sm:h-36 justify-center gap-y-1">
                                            <Link to="/properties">
                                                <p className="text-lg text-white mb-1">{t('properties')}</p>
                                            </Link>
                                            <div className="flex justify-between">
                                                <span className="material-symbols-outlined text-white" style={{ fontSize: '1.8rem' }}>home_work</span>
                                                <span className="text-white text-xl font-bold align-top float-right">{propertiesCount ? propertiesCount : '-'}</span>
                                            </div>
                                        </div>
                                        <div className="bg-primary-blue w-11/12 sm:w-4/12 md:w-full rounded-2xl py-3 px-8 md:px-4 flex flex-col h-26 sm:h-36 justify-center gap-y-1">
                                            <Link to="/users">
                                                <p className="text-lg text-white mb-1">{t('users')}</p>
                                            </Link>
                                            <div className="flex justify-between">
                                                <span className="material-symbols-outlined text-white" style={{ fontSize: '1.8rem' }}>group</span>
                                                <span className="text-white text-xl font-bold align-top float-right">{usersCount ? usersCount : '-'}</span>
                                            </div>
                                        </div>
                                        <div className="bg-primary-blue w-11/12 sm:w-4/12 md:w-full rounded-2xl py-3 px-8 md:px-4 flex flex-col h-26 sm:h-36 justify-center gap-y-1">
                                            <Link to="/logs">
                                                <p className="text-lg text-white mb-1">{t('logs')}</p>
                                            </Link>
                                            <div className="flex justify-between">
                                                <span className="material-symbols-outlined text-white" style={{ fontSize: '1.8rem' }}>note_stack</span>
                                                <span className="text-white text-xl font-bold align-top float-right">{logsCount ? logsCount : '-'}</span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="w-10/12 mx-auto grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5">
                                        <div className="bg-gray-100 h-32 rounded-2xl drop-shadow">
                                            <div className="flex gap-x-1 flex-wrap justify-center mt-2">
                                                <span className="material-symbols-outlined">apartment</span>
                                                <Link to="/properties">
                                                    <p className="">{t('properties')}</p>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="bg-gray-100  h-32 rounded-2xl drop-shadow">
                                            <div className="flex gap-x-1 flex-wrap justify-center mt-2">
                                                <span className="material-symbols-outlined">group</span>
                                                <Link to="/users">
                                                    <p className="">{t('users')}</p>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="bg-gray-100 h-32 rounded-2xl drop-shadow">
                                            <div className="flex gap-x-1 flex-wrap justify-center mt-2">
                                                <span className="material-symbols-outlined">note_stack</span>
                                                <Link to="/logs">
                                                    <p className="">{t('logs')}</p>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="bg-gray-100  h-32 rounded-2xl drop-shadow">
                                            <div className="flex gap-x-1 flex-wrap sm:flex-nowrap justify-center mt-2">
                                                <span className="material-symbols-outlined">person</span>
                                                <Link to="/profile">
                                                    <p className="">{t('profile')}</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grow>
                        </>
                    )} */}

                        {/* User View when role is set */}
                        {/* {(currentUser.email_verified && currentUser.role === Roles.User) && (
                        <Grow in={currentUser.email_verified ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                            <div className="w-full flex flex-col items-center mt-5">
                                <div className="flex flex-col justify-center items-center flex-wrap bg-primary-pink px-5 w-8/12 md:w-6/12 h-32 rounded-xl text-white shadow-md">
                                    <h4 className="text-lg font-bold">{t('common:hello')} {currentUser.name}!</h4>
                                    <p className="text-sm font-semibold text-center">{t('common:clickToContinue')}</p>
                                </div>

                                <div className="w-full mt-10 flex flex-col items-center text-center gap-y-4">
                                    <Link to="/logs" className="w-full flex justify-center">
                                        <div className="flex justify-center items-center bg-primary-blue text-white w-8/12 sm:w-1/4 md:w-6/12 h-16 rounded-xl drop-md">
                                            <div className="flex gap-x-2">
                                                <span className="material-symbols-outlined" style={{ fontSize: '1.8rem' }}>note_stack</span>
                                                <p className="text-xl">{t('common:logs')}</p>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/profile" className="w-full flex justify-center">
                                        <div className="flex justify-center items-center bg-primary-blue text-white w-8/12 sm:w-1/4 md:w-6/12 h-16 rounded-xl shadow-md">
                                            <div className="flex gap-x-2">
                                                <span className="material-symbols-outlined" style={{ fontSize: '1.8rem' }}>settings</span>
                                                <p className="text-xl">{t('common:settings')}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                            </div>
                        </Grow>
                    )} */}

                        {/* User View when role is NOT set */}
                        {/* {(currentUser.email_verified && currentUser.role === Roles.None) && (
                        <Grow in={currentUser.email_verified ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                            <div className="w-full flex flex-col items-center mt-5">
                                <div className="flex flex-col justify-center items-center flex-wrap bg-primary-blue-5 px-5 w-8/12 md:w-6/12 h-32 rounded-lg shadow-md">
                                    <h4 className="text-lg text-primary-blue font-bold">{t('common:hello')} {currentUser.name}!</h4>
                                    <p className="text-sm font-semibold text-center mt-2 text-primary-blue">{t('common:userNoRoleYet')}</p>
                                </div>

                                <div className="w-full mt-10 flex flex-col items-center text-center gap-y-4">
                                    <Link to="/profile" className="w-full flex justify-center">
                                        <div className="flex justify-center items-center bg-primary-blue text-white w-8/12 sm:w-1/4 md:w-6/12 h-16 rounded-xl shadow-md">
                                            <div className="flex gap-x-2">
                                                <span className="material-symbols-outlined" style={{ fontSize: '1.8rem' }}>person</span>
                                                <p className="text-xl">{t('profile')}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                            </div>
                        </Grow>
                    )} */}
                    </>
                ) : (
                    <Loader show={!currentUser.id} size='large' top='xlarge' />
                )
                }
            </div>
        </Grow>
    )
}

export default HomePage;

