
import { Dialog } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";

type Props = {
    isOpen: boolean;
    close: () => void;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    type?: 'warning' | 'info' | 'auth' | 'success';
    titleAlign?: 'left' | 'center' | 'right';
    titleSize?: 'xs' | 'sm' | 'md' | 'lg';
    title: string;
    children: React.ReactNode;
    textAlign?: 'left' | 'center' | 'right';
}

const ConfirmModal = ({
    isOpen,
    close,
    size = 'md',
    type,
    titleAlign = 'left',
    titleSize = 'md',
    title,
    children,
    textAlign = 'center',
}: Props) => {
    // Map material symbol based on passed type
    let symbol = '';
    switch (type) {
        case 'warning':
            symbol = 'warning';
            break;
        case 'info':
            symbol = 'warning';
            break;
        case 'auth':
            symbol = 'lock';
            break;
        case 'success':
            symbol = 'check_circle';
            break;
        default:
            symbol = ''
            break;
    };

    return isOpen ? (
        <Dialog fullWidth className={clsx("relative z-[75] mx-auto", {
            "max-w-sm": size === "xs",
            "max-w-md": size === "sm",
            "max-w-xl": size === "md",
            "max-w-3xl": size === "lg",
        })} open={isOpen} onClose={close}>
            <div className={clsx(
                "flex flex-col w-full h-full justify-center transform overflow-auto p-10 text-left shadow-xl transition-all max-h-[45vh] min-h-[20vh] rounded-lg", {
            })}>
                <button
                    className="material-symbols-outlined absolute right-5 top-5 sm:top-15 bg-primary-blue-10 hover:bg-primary-blue-15 p-1 rounded-md"
                    onClick={close}
                    style={{ fontSize: '1.3rem' }}
                >close</button>
                {type === 'warning' && (<span className="material-symbols-outlined mt-[-5px] text-center text-status-critical" style={{ fontSize: '3rem' }}>{symbol}</span>)}
                {type === 'info' && (<span className="material-symbols-outlined mt-[-5px] text-center text-status-warning" style={{ fontSize: '3rem' }}>{symbol}</span>)}
                {type === 'auth' && (<span className="material-symbols-outlined mt-[-5px] text-center text-status-critical" style={{ fontSize: '3rem' }}>{symbol}</span>)}
                {type === 'success' && (<span className="material-symbols-outlined mt-[-5px] text-center text-status-critical" style={{ fontSize: '3rem' }}>{symbol}</span>)}
                {title && (
                    <h3 className={clsx("font-bold mt-2", {
                        "text-left": titleAlign === 'left',
                        "text-center": titleAlign === 'center',
                        "text-right": titleAlign === 'right',

                        "text-base": titleSize === 'xs',
                        "text-lg": titleSize === 'sm',
                        "text-xl": titleSize === 'md',
                        "text-2xl": titleSize === 'lg',
                    })}>{title}</h3>
                )}
                <div className={`
                ${textAlign === 'left' && 'text-left'}
                ${textAlign === 'center' && 'text-center'}
                ${textAlign === 'right' && 'text-right'}
                w-full mt-3`}>
                    {children}
                </div>
            </div>
        </Dialog>
    ) : (null)
}

export default ConfirmModal;