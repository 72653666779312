import { PageOptionsDto } from '../../dto/page-options.dto';
import { PageDto } from '../../dto/page.dto';
import { reAuthAPI } from '../../re-auth/re-auth';
import { CreatePropertyBuildingDto } from './dto/create-property-building.dto';
import { PropertyBuildingDto } from './dto/property-building.dto';
import { UpdatePropertyBuildingDto } from './dto/update-property-building.dto';

export const propertyBuildingAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Get all property buildings for given tenant
        getAllPropertyBuildings: builder.query<PropertyBuildingDto[], void>({
            query: () => ({
                url: '/property-buildings',
                method: 'GET',
            }),
            providesTags: ['PropertyBuilding']
        }),
        // Get one property building by id
        getPropertyBuilding: builder.query<PropertyBuildingDto, string>({
            query: (propertyBuildingId) => ({
                url: '/property-buildings/' + propertyBuildingId,
                method: 'GET',
            }),
            providesTags: ['PropertyBuilding']
        }),
        // Create new property building for given tenant
        createPropertyBuilding: builder.mutation<any, CreatePropertyBuildingDto>({
            query: (propertyBuilding) => ({
                url: '/property-buildings',
                method: 'POST',
                body: propertyBuilding,
            }),
        }),
        // Update one property building
        updatePropertyBuilding: builder.mutation<any, UpdatePropertyBuildingDto>({
            query: (propertyBuilding) => ({
                url: '/property-buildings/' + propertyBuilding.id,
                method: 'PUT',
                body: propertyBuilding
            }),
        }),
        // Update many property buildings
        updateManyPropertyBuildings: builder.mutation<any, UpdatePropertyBuildingDto[]>({
            query: (propertyBuilding) => ({
                url: '/property-buildings',
                method: 'PUT',
                body: propertyBuilding
            }),
            invalidatesTags: ['PropertyBuilding']
        }),
        // Delete building with given id
        deletePropertyBuilding: builder.mutation<any, { property_building_id : string }>({
            query: (propertyBuildingId) => ({
                url: '/property-buildings',
                method: 'DELETE',
                body: propertyBuildingId,
            }),
            invalidatesTags: ['PropertyBuilding', 'PropertyObject']
        }),
        // Get property buildings by property id
        getAllPropertyBuildingsByPropertyId: builder.query<PageDto<PropertyBuildingDto>, { propertyId: string, pageOptionsDto: PageOptionsDto}>({
            query: (options) => {
                const queryParams = [];
                if (options.pageOptionsDto.page !== undefined) queryParams.push(`page=${options.pageOptionsDto.page}`);
                if (options.pageOptionsDto.take !== undefined) queryParams.push(`take=${options.pageOptionsDto.take}`);
                if (options.pageOptionsDto.order !== undefined) queryParams.push(`order=${options.pageOptionsDto.order}`);
                if (options.pageOptionsDto.search !== undefined && options.pageOptionsDto.search.length > 0) queryParams.push(`search=${options.pageOptionsDto.search}`);
                if (options.pageOptionsDto.sortBy !== undefined) queryParams.push(`sort_by=${options.pageOptionsDto.sortBy}`)

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    url: `/property-buildings/property/${options.propertyId}${queryString}`,
                    method: 'GET',
                }
            },
            providesTags: ['PropertyBuilding']
        }),
        // Get property buildings by joinig on property_id from PropertyLogAccess relation where logTemplateId
        getAllPropertyBuildingsByPropertyLogAccess: builder.query<PropertyBuildingDto[], string>({
            query: (logTemplateId) => ({
                url: '/property-buildings/property-log-access/' + logTemplateId,
                method: 'GET',
            }),
            providesTags: ['PropertyBuilding', 'PropertyLogAccess']
        }),
         // Get property buildings by user id joined with property user access table
         getAllPropertyBuildingsByUserId: builder.query<PropertyBuildingDto[], string>({
            query: (userId) => ({
                url: '/property-buildings/user/' + userId,
                method: 'GET',
            }),
            providesTags: ['PropertyBuilding']
        }),
        // Get property buildings paginated
        getPropertyBuildings: builder.query<PageDto<PropertyBuildingDto>, PageOptionsDto>({
            query: (options) => {
                const queryParams = [];
                if (options.page !== undefined) queryParams.push(`page=${options.page}`);
                if (options.take !== undefined) queryParams.push(`take=${options.take}`);
                if (options.order !== undefined) queryParams.push(`order=${options.order}`);
                if (options.search !== undefined && options.search.length > 0) queryParams.push(`search=${options.search}`);
                if (options.sortBy !== undefined) queryParams.push(`sort_by=${options.sortBy}`)

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    url: `/property-buildings/${queryString}`,
                    method: 'GET',
                }
            },
            providesTags: ['PropertyBuilding'],
        }),
        // Get property buildings by user id paginated
        getPropertyBuildingsByUserId: builder.query<PageDto<PropertyBuildingDto>, { pageOptionsDto: PageOptionsDto, userId: string }>({
            query: (params) => {
                const queryParams = [];
                if (params.pageOptionsDto.page !== undefined) queryParams.push(`page=${params.pageOptionsDto.page}`);
                if (params.pageOptionsDto.take !== undefined) queryParams.push(`take=${params.pageOptionsDto.take}`);
                if (params.pageOptionsDto.order !== undefined) queryParams.push(`order=${params.pageOptionsDto.order}`);
                if (params.pageOptionsDto.search !== undefined && params.pageOptionsDto.search.length > 0) queryParams.push(`search=${params.pageOptionsDto.search}`);
                if (params.pageOptionsDto.sortBy !== undefined) queryParams.push(`sort_by=${params.pageOptionsDto.sortBy}`)

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    url: `/property-buildings/user/${params.userId}${queryString}`,
                    method: 'GET',
                }
            },
            providesTags: ['PropertyBuilding'],
        }),
    })
})

export const {
    useGetAllPropertyBuildingsQuery,
    useGetPropertyBuildingQuery,
    useCreatePropertyBuildingMutation,
    useUpdatePropertyBuildingMutation,
    useUpdateManyPropertyBuildingsMutation,
    useDeletePropertyBuildingMutation,
    useGetAllPropertyBuildingsByPropertyIdQuery,
    useGetAllPropertyBuildingsByPropertyLogAccessQuery,
    useGetAllPropertyBuildingsByUserIdQuery,
    useGetPropertyBuildingsQuery,
    useGetPropertyBuildingsByUserIdQuery
} = propertyBuildingAPI;