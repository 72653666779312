import React from 'react'

type TableProps = {
    children: React.ReactNode;
}

const Table = ({
    children
}: TableProps) => {
    return (
        <div className="relative overflow-x-scroll max-h-[50vh]">
            <table className="w-full text-sm text-left text-black">
                {children}
            </table>
        </div>
    )
}

const Thead = ({
    children
}: TableProps) => {
    return (
        <thead className="text-xs lg:text-sm capitalize bg-white sticky top-0 z-10">
            {children}
        </thead>
    )
}

const Tbody = ({
    children
}: TableProps) => {
    return (
        <tbody className="overflow-y-scroll">
            {children}
        </tbody>
    )
}

Table.Thead = Thead;
Table.Tbody = Tbody;

export default Table;