import { useDispatch } from "react-redux";
import { useGetReceivedNotificationsQuery } from "../../services/catalog/notification/notification.service";
import { useAppSelector } from "../hooks";
import { getCurrentUser } from "../slices/user.slice";
import { Roles } from "../../services/enum/roles";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { NotificationStatus } from "../../services/enum/notification-status";
import { setNotificationState } from "../slices/notification.slice";
import { useEffect } from "react";

export function useNotifications() {
    const { currentUser } = useAppSelector(getCurrentUser);
    const dispatch = useDispatch();


    const { data: notifications, isFetching, isError } = useGetReceivedNotificationsQuery(
        currentUser.id && (currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) ? {
            pageOptionsDto: {
                page: 1,
                take: 5,
                sortBy: 'created_at'
            }
        } : skipToken
    );

    useEffect(() => {
        if (notifications && notifications.data.length > 0) {
            const unreadNotifications = notifications.data.some(e => e.status === NotificationStatus.Unread);
            dispatch(setNotificationState({ newNotification: unreadNotifications, data: notifications.data }));
        }
    }, [notifications])
}