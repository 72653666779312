import { PageOptionsDto } from "../../dto/page-options.dto";
import { PageDto } from "../../dto/page.dto";
import { reAuthAPI } from "../../re-auth/re-auth";
import { UserDto } from "../../tenant/user/dto/user.dto";

/*
    Service related to requests to external tenants
*/

export const CommonAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({

        // Get external user by tenant id and user id
        getExternalUserById: builder.query<UserDto, { tenantId: string, userId: string }>({
            query: (options) => {
                return {
                    url: `/external/common/${options.tenantId}/users/${options.userId}`,
                    method: 'GET',
                }
            },
            providesTags: ['ExternalCommon']
        }),

    })
})

export const {
    useGetExternalUserByIdQuery
} = CommonAPI;