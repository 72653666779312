import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { AuthState } from "../../services/dto/auth-state.dto";
import { Roles } from "../../services/enum/roles";

const initialState: AuthState = {
    authUser: {
        name: '',
        email: '',
        user_id: '',
        role: Roles.None,
    },
    access_token: '',
}

export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        // Set the user email, name and access_token after the user has successfully logged in
        setAuthenticatedUser: (state: AuthState, { type, payload }: PayloadAction<AuthState>) => {
            state.authUser = payload.authUser;
            state.access_token = payload.access_token
        },
    },
})

export const { setAuthenticatedUser } = authSlice.actions;
export const authReducer = authSlice.reducer;
export const getAuthenticatedUser = (state: RootState) => state.rootReducer.auth;
export const resetAuthState = (state: RootState) => state.rootReducer.auth;