import { useState } from "react";
import { DynamicFieldTypes } from "../../../../molecules/create-custom-log";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../../atoms/checkbox";

type RenderProps = {
    field: DynamicFieldTypes;
}
const DynamicRenderPreview = ({ field }: RenderProps) => {
    const { t } = useTranslation();

    const [check, setChecked] = useState<boolean>(false); // For preview testing purpose
    
    if ('inputType' in field) {
        return (
            <div className="w-full my-2">
                <div className="sm:w-3/4 mx-auto">
                    <label className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600" htmlFor={field.fieldName.split(' ').join('_')}>
                        {field.fieldName.trim()}
                        {field.isRequired && <span className="text-status-critical">*</span>}
                    </label>
                    <input
                        type={field.inputType}
                        multiple={field.inputType === 'file' ? true : false} // When input type is file, allow multiple files to be uploaded
                        accept={field.inputType === 'file' ? 'video/*, image/*, .png, .jpeg, .jpg, .pdf, .mp4' : ''}
                        placeholder={field.inputType === 'date' ? new Date().toISOString().split('T')[0] : field.fieldName}
                        value={field.inputType === 'date' ? new Date().toISOString().split('T')[0] : ''}
                        disabled={true}
                        required={field.isRequired}
                        name={field.fieldName.split(' ').join('_')}
                        className="w-full rounded-md border-0 p-2 h-10 bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 md:text-sm sm:leading-6 placeholder:text-sm"
                    />
                </div>
            </div>
        )
    }
    else if ('flag' in field) {
        return (
            <div className="w-full my-2.5 ">
                <div className="sm:w-3/4 mx-auto">
                    <Checkbox
                        size={'lg'}
                        label={field.fieldName.trim()}
                        checked={check}
                        onChange={(e) => setChecked(e.target.checked)}
                    />
                </div>
            </div>
        )
    }
    else if ('selectValues' in field) {
        return (
            <div className="w-full my-2">
                <div className="sm:w-3/4 mx-auto">
                    <label className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600" htmlFor={field.fieldName.split(' ').join('_')}>{field.fieldName.trim()}</label>
                    <div className="flex">
                        <select
                            name={field.fieldName.split(' ').join('_')}
                            className="block w-full rounded-md border border-gray-300 p-2 h-10 bg-white text-gray-900 shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:text-sm sm:leading-6">
                            {field.selectValues.map((option, idx) => (
                                <option key={idx} value={option}>
                                    {option.trim()}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return <p>No match</p>
    }
}
export default DynamicRenderPreview;