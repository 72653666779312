import React, { useEffect, useRef, useState } from 'react'
import { NotificationManager } from 'react-notifications';
import Select, { MultiValue, components } from 'react-select';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { LogTemplateDto } from '../../../../../services/tenant/log-template/dto/log-template.dto';
import InputCheckbox from '../../../../atoms/select-checkbox'
import { UserDto } from '../../../../../services/tenant/user/dto/user.dto';
import { useGetAllRevokeLogUserAccessByIdQuery, useUpdateManyRevokeLogUserAccessMutation } from '../../../../../services/tenant/revoke-log-user-access/revoke-log-user-access.service';
import { UpdateRevokeLogUserAccessDto } from '../../../../../services/tenant/revoke-log-user-access/dto/update-revoke-log-user-access.dto';
import { PageDto } from '../../../../../services/dto/page.dto';
import { useTranslation } from 'react-i18next';
import { debounce } from '../../../../../utils/debounce';
import { Order } from '../../../../../services/enum/order';
import { SelectCustomStyles } from '../../../../atoms/select-custom-styles/select-custom-styles';
import { LogTypes } from '../../../../../services/enum/log-types';
import { useGetUsersByLogAccessQuery, useGetUsersQuery } from '../../../../../services/tenant/user/user.service';
import SecondaryButton from '../../../../atoms/secondary-button';


type Props = {
    logTemplate?: LogTemplateDto;
}

type SelectOption = {
    label: string;
    value: string;
    existing_access: boolean; // Access when the records is fetched
    revoke_log_user_access_id?: string;
    user_id: string;
}

const RevokeLogUserAccess = ({ logTemplate }: Props) => {
    const { t } = useTranslation();

    const [updateRevokeLogUserAccess, response] = useUpdateManyRevokeLogUserAccessMutation();

    const [allOptions, setAllOptions] = useState<SelectOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
    const [search, setSearch] = useState<string>();
    const [page, setPage] = useState<number>(1);

    const debouncedSearch = useRef(debounce((search: string) => setSearch(search), 500)).current;

    const [skipTokenRevokeLogUserAccess, setSkipTokenRevokeLogUserAccess] = useState<any>(skipToken);

    // Get users by logAccess
    const { data: users } = useGetUsersByLogAccessQuery(
        logTemplate?.id ? {
            options: {
                take: 25,
                page: page,
                order: Order.ASC,
                sortBy: 'name',
                search: search
            },
            logTemplateId: logTemplate?.id
        }
            : skipTokenRevokeLogUserAccess
    );

    // Get all RevokeLogUserAccess relations by logTemplateId
    const { data: revokeLogUserAccess } = useGetAllRevokeLogUserAccessByIdQuery(skipTokenRevokeLogUserAccess);

    useEffect(() => {
        if (logTemplate) {
            setSkipTokenRevokeLogUserAccess(logTemplate.id);
        }
    }, [logTemplate])

    useEffect(() => {
        if (users && revokeLogUserAccess) {
            // INIT
            if (allOptions.length === 0) {
                initOptions(users);
            }
            // SEARCH
            else if (search) {
                handleSearchOptions(search, users);
            }
            // PAGINATION
            else {
                handlePagination(users);
            }
        }
    }, [users, revokeLogUserAccess])

    // Set initial options and selected options
    const initOptions = (users: PageDto<UserDto>) => {
        if (revokeLogUserAccess && allOptions.length === 0) {
            const options = users.data.map(user => ({
                value: user.id,
                label: user.name,
                existing_access: revokeLogUserAccess.find(e => e.user_id === user.id) ? true : false,
                revoke_log_user_access_id: revokeLogUserAccess.find(e => e.user_id === user.id)?.id,
                user_id: user.id
            }))
            setAllOptions(options);
        }

        // if (revokeLogUserAccess) {
        //     const selectOptions: SelectOption[] = revokeLogUserAccess.map(rlua => ({
        //         value: rlua.user_id,
        //         label: rlua.user_name,
        //         existing_access: true,
        //         revoke_log_user_access_id: rlua.id,
        //         user_id: rlua.user_id
        //     }))
        //     setSelectedOptions(selectOptions);
        // }

        if (revokeLogUserAccess) {
            const allSelected = [...selectedOptions];
            users.data.map(user => {
                const revokeMatch = revokeLogUserAccess.find(e => e.user_id === user.id);
                if (!revokeMatch) {
                    allSelected.push({
                        value: user.id,
                        label: user.name,
                        existing_access: false,
                        user_id: user.id
                    })
                }
            })
            setSelectedOptions(allSelected);
        }
        else {
            setSelectedOptions(users.data.map(user => ({
                value: user.id,
                label: user.name,
                existing_access: false,
                user_id: user.id
            })));
        }
    }

    // Handle options when search query is provided
    const handleSearchOptions = (search: string, users: PageDto<UserDto>) => {
        if (revokeLogUserAccess && search) {
            const allOptions: SelectOption[] = [];
            const selectedOptions: SelectOption[] = [];
            setPage(1)
            users.data.forEach(user => {
                let tempObj: SelectOption = {
                    label: user.name,
                    value: user.id,
                    existing_access: revokeLogUserAccess.find(e => e.user_id === user.id) ? true : false,
                    revoke_log_user_access_id: revokeLogUserAccess.find(e => e.user_id === user.id)?.id,
                    user_id: user.id
                };
                allOptions.push(tempObj);
                // When match between user and subsidiary id, push to selectedOptions
                if (!tempObj.existing_access) {
                    selectedOptions.push(tempObj);
                }
            })
            setAllOptions([...allOptions]);
            setSelectedOptions([...selectedOptions]);
        }
    }

    // Handling options with pagination
    const handlePagination = (users: PageDto<UserDto>) => {
        if (revokeLogUserAccess) {
            // Get existing options
            const existingOptions = [...allOptions];
            // Get existing selected options
            const existingSelected = [...selectedOptions];

            // Remove duplicates
            const uniqueArr = existingOptions.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.user_id === value.user_id
                ))
            )

            // Add options
            users.data.forEach(user => {
                const obj = {
                    value: user.id,
                    label: user.name,
                    existing_access: revokeLogUserAccess.find(e => e.user_id === user.id) ? true : false,
                    revoke_log_user_access_id: revokeLogUserAccess.find(e => e.user_id === user.id)?.id,
                    user_id: user.id
                }
                // Make sure no duplicates are added
                if (!uniqueArr.some(e => e.user_id === obj.user_id)) {
                    uniqueArr.push(obj);
                    if (!obj.existing_access && !existingSelected.some(e => e.user_id === obj.user_id)) {
                        existingSelected.push(obj);
                    }
                }
            });

            setAllOptions(uniqueArr);
            setSelectedOptions(existingSelected);
        }
    }

    // Handle scroll event to fetch more options
    const handleScroll = (event: any) => {
        if (users && users.meta.hasNextPage) {
            if (!search) {
                setPage(page ? page + 1 : 1);
            }
        }
    }

    // Selected option(s) will get passed
    const handleSelectChange = (options: MultiValue<SelectOption>) => {

        const allSelectedOptions = [...selectedOptions]; // All selected options

        if (options.length > 0) {
            // If options array is shorter than selectedOptions array, a user has been removed
            if (options.length < allSelectedOptions.length) {

                allSelectedOptions.forEach((option: SelectOption) => {
                    // If current option not in passed options arr, remove object from selectedOptions array
                    // if (!options.includes(option)) {
                    if (!options.find(e => e.user_id === option.user_id)) {
                        const index = allSelectedOptions.findIndex((e) => e.value === option.value);
                        if (index !== -1) {
                            allSelectedOptions.splice(index, 1);
                        }
                    }
                })
                setSelectedOptions(allSelectedOptions); // Update selectedOptions state
            }
            // If options array is not shorter than selectedOptions array, add option to selectedOptions array
            else {
                options.forEach((option: SelectOption) => {
                    // Add option if not already exists in allSelectedOptions array
                    // if (!allSelectedOptions.includes(option)) {
                    if (!allSelectedOptions.some(e => e.user_id === option.user_id)) {
                        let tempObj: SelectOption = {
                            label: option.label,
                            value: option.value,
                            existing_access: option.existing_access,
                            revoke_log_user_access_id: option.revoke_log_user_access_id,
                            user_id: option.user_id
                        };
                        allSelectedOptions.push(tempObj);
                    }
                });
                setSelectedOptions(allSelectedOptions);
            }
        }
        else {
            setSelectedOptions([])
        }
    }

    const handleUpdateLogPropertyAccess = async () => {
        let recordsToBeUpdated: UpdateRevokeLogUserAccessDto[] = [];
        let existingRecords: SelectOption[] = [];

        // Loop through selectedOptions to get records to be created
        selectedOptions.forEach((record) => {
            // If the property existing_access is not true, it's a completely new record to be added
            if (record.existing_access === true) {
                let tempObj: UpdateRevokeLogUserAccessDto = {
                    access: false,
                    user_id: record.user_id,
                    revoke_log_user_access_id: record.revoke_log_user_access_id,
                    log_template_id: logTemplate!.id,
                }
                recordsToBeUpdated.push(tempObj);
            }
            else if (record.existing_access === false) {
                existingRecords.push(record);
            }
        })

        // Loop through all options and find records with property existing_access true
        // If the records does not exist in selectedOptions, it means it has been removed has should be deleted
        allOptions.forEach((option) => {
            if (option.existing_access === false) {
                // if (!existingRecords.some((e) => e.revoke_log_user_access_id === option.revoke_log_user_access_id)) {
                if (!existingRecords.some((e) => e.user_id === option.user_id)) {
                    let tempObj: UpdateRevokeLogUserAccessDto = {
                        access: true,
                        user_id: option.user_id,
                        log_template_id: logTemplate!.id,
                        revoke_log_user_access_id: option.revoke_log_user_access_id,
                    }
                    recordsToBeUpdated.push(tempObj);
                }
            }
        });

        if (recordsToBeUpdated.length > 0) {
            try {
                const response = await updateRevokeLogUserAccess(recordsToBeUpdated).unwrap();

                if (response.success) {
                    NotificationManager.success(t('common:notifications.updated'));
                }
                else {
                    NotificationManager.info(t('common:notifications.updateFailed'));
                }

            }
            catch (error) {
                NotificationManager.error(t('common:notifications.updateFailed'))
            }
        }
    }


    return (
        <div className="rounded-lg w-full mx-auto mb-2">
            <p className="my-2 text-xs sm:text-sm font-semibold">
                {t('page:properties.selectUsersTo')} <span className="">{t('common:giveLower')} </span>
                {t('page:properties.accessTo')}
                <span className="font-extrabold uppercase"> {logTemplate?.log_type === LogTypes.Default ?
                    t(`page:logs.defaultLogs.${logTemplate.name.split('_').join('')}`) :
                    logTemplate?.name.split('_').join(' ')}</span>
            </p>
            <Select
                className="z-20 text-sm capitalize mb-2"
                value={selectedOptions}
                options={allOptions}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(options) => handleSelectChange(options)}
                controlShouldRenderValue={true}
                isClearable={false}
                backspaceRemovesValue={false}
                maxMenuHeight={200}
                placeholder={t('common:select')}
                noOptionsMessage={() => t('page:properties.noUsersFound')}
                components={{
                    // @ts-ignore
                    Option: InputCheckbox
                }}
                onInputChange={(value) => debouncedSearch(value)}
                captureMenuScroll={true}
                onMenuScrollToBottom={(val) => handleScroll(val)}
                filterOption={null}
                styles={SelectCustomStyles}
            />
            <SecondaryButton
                text={t('common:update')}
                size={'medium'}
                align={'center'}
                wide={true}
                onClick={handleUpdateLogPropertyAccess}
            />
        </div>
    )
}

export default RevokeLogUserAccess;