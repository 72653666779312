const toCamelCase = (word: string) => {
    return word
        .split('_')
        .map((word, index) => {
            // If it's the first word, return it as is. Otherwise, capitalize the first letter.
            if (index === 0) {
                return word;
            } else {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }
        })
        .join('');
};

export default toCamelCase;