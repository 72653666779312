import { LogCategoriesDto } from '../../dto/log-categories.dto';
import { PageOptionsDto } from '../../dto/page-options.dto';
import { PageDto } from '../../dto/page.dto';
import { reAuthAPI } from '../../re-auth/re-auth';
import { EntityLogDto } from './dto/entity-log.dto';
import { PropertyBuildingLogAccessDto } from './dto/building-log-access/property-building-log-access.dto';
import { PropertyLogAccessDto } from './dto/property-log-access/property-log-access.dto';
import { PropertyObjectLogAccessDto } from './dto/object-log-access/property-object-log-access.dto';
import { UpdatePropertyBuildingLogAccessDto } from './dto/building-log-access/update-property-building-log-access.dto';
import { UpdatePropertyLogAccessDto } from './dto/property-log-access/update-property-log-access.dto';
import { UpdatePropertyObjectLogAccessDto } from './dto/object-log-access/update-property-object-log-access.dto';
import { UpdateEntityLogDto } from './dto/update-entity-log.dto';

export const entityLogAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Get all entityLogs
        getAllEntityLogs: builder.query<PageDto<EntityLogDto>, PageOptionsDto>({
            query: (options) => {
                const queryParams = [];

                if (options.page !== undefined) queryParams.push(`page=${options.page}`);
                if (options.take !== undefined) queryParams.push(`take=${options.take}`);
                if (options.order !== undefined) queryParams.push(`order=${options.order}`);
                if (options.search !== undefined && options.search.length > 0) queryParams.push(`search=${options.search}`);
                if (options.sortBy !== undefined) queryParams.push(`sort_by=${options.sortBy}`);
                if (options.filter !== undefined) queryParams.push(`filter=${options.filter}`);

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    url: `/entity-logs${queryString}`,
                    method: 'GET',
                }
            },
            providesTags: ['EntityLogs'],
        }),
        // Get entityLogs by user
        getEntityLogsByUser: builder.query<PageDto<EntityLogDto>, { pageOptions: PageOptionsDto, userId: string }>({
            query: ({ pageOptions, userId }) => {
                const queryParamsPaginated = [];
                if (pageOptions.page !== undefined) queryParamsPaginated.push(`page=${pageOptions.page}`);
                if (pageOptions.take !== undefined) queryParamsPaginated.push(`take=${pageOptions.take}`);
                if (pageOptions.order !== undefined) queryParamsPaginated.push(`order=${pageOptions.order}`);
                if (pageOptions.search !== undefined && pageOptions.search.length > 0) queryParamsPaginated.push(`search=${pageOptions.search}`);
                if (pageOptions.sortBy !== undefined) queryParamsPaginated.push(`sort_by=${pageOptions.sortBy}`);

                // Contruct query string for paginated request
                const queryStringPaginated = queryParamsPaginated.length > 0 ? `?${queryParamsPaginated.join('&')}` : '';

                return {
                    url: `/entity-logs/user/${userId}${queryStringPaginated}`,
                    method: 'GET',
                }
            },
            providesTags: ['EntityLogs'],
        }),
        getEntityLogsUniqueCategories: builder.query<LogCategoriesDto[], void>({
            query: () => ({
                url: '/entity-logs/categories',
                method: 'GET',
            }),
            providesTags: ['EntityLogs']
        }),
        // Update entity log
        updateEntityLog: builder.mutation<any, UpdateEntityLogDto>({
            query: (entityLog) => ({
                url: '/entity-logs/' + entityLog.id,
                method: 'PUT',
                body: entityLog
            }),
            invalidatesTags: ['EntityLogs']
        }),

        /* PROPERTY LOG ACCESS */ 

        // Get all property log access relations
        getAllPropertyLogAccess: builder.query<PropertyLogAccessDto[], void>({
            query: () => ({  
                    url: `/entity-logs/properties/access`,
                    method: 'GET',
            }),
            providesTags: ['EntityLogs']
        }),
        // Get all property log access relations by ids (logTemplateIds or propertyIds)
        getAllPropertyLogAccessByIds: builder.query<PropertyLogAccessDto[], string[]>({
            query: (ids) => ({
                url: '/entity-logs/properties/access/ids?ids=' + ids,
                method: 'GET',
            }),
            providesTags: ['EntityLogs']
        }),
        // Get all property log access relations by given id (logTemplateId or propertyId)
        getAllPropertyLogAccessById: builder.query<PropertyLogAccessDto[], string>({
            query: (id) => ({
                url: '/entity-logs/properties/access/' + id,
                method: 'GET',
            }),
            providesTags: ['EntityLogs']
        }),
        // Update many property log access relations
        updateManyPropertyLogAccess: builder.mutation<any, UpdatePropertyLogAccessDto[]>({
            query: (propertyLogAccess) => ({
                url: '/entity-logs/properties/access',
                method: 'PUT',
                body: propertyLogAccess
            }),
            invalidatesTags: ['EntityLogs', 'Logs', 'LogTemplate']
        }),

        /* BUILDING LOG ACCESS */ 

        // Get all property building log access relations
        getAllPropertyBuildingLogAccess: builder.query<PropertyBuildingLogAccessDto[], void>({
            query: () => ({
                url: '/entity-logs/buildings/access',
                method: 'GET',
            }),
            providesTags: ['EntityLogs']
        }),
        // Get all property building log access relations by ids (logTemplateIds or propertyIds or propertBuildingIds or propertyObjectIds)
        getAllPropertyBuildingLogAccessByIds: builder.query<PropertyBuildingLogAccessDto[], string[]>({
            query: (ids) => ({
                url: '/entity-logs/buildings/access/ids?ids=' + ids,
                method: 'GET',
            }),
            providesTags: ['EntityLogs']
        }),
        // Get all property building log access relations by given id (logTemplateId or propertyId or propertBuildingId or propertyObjectId)
        getAllPropertyBuildingLogAccessById: builder.query<PropertyBuildingLogAccessDto[], string>({
            query: (id) => ({
                url: '/entity-logs/buildings/access/id/' + id,
                method: 'GET',
            }),
            providesTags: ['EntityLogs']
        }),
        // Update many property building log access relations
        updateManyPropertyBuildingLogAccess: builder.mutation<any, UpdatePropertyBuildingLogAccessDto[]>({
            query: (propertyBuildingLogAccess) => ({
                url: '/entity-logs/buildings/access',
                method: 'PUT',
                body: propertyBuildingLogAccess
            }),
            invalidatesTags: ['EntityLogs', 'Logs', 'LogTemplate', 'PropertyObject']
        }),

        /* OBJECT LOG ACCESS */
        
        // Get all property object log access relations
        getAllPropertyObjectLogAccess: builder.query<PropertyObjectLogAccessDto[], void>({
            query: () => ({
                url: '/entity-logs/objects/access',
                method: 'GET',
            }),
            providesTags: ['EntityLogs']
        }),
        // Get all property object log access relations by ids
        getAllPropertyObjectLogAccessByIds: builder.query<PropertyObjectLogAccessDto[], string[]>({
            query: (ids) => ({
                url: '/entity-logs/objects/access/ids?ids=' + ids,
                method: 'GET',
            }),
            providesTags: ['EntityLogs']
        }),
        // Get all property object log access relations by given id
        getAllPropertyObjectLogAccessById: builder.query<PropertyObjectLogAccessDto[], string>({
            query: (id) => ({
                url: '/entity-logs/objects/access/id/' + id,
                method: 'GET',
            }),
            providesTags: ['EntityLogs']
        }),
        // Update many property building log access relations
        updateManyPropertyObjectLogAccess: builder.mutation<any, UpdatePropertyObjectLogAccessDto[]>({
            query: (propertyObjectLogAccess) => ({
                url: '/entity-logs/objects/access',
                method: 'PUT',
                body: propertyObjectLogAccess
            }),
            invalidatesTags: ['EntityLogs', 'Logs', 'LogTemplate', 'PropertyObject']
        }),
    })
})

export const {
    /* GENERAL */
    useGetAllEntityLogsQuery,
    useGetEntityLogsByUserQuery,
    useGetEntityLogsUniqueCategoriesQuery,
    useUpdateEntityLogMutation,
    /* PROPERTY LOG ACCESS */
    useGetAllPropertyLogAccessQuery,
    useGetAllPropertyLogAccessByIdsQuery,
    useGetAllPropertyLogAccessByIdQuery,
    useUpdateManyPropertyLogAccessMutation,
    /* BUILDING LOG ACCESS */
    useGetAllPropertyBuildingLogAccessQuery,
    useGetAllPropertyBuildingLogAccessByIdsQuery,
    useGetAllPropertyBuildingLogAccessByIdQuery,
    useUpdateManyPropertyBuildingLogAccessMutation,
    /* OBJECT LOG ACCESS */
    useGetAllPropertyObjectLogAccessQuery,
    useGetAllPropertyObjectLogAccessByIdsQuery,
    useGetAllPropertyObjectLogAccessByIdQuery,
    useUpdateManyPropertyObjectLogAccessMutation,
} = entityLogAPI;