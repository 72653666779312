
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetUserInvitesByTenantIdQuery } from "../../../../services/catalog/user-invite/user-invite.service";
import { Fragment, useEffect, useRef, useState } from "react";
import { dateToLocaleDate, dateToLocaleDateAndHour } from "../../../../utils/date-time";
import { useDeleteUserMutation, useUpdateUserMutation } from "../../../../services/tenant/user/user.service";
import { NotificationManager } from 'react-notifications';

import Modal from "../../../molecules/modal";
import UpdateUserForm from "../../forms/user/update-user-form";
import { getCurrentUser } from "../../../../redux/slices/user.slice";
import ConfirmModal from "../../../molecules/confirm-modal";
import { PropertyDto } from "../../../../services/tenant/property/dto/property.dto";
import { propertyAPI, useDeletePropertyMutation, useGetAllPropertiesQuery, useUpdatePropertyMutation } from "../../../../services/tenant/property/property.service";
import UpdatePropertyForm from "../../forms/property/update-property-form";
import PropertyUserAccess from "../property-user-access";
import PropertyBuildingAccess from "../property-building-access";
import PropertyObjectAccess from "../property-object-access";
import PropertyLogAccess from "../property-log-access";
import { useAppSelector } from "../../../../redux/hooks";
import { useTranslation } from "react-i18next";

import { Input } from "@mui/material";
import { debounce } from "../../../../utils/debounce";
import { PropertyTypes } from "../../../../services/enum/property-types";
import { Order } from "../../../../services/enum/order";
import Loader from "../../../atoms/loader/loader";
import FilterTableColumns from "../../../molecules/filter-table-columns";
import { Roles } from "../../../../services/enum/roles";
import { Menu, Transition } from "@headlessui/react";
import { UpdatePropertyDto } from "../../../../services/tenant/property/dto/update-property.dto";
import { useDispatch } from "react-redux";
import { useGetAllPropertyUserAccessByIdsQuery, useUpdateManyPropertyUserAccessMutation } from "../../../../services/tenant/property-user-access/property-user-access.service";
import { UpdatePropertyUserAccessDto } from "../../../../services/tenant/property-user-access/dto/update-property-user-access.dto";
import CreatePropertyForm from "../../forms/property/create-property-form";
import { SubsidiaryDto } from "../../../../services/tenant/subsidiary/dto/subsidiary.dto";
import { UserDto } from "../../../../services/tenant/user/dto/user.dto";
import { LogTemplateDto } from "../../../../services/tenant/log-template/dto/log-template.dto";
import { LogTypes } from "../../../../services/enum/log-types";
import PrimaryButton from "../../../atoms/primary-button";
import Checkbox from "../../../atoms/checkbox";
import Pagination from "../../../molecules/pagination";
import Table from "../../../molecules/table";
import { useGetAllPropertyLogAccessByIdsQuery, useUpdateManyPropertyLogAccessMutation } from "../../../../services/tenant/entity-log/entity-log.service";
import { UpdatePropertyLogAccessDto } from "../../../../services/tenant/entity-log/dto/property-log-access/update-property-log-access.dto";

type Props = {
    subsidiaries?: SubsidiaryDto[];
    users?: UserDto[];
    logTemplates?: LogTemplateDto[];
}

const PropertyTableEdit = ({
    subsidiaries,
    users,
    logTemplates,
}: Props) => {
    const { t } = useTranslation();
    const { currentUser } = useAppSelector(getCurrentUser);
    const dispatch = useDispatch();

    const [updateProperty, { isLoading: updateIsLoading, error: updateError }] = useUpdatePropertyMutation();
    const [deleteProperty, { isLoading: deleteIsLoading, error: deleteError }] = useDeletePropertyMutation();
    const [updatePropertyUserAccess] = useUpdateManyPropertyUserAccessMutation();
    const [updatePropertyLogAccess] = useUpdateManyPropertyLogAccessMutation();

    const [showCreateModal, setShowCreateModal] = useState<boolean>(false); // Toggle create modal
    const [showCopyModal, setShowCopyModal] = useState<boolean>(false); // Toggle copy modal
    const [showModal, setShowModal] = useState<boolean>(false); // Toggle modal
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false); // Toggle confirm modal
    const [selectedProperty, setSelectedProperty] = useState<PropertyDto>();
    const [tableHeaders, setTableHeaders] = useState<string[]>();

    // Pagination
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>();
    const [hasNextPage, setHasNextPage] = useState<boolean>();
    const [hasPrevPage, setHasPrevPage] = useState<boolean>();

    // Table sort and search
    const [search, setSearch] = useState<string | undefined>('');
    const [searchOperation, setSearchOperation] = useState<string | undefined>();
    const [sortBy, setSortBy] = useState<string | undefined>('label'); // Column to sort by
    const [sortOrder, setSortOrder] = useState<Order>(Order.ASC); // Sort order
    const [showSortMenu, setShowSortMenu] = useState<boolean>(false); // Sort menu on small screens

    const debouncedSearch = useRef(debounce((search: string) => setSearchOperation(search), 500)).current;

    // Get properties paginated
    const { data: properties, isFetching } = useGetAllPropertiesQuery({
        page: page,
        take: 25,
        search: searchOperation,
        sortBy: sortBy,
        order: sortOrder
    });

    useEffect(() => {
        if (properties) {
            setPage(properties.meta.page);
            setPageCount(properties.meta.pageCount);
            setHasNextPage(properties.meta.hasNextPage);
            setHasPrevPage(properties.meta.hasPreviousPage);
        }
    }, [properties])

    const [skipTokenUserIds, setSkipTokenUserIds] = useState<any>(skipToken);
    const { data: propertyUserAccessByIds } = useGetAllPropertyUserAccessByIdsQuery(skipTokenUserIds);

    const [skipTokenLogTemplateIds, setSkipTokenLogTemplateIds] = useState<any>(skipToken);
    const { data: propertyLogAccessByIds } = useGetAllPropertyLogAccessByIdsQuery(skipTokenLogTemplateIds);

    useEffect(() => {
        if (users) {
            setSkipTokenUserIds(users.map(e => e.id).join(','));
        }
    }, [users])

    useEffect(() => {
        if (logTemplates) {
            setSkipTokenLogTemplateIds(logTemplates.map(e => e.id).join(','));
        }
    }, [logTemplates])

    const handlePagination = (page: number) => {
        setPage(page);
    }

    // If selectedProperty changes, set updated property
    useEffect(() => {
        if (selectedProperty) {
            const match = properties?.data.find((e) => e.id === selectedProperty.id);
            if (match) {
                setSelectedProperty(match);
            }
        }
    }, [properties]);

    // Handle sort by column and sort order
    const handleSort = (column: string, order: Order) => {
        setSortBy(column);
        setSortOrder(order === Order.ASC ? Order.DESC : Order.ASC)
    }

    // Edit property
    const handleEditProperty = (property: PropertyDto) => {
        setShowModal(true); // Show modal
        setSelectedProperty(property);
    }

    // Copy property
    const handleCopyProperty = (property: PropertyDto) => {
        setShowCopyModal(true);
        setSelectedProperty(property);
    }

    // Toggle modal and setSelected user
    const handleDeleteProperty = (property: PropertyDto) => {
        setSelectedProperty(property); // Set selected property to be deleted if confirmed in modal
        setShowConfirmModal(true); // Show confirm modal
    }

    // Delete property after confirmation
    const deletePropertyConfirmed = async () => {
        const propertyToBeDeleted = selectedProperty;
        if (propertyToBeDeleted?.id) {

            const payload = {
                property_id: propertyToBeDeleted.id,
            }

            try {
                const response = await deleteProperty(payload).unwrap();
                if (response.success) {
                    NotificationManager.success(t('page:properties.form.propertyDeleted'));
                    setShowConfirmModal(false);
                }
                else {
                    NotificationManager.error(t('page:properties.form.propertyNotDeleted'));
                }
            }
            catch (error) {
                NotificationManager.error(t('page:properties.form.propertyNotDeleted'));
            }
        }
    }

    const handleProperty = () => {

    }

    const handleUpdateProperty = async (updatePropertyDto: UpdatePropertyDto) => {
        try {
            const response = await updateProperty(updatePropertyDto).unwrap();
            if (response.success) {
                NotificationManager.success(t('page:properties.form.propertyUpdated'));
                dispatch(propertyAPI.util.invalidateTags(['Properties']));
            }
            else {
                NotificationManager.error(t('page:properties.form.propertyNotUpdated'))
            }
        }
        catch (error) {
            NotificationManager.error(t('page:properties.form.propertyNotUpdated'));
        }
    }

    const handleUpdatePropertyUserAccess = async (updatePropertyUserAccessDto: UpdatePropertyUserAccessDto[]) => {
        // Update property user access
        try {
            const response = await updatePropertyUserAccess(updatePropertyUserAccessDto).unwrap();
            if (response.success) {
                NotificationManager.success(t('page:properties.form.propertyUpdated'));
            }
            else {
                NotificationManager.error(t('page:properties.form.propertyNotUpdated'))
            }
        }
        catch (error) {
            NotificationManager.error(t('page:properties.form.propertyNotUpdated'));
        }
    }

    const handleUpdatePropertyLogAccess = async (updatePropertyLogAccessDto: UpdatePropertyLogAccessDto[]) => {
        // Update property user access
        try {
            const response = await updatePropertyLogAccess(updatePropertyLogAccessDto).unwrap();
            if (response.success) {
                NotificationManager.success(t('page:properties.form.propertyUpdated'));
            }
            else {
                NotificationManager.error(t('page:properties.form.propertyNotUpdated'))
            }
        }
        catch (error) {
            NotificationManager.error(t('page:properties.form.propertyNotUpdated'));
        }
    }

    const handleAccess = async (property: PropertyDto, id: string) => {
        if (subsidiaries) {
            let updatePropertyDto: UpdatePropertyDto = {
                id: property.id
            }
            const existingAccess = property.subsidiary_id === id;
            if (existingAccess) {
                updatePropertyDto.subsidiary_id = null;
                await handleUpdateProperty(updatePropertyDto);
            }
            else {
                updatePropertyDto.subsidiary_id = id;
                await handleUpdateProperty(updatePropertyDto);
            }
        }
        else if (users && propertyUserAccessByIds) {
            const propertyUserAccessMatch = propertyUserAccessByIds.find(e => e.property_id === property.id && e.user_id === id);
            if (propertyUserAccessMatch) {
                // Remove access
                let updateObj: UpdatePropertyUserAccessDto = {
                    access: false,
                    user_id: id,
                    property_id: property.id,
                    property_user_access_id: propertyUserAccessMatch.id
                }
                handleUpdatePropertyUserAccess([updateObj]);
            }
            else {
                // Give access
                let updateObj: UpdatePropertyUserAccessDto = {
                    access: true,
                    user_id: id,
                    property_id: property.id,
                }
                handleUpdatePropertyUserAccess([updateObj]);
            }
        }

        else if (logTemplates && propertyLogAccessByIds) {
            const propertyLogAccessMatch = propertyLogAccessByIds.find(e => e.property_id === property.id && e.log_template_id === id);
            if (propertyLogAccessMatch) {
                // Remove access
                let updateObj: UpdatePropertyLogAccessDto = {
                    access: false,
                    log_template_id: id,
                    property_id: property.id,
                    entity_log_id: propertyLogAccessMatch.id
                }
                handleUpdatePropertyLogAccess([updateObj]);
            }
            else {
                // Give access
                let updateObj: UpdatePropertyLogAccessDto = {
                    access: true,
                    log_template_id: id,
                    property_id: property.id,
                }
                handleUpdatePropertyLogAccess([updateObj]);
            }
        }
    }

    return (

        <div className="w-full mx-auto pb-5">
            <div className="relative">
                <div className="flex items-center">
                    <h5 className="text-base font-semibold ml-2 w-5/12">{t('common:properties')}</h5>
                    <div className="w-7/12 flex justify-end">
                        <PrimaryButton
                            text={t('page:properties.createProperty')}
                            size={'medium'}
                            startAdornment={'add'}
                            onClick={() => setShowCreateModal(true)}
                        />
                    </div>
                </div>
                {(properties && properties.data.length < 1 && !searchOperation) ? (
                    <div className="w-10 xl:w-8/12 max-w-2xl mx-auto bg-primary-blue-5 text-black rounded-md px-2 py-4 text-center">
                        <p className="text-base font-semibold">{t('page:properties.noPropertiesFound')}...</p>
                    </div>
                ) : (
                    <>
                        <div className="sm:w-6/12 max-w-2xl mt-2 text-center sm:text-left">
                            <Input
                                type="search"
                                name="search_name"
                                placeholder={t('common:search')}
                                disableUnderline={true}
                                value={search}
                                className="w-full border rounded-md text-base md:text-sm px-2 pt-2.5 pb-1 mt-3 bg-white"
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                    debouncedSearch(e.target.value);
                                }}
                                endAdornment={
                                    <span
                                        className="material-symbols-outlined pb-1 text-gray-500 cursor-pointer"
                                        style={{ fontSize: '1.3rem' }}
                                        onClick={() => {
                                            if (search) {
                                                setSearch('')
                                                debouncedSearch('')
                                            }
                                        }}
                                    >
                                        {search ? 'close' : 'search'}
                                    </span>
                                }
                                size="small"
                            />
                        </div>
                        <div className="overflow-x-scroll max-h-[45vh]">
                            {properties?.data && properties.data.length > 0 && !isFetching && (
                                <Table>
                                    <Table.Thead>
                                        <tr className="text-xs">
                                            <th scope="col" className="sticky top-0 bg-white z-10">
                                                <span className="sr-only">Property image</span>
                                            </th>
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[8rem] max-w-[8rem] sticky top-0 bg-white z-10"
                                                onClick={() => handleSort('label', sortOrder)}>
                                                {t('common:label')}
                                                {(sortBy === 'label') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </th>
                                            {subsidiaries && [...subsidiaries]
                                                .sort((a, b) => a.company_name.localeCompare(b.company_name))
                                                .map(subsidiary => {
                                                    return (
                                                        <th
                                                            key={subsidiary.id}
                                                            scope="col"
                                                            className="py-1 pl-2 md:py-2 pr-2 sm:table-cell cursor-pointer font-bold min-w-[8rem] max-w-[8rem] sticky top-0 bg-white z-10">
                                                            {subsidiary.company_name}
                                                        </th>
                                                    )
                                                })}
                                            {users && [...users]
                                                .sort((a, b) => a.name.localeCompare(b.name))
                                                .map(user => {
                                                    return (
                                                        <th
                                                            key={user.id}
                                                            scope="col"
                                                            className="py-1 pl-2 md:py-2 pr-2 sm:table-cell cursor-pointer font-bold min-w-[8rem] max-w-[8rem] sticky top-0 bg-white z-10">
                                                            {user.name}
                                                        </th>
                                                    )
                                                })}

                                            {logTemplates && [...logTemplates]
                                                .sort((a, b) => a.name.localeCompare(b.name))
                                                .map(logTemplate => {
                                                    return (
                                                        <th
                                                            key={logTemplate.id}
                                                            scope="col"
                                                            className="py-1 pl-2 md:py-2 pr-2 sm:table-cell cursor-pointer font-bold min-w-[8rem] max-w-[8rem] sticky top-0 bg-white z-10">
                                                            {logTemplate.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${logTemplate.name.split('_').join('')}`) : logTemplate.name.split('_').join(' ')}
                                                        </th>
                                                    )
                                                })
                                            }
                                            {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                                                <>
                                                    <th scope="col" className="sticky top-0 bg-white z-10">
                                                        <span className="sr-only">Copy</span>
                                                    </th>
                                                    <th scope="col" className="sticky top-0 bg-white z-10">
                                                        <span className="sr-only">Edit</span>
                                                    </th>
                                                    <th scope="col" className="sticky top-0 bg-white z-10">
                                                        <span className="sr-only">Delete</span>
                                                    </th>
                                                </>
                                            )}

                                        </tr>
                                    </Table.Thead>
                                    <Table.Tbody>
                                        {properties?.data.map((property, idx) => {
                                            return (
                                                <tr key={idx} className={`odd:hover:bg-primary-blue-10 even:hover:bg-gray-50 cursor-pointer text-xs text-black even:bg-white odd:bg-primary-blue-5`}>
                                                    <td scope="row" className="w-14 md:w-20 pl-2 capitalize py-1">
                                                        {property.image ? (
                                                            <div className="flex items-center w-8 h-8 rounded-full bg-white overflow-hidden">
                                                                <img
                                                                    src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${property.tenant_id}/${property.image}`}
                                                                    // onError={e => e.currentTarget.src = DefaultProperty}
                                                                    alt="Property image"
                                                                    className="w-8 h-8 rounded-full object-cover" />
                                                            </div>
                                                        ) : (
                                                            <div className="flex items-center justify-center w-8 h-8 rounded-full border border-gray-100">
                                                                <span
                                                                    className="material-symbols-outlined"
                                                                    style={{ fontSize: '1.2rem' }}
                                                                >
                                                                    home_work
                                                                </span>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td
                                                        className="py-2 pr-2 pl-1 sm:pl-2 text-xs capitalize font-semibold max-w-[5rem] min-w-[5rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                        title={property?.label}
                                                        onClick={handleProperty}>
                                                        {property.label ? property.label : '-'}
                                                    </td>
                                                    {subsidiaries && [...subsidiaries]
                                                        .sort((a, b) => a.company_name.localeCompare(b.company_name))
                                                        .map(subsidiary => {
                                                            return (
                                                                <td
                                                                    key={subsidiary.id}
                                                                    className="pl-4 text-xs capitalize font-semibold sm:font-normal max-w-[3rem] min-w-[3rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                                                    <Checkbox
                                                                        size={'sm'}
                                                                        checked={subsidiary.id === property.subsidiary_id}
                                                                        onChange={() => handleAccess(property, subsidiary.id)}
                                                                    />
                                                                </td>
                                                            )
                                                        })}
                                                    {users && propertyUserAccessByIds && [...users]
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map(user => {
                                                            return (
                                                                <td
                                                                    key={user.id}
                                                                    className="pl-4 text-xs capitalize font-semibold sm:font-normal max-w-[3rem] min-w-[3rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                                                    <Checkbox
                                                                        size={'sm'}
                                                                        checked={propertyUserAccessByIds.find(e => e.property_id === property.id && e.user_id === user.id) ? true : false}
                                                                        onChange={() => handleAccess(property, user.id)}
                                                                    />
                                                                </td>
                                                            )
                                                        })}
                                                    {logTemplates && propertyLogAccessByIds && [...logTemplates]
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map(logTemplate => {
                                                            return (
                                                                <td
                                                                    key={logTemplate.id}
                                                                    className="pl-4 text-xs capitalize font-semibold sm:font-normal max-w-[3rem] min-w-[3rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                                                    <Checkbox
                                                                        size={'sm'}
                                                                        checked={propertyLogAccessByIds.find(e => e.property_id === property.id && e.log_template_id === logTemplate.id) ? true : false}
                                                                        onChange={() => handleAccess(property, logTemplate.id)}
                                                                    />
                                                                </td>
                                                            )
                                                        })}
                                                    {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                                                        <>
                                                            <td className="text-right">
                                                                <button
                                                                    onClick={() => handleCopyProperty(property)}
                                                                    className="text-black material-symbols-outlined"
                                                                    style={{ fontSize: '1.2rem' }}
                                                                    title={t('common:duplicate')}
                                                                >content_copy</button>
                                                            </td>
                                                            <td className="pb-1 text-center px-2">
                                                                <button
                                                                    onClick={() => handleEditProperty(property)}
                                                                    className="text-black material-symbols-outlined"
                                                                    style={{ fontSize: '1.2rem' }}
                                                                    title={t('common:edit')}
                                                                >edit_square</button>
                                                            </td>
                                                            <td className="text-left">
                                                                <button
                                                                    onClick={() => handleDeleteProperty(property)}
                                                                    className="material-symbols-outlined text-status-critical"
                                                                    style={{ fontSize: '1.3rem' }}
                                                                    title={t('common:delete')}
                                                                >delete</button>
                                                            </td>
                                                        </>
                                                    )}
                                                </tr>
                                            )
                                        })}
                                    </Table.Tbody>
                                </Table>
                            )}

                            {isFetching && (
                                <div className="mx-auto mb-5 mt-1">
                                    <Loader show={isFetching} size='small' />
                                </div>
                            )}

                            {!properties || properties.data.length < 1 && (
                                <div className="my-5">
                                    <p className="text-sm ml-7 md:ml-5 font-semibold text-black">{t('page:properties.noPropertiesFound')}...</p>
                                </div>
                            )}
                        </div>
                    </>
                )}
                {properties?.data && properties?.meta?.hasNextPage && (
                    <div className="flex justify-center py-2">
                        <Pagination
                            count={pageCount}
                            page={page}
                            defaultPage={1}
                            showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                            showLastButton={pageCount ? pageCount > 5 : false}
                            hidePrevButton={!hasPrevPage}
                            hideNextButton={!hasNextPage}
                            onChange={handlePagination}
                        />
                    </div>
                )}
            </div>

            <Modal isOpen={showCreateModal} close={() => setShowCreateModal(false)} title={`${t('page:properties.createProperty')}`} size='sm' titleAlign='center'>
                <CreatePropertyForm />
            </Modal>

            <Modal isOpen={showCopyModal} close={() => setShowCopyModal(false)} title={`${t('page:properties.createProperty')} (${t('common:duplicate')})`} size='sm' titleAlign='center'>
                <CreatePropertyForm property={selectedProperty} />
            </Modal>

            <Modal isOpen={showModal} close={() => setShowModal(false)} title={`${selectedProperty?.name}`} size='sm' titleAlign='center'>
                <div>
                    <div className="flex flex-wrap justify-center mx-auto mb-5">
                        <div className="w-10/12 sm:w-full">
                            <UpdatePropertyForm property={selectedProperty} />

                            <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                            <PropertyLogAccess property={selectedProperty} />

                            <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                            <PropertyUserAccess property={selectedProperty} />

                            <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                            <PropertyBuildingAccess property={selectedProperty} />

                            <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                            <PropertyObjectAccess property={selectedProperty} />
                        </div>
                    </div>
                </div>
            </Modal>

            <ConfirmModal isOpen={showConfirmModal} close={() => setShowConfirmModal(false)} title={t('page:properties.deleteProperty')} titleAlign='center' type='warning'>
                <div className="bg-red-200 rounded p-1">
                    <p className="text-xs font-semibold my-1 text-black">{t('page:properties.deletePropertyInfo')}</p>
                    <p className="text-xs font-semibold mt-2 mb-1 text-black">{t('page:properties.deletePropertyLogInfo')}</p>
                </div>
                <p className="text-lg font-semibold mt-3">{t('common:confirmDelete')} {selectedProperty?.label ? selectedProperty.label : 'the selected property'}?</p>
                <button
                    onClick={deletePropertyConfirmed}
                    className="bg-status-critical hover:bg-status-critical-h py-2 px-6 mt-3 rounded mx-auto text-sm sm:text-base text-white font-semibold">{t('common:delete')}</button>
            </ConfirmModal>
        </div>
    )
}

export default PropertyTableEdit;