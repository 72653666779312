import React, { useEffect, useState } from 'react'
import { EntityLogDto } from '../../../../services/tenant/entity-log/dto/entity-log.dto';
import { Circle, GoogleMap, Marker, OverlayView } from '@react-google-maps/api';
import { getLatLng } from '../../../../utils/getLatLng';
import { useTranslation } from 'react-i18next';
import { ContractorLogAccessDto } from '../../../../services/tenant/contractor-log-user-access/dto/contractor-log-access.dto';

type LatLngType = {
    lat: number;
    lng: number;
}

type Props = {
    entityLog: EntityLogDto | ContractorLogAccessDto;
    setUserWithinRange: (flag: boolean) => void;
    setDistanceFromBoundary: (val: number) => void;
    setLocationError: (flag: boolean) => void;
}

const Proximity = ({
    entityLog,
    setUserWithinRange,
    setDistanceFromBoundary,
    setLocationError
}: Props) => {
    const { t } = useTranslation();

    const [center, setCenter] = useState<LatLngType>({ lat: 0, lng: 0 })
    const [markerUser, setMarkerUser] = useState<LatLngType>();
    const [markerEntity, setMarkerEntity] = useState<LatLngType>();
    const [watchId, setWatchId] = useState<any>(null);    

    useEffect(() => {
        // Try to get position of entity
        const init = async () => {
            const entityPosition = await getEntityPosition();
            
            if (entityPosition) {
                setMarkerEntity(entityPosition);
            }
            else {
                // console.error('No valid entity position availabe');
            }
        }

        init();

        const watchId = navigator.geolocation.watchPosition(
            showPosition,
            showError,
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            }
        );

        setWatchId(watchId);

        return () => {
            navigator.geolocation.clearWatch(watchId);
        }
    }, [])

    useEffect(() => {
        if (markerUser && markerEntity) {
            checkProximity(markerUser)
        }
    }, [markerUser, markerEntity])

    const showPosition = (position: GeolocationPosition) => {
        const userLocation = {
            lat: position?.coords?.latitude,
            lng: position?.coords?.longitude
        };
        setCenter(userLocation);
        setMarkerUser(userLocation)
        checkProximity(userLocation)
    }

    const showError = (error: any) => {
        // console.error('Error obtaining location: ', error);
        setLocationError(true);
    }

    const checkProximity = async (userLocation: LatLngType) => {
        if (markerUser && markerEntity) {
            setMarkerUser(userLocation);

            const distance = calculateDistance(userLocation, markerEntity);
            const proximityRange = entityLog.proximity_range.split('-')[1];

            const distanceFromBoundary = Math.round(Math.abs(distance - Number(proximityRange)));

            setDistanceFromBoundary(distanceFromBoundary);

            if (distance <= Number(proximityRange)) {
                setUserWithinRange(true);
            }
            else {
                setUserWithinRange(false);
            }
        }
        else {
            // console.log('Entity position not set, unable to check proximity')
        }
    }

    const calculateDistance = (userLocation: LatLngType, targetLocation: LatLngType) => {
        const { google } = window;
        return google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(userLocation.lat, userLocation.lng),
            new google.maps.LatLng(targetLocation.lat, targetLocation.lng)
        )
    }

    const getEntityPosition = async () => {
        if (entityLog.property_object_id) {
            if (entityLog.property_object_longitude && entityLog.property_object_latitude) {
                return {
                    lat: Number(entityLog.property_object_latitude),
                    lng: Number(entityLog.property_object_longitude)
                }
            }
            else if (entityLog.property_object_address) {
                return await getLatLng(entityLog.property_object_address)
            }
            return null;
        }
        else if (entityLog.property_building_id) {
            if (entityLog.property_longitude && entityLog.property_latitude) {
                return {
                    lat: Number(entityLog.property_building_latitude),
                    lng: Number(entityLog.property_building_longitude)
                }
            }
            else if (entityLog.property_building_address) {
                return await getLatLng(entityLog.property_building_address);
            }
            return null;
        }
        else if (entityLog.property_id) {
            if (entityLog.property_latitude && entityLog.property_longitude) {
                return {
                    lat: Number(entityLog.property_latitude),
                    lng: Number(entityLog.property_longitude)
                }
            }
            else if (entityLog.property_address) {
                return await getLatLng(entityLog.property_address);
            }
            return null;
        }
    }


    const containerStyle = {
        width: '100%',
        height: '100%'
    }

    const userIcon = {
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 10,
        fillColor: "#4657DB",
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: "#FFFFFF",
    }

    return (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
            >
                {(markerUser && markerEntity) ? (
                    <>
                        <Marker
                            position={markerUser}
                            icon={userIcon}
                        />

                        <Marker
                            position={markerEntity}
                        />
                        <Circle
                            center={markerEntity}
                            radius={Number(entityLog.proximity_range.split('-')[1])}
                            options={{
                                strokeColor: '#4657DB',
                                strokeOpacity: 0.8,
                                strokeWeight: 1,
                                fillColor: '#4657DB',
                                fillOpacity: 0.15,
                            }}
                        />
                    </>

                ) : (
                    <div>{t('page:logs.proximityEntityLocationNotAvailable')}</div>
                )}

            </GoogleMap >
        </>
    )
}

export default Proximity;