import React from 'react'

type Props = {
    count?: number;
    page: number;
    defaultPage: number;
    showFirstButton: boolean;
    showLastButton: boolean;
    hidePrevButton: boolean;
    hideNextButton: boolean;
    setPage?: (val: number) => void;
    onChange?: (newPage: number) => void;
}

const Pagination = ({
    count,
    page,
    defaultPage,
    showFirstButton,
    showLastButton,
    hidePrevButton,
    hideNextButton,
    setPage,
    onChange,
}: Props) => {

    const handlePageChange = (newPage: number) => {
        if (onChange) {
            onChange(newPage);
        }
        if (setPage) {
            setPage(newPage);
        }
    }

    return (
        <>
            {(count && count > 0) ? (
                <div className="bg-primary-blue-5 text-black w-fit py-2 px-4 rounded-md flex items-center text-xs">
                    <button
                        className={`material-symbols-outlined pr-1 ${hidePrevButton && 'text-primary-blue-25'}`}
                        style={{ fontSize: '1.3rem' }}
                        disabled={hidePrevButton}
                        onClick={() => handlePageChange(page - 1)}
                    >chevron_left</button>
                    <div>
                        <span>Sida {page} av {count}</span>
                    </div>

                    <button
                        className={`material-symbols-outlined pl-1 ${hideNextButton && 'text-primary-blue-25'}`}
                        style={{ fontSize: '1.3rem' }}
                        disabled={hideNextButton}
                        onClick={() => handlePageChange(page + 1)}
                    >chevron_right</button>
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

export default Pagination