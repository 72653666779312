import React, { Fragment, useState } from 'react'
import { Plans } from '../../../services/enum/plans'
import { useTranslation } from 'react-i18next';
import KeenCarousel from '../keen-slider';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css'
import './styles.css'
import { Pricing } from '../../../constants/pricing';

type Props = {
    setSelectedPlan: (val: Plans) => void;
    selectedPlan: Plans
}

const PlanSelector = ({
    setSelectedPlan,
    selectedPlan,
}: Props) => {
    const { t } = useTranslation();

    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const [loaded, setLoaded] = useState<boolean>(false);

    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
        initial: 0,
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
        created() {
            setLoaded(true);
        },
        slides: {
            perView: 1,
            spacing: 5
        },
        breakpoints: {
            '(min-width: 640px)': {
                slides: {
                    perView: 2.5,
                    spacing: 25
                }
            }
        },
    })

    return (
        <div className="navigation-wrapper">
            <div ref={sliderRef} className="keen-slider">
                {(Object.values(Plans) as Array<keyof typeof Plans>).map((val) => {
                    if (val !== Plans.None) {
                        return (
                            <Fragment key={val}>
                                <div className={`keen-slider__slide number-slide rounded-lg bg-primary-blue-5 ${selectedPlan === val && 'border-2 border-primary-blue'}`}>
                                    <div className="w-full"
                                        onClick={() => setSelectedPlan(val as Plans)}
                                    >
                                        <p className="text-center font-bold text-xs sm:text-sm">{t(`common:enums:plans.${Plans[val]}`)}</p>
                                        {Plans[val] === 'Free' && (
                                            <>
                                                <p className="text-xs text-center mt-2 ">{Pricing.Free}{t('common:currencySign')} / {t('common:monthLower')}</p>
                                                <p className="text-xs text-center mt-2">Gratis test i 6 månader</p>
                                                <a href={`${process.env.REACT_APP_LOGGELLO_LANDING_URL}/plans`} target='_blank'>
                                                    <p className="text-[10px] sm:text-xs mt-5 text-center text-blue-500 hover:underline hover:text-blue-600">{t('common:readMore')}</p>
                                                </a>
                                            </>
                                        )}
                                        {Plans[val] === 'Pro' && (
                                            <>
                                                <p className="text-xs text-center mt-2 ">{Pricing.Pro}{t('common:currencySign')} / {t('common:monthLower')}</p>
                                                <p className="text-xs text-center mt-2">Ingen uppsägningstid</p>
                                                <a href={`${process.env.REACT_APP_LOGGELLO_LANDING_URL}/plans`} target='_blank'>
                                                    <p className="text-[10px] sm:text-xs mt-5 text-center text-blue-500 hover:underline hover:text-blue-600">{t('common:readMore')}</p>
                                                </a>
                                            </>
                                        )}
                                        {Plans[val] === 'Premium' && (
                                            <>
                                                <p className="text-xs text-center mt-2 ">{Pricing.Premium}{t('common:currencySign')} / {t('common:monthLower')}</p>
                                                <p className="text-xs text-center mt-2">Obegränsat antal loggar</p>
                                                <a href={`${process.env.REACT_APP_LOGGELLO_LANDING_URL}/plans`} target='_blank'>
                                                    <p className="text-[10px] sm:text-xs mt-5 text-center text-blue-500 hover:underline hover:text-blue-600">{t('common:readMore')}</p>
                                                </a>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Fragment>
                        )
                    }
                })}
            </div>
            {loaded && instanceRef.current && (
                <>
                    <Arrow
                        left
                        onClick={(e: any) =>
                            e.stopPropagation() || instanceRef.current?.prev()
                        }
                        disabled={currentSlide === 0}
                    />

                    <Arrow
                        onClick={(e: any) =>
                            e.stopPropagation() || instanceRef.current?.next()
                        }
                        disabled={
                            currentSlide ===
                            instanceRef.current.track.details.slides.length - 1
                        }
                    />
                </>
            )}
        </div>
    )
}

function Arrow(props: {
    disabled: boolean;
    left?: boolean;
    onClick: (e: any) => void;
}) {
    const disabled = props.disabled ? " arrow--disabled" : "";
    return (
        <svg
            onClick={props.onClick}
            className={`arrow sm:hidden ${props.left ? "arrow--left" : "arrow--right"
                } ${disabled}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            {props.left && (
                <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
            )}
            {!props.left && (
                <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
            )}
        </svg>
    )
}

export default PlanSelector