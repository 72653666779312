
import { Dialog } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";

type Props = {
    isOpen: boolean;
    close: () => void;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    type?: 'warning' | 'auth' | 'success';
    titleAlign?: 'left' | 'center' | 'right';
    title: string;
    children: React.ReactNode;
    titleSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
}

const Modal = ({
    isOpen,
    close,
    size = 'sm',
    type,
    titleAlign = 'left',
    title,
    children,
    titleSize = 'xl',
}: Props) => {

    // Map material symbol based on passed type
    let symbol = '';
    switch (type) {
        case 'warning':
            symbol = 'warning';
            break;
        case 'auth':
            symbol = 'lock';
            break;
        case 'success':
            symbol = 'check_circle';
            break;
        default:
            symbol = ''
            break;
    };

    return isOpen ? (
        <Dialog fullWidth maxWidth={size} className={clsx("relative z-[75] mx-auto", {
            // "max-w-md": size === "small",
            // "max-w-xl": size === "medium",
            // "max-w-3xl": size === "large",
            // "max-w-6xl": size === "xlarge",

        })} open={isOpen} onClose={close}>
            <div className={clsx(
                "flex flex-col justify-center w-full h-full transform text-left shadow-xl transition-all max-h-[85vh] min-h-[35vh] overflow-y-scroll rounded-lg", {
            })}>
                <div className="flex flex-col flex-grow relative justify-center overflow-y-scroll">
                    <div className="sm:px-10 px-1 pb-5 overflow-y-scroll">
                        <div className="flex justify-end mt-3">
                            <button
                                className="material-symbols-outlined bg-primary-blue-10 hover:bg-primary-blue-15 p-1 rounded-md"
                                onClick={close}
                                style={{ fontSize: '1.3rem' }}
                            >close</button>
                        </div>
                        <span className="material-symbols-outlined mt-[-5px] text-center" style={{ fontSize: '2.5rem' }}>{symbol}</span>
                        <h3 className={clsx("font-bold text-black capitalize", {
                            "text-left": titleAlign === 'left',
                            "text-center": titleAlign === 'center',
                            "text-right": titleAlign === 'right',
                            "text-xs": titleSize === 'xs',
                            "text-sm": titleSize === 'sm',
                            "text-base": titleSize === 'md',
                            "text-lg": titleSize === 'lg',
                            "text-xl": titleSize === 'xl',
                            "text-2xl": titleSize === '2xl',
                            "sm:text-3xl text-xl": titleSize === '3xl',
                        })}>{title}</h3>
                        <div className="w-full h-full mt-3">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    ) : (null)
}

export default Modal;