import React, { useEffect, useRef, useState } from 'react'
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import { PropertyDto } from '../../../../services/tenant/property/dto/property.dto';
import InputCheckbox from '../../../atoms/select-checkbox';
import { PropertyBuildingDto } from '../../../../services/tenant/property-building/dto/property-building.dto';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetAllPropertyBuildingsQuery, useGetPropertyBuildingsQuery, useUpdateManyPropertyBuildingsMutation } from '../../../../services/tenant/property-building/property-building.service';
import { UpdatePropertyBuildingDto } from '../../../../services/tenant/property-building/dto/update-property-building.dto';
import ConfirmModal from '../../../molecules/confirm-modal';
import { useTranslation } from 'react-i18next';
import { debounce } from '@mui/material';
import { useGetAllPropertyBuildingUserAccessByIdQuery } from '../../../../services/tenant/property-building-user-access/property-building-user-access.service';
import { Order } from '../../../../services/enum/order';
import { PageDto } from '../../../../services/dto/page.dto';
import { SelectCustomStyles } from '../../../atoms/select-custom-styles/select-custom-styles';
import SecondaryButton from '../../../atoms/secondary-button';


type Props = {
    property?: PropertyDto;
}

type SelectOption = {
    label: string;
    value: string;
    selected: boolean;
    property_building_id: string;
}

const PropertyBuildingAccess = ({ property }: Props) => {
    const { t } = useTranslation();

    const [updateManyPropertyBuildings, response] = useUpdateManyPropertyBuildingsMutation();

    const [error, setError] = useState<string>('');
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

    const [allOptions, setAllOptions] = useState<SelectOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
    const [search, setSearch] = useState<string>();
    const [page, setPage] = useState<number>(1);

    const debouncedSearch = useRef(debounce((search: string) => setSearch(search), 500)).current;

    // Get all buildings 
    const { data: propertyBuildings } = useGetPropertyBuildingsQuery({
        take: 15,
        page: page,
        order: Order.ASC,
        sortBy: 'label',
        search: search
    });

    useEffect(() => {
        if (propertyBuildings) {
            // INIT
            if (allOptions.length === 0) {
                initOptions(propertyBuildings);
            }
            // SEARCH
            else if (search) {
                handleSearchOptions(search, propertyBuildings);
            }
            // PAGINATION
            else {
                handlePagination(propertyBuildings);
            }
        }
    }, [propertyBuildings])

    // Set initial options and selected options
    const initOptions = (propertyBuildings: PageDto<PropertyBuildingDto>) => {
        const allOptions: SelectOption[] = [];
        const selectedOptions: SelectOption[] = [];

        if (propertyBuildings && allOptions.length === 0) {
            propertyBuildings.data.forEach(pb => {
                let tempObj: SelectOption = {
                    label: pb.label,
                    value: pb.id,
                    selected: pb.property_id === property?.id ? true : false,
                    property_building_id: pb.id,
                };
                allOptions.push(tempObj);
                // When match between user and subsidiary id, push to selectedOptions
                if (tempObj.selected) {
                    selectedOptions.push(tempObj);
                }
            })
            setAllOptions(allOptions);
            setSelectedOptions(selectedOptions);
        }
    }

    // Handle options when search query is provided
    const handleSearchOptions = (search: string, propertyBuildings: PageDto<PropertyBuildingDto>) => {
        if (propertyBuildings && search) {
            const allOptions: SelectOption[] = [];
            const selectedOptions: SelectOption[] = [];
            setPage(1);
            propertyBuildings.data.forEach(pb => {
                let tempObj: SelectOption = {
                    label: pb.label,
                    value: pb.id,
                    selected: pb.property_id === property?.id ? true : false,
                    property_building_id: pb.id,
                };
                allOptions.push(tempObj);
                // When match between user and subsidiary id, push to selectedOptions
                if (tempObj.selected) {
                    selectedOptions.push(tempObj);
                }
            })
            setAllOptions([...allOptions]);
            setSelectedOptions([...selectedOptions]);
        }
    }

    // Handling options with pagination
    const handlePagination = (propertyBuildings: PageDto<PropertyBuildingDto>) => {
        if (propertyBuildings) {
            // Get existing options
            const existingOptions = [...allOptions];
            // Get existing selected options
            const existingSelected = [...selectedOptions];

            // Remove duplicates
            const uniqueArr = existingOptions.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.property_building_id === value.property_building_id
                ))
            )

            // Add options
            propertyBuildings.data.forEach(pb => {
                const obj = {
                    label: pb.label,
                    value: pb.id,
                    selected: pb.property_id === property?.id ? true : false,
                    property_building_id: pb.id,
                }
                // Make sure no duplicates are added
                if (!uniqueArr.some(e => e.property_building_id === obj.property_building_id)) {
                    uniqueArr.push(obj);
                    if (obj.selected && !existingSelected.some(e => e.property_building_id === obj.property_building_id)) {
                        existingSelected.push(obj);
                    }
                }
            });

            setAllOptions(uniqueArr);
            setSelectedOptions(existingSelected);
        }
    }

    // Handle scroll event to fetch more options
    const handleScroll = (event: any) => {
        if (propertyBuildings && propertyBuildings.meta.hasNextPage) {
            if (!search) {
                setPage(page ? page + 1 : 1);
            }
        }
    }

    // Selected option(s) will get passed
    const handleSelectChange = (options: any) => {

        const allSelectedOptions = [...selectedOptions]; // All selected options    

        if (options.length > 0) {
            // If options array is shorter than selectedOptions array, a property object has been removed
            if (options.length < allSelectedOptions.length) {

                allSelectedOptions.forEach((option: SelectOption) => {
                    // If current option not in passed options arr, remove property object from selectedOptions array
                    if (!options.includes(option) && option.selected === false) {
                        const index = allSelectedOptions.findIndex((e) => e.value === option.value);
                        if (index !== -1) {
                            allSelectedOptions.splice(index, 1);
                            setError('');
                        }
                    }
                    else {
                        /* 
                            Can't remove a property building that currently belong to the given property
                            A property building must always belong to a property so we can't allow that
                        */
                        setError(t('page:properties.buildingNeedsToBelongToPropertyError'))
                    }
                })
                setSelectedOptions(allSelectedOptions); // Update selectedOptions state
            }
            // If options array is not shorter than selectedOptions array, add option to selectedOptions array
            else {
                setError('');

                options.forEach((option: SelectOption) => {
                    // Add option if not already exists in allSelectedOptions array
                    if (!allSelectedOptions.includes(option)) {
                        let tempObj: SelectOption = {
                            label: option.label,
                            value: option.value,
                            selected: option.selected,
                            property_building_id: option.property_building_id,
                        };
                        allSelectedOptions.push(tempObj);
                    }
                });
                setSelectedOptions(allSelectedOptions);
            }
        }
        else {
            setError(t('page:properties.buildingNeedsToBelongToPropertyError'))
        }
        // else {
        //     setSelectedOptions([])
        // }
    }

    const prepareUpdate = () => {
        /* 
            Since we set an initial state of the selected option (true/false) we want to find
            all options with an initial state of true that are not in selectedOptions array
            since they will have an existing record in the database that needs to be removed
         */

        let updateArray: SelectOption[] = [];

        allOptions.forEach((option) => {

            // Check if selectedOptions includes an option from allOptions
            // If it does and option.selected = false, set selected = true so we know it should be added
            const includes = selectedOptions.some((e) => e.property_building_id === option.property_building_id);
            if (includes) {
                if (option.selected === false) {
                    option.selected = true; // Set selected = true
                    updateArray.push(option); // Add to updateArray
                }
            }

            // If current option.selected = true and it's not included in selectedOptions we set it to false
            // so we know it should be removed since an existing relation exists for this option
            else if (option.selected === true) {
                // If option.selected is true and it's not included in selectedOptions we want to set selected = false
                if (!selectedOptions.includes(option)) {
                    option.selected = false; // Set selected = false
                    updateArray.push(option); // Add to updatedArray
                }
            }
        })
        return updateArray;
    }

    const handleUpdatePropertyBuildingAccess = async () => {
        setShowConfirmModal(false);
        setError('');
        const updateArray = prepareUpdate();

        let propertyBuildingUpdateDtos: UpdatePropertyBuildingDto[] = [];

        // Loop through each property building to be updated
        // If selected is true => set property_id
        // If selected if false => set property_id null
        updateArray.forEach((object) => {
            let tempObj: UpdatePropertyBuildingDto = {
                id: object.property_building_id,
                property_id: object.selected ? property?.id : null,
            }
            propertyBuildingUpdateDtos.push(tempObj);
        });

        if (propertyBuildingUpdateDtos.length > 0) {
            try {
                const response = await updateManyPropertyBuildings(propertyBuildingUpdateDtos).unwrap();

                if (response.success) {
                    NotificationManager.success(t('common:notifications.updated'));
                }
                else {
                    NotificationManager.info(t('common:notifications.updateFailed'));
                }

            }
            catch (error) {
                NotificationManager.error(t('common:notifications.updateFailed'))
            }
        }
        else {
            NotificationManager.info(t('common:notifications.noChanges'));
        }
    }


    return (
        <>
            <div className="rounded-lg w-full mx-auto mb-2">
                <>
                    <p className="my-2 text-xs sm:text-sm font-semibold">{t('page:properties.buildingBelongsTo')} <span className="uppercase font-extrabold">{property?.label}</span></p>
                    <Select
                        className="z-30 capitalize mb-2"
                        value={selectedOptions}
                        options={allOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        onChange={(options) => handleSelectChange(options)}
                        controlShouldRenderValue={true}
                        isClearable={false}
                        backspaceRemovesValue={false}
                        maxMenuHeight={200}
                        placeholder={t('common:select')}
                        noOptionsMessage={() => t('page:properties.noBuildingsFound')}
                        components={{
                            // @ts-ignore
                            Option: InputCheckbox
                        }}
                        onInputChange={(value) => debouncedSearch(value)}
                        captureMenuScroll={true}
                        onMenuScrollToBottom={(val) => handleScroll(val)}
                        filterOption={null}
                        styles={SelectCustomStyles}
                    />
                    {error && (<span className="text-xs text-status-critical">{error}</span>)}
                    <SecondaryButton
                        text={t('common:update')}
                        size={'medium'}
                        align={'center'}
                        wide={true}
                        onClick={handleUpdatePropertyBuildingAccess}
                    />
                </>
            </div>

            {/* FIX */}
            <ConfirmModal isOpen={showConfirmModal} close={() => setShowConfirmModal(false)} title={t('common:update')} titleSize='sm' titleAlign='center' type='info' size='sm'>
                <p className="text-xs">{t('page:properties.propertyBuildingUpdateInfo')}</p>
                <p className="text-sm font-semibold mt-5">{t('common:confirmProceed')}</p>
                <button
                    onClick={handleUpdatePropertyBuildingAccess}
                    className="bg-status-warning hover:bg-status-warning-h py-2 px-1 sm:px-6 mt-3 text-sm rounded mx-auto text-white font-semibold">{t('common:update')}</button>
            </ConfirmModal>
        </>
    )
}

export default PropertyBuildingAccess;