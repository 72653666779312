import React, { useEffect, useRef, useState } from 'react'
import { useGetUsersQuery } from '../../../../services/tenant/user/user.service';
import { UserDto } from '../../../../services/tenant/user/dto/user.dto';
import { NotificationManager } from 'react-notifications';
import Select, { components } from 'react-select';
import InputCheckbox from '../../../atoms/select-checkbox';
import { PropertyBuildingDto } from '../../../../services/tenant/property-building/dto/property-building.dto';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { UpdatePropertyBuildingUserAccessDto } from '../../../../services/tenant/property-building-user-access/dto/update-property-building-user-access.dto';
import { useGetAllPropertyBuildingUserAccessByIdQuery, useUpdateManyPropertyBuildingUserAccessMutation } from '../../../../services/tenant/property-building-user-access/property-building-user-access.service';
import { useTranslation } from 'react-i18next';
import { PageDto } from '../../../../services/dto/page.dto';
import { Order } from '../../../../services/enum/order';
import { debounce } from '../../../../utils/debounce';
import { SelectCustomStyles } from '../../../atoms/select-custom-styles/select-custom-styles';
import SecondaryButton from '../../../atoms/secondary-button';

type Props = {
    propertyBuilding?: PropertyBuildingDto;
}

type SelectOption = {
    label: string;
    value: string;
    existing_access: boolean; // Access when the records is fetched
    user_id: string;
    property_building_user_access_id?: string;
}

const PropertyBuildingUserAccess = ({ propertyBuilding }: Props) => {
    const { t } = useTranslation();

    // const [updateUsers, response] = useUpdateUsersMutation();
    const [updatePropertyBuildingUserAccess, response] = useUpdateManyPropertyBuildingUserAccessMutation();

    const [allOptions, setAllOptions] = useState<SelectOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
    const [search, setSearch] = useState<string>();
    const [page, setPage] = useState<number>(1);

    const debouncedSearch = useRef(debounce((search: string) => setSearch(search), 500)).current;

    // Get all users for current tenant
    const { data: users } = useGetUsersQuery({
        take: 15,
        page: page,
        order: Order.ASC,
        sortBy: 'name',
        search: search,
    });

    const [skipTokenPropertyBuildingUserAccess, setSkipTokenPropertyBuildingUserAccess] = useState<any>(skipToken);
    // Get all PropertyBuildingUserAccess relations
    const { data: propertyBuildingUserAccess } = useGetAllPropertyBuildingUserAccessByIdQuery(skipTokenPropertyBuildingUserAccess);

    useEffect(() => {
        if (propertyBuilding) {
            setSkipTokenPropertyBuildingUserAccess(propertyBuilding.id);
        }
    }, [propertyBuilding])

    useEffect(() => {
        if (users && propertyBuildingUserAccess) {
            // INIT
            if (allOptions.length === 0) {
                initOptions(users);
            }
            // SEARCH
            else if (search) {
                handleSearchOptions(search, users);
            }
            // PAGINATION
            else {
                handlePagination(users);
            }
        }
    }, [users, propertyBuildingUserAccess])

    // Set initial options and selected options
    const initOptions = (users: PageDto<UserDto>) => {
        if (propertyBuildingUserAccess && allOptions.length === 0) {
            const options = users.data.map(user => ({
                value: user.id,
                label: user.name,
                existing_access: propertyBuildingUserAccess.find(e => e.user_id === user.id) ? true : false,
                property_building_user_access_id: propertyBuildingUserAccess.find(e => e.user_id === user.id)?.id,
                user_id: user.id
            }))
            setAllOptions(options);
        }

        if (propertyBuildingUserAccess) {
            const selectOptions: SelectOption[] = propertyBuildingUserAccess.map(pbua => ({
                value: pbua.user_id,
                label: pbua.user_name,
                existing_access: true,
                property_building_user_access_id: pbua.id,
                user_id: pbua.user_id
            }))
            setSelectedOptions(selectOptions);
        }
    }

    // Handle options when search query is provided
    const handleSearchOptions = (search: string, users: PageDto<UserDto>) => {
        if (propertyBuildingUserAccess && search) {
            const allOptions: SelectOption[] = [];
            const selectedOptions: SelectOption[] = [];
            setPage(1)
            users.data.forEach(user => {
                let tempObj: SelectOption = {
                    label: user.name,
                    value: user.id,
                    existing_access: propertyBuildingUserAccess.find(e => e.user_id === user.id) ? true : false,
                    property_building_user_access_id: propertyBuildingUserAccess.find(e => e.user_id === user.id)?.id,
                    user_id: user.id
                };
                allOptions.push(tempObj);
                // When match between user and subsidiary id, push to selectedOptions
                if (tempObj.existing_access) {
                    selectedOptions.push(tempObj);
                }
            })
            setAllOptions([...allOptions]);
            setSelectedOptions([...selectedOptions]);
        }
    }

    // Handling options with pagination
    const handlePagination = (users: PageDto<UserDto>) => {
        if (propertyBuildingUserAccess) {
            // Get existing options
            const existingOptions = [...allOptions];
            // Get existing selected options
            const existingSelected = [...selectedOptions];

            // Remove duplicates
            const uniqueArr = existingOptions.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.user_id === value.user_id
                ))
            )

            // Add options
            users.data.forEach(user => {
                const obj = {
                    value: user.id,
                    label: user.name,
                    existing_access: propertyBuildingUserAccess.find(e => e.user_id === user.id) ? true : false,
                    property_building_user_access_id: propertyBuildingUserAccess.find(e => e.user_id === user.id)?.id,
                    user_id: user.id
                }
                // Make sure no duplicates are added
                if (!uniqueArr.some(e => e.user_id === obj.user_id)) {
                    uniqueArr.push(obj);
                    if (obj.existing_access && !existingSelected.some(e => e.user_id === obj.user_id)) {
                        existingSelected.push(obj);
                    }
                }
            });
            setAllOptions(uniqueArr);
            setSelectedOptions(existingSelected);
        }
    }

    // Handle scroll event to fetch more options
    const handleScroll = (event: any) => {
        if (users && users.meta.hasNextPage) {
            if (!search) {
                setPage(page ? page + 1 : 1);
            }
        }
    }

    // Selected option(s) will get passed
    const handleSelectChange = (options: any) => {

        const allSelectedOptions = [...selectedOptions]; // All selected options

        if (options.length > 0) {
            // If options array is shorter than selectedOptions array, a user has been removed
            if (options.length < allSelectedOptions.length) {

                allSelectedOptions.forEach((option: SelectOption) => {
                    // If current option not in passed options arr, remove user from selectedOptions array
                    if (!options.includes(option)) {
                        const index = allSelectedOptions.findIndex((e) => e.value === option.value);
                        if (index !== -1) {
                            allSelectedOptions.splice(index, 1);
                        }
                    }
                })
                setSelectedOptions(allSelectedOptions); // Update selectedOptions state
            }
            // If options array is not shorter than selectedOptions array, add option to selectedOptions array
            else {
                options.forEach((option: SelectOption) => {
                    // Add option if not already exists in allSelectedOptions array
                    if (!allSelectedOptions.includes(option)) {
                        let tempObj: SelectOption = {
                            label: option.label,
                            value: option.value,
                            existing_access: option.existing_access,
                            property_building_user_access_id: option.property_building_user_access_id,
                            user_id: option.user_id
                        };
                        allSelectedOptions.push(tempObj);
                    }
                });
                setSelectedOptions(allSelectedOptions);
            }
        }
        else {
            setSelectedOptions([])
        }
    }

    const handleUpdateUserAccess = async () => {
        let recordsToBeUpdated: UpdatePropertyBuildingUserAccessDto[] = [];
        let existingRecords: SelectOption[] = [];

        // Loop through selectedOptions to get records to be created
        selectedOptions.forEach((record) => {
            // If the property existing_access is not true, it's a completely new record to be added
            if (record.existing_access === false) {
                let tempObj: UpdatePropertyBuildingUserAccessDto = {
                    access: true,
                    user_id: record.user_id,
                    property_building_id: propertyBuilding!.id,
                    property_building_user_access_id: record.property_building_user_access_id,
                }
                recordsToBeUpdated.push(tempObj);
            }
            else if (record.existing_access === true) {
                existingRecords.push(record);
            }
        })

        // Loop through all options and find records with property existing_access true
        // If the records does not exist in selectedOptions, it means it has been removed has should be deleted
        allOptions.forEach((option) => {
            if (option.existing_access === true) {
                if (!existingRecords.some((e) => e.property_building_user_access_id === option.property_building_user_access_id)) {
                    let tempObj: UpdatePropertyBuildingUserAccessDto = {
                        access: false,
                        user_id: option.user_id,
                        property_building_id: propertyBuilding!.id,
                        property_building_user_access_id: option.property_building_user_access_id,
                    }
                    recordsToBeUpdated.push(tempObj);
                }
            }
        });

        if (recordsToBeUpdated.length > 0) {
            try {
                const response = await updatePropertyBuildingUserAccess(recordsToBeUpdated).unwrap();

                if (response.success) {
                    NotificationManager.success(t('common:notifications.updated'));
                }
                else {
                    NotificationManager.info(t('common:notifications.updateFailed'));
                }

            }
            catch (error) {
                NotificationManager.error(t('common:notifications.updateFailed'))
            }
        }
    }


    return (
        <>
            <div className="rounded-lg w-full mx-auto mb-2">
                <p className="my-2 text-xs sm:text-sm font-semibold">{t('page:properties.userAccess')} <span className="font-extrabold uppercase">{propertyBuilding?.label}</span></p>
                <Select
                    className="z-40 capitalize mb-2"
                    value={selectedOptions}
                    options={allOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(options) => handleSelectChange(options)}
                    controlShouldRenderValue={true}
                    isClearable={false}
                    backspaceRemovesValue={false}
                    maxMenuHeight={200}
                    placeholder={t('common:select')}
                    noOptionsMessage={() => t('page:properties.noUsersFound')}
                    components={{
                        // @ts-ignore
                        Option: InputCheckbox
                    }}
                    onInputChange={(value) => debouncedSearch(value)}
                    captureMenuScroll={true}
                    onMenuScrollToBottom={(val) => handleScroll(val)}
                    filterOption={null}
                    styles={SelectCustomStyles}
                />
                <SecondaryButton
                    text={t('common:update')}
                    size={'medium'}
                    align={'center'}
                    wide={true}
                    onClick={handleUpdateUserAccess}
                />
            </div>
        </>
    )
}

export default PropertyBuildingUserAccess;