import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../redux/hooks';
import { getCurrentUser } from '../../../../redux/slices/user.slice';
import Modal from '../../../molecules/modal';
import CreateCustomLog from '../../../molecules/create-custom-log';
import LogTemplates from '../../../molecules/log-templates';
import { LogTemplateDto } from '../../../../services/tenant/log-template/dto/log-template.dto';
import { Grow } from '@mui/material';
import EditCustomLog from '../../../molecules/edit-custom-log';
import { useTranslation } from 'react-i18next';
import { Roles } from '../../../../services/enum/roles';
import { getTenant } from '../../../../redux/slices/tenant.slice';
import { TenantTypes } from '../../../../services/enum/tenant-types';
import PrimaryButton from '../../../atoms/primary-button';
import LogsPropertyOwner from '../../../organisms/log-components/log-structure/logs-property-owner';
import LogsContractor from '../../../organisms/friendship/contractor/logs-contractor';

type Props = {}

const LogsPage = (props: Props) => {

    const { t } = useTranslation()
    const { currentUser } = useAppSelector(getCurrentUser);
    const { tenant } = useAppSelector(getTenant);

    const [showTabs, setShowTabs] = useState<boolean>(true);
    const [selectedTab, setSelectedTab] = useState<string>('logs');
    const [selectedSubTab, setSelectedSubTab] = useState<string>('internalLogs');

    // State for the selected row clicked in AllLogTemplatesTable
    const [selectedLogTemplate, setSelectedLogTemplate] = useState<LogTemplateDto>();
    // State for create modal
    const [showCreateLogTemplate, setShowCreateLogTemplate] = useState<boolean>(false);
    // State for edit modal
    const [showEditLogTemplate, setShowEditLogTemplate] = useState<LogTemplateDto>();

    return (
        <div className="w-full sm:w-11/12 p-1 mx-auto mb-10">
            <Grow in={selectedTab ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                <div>
                    {(selectedTab && showTabs) && (
                        <>
                            {currentUser && (currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) ? (
                                <div className="text-sm mb-5 text-center border-b-2 text-black border-primary-blue-5">
                                    <ul className="flex flex-wrap -mb-px justify-center sm:justify-normal">
                                        <li onClick={() => setSelectedTab('logs')} className={`transition-all flex items-center pl-2 pr-4 pb-4 pt-4 rounded-t-lg mr-2 cursor-pointer ${selectedTab === 'logs' ? 'border-blue-600 active text-blue-600 border-b-2' : ''}`}>
                                            <span className="material-symbols-outlined align-sub" style={{ fontSize: '1.3rem' }}>note_stack</span>
                                            <span className="inline-block pl-1">{t('common:logs')}</span>
                                        </li>
                                        <li onClick={() => setSelectedTab('log_templates')} className={`transition-all flex items-center pl-2 pr-4 pb-4 pt-4 rounded-t-lg mr-2 cursor-pointer ${selectedTab === 'log_templates' ? 'border-blue-600 active text-blue-600 border-b-2' : ''}`}>
                                            <span className="material-symbols-outlined align-sub" style={{ fontSize: '1.3rem' }}>sticky_note_2</span>
                                            <span className="inline-block pl-1">{t('page:logs.logTemplates')}</span>
                                        </li>
                                    </ul>
                                </div>
                            ) : (
                                <div className="flex justify-center items-center gap-x-2 text-black mb-5">
                                    <span className="material-symbols-outlined" style={{ fontSize: '2rem' }}>note_stack</span>
                                    <h1 className="inline-block text-3xl font-extrabold">{t('logs')}</h1>
                                </div>
                            )}
                        </>
                    )}

                    {currentUser && tenant.type === TenantTypes.Property_Owner && (
                        <LogsPropertyOwner
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                            showTabs={showTabs}
                            setShowTabs={setShowTabs}
                        />
                    )}

                    {currentUser && tenant.type === TenantTypes.Contractor && (
                        <LogsContractor
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                            showTabs={showTabs}
                            setShowTabs={setShowTabs}

                        />
                    )}


                    {/* LOG TEMPLATES */}
                    {selectedTab === 'log_templates' && (currentUser.role === Roles.Superadmin || currentUser.role === Roles.Admin) && (
                        <>
                            <div className="flex justify-end mt-5 mb-2 w-full mx-auto">
                                <PrimaryButton
                                    text={t('page:logs.createLogTemplate')}
                                    size={'medium'}
                                    startAdornment={'add'}
                                    onClick={() => setShowCreateLogTemplate(true)}
                                />
                            </div>
                            <div className="">
                                <LogTemplates
                                    setSelectedLogTemplate={setSelectedLogTemplate}
                                    selectedLogTemplate={selectedLogTemplate}
                                    setShowEditLogTemplate={setShowEditLogTemplate}
                                />
                            </div>

                            <Modal isOpen={showCreateLogTemplate} close={() => setShowCreateLogTemplate(false)} title={t('page:logs.createLogTemplate')} size='md' titleAlign='center'>
                                <div>
                                    <div className="w-full mx-auto mb-5">
                                        <CreateCustomLog setShowCreateLogTemplate={setShowCreateLogTemplate} />
                                    </div>
                                </div>
                            </Modal>
                            <Modal isOpen={showEditLogTemplate ? true : false} close={() => setShowEditLogTemplate(undefined)} title={showEditLogTemplate ? `${t('common:edit')} ${showEditLogTemplate.name.split('_').join(' ')}` : t('page:logs.editLogTemplate')} size='md' titleAlign='center'>
                                <div>
                                    <div className="w-full mx-auto mb-5">
                                        <div className="">
                                            <EditCustomLog logTemplate={showEditLogTemplate} />
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </>
                    )}
                </div>
            </Grow>
        </div>
    )
}

export default LogsPage;