
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useRef, useState } from "react";
import { dateToLocaleDate, dateToLocaleDateAndHour } from "../../../../../utils/date-time";
import { useGetLogEntriesQuery } from "../../../../../services/tenant/log-entries/log.service";
import { LogEntryQueryParams } from "../../../../../services/tenant/log-entries/dto/log-entry-query-params.dto";
import { LogEntryDto } from "../../../../../services/tenant/log-entries/dto/log-entry.dto";
import { Grow, Input } from "@mui/material";
import Modal from "../../../../molecules/modal";
import ExpandLog from "../expand-log";
import { useTranslation } from "react-i18next";
import { LogTypes } from "../../../../../services/enum/log-types";
import { Order } from "../../../../../services/enum/order";
import { debounce } from "../../../../../utils/debounce";
import { arrayOfObjectsEqual } from "../../../../../utils/array-of-objects-eqal";
import Pagination from "../../../../molecules/pagination";
import { DisplayNameType } from "../../log-structure/log-entries";
import './style.css';
import Checkbox from "../../../../atoms/checkbox";
import { useAppSelector } from "../../../../../redux/hooks";
import { getCurrentUser } from "../../../../../redux/slices/user.slice";
import { Roles } from "../../../../../services/enum/roles";
import { LogTemplateDto } from "../../../../../services/tenant/log-template/dto/log-template.dto";
import { EntityLogDto } from "../../../../../services/tenant/entity-log/dto/entity-log.dto";

type LogFormatType = {
    name: string;
    type: string;
    active: boolean;
}

type Props = {
    selectedEntityLog: EntityLogDto;
    displayName: DisplayNameType;
    selectedLogTemplate: LogTemplateDto;
}

const LogsTable = ({
    selectedEntityLog,
    displayName,
    selectedLogTemplate,
}: Props) => {
    const { t } = useTranslation();
    const { currentUser } = useAppSelector(getCurrentUser);

    // Pagination
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>();
    const [hasNextPage, setHasNextPage] = useState<boolean>();
    const [hasPrevPage, setHasPrevPage] = useState<boolean>();

    const [search, setSearch] = useState<string>();
    const [searchOperation, setSearchOperation] = useState<string>();
    const [sortBy, setSortBy] = useState<string>('created_at');
    const [sortOrder, setSortOrder] = useState<Order>(Order.DESC);
    const [showSortMenu, setShowSortMenu] = useState<boolean>(false); // Sort menu on small screens
    const [showArchived, setShowArchived] = useState<boolean>(false);

    const debouncedSearch = useRef(debounce((search: string) => setSearchOperation(search), 500)).current;

    const [logEntryQueryParams, setLogEntryQueryParams] = useState<LogEntryQueryParams>();

    const [skip, setSkip] = useState<any>(skipToken);
    // Get logs specific to selected property
    const { data: logs, isLoading, error } = useGetLogEntriesQuery(
        logEntryQueryParams ?
            {
                pageOptions: {
                    take: 25,
                    page: page,
                    order: sortOrder,
                    sortBy: 'created_at',
                    search: searchOperation,
                },
                queryParamsLogEntry: logEntryQueryParams
            } : skip
    );

    // State for table headers (from log data)
    const [allTableHeaders, setAllTableHeaders] = useState<LogFormatType[]>([]);
    const [selectedTableHeaders, setSelectedTableHeaders] = useState<LogFormatType[]>([]);
    // State to show/hide active fields
    const [isActiveFilter, setIsActiveFilter] = useState<boolean>(true);

    // State for selected log
    const [selectedLog, setSelectedLog] = useState<LogEntryDto>()
    // State for modal
    const [showModal, setShowModal] = useState<boolean>(false);

    // Determine when to reload colum headers (keep track of selectedEntityLog)
    const [currentCompositeKey, setCurrentCompositeKey] = useState<string>();
    const [shouldFetchLogs, setShouldFetchLogs] = useState<boolean>(false);

    const [mappedLogs, setMappedLogs] = useState<LogEntryDto[]>();

    useEffect(() => {
        if (selectedEntityLog) {
            const logEntryQueryParams: LogEntryQueryParams = {
                log_template_id: selectedEntityLog.log_template_id,
                property_id: selectedEntityLog.property_id,
                property_building_id: selectedEntityLog.property_building_id,
                property_object_id: selectedEntityLog.property_object_id,
                archived: showArchived ? 'true' : 'false',

            };
            setLogEntryQueryParams(logEntryQueryParams);
            setSkip(true);
        }
        setSearch('');
        setSearchOperation('');
    }, [selectedEntityLog, showArchived])

    useEffect(() => {
        const newCompositeKey = getCompositeKey(selectedEntityLog);
        if (currentCompositeKey !== newCompositeKey) {
            setCompositeKey(selectedEntityLog);
            setShouldFetchLogs(true);
        }
    }, [selectedEntityLog])

    useEffect(() => {
        if (shouldFetchLogs && logs && logs.data) {
            extractLogHeaders(logs.data);
            setShouldFetchLogs(false);
        }
    }, [logs, shouldFetchLogs])

    useEffect(() => {
        if (logs) {
            setPage(logs.meta.page);
            setPageCount(logs.meta.pageCount);
            setHasNextPage(logs.meta.hasNextPage);
            setHasPrevPage(logs.meta.hasPreviousPage);

            extractLogHeaders(logs.data);
        }

    }, [logs])

    // Set initial state for selected log headers
    useEffect(() => {
        if (allTableHeaders) {
            if (allTableHeaders.length > 3) {
                // Only add first 3 column headers
                setSelectedTableHeaders(allTableHeaders.slice(0, 3));
            }
            else {
                setSelectedTableHeaders(allTableHeaders);
            }
        }
    }, [allTableHeaders])

    // Sort table headers to place inactive columns in the end of the array
    useEffect(() => {
        if (isActiveFilter === true && allTableHeaders.length > 0) {
            setAllTableHeaders(allTableHeaders.sort(sortTableHeaders))
        }
    }, [isActiveFilter, allTableHeaders])

    // Map log data to log headers
    useEffect(() => {
        if (logs && allTableHeaders) {
            const mappedLogs = mapLogData(logs.data, allTableHeaders);
            setMappedLogs(mappedLogs);
        }
    }, [logs, allTableHeaders])

    // Get headers to be displayed in table
    const extractLogHeaders = (logs: LogEntryDto[]) => {
        const headers: { name: string, type: string, active: boolean }[] = [];
        logs.forEach((item) => {
            item.log_data.forEach((logData) => {
                if (!headers.some((e) => e.name === logData.name)) {
                    headers.push({
                        name: logData.name,
                        type: logData.type,
                        active: logData.active ? true : false,
                    })
                }
            })
        })
        // Manually add static header
        headers.push(
            {
                name: 'created_at',
                type: 'date',
                active: true,
            },
            {
                name: 'created_by',
                type: 'text',
                active: true
            },
            {
                name: 'deleted_at',
                type: 'date',
                active: true
            }
        );

        // Sort headers to be able to compare with allTableHeaders
        const sortedHeaders = headers.sort(sortTableHeaders);

        // If fetched headers are same as allTableHeaders, no need to re-initiate
        const isEqual = arrayOfObjectsEqual(sortedHeaders, allTableHeaders);

        if (!isEqual) {
            setAllTableHeaders(headers);
        }
    }

    const setCompositeKey = (selectedEntityLog: EntityLogDto) => {
        const plaid = selectedEntityLog.id ?? '';
        const pblaid = selectedEntityLog.id ?? '';
        const polaid = selectedEntityLog.id ?? '';

        const compositeKey = `${plaid}-${pblaid}-${polaid}`;
        setCurrentCompositeKey(compositeKey);
    }

    const getCompositeKey = (selectedEntityLog: EntityLogDto) => {
        const plaid = selectedEntityLog.id ?? '';
        const pblaid = selectedEntityLog.id ?? '';
        const polaid = selectedEntityLog.id ?? '';

        const compositeKey = `${plaid}-${pblaid}-${polaid}`;
        return compositeKey;
    }

    const handleSort = (column: string, order: Order) => {
        setSortBy(column);
        setSortOrder(order === Order.ASC ? Order.DESC : Order.ASC);
    }

    const handleSelectedLog = (log: LogEntryDto) => {
        setSelectedLog(log);
        setShowModal(true);
    }

    // Handle what column headers to display in the table
    const handleColumnHeader = (header: string, type: string, active: boolean) => {

        const allCurrentLogHeaders = [...selectedTableHeaders];

        const index = allCurrentLogHeaders.findIndex((e) => e.name === header);

        // Remove column header
        if (index !== -1 && allCurrentLogHeaders.length > 1) {
            allCurrentLogHeaders.splice(index, 1);
            setSelectedTableHeaders(allCurrentLogHeaders);
        }
        // Add column header
        else {
            setSelectedTableHeaders([...allCurrentLogHeaders, { name: header, type, active }])
        }
    }

    // Sort table headers to place inactive fields in the end of the array of table headers
    const sortTableHeaders = (headerA: { name: string, active: boolean }, headerB: { name: string, active: boolean }) => {

        if (headerA.name === 'created_at' && headerB.name !== 'created_at') {
            return 1; // 'created_at' should come after everything else
        }
        else if (headerB.name === 'created_at' && headerA.name !== 'created_at') {
            return -1; // Everything else should come before 'created_at'
        }
        else if (headerA.name === 'created_by' && headerB.name !== 'created_by') {
            return 1;
        }
        else if (headerB.name === 'created_by' && headerA.name === 'created_by') {
            return -1;
        }

        // If neither header is 'created_at' sort based on 'active' property
        return headerA.active === headerB.active ? 0 : headerA.active ? -1 : 1;
    }

    // Map log data to log headers
    const mapLogData = (logs: LogEntryDto[], allTableHeaders: LogFormatType[]) => {
        return logs.map(log => {
            const mappedLogData = allTableHeaders.map(header => {
                const logItem = log.log_data.find(item => item.name === header.name);
                if (logItem) {
                    return logItem;
                }
                else if (header.name === 'created_at') {
                    return { name: header.name, data: log.created_at, type: header.type, active: header.active };
                }
                else if (header.name === 'created_by') {
                    return { name: header.name, data: log.user_name ?? t('common:external'), type: header.type, active: header.active };
                }
                else if (header.name === 'deleted_at') {
                    return { name: header.name, data: log.deleted_at, type: header.type, active: header.active }
                }
                else {
                    return { name: header.name, data: '-', type: header.type, active: header.active };
                }
            });
            return { ...log, log_data: mappedLogData };
        })
    }

    return (
        <div className="w-full mx-auto">
            <div className="hidden md:block ">
                <Grow in={mappedLogs ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                    <div className="relative pb-2">
                        {((mappedLogs && mappedLogs.length > 0) || (mappedLogs?.length === 0 && searchOperation)) && (
                            <>
                                <div className="p-2">
                                    <Input
                                        type="search"
                                        name="search_name"
                                        placeholder={t('common:search')}
                                        disableUnderline={true}
                                        value={search}
                                        className="border rounded-lg text-base px-2 pt-2.5 pb-1 mt-3 w-full md:w-7/12 max-w-2xl bg-white"
                                        onChange={(e) => {
                                            setSearch(e.target.value)
                                            debouncedSearch(e.target.value)
                                        }}
                                        endAdornment={
                                            <span
                                                className="material-symbols-outlined pb-1 text-gray-500 cursor-pointer"
                                                style={{ fontSize: '1.3rem' }}
                                                onClick={() => {
                                                    if (search) {
                                                        setSearch('')
                                                        debouncedSearch('')
                                                    }
                                                }}
                                            >
                                                {search ? 'close' : 'search'}
                                            </span>
                                        }
                                        size="small"
                                    />
                                </div>

                                <div className="flex max-h-64 w-full flex-wrap">
                                    {allTableHeaders
                                        .map((header, idx) => {
                                            if (currentUser.role === Roles.User && header.name === 'deleted_at') return null;
                                            return (
                                                <div
                                                    key={idx}
                                                    onClick={() => handleColumnHeader(header.name, header.type, header.active)}
                                                    className="inline-block relative md:mx-1 rounded-md md:p-1 my-1 cursor-pointer">
                                                    <Checkbox
                                                        size='md'
                                                        checked={selectedTableHeaders.some((e) => e.name === header.name)}
                                                        label={header.name === 'created_at' ? t('common:createdAt') : header.name === 'created_by' ? t('common:createdBy') : header.name === 'deleted_at' ? t('common:archived') : header.name.split('_').join(' ')}
                                                        labelLineThrough={(isActiveFilter && !header.active)}
                                                        disabled={(isActiveFilter && !header.active) ? true : false}
                                                    />
                                                </div>
                                            )
                                        })}
                                </div>

                                <hr className="mx-auto w-8/12 border-primary-blue-5 my-1" />

                                <div
                                    onClick={() => setIsActiveFilter(!isActiveFilter)}
                                    className="inline-block md:mx-1 md:p-1 cursor-pointer px-1">
                                    <Checkbox
                                        size='md'
                                        checked={isActiveFilter}
                                        label={t('page:logs.showActiveFieldsOnly')}
                                    />
                                </div>

                                {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                                    <div
                                        className="inline-block md:mx-1 md:p-1 mt-1 cursor-pointer"
                                        onClick={() => setShowArchived(!showArchived)}>
                                        <Checkbox
                                            size='md'
                                            checked={showArchived}
                                            label={t('page:logs.showArchived')}
                                        />
                                    </div>
                                )}
                            </>
                        )}

                        {((mappedLogs && mappedLogs.length > 0) || (mappedLogs?.length === 0 && searchOperation)) && (
                            <div className="overflow-x-scroll">
                                <table className="w-full text-sm text-left text-black">
                                    <thead className="text-xs lg:text-sm bg-white sticky top-0">
                                        <tr className="capitalize">
                                            {allTableHeaders.map((header, idx) => {
                                                if (isActiveFilter) {
                                                    if (selectedTableHeaders.some((e) => e.name === header.name) && header.active && mappedLogs.length > 0) {
                                                        if (header.name === 'created_at') {
                                                            return (
                                                                <th
                                                                    key={idx}
                                                                    scope="col"
                                                                    className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]"
                                                                    onClick={() => handleSort(header.name, sortOrder)}>
                                                                    {t('common:createdAt')}
                                                                    {(sortBy === header.name) ? (
                                                                        <>
                                                                            {sortOrder === Order.ASC ? (
                                                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                                            ) : (
                                                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                                        </>
                                                                    )}
                                                                </th>
                                                            )
                                                        }
                                                        else if (header.name === 'created_by') {
                                                            return (
                                                                <th
                                                                    key={idx}
                                                                    scope="col"
                                                                    className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]"
                                                                    onClick={() => handleSort(header.name, sortOrder)}>
                                                                    {t('common:createdBy')}
                                                                    {(sortBy === header.name) ? (
                                                                        <>
                                                                            {sortOrder === Order.ASC ? (
                                                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                                            ) : (
                                                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                                        </>
                                                                    )}
                                                                </th>
                                                            )
                                                        }
                                                        else if (header.name === 'deleted_at') {
                                                            return (
                                                                <th
                                                                    key={idx}
                                                                    scope="col"
                                                                    className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]"
                                                                    onClick={() => handleSort(header.name, sortOrder)}>
                                                                    {t('common:archived')}
                                                                </th>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <th
                                                                    key={idx}
                                                                    scope="col"
                                                                    className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[8rem]">
                                                                    {header.name.split('_').join(' ')}
                                                                </th>
                                                            )
                                                        }
                                                    }
                                                }
                                                else {
                                                    if (selectedTableHeaders.some((e) => e.name === header.name) && mappedLogs.length > 0) {
                                                        if (header.name === 'created_at') {
                                                            return (
                                                                <th
                                                                    key={idx}
                                                                    scope="col"
                                                                    className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]"
                                                                    onClick={() => handleSort(header.name, sortOrder)}>
                                                                    {t('common:createdAt')}
                                                                    {(sortBy === header.name) ? (
                                                                        <>
                                                                            {sortOrder === Order.ASC ? (
                                                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                                            ) : (
                                                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                                        </>
                                                                    )}
                                                                </th>
                                                            )
                                                        }
                                                        else if (header.name === 'created_by') {
                                                            return (
                                                                <th
                                                                    key={idx}
                                                                    scope="col"
                                                                    className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]"
                                                                    onClick={() => handleSort(header.name, sortOrder)}>
                                                                    {t('common:createdBy')}
                                                                    {(sortBy === header.name) ? (
                                                                        <>
                                                                            {sortOrder === Order.ASC ? (
                                                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                                            ) : (
                                                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                                        </>
                                                                    )}
                                                                </th>
                                                            )
                                                        }
                                                        else if (header.name === 'deleted_at') {
                                                            return (
                                                                <th
                                                                    key={idx}
                                                                    scope="col"
                                                                    className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]"
                                                                    onClick={() => handleSort(header.name, sortOrder)}>
                                                                    {t('common:archived')}
                                                                </th>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <th
                                                                    key={idx}
                                                                    scope="col"
                                                                    className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[8rem]">
                                                                    {header.name.split('_').join(' ')}
                                                                </th>
                                                            )
                                                        }
                                                    }
                                                }
                                                <th
                                                    key={idx}
                                                    scope="col"
                                                    className="sr-only">
                                                    Status
                                                </th>
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {mappedLogs && mappedLogs.map((logData, idx) => {
                                            return (
                                                <tr key={idx}
                                                    className={`bg-white h-12 max-h-12 text-xs text-left cursor-pointer text-black even:bg-white odd:bg-primary-blue-5 odd:hover:bg-primary-blue-10 even:hover:bg-gray-50 ${logData.deleted_at && '!bg-red-100'}`}
                                                    onClick={() => handleSelectedLog(logData)}>
                                                    {logData.log_data.length > 0 && logData.log_data.map((item, idx) => {
                                                        if (isActiveFilter) {
                                                            if (item.type === 'text' && selectedTableHeaders.some((e) => e.name === item.name && e.active)) {
                                                                return (
                                                                    <td key={idx} className="px-1 sm:px-6 py-2 first-letter:capitalize">{item.data ? item.data : '-'}</td>
                                                                )
                                                            }
                                                            else if (item.type === 'checkbox' && selectedTableHeaders.some((e) => e.name === item.name && e.active)) {
                                                                return (
                                                                    <td
                                                                        key={idx}
                                                                        className={`${item.data ? 'text-black' : 'text-black'} 
                                                                     material-symbols-outlined px-1 sm:px-6 mt-3 capitalize !table-cell`}
                                                                        style={{ fontSize: '1.2rem' }}>
                                                                        {item.data === true ? 'check_circle' : 'cancel'}
                                                                    </td>
                                                                )
                                                            }
                                                            else if (item.type === 'select' && selectedTableHeaders.some((e) => e.name === item.name && e.active)) {
                                                                return (
                                                                    <td key={idx} className="px-1 sm:px-6 py-2 capitalize">{item.data ? item.data : '-'}</td>
                                                                )
                                                            }
                                                            else if (item.type === 'file' && selectedTableHeaders.some((e) => e.name === item.name && e.active)) {
                                                                return (
                                                                    <td key={idx} className="px-1 sm:px-6 py-2 capitalize">{item.data ? item.data.length : '-'}</td>
                                                                )
                                                            }
                                                            else if (item.type === 'date' && selectedTableHeaders.some((e) => e.name === item.name && e.active)) {
                                                                return (
                                                                    <td key={idx} className="px-1 sm:px-6 py-2 capitalize">{item.data ? dateToLocaleDate(item.data) : '-'}</td>
                                                                )
                                                            }
                                                        }
                                                        else {
                                                            if (item.type === 'text' && selectedTableHeaders.some((e) => e.name === item.name)) {
                                                                return (
                                                                    <td key={idx} className="px-1 sm:px-6 py-2 first-letter:capitalize">{item.data ? item.data : '-'}</td>
                                                                )
                                                            }
                                                            else if (item.type === 'checkbox' && selectedTableHeaders.some((e) => e.name === item.name)) {
                                                                return (
                                                                    <td
                                                                        key={idx}
                                                                        className={`${item.data ? 'text-black' : 'text-black'} 
                                                                    material-symbols-outlined px-1 sm:px-6 mt-3 capitalize !table-cell`}
                                                                        style={{ fontSize: '1.2rem' }}>
                                                                        {item.data === true ? 'check_circle' : 'cancel'}
                                                                    </td>
                                                                )
                                                            }
                                                            else if (item.type === 'select' && selectedTableHeaders.some((e) => e.name === item.name)) {
                                                                return (
                                                                    <td key={idx} className="px-1 sm:px-6 py-2 capitalize">{item.data ? item.data : '-'}</td>
                                                                )
                                                            }
                                                            else if (item.type === 'file' && selectedTableHeaders.some((e) => e.name === item.name)) {
                                                                return (
                                                                    <td key={idx} className="px-1 sm:px-6 py-2 capitalize">{item.data ? item.data.length : '-'}</td>
                                                                )
                                                            }
                                                            else if (item.type === 'date' && selectedTableHeaders.some((e) => e.name === item.name)) {
                                                                return (
                                                                    <td key={idx} className="px-1 sm:px-6 py-2 capitalize">{item.data ? dateToLocaleDate(item.data) : '-'}</td>
                                                                )
                                                            }
                                                        }

                                                    })}
                                                    <td key={idx}>
                                                        { (selectedEntityLog.proximity_detection && !logData.proximity_valid) && (
                                                            <span 
                                                            className="material-symbols-outlined text-status-critical" 
                                                            style={{ fontSize: '1.3rem' }}
                                                            title={t('page:logs.proximityNotValid')}
                                                            >location_off</span>
                                                        ) }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        <div className="flex justify-center py-2">
                            <Pagination
                                count={pageCount}
                                page={page}
                                defaultPage={1}
                                showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                                showLastButton={pageCount ? pageCount > 5 : false}
                                hidePrevButton={!hasPrevPage}
                                hideNextButton={!hasNextPage}
                                setPage={setPage}
                            />
                        </div>

                        {searchOperation && logs && logs.data.length < 1 && (
                            <p className="text-sm pl-2 text-black font-semibold">{t('page:properties.noLogsFound')}...</p>
                        )}

                        {(logs && logs.data.length < 1 && !searchOperation) && (
                            <div className="border-t">
                                <p className="text-center text-sm mt-5 text-black">{t('page:properties.noLogsFound')}...</p>
                            </div>
                        )}
                    </div>
                </Grow>
            </div>

            {/* Mobile screen  */}
            <div className="block md:hidden w-full mx-auto mt-1">
                <div className="pt-1">
                    {((mappedLogs && mappedLogs.length > 0) || (mappedLogs?.length === 0 && searchOperation)) && (
                        <>
                            <Input
                                type="search"
                                name="search_name"
                                placeholder={t('common:search')}
                                disableUnderline={true}
                                className="border rounded-md px-2 pt-2.5 pb-1 my-3 w-full bg-white"
                                onChange={(e) => debouncedSearch(e.target.value)}
                                endAdornment={<span className="material-symbols-outlined text-gray-500 pb-1" style={{ fontSize: '1.3rem' }}>search</span>}
                                size="small"
                            />
                            <div className="flex max-h-64 w-full flex-wrap">
                                {allTableHeaders.map((header, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            onClick={() => handleColumnHeader(header.name, header.type, header.active)}
                                            className="inline-block relative mr-3 mt-1 rounded-md my-1 md:p-1 cursor-pointera">
                                            <Checkbox
                                                size='md'
                                                checked={selectedTableHeaders.some((e) => e.name === header.name)}
                                                label={header.name === 'created_at' ? t('common:createdAt') : header.name === 'created_by' ? t('common:createdBy') : header.name === 'deleted_at' ? t('common:archived') : header.name.split('_').join(' ')}
                                                labelLineThrough={(isActiveFilter && !header.active)}
                                                disabled={(isActiveFilter && !header.active) ? true : false}
                                            />
                                        </div>
                                    )
                                })}
                            </div>

                            <hr className="mx-auto w-8/12 border-primary-blue-5 my-1" />

                            <div
                                onClick={() => setIsActiveFilter(!isActiveFilter)}
                                className="inline-block cursor-pointer">
                                <Checkbox
                                    size='md'
                                    checked={isActiveFilter}
                                    label={t('page:logs.showActiveFieldsOnly')}
                                />
                            </div>

                            {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                                <div
                                    className="inline-block mx-1 p-1 mt-1 cursor-pointer"
                                    onClick={() => setShowArchived(!showArchived)}>
                                    <Checkbox
                                        size='md'
                                        checked={showArchived}
                                        label={t('page:logs.showArchived')}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
                {((mappedLogs && mappedLogs.length > 0) || (mappedLogs?.length === 0 && searchOperation)) && (
                    <div className="overflow-x-hidden w-full">
                        {mappedLogs && mappedLogs.map((logData, idx) => {
                            return (
                                <div key={idx} className={`flex flex-wrap my-2 py-2 px-1.5 odd:bg-primary-blue-5 odd:hover:bg-primary-blue-10 even:border border-primary-blue-5 hover:even:bg-gray-50 rounded-md ${logData.deleted_at && '!bg-red-100'}`}>
                                    <div className="flex w-full">
                                        <div className="text-xs font-semibold w-[6rem] min-w-[4rem]">
                                            {allTableHeaders.map((header, idx) => {
                                                if (isActiveFilter) {
                                                    if (selectedTableHeaders.some((e) => e.name === header.name) && header.active) {
                                                        if (header.name === 'created_at') {
                                                            return (
                                                                <p
                                                                    key={idx}
                                                                    className="py-1 pl-1 pr-2 max-w-[100%] min-w-[100%] overflow-hidden whitespace-nowrap text-ellipsis h-6">
                                                                    {t('common:createdAt')}
                                                                </p>
                                                            )
                                                        }
                                                        else if (header.name === 'created_by') {
                                                            return (
                                                                <p
                                                                    key={idx}
                                                                    className="py-1 pl-1 pr-2 max-w-[100%] min-w-[100%] overflow-hidden whitespace-nowrap text-ellipsis h-6">
                                                                    {t('common:createdBy')}
                                                                </p>
                                                            )
                                                        }
                                                        else if (header.name === 'deleted_at') {
                                                            return (
                                                                <p
                                                                    key={idx}
                                                                    className="py-1 pl-1 pr-2 max-w-[100%] min-w-[100%] overflow-hidden whitespace-nowrap text-ellipsis h-6">
                                                                    {t('common:archived')}
                                                                </p>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <p
                                                                    key={idx}
                                                                    className="py-1 pl-1 pr-2 max-w-[100%] min-w-[100%] overflow-hidden whitespace-nowrap text-ellipsis h-6">
                                                                    {header.name.split('_').join(' ')}
                                                                </p>
                                                            )
                                                        }
                                                    }
                                                }
                                                else {
                                                    if (selectedTableHeaders.some((e) => e.name === header.name)) {
                                                        if (header.name === 'created_at') {
                                                            return (
                                                                <p
                                                                    key={idx}
                                                                    className="py-1 pl-1 pr-2 max-w-[100%] min-w-[100%] overflow-hidden whitespace-nowrap text-ellipsis h-6">
                                                                    {t('common:createdAt')}
                                                                </p>
                                                            )
                                                        }
                                                        else if (header.name === 'created_by') {
                                                            return (
                                                                <p
                                                                    key={idx}
                                                                    className="py-1 pl-1 pr-2 max-w-[100%] min-w-[100%] overflow-hidden whitespace-nowrap text-ellipsis h-6">
                                                                    {t('common:createdBy')}
                                                                </p>
                                                            )
                                                        }
                                                        else if (header.name === 'deleted_at') {
                                                            return (
                                                                <p
                                                                    key={idx}
                                                                    className="py-1 pl-1 pr-2 max-w-[100%] min-w-[100%] overflow-hidden whitespace-nowrap text-ellipsis h-6">
                                                                    {t('common:archived')}
                                                                </p>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <p
                                                                    key={idx}
                                                                    className="py-1 pl-1 pr-2 max-w-[100%] min-w-[100%] overflow-hidden whitespace-nowrap text-ellipsis h-6">
                                                                    {header.name.split('_').join(' ')}
                                                                </p>
                                                            )
                                                        }
                                                    }
                                                }
                                            })}
                                        </div>
                                        <div key={idx} className="text-xs relative grow pl-1 cursor-pointer" onClick={() => handleSelectedLog(logData)}>
                                            {logData.log_data.map((item, idx) => {
                                                if (isActiveFilter) {
                                                    if (item.type === 'text' && selectedTableHeaders.some((e) => e.name === item.name && e.active)) {
                                                        return (
                                                            <p key={idx} className="first-letter:capitalize h-6 py-1 pl-0.5 max-w-[100%] min-w-[100%] overflow-hidden whitespace-nowrap text-ellipsis">{item.data ? item.data : '-'}</p>
                                                        )
                                                    }
                                                    else if (item.type === 'checkbox' && selectedTableHeaders.some((e) => e.name === item.name && e.active)) {
                                                        return (
                                                            <p
                                                                key={idx}
                                                                className={`${item.data ? 'text-black' : 'text-black'} 
                                                            material-symbols-outlined capitalize pb-1 pt-0.5 h-6 !block`}
                                                                style={{ fontSize: '1.2rem' }}>
                                                                {item.data === true ? 'check_circle' : 'cancel'}
                                                            </p>
                                                        )
                                                    }
                                                    else if (item.type === 'select' && selectedTableHeaders.some((e) => e.name === item.name && e.active)) {
                                                        return (
                                                            <p key={idx} className="capitalize h-6 py-1 pl-0.5 max-w-[100%] min-w-[100%] overflow-hidden whitespace-nowrap text-ellipsis">{item.data ? item.data : '-'}</p>
                                                        )
                                                    }
                                                    else if (item.type === 'file' && selectedTableHeaders.some((e) => e.name === item.name && e.active)) {
                                                        return (
                                                            <p key={idx} className="capitalize h-6 py-1 pl-0.5 max-w-[100%] min-w-[100%] overflow-hidden whitespace-nowrap text-ellipsis">{item.data ? item.data.length : '-'}</p>
                                                        )
                                                    }
                                                    else if (item.type === 'date' && selectedTableHeaders.some((e) => e.name === item.name && e.active)) {
                                                        return (
                                                            <p key={idx} className="capitalize h-6 py-1 pl-0.5 max-w-[100%] min-w-[100%] overflow-hidden whitespace-nowrap text-ellipsis">{item.data ? dateToLocaleDate(item.data) : '-'}</p>
                                                        )
                                                    }
                                                }
                                                else {
                                                    if (item.type === 'text' && selectedTableHeaders.some((e) => e.name === item.name)) {
                                                        return (
                                                            <p
                                                                key={idx}
                                                                className="first-letter:capitalize h-6 py-1 pl-0.5 max-w-[100%] min-w-[100%] overflow-hidden whitespace-nowrap text-ellipsis">
                                                                {item.data ? item.data : '-'}
                                                            </p>
                                                        )
                                                    }
                                                    else if (item.type === 'checkbox' && selectedTableHeaders.some((e) => e.name === item.name)) {
                                                        return (
                                                            <p
                                                                key={idx}
                                                                className={`${item.data ? 'text-black' : 'text-black'} 
                                                                material-symbols-outlined capitalize pb-1 pt-0.5 h-6 !block`}
                                                                style={{ fontSize: '1.1rem' }}>
                                                                {item.data === true ? 'check_circle' : 'cancel'}
                                                            </p>
                                                        )
                                                    }
                                                    else if (item.type === 'select' && selectedTableHeaders.some((e) => e.name === item.name)) {
                                                        return (
                                                            <p key={idx} className="capitalize h-6 py-1 pl-0.5 max-w-[100%] min-w-[100%] overflow-hidden whitespace-nowrap text-ellipsis">{item.data ? item.data : '-'}</p>
                                                        )
                                                    }
                                                    else if (item.type === 'file' && selectedTableHeaders.some((e) => e.name === item.name)) {
                                                        return (
                                                            <p key={idx} className="capitalize h-6 py-1 pl-0.5 max-w-[100%] min-w-[100%] overflow-hidden whitespace-nowrap text-ellipsis">{item.data ? item.data.length : '-'}</p>
                                                        )
                                                    }
                                                    else if (item.type === 'date' && selectedTableHeaders.some((e) => e.name === item.name)) {
                                                        return (
                                                            <p key={idx} className="capitalize h-6 py-1 pl-0.5 max-w-[100%] min-w-[100%] overflow-hidden whitespace-nowrap text-ellipsis">{item.data ? dateToLocaleDate(item.data) : '-'}</p>
                                                        )
                                                    }
                                                }
                                            })}
                                        </div>
                                    </div>
                                    <div className="w-full mt-2 py-1 border-t border-primary-blue-10">
                                        <div className="flex items-end justify-end gap-x-5 text-[0.6rem] pr-3">
                                            <div className="flex flex-col items-center">
                                                <span className="font-semibold">{t('common:createdBy')}</span>
                                                <span className="">
                                                    {
                                                        logData.log_data.find(e => e.name === 'created_by')?.data
                                                    }
                                                </span>
                                            </div>
                                            <div className="flex flex-col">
                                                <span className="font-semibold">{t('common:createdAt')}</span>
                                                <span className="">{dateToLocaleDate(logData.log_data.find(e => e.name === 'created_at')?.data)}</span>
                                            </div>
                                            {logData.deleted_at && (
                                                <div className="flex flex-col">
                                                    <span className="font-semibold">{t('common:archived')}</span>
                                                    <span className="">{dateToLocaleDate(logData.deleted_at)}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}

                {searchOperation && logs && logs.data.length < 1 && (
                    <p className="text-sm mt-2 mb-2 md:mt-4 ml-1 md:ml-5 text-black font-semibold">{t('page:properties.noLogsFound')}...</p>
                )}

                {(logs && logs.data.length < 1 && !searchOperation) && (
                    <div className="border-t mt-2">
                        <p className="text-sm mt-5 mb-2 md:mt-4 ml-2 md:ml-5 text-center text-black font-semibold">{t('page:properties.noLogsFound')}...</p>
                    </div>
                )}

                <div className="flex justify-center py-2">
                    <Pagination
                        count={pageCount}
                        page={page}
                        defaultPage={1}
                        showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                        showLastButton={pageCount ? pageCount > 5 : false}
                        hidePrevButton={!hasPrevPage}
                        hideNextButton={!hasNextPage}
                        setPage={setPage}
                    />
                </div>
            </div>

            <Modal isOpen={showModal} close={() => setShowModal(false)} title={displayName.logName + ' - ' + displayName.pboName} size='md' titleAlign='center' titleSize="3xl">
                <div className="mx-auto">
                    {selectedLog && (
                        <ExpandLog
                            log={selectedLog}
                            setShowExpandLog={setShowModal}
                            logTemplate={selectedLogTemplate}
                            entityLog={selectedEntityLog}
                        />
                    )}
                </div>
            </Modal>
        </div>
    )
}

export default LogsTable;