import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications';
import { Link, useNavigate, useParams } from 'react-router-dom';

type Props = {}

const ErrorPage = (props: Props) => {

    const { parameter } = useParams();
    let errorMsg = '';

    switch (parameter) {
        case 'tokenExpired':
            errorMsg = 'The token has expired, sign in and resend the verification link!'
            break;

        default:
            errorMsg = 'Try again or contact us if the error persists.'
            break;
    }



    useEffect(() => {

    }, [])

    return (
        <div className="w-3/4 mx-auto flex flex-col bg-zinc-200 rounded p-4 text-center mt-10">
            <span className="material-symbols-outlined" style={{ fontSize: '3rem'}}>warning</span>
            <h1 className="text-3xl my-5 font-bold">Something went wrong...</h1>
            <p className="text-xl font-bold my-5">{errorMsg}</p>
            <Link to='/home' className="mt-5 underline text-blue-500">Click here to go back</Link>

        </div>
    )
}

export default ErrorPage;