export enum LogCategories {
    None = 'None',
    Fire = 'Fire',
    Camera = 'Camera',
    Security = 'Security',
    Access = 'Access',
    Cleaning = 'Cleaning',
    Maintenance = 'Maintenance',
    Rounding = 'Rounding',
    Ventilation = 'Ventilation',
    Elevator = 'Elevator',
    Other = 'Other',
}