
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Fragment, useEffect, useRef, useState } from "react";
import { dateToLocaleDate, dateToLocaleDateAndHour } from "../../../../utils/date-time";
import { NotificationManager } from 'react-notifications';

import Modal from "../../../molecules/modal";
import ConfirmModal from "../../../molecules/confirm-modal";
import { SubsidiaryDto } from "../../../../services/tenant/subsidiary/dto/subsidiary.dto";
import { useDeleteSubsidiaryMutation, useGetSubsidiariesQuery } from "../../../../services/tenant/subsidiary/subsidiary.service";
import { Grow, Input, debounce } from "@mui/material";
import UpdateSubsidiaryForm from "../../forms/subsidiary/update-subsidiary-form";
import SubsidiaryUserAccess from "../subsidiary-user-access";
import SubsidiaryPropertyAccess from "../subsidiary-property-access";
import { useTranslation } from "react-i18next";

import DefaultPersonImg from '../../../../assets/default/person.png';
import { Order } from "../../../../services/enum/order";
import Loader from "../../../atoms/loader/loader";
import FilterTableColumns from "../../../molecules/filter-table-columns";
import { Menu, Transition } from "@headlessui/react";
import CreateSubsidiaryForm from "../../forms/subsidiary/create-subsidiary-form";
import Pagination from "../../../molecules/pagination";
import Table from "../../../molecules/table";

type Props = {
}

const SubsidiaryTable = ({ }: Props) => {
    const { t } = useTranslation();

    // Delete subsidiary
    const [deleteSubsidiary, response] = useDeleteSubsidiaryMutation();

    const [showCopyModal, setShowCopyModal] = useState<boolean>(false); // Toggle copy modal
    const [showModal, setShowModal] = useState<boolean>(false); // Toggle modal
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false); // Toggle confirm modal
    const [selectedSubsidiary, setSelectedSubsidiary] = useState<SubsidiaryDto>(); // State for selected subsidiary
    const [tableHeaders, setTableHeaders] = useState<string[]>();

    // Pagination
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>();
    const [hasNextPage, setHasNextPage] = useState<boolean>();
    const [hasPrevPage, setHasPrevPage] = useState<boolean>();

    // Table sort and search
    const [search, setSearch] = useState<string | undefined>('');
    const [searchOperation, setSearchOperation] = useState<string | undefined>();
    const [sortBy, setSortBy] = useState<string | undefined>('company_name'); // Column to sort by
    const [sortOrder, setSortOrder] = useState<Order>(Order.ASC); // Sort order
    const [showSortMenu, setShowSortMenu] = useState<boolean>(false); // // Sort menu on small screens 

    const debouncedSearch = useRef(debounce((search: string) => setSearchOperation(search), 500)).current;

    const { data: subsidiaries, isFetching } = useGetSubsidiariesQuery({
        page: page,
        take: 25,
        search: searchOperation,
        sortBy: sortBy,
        order: sortOrder
    });

    useEffect(() => {
        if (subsidiaries) {
            setPage(subsidiaries.meta.page);
            setPageCount(subsidiaries.meta.pageCount);
            setHasNextPage(subsidiaries.meta.hasNextPage);
            setHasPrevPage(subsidiaries.meta.hasPreviousPage);
        }
    }, [subsidiaries])

    const handlePagination = (page: number) => {
        setPage(page);
    }

    const handleSort = (column: string, order: Order) => {
        setSortBy(column);
        setSortOrder(order === Order.ASC ? Order.DESC : Order.ASC);
    }

    // If selectedSubsidiary changes, set updated subsidiary
    useEffect(() => {
        if (selectedSubsidiary) {
            const match = subsidiaries?.data.find((e) => e.id === selectedSubsidiary.id);
            if (match) {
                setSelectedSubsidiary(match);
            }
        }
    }, [subsidiaries]);

    // Edit subsidiary
    const handleEditSubsidiary = (subsidiary: SubsidiaryDto) => {
        setShowModal(true); // Show modal
        setSelectedSubsidiary(subsidiary);
    }

    // Copy subsidiary
    const handleCopySubsidiary = (subsidiary: SubsidiaryDto) => {
        setShowCopyModal(true);
        setSelectedSubsidiary(subsidiary);
    }

    // Toggle modal and setSelected subsidiary
    const handleDeleteSubsidiary = (subsidiary: SubsidiaryDto) => {
        setSelectedSubsidiary(subsidiary); // Set selected subsidiary to be deleted if confirmed in modal
        setShowConfirmModal(true); // Show confirm modal
    }

    // Delete subsidiary after confirmation
    const deleteSubsidiaryConfirmed = async () => {

        // Get id from selected subsidiary
        if (selectedSubsidiary?.id) {

            const payload = {
                subsidiary_id: selectedSubsidiary.id,
            }

            try {
                const response = await deleteSubsidiary(payload).unwrap();

                if (response.success) {
                    NotificationManager.success(t('page:company.form.subsidiaryDeleted'));
                    setShowConfirmModal(false);
                }
                else {
                    NotificationManager.error(t('page:company.form.subsidiaryNotDeleted'));
                }
            }
            catch (error) {
                NotificationManager.error(t('page:company.form.subsidiaryNotDeleted'));
            }
        }
    }

    const handleSubsidiary = () => {

    }

    // Define available column headers to be sent to FilterTableColumns
    const columnHeaders = ['company_name', 'org_number', 'address', 'created_at'];

    // Gets triggered by FilterTableColumns when columns change
    const handleColumnsToDisplay = (columns: string[]) => {
        setTableHeaders(columns);
    }

    return (
        <>
            {(subsidiaries && subsidiaries?.data.length < 1 && !searchOperation) ? (
                <>
                    <div className="w-10/12 xl:w-8/12 max-w-2xl mx-auto bg-primary-blue-5 text-black rounded-md px-2 py-4 mt-10 text-center">
                        <p className="text-lg font-semibold">{t('page:company.noSubsidiariesFound')}...</p>
                        <p className="mt-2">{t('page:company.noSubsidiariesFoundInfo')}</p>
                    </div>
                </>
            ) : (
                <div className="w-full mx-auto md:mt-1">
                    <div className="hidden md:block">
                        <div className="sm:w-6/12 max-w-2xl mt-2 text-center sm:text-left">
                            <Input
                                type="search"
                                name="search_name"
                                placeholder={t('common:search')}
                                disableUnderline={true}
                                value={search}
                                className="w-full border rounded-md text-base md:text-sm px-2 pt-2.5 pb-1 mt-3 bg-white"
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                    debouncedSearch(e.target.value)
                                }}
                                endAdornment={
                                    <span
                                        className="material-symbols-outlined pb-1 text-gray-500 cursor-pointer"
                                        style={{ fontSize: '1.3rem' }}
                                        onClick={() => {
                                            if (search) {
                                                setSearch('')
                                                debouncedSearch('')
                                            }
                                        }}
                                    >
                                        {search ? 'close' : 'search'}
                                    </span>
                                }
                                size="small"
                            />
                        </div>
                        <div className="w-full mt-1 mb-2">
                            <FilterTableColumns
                                tableColumns={columnHeaders}
                                tableType='st_filter'
                                handleColumnsToDisplay={handleColumnsToDisplay}
                            />
                        </div>
                        <div className="overflow-x-scroll">
                            <Table>
                                <Table.Thead>
                                    <tr>
                                        <th scope="col" className="w-20 hidden sm:table-cell">
                                            <span className="sr-only">Logo</span>
                                        </th>
                                        {tableHeaders?.includes('company_name') && (
                                            <th scope="col" className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]" onClick={() => handleSort('company_name', sortOrder)} >
                                                {t('common:name')}
                                                {(sortBy === 'company_name') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </th>
                                        )}
                                        {tableHeaders?.includes('org_number') && (
                                            <th scope="col" className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[11rem]" onClick={() => handleSort('org_number', sortOrder)}>
                                                {t('common:orgNumber')}
                                                {(sortBy === 'org_number') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </th>
                                        )}
                                        {tableHeaders?.includes('address') && (
                                            <th scope="col" className="py-1 md:py-2 cursor-pointer font-bold min-w-[6rem]">
                                                {t('common:address')}
                                            </th>
                                        )}
                                        {tableHeaders?.includes('created_at') && (
                                            <th scope="col" className="py-1 md:py-2 cursor-pointer font-bold min-w-[6rem]" onClick={() => handleSort('created_at', sortOrder)}>
                                                {t('common:createdAt')}
                                                {(sortBy === 'created_at') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </th>
                                        )}
                                        <th scope="col" className="">
                                            <span className="sr-only">Copy</span>
                                        </th>
                                        <th scope="col" className="">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                        <th scope="col" className="">
                                            <span className="sr-only">Delete</span>
                                        </th>
                                    </tr>
                                </Table.Thead>
                                {subsidiaries && subsidiaries.data.length > 0 && !isFetching && (
                                    <Table.Tbody>
                                        {subsidiaries?.data.map((subsidiary, idx) => {
                                            return (
                                                <tr key={idx} className={`odd:hover:bg-primary-blue-10 even:hover:bg-gray-50 cursor-pointer text-xs text-black even:bg-white odd:bg-primary-blue-5`}>
                                                    <td scope="row" className="pl-1 capitalize hidden sm:table-cell py-2 lg:py-1">
                                                        {subsidiary.logo ? (
                                                            <div className="flex items-center w-9 lg:w-12 h-9 lg:h-12 rounded-full bg-white overflow-hidden">
                                                                <img
                                                                    src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${subsidiary.tenant_id}/${subsidiary.logo}`}
                                                                    // onError={e => e.currentTarget.src = DefaultPersonImg}
                                                                    alt="Profile picture"
                                                                    className="w-9 lg:w-12 h-9 lg:h-12 object-contain rounded-full lg:p-0.5" />
                                                            </div>
                                                        ) : (
                                                            <div className="flex items-center justify-center w-9 lg:w-12 h-9 lg:h-12 rounded-full border border-gray-100">
                                                                <span
                                                                    className="material-symbols-outlined"
                                                                    style={{ fontSize: '1.3rem' }}>
                                                                    cases
                                                                </span>
                                                            </div>
                                                        )}
                                                    </td>
                                                    {tableHeaders?.includes('company_name') && (
                                                        <td
                                                            scope="row"
                                                            className="py-2 pl-1 sm:pl-2 text-xs capitalize font-semibold sm:font-normal max-w-[8rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            title={subsidiary?.company_name}
                                                            onClick={handleSubsidiary}>
                                                            {subsidiary.company_name ? subsidiary.company_name : '-'}
                                                        </td>
                                                    )}
                                                    {tableHeaders?.includes('org_number') && (
                                                        <td
                                                            className="py-2 pl-1 sm:pl-2 text-xs capitalize font-semibold sm:font-normal max-w-[11rem] min-w-[11rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            title={subsidiary?.org_number}
                                                            onClick={handleSubsidiary}>
                                                            {subsidiary.org_number ? subsidiary.org_number : '-'}
                                                        </td>
                                                    )}

                                                    {tableHeaders?.includes('address') && (
                                                        <td
                                                            className="py-2 pr-2 max-w-[7rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            title={subsidiary?.address}
                                                            onClick={handleSubsidiary}>
                                                            {subsidiary.address ? subsidiary.address : '-'}
                                                        </td>
                                                    )}
                                                    {tableHeaders?.includes('created_at') && (
                                                        <td
                                                            className="py-2 max-w-[7rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            title={subsidiary.created_at && dateToLocaleDate(subsidiary.created_at)}
                                                            onClick={handleSubsidiary}>
                                                            {dateToLocaleDate(subsidiary.created_at) ? dateToLocaleDate(subsidiary.created_at) : '-'}
                                                        </td>
                                                    )}
                                                    <td className="px-2 lg:px-1">
                                                        <button
                                                            onClick={() => handleCopySubsidiary(subsidiary)}
                                                            className="text-black material-symbols-outlined"
                                                            style={{ fontSize: '1.2rem' }}
                                                            title={t('common:duplicate')}
                                                        >content_copy</button>
                                                    </td>
                                                    <td className="pb-1 px-2 lg:px-1">
                                                        <button
                                                            onClick={() => handleEditSubsidiary(subsidiary)}
                                                            className="text-black material-symbols-outlined"
                                                            style={{ fontSize: '1.2rem' }}
                                                            title={t('common:edit')}
                                                        >edit_square</button>
                                                    </td>
                                                    <td className="px-2 lg:px-1">
                                                        <button
                                                            onClick={() => handleDeleteSubsidiary(subsidiary)}
                                                            className="material-symbols-outlined text-status-critical"
                                                            style={{ fontSize: '1.3rem' }}
                                                            title={t('common:delete')}
                                                        >delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </Table.Tbody>
                                )}
                            </Table>
                            {isFetching && (
                                <div className="mx-auto mb-5 mt-1">
                                    <Loader show={isFetching} size='small' />
                                </div>
                            )}

                            {!subsidiaries || subsidiaries.data.length < 1 && (
                                <div className="my-5">
                                    <p className="text-sm ml-7 md:ml-5 font-semibold text-black">{t('page:company.noSubsidiariesFound')}...</p>
                                </div>
                            )}
                        </div>
                        {subsidiaries && subsidiaries.meta && (
                            <div className="flex justify-center py-2">
                                <Pagination
                                    count={pageCount}
                                    page={page}
                                    defaultPage={1}
                                    showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                                    showLastButton={pageCount ? pageCount > 5 : false}
                                    hidePrevButton={!hasPrevPage}
                                    hideNextButton={!hasNextPage}
                                    onChange={handlePagination}
                                />
                            </div>
                        )}
                    </div>

                    {/* Mobile screen  */}
                    <div className="block md:hidden w-full mx-auto mt-1">
                        <div className="pt-1">
                            <Input
                                type="search"
                                name="search_name"
                                placeholder={t('common:search')}
                                disableUnderline={true}
                                className="border rounded-md px-2 pt-2.5 pb-1 mt-3 w-10/12 bg-white"
                                onChange={(e) => debouncedSearch(e.target.value)}
                                endAdornment={<span className="material-symbols-outlined text-gray-500 pb-1" style={{ fontSize: '1.3rem' }}>search</span>}
                                size="small"
                            />
                            <Menu as="div" className="relative inline-block text-left cursor-pointer">
                                <div>
                                    <Menu.Button
                                        className="material-symbols-outlined text-black align-sub ml-3 rotate-90 border rounded-md px-1 py-1"
                                        style={{ fontSize: '1.4rem' }}
                                        onClick={() => setShowSortMenu(!showSortMenu)}>
                                        sync_alt
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className="absolute right-0 z-10 mt-2 w-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="">
                                            <Menu.Item>
                                                <div className="flex hover:bg-primary-blue-5"
                                                    onClick={(e) => handleSort('company_name', sortOrder)}>
                                                    <span className="w-1/2 block px-2 py-2 text-sm">{t('common:name')}</span>
                                                    <div className="w-1/2 py-2 pr-1">
                                                        {(sortBy === 'company_name') ? (
                                                            <>
                                                                {sortOrder === Order.ASC ? (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                                ) : (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <div className="flex hover:bg-primary-blue-5" onClick={() => handleSort('org_number', sortOrder)}>
                                                    <span className="w-1/2 block px-2 py-2 text-sm">{t('common:orgNumber')}</span>
                                                    <div className="w-1/2 py-2 pr-1">
                                                        {(sortBy === 'org_number') ? (
                                                            <>
                                                                {sortOrder === Order.ASC ? (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                                ) : (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <div className="flex hover:bg-primary-blue-5" onClick={() => handleSort('created_at', sortOrder)}>
                                                    <span className="w-1/2 block px-2 py-2 text-sm">{t('common:createdAt')}</span>
                                                    <div className="w-1/2 py-2 pr-1">
                                                        {(sortBy === 'created_at') ? (
                                                            <>
                                                                {sortOrder === Order.ASC ? (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                                ) : (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                        {subsidiaries?.data && subsidiaries.data.length > 0 && !isFetching && (
                            <div>
                                {subsidiaries?.data?.map((subsidiary, idx) => (
                                    <div key={idx} className="my-2 p-2 text-xs relative odd:bg-primary-blue-5 odd:hover:bg-primary-blue-10 even:border border-primary-blue-5 hover:even:bg-gray-50 rounded-md">
                                        <div className="flex flex-col flex-wrap gap-x-5 my-1">
                                            <div>
                                                <button
                                                    onClick={() => handleEditSubsidiary(subsidiary)}
                                                    className="text-black material-symbols-outlined absolute right-[4.5rem] top-2"
                                                    style={{ fontSize: '1.2rem' }}
                                                    title={t('common:duplicate')}
                                                >content_copy</button>
                                                <button
                                                    onClick={() => handleEditSubsidiary(subsidiary)}
                                                    className="text-black material-symbols-outlined absolute right-10 top-1.5"
                                                    style={{ fontSize: '1.2rem' }}
                                                    title={t('common:edit')}
                                                >edit_square</button>
                                                <button
                                                    onClick={() => handleDeleteSubsidiary(subsidiary)}
                                                    className="material-symbols-outlined text-status-critical disabled:text-gray-300 absolute right-2 top-2"
                                                    style={{ fontSize: '1.3rem' }}
                                                    title={t('common:delete')}
                                                >delete</button>
                                            </div>

                                            <div className="flex items-center">
                                                <div className="sm:ml-5">
                                                    {subsidiary.logo ? (
                                                        <div className="flex items-center rounded-full bg-white overflow-hidden">
                                                            <img
                                                                src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${subsidiary.tenant_id}/${subsidiary.logo}`}
                                                                // onError={e => e.currentTarget.src = DefaultPersonImg}
                                                                alt="Profile picture"
                                                                className="rounded-full w-12 sm:w-14 h-12 sm:h-14 object-contain" />
                                                        </div>
                                                    ) : (
                                                        <div className="flex items-center w-12 sm:w-14 h-12 sm:h-14 justify-center rounded-full border border-gray-100">
                                                            <span
                                                                className="material-symbols-outlined"
                                                                style={{ fontSize: '1.4rem' }}
                                                            >
                                                                cases
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="ml-3 sm:ml-10">
                                                    <p className="font-semibold text-sm capitalize">{subsidiary.company_name}</p>
                                                    <p>{subsidiary.org_number ? subsidiary.org_number : '-'}</p>
                                                    <p>{subsidiary.address ? subsidiary.address : '-'}</p>
                                                </div>

                                                <p className="absolute right-2 bottom-2">{dateToLocaleDate(subsidiary.created_at)}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        )}
                        {isFetching && (
                            <div className="mx-auto mb-5 mt-1">
                                <Loader show={isFetching} size='small' />
                            </div>
                        )}
                        {!subsidiaries || subsidiaries.data.length < 1 && (
                            <div className="my-2">
                                <p className="text-sm ml-4 md:ml-5 font-semibold text-black">{t('page:company.noSubsidiariesFound')}...</p>
                            </div>
                        )}
                        {subsidiaries && subsidiaries.meta && (
                            <div className="flex justify-center py-2">
                                <Pagination
                                    count={pageCount}
                                    page={page}
                                    defaultPage={1}
                                    showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                                    showLastButton={pageCount ? pageCount > 5 : false}
                                    hidePrevButton={!hasPrevPage}
                                    hideNextButton={!hasNextPage}
                                    onChange={handlePagination}
                                />
                            </div>
                        )}
                    </div>

                    <Modal isOpen={showCopyModal} close={() => setShowCopyModal(false)} title={`${t('page:company.createSubsidiary')} (${t('common:duplicate')})`} size='sm' titleAlign='center'>
                        <CreateSubsidiaryForm subsidiary={selectedSubsidiary} />
                    </Modal>

                    <Modal isOpen={showModal} close={() => setShowModal(false)} title={`${selectedSubsidiary?.company_name}`} size='sm' titleAlign='center'>
                        <div>
                            <div className="flex flex-wrap justify-center mx-auto mb-5">
                                <div className="w-10/12 sm:w-11/12">
                                    <UpdateSubsidiaryForm subsidiary={selectedSubsidiary} />

                                    <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                                    <SubsidiaryUserAccess subsidiary={selectedSubsidiary} />

                                    <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                                    <SubsidiaryPropertyAccess subsidiary={selectedSubsidiary} />
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <ConfirmModal isOpen={showConfirmModal} close={() => setShowConfirmModal(false)} title={t('page:company.deleteSubsidiary')} titleAlign='center' type='warning'>
                        <p className="text-lg font-semibold">{t('common:confirmDelete')} {selectedSubsidiary?.company_name ? selectedSubsidiary.company_name : 'the selected subsidiary'}?</p>
                        <button
                            onClick={deleteSubsidiaryConfirmed}
                            className="bg-status-critical hover:bg-status-critical-h py-2 px-6 mt-3 rounded mx-auto text-white font-semibold">{t('common:delete')}</button>
                    </ConfirmModal>
                </div>
            )}
        </>
    )
}

export default SubsidiaryTable;