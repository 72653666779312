import React, { useState } from 'react'
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import { PropertyDto } from '../../../../services/tenant/property/dto/property.dto';
import { PropertyBuildingDto } from '../../../../services/tenant/property-building/dto/property-building.dto';
import Loader from '../../../atoms/loader/loader';
import { propertyBuildingAPI, useUpdatePropertyBuildingMutation } from '../../../../services/tenant/property-building/property-building.service';
import { t } from 'i18next';
import PropertyBuildingFileUpload from '../../property-building/property-building-file-upload';
import PropertySelect from '../../property/property-select';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PrimaryButton from '../../../atoms/primary-button';

export type UpdatePropertyBuildingFormType = {
    id: string;
    label: string;
    name?: string;
    object_number?: string;
    property_id: string;
    address?: string;
    image?: string;
}

type Props = {
    propertyBuilding?: PropertyBuildingDto;
    showPropertyBuildingModal: (x: boolean) => void;
}

const UpdatePropertyBuildingForm = ({ propertyBuilding, showPropertyBuildingModal }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [error, setGeneralError] = useState<string>();

    const [updatePropertyBuilding, response] = useUpdatePropertyBuildingMutation();

    const { control, handleSubmit, setValue, setError, clearErrors, formState: { errors } } = useForm<UpdatePropertyBuildingFormType>({
        defaultValues: {
            id: propertyBuilding?.id || '',
            label: propertyBuilding?.label || '',
            name: propertyBuilding?.name || '',
            object_number: propertyBuilding?.object_number || '',
            property_id: propertyBuilding?.property_id || '',
            address: propertyBuilding?.address || '',
            image: propertyBuilding?.image || ''
        }
    });

    const onSubmit = async (formData: UpdatePropertyBuildingFormType) => {

        try {
            const response = await updatePropertyBuilding(formData).unwrap();

            if (response.success) {
                setGeneralError('');
                NotificationManager.success(t('page:properties.form.buildingUpdated'));
                dispatch(propertyBuildingAPI.util.invalidateTags(['PropertyBuilding']));
            }
            else {
                setGeneralError(t(`common:server.${response.message}`));
                NotificationManager.error(t('page:properties.form.buildingNotUpdated'));
            }
        }
        catch (error) {
            setGeneralError('');
            NotificationManager.error(t('page:properties.form.buildingNotUpdated'));
        }

    }

    const handleSelectedProperty = (selectOption: { label: string, value: string }) => {
        if (selectOption.value && selectOption.value.length > 1) {
            setValue('property_id', selectOption.value);
            clearErrors('property_id');
        }
        else {
            setError('property_id', { type: 'required', message: t('page:properties.form.selectProperty') });
        }
    }

    return (
        <>
            <PropertyBuildingFileUpload building={propertyBuilding} />

            <div className="sm:mx-auto sm:w-full">
                <form className="space-y-2" onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                        <label htmlFor='label' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:label')}<span className="text-status-critical ">*</span>
                        </label>
                        <Controller
                            name="label"
                            control={control}
                            rules={{
                                required: t('page:properties.form.labelNotEmpty'),
                                minLength: {
                                    value: 3,
                                    message: t('page:properties.form.labelMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:label')}
                                        className={`${errors.label ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.label && <p className="text-status-critical text-xs my-1 ml-1">{errors.label.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='property_id' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('page:properties.buildingBelongsToProperty')}<span className="text-status-critical ">*</span>
                        </label>
                        <PropertySelect
                            handleSelectedProperty={handleSelectedProperty}
                            id={propertyBuilding?.property_id ?? undefined}
                        />
                        {errors.property_id && <p className="text-status-critical text-xs my-1 ml-1">{errors.property_id.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='name' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:name')}
                        </label >
                        <Controller
                            name="name"
                            control={control}
                            rules={{
                                required: false,
                                minLength: {
                                    value: 3,
                                    message: t('common:form.nameMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:name')}
                                        className={`${errors.name ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.name && <p className="text-status-critical text-xs my-1 ml-1">{errors.name.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='object_number' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:objectNumber')}
                        </label >
                        <Controller
                            name="object_number"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 3,
                                    message: t('page:properties.form.objectNumberMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:objectNumber')}
                                        className={`${errors.object_number ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.object_number && <p className="text-status-critical text-xs my-1 ml-1">{errors.object_number.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='address' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:address')}
                        </label >
                        <Controller
                            name="address"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 3,
                                    message: t('common:form.addressMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:address')}
                                        className={`${errors.address ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.address && <p className="text-status-critical text-xs my-1 ml-1">{errors.address.message}</p>}
                    </div>

                    {error && (<p className="font-bold text-sm mt-2 text-center text-status-critical">{error}</p>)}
                    <div>
                        <PrimaryButton
                        text={t('common:update')}
                        size={'medium'}
                        fullWidth={true}
                        />
                        {response.isLoading && (<Loader show={response.isLoading} size='small' />)}
                    </div>
                </form>
            </div >
        </>
    )
}

export default UpdatePropertyBuildingForm;