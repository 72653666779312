import { debounce } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Order } from '../../../../services/enum/order';
import { PageDto } from '../../../../services/dto/page.dto';
import Select, { MultiValue } from 'react-select';
import { SelectCustomStyles } from '../../../atoms/select-custom-styles/select-custom-styles';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetAllPropertiesQuery, useGetPropertyQuery } from '../../../../services/tenant/property/property.service';
import { PropertyDto } from '../../../../services/tenant/property/dto/property.dto';
import InputCheckbox from '../../../atoms/select-checkbox';

type SelectOption = {
    label: string;
    value: string;
    data: PropertyDto;
}

type Props = {
    handleSelectedProperties: (value: SelectOption[]) => void;
    noneSelected: boolean;
    id?: string; // Id passed to match on property;
    zIndex?: string;
    reset?: boolean;
}

const PropertySelectMulti = ({
    handleSelectedProperties,
    noneSelected,
    id,
    zIndex,
    reset
}: Props) => {
    const { t } = useTranslation();

    const [allOptions, setAllOptions] = useState<SelectOption[]>([]);
    const [search, setSearch] = useState<string>();
    const [page, setPage] = useState<number>(1);

    const debouncedSearch = useRef(debounce((search: string) => setSearch(search), 500)).current;

    // Get properties
    const { data: properties } = useGetAllPropertiesQuery({
        take: 15,
        page: page,
        order: Order.ASC,
        sortBy: 'label',
        search: search
    });

    const [selectKey, setSelectKey] = useState<number>(0);

    useEffect(() => {
        if (reset) {
            handleSelectedProperties([]);
            setSelectKey(prevKey => prevKey + 1); // Reset select component
        }

    }, [reset])

    useEffect(() => {
        if (properties) {
            // INIT
            if (allOptions.length === 0) {
                initOptions(properties);
            }
            // SEARCH
            else if (search) {
                handleSearchOptions(search, properties);
            }
            // PAGINATION
            else {
                handlePagination(properties);
            }
        }
    }, [properties])

    // Set initial options and selected options
    const initOptions = (properties: PageDto<PropertyDto>) => {
        const allOptions: SelectOption[] = [];
        if (properties && allOptions.length === 0) {
            properties.data.forEach(property => {
                let tempObj: SelectOption = {
                    label: property.label,
                    value: property.id,
                    data: property,
                };
                allOptions.push(tempObj);
            })
            setAllOptions(allOptions);
        }
    }

    // Handle options when search query is provided
    const handleSearchOptions = (search: string, properties: PageDto<PropertyDto>) => {
        if (properties && search) {
            setPage(1);
            const options: SelectOption[] = properties.data.map(property => ({
                label: property.label,
                value: property.id,
                data: property
            }));
            setAllOptions([...options]);
        }
    }

    // Handling options with pagination
    const handlePagination = (properties: PageDto<PropertyDto>) => {
        if (properties) {
            // Get existing options
            const existingOptions = [...allOptions];

            // Remove duplicates
            const uniqueArr = existingOptions.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.value === value.value
                ))
            )

            // Add options
            properties.data.forEach(property => {
                const obj = {
                    label: property.label,
                    value: property.id,
                    data: property,
                }
                // Make sure no duplicates are added
                if (!uniqueArr.some(e => e.value === obj.value)) {
                    uniqueArr.push(obj);
                }
            });

            setAllOptions(uniqueArr);
        }
    }

    // Handle scroll event to fetch more options
    const handleScroll = (event: any) => {
        if (properties && properties.meta.hasNextPage) {
            if (!search) {
                setPage(page ? page + 1 : 1);
            }
        }
    }

    const handleChange = (selectOptions: MultiValue<SelectOption>) => {
        // Make copy since passed array is readonly
        const selectOptionsArr: SelectOption[] = [...selectOptions];
        handleSelectedProperties(selectOptionsArr);
    }

    return (
        <Select
            key={selectKey}
            className={`${zIndex ? `z-${zIndex}` : ''} capitalize md:text-sm ${(reset === false || noneSelected) ? 'bg-white' : 'bg-primary-blue-5' }`}
            options={allOptions}
            hideSelectedOptions={false}
            onChange={(val) => handleChange(val)}
            maxMenuHeight={200}
            placeholder={t('common:select')}
            noOptionsMessage={() => t('page:properties.noPropertiesFound')}
            onInputChange={(value) => debouncedSearch(value)}
            captureMenuScroll={true}
            onMenuScrollToBottom={(val) => handleScroll(val)}
            filterOption={null}
            styles={SelectCustomStyles}
            isMulti={true}
            closeMenuOnSelect={false}
            components={{
                // @ts-ignore
                Option: InputCheckbox
            }}
        />
    )
}

export default PropertySelectMulti;