import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Grow } from '@mui/material';
import PropertyBuildingTableEdit from '../../../property-building/property-building-table-edit';
import PropertyObjectTableEdit from '../../../property-object/property-object-table-edit';
import UserTableEdit from '../../../user/user-table-edit';
import AllLogTemplatesTableEdit from '../../../log-components/log-structure/all-log-templates-table-edit';
import { PropertyDto } from '../../../../../services/tenant/property/dto/property.dto';
import Checkbox from '../../../../atoms/checkbox';

type Props = {
  selectedProperties: PropertyDto[];
};

const PropertyTables = ({
  selectedProperties
}: Props) => {

  const { t } = useTranslation();

  // Entities that can belong to a property
  const availableSelects = ['buildings', 'objects', 'users', 'logTemplates'];
  const [selectedEntities, setSelectedEntities] = useState<string[]>(availableSelects);

  const handleSelectedEntity = (entity: string) => {
    const allSelectedEntities = [...selectedEntities];

    const index = allSelectedEntities.findIndex(e => e === entity);
    if (index !== -1) {
      allSelectedEntities.splice(index, 1);
    }
    else {
      allSelectedEntities.push(entity);
    }
    setSelectedEntities(allSelectedEntities);
  }

  return (
    <div className="p-2 text-black rounded-lg">
      <div className="flex flex-wrap justify-center gap-x-5 mt-5">
        {availableSelects.map((entity, idx) => {
          return (
            <div key={idx} className="flex items-center">
              <Checkbox
                label={t(`common:${entity}`)}
                size={'md'}
                onChange={() => handleSelectedEntity(entity)}
                checked={selectedEntities?.find(e => e === entity) ? true : false}
              />
            </div>
          )
        })}
      </div>

      {selectedEntities.length > 0 ? (
        <>
          {selectedEntities.includes('buildings') && (
            <Grow in={selectedEntities.includes('buildings') ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
              <div>
                <hr className="w-6/12 mx-auto my-5" />
                <PropertyBuildingTableEdit properties={selectedProperties} />
              </div>
            </Grow>
          )}

          {selectedEntities.includes('objects') && (
            <Grow in={selectedEntities.includes('objects') ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
              <div className="mt-5">
                <PropertyObjectTableEdit properties={selectedProperties} />
              </div>
            </Grow>
          )}

          {selectedEntities.includes('users') && (
            <Grow in={selectedEntities.includes('users') ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
              <div className="mt-5">
                <UserTableEdit properties={selectedProperties} />
              </div>
            </Grow>
          )}


          {selectedEntities.includes('logTemplates') && (
            <Grow in={selectedEntities.includes('logTemplates') ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
              <div className="mt-5">
                <AllLogTemplatesTableEdit properties={selectedProperties} />
              </div>
            </Grow>
          )}
        </>
      ) : (
        <p className="font-semibold text-sm text-center my-3">{t('page:rights:noEntitiesSelected')}</p>
      )}
    </div>
  )
}

export default PropertyTables