import React, { BaseSyntheticEvent, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { NotificationManager } from 'react-notifications';
import { useUpdateTenantMutation } from '../../../../services/tenant/tenant/tenant.service';
import { useTranslation } from 'react-i18next';
import CompanyFileUpload from '../../company/company-file-upload';
import { useAppSelector } from '../../../../redux/hooks';
import { TenantStateType } from '../../../../services/dto/tenant-state-type';
import { Plans } from '../../../../services/enum/plans';
import { getCurrentUser } from '../../../../redux/slices/user.slice';
import { TenantTypes } from '../../../../services/enum/tenant-types';
import { Roles } from '../../../../services/enum/roles';
import { SelectCustomStyles } from '../../../atoms/select-custom-styles/select-custom-styles';
import PrimaryButton from '../../../atoms/primary-button';
import Checkbox from '../../../atoms/checkbox';

type Props = {
    tenant?: TenantStateType;
}

export type UpdateTenantFormType = {
    id: string;
    company_name?: string;
    org_number?: string;
    domain?: string;
    plan?: Plans;
    tenant_type?: TenantTypes;
    address?: string;
    city?: string;
    zip?: string;
    country?: string;
    logo?: string;
    visible?: boolean;
}

const UpdateTenantForm = ({ tenant }: Props) => {
    const { t } = useTranslation();
    const { currentUser } = useAppSelector(getCurrentUser);

    const [updateTenant, response] = useUpdateTenantMutation();

    const { control, handleSubmit, formState: { errors } } = useForm<UpdateTenantFormType>({
        defaultValues: {
            id: tenant?.id,
            company_name: tenant?.name,
            org_number: tenant?.org_number,
            domain: tenant?.domain,
            plan: tenant?.plan,
            tenant_type: tenant?.type,
            address: tenant?.address ? tenant.address : '',
            city: tenant?.city ? tenant.country : '',
            zip: tenant?.zip ? tenant.zip : '',
            country: tenant?.country ? tenant.country : '',
            logo: tenant?.logo ? tenant.logo : '',
            visible: tenant?.visible ?? tenant?.visible
        }
    });

    const onSubmit = async (formData: UpdateTenantFormType) => {
        try {
            const response = await updateTenant(formData).unwrap();

            if (response.success) {
                NotificationManager.success(t('page:company.form.companyUpdated'));
            }
            else {
                NotificationManager.error(t('page:company.form.companyNotUpdated'));
            }
        }
        catch (error) {
            NotificationManager.error(t('page:company.form.companyNotUpdated'));
        }
    }

    return (
        <>

            <CompanyFileUpload tenant={tenant} />

            <div className="w-full mx-auto">
                <form className="space-y-2" onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                        <label htmlFor='company_name' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:name')}<span className="text-status-critical ">*</span>
                        </label >
                        <Controller
                            name="company_name"
                            control={control}
                            rules={{
                                required: t('common:form.nameNotEmpty'),
                                minLength: {
                                    value: 3,
                                    message: t('common:form.nameMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        disabled={(currentUser.role === Roles.None || currentUser.role === Roles.User)}
                                        type='string'
                                        placeholder={t('common:name')}
                                        className={`${errors.company_name ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full text-base md:text-sm disabled:bg-zinc-100 disabled:text-zinc-500 disabled:ring-zinc-200 rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.company_name && <p className="text-status-critical text-xs my-1 ml-1">{errors.company_name.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='org_number' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('page:company.orgNumber')}<span className="text-status-critical ">*</span>
                        </label >
                        <Controller
                            name="org_number"
                            control={control}
                            rules={{
                                required: t('page:company.form.orgNumberNotEmpty'),
                                minLength: {
                                    value: 10,
                                    message: t('page:company.form.orgNumberMinLength10')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        disabled
                                        {...field}
                                        type='string'
                                        placeholder={t('page:company.orgNumber')}
                                        className={`${errors.org_number ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full text-base md:text-sm disabled:bg-gray-100 disabled:text-gray-400 disabled:ring-gray-200 rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.org_number && <p className="text-status-critical text-xs my-1 ml-1">{errors.org_number.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='domain' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:domain')}<span className="text-status-critical ">*</span>
                        </label >
                        <Controller
                            name="domain"
                            control={control}
                            rules={{
                                required: t('page:company.form.domainNotEmpty'),
                                minLength: {
                                    value: 3,
                                    message: t('page:company.form.domainMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        disabled
                                        {...field}
                                        type='string'
                                        placeholder={t('common:domain')}
                                        className={`${errors.domain ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full text-base md:text-sm disabled:bg-gray-100 disabled:text-gray-400 disabled:ring-gray-200 rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.domain && <p className="text-status-critical text-xs my-1 ml-1">{errors.domain.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='plan' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:plan')}<span className="text-status-critical ">*</span>
                        </label>
                        <Controller
                            name="plan"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <>
                                    <Select
                                        name="plan"
                                        className={`${errors.plan ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical rounded-md' : ''} text-base md:text-sm`}
                                        value={(Object.entries(Plans) as [string, string][])
                                            .filter(([key, value]) => typeof value === 'string')
                                            .map(([key, value]) => ({ label: t(`common:enums.plans.${key}`), value: value.toString() }))
                                            .find(option => option.value === field.value?.toString())}
                                        onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                                        styles={SelectCustomStyles}
                                        options={(Object.values(Plans) as Array<keyof typeof Plans>)
                                            .filter((val) => typeof val === 'string' && val !== 'None')
                                            .map((val) => ({
                                                label: t(`common:enums.plans.${Plans[val]}`),
                                                value: val,
                                            }))
                                        }
                                        isDisabled
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        controlShouldRenderValue={true}
                                        isClearable={false}
                                        backspaceRemovesValue={false}
                                        noOptionsMessage={() => t('common:noResultsFound')}
                                    />
                                </>
                            )}
                        />
                        {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (<span className="text-xs font-bold text-blue-700 underline cursor-pointer">{t('page:company.form.changePlan')}</span>)}
                        {errors.plan && <p className="text-status-critical text-xs my-1 ml-1">{errors.plan.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='tenant_type' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:companyType')}<span className="text-status-critical ">*</span>
                        </label>
                        <Controller
                            name="tenant_type"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <>
                                    <Select
                                        name="tenant_type"
                                        className={`${errors.tenant_type ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical rounded-md' : ''} text-base md:text-sm`}
                                        value={(Object.entries(TenantTypes) as [string, string][])
                                            .filter(([key, value]) => typeof value === 'string')
                                            .map(([key, value]) => ({ label: t(`common:enums.tenantTypes.${key}`), value: value.toString() }))
                                            .find(option => option.value === field.value?.toString())}
                                        onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                                        styles={SelectCustomStyles}
                                        options={(Object.values(TenantTypes) as Array<keyof typeof TenantTypes>)
                                            .filter((val) => typeof val === 'string' && val !== TenantTypes.None)
                                            .map((val) => ({
                                                label: t(`common:enums.tenantTypes.${val.split(' ').join('_')}`),
                                                value: val,
                                            }))
                                        }
                                        isDisabled={(currentUser.role === Roles.None || currentUser.role === Roles.User)}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        controlShouldRenderValue={true}
                                        isClearable={false}
                                        backspaceRemovesValue={false}
                                        noOptionsMessage={() => t('common:noResultsFound')}
                                    />
                                </>
                            )}
                        />
                        {errors.tenant_type && <p className="text-status-critical text-xs my-1 ml-1">{errors.tenant_type.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='address' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:address')}
                        </label>
                        <Controller
                            name="address"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 3,
                                    message: t('common:form.addressMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        disabled={(currentUser.role === Roles.None || currentUser.role === Roles.User)}
                                        type='string'
                                        placeholder={(currentUser.role === Roles.None || currentUser.role === Roles.User) ? '' : t('common:address')}
                                        className={`${errors.address ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full text-base md:text-sm disabled:bg-zinc-100 disabled:text-zinc-500 disabled:ring-zinc-200 rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.address && <p className="text-status-critical text-xs my-1 ml-1">{errors.address.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='city' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:city')}
                        </label>
                        <Controller
                            name="city"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 3,
                                    message: t('common:form.cityMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        disabled={(currentUser.role === Roles.None || currentUser.role === Roles.User)}
                                        type='string'
                                        placeholder={(currentUser.role === Roles.None || currentUser.role === Roles.User) ? '' : t('common:city')}
                                        className={`${errors.city ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full text-base md:text-sm disabled:bg-zinc-100 disabled:text-zinc-500 disabled:ring-zinc-200 rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.city && <p className="text-status-critical text-xs my-1 ml-1">{errors.city.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='zip' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:zip')}
                        </label>
                        <Controller
                            name="zip"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 5,
                                    message: t('common:form.zipMinLength5')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        disabled={(currentUser.role === Roles.None || currentUser.role === Roles.User)}
                                        type='string'
                                        placeholder={(currentUser.role === Roles.None || currentUser.role === Roles.User) ? '' : t('common:zip')}
                                        className={`${errors.zip ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full text-base md:text-sm disabled:bg-zinc-100 disabled:text-zinc-500 disabled:ring-zinc-200 rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.zip && <p className="text-status-critical text-xs my-1 ml-1">{errors.zip.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='country' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:country')}
                        </label>
                        <Controller
                            name="country"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 3,
                                    message: t('common:form.countryMinLength2')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        disabled={(currentUser.role === Roles.None || currentUser.role === Roles.User)}
                                        type='string'
                                        placeholder={(currentUser.role === Roles.None || currentUser.role === Roles.User) ? '' : t('common:country')}
                                        className={`${errors.country ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full text-base md:text-sm disabled:bg-zinc-100 disabled:text-zinc-500 disabled:ring-zinc-200 rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.country && <p className="text-status-critical text-xs my-1 ml-1">{errors.country.message}</p>}
                    </div>
                    <div className="flex items-center py-2">
                        <Controller
                            name="visible"
                            control={control}
                            // rules={}
                            render={({ field }) => (
                                <>
                                    <Checkbox
                                        size={'md'}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                        checked={field.value ? true : false}
                                        disabled={(currentUser.role === Roles.None || currentUser.role === Roles.User)}
                                    />
                                </>
                            )}
                        />
                        <label htmlFor='visible' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:visible')} ({t('common:visibleToExternals')})
                        </label>
                        {errors.visible && <p className="text-status-critical text-xs my-1 ml-1">{errors.visible.message}</p>}
                    </div>
                    {/* @ts-ignore */}
                    {(response.error && response.error.data.message) && (<p className="font-bold text-center text-status-critical">{t(`common:server.${response.error.data.message}`)}</p>)}
                    {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                        <div className="flex justify-center">
                            <PrimaryButton
                                text={t('common:update')}
                                size={'medium'}
                                fullWidth={true}
                            />
                        </div>
                    )}
                </form>
            </div>
        </>
    )
}

export default UpdateTenantForm;