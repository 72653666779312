import React from 'react'

type Props = {
    text: string;
    size: 'small' | 'medium' | 'large',
    onClick?: (val: any) => void;
    startAdornment?: string;
    endAdornment?: string;
    wide?: boolean;
    fullWidth?: boolean;
    align?: 'left' | 'center' | 'right';
    font?: 'normal' | 'medium' | 'semibold' | 'bold';
    color?: 'red' | 'blue' | 'green';
    disabled?: boolean;
}

const PrimaryButton = ({
    text,
    size = 'medium',
    onClick,
    startAdornment,
    endAdornment,
    wide,
    fullWidth,
    align,
    font,
    color = 'blue',
    disabled = false,
}: Props) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={`
            ${size === 'small' && 'text-xs px-2 py-1.5'}
            ${size === 'medium' && 'text-sm px-3 py-2.5'}
            ${size === 'large' && 'text-sm px-4 py-3'}
            ${wide && 'px-8'}
            ${fullWidth && 'w-full justify-center'}
            ${align === 'center' ? 'mx-auto' : align === 'right' ? 'justify-end' : 'justify-normal'}
            ${disabled && '!bg-primary-blue-10 !text-gray-400'}
            ${color === 'blue' ? 'bg-primary-blue hover:bg-primary-blue-h text-secondary-blue' : 
                color === 'red' ? 'bg-status-critical hover:bg-status-critical-h text-white' : 
                color === 'green' ? 'bg-status-ok hover:bg-status-ok text-black' : ''}
            flex items-center gap-x-1 rounded-md`
            }>
            {startAdornment && (
                <>
                    <span
                        className="material-symbols-outlined"
                        style={{ fontSize: `${size === 'small' ? '1.3rem' : size === 'medium' ? '1.4rem' : '1.4rem'}` }}
                    >{startAdornment}</span>
                    <span
                        className={`${font === 'medium' ? 'font-semibold' : font === 'semibold' ? 'font-bold' : font === 'bold' ? 'font-extrabold' : 'font-normal'}`}
                    >{text}
                    </span>
                </>
            )}
            {endAdornment && (
                <>
                    <span
                        className={`${font === 'medium' ? 'font-semibold' : font === 'semibold' ? 'font-bold' : font === 'bold' ? 'font-extrabold' : 'font-normal'}`}
                    >{text}
                    </span>
                    <span
                        className="material-symbols-outlined"
                        style={{ fontSize: `${size === 'small' ? '1.3rem' : size === 'medium' ? '1.4rem' : '1.4rem'}` }}
                    >{endAdornment}</span>
                </>
            )}
            {(!startAdornment && !endAdornment) && (
                <span
                    className={`${font === 'medium' ? 'font-semibold' : font === 'semibold' ? 'font-bold' : font === 'bold' ? 'font-extrabold' : 'font-normal'}`}
                >{text}
                </span>
            )}
        </button>
    )
}

export default PrimaryButton