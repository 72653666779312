import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Order } from "../../../services/enum/order";
import { useDeleteNotificationMutation, useGetReceivedNotificationsQuery, useUpdateNotificationMutation } from "../../../services/catalog/notification/notification.service";
import { useAppSelector } from "../../../redux/hooks";
import { getCurrentUser } from "../../../redux/slices/user.slice";
import { NotificationStatus } from "../../../services/enum/notification-status";
import { Roles } from "../../../services/enum/roles";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { NotificationType } from "../../../services/enum/notification-type";
import { dateToLocaleDate } from "../../../utils/date-time";
import { NotificationDto } from "../../../services/catalog/notification/dto/notification.dto";
import { NotificationManager } from 'react-notifications';
import { UpdateNotificationDto } from "../../../services/catalog/notification/dto/update-notification.dto";
import { NotificationMessage } from "../../../services/enum/notification-message";
import { useDispatch } from "react-redux";
import { getNotificationState, setNotificationState } from "../../../redux/slices/notification.slice";

type Props = {
    position?: string;
}
const Notifications = ({
    position = 'absolute'
}: Props) => {
    const { currentUser } = useAppSelector(getCurrentUser);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [updateNotification] = useUpdateNotificationMutation();
    const [deleteNotification] = useDeleteNotificationMutation();

    // Paginated request
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>();
    const [hasNextPage, setHasNextPage] = useState<boolean>();
    const [hasPrevPage, setHasPrevPage] = useState<boolean>();
    const [search, setSearch] = useState<string | undefined>('');
    const [searchOperation, setSearchOperation] = useState<string | undefined>();
    const [sortBy, setSortBy] = useState<string | undefined>('created_at');
    const [sortOrder, setSortOrder] = useState<Order>(Order.ASC);

    const [notificationStatusFilter, setNotificationStatusFilter] = useState<NotificationStatus>();
    const [newNotification, setNewNotification] = useState<boolean>(false);
    const [showNotifications, setShowNotifications] = useState<boolean>(false);

    const notifications = useAppSelector(getNotificationState);

    useEffect(() => {
        if (notifications) {
            if (notifications.newNotification) {
                setNewNotification(notifications.newNotification)
            }
        }
    }, [notifications])

    // Handle close when clicking outside of notification box
    const dropdownRef = useRef<HTMLDivElement>(null);
    const handleClickOutsideDropdown = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setShowNotifications(false);
        }
    }
    useEffect(() => {
        document.addEventListener('click', handleClickOutsideDropdown);

        return () => {
            document.removeEventListener('click', handleClickOutsideDropdown);
        }
    }, [])

    // Update notification status
    const handleUpdateStatus = async (notification: NotificationDto) => {
        const updateNotificationDto: UpdateNotificationDto = {
            id: notification.id,
            status: notification.status === NotificationStatus.Read ? NotificationStatus.Unread : NotificationStatus.Read,
            receiver_tenant_id: notification.receiver_tenant_id,
            sender_tenant_id: notification.sender_tenant_id!,
        }

        try {
            const response = await updateNotification(updateNotificationDto).unwrap();
            // Only show notification when error occurs
            if (!response.success) {
                NotificationManager.error(t('common:notification.notificationNotUpdated'));
            }
        }
        catch (error) {
            NotificationManager.error(t('common:notification.notificationNotUpdated'));
        }
    }

    // Delete notification
    const handleDeleteNotification = async (notification: NotificationDto) => {
        try {
            const response = await deleteNotification(notification.id).unwrap();
            // Only show notification when error occurs
            if (!response.success) {
                NotificationManager.error(t('common:notification.notificationNotDeleted'));
            }
        }
        catch (error) {
            NotificationManager.error(t('common:notification.notificationNotDeleted'));
        }
    }



    return (
        <>
            {/* Notification bell */}
            <div className={`${position === 'absolute' ? 'absolute right-0 top-0' : 'relative'} cursor-pointer`} onClick={() => setShowNotifications(!showNotifications)} ref={dropdownRef}>
                {newNotification && (
                    <span className="absolute w-2 mt-1 h-2 ml-4 flex">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-status-critical opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-2 w-2 bg-status-critical"></span>
                    </span>
                )}
                <span
                    className="material-symbols-outlined text-black"
                    title='Notifications'
                    style={{ fontSize: '1.7rem' }}
                >notifications</span>
            </div>

            {/* Notification box */}
            {showNotifications && (
                <div
                    className={`${position === 'absolute' ? 'absolute right-0 top-7' : 'absolute top-12 right-16'} w-10/12 sm:w-8/12 md:w-6/12 max-w-3xl bg-gray-50 shadow-md rounded-md p-1 max-h-[75vh] overflow-y-scroll z-[100]`}
                    onClick={(e) => e.stopPropagation()}>
                    <div className="flex justify-center my-1 mb-2">
                        <span className="text-sm font-semibold">{t('common:messages')}</span>
                        <button
                            className="absolute right-1 top-1 material-symbols-outlined"
                            onClick={() => setShowNotifications(false)}
                            style={{ fontSize: '1.3rem' }}
                        >close</button>
                    </div>

                    {notifications && notifications.data ? (
                        <>
                            {notifications.data.map((notification, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className="flex w-full text-xs border-b border-gray-100 last:border-none mt-4 mb-2 pb-2"
                                    >
                                        <div className="flex w-1/12 cursor-pointer" onClick={() => handleUpdateStatus(notification)}>
                                            {notification.status === NotificationStatus.Read ? (
                                                <div className="flex gap-y-2 flex-col p-3">
                                                    <span className="w-2 h-2 bg-gray-200 rounded-full"></span>
                                                </div>
                                            ) : (
                                                <div className="flex gap-y-2 flex-col p-3">
                                                    <span className="w-2 h-2 bg-blue-400 rounded-full"></span>
                                                </div>
                                            )}
                                        </div>

                                        <div className="py-1 w-full">
                                            <div className="flex mb-1">
                                                <div className="flex w-9/12 justify-start">
                                                    <p className="font-semibold">
                                                        {
                                                            notification.type === NotificationType.Friend_Request ?
                                                                t('common:notifications.friendRequest') :
                                                                notification.type === NotificationType.Friend_Request_Accepted ?
                                                                    t('common:notifications.friendRequestAccepted') :
                                                                    notification.type === NotificationType.New_User ?
                                                                        t('common:notifications.newUser') :
                                                                        t('common:notifications.systemMessage')
                                                        }
                                                    </p>
                                                </div>
                                                <div className="flex w-4/12 sm:w-3/12 justify-end">
                                                    <p className="font-semibold">{dateToLocaleDate(notification.created_at)}</p>
                                                </div>
                                            </div>

                                            <div className="w-full mt-0.5">
                                                {
                                                    notification.message === NotificationMessage.Request ?
                                                        notification.sender_company_name ?
                                                            `${notification.sender_company_name} ${t('common:notifications.sentFriendRequest')}` :
                                                            t('common:notifications.friendRequestReceived') :
                                                        notification.message === NotificationMessage.Request_Accepted ?
                                                            notification.sender_company_name ?
                                                                `${notification.sender_company_name} ${t('common:notifications.acceptedFriendRequest')}` :
                                                                t('common:notifications.friendRequestAccept') :
                                                            notification.message === NotificationMessage.New_User ?
                                                                t('common:notifications.newUserRegistered') :
                                                                t('common:notifications.newMessage')
                                                }
                                            </div>
                                        </div>
                                        <div className="flex justify-end items-center w-1/12 mt-2">
                                            <span
                                                className="material-symbols-outlined text-status-critical hover:text-status-critical-h cursor-pointer"
                                                style={{ fontSize: '1.3rem' }}
                                                onClick={() => handleDeleteNotification(notification)}
                                            >delete</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    ) : (
                        <p className="text-center text-sm pb-2">{t('common:notifications.noNotificationsFound')}...</p>
                    )}

                </div>
            )}
        </>
    )
}

export default Notifications;