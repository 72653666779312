import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { AuthState } from "../../services/dto/auth-state.dto";
import { Roles } from "../../services/enum/roles";
import { NotificationDto } from "../../services/catalog/notification/dto/notification.dto";

type NotificationState = {
    newNotification: boolean;
    data: NotificationDto[] | undefined;
}

const initialState: NotificationState = {
    newNotification: false,
    data: undefined
}

export const notificationSlice = createSlice({
    name: 'notificationSlice',
    initialState,
    reducers: {
        // Set notification state
        setNotificationState: (state: NotificationState, { type, payload }: PayloadAction<NotificationState>) => {
            state.newNotification = payload.newNotification;
            state.data = payload.data;
        },
    },
})

export const { setNotificationState } = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
export const getNotificationState = (state: RootState) => state.rootReducer.notification;