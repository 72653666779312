import React, { BaseSyntheticEvent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SubsidiaryDto } from '../../../../services/tenant/subsidiary/dto/subsidiary.dto';
import Organisationsnummer from 'organisationsnummer';
import { subsidiaryAPI, useUpdateSubsidiaryMutation } from '../../../../services/tenant/subsidiary/subsidiary.service';
import { NotificationManager } from 'react-notifications';
import { getCurrentUser } from '../../../../redux/slices/user.slice';
import { useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import SubsidiaryFileUpload from '../../subsidiary/subsidiary-file-upload';
import { Roles } from '../../../../services/enum/roles';
import SubsidiarySelect from '../../subsidiary/subsidiary-select';
import { useDispatch } from 'react-redux';
import PrimaryButton from '../../../atoms/primary-button';

type Props = {
    subsidiary?: SubsidiaryDto
}

export type UpdateSubsidiaryFormType = {
    id: string;
    company_name: string;
    org_number: string;
    active?: boolean;
    address?: string;
    city?: string;
    zip?: string;
    country?: string;
    contact_phone?: string;
    contact_email?: string;
    logo?: string;
    subsidiary_parent_id?: string | null;
}


const UpdateSubsidiaryForm = ({ subsidiary }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [logo, setLogo] = useState<string>('');

    const { currentUser } = useAppSelector(getCurrentUser);
    // Update subsidiary
    const [updateSubsidiary, response] = useUpdateSubsidiaryMutation();
    // Error state
    const [error, setError] = useState<string>('');
    // Org number error state
    const [orgNumberError, setOrgNumberError] = useState<string>('');

    const { control, handleSubmit, setValue, formState: { errors } } = useForm<UpdateSubsidiaryFormType>({
        defaultValues: {
            id: subsidiary?.id || '',
            company_name: subsidiary?.company_name || '',
            org_number: subsidiary?.org_number || '',
            active: subsidiary?.active || true,
            address: subsidiary?.address || '',
            city: subsidiary?.city || '',
            zip: subsidiary?.zip || '',
            country: subsidiary?.country || '',
            logo: subsidiary?.logo || '',
            subsidiary_parent_id: subsidiary?.subsidiary_parent_id || null,
        }
    });

    const validateOrgNumber = (value: string) => {
        const orgNumberValid = Organisationsnummer.valid(value);
        if (!orgNumberValid) {
            setOrgNumberError(t('page:company.invalidOrgNumber'))
            return false;
        }
        setOrgNumberError('');
        return true;
    }

    const handleSelectedSubsidiary = (selectOption: { label: string, value: string }) => {
        if (selectOption.value && selectOption.value.length > 1) {
            setValue('subsidiary_parent_id', selectOption.value);
        }
        else {
            setValue('subsidiary_parent_id', null);
        }
    }

    // Update subsidiary data
    const submitSubsidiaryForm = async (formData: UpdateSubsidiaryFormType) => {
        try {
            const response = await updateSubsidiary(formData).unwrap();

            if (response.success) {
                setError(''); // Reset error state
                NotificationManager.success(t('page:company.form.subsidiaryUpdated'));
                dispatch(subsidiaryAPI.util.invalidateTags(['Subsidiaries']));
            }
            else {
                setError(t(`common:server.${response.message}`));
                NotificationManager.error(t('page:company.form.subsidiaryNotUpdated'));
            }
        }
        catch (error) {
            NotificationManager.error(t('page:company.form.subsidiaryNotUpdated'));
        }
    }

    return (
        <>
            <SubsidiaryFileUpload subsidiary={subsidiary} />

            <div className="">
                <form className="space-y-2" onSubmit={handleSubmit(submitSubsidiaryForm)}>
                    <div>
                        <label htmlFor='company_name' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:companyName')}<span className="text-status-critical text-base">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="company_name"
                                control={control}
                                rules={{
                                    required: t('page:company.form.companyNameNotEmpty'),
                                    minLength: {
                                        value: 3,
                                        message: t('page:company.form.companyNameMinLength3')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={32}
                                            placeholder={t('common:companyName')}
                                            className={`${errors.company_name && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} disabled:bg-zinc-100 disabled:text-zinc-500 block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {errors.company_name && (<p className="text-status-critical mt-1 ml-1 text-xs">{errors.company_name.message}</p>)}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='org_number' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:orgNumber')}<span className="text-status-critical text-base">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="org_number"
                                control={control}
                                rules={{
                                    required: t('page:company.form.orgNumberNotEmpty'),
                                    validate: validateOrgNumber,
                                    minLength: {
                                        value: 10,
                                        message: t('page:company.form.orgNumberMinLength10')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={11}
                                            placeholder='123456-7890'
                                            className={`${errors.org_number && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} disabled:bg-zinc-100 disabled:text-zinc-500 block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {errors.org_number && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.org_number.message ? errors.org_number.message : orgNumberError}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='subsidiary_id' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('page:company.subsidiaryBelongsToSubsidiary')}
                            <span
                                className="material-symbols-outlined text-gray-600 ml-1 align-sub"
                                style={{ fontSize: '1.2rem' }}
                                title={t('page:company.subsidiaryInfoSubsidiary')}
                            >help</span>
                        </label>
                        <SubsidiarySelect
                            handleSelectedSubsidiary={handleSelectedSubsidiary}
                            id={subsidiary?.subsidiary_parent_id}
                            currentSubsidiaryId={subsidiary?.id}
                        />
                        {errors.subsidiary_parent_id && <p className="text-status-critical text-xs my-1 ml-1">{errors.subsidiary_parent_id.message}</p>}
                    </div>
                    <div>
                        <label htmlFor='address' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:address')}
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="address"
                                control={control}
                                rules={{
                                    minLength: {
                                        value: 3,
                                        message: t('common:form.addressMinLength3')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            disabled={(currentUser.role === Roles.None || currentUser.role === Roles.User) ? true : false}
                                            type='text'
                                            maxLength={32}
                                            placeholder={t('common:address')}
                                            className={`${errors.address && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} disabled:bg-zinc-100 disabled:text-zinc-500 block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {errors.address && (<p className="text-status-critical mt-1 ml-1 text-xs">{errors.address.message}</p>)}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='city' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:city')}
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="city"
                                control={control}
                                rules={{
                                    minLength: {
                                        value: 3,
                                        message: t('common:form.cityMinLength3')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            disabled={(currentUser.role === Roles.None || currentUser.role === Roles.User) ? true : false}
                                            type='text'
                                            maxLength={32}
                                            placeholder={t('common:city')}
                                            className={`${errors.city && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} disabled:bg-zinc-100 disabled:text-zinc-500 block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {errors.city && (<p className="text-status-critical mt-1 ml-1 text-xs">{errors.city.message}</p>)}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='zip' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:zip')}
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="zip"
                                control={control}
                                rules={{
                                    minLength: {
                                        value: 5,
                                        message: t('common:form.zipMinLength5')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            disabled={(currentUser.role === Roles.None || currentUser.role === Roles.User) ? true : false}
                                            type='text'
                                            maxLength={32}
                                            placeholder={t('common:zip')}
                                            className={`${errors.zip && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} disabled:bg-zinc-100 disabled:text-zinc-500 block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {errors.zip && (<p className="text-status-critical mt-1 ml-1 text-xs">{errors.zip.message}</p>)}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='country' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:country')}
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="country"
                                control={control}
                                rules={{
                                    minLength: {
                                        value: 2,
                                        message: t('common:form.countryMinLength2')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            disabled={(currentUser.role === Roles.None || currentUser.role === Roles.User) ? true : false}
                                            type='text'
                                            maxLength={32}
                                            placeholder={t('common:country')}
                                            className={`${errors.country && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} disabled:bg-zinc-100 disabled:text-zinc-500 block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {errors.country && (<p className="text-status-critical mt-1 ml-1 text-xs">{errors.country.message}</p>)}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    {error && (<p className="font-bold text-xs sm:text-sm pt-2 text-center text-status-critical">{error}</p>)}
                    {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                        <div>
                            <PrimaryButton
                                text={t('common:update')}
                                size={'medium'}
                                fullWidth={true}
                            />
                        </div>
                    )}
                </form>
            </div>
        </>
    )
}

export default UpdateSubsidiaryForm;