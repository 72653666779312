import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLogoutQuery } from "../../../../../services/management/management.service";
import { getAuthenticatedUser } from "../../../../../redux/slices/auth.slice";
import { getCurrentUser } from "../../../../../redux/slices/user.slice";
import { getTenant } from "../../../../../redux/slices/tenant.slice";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Roles } from "../../../../../services/enum/roles";
import { userAPI } from "../../../../../services/tenant/user/user.service";
import { Grow } from "@mui/material";
import Notifications from "../../../../atoms/notifications";
import LanguageSelector from "../../../../atoms/language-selector";
import { getNotificationState } from "../../../../../redux/slices/notification.slice";

type Props = {
    showNav: boolean;
    setShowNav: (flag: boolean) => void;
}

const MobileNav = ({
    showNav,
    setShowNav
}: Props) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const [logoutTrigger, setLogoutTrigger] = useState<any>(skipToken);
    const { data: logout } = useLogoutQuery(logoutTrigger);

    const { authUser } = useAppSelector(getAuthenticatedUser);
    const { currentUser } = useAppSelector(getCurrentUser);
    const { tenant } = useAppSelector(getTenant);

    const location = useLocation();

    // Reload page after logout result is returned
    useEffect(() => {
        if (logout) {
            if (logout.success) {
                window.location.reload();
            }
        }
    }, [logout])

    const handleLogout = () => {
        localStorage.setItem('user', ''); // Reset user in localstorage 
        localStorage.setItem('path', ''); // Reset path in localstorage
        setLogoutTrigger(true); // Call logout function
        dispatch({ type: 'RESET_APP' }); // Reset redux state
    }

    // NOTIFICATION STATE NEEDS TO BE FETCHED IN APP.TSX?
    const notifications = useAppSelector(getNotificationState);
    

    return (
        <>
            {/* EXPANDED MENU  */}
            {showNav ? (
                <Grow in={showNav} timeout={400} style={{ transformOrigin: 'bottom right' }}>
                    <div className="w-full h-[calc(98dvh)] pt-16 pb-5 bg-white text-black z-[99]">
                        <div className="w-full absolute right-3 top-2.5">
                            <LanguageSelector />
                        </div>
                        <div className="w-full absolute top-2 right-12 sm:right-14">
                            <Notifications />
                        </div>

                        <button
                            className="material-symbols-outlined absolute right-0 bg-primary-blue hover:bg-primary-blue-h text-white h-20 w-10 rounded-tl-md rounded-bl-md mt-10"
                            onClick={() => setShowNav(false)}>
                            close
                        </button>

                        <div className="w-8/12 h-full font-bold mx-auto text-sm">
                            <h3 className="text-3xl text-black font-extrabold">{t('common:menu')}</h3>
                            <div className="flex flex-col w-full h-full">
                                {/* ALL AUTHENTICATED */}
                                {(authUser && authUser.user_id) && (
                                    <>
                                        <div className="flex flex-col justify-center gap-y-1 mt-10">
                                            <NavLink to="/home" onClick={() => setShowNav(false)} className={`flex items-center gap-x-4 w-10/12 pl-5 pr-2 py-2 rounded-md ${location.pathname === '/home' && 'text-primary-blue bg-secondary-blue bg-secondary-blue'}`}>
                                                <button className="material-symbols-outlined">dashboard</button>
                                                <span>{t('common:homePage')}</span>
                                            </NavLink>

                                            {/* USER ROLE  */}
                                            {currentUser.role === Roles.User && (
                                                <>
                                                    <NavLink to="/logs" onClick={() => setShowNav(false)} className={`flex items-center gap-x-4 w-10/12 pl-5 pr-2 py-2 rounded-md ${location.pathname === '/logs' && 'text-primary-blue bg-secondary-blue'}`}>
                                                        <button className="material-symbols-outlined">note_stack</button>
                                                        <span>{t('common:homePage')}</span>
                                                    </NavLink>
                                                </>
                                            )}

                                            {/* ADMIN AND SUPERADMIN  */}
                                            {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                                                <>
                                                    <NavLink to="/connections" onClick={() => setShowNav(false)} className={`flex items-center gap-x-4 w-10/12 pl-5 pr-2 py-2 rounded-md ${location.pathname === '/connections' && 'text-primary-blue bg-secondary-blue'}`}>
                                                        <button className="material-symbols-outlined">person_search</button>
                                                        <span>{t('common:connections')}</span>
                                                    </NavLink>
                                                    <NavLink to="/rights" onClick={() => setShowNav(false)} className={`flex items-center gap-x-4 w-10/12 pl-5 pr-2 py-2 rounded-md ${location.pathname === '/rights' && 'text-primary-blue bg-secondary-blue'}`}>
                                                        <button className="material-symbols-outlined">account_tree</button>
                                                        <span>{t('common:rights')}</span>
                                                    </NavLink>
                                                    <NavLink to="/logs" onClick={() => setShowNav(false)} className={`flex items-center gap-x-4 w-10/12 pl-5 pr-2 py-2 rounded-md ${location.pathname === '/logs' && 'text-primary-blue bg-secondary-blue'}`}>
                                                        <button className="material-symbols-outlined">note_stack</button>
                                                        <span>{t('common:logs')}</span>
                                                    </NavLink>
                                                    <NavLink to="/properties" onClick={() => setShowNav(false)} className={`flex items-center gap-x-4 w-10/12 pl-5 pr-2 py-2 rounded-md ${location.pathname === '/properties' && 'text-primary-blue bg-secondary-blue'}`}>
                                                        <button className="material-symbols-outlined">home_work</button>
                                                        <span>{t('common:properties')}</span>
                                                    </NavLink>
                                                    <NavLink to="/users" onClick={() => setShowNav(false)} className={`flex items-center gap-x-4 w-10/12 pl-5 pr-2 py-2 rounded-md ${location.pathname === '/users' && 'text-primary-blue bg-secondary-blue'}`}>
                                                        <button className="material-symbols-outlined">group</button>
                                                        <span>{t('common:users')}</span>
                                                    </NavLink>
                                                    <NavLink to="/company" onClick={() => setShowNav(false)} className={`flex items-center gap-x-4 w-10/12 pl-5 pr-2 py-2 rounded-md ${location.pathname === '/company' && 'text-primary-blue bg-secondary-blue'}`}>
                                                        <button className="material-symbols-outlined">work</button>
                                                        <span>{t('common:company')}</span>
                                                    </NavLink>
                                                </>
                                            )}
                                        </div>

                                        <div className="mt-10">
                                            {/* ALL AUTHENTICATED */}
                                            <NavLink to="/profile" onClick={() => setShowNav(false)} className={`flex items-center gap-x-4 w-10/12 pl-5 pr-2 py-2 rounded-md ${location.pathname === '/profile' && 'text-primary-blue bg-secondary-blue'}`}>
                                                <button className="material-symbols-outlined">settings</button>
                                                <span>{t('common:settings')}</span>
                                            </NavLink>
                                        </div>

                                        <div className="flex h-full items-end pb-5">
                                            <div className="bg-primary-blue-5 rounded-md w-full p-4 flex flex-row box-border">
                                                <NavLink to="/profile" className="" onClick={() => setShowNav(false)}>
                                                    {currentUser && currentUser.profile_picture ? (
                                                        <img
                                                            src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${currentUser.tenant_id}/${currentUser.profile_picture}`}
                                                            alt="Profile picture"
                                                            className="w-10 h-10 object-cover rounded-md"
                                                        />
                                                    ) : (
                                                        <span
                                                            className="material-symbols-outlined"
                                                            style={{ fontSize: '2.5rem' }}>
                                                            person
                                                        </span>
                                                    )}
                                                </NavLink>
                                                <NavLink to="/profile" className="flex-grow ml-5" onClick={() => setShowNav(false)}>
                                                    {(currentUser && currentUser.name.split(' ').length > 0) ? (
                                                        <div className="flex flex-col">
                                                            <p className="font-normal">{currentUser.name.split(' ')[0]}</p>
                                                            <p className="font-normal">{currentUser.name.split(' ').slice(1)}</p>
                                                        </div>
                                                    ) : (
                                                        <span className="font-normal">{currentUser.name}</span>
                                                    )}
                                                </NavLink>

                                                <div className="flex flex-1 justify-end">
                                                    <button
                                                        className="material-symbols-outlined bg-primary-blue-10 hover:bg-primary-blue-15 p-2 rounded-md"
                                                        title={t('common:logout')}
                                                        onClick={() => handleLogout()}
                                                    >logout</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Grow>

            ) : (
                // SMALL MENU 
                <div className="w-full h-[calc(10dvh)] sm:h-[calc(6vh)] bg-white sticky bottom-0 border-t-4 border-primary-blue-10 z-[99]">
                    <div className="flex h-full items-center justify-around text-xs">
                        {/* AUTHENTICATED */}
                        {(authUser && authUser.user_id) ? (
                            <>
                                <NavLink to="/home" onClick={() => setShowNav(false)} className={`flex flex-col ${location.pathname === '/home' && 'text-primary-blue'}`}>
                                    <button className="material-symbols-outlined" style={{ fontSize: '1.4rem' }}>dashboard</button>
                                    <span>{t('common:homePage')}</span>
                                </NavLink>


                                {/* USER ROLE  */}
                                {currentUser.role === Roles.User && (
                                    <>
                                        <NavLink to="/logs" onClick={() => setShowNav(false)} className={`flex flex-col ${location.pathname === '/logs' && 'text-primary-blue'}`}>
                                            <button className="material-symbols-outlined" style={{ fontSize: '1.4rem' }}>note_stack</button>
                                            <span>{t('common:logs')}</span>
                                        </NavLink>
                                        <div className="flex flex-col" onClick={() => setShowNav(true)}>
                                            <button className="material-symbols-outlined" style={{ fontSize: '1.4rem' }}>menu</button>
                                            <span>{t('common:more')}</span>
                                        </div>
                                    </>
                                )}

                                {/* ADMIN AND SUPERADMIN  */}
                                {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                                    <>
                                        <NavLink to="/rights" onClick={() => setShowNav(false)} className={`flex flex-col ${location.pathname === '/rights' && 'text-primary-blue'}`}>
                                            <button className="material-symbols-outlined" style={{ fontSize: '1.4rem' }}>account_tree</button>
                                            <span>{t('common:rights')}</span>
                                        </NavLink>
                                        <NavLink to="/logs" onClick={() => setShowNav(false)} className={`flex flex-col ${location.pathname === '/logs' && 'text-primary-blue'}`}>
                                            <button className="material-symbols-outlined" style={{ fontSize: '1.4rem' }}>note_stack</button>
                                            <span>{t('common:logs')}</span>
                                        </NavLink>
                                        <NavLink to="/users" onClick={() => setShowNav(false)} className={`flex flex-col ${location.pathname === '/users' && 'text-primary-blue'}`}>
                                            <button className="material-symbols-outlined" style={{ fontSize: '1.4rem' }}>group</button>
                                            <span>{t('common:users')}</span>
                                        </NavLink>
                                        <div className="flex flex-col cursor-pointer" onClick={() => setShowNav(true)}>
                                            <button className="material-symbols-outlined" style={{ fontSize: '1.4rem' }}>menu</button>
                                            <span>{t('common:more')}</span>
                                            { (notifications && notifications.newNotification) && (
                                                <span className="absolute w-2 h-2 bg-status-critical rounded ml-[16px] mt-[2px]"></span>
                                            )}
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {/* NOT AUTHENTICATED */}
                                <NavLink to="/login" className={`flex flex-col ${location.pathname === '/login' && 'text-primary-blue'}`}>
                                    <button className="material-symbols-outlined" style={{ fontSize: '1.4rem' }}>login</button>
                                    <span>{t('common:login')}</span>
                                </NavLink>
                                <NavLink to="/register" className={`flex flex-col ${location.pathname === '/register' && 'text-primary-blue'}`}>
                                    <button className="material-symbols-outlined" style={{ fontSize: '1.4rem' }}>assignment</button>
                                    <span>{t('common:register')}</span>
                                </NavLink>
                                <NavLink to={process.env.REACT_APP_LOGGELLO_LANDING_URL || 'https://loggello.com'} className={`flex flex-col`}>
                                    <button className="material-symbols-outlined" style={{ fontSize: '1.4rem' }}>info</button>
                                    <span>{t('common:information')}</span>
                                </NavLink>
                            </>
                        )}

                    </div>
                </div>
            )}
        </>
    )

}

export default MobileNav;