import { Grow } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { LogTemplateDto } from "../../../../../services/tenant/log-template/dto/log-template.dto";
import { useTranslation } from "react-i18next";
import { LogCategories } from "../../../../../services/enum/log-categories";
import { LogCategoriesDto } from "../../../../../services/dto/log-categories.dto";
import { Menu, Transition } from "@headlessui/react";

type Props = {
    logCategories?: LogCategoriesDto[];
    setSelectedCategory: (category: LogCategories | undefined) => void;
    selectedCategory?: LogCategories;
    alignCenter?: boolean;

}

const AllLogCategories = ({ logCategories, setSelectedCategory, selectedCategory, alignCenter }: Props) => {
    const { t } = useTranslation();

    const [sortedCategories, setSortedCategories] = useState<LogCategories[]>([]);

    const handleSelectedCategory = (category: LogCategories) => {
        if (category === selectedCategory) {
            setSelectedCategory(undefined)
        }
        else {
            setSelectedCategory(category);
        }
    }

    useEffect(() => {
        if (logCategories && logCategories.length > 0) {
            let categoriesArr: LogCategories[] = [];
            Object.values(LogCategories).forEach((category) => {
                if (typeof category === 'string' && category !== LogCategories.None) {
                    categoriesArr.push(category);
                }
            });

            // Sort
            const sortedArray = categoriesArr.sort(categoriesSort);

            // Set state
            setSortedCategories(sortedArray);
        }
    }, [logCategories])

    // Sort categories to display "active" categories first
    const categoriesSort = (categoryA: LogCategories, categoryB: LogCategories) => {
        const aMatch = logCategories?.some((e) => e.log_category === categoryA);
        const bMatch = logCategories?.some((e) => e.log_category === categoryB);
        if (aMatch && !bMatch) {
            return -1;
        }
        else if (!aMatch && bMatch) {
            return 1;
        }
        else {
            return 0;
        }
    }

    return (
        <Grow in={logCategories ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
            <div className={`${alignCenter ? 'mx-auto' : ''} flex flex-wrap gap-1 mt-2 sm:mx-0`}>
                {sortedCategories.map((val, idx) => {
                    return (
                        <Fragment key={idx}>
                            {logCategories?.some((e) => e.log_category === val) && (
                                (typeof val === 'string' && val !== 'None') && (
                                    <div
                                        key={idx}
                                        className={`transition-all w-12 sm:w-14 py-1 rounded text-center cursor-pointer text-xs flex items-center ${selectedCategory === val ? 'bg-primary-blue text-secondary-blue' : 'bg-primary-blue-10 text-black'}`}
                                        onClick={() => handleSelectedCategory(val)}
                                    >
                                        {LogCategories[val] === 'Other' && (<span className="material-symbols-outlined w-full" style={{ fontSize: '1.3rem' }} title={t(`common:enums.logCategories.${LogCategories[val]}`)}>home_repair_service</span>)}
                                        {LogCategories[val] === 'Fire' && (<span className="material-symbols-outlined w-full" style={{ fontSize: '1.3rem' }} title={t(`common:enums.logCategories.${LogCategories[val]}`)}>local_fire_department</span>)}
                                        {LogCategories[val] === 'Security' && (<span className="material-symbols-outlined w-full" style={{ fontSize: '1.3rem' }} title={t(`common:enums.logCategories.${LogCategories[val]}`)}>encrypted</span>)}
                                        {LogCategories[val] === 'Camera' && (<span className="material-symbols-outlined w-full" style={{ fontSize: '1.3rem' }} title={t(`common:enums.logCategories.${LogCategories[val]}`)}>photo_camera</span>)}
                                        {LogCategories[val] === 'Access' && (<span className="material-symbols-outlined w-full" style={{ fontSize: '1.3rem' }} title={t(`common:enums.logCategories.${LogCategories[val]}`)}>vpn_key</span>)}
                                        {LogCategories[val] === 'Maintenance' && (<span className="material-symbols-outlined w-full" style={{ fontSize: '1.3rem' }} title={t(`common:enums.logCategories.${LogCategories[val]}`)}>construction</span>)}
                                        {LogCategories[val] === 'Cleaning' && (<span className="material-symbols-outlined w-full" style={{ fontSize: '1.3rem' }} title={t(`common:enums.logCategories.${LogCategories[val]}`)}>mop</span>)}
                                        {LogCategories[val] === 'Rounding' && (<span className="material-symbols-outlined w-full" style={{ fontSize: '1.3rem' }} title={t(`common:enums.logCategories.${LogCategories[val]}`)}>shield_person</span>)}
                                        {LogCategories[val] === 'Elevator' && (<span className="material-symbols-outlined w-full" style={{ fontSize: '1.3rem' }} title={t(`common:enums.logCategories.${LogCategories[val]}`)}>elevator</span>)}
                                        {LogCategories[val] === 'Ventilation' && (<span className="material-symbols-outlined w-full" style={{ fontSize: '1.3rem' }} title={t(`common:enums.logCategories.${LogCategories[val]}`)}>mode_fan</span>)}
                                    </div>
                                )
                            )}
                        </Fragment>
                    )
                })}
            </div>
        </Grow>
    )
}

export default AllLogCategories;