import { useEffect, useState } from "react";

const useWindowWidth = () => {

    const [isMobile, setIsMobile] = useState<boolean>(true);

    const mobileWidth = 640;
    const tabletWidth = 768;
    const tablet2Width = 1024;

    useEffect(() => {
        if (typeof window !== undefined) {
            const handleResize = () => {
                setIsMobile(window.innerWidth < tablet2Width);
            };

            // Add event listener
            window.addEventListener('resize', handleResize);

            setIsMobile(window.innerWidth < tablet2Width);

            // Cleanup function to remove event listener
            return () => {
                window.removeEventListener('resize', handleResize);
            }
        }
    }, [])

    return isMobile;
};

export default useWindowWidth;