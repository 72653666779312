import React, { useEffect } from 'react'
import SubsidiaryTables from '../tables/subsidiary-tables';
import LogTemplateTables from '../tables/log-template-tables';
import UserTables from '../tables/user-tables';
import PropertyTables from '../tables/property-tables';
import { useTranslation } from 'react-i18next';
import { SubsidiaryDto } from '../../../../services/tenant/subsidiary/dto/subsidiary.dto';
import { PropertyDto } from '../../../../services/tenant/property/dto/property.dto';
import { UserDto } from '../../../../services/tenant/user/dto/user.dto';
import { LogTemplateDto } from '../../../../services/tenant/log-template/dto/log-template.dto';

type Props = {
    selectedSubsidiaries?: SubsidiaryDto[];
    selectedProperties?: PropertyDto[];
    selectedUsers?: UserDto[];
    selectedLogTemplates?: LogTemplateDto[];
};

const TableContainer = ({
    selectedSubsidiaries,
    selectedProperties,
    selectedUsers,
    selectedLogTemplates,
}: Props) => {
    const { t } = useTranslation();    

    return (
        <div className="mt-5">

            {selectedSubsidiaries && selectedSubsidiaries.length > 0 && (
                <SubsidiaryTables selectedSubsidiaries={selectedSubsidiaries} />
            )}

            {selectedProperties && selectedProperties.length > 0 && (
                <PropertyTables selectedProperties={selectedProperties} />
            )}

            {selectedUsers && selectedUsers.length > 0 && (
                <UserTables selectedUsers={selectedUsers} />
            )}

            {selectedLogTemplates && selectedLogTemplates.length > 0 && (
                <LogTemplateTables selectedLogTemplates={selectedLogTemplates} />
            )}
        </div>
    )
}

export default TableContainer;