import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const authAPI = createApi({
    reducerPath: 'authAPI',
    tagTypes: ['Post'],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BACKEND_URL,
    }),
    endpoints: (builder) => ({
        // Login
        login: builder.mutation<any, any>({
            query: ({ email, password }) => ({
                credentials: 'include',
                url: '/catalog/auth/login',
                method: 'POST',
                body: { email, password }
            })
        }),
        // Verify OTP code on login
        verifyOtp: builder.mutation<any, { otp: string, email: string }>({
            query: ({ otp, email }) => ({
                url: '/catalog/auth/login/verify/token',
                method: 'POST',
                body: { otp, email }
            })
        }),
        // Verify token sent to email for verification
        authEmailToken: builder.query<any, any>({
            query: (token) => ({
                credentials: 'include',
                url: '/catalog/auth/verify/email/' + token,
                method: 'GET',
            })
        }),
    }),
})

export const {
    useLoginMutation,
    useVerifyOtpMutation,
    useAuthEmailTokenQuery,
} = authAPI