
import { Fragment, useEffect, useRef, useState } from "react";

import Modal from "../../../../molecules/modal";
import { PropertyDto } from "../../../../../services/tenant/property/dto/property.dto";
import PropertyObjectLogAccess from "../log-property-object-access";
import LogPropertyAccess from "../log-property-access";
import LogAccessPropertyBuilding from "../log-property-building-access";
import RevokeLogUserAccess from "../revoke-log-user-access";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../redux/hooks";
import { getCurrentUser } from "../../../../../redux/slices/user.slice";
import { LogTemplateDto } from "../../../../../services/tenant/log-template/dto/log-template.dto";
import { LogCategories } from "../../../../../services/enum/log-categories";
import { LogTypes } from "../../../../../services/enum/log-types";
import { LogAccess } from "../../../../../services/enum/log-access";
import { Order } from "../../../../../services/enum/order";
import { debounce } from "../../../../../utils/debounce";
import { useGetLogTemplatesByUserIdsQuery, useGetLogTemplatesQuery, useGetLogTemplatesUniqueCategoriesQuery } from "../../../../../services/tenant/log-template/log-template.service";
import { Input } from "@mui/material";
import Loader from "../../../../atoms/loader/loader";
import { Menu, Transition } from "@headlessui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { NotificationManager } from 'react-notifications';
import EditCustomLog from "../../../../molecules/edit-custom-log";
import CreateCustomLog from "../../../../molecules/create-custom-log";
import { UserDto } from "../../../../../services/tenant/user/dto/user.dto";
import { useGetAllRevokeLogUserAccessByIdsQuery, useUpdateManyRevokeLogUserAccessMutation } from "../../../../../services/tenant/revoke-log-user-access/revoke-log-user-access.service";
import { UpdateRevokeLogUserAccessDto } from "../../../../../services/tenant/revoke-log-user-access/dto/update-revoke-log-user-access.dto";
import PrimaryButton from "../../../../atoms/primary-button";
import Checkbox from "../../../../atoms/checkbox";
import Pagination from "../../../../molecules/pagination";
import Table from "../../../../molecules/table";
import { useGetAllPropertyLogAccessByIdsQuery, useUpdateManyPropertyLogAccessMutation } from "../../../../../services/tenant/entity-log/entity-log.service";
import { UpdatePropertyLogAccessDto } from "../../../../../services/tenant/entity-log/dto/property-log-access/update-property-log-access.dto";

type FilterType = {
    category: LogCategories;
    showAllTemplates: boolean;
}

type Props = {
    properties?: PropertyDto[];
    users?: UserDto[];
}

const AllLogTemplatesTableEdit = ({
    properties,
    users,
}: Props) => {
    const { t } = useTranslation();
    const { currentUser } = useAppSelector(getCurrentUser);

    const [updatePropertyLogAccess] = useUpdateManyPropertyLogAccessMutation();
    const [updateRevokeLogUserAccess] = useUpdateManyRevokeLogUserAccessMutation();

    // Pagination
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>();
    const [hasNextPage, setHasNextPage] = useState<boolean>();
    const [hasPrevPage, setHasPrevPage] = useState<boolean>();

    // Table sort and search
    const [search, setSearch] = useState<string | undefined>('');
    const [searchOperation, setSearchOperation] = useState<string | undefined>();
    const [sortBy, setSortBy] = useState<string | undefined>('name'); // Column to sort by
    const [sortOrder, setSortOrder] = useState<Order>(Order.ASC); // Sort order
    const [showSortMenu, setShowSortMenu] = useState<boolean>(false); // Sort menu on small screens

    const debouncedSearch = useRef(debounce((search: string) => setSearchOperation(search), 500)).current;

    // State for filters
    const [filterObject, setFilterObject] = useState<FilterType>();
    const [filterString, setFilterString] = useState<string>();
    // State for selected category
    const [selectedCategory, setSelectedCategory] = useState<LogCategories>();

    // State to show only the log templates created by the tenant
    const [showAllTemplates, setShowAllTemplates] = useState<boolean>(true);

    // Selected log template
    const [selectedLogTemplate, setSelectedLogTemplate] = useState<LogTemplateDto>();
    const [showEditLogTemplate, setShowEditLogTemplate] = useState<LogTemplateDto>();

    // Get log templates
    const { data: logTemplates, isFetching } = useGetLogTemplatesQuery({
        page: page,
        take: 25,
        search: searchOperation,
        sortBy: sortBy,
        order: sortOrder,
        filter: filterString
    });

    // Get all unique log categories for log templates
    const { data: logCategories } = useGetLogTemplatesUniqueCategoriesQuery();


    const [showCreateLogTemplate, setShowCreateLogTemplate] = useState<boolean>(false); // State for create modal
    const [showModal, setShowModal] = useState<boolean>(false); // Toggle modal
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false); // Toggle confirm modal

    useEffect(() => {
        if (logTemplates) {
            setPage(logTemplates.meta.page);
            setPageCount(logTemplates.meta.pageCount);
            setHasNextPage(logTemplates.meta.hasNextPage);
            setHasPrevPage(logTemplates.meta.hasPreviousPage);
        }
    }, [logTemplates])

    const [skipTokenPropertyIds, setSkipTokenPropertyIds] = useState<any>(skipToken);
    const { data: propertyLogAccessByIds } = useGetAllPropertyLogAccessByIdsQuery(skipTokenPropertyIds);

    const [skipTokenUserIds, setSkipTokenUserIds] = useState<any>(skipToken);
    const { data: revokeLogUserAccessByIds } = useGetAllRevokeLogUserAccessByIdsQuery(skipTokenUserIds);

    // Get user log templates
    const { data: userLogTemplates } = useGetLogTemplatesByUserIdsQuery(skipTokenUserIds);

    useEffect(() => {
        if (properties) {
            setSkipTokenPropertyIds(properties.map(e => e.id).join(','));
        }
    }, [properties])

    useEffect(() => {
        if (users) {
            setSkipTokenUserIds(users.map(e => e.id).join(','));
        }
    }, [users])

    useEffect(() => {
        const tempObj: FilterType = {
            category: LogCategories.None,
            showAllTemplates: showAllTemplates,
        };
        if (selectedCategory !== undefined && selectedCategory !== LogCategories.None) {
            tempObj.category = selectedCategory;
        }
        if (filterObject || (tempObj.category !== LogCategories.None || tempObj.showAllTemplates === false)) {
            setFilterObject(tempObj);
            const encoded = encodeURIComponent(JSON.stringify(tempObj));
            setFilterString(encoded);
        }
    }, [selectedCategory, showAllTemplates])

    // Edit property
    const handleEditLog = (log: LogTemplateDto) => {
        setShowModal(true); // Show modal
        setSelectedLogTemplate(log);
    }

    const handleSort = (column: string, order: Order) => {
        setSortBy(column);
        setSortOrder(order === Order.ASC ? Order.DESC : Order.ASC);
    }

    const handlePagination = (page: number) => {
        setPage(page);
    }

    const handleUpdatePropertyLogAccess = async (updatePropertyLogAccessDto: UpdatePropertyLogAccessDto[]) => {
        // Update property log access
        try {
            const response = await updatePropertyLogAccess(updatePropertyLogAccessDto).unwrap();
            if (response.success) {
                NotificationManager.success(t('common:notifications.updated'));
            }
            else {
                NotificationManager.error(t('common:notifications.updateFailed'));
            }
        }
        catch (eror) {
            NotificationManager.error(t('common:notifications.updateFailed'));
        }
    }

    const handleUpdateRevokeLogUserAccess = async (updateRevokeLogUserAccessDto: UpdateRevokeLogUserAccessDto[]) => {
        // Update user log access
        try {
            const response = await updateRevokeLogUserAccess(updateRevokeLogUserAccessDto).unwrap();
            if (response.success) {
                NotificationManager.success(t('common:notifications.updated'));
            }
            else {
                NotificationManager.error(t('common:notifications.updateFailed'));
            }
        }
        catch (eror) {
            NotificationManager.error(t('common:notifications.updateFailed'));
        }
    }

    const handleAccess = (logTemplate: LogTemplateDto, id: string) => {

        if (properties && propertyLogAccessByIds) {
            const propertyLogAccessMatch = propertyLogAccessByIds.find(e => e.log_template_id === logTemplate.id && e.property_id === id);
            if (propertyLogAccessMatch) {
                // Remove access
                let updateObj: UpdatePropertyLogAccessDto = {
                    access: false,
                    log_template_id: logTemplate.id,
                    property_id: id,
                    entity_log_id: propertyLogAccessMatch.id
                }
                handleUpdatePropertyLogAccess([updateObj]);
            }
            else {
                // Give access
                let updateObj: UpdatePropertyLogAccessDto = {
                    access: true,
                    log_template_id: logTemplate.id,
                    property_id: id,
                }
                handleUpdatePropertyLogAccess([updateObj]);
            }
        }
        if (users && revokeLogUserAccessByIds) {
            const revokeLogUserAccessMatch = revokeLogUserAccessByIds.find(e => e.log_template_id === logTemplate.id && e.user_id === id);
            if (revokeLogUserAccessMatch) {
                // Remove access
                let updateObj: UpdateRevokeLogUserAccessDto = {
                    access: false,
                    log_template_id: logTemplate.id,
                    user_id: id,
                    revoke_log_user_access_id: revokeLogUserAccessMatch.id
                }
                handleUpdateRevokeLogUserAccess([updateObj]);
            }
            else {
                // Give access
                let updateObj: UpdateRevokeLogUserAccessDto = {
                    access: true,
                    log_template_id: logTemplate.id,
                    user_id: id,
                }
                handleUpdateRevokeLogUserAccess([updateObj]);
            }
        }
    }

    return (
        <div className="w-full mx-auto">
            <div className="flex items-center">
                <h5 className="text-base font-semibold ml-2 w-5/12">{t('common:logTemplates')}</h5>
                <div className="w-7/12 flex justify-end">
                    <PrimaryButton
                        text={t('page:logs.createLog')}
                        size={'medium'}
                        startAdornment={'add'}
                        onClick={() => setShowCreateLogTemplate(true)}
                    />
                </div>
            </div>
            <div className="relative">
                <div className="sm:w-6/12 max-w-2xl mt-2 text-center sm:text-left">
                    <Input
                        type="search"
                        name="search_name"
                        placeholder={t('common:search')}
                        disableUnderline={true}
                        className="w-full border rounded-md text-base md:text-sm px-2 pt-2.5 pb-1 mt-3 bg-white"
                        onChange={(e) => {
                            setSearch(e.target.value)
                            debouncedSearch(e.target.value)
                        }
                        }
                        value={search}
                        endAdornment={
                            <span
                                className="material-symbols-outlined pb-1 text-gray-500 cursor-pointer"
                                style={{ fontSize: '1.3rem' }}
                                onClick={() => {
                                    if (search) {
                                        setSearch('')
                                        debouncedSearch('')
                                    }
                                }}
                            >
                                {search ? 'close' : 'search'}
                            </span>
                        }
                        size="small"
                    />
                </div>
                {/* <div className="px-2 mb-2">
                    <AllLogCategories logCategories={logCategories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
                </div> */}

                {setShowAllTemplates && logTemplates && logTemplates.data && logTemplates.data.length > 0 && (
                    <div
                        onClick={() => setShowAllTemplates(!showAllTemplates)}
                        className="inline-block relative md:mx-1 md:p-1 md:mt-1 cursor-pointer">
                        <Checkbox
                            label={t('page:logs.showOnlyMyLogs')}
                            size={'sm'}
                            checked={!showAllTemplates}
                        />
                    </div>
                )}

                <div className="overflow-x-auto max-h-[45vh]">
                    {logTemplates && logTemplates.data && logTemplates.data.length > 0 && (
                        <Table>
                            <Table.Thead>
                                <tr className="text-xs">
                                    <th scope="col" className="sticky top-0 bg-white z-10">
                                        <span className="sr-only">Category</span>
                                    </th>
                                    <th
                                        scope="col"
                                        className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[8rem] max-w-[8rem] sticky top-0 bg-white z-10"
                                        onClick={() => handleSort('name', sortOrder)}>
                                        {t('common:name')}
                                        {(sortBy === 'name') ? (
                                            <>
                                                {sortOrder === Order.ASC ? (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                ) : (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                            </>
                                        )}
                                    </th>
                                    {properties && [...properties]
                                        .sort((a, b) => a.label.localeCompare(b.label))
                                        .map(property => {
                                            return (
                                                <th
                                                    key={property.id}
                                                    scope="col"
                                                    className="py-1 pl-2 md:py-2 pr-2 sm:table-cell cursor-pointer font-bold min-w-[8rem] max-w-[8rem] sticky top-0 bg-white z-10">
                                                    {property.label}
                                                </th>
                                            )
                                        })}
                                    {users && [...users]
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map(user => {
                                            return (
                                                <th
                                                    key={user.id}
                                                    scope="col"
                                                    className="py-1 pl-2 md:py-2 pr-2 sm:table-cell cursor-pointer font-bold min-w-[8rem] max-w-[8rem] sticky top-0 bg-white z-10">
                                                    {user.name}
                                                </th>
                                            )
                                        })}
                                    <th scope="col" className="sticky top-0 bg-white z-10">
                                        <span className="sr-only">Edit Access</span>
                                    </th>
                                    <th scope="col" className="sticky top-0 bg-white z-10">
                                        <span className="sr-only">Edit Log</span>
                                    </th>
                                    <th scope="col" className="sticky top-0 bg-white z-10">
                                        <span className="sr-only">Delete</span>
                                    </th>
                                </tr>
                            </Table.Thead>
                            <Table.Tbody>
                                {logTemplates?.data.map((template, idx) => {
                                    return (
                                        <tr
                                            key={idx}
                                            className={`odd:hover:bg-primary-blue-10 even:hover:bg-gray-50 cursor-pointer text-xs text-black even:bg-white odd:bg-primary-blue-5`}>
                                            <td className="w-14 md:w-20 pl-2 capitalize py-1">
                                                <div className="flex items-center justify-center w-8 h-8 rounded-full border border-gray-100">
                                                    {template.category === LogCategories.Other && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>home_repair_service</span>)}
                                                    {template.category === LogCategories.Fire && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>local_fire_department</span>)}
                                                    {template.category === LogCategories.Security && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>encrypted</span>)}
                                                    {template.category === LogCategories.Camera && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>photo_camera</span>)}
                                                    {template.category === LogCategories.Access && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>vpn_key</span>)}
                                                    {template.category === LogCategories.Maintenance && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>construction</span>)}
                                                    {template.category === LogCategories.Cleaning && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>mop</span>)}
                                                    {template.category === LogCategories.Rounding && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>shield_person</span>)}
                                                    {template.category === LogCategories.Elevator && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>elevator</span>)}
                                                    {template.category === LogCategories.Ventilation && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>mode_fan</span>)}
                                                </div>
                                            </td>
                                            <td
                                                scope="row"
                                                className="py-2 pr-2 pl-1 sm:pl-2 text-xs first-letter:capitalize font-semibold min-w-[5rem] max-w-[5rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                            >
                                                {
                                                    template.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${template.name.split('_').join('')}`) :
                                                        template.name.split('_').join(' ')
                                                }
                                            </td>
                                            {properties && propertyLogAccessByIds && [...properties]
                                                .sort((a, b) => a.label.localeCompare(b.label))
                                                .map(property => {
                                                    return (
                                                        <td
                                                            key={property.id}
                                                            className="pl-4 text-xs capitalize font-semibold sm:font-normal max-w-[3rem] min-w-[3rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                                            <Checkbox
                                                                size={'sm'}
                                                                checked={propertyLogAccessByIds.find(e => e.log_template_id === template.id && e.property_id === property.id) ? true : false}
                                                                onChange={() => handleAccess(template, property.id)}
                                                            />
                                                        </td>
                                                    )
                                                })}

                                            {users && revokeLogUserAccessByIds && userLogTemplates && [...users]
                                                .sort((a, b) => a.name.localeCompare(b.name))
                                                .map(user => {
                                                    return (
                                                        <td
                                                            key={user.id}
                                                            className="pl-4 text-xs capitalize font-semibold sm:font-normal max-w-[3rem] min-w-[3rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                                            <Checkbox
                                                                size={'sm'}
                                                                disabled={userLogTemplates.find(e => e.log_template_id === template.id && e.user_id === user.id) ? false : true}
                                                                checked={
                                                                    userLogTemplates.find(e => e.log_template_id === template.id && e.user_id === user.id) ?
                                                                        revokeLogUserAccessByIds.find(e => e.log_template_id === template.id && e.user_id === user.id) ? false : true : false
                                                                }
                                                                onChange={() => handleAccess(template, user.id)}
                                                            />
                                                        </td>
                                                    )
                                                })}
                                            <td className="text-right">
                                                <button
                                                    onClick={() => handleEditLog(template)}
                                                    className="align-middle text-black material-symbols-outlined"
                                                    title={t('common:editAccess')}
                                                    style={{ fontSize: '1.3rem ' }}
                                                >room_preferences</button>
                                            </td>
                                            <td className="text-center px-2">
                                                <button
                                                    onClick={() => setShowEditLogTemplate(template)}
                                                    disabled={template.log_type === LogTypes.Default || currentUser.tenant_id !== template.tenant_id}
                                                    className="align-middle text-black material-symbols-outlined pb-0.5 disabled:text-gray-300"
                                                    style={{ fontSize: '1.2rem' }}
                                                    title={t('common:editTemplate')}
                                                >edit_square</button>
                                            </td>
                                            <td className="text-left">
                                                <button
                                                    disabled={true}
                                                    className="align-middle material-symbols-outlined text-status-critical disabled:text-gray-300"
                                                    title={t('common:delete')}
                                                    style={{ fontSize: '1.3rem' }}
                                                >delete</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </Table.Tbody>
                        </Table>
                    )}
                </div>

                {isFetching && (
                    <div className="mx-auto mb-5 mt-1">
                        <Loader show={isFetching} size='small' />
                    </div>
                )}

                {!logTemplates || logTemplates.data.length < 1 && (
                    <div className="my-5">
                        <p className="text-sm ml-5 text-black font-semibold">{t('page:properties.noLogsFound')}...</p>
                    </div>
                )}
                {logTemplates?.meta && logTemplates?.meta?.hasNextPage && (
                    <div className="flex justify-center py-2 border-t">
                        <Pagination
                            count={pageCount}
                            page={page}
                            defaultPage={1}
                            showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                            showLastButton={pageCount ? pageCount > 5 : false}
                            hidePrevButton={!hasPrevPage}
                            hideNextButton={!hasNextPage}
                            onChange={handlePagination}
                        />
                    </div>
                )}
            </div>

            <Modal isOpen={showCreateLogTemplate} close={() => setShowCreateLogTemplate(false)} title={t('page:logs.createLog')} size='md' titleAlign='center'>
                <CreateCustomLog setShowCreateLogTemplate={setShowCreateLogTemplate} />
            </Modal>
            <Modal isOpen={showEditLogTemplate ? true : false} close={() => setShowEditLogTemplate(undefined)} title={showEditLogTemplate ? `${t('common:edit')} ${showEditLogTemplate.name.split('_').join(' ')}` : t('page:logs.editLogTemplate')} size='md' titleAlign='center'>
                <div>
                    <div className="w-full mx-auto mb-5">
                        <div className="">
                            <EditCustomLog logTemplate={showEditLogTemplate} />
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={showModal} close={() => setShowModal(false)} title={t('page:logs.editLogAccess')} size='sm' titleAlign='center'>
                <div className="w-10/12 flex flex-wrap justify-center mx-auto">
                    <h5 className="font-semibold mb-3 capitalize">
                        ({selectedLogTemplate?.log_type === LogTypes.Default ?
                            t(`page:logs.defaultLogs.${selectedLogTemplate.name.split('_').join('')}`) :
                            selectedLogTemplate?.name.split('_').join(' ')})
                    </h5>

                    <LogPropertyAccess logTemplate={selectedLogTemplate} />

                    <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                    <LogAccessPropertyBuilding logTemplate={selectedLogTemplate} />

                    <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                    <PropertyObjectLogAccess logTemplate={selectedLogTemplate} />

                    <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                    <RevokeLogUserAccess logTemplate={selectedLogTemplate} />

                </div>
            </Modal>
        </div>
    )
}

export default AllLogTemplatesTableEdit;