import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useCreateFriendshipMutation, useDeleteFriendshipMutation, useGetAllFriendshipTenantsQuery, useGetMyFriendshipTenantsQuery, useUpdateFriendshipMutation } from '../../../../../services/catalog/friendship/friendship.service'
import { Input } from '@mui/material';
import { Order } from '../../../../../services/enum/order';
import { debounce } from '../../../../../utils/debounce';
import { useTranslation } from 'react-i18next';
import { TenantTypes } from '../../../../../services/enum/tenant-types';
import Loader from '../../../../atoms/loader/loader';
import { FriendshipTenantDto } from '../../../../../services/catalog/friendship/dto/friendship-tenant.dto';
import { FriendshipStatus } from '../../../../../services/enum/friendship-status';
import CompanyFileUpload from '../../../company/company-file-upload';
import { useAppSelector } from '../../../../../redux/hooks';
import { getTenant } from '../../../../../redux/slices/tenant.slice';
import { CreateFriendshipDto } from '../../../../../services/catalog/friendship/dto/create-friendship.dto';
import { NotificationManager } from 'react-notifications';
import { UpdateFriendshipDto } from '../../../../../services/catalog/friendship/dto/update-friendship.dto';
import ConfirmModal from '../../../../molecules/confirm-modal';
import { Link } from 'react-router-dom';
import Pagination from '../../../../molecules/pagination';
import Table from '../../../../molecules/table';
import { Menu, Transition } from '@headlessui/react';
import { Roles } from '../../../../../services/enum/roles';
import { getCurrentUser } from '../../../../../redux/slices/user.slice';
import { dateToLocaleDate } from '../../../../../utils/date-time';

type Props = {

}

const RelatedCompaniesTable = (
  {

  }: Props) => {
  const { t } = useTranslation();
  const { currentUser } = useAppSelector(getCurrentUser);
  const { tenant } = useAppSelector(getTenant)

  const [createFriendship] = useCreateFriendshipMutation();
  const [updateFriendship] = useUpdateFriendshipMutation();
  const [deleteFriendship] = useDeleteFriendshipMutation();

  // const [showCopyModal, setShowCopyModal] = useState<boolean>(false); // Toggle copy modal
  // const [showModal, setShowModal] = useState<boolean>(false); // Toggle modal
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false); // Toggle confirm modal
  const [selectedCompany, setSelectedCompany] = useState<FriendshipTenantDto>();
  const [removalType, setRemovalType] = useState<string>();
  // const [tableHeaders, setTableHeaders] = useState<string[]>();

  // Pagination
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>();
  const [hasNextPage, setHasNextPage] = useState<boolean>();
  const [hasPrevPage, setHasPrevPage] = useState<boolean>();

  // Table sort and search
  const [search, setSearch] = useState<string | undefined>('');
  const [searchOperation, setSearchOperation] = useState<string | undefined>();
  const [sortBy, setSortBy] = useState<string | undefined>('company_name'); // Column to sort by
  const [sortOrder, setSortOrder] = useState<Order>(Order.ASC); // Sort order
  const [showSortMenu, setShowSortMenu] = useState<boolean>(false); // Sort menu on small screens

  const debouncedSearch = useRef(debounce((search: string) => setSearchOperation(search), 500)).current;

  // Get properties paginated
  const { data: companies, isFetching } = useGetMyFriendshipTenantsQuery({
    page: page,
    take: 25,
    search: searchOperation,
    sortBy: sortBy,
    order: sortOrder
  });

  useEffect(() => {
    if (companies) {
      setPage(companies.meta.page);
      setPageCount(companies.meta.pageCount);
      setHasNextPage(companies.meta.hasNextPage);
      setHasPrevPage(companies.meta.hasPreviousPage);
    }
  }, [companies])

  const handlePagination = (page: number) => {
    setPage(page);
  }

  // If selectedCompany changes, set updated property
  useEffect(() => {
    if (selectedCompany) {
      const match = companies?.data.find((e) => e.tenant_id === selectedCompany.tenant_id);
      if (match) {
        setSelectedCompany(match);
      }
    }
  }, [companies]);

  // Handle sort by column and sort order
  const handleSort = (column: string, order: Order) => {
    setSortBy(column);
    setSortOrder(order === Order.ASC ? Order.DESC : Order.ASC)
  }

  const handleProperty = () => { }

  const handleRequestRelation = async (company: FriendshipTenantDto) => {
    // Send request to selected tenant
    // Update status of icon

    if (tenant) {

      const createFriendshipDto: CreateFriendshipDto = {
        sender_tenant_id: tenant.id,
        receiver_tenant_id: company.tenant_id,
        status: FriendshipStatus.Pending,
      }

      try {
        const response = await createFriendship(createFriendshipDto).unwrap();
        if (response.success) {
          NotificationManager.success(t('page:friendship.createRequestSuccess'));
        }
        else {
          NotificationManager.error(t('page:friendship.createRequestFailed'))
        }
      }
      catch (error) {
        NotificationManager.error(t('page:friendship.createRequestFailed'));
      }
    }

  }

  const handleRemoveRelation = async (company: FriendshipTenantDto, type: string) => {
    if (tenant && company.id) {
      try {
        const response = await deleteFriendship(company.id).unwrap();
        if (response.success) {
          if (type === 'remove')
            NotificationManager.success(t('page:friendship.removeRequestSuccess'));
          else if (type === 'cancel')
            NotificationManager.success(t('page:friendship.cancelRequestSuccess'));
          else if (type === 'deny')
            NotificationManager.success(t('page:friendship.denyRequestSuccess'));

          setShowConfirmModal(false);
        }
        else {
          if (type === 'remove')
            NotificationManager.error(t('page:friendship.removeRequestFailed'))
          if (type === 'cancel')
            NotificationManager.error(t('page:friendship.cancelRequestFailed'))
          if (type === 'deny')
            NotificationManager.error(t('page:friendship.denyRequestFailed'))

          setShowConfirmModal(false);
        }
      }
      catch (error) {
        if (type === 'remove')
          NotificationManager.error(t('page:friendship.removeRequestFailed'))
        if (type === 'cancel')
          NotificationManager.error(t('page:friendship.cancelRequestFailed'))
        if (type === 'deny')
          NotificationManager.error(t('page:friendship.denyRequestFailed'))

        setShowConfirmModal(false);
      }
    }
  }

  // Toggle confirm modal before remove, cancel or denying request
  const handleRemoval = (company: FriendshipTenantDto, type: string) => {
    setRemovalType(type);
    setSelectedCompany(company);
    setShowConfirmModal(true); // Toggle confirm modal
  }

  const handleRedirect = (company: FriendshipTenantDto) => {

  }

  return (
    <>
      {(companies && companies.data.length < 1 && !searchOperation) ? (
        <div className="w-10/12 xl:w-8/12 max-w-2xl mx-auto bg-primary-blue-5 text-black rounded-md px-2 py-4 mt-10 text-center">
          <p className="text-lg font-semibold">{t('page:friendship.noCompaniesFound')}...</p>
          <p className="mt-2">{t('page:friendship.noRelatedCompaniesFoundInfo')}</p>
        </div>
      ) : (
        <div className="w-full mx-auto md:mt-1">
          <div className="hidden md:block">
            <div className="sm:w-6/12 max-w-2xl mt-2 text-center sm:text-left">
              <Input
                type="search"
                name="search_name"
                placeholder={t('common:search')}
                disableUnderline={true}
                value={search}
                className="w-full border rounded-md text-base md:text-sm px-2 pt-2.5 pb-1 mt-3 bg-white"
                onChange={(e) => {
                  setSearch(e.target.value);
                  debouncedSearch(e.target.value);
                }}
                endAdornment={
                  <span
                    className="material-symbols-outlined pb-1 text-gray-500 cursor-pointer"
                    style={{ fontSize: '1.3rem' }}
                    onClick={() => {
                      if (search) {
                        setSearch('')
                        debouncedSearch('')
                      }
                    }}
                  >
                    {search ? 'close' : 'search'}
                  </span>
                }
                size="small"
              />
            </div>
            <div className="overflow-x-scroll">
              <Table>
                <Table.Thead>
                  <tr>
                    <th scope="col" className="w-20 hidden sm:table-cell">
                      <span className="sr-only">Company logo</span>
                    </th>
                    <th
                      scope="col"
                      className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]"
                      onClick={() => handleSort('company_name', sortOrder)}>
                      {t('common:name')}
                      {(sortBy === 'company_name') ? (
                        <>
                          {sortOrder === Order.ASC ? (
                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                          ) : (
                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                          )}
                        </>
                      ) : (
                        <>
                          <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                        </>
                      )}
                    </th>
                    <th
                      scope="col"
                      className="py-1 md:py-2 pr-2 cursor-pointer font-bold min-w-[4rem]">
                      {t('common:type')}
                    </th>
                    <th
                      scope="col"
                      className="py-1 md:py-2 pr-2 cursor-pointer font-bold min-w-[6rem]"
                      onClick={() => handleSort('status', sortOrder)}
                    >
                      {t('common:status')}
                      {(sortBy === 'status') ? (
                        <>
                          {sortOrder === Order.ASC ? (
                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                          ) : (
                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                          )}
                        </>
                      ) : (
                        <>
                          <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                        </>
                      )}
                    </th>
                    <th
                      scope="col"
                      className="py-1 md:py-2 cursor-pointer font-bold min-w-[6rem]">
                      {t('common:address')}
                    </th>
                    <th scope="col" className="">
                      <span className="sr-only">Relation</span>
                    </th>
                    <th scope="col" className="">
                      <span className="sr-only">Handle Relation</span>
                    </th>
                    <th scope="col" className="">
                      <span className="sr-only">Redirect</span>
                    </th>

                  </tr>
                </Table.Thead>
                {companies?.data && companies.data.length > 0 && !isFetching && (
                  <Table.Tbody>
                    {companies?.data.map((company, idx) => {
                      return (
                        <tr key={idx} className={`odd:hover:bg-primary-blue-10 even:hover:bg-gray-50 cursor-pointer text-xs text-black even:bg-white odd:bg-primary-blue-5`}>
                          <td scope="row" className="pl-1 capitalize hidden sm:table-cell py-2 lg:py-1">
                            {company.logo ? (
                              <div className="flex items-center w-9 lg:w-12 h-9 lg:h-12 rounded-full bg-white overflow-hidden">
                                <img
                                  src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${company.tenant_id}/${company.logo}`}
                                  // onError={e => e.currentTarget.src = DefaultProperty}
                                  alt="Property image"
                                  className="w-9 md:w-12 h-9 md:h-12 rounded-full object-contain lg:p-0.5" />
                              </div>
                            ) : (
                              <div className="flex items-center justify-center w-9 lg:w-12 h-9 lg:h-12 rounded-full border border-gray-100">
                                <span
                                  className="material-symbols-outlined"
                                  style={{ fontSize: '1.3rem' }}
                                >
                                  home_work
                                </span>
                              </div>
                            )}
                          </td>
                          <td
                            className="py-2 pr-2 pl-1 sm:pl-2 text-xs capitalize font-semibold sm:font-normal max-w-[9rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                            title={company?.company_name}
                            onClick={handleProperty}>
                            {company.company_name ? company.company_name : '-'}
                          </td>
                          <td
                            className="py-2 pr-2 capitalize max-w-[4rem] min-w-[4rem] overflow-hidden whitespace-nowrap text-ellipsis"
                            title={company.company_type && t(`common:enums.tenantTypes.${company.company_type.split(' ').join('_')}`)}
                            onClick={handleProperty}>
                            {t(`common:enums.tenantTypes.${company.company_type.split(' ').join('_')}`) ?? '-'}
                          </td>
                          <td
                            className="py-2 pr-2 max-w-[7rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                            title={company?.status}
                            onClick={handleProperty}>
                            {company.status && company.status === FriendshipStatus.Pending && (
                              company.receiver_tenant_id === tenant.id ? (
                                <span className="bg-blue-300 py-1 px-2 rounded-md">
                                  {t('page:friendship.pendingReceived')}
                                </span>
                              ) : (
                                company.sender_tenant_id === tenant.id ? (
                                  <span className="bg-blue-300 py-1 px-2 rounded-md">
                                    {t('page:friendship.pendingSent')}
                                  </span>
                                ) : (
                                  <span className="">
                                    {t(`common:enums.friendshipStatus.${company.status}`)}
                                  </span>
                                )
                              )
                            )}
                            {company.status && company.status === FriendshipStatus.Accepted && (
                              <span className="bg-green-300 py-1 px-2 rounded-md">
                                {t(`common:enums.friendshipStatus.${company.status}`)}
                              </span>
                            )}
                            {company.status && company.status !== FriendshipStatus.Pending && company.status !== FriendshipStatus.Accepted && (
                              t(`common:enums.friendshipStatus.${company.status}`)
                            )}
                            {!company.status && (
                              <span className="bg-yellow-300 py-1 px-2 rounded-md">
                                {t('common:noRelation')}
                              </span>
                            )}
                          </td>

                          <td
                            className="py-2 pr-2 max-w-[7rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                            title={company?.address}
                            onClick={handleProperty}>
                            {company.address ? company.address : '-'}
                          </td>

                          {/* No status / no relation  */}
                          {!company.status && (
                            <>
                              <td className="md:px-2 lg:px-1">
                                <button
                                  onClick={() => handleRequestRelation(company)}
                                  className="text-black material-symbols-outlined"
                                  style={{ fontSize: '1.4rem' }}
                                  title={t('page:friendship.requestRelation')}
                                >person_add</button>
                              </td>
                              <td className="md:px-2 lg:px-1">
                                <button
                                  className="text-black material-symbols-outlined disabled:text-gray-300"
                                  disabled={true}
                                  style={{ fontSize: '1.3rem' }}
                                  title={t('page:friendship.removeRelation')}
                                >block</button>
                              </td>
                            </>
                          )}
                          {/* Accepted relation  */}
                          {company.status === FriendshipStatus.Accepted && (
                            <>
                              <td className="md:px-2 lg:px-1">
                                <button
                                  // onClick={() => handleRemoveRelation(company)}
                                  className="text-black material-symbols-outlined cursor-default"
                                  style={{ fontSize: '1.4rem' }}
                                  title={t('common:relation')}
                                >person_check</button>
                              </td>
                              <td className="md:px-2 lg:px-1">
                                <button
                                  // onClick={() => handleRemoveRelation(company, 'remove')}
                                  onClick={() => handleRemoval(company, 'remove')}
                                  className="material-symbols-outlined text-status-critical"
                                  style={{ fontSize: '1.3rem' }}
                                  title={t('page:friendship.removeRelation')}
                                >block</button>
                              </td>
                            </>
                          )}
                          {/* Pending request, for sender  */}
                          {company.status === FriendshipStatus.Pending && company.sender_tenant_id === tenant.id && (
                            <>
                              <td className="md:px-2 lg:px-1">
                                <button
                                  // onClick={() => handleCancelRequest(company)}
                                  className="material-symbols-outlined py-1"
                                  style={{ fontSize: '1.2rem' }}
                                  title={t('page:friendship.pendingRequest')}
                                >hourglass_empty</button>
                              </td>
                              <td className="md:px-2 lg:px-1">
                                <button
                                  // onClick={() => handleRemoveRelation(company, 'cancel')}
                                  onClick={() => handleRemoval(company, 'cancel')}
                                  className="material-symbols-outlined text-status-critical"
                                  style={{ fontSize: '1.3rem' }}
                                  title={t('page:friendship.cancelRequest')}
                                >block</button>
                              </td>
                            </>
                          )}
                          {/* Pending request, for receiver  */}
                          {company.status === FriendshipStatus.Pending && company.receiver_tenant_id === tenant.id && (
                            <>
                              <td className="md:px-2 lg:px-1">
                                <button
                                  // onClick={() => handleRemoveRelation(company, 'deny')}
                                  onClick={() => handleRemoval(company, 'deny')}
                                  className="material-symbols-outlined text-status-critical"
                                  style={{ fontSize: '1.3rem' }}
                                  title={t('page:friendship.denyRequest')}
                                >block</button>
                              </td>
                            </>
                          )}

                          <td className="md:px-2 lg:px-1">
                            <Link to="/logs" state={{ company: company }}>
                              <span
                                className="material-symbols-outlined"
                                style={{ fontSize: '1.3rem' }}
                                title={t('common:handle')}
                              >arrow_forward</span>
                            </Link>
                          </td>
                        </tr>
                      )
                    })}
                  </Table.Tbody>
                )}
              </Table>
              {isFetching && (
                <div className="mx-auto mb-5 mt-1">
                  <Loader show={isFetching} size='small' />
                </div>
              )}

              {!companies || companies.data.length < 1 && (
                <div className="my-5">
                  <p className="text-sm ml-7 md:ml-5 font-semibold text-black">{t('page:friendship.noCompaniesFound')}...</p>
                </div>
              )}
            </div>
            {companies && companies.meta && (
              <div className="flex justify-center py-2">
                <Pagination
                  count={pageCount}
                  page={page}
                  defaultPage={1}
                  showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                  showLastButton={pageCount ? pageCount > 5 : false}
                  hidePrevButton={!hasPrevPage}
                  hideNextButton={!hasNextPage}
                  onChange={handlePagination}
                />
              </div>
            )}
          </div>

          {/* Mobile screen  */}
          <div className="block md:hidden w-full mx-auto mt-1">
            <div className="pt-1">
              <Input
                type="search"
                name="search_name"
                placeholder={t('common:search')}
                disableUnderline={true}
                className="border rounded-md px-2 pt-2.5 pb-1 mt-3 w-10/12 bg-white"
                onChange={(e) => debouncedSearch(e.target.value)}
                endAdornment={<span className="material-symbols-outlined text-gray-500 pb-1" style={{ fontSize: '1.3rem' }}>search</span>}
                size="small"
              />
              <Menu as="div" className="relative inline-block text-left cursor-pointer">
                <div>
                  <Menu.Button
                    className="material-symbols-outlined text-black align-sub ml-3 rotate-90 border rounded-md px-1 py-1"
                    style={{ fontSize: '1.4rem' }}
                    onClick={() => setShowSortMenu(!showSortMenu)}>
                    sync_alt
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    className="absolute right-0 z-10 mt-2 w-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="">
                      <Menu.Item>
                        <div className="flex hover:bg-primary-blue-5"
                          onClick={(e) => handleSort('label', sortOrder)}>
                          <span className="w-1/2 block px-2 py-2 text-sm">{t('common:label')}</span>
                          <div className="w-1/2 py-2 pr-1">
                            {(sortBy === 'label') ? (
                              <>
                                {sortOrder === Order.ASC ? (
                                  <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                ) : (
                                  <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                )}
                              </>
                            ) : (
                              <>
                                <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                              </>
                            )}
                          </div>
                        </div>
                      </Menu.Item>
                      <Menu.Item>
                        <div className="flex hover:bg-primary-blue-5" onClick={() => handleSort('object_number', sortOrder)}>
                          <span className="w-1/2 block px-2 py-2 text-sm">{t('common:objectNumber')}</span>
                          <div className="w-1/2 py-2 pr-1">
                            {(sortBy === 'object_number') ? (
                              <>
                                {sortOrder === Order.ASC ? (
                                  <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                ) : (
                                  <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                )}
                              </>
                            ) : (
                              <>
                                <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                              </>
                            )}
                          </div>
                        </div>
                      </Menu.Item>
                      <Menu.Item>
                        <div className="flex hover:bg-primary-blue-5" onClick={() => handleSort('created_at', sortOrder)}>
                          <span className="w-1/2 block px-2 py-2 text-sm">{t('common:createdAt')}</span>
                          <div className="w-1/2 py-2 pr-1">
                            {(sortBy === 'created_at') ? (
                              <>
                                {sortOrder === Order.ASC ? (
                                  <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                ) : (
                                  <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                )}
                              </>
                            ) : (
                              <>
                                <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                              </>
                            )}
                          </div>
                        </div>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            {companies?.data && companies.data.length > 0 && !isFetching && (
              <div>
                {companies?.data?.map((company, idx) => (
                  <div key={idx} className="my-2 p-2 text-xs relative odd:bg-primary-blue-5 odd:hover:bg-primary-blue-10 even:border border-primary-blue-5 hover:even:bg-gray-50 rounded-md">
                    <div className="flex flex-col flex-wrap gap-x-5 my-1">
                      {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                        <div>
                          {/* No status / no relation  */}
                          {!company.status && (
                            <>
                              <button
                                onClick={() => handleRequestRelation(company)}
                                className="text-black material-symbols-outlined absolute right-10 top-1.5"
                                style={{ fontSize: '1.4rem' }}
                                title={t('page:friendship.requestRelation')}
                              >person_add</button>
                              <button
                                className="material-symbols-outlined disabled:text-gray-300 absolute right-2 top-2"
                                disabled={true}
                                style={{ fontSize: '1.3rem' }}
                                title={t('page:friendship.removeRelation')}
                              >block</button>
                            </>
                          )}
                          {/* Accepted relation  */}
                          {company.status === FriendshipStatus.Accepted && (
                            <>
                              <button
                                // onClick={() => handleRemoveRelation(company)}
                                className="text-black material-symbols-outlined absolute right-10 top-1.5"
                                style={{ fontSize: '1.4rem' }}
                                title={t('common:relation')}
                              >person_check</button>
                              <button
                                // onClick={() => handleRemoveRelation(company, 'remove')}
                                onClick={() => handleRemoval(company, 'remove')}
                                className="material-symbols-outlined disabled:text-gray-300 absolute right-2 top-2 text-status-critical"
                                style={{ fontSize: '1.3rem' }}
                                title={t('page:friendship.removeRelation')}
                              >block</button>
                            </>
                          )}
                          {/* Pending request, for sender  */}
                          {company.status === FriendshipStatus.Pending && company.sender_tenant_id === tenant.id && (
                            <>
                              <button
                                // onClick={() => handleCancelRequest(company)}
                                className="text-black material-symbols-outlined absolute right-10 top-1.5"
                                style={{ fontSize: '1.2rem' }}
                                title={t('page:friendship.pendingRequest')}
                              >hourglass_empty</button>
                              <button
                                // onClick={() => handleRemoveRelation(company, 'cancel')}
                                onClick={() => handleRemoval(company, 'cancel')}
                                className="material-symbols-outlined disabled:text-gray-300 absolute right-2 top-2 text-status-critical"
                                style={{ fontSize: '1.3rem' }}
                                title={t('page:friendship.cancelRequest')}
                              >block</button>
                            </>
                          )}
                          {/* Pending request, for receiver  */}
                          {company.status === FriendshipStatus.Pending && company.receiver_tenant_id === tenant.id && (
                            <>
                              <button
                                // onClick={() => handleRemoveRelation(company, 'deny')}
                                onClick={() => handleRemoval(company, 'deny')}
                                className="material-symbols-outlined disabled:text-gray-300 absolute right-2 top-2 text-status-critical"
                                style={{ fontSize: '1.3rem' }}
                                title={t('page:friendship.denyRequest')}
                              >block</button>
                            </>
                          )}
                        </div>
                      )}

                      <div className="flex items-center">
                        <div className="sm:ml-5">
                          {company.logo ? (
                            <div className="flex items-center rounded-full bg-white overflow-hidden">
                              <img
                                src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${company.tenant_id}/${company.logo}`}
                                // onError={e => e.currentTarget.src = DefaultPersonImg}
                                alt="Company logo"
                                className="rounded-full w-12 sm:w-14 h-12 sm:h-14 object-contain" />
                            </div>
                          ) : (
                            <div className="flex items-center w-12 sm:w-14 h-12 sm:h-14 justify-center rounded-full border border-gray-100">
                              <span
                                className="material-symbols-outlined"
                                style={{ fontSize: '1.4rem' }}
                              >
                                home_work
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="ml-3 sm:ml-10">
                          <p className="font-semibold text-sm capitalize">{company.company_name}</p>
                          <p>{t(`common:enums.tenantTypes.${company.company_type.split(' ').join('_')}`) ?? '-'}</p>
                          <p>{company.address ? company.address : '-'}</p>
                        </div>

                        <p className="absolute right-2 bottom-2 text-xs">
                          {company.status && company.status === FriendshipStatus.Pending && (
                            company.receiver_tenant_id === tenant.id ? (
                              <span className="bg-blue-300 py-0.5 px-1.5 rounded-md">
                                {t('page:friendship.pendingReceived')}
                              </span>
                            ) : (
                              company.sender_tenant_id === tenant.id ? (
                                <span className="bg-blue-300 py-0.5 px-1.5 rounded-md">
                                  {t('page:friendship.pendingSent')}
                                </span>
                              ) : (
                                <span className="">
                                  {t(`common:enums.friendshipStatus.${company.status}`)}
                                </span>
                              )
                            )
                          )}
                          {company.status && company.status === FriendshipStatus.Accepted && (
                            <span className="bg-green-300 py-0.5 px-1.5 rounded-md">
                              {t(`common:enums.friendshipStatus.${company.status}`)}
                            </span>
                          )}
                          {company.status && company.status !== FriendshipStatus.Pending && company.status !== FriendshipStatus.Accepted && (
                            t(`common:enums.friendshipStatus.${company.status}`)
                          )}
                          {!company.status && (
                            <span className="bg-yellow-300 py-0.5 px-1.5 rounded-md">
                              {t('common:noRelation')}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}

              </div>
            )}

            {isFetching && (
              <div className="mx-auto mb-5 mt-1">
                <Loader show={isFetching} size='small' />
              </div>
            )}

            {!companies || companies.data.length < 1 && (
              <div className="my-2">
                <p className="text-sm ml-7 md:ml-5 font-semibold text-black">{t('page:properties.noPropertiesFound')}...</p>
              </div>
            )}
            {companies && companies.meta && (
              <div className="flex justify-center py-2">
                <Pagination
                  count={pageCount}
                  page={page}
                  defaultPage={1}
                  showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                  showLastButton={pageCount ? pageCount > 5 : false}
                  hidePrevButton={!hasPrevPage}
                  hideNextButton={!hasNextPage}
                  onChange={handlePagination}
                />
              </div>
            )}
          </div>

          <ConfirmModal isOpen={showConfirmModal} close={() => setShowConfirmModal(false)} title={''} titleAlign='center' type='warning'>
            {selectedCompany && removalType === 'remove' && (
              <p className="text-lg font-semibold mt-3">
                {t('common:areYouSureYouWantTo')} {t(`common:${removalType}Lower`)} {t('page:friendship.relationWith')} {selectedCompany?.company_name}?
              </p>
            )}
            {selectedCompany && removalType === 'cancel' && (
              <p className="text-lg font-semibold mt-3">
                {t('common:areYouSureYouWantTo')} {t(`common:${removalType}Lower`)} {t('page:friendship.requestTo')} {selectedCompany?.company_name}?
              </p>
            )}
            {selectedCompany && removalType === 'deny' && (
              <p className="text-lg font-semibold mt-3">
                {t('common:areYouSureYouWantTo')} {t(`common:${removalType}Lower`)} {t('page:friendship.requestFrom')} {selectedCompany?.company_name}?
              </p>
            )}
            {!selectedCompany && (
              <p className="text-lg font-semibold mt-3">
                {t('common:confirmDelete')}?
              </p>
            )}
            <button
              onClick={() => {
                if (selectedCompany && removalType)
                  handleRemoveRelation(selectedCompany, removalType)
              }}
              className="bg-status-critical hover:bg-status-critical-h py-2 px-6 mt-5 rounded mx-auto text-sm sm:text-base text-white font-semibold"
            >
              {selectedCompany && removalType === 'remove' && (
                <span>{t('common:delete')}</span>
              )}
              {selectedCompany && removalType === 'cancel' && (
                <span>{t('page:friendship.cancelRequest')}</span>
              )}
              {selectedCompany && removalType === 'deny' && (
                <span>{t('page:friendship.denyRequest')}</span>
              )}
              {!selectedCompany && (
                <span>{t('common:delete')}</span>
              )}
            </button>
          </ConfirmModal>
        </div>
      )}
    </>
  )
}

export default RelatedCompaniesTable;