import React, { useEffect, useState } from 'react'
import { set, useForm } from 'react-hook-form';
import 'material-symbols';
import CreateTenantForm, { TenantFormType } from '../../../organisms/forms/tenant/create-tenant-form';
import CreateUserForm, { UserFormType } from '../../../organisms/forms/user/create-user-form';
import { useCreateTenantMutation, useRegisterUserMutation, useValidateTenantMutation, useValidateUserMutation } from '../../../../services/management/management.service';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { NotificationManager } from 'react-notifications';
import { TenantDto } from '../../../../services/tenant/tenant/dto/tenant.dto';
import { CreateTenantDto } from '../../../../services/tenant/tenant/dto/create-tenant.dto';
import { CreateUserDto } from '../../../../services/tenant/user/dto/create-user.dto';
import { getAuthenticatedUser, setAuthenticatedUser } from '../../../../redux/slices/auth.slice';
import { useNavigate } from 'react-router-dom';
import Organisationsnummer from 'organisationsnummer';
import { useCreateUserMutation, userAPI } from '../../../../services/tenant/user/user.service';
import { useTranslation } from 'react-i18next';
import { Roles } from '../../../../services/enum/roles';
import { useDispatch } from 'react-redux';
import KeenSlider from '../../../molecules/keen-slider';
import Loader from '../../../atoms/loader/loader';

// FIX: CHECK REGISTER FLOW

const RegisterPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [validateTenant] = useValidateTenantMutation(); // Validate tenant obj before creation
    const [validateUser] = useValidateUserMutation(); // Validate user obj before creation
    const [createTenant, { isLoading: createTenantLoading }] = useCreateTenantMutation(); // Create tenant
    const [createUser, { isLoading: createUserLoading }] = useCreateUserMutation(); // Create user
    const [registerUser, { isLoading: registerUserLoading }] = useRegisterUserMutation();

    const [tenant, setTenant] = useState<CreateTenantDto>(); // Hold tenant state after successful validation
    const [showInfo, setShowInfo] = useState<boolean>(false); // Show info text
    const [registerType, setRegisterType] = useState<string>('')
    const [tenantCreated, setTenantCreated] = useState<boolean>(false);
    const [userCreated, setUserCreated] = useState<boolean>(false);
    const [error, setError] = useState<string>(''); // Display errors returned from server when creating tenant/user
    const [showTenantForm, setShowTenantForm] = useState<boolean>(false); // Toggle to show tenant form

    const [showUserInfo, setShowUserInfo] = useState<boolean>(false);
    const [signupSuccess, setSignupSuccess] = useState<boolean>(false);

    // Validates tenant to be created
    const onSubmitTenantForm = async (tenantFormData: TenantFormType) => {
        tenantFormData.active = true;

        // Get domain from tenant email/domain
        const splitDomain = tenantFormData.domain.split('@');
        // This should get the domain?
        const domain = splitDomain[splitDomain.length - 1];
        tenantFormData.domain = domain;

        try {
            const response = await validateTenant(tenantFormData).unwrap();

            if (response.success) {
                setTenant(tenantFormData); // Set tenant in state for later use
                setTenantCreated(true); // Show user form
                setError(''); // Reset error message
            }
            else {
                // Show error message, do not proceed
                NotificationManager.error(t(`common:server.${response.message}`));
                setError(t(`common:server.${response.message}`))
            }
        }
        catch (error) {
            NotificationManager.error(t('common:server.tenant_not_valid'));
            setError(t('common:server.tenant_not_valid'));
        }
    }

    // Validates user to be created, calls createTenantAndUser on success
    const onSubmitUserForm = async (userFormData: UserFormType) => {
        userFormData.active = true;
        userFormData.role = Roles.Superadmin; // Set user role to admin
        userFormData.first_user = true;

        // Split email domain in case domain has been given
        const splitEmailDomain = userFormData.email.split('@');
        // Get email, sort out domain if it's been given
        const email = splitEmailDomain[0];
        // Construct email with tenant domain to create email
        const userEmail = email + '@' + tenant?.domain;
        // Set email in form data
        userFormData.email = userEmail;

        try {
            const response = await validateUser(userFormData).unwrap();

            if (response.success) {
                setError(''); // Reset error state
                // dispatch(userAPI.util.invalidateTags(['Users']));

                // Tenant and user is validated, create tenant and user
                await createTenantAndUser(userFormData);
            }
            else {
                NotificationManager.error(t(`common:server.${response.message}`));
                setError(t(`common:server.${response.message}`));
            }
        }
        catch (error) {
            NotificationManager.error(t('common:server.user_not_valid'));
            setError(t('common:server.user_not_valid'));
        }
    }

    // Creates new tenant and new user
    const createTenantAndUser = async (user: CreateUserDto) => {
        // 1. Create tenant
        // 2. Create user if tenant was successfull created
        // 3. Show email confirmation box, email sent from server

        if (tenant && user) {
            try {
                // Create tenant
                const tenantResponse = await createTenant(tenant).unwrap();

                if (tenantResponse.success) {

                    // Create user
                    try {
                        user.role = Roles.Superadmin;
                        user.tenant_id = tenantResponse.tenant_id; // Pass tenant_id to user being created
                        const userResponse = await createUser(user).unwrap();
                        if (userResponse.success) {
                            setError(''); // Reset error state
                            setUserCreated(true); // Set userCreated true to show email confirmation box
                            NotificationManager.success(t('page:users.form.tenantAndUserCreated'));

                            const authStateObj = {
                                authUser: {
                                    name: userResponse.user.name,
                                    email: userResponse.user.email,
                                    role: userResponse.user.role,
                                    user_id: userResponse.user.user_id,
                                },
                                access_token: userResponse.access_token
                            };

                            // Sign in user after registration
                            localStorage.setItem('user', JSON.stringify(authStateObj.authUser.name));
                            dispatch(setAuthenticatedUser(authStateObj));
                            navigate('/home');
                        }
                    }
                    // Catch user error
                    catch (error: any) {
                        setError(t(`common:server.${error.data.message}`));
                    }
                }
                else {
                    NotificationManager.error(t('common:server.tenant_not_created'));
                }
            }
            // Catch tenant error
            catch (error) {
                NotificationManager.error(t('common:somethingWentWrong'));
                setError(t('common:somethingWentWrong'));
            }
        }
    }

    // Register used to existing company
    const onSubmitRegisterUser = async (formData: UserFormType) => {
        formData.active = true;
        formData.role = Roles.None; // Set user role to none as default
        formData.first_user = false;

        try {
            const response = await registerUser(formData).unwrap();

            if (response.success) {
                setError(''); // Reset error state
                setSignupSuccess(true);
                NotificationManager.success(t('page:users.form.userCreatedConfirmEmail'));
            }
            else {
                setError(t(`common:server.${response.message}`));
            }
        }
        catch (error) {
            setError(t('common:somethingWentWrong'))
        }
    }

    return (
        <div className="mx-auto w-full md:w-8/12 md:max-w-2xl">
            {/* Show register options if none have been chosen */}
            {registerType === '' && (
                <div className="flex flex-col mx-auto my-5 rounded-lg mt-[15vh]">
                    <h1 className="text-2xl font-extrabold text-center text-black mb-10">{t('common:register')}</h1>

                    <div className="w-full mx-auto">
                        <div onClick={() => setRegisterType('new')} className="bg-primary-blue hover:bg-primary-blue-h cursor-pointer text-secondary-blue p-4 rounded-lg">
                            <span className="font-bold text-sm">{t('page:register.newCompany')}</span>
                            <span className="material-symbols-outlined align-sub float-right">trending_flat</span>
                        </div>

                        <div onClick={() => setRegisterType('existing')} className="bg-secondary-blue hover:bg-secondary-blue-h cursor-pointer text-primary-blue p-4 rounded-lg mt-3">
                            <span className="font-bold text-center text-sm">{t('page:register.newUser')}</span>
                            <span className="material-symbols-outlined align-sub float-right">trending_flat</span>
                        </div>

                        <div className="mt-4">
                            <div
                                className="flex justify-center items-center text-primary-blue hover:text-primary-blue-h cursor-pointer"
                                onClick={() => setShowInfo(!showInfo)}
                            >
                                <span className="text-center text-sm font-semibold">{t('page:register.whatToChoose')}</span>
                                <span className="material-symbols-outlined">{showInfo ? 'expand_more' : 'expand_less'}</span>
                            </div>
                            {showInfo && (
                                <div className="text-sm mt-1 bg-primary-blue-5 p-3 rounded-lg">
                                    <p className="font-semibold">1. {t('page:register.newCompany')}</p>
                                    <p>{t('page:register.newCompanyInfo')}</p>

                                    <p className="font-semibold mt-2">2. {t('page:register.newUser')}</p>
                                    <p className="">{t('page:register.newUserInfo')}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {/* If user selected 'Register company' */}
            {registerType === 'new' && (
                <div className="flex flex-col mx-auto w-full mt-10">
                    <div className="pl-2">
                        <button
                            className="material-symbols-outlined p-0.5 bg-primary-blue-5 rounded-md"
                            style={{ fontSize: '1.6rem' }}
                            onClick={() => setRegisterType('')}
                        >chevron_left</button>
                    </div>
                    <div className="w-full mt-5 rounded-md mx-auto p-3">
                        <span className={`font-bold ${tenantCreated ? 'text-gray-400 cursor-pointer' : 'text-black'}`} onClick={() => setShowTenantForm(!showTenantForm)}>1. {t('common:createCompany')}</span>
                        {tenantCreated && (<span className="material-symbols-outlined text-green-500 ml-1 align-bottom">check_circle</span>)}
                        <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>

                        {(!tenantCreated || showTenantForm) && (
                            <div className="flex w-full mx-auto flex-1 flex-col justify-center px-5 py-5">
                                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                                    <h2 className="text-center text-2xl font-extrabold leading-9 tracking-tight text-black mb-3">{t('common:registerCompany')}</h2>
                                </div>
                                <CreateTenantForm onSubmitTenantForm={onSubmitTenantForm} error={error} />
                            </div>
                        )}
                    </div>

                    <div className="w-full mt-5 rounded-md mx-auto p-3 mb-4">
                        <span className={`font-bold ${(tenantCreated && !userCreated) ? 'text-black' : 'text-gray-400'}`}>2. {t('common:createUser')}</span>
                        {userCreated && (<span className="material-symbols-outlined text-green-500 ml-1 align-bottom">check_circle</span>)}
                        <hr className="h-px my-2 bg-gray-200 border-0"></hr>

                        {(tenantCreated && !userCreated) && (
                            <>
                                <div className={`w-full mx-auto text-center ${(createTenantLoading || createUserLoading) ? 'mt-10' : 'mt-0'}`}>
                                    {(createTenantLoading || createUserLoading) && (
                                        <Loader show={(createTenantLoading || createUserLoading)} />
                                    )}
                                </div>
                                <div className={`flex w-full mx-auto flex-1 flex-col justify-center px-6 py-12 ${(createTenantLoading || createUserLoading) ? 'hidden' : 'block'}`}>
                                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                                        <h2 className="text-center text-2xl font-extrabold leading-9 tracking-tight text-black">{t('common:newUser')}</h2>
                                    </div>
                                    <CreateUserForm onSubmitUserForm={onSubmitUserForm} error={error} tenantDomain={tenant?.domain} />
                                </div>
                            </>
                        )}
                    </div>

                    {/* <div className="border mt-5 rounded-md w-3/4 mx-auto p-3">
                        <span className={`font-bold ${tenantCreated && userCreated ? 'text-black' : 'text-gray-400'}`}>3. Confirm Email</span>
                        <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>

                        {(tenantCreated && userCreated) && (
                            <div className="flex w-11/12 mx-auto flex-1 flex-col justify-center px-6 py-8">
                                <div className="sm:mx-auto sm:w-full sm:max-w-sm text-center">
                                    <span className="material-symbols-outlined text-teal-700 mb-5" style={{ fontSize: '3rem' }}>celebration</span>
                                    <h2 className="text-center text-2xl font-extrabold leading-9 tracking-tight text-black mb-3">Confirm Email</h2>
                                </div>
                                <div className="">
                                    <span className="font-bold">Tenant and user has been successfully created!</span>
                                    <p className="font-bold mt-5">An email has been sent to {tenant?.contact_email ? tenant.contact_email : 'the email registred for the tenant'}, click the link in the email to confirm your email and proceed to login.</p>

                                </div>
                            </div>
                        )}

                    </div> */}
                </div>
            )}

            {/* If user selected 'Register user' */}
            {
                registerType === 'existing' && (
                    <div className="mt-5 rounded-md w-full mx-auto p-3">
                        {signupSuccess ? (
                            <div className="w-full flex flex-col items-center mt-16 bg-primary-blue-5 rounded-md min-h-[25vh] p-5 text-center shadow-md text-black">
                                <span className="material-symbols-outlined mb-5" style={{ fontSize: '2.5rem' }}>celebration</span>
                                <h5 className="text-lg mb-3 font-bold text-primary-blue">{t('page:users.accountCreated')}</h5>
                                <p>{t('page:users.toSignInConfirm')}</p>
                                <p className="text-xs font-bold mt-10">{t('page:users.notReceivedEmail')}</p>
                                <p className="text-xs mt-1">{t('page:users.checkSpamOrContact')}</p>
                            </div>
                        ) : (
                            <div className="flex mx-auto flex-1 flex-col justify-center px-5 py-5">
                                <div className="">
                                    <button
                                        className="material-symbols-outlined p-0.5 bg-primary-blue-5 rounded-md"
                                        style={{ fontSize: '1.6rem' }}
                                        onClick={() => setRegisterType('')}
                                    >chevron_left</button>
                                </div>
                                <div className="sm:mx-auto sm:w-full sm:max-w-sm pt-3">
                                    <h2 className="text-center text-2xl font-extrabold leading-9 tracking-tight text-black mb-3">{t('common:registerUser')}</h2>
                                </div>
                                <div className="my-2">
                                    <div className="flex items-center text-primary-blue font-bold cursor-pointer" onClick={() => setShowUserInfo(!showUserInfo)}>
                                        <span className="">{t('page:users.whatCompanyInfo')}</span>
                                        <span className="material-symbols-outlined" style={{ fontSize: '1.8rem' }}>{showUserInfo ? 'expand_more' : 'expand_less'}</span>
                                    </div>
                                    {showUserInfo && (
                                        <div className=" rounded-md text-sm text-black font-medium">
                                            <p className="mb-1.5">
                                                {t('page:users.userRegistrationInfo1')}
                                            </p>
                                            <p className="my-1.5">
                                                {t('page:users.userRegistrationInfo2')}
                                            </p>
                                            <p className="my-1.5">
                                                {t('page:users.userRegistrationInfo3')}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <CreateUserForm onSubmitUserForm={onSubmitRegisterUser} error={error} />
                            </div>
                        )}
                    </div>
                )
            }

        </div>
    )
}

export default RegisterPage;