import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { LogTemplateDto } from '../../../../../services/tenant/log-template/dto/log-template.dto';
import { useGetLogTemplateQuery } from '../../../../../services/tenant/log-template/log-template.service';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FriendshipTenantDto } from '../../../../../services/catalog/friendship/dto/friendship-tenant.dto';
import Modal from '../../../../molecules/modal';
import { Grow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../redux/hooks';
import { getCurrentUser } from '../../../../../redux/slices/user.slice';
import { getTenant } from '../../../../../redux/slices/tenant.slice';
import { Roles } from '../../../../../services/enum/roles';
import { TenantTypes } from '../../../../../services/enum/tenant-types';
import SubTabs from '../../../../molecules/sub-tabs';
import PropertyLogTemplatesTable from '../entity-log-table';
import LogEntries from '../log-entries';
import ExternalLogAccessPropertyOwner from '../../../friendship/property-owner/external-log-access-property-owner';
import { EntityLogDto } from '../../../../../services/tenant/entity-log/dto/entity-log.dto';

type Props = {
    selectedTab: string;
    setSelectedTab: (val: string) => void;
    showTabs: boolean;
    setShowTabs: (flag: boolean) => void;
}

const LogsPropertyOwner = ({
    selectedTab,
    setSelectedTab,
    showTabs,
    setShowTabs,
}: Props) => {
    const { t } = useTranslation()
    const { currentUser } = useAppSelector(getCurrentUser);
    const { tenant } = useAppSelector(getTenant);
    const location = useLocation();

    const [selectedSubTab, setSelectedSubTab] = useState<string>('internalLogs');

    const [selectedLogTemplate, setSelectedLogTemplate] = useState<LogTemplateDto>();

    // Company passed as prop
    const [selectedCompany, setSelectedCompany] = useState<FriendshipTenantDto>();

    // // If external company is passed as prop
    // useEffect(() => {
    //     if (location.state) {
    //         const { company } = location.state;
    //         if (company) {
    //             setSelectedCompany(company);
    //             setSelectedTab('logs');
    //             setSelectedSubTab('externalLogs')
    //         }
    //     }
    // }, [])

    // Select Property Log Template
    const [selectedEntityLog, setSelectedEntityLog] = useState<EntityLogDto | null>();
    // State for selected log template id to be fetched
    const [selectedLogTemplateId, setSelectedLogTemplateId] = useState<string>();

    const [skipTokenLogTemplateId, setSkipTokenLogTemplateId] = useState<any>(skipToken);
    const { data: logTemplate } = useGetLogTemplateQuery(skipTokenLogTemplateId);

    useEffect(() => {
        if (selectedLogTemplateId) {
            setSkipTokenLogTemplateId(selectedLogTemplateId);
        }
    }, [selectedLogTemplateId])

    useEffect(() => {
        if (logTemplate) {
            setSelectedLogTemplate(logTemplate);
        }
    }, [logTemplate])

    useEffect(() => {
        if (selectedEntityLog) setShowTabs(false)
        else setShowTabs(true);

    }, [selectedEntityLog])

    return (
        <div className="w-full p-1 mx-auto">
            <Grow in={selectedTab ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                <div>
                    {/* PROPERY OWNER  */}
                    {tenant.type === TenantTypes.Property_Owner && selectedTab === 'logs' && (
                        <>
                            {(currentUser.role === Roles.Superadmin || currentUser.role === Roles.Admin) && (
                                <>
                                    {!selectedEntityLog && (
                                        <SubTabs
                                            selectableTabs={['internalLogs', 'externalLogs']}
                                            setSelectedSubTab={setSelectedSubTab}
                                            selectedSubTab={selectedSubTab}
                                        />
                                    )}

                                    {selectedSubTab === 'internalLogs' && (
                                        <>
                                            {!selectedEntityLog && (
                                                <PropertyLogTemplatesTable
                                                    setSelectedEntityLog={setSelectedEntityLog}
                                                    setSelectedLogTemplateId={setSelectedLogTemplateId}
                                                />
                                            )}

                                            {(selectedEntityLog && selectedLogTemplate) && (
                                                <LogEntries
                                                    setSelectedEntityLog={setSelectedEntityLog}
                                                    selectedEntityLog={selectedEntityLog}
                                                    selectedLogTemplate={selectedLogTemplate}
                                                />
                                            )}
                                        </>
                                    )}
                                    {selectedSubTab === 'externalLogs' && (
                                        <ExternalLogAccessPropertyOwner preSelectedCompany={selectedCompany} />
                                    )}
                                </>

                            )}
                            {currentUser.role === Roles.User && (
                                <>
                                    {selectedSubTab === 'internalLogs' && (
                                        <>
                                            {!selectedEntityLog && (
                                                <PropertyLogTemplatesTable
                                                    setSelectedEntityLog={setSelectedEntityLog}
                                                    setSelectedLogTemplateId={setSelectedLogTemplateId}
                                                />
                                            )}

                                            {(selectedEntityLog && selectedLogTemplate) && (
                                                <LogEntries
                                                    setSelectedEntityLog={setSelectedEntityLog}
                                                    selectedEntityLog={selectedEntityLog}
                                                    selectedLogTemplate={selectedLogTemplate}
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </Grow>
        </div>
    )
}

export default LogsPropertyOwner