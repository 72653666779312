import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Order } from '../../../../../services/enum/order';
import Select, { MultiValue, SingleValue } from 'react-select';
import { SelectCustomStyles } from '../../../../atoms/select-custom-styles/select-custom-styles';
import { useGetMyFriendshipTenantsQuery } from '../../../../../services/catalog/friendship/friendship.service';
import { FriendshipTenantDto } from '../../../../../services/catalog/friendship/dto/friendship-tenant.dto';
import { debounce } from '../../../../../utils/debounce';

type SelectOption = {
    label: string;
    value: string;
    data: FriendshipTenantDto | null;
    selected?: boolean;
}

type Props = {
    handleSelectedCompany: (value: SelectOption) => void;
    preSelectedCompany?: FriendshipTenantDto;
    companyIds?: { id: string }[], // Filter out companies that the user doesn't have access to
    zIndex?: string;
    reset?: boolean;
}

const ExternalCompaniesContractorSelect = ({
    handleSelectedCompany,
    preSelectedCompany,
    companyIds,
    zIndex,
    reset
}: Props) => {
    const { t } = useTranslation();

    const [selectedCompany, setSelectedCompany] = useState<FriendshipTenantDto>();

    const [allOptions, setAllOptions] = useState<SelectOption[]>([]);
    const [search, setSearch] = useState<string>();
    const [page, setPage] = useState<number>(1);

    const debouncedSearch = useRef(debounce((search: string) => setSearch(search), 500)).current;

    // Get external companies
    const { data: companies } = useGetMyFriendshipTenantsQuery({
        take: 15,
        page: page,
        order: Order.ASC,
        sortBy: 'company_name',
        search: search
    });

    useEffect(() => {
        if (companies) {
            // INIT
            if (allOptions.length === 0 || selectedCompany) {
                initOptions(companies.data);
            }
            // SEARCH
            else if (search) {
                handleSearchOptions(search, companies.data);
            }
            // PAGINATION
            else {
                handlePagination(companies.data);
            }
        }
    }, [companies, selectedCompany])

    useEffect(() => {
        if (preSelectedCompany) {
            setSelectedCompany(preSelectedCompany);
        }
    }, [preSelectedCompany])

    useEffect(() => {
        if (selectedCompany && allOptions) {
            // If passed prop preSelectedCompany is selected, update selected company
            const preSelectedCompanySelected = allOptions.find(e => e.value === selectedCompany.tenant_id);
            if (preSelectedCompanySelected) {
                handleSelectedCompany(preSelectedCompanySelected)
            }
        }
    }, [selectedCompany, allOptions])

    // Set initial options and selected options
    const initOptions = (companies: FriendshipTenantDto[]) => {
        const allOptions: SelectOption[] = [];
        if (companies && allOptions.length === 0) {
            companies.forEach(company => {
                let tempObj: SelectOption;
                // Pre selected company passed as prop
                if (!companyIds && company.tenant_id === selectedCompany?.tenant_id) {
                    tempObj = {
                        label: company.company_name,
                        value: company.tenant_id,
                        data: company,
                        selected: true,
                    };
                    allOptions.push(tempObj);
                }
                else if (!companyIds) {
                    tempObj = {
                        label: company.company_name,
                        value: company.tenant_id,
                        data: company,
                    };
                    allOptions.push(tempObj);
                }
                // companyIds passed, filter to only display companies within the companyIds array
                else if (companyIds && companyIds?.some(e => e.id === company.receiver_tenant_id || e.id === company.sender_tenant_id)) {
                    tempObj = {
                        label: company.company_name,
                        value: company.tenant_id,
                        data: company,
                    };
                    allOptions.push(tempObj);
                }
            })
            setAllOptions(allOptions);
        }
    }

    // Handle options when search query is provided
    const handleSearchOptions = (search: string, companies: FriendshipTenantDto[]) => {
        if (companies && search) {
            setPage(1);
            const options: SelectOption[] = [];
            companies.forEach(company => {
                let tempObj: SelectOption;
                // Pre selected company passed as prop
                if (!companyIds && company.tenant_id === selectedCompany?.tenant_id) {
                    tempObj = {
                        label: company.company_name,
                        value: company.tenant_id,
                        data: company,
                        selected: true,
                    };
                    options.push(tempObj);
                }
                else if (!companyIds) {
                    tempObj = {
                        label: company.company_name,
                        value: company.tenant_id,
                        data: company,
                    };
                    options.push(tempObj);
                }
                // companyIds passed, filter to only display companies within the companyIds array
                else if (companyIds && companyIds?.some(e => e.id === company.receiver_tenant_id || e.id === company.sender_tenant_id)) {
                    tempObj = {
                        label: company.company_name,
                        value: company.tenant_id,
                        data: company,
                    };
                    options.push(tempObj);
                }
            })
            setAllOptions([...options]);
        }
    }

    // Handling options with pagination
    const handlePagination = (companies: FriendshipTenantDto[]) => {
        if (companies) {
            // Get existing options
            const existingOptions = [...allOptions];

            // Remove duplicates
            const uniqueArr = existingOptions.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.value === value.value
                ))
            )

            // Add options
            companies.forEach(company => {
                let tempObj: SelectOption;
                // Pre selected company passed as prop
                if (!companyIds && company.tenant_id === selectedCompany?.tenant_id) {
                    tempObj = {
                        label: company.company_name,
                        value: company.tenant_id,
                        data: company,
                        selected: true,
                    };
                    // Make sure no duplicates are added
                    if (!uniqueArr.some(e => e.value === tempObj.value)) {
                        uniqueArr.push(tempObj);
                    }
                }
                else if (!companyIds) {
                    tempObj = {
                        label: company.company_name,
                        value: company.tenant_id,
                        data: company,
                    };
                    // Make sure no duplicates are added
                    if (!uniqueArr.some(e => e.value === tempObj.value)) {
                        uniqueArr.push(tempObj);
                    }
                }
                // companyIds passed, filter to only display companies within the companyIds array
                else if (companyIds && companyIds?.some(e => e.id === company.receiver_tenant_id || e.id === company.sender_tenant_id)) {
                    tempObj = {
                        label: company.company_name,
                        value: company.tenant_id,
                        data: company,
                    };
                    // Make sure no duplicates are added
                    if (!uniqueArr.some(e => e.value === tempObj.value)) {
                        uniqueArr.push(tempObj);
                    }
                }

                // if (!companyIds || companyIds?.some(e => e.id === company.receiver_tenant_id || e.id === company.sender_tenant_id)) {
                //     const obj = {
                //         label: company.company_name,
                //         value: company.tenant_id,
                //         data: company,
                //     }
                //     // Make sure no duplicates are added
                //     if (!uniqueArr.some(e => e.value === obj.value)) {
                //         uniqueArr.push(obj);
                //     }
                // }
            });
            setAllOptions(uniqueArr);
        }
    }

    // Handle scroll event to fetch more options
    const handleScroll = (event: any) => {
        if (companies && companies.meta.hasNextPage) {
            if (!search) {
                setPage(page ? page + 1 : 1);
            }
        }
    }

    const handleChange = (selectOption: SelectOption) => {
        handleSelectedCompany(selectOption);

        selectOption.selected = true;
        
        // Set selected = false if option is not equal to passed selectOption
        const allOptionsArr = [...allOptions];
        allOptionsArr.forEach(opt => {
            if (opt.value !== selectOption.value) {
                opt.selected = false;
            }
        })

        // preSelectedCompany has been unselected
        if (selectOption.value !== selectedCompany?.tenant_id) {
            const index = allOptionsArr.findIndex(e => e.value === selectedCompany?.tenant_id);
            if (index !== -1) {
                allOptionsArr[index].selected = false;
                setSelectedCompany(undefined);
            }
        }
        setAllOptions(allOptionsArr);


    }

    return (
        <Select
            className={`${zIndex ? `z-${zIndex}` : ''} capitalize md:text-sm`}
            options={allOptions}
            hideSelectedOptions={false}
            onChange={(val) => handleChange(val ? val : { label: '', value: '', data: null })}
            maxMenuHeight={200}
            placeholder={t('common:select')}
            noOptionsMessage={() => t('page:friendship.noCompaniesFound')}
            onInputChange={(value) => debouncedSearch(value)}
            captureMenuScroll={true}
            onMenuScrollToBottom={(val) => handleScroll(val)}
            filterOption={null}
            styles={SelectCustomStyles}
            backspaceRemovesValue={false}
            value={allOptions.filter(e => e.selected)}
        />
    )
};

export default ExternalCompaniesContractorSelect;