import React, { useState } from 'react'
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Roles } from '../../../../services/enum/roles';
import { CreateUserInviteDto } from '../../../../services/tenant/user/dto/create-user-invite.dto';
import { NotificationManager } from 'react-notifications';
import { useAppDispatch } from '../../../../redux/hooks';
import { setAuthenticatedUser } from '../../../../redux/slices/auth.slice';
import { useNavigate } from 'react-router-dom';
import { useCreateUserInvitedMutation } from '../../../../services/tenant/user/user.service';
import { AuthState } from '../../../../services/dto/auth-state.dto';
import Loader from '../../../atoms/loader/loader';

export type UserInviteFormType = {
    name: string;
    email: string;
    password: string;
    confirm_password: string;
    phone: string;
    active: true;
    role: Roles.User;
}

type Props = {
    token?: string | null;
}

const CreateUserInviteForm = ({ token }: Props) => {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    const [createInvitedUser, { isLoading }] = useCreateUserInvitedMutation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [error, setError] = useState<string>('');

    const { control, getValues, handleSubmit, formState: { errors } } = useForm<UserInviteFormType>({
        defaultValues: {
            name: '',
            email: '',
            password: '',
            confirm_password: '',
            phone: '',
            active: true,
            role: Roles.User,
        }
    });

    const onSubmit = async (formData: UserInviteFormType) => {
        const userData: CreateUserInviteDto = {
            name: formData.name,
            phone: formData.phone,
            password: formData.password,
            active: formData.active,
            role: formData.role,
            email: formData.email,
        }

        if (token) {
            try {
                const response = await createInvitedUser({ userData, token }).unwrap();

                if (response.success) {
                    setError(''); // Reset error state

                    NotificationManager.success(t('common:notifications.registrationSuccess'));

                    const authStateObj: AuthState = {
                        authUser: {
                            name: response.user.name,
                            email: response.user.email,
                            role: response.user.role,
                            user_id: response.user.user_id,
                        },
                        access_token: response.access_token
                    };

                    // Sign in user after registration
                    localStorage.setItem('user', JSON.stringify(authStateObj.authUser.name));
                    dispatch(setAuthenticatedUser(authStateObj));
                    navigate('/home');
                }
                else {
                    NotificationManager.error(t(`common:server.${response.message}`));
                    setError(t(`common:server.${response.message}`));
                }

            }
            catch (error) {
                setError(t('common:server.something_went_wrong'));
            }
        }
    }

    const validateConfirmPassword = () => {
        const password = getValues().password;
        const confirmPassword = getValues().confirm_password;

        if (!confirmPassword)
            return true;

        if (confirmPassword.length > 7 && confirmPassword !== password)
            return t('page:users.form.passwordNotSame');
    }

    return (
        <>
            <div className="sm:mx-auto sm:w-full">
                <form className="space-y-3" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label htmlFor='name' className="block text-sm font-semibold leading-6 text-gray-600">
                            {t('common:name')}<span className="text-status-critical">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: t('common:form.nameNotEmpty'),
                                    minLength: {
                                        value: 3,
                                        message: t('common:form.nameMinLength3')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={64}
                                            placeholder={t('common:name')}
                                            className={`${errors.name && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                        />
                                        {errors.name && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.name.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='password' className="block text-sm font-semibold leading-6 text-gray-600">
                            {t('common:password')}<span className="text-status-critical">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="password"
                                control={control}
                                rules={{
                                    required: t('page:users.form.passwordNotEmpty'),
                                    minLength: {
                                        value: 8,
                                        message: t('page:users.form.passwordMinLength8')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type={showPassword ? 'text' : 'password'}
                                            maxLength={32}
                                            placeholder={t('common:password')}
                                            className={`${errors.password && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                        />
                                        <span
                                            onClick={() => setShowPassword(!showPassword)}
                                            className="material-symbols-outlined text-black cursor-pointer hover:text-gray-600 float-right mr-2 mt-[-30px]">
                                            {showPassword ? 'visibility' : 'visibility_off'}
                                        </span>

                                        {errors.password && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.password.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='confirm_password' className="block text-sm font-semibold leading-6 text-gray-600">
                            {t('page:users.form.confirmPassword')}<span className="text-status-critical">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="confirm_password"
                                control={control}
                                rules={{
                                    required: t('page:users.form.passwordNotEmpty'),
                                    minLength: {
                                        value: 8,
                                        message: t('page:users.form.passwordMinLength8')
                                    },
                                    validate: validateConfirmPassword
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            maxLength={32}
                                            placeholder={t('common:password')}
                                            className={`${errors.confirm_password && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                        />
                                        <span
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            className="material-symbols-outlined text-black cursor-pointer hover:text-gray-600 float-right mr-2 mt-[-30px]">
                                            {showConfirmPassword ? 'visibility' : 'visibility_off'}
                                        </span>

                                        {errors.confirm_password && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.confirm_password.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='phone' className="block text-sm font-semibold leading-6 text-gray-600">
                            {t('common:phone')}<span className="text-status-critical">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="phone"
                                control={control}
                                rules={{
                                    required: t('page:users.form.phoneNotEmpty'),
                                    minLength: {
                                        value: 10,
                                        message: t('page:users.form.phoneMinLength10')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={16}
                                            placeholder={t('common:phone')}
                                            className={`${errors.phone && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                        />
                                        {errors.phone && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.phone.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    {error && (<p className="font-bold text-sm text-center text-status-critical">{error}</p>)}
                    {isLoading ? (
                        <Loader show={isLoading} size='small' />
                    ) : (
                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-primary-blue hover:bg-primary-blue-h px-3 py-1.5 text-sm font-bold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                {t('common:register')}
                            </button>
                        </div>
                    )}
                </form>
            </div>

        </>
    )
}

export default CreateUserInviteForm;