import { useEffect, useRef, useState } from "react";
import { Input } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LogCategories } from "../../../../../services/enum/log-categories";
import { Order } from "../../../../../services/enum/order";
import { debounce } from "../../../../../utils/debounce";
import Loader from "../../../../atoms/loader/loader";
import AllLogCategories from "../all-log-categories";
import { LogTypes } from "../../../../../services/enum/log-types";
import { useAppSelector } from "../../../../../redux/hooks";
import { getCurrentUser } from "../../../../../redux/slices/user.slice";
import { Roles } from "../../../../../services/enum/roles";
import LogFilterByEntities from "../log-filter-by-entities";
import './style.css';
import Pagination from "../../../../molecules/pagination";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetAllEntityLogsQuery, useGetAllPropertyBuildingLogAccessQuery, useGetAllPropertyLogAccessQuery, useGetAllPropertyObjectLogAccessQuery, useGetEntityLogsUniqueCategoriesQuery } from "../../../../../services/tenant/entity-log/entity-log.service";
import { EntityLogDto } from "../../../../../services/tenant/entity-log/dto/entity-log.dto";
import Modal from "../../../../molecules/modal";
import UpdateEntityLogForm from "../../../forms/entity-log/update-entity-log-form";

type FilterType = {
    properties?: string;
    buildings?: string;
    objects?: string;
    category?: LogCategories;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

type Props = {
    setSelectedEntityLog: (entityLog: EntityLogDto | null) => void;
    selectedEntityLog?: EntityLogDto | null
    setSelectedLogTemplateId: (logStructuretemplateId: string) => void;
}

const EntityLogTable = ({
    setSelectedEntityLog,
    selectedEntityLog,
    setSelectedLogTemplateId,
}: Props) => {
    const { t } = useTranslation();
    const { currentUser } = useAppSelector(getCurrentUser);

    const navigate = useNavigate();
    const query = useQuery();

    // Pagination
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>();
    const [hasNextPage, setHasNextPage] = useState<boolean>();
    const [hasPrevPage, setHasPrevPage] = useState<boolean>();

    // Table sort and search
    const [search, setSearch] = useState<string | undefined>('');
    const [searchOperation, setSearchOperation] = useState<string | undefined>();
    const [sortBy, setSortBy] = useState<string | undefined>('belongs_to'); // Column to sort by
    const [sortOrder, setSortOrder] = useState<Order>(Order.ASC); // Sort order

    // State for filters
    const [filterObject, setFilterObject] = useState<FilterType>();
    const [filterString, setFilterString] = useState<string>();

    // Filter based on property, building or object
    const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
    const [selectedBuildings, setSelectedBuildings] = useState<string[]>([]);
    const [selectedObjects, setSelectedObjects] = useState<string[]>([]);

    // State for selected category
    const [selectedCategory, setSelectedCategory] = useState<LogCategories>();

    // Show edit modal
    const [showEditModal, setShowEditModal] = useState<EntityLogDto | null>(null);

    const debouncedSearch = useRef(debounce((search: string) => setSearchOperation(search), 500)).current;

    const [displayName, setDisplayName] = useState<{ pboName: string, logName: string}>({ pboName: '', logName: ''})

    // Get all logs with a relation to a property, building or object
    const { data: entityLogs, isFetching } = useGetAllEntityLogsQuery({
        page: page,
        take: 25,
        search: searchOperation,
        sortBy: sortBy,
        order: sortOrder,
        filter: filterString
    })

    // Get all access records to display in filter lists
    const { data: propertyLogAccess } = useGetAllPropertyLogAccessQuery();
    const { data: propertyBuildingLogAccess } = useGetAllPropertyBuildingLogAccessQuery();
    const { data: propertyObjectLogAccess } = useGetAllPropertyObjectLogAccessQuery();

    // Get all unique log categories for entity logs
    const { data: logCategories } = useGetEntityLogsUniqueCategoriesQuery();

    useEffect(() => {
        if (entityLogs) {
            setPage(entityLogs.meta.page);
            setPageCount(entityLogs.meta.pageCount);
            setHasNextPage(entityLogs.meta.hasNextPage);
            setHasPrevPage(entityLogs.meta.hasPreviousPage);
        }
    }, [entityLogs])

    // Construct filterQuery
    useEffect(() => {
        let tempObj: FilterType = {};

        if (selectedProperties && selectedProperties.length > 0) {
            tempObj.properties = selectedProperties.map(e => e).join(',');
        }
        if (selectedBuildings && selectedBuildings.length > 0) {
            tempObj.buildings = selectedBuildings.map(e => e).join(',');
        }
        if (selectedObjects && selectedObjects.length > 0) {
            tempObj.objects = selectedObjects.map(e => e).join(',');
        }
        if (selectedCategory !== undefined && selectedCategory !== LogCategories.None) {
            tempObj.category = selectedCategory;
        }

        // Check if there's a change in the filter
        if (JSON.stringify(filterObject) !== JSON.stringify(tempObj)) {
            setFilterObject(tempObj);
            const encoded = encodeURIComponent(JSON.stringify(tempObj));
            setFilterString(encoded);
        }
    }, [selectedProperties, selectedBuildings, selectedObjects, selectedCategory, navigate])

    useEffect(() => {
        if (showEditModal) {
            let logName = '';
            let pboName = '';

            // Get correct property / building / object name
            if (showEditModal.property_object_name) {
                pboName = showEditModal.property_object_name
            }
            else if (showEditModal.property_building_label) {
                pboName = showEditModal.property_building_label;
            }
            if (showEditModal.property_label) {
                pboName = showEditModal.property_label;
            }

            // Get correct log name
            logName = showEditModal.log_type === LogTypes.Default ?
                t(`page:logs.defaultLogs.${showEditModal.log_template_name.split('_').join('')}`) :
                showEditModal.log_template_name.split('_').join(' ')

            setDisplayName({
                logName,
                pboName
            })
        }
    }, [showEditModal])

    const handlePagination = (e: any, page: number) => {
        setPage(page);
    }

    const handleSort = (column: string, order: Order) => {
        setSortBy(column);
        setSortOrder(order === Order.ASC ? Order.DESC : Order.ASC);
    }

    const handleSelectedLogTemplate = (record: EntityLogDto) => {
        setSelectedEntityLog(record);

        const selectedLogTemplate = entityLogs?.data.find((e) => e.log_template_id === record.log_template_id);

        if (selectedLogTemplate)
            setSelectedLogTemplateId(selectedLogTemplate.log_template_id);
    }

    return (
        <>
            {(entityLogs && entityLogs?.data.length < 1 && !searchOperation && !filterString) ? (
                <div className="w-10/12 xl:w-8/12 max-w-2xl mx-auto bg-primary-blue-5 text-black rounded-md px-2 py-4 mt-10 text-center">
                    {currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin && (
                        <>
                            <p className="text-lg font-semibold">{t('page:logs.noLogsFound')}</p>
                            <p className="mt-2">{t('page:logs.noLogsFoundAdmin')}</p>
                        </>
                    )}

                    {currentUser.role === Roles.User && (
                        <>
                            <p className="text-lg font-semibold">{t('page:logs.noLogsFound')}</p>
                            <p className="mt-2">{t('page:logs.noLogsFoundUser1')}</p>
                            <p className="">{t('page:logs.noLogsFoundUser2')}</p>
                        </>
                    )}
                </div>
            ) : (
                <div className="w-full mx-auto rounded-md overflow-x-hidden">
                    <div className="sm:w-6/12 max-w-2xl mt-2 text-center sm:text-left">
                        <Input
                            type="search"
                            name="search_name"
                            placeholder={t('common:search')}
                            disableUnderline={true}
                            className="w-full border rounded-md text-base md:text-sm px-2 pt-2.5 pb-1 mt-3 bg-white"
                            onChange={
                                (e) => {
                                    setSearch(e.target.value)
                                    debouncedSearch(e.target.value)
                                }
                            }
                            value={search}
                            endAdornment={
                                <span
                                    className="material-symbols-outlined pb-1 text-gray-500 cursor-pointer"
                                    style={{ fontSize: '1.3rem' }}
                                    onClick={() => {
                                        if (search) {
                                            setSearch('')
                                            debouncedSearch('')
                                        }
                                    }}
                                >
                                    {search ? 'close' : 'search'}
                                </span>
                            }
                            size="small"
                        />
                    </div>
                    <div className="w-full">
                        <AllLogCategories logCategories={logCategories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} alignCenter={true} />
                    </div>
                    <div className="w-full mt-1 mb-2">
                        {
                            (propertyLogAccess || propertyBuildingLogAccess || propertyObjectLogAccess) && (
                                <LogFilterByEntities
                                    allPropertyLabels={propertyLogAccess ? propertyLogAccess.filter(e => e.property_label !== undefined).map(e => e.property_label as string) : []}
                                    allBuildingLabels={propertyBuildingLogAccess ? propertyBuildingLogAccess.filter(e => e.property_building_label !== undefined).map(e => e.property_building_label as string) : []}
                                    allObjectNames={propertyObjectLogAccess ? propertyObjectLogAccess.filter(e => e.property_object_name !== undefined).map(e => e.property_object_name as string) : []}
                                    setSelectedProperties={setSelectedProperties}
                                    selectedProperties={selectedProperties}
                                    setSelectedBuildings={setSelectedBuildings}
                                    selectedBuildings={selectedBuildings}
                                    setSelectedObjects={setSelectedObjects}
                                    selectedObjects={selectedObjects}
                                />
                            )
                        }
                    </div>

                    <div className="relative overflow-x-scroll max-h-[50vh]">
                        <table className="w-full text-sm text-left text-black">
                            <thead className="text-xs lg:text-sm capitalize bg-white sticky top-0">
                                <tr className="">
                                    <th scope="col" className="px-1 sm:px-6 py-2 sm:py-3 text-left font-bold min-w-[4rem]">
                                        {t('common:category')}
                                    </th>
                                    <th
                                        scope="col"
                                        className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]"
                                        onClick={() => handleSort('log_template_name', sortOrder)}>
                                        {t('common:logName')}
                                        {(sortBy === 'log_template_name') ? (
                                            <>
                                                {sortOrder === Order.ASC ? (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                ) : (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                            </>
                                        )}
                                    </th>
                                    <th
                                        scope="col"
                                        className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]"
                                        onClick={() => handleSort('belongs_to', sortOrder)}>
                                        {t('common:belongsTo')}
                                        {(sortBy === 'belongs_to') ? (
                                            <>
                                                {sortOrder === Order.ASC ? (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                ) : (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                            </>
                                        )}
                                    </th>
                                    <th
                                        scope="col">
                                        <span className="sr-only">Edit Log</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="overflow-y-scroll">
                                {entityLogs?.data.map((entityLog, idx) => {
                                    return (
                                        <tr
                                            key={idx}
                                            className={`odd:hover:bg-primary-blue-10 even:hover:bg-gray-50 cursor-pointer text-xs text-black even:bg-white odd:bg-primary-blue-5`}>

                                            <td
                                                scope="row"
                                                onClick={() => handleSelectedLogTemplate(entityLog)}
                                                className="px-1 py-2 sm:px-6 capitalize text-left">
                                                {entityLog.log_category === LogCategories.Other && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[entityLog.log_category]}`)}>home_repair_service</span>)}
                                                {entityLog.log_category === LogCategories.Fire && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[entityLog.log_category]}`)}>local_fire_department</span>)}
                                                {entityLog.log_category === LogCategories.Security && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[entityLog.log_category]}`)}>encrypted</span>)}
                                                {entityLog.log_category === LogCategories.Camera && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[entityLog.log_category]}`)}>photo_camera</span>)}
                                                {entityLog.log_category === LogCategories.Access && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[entityLog.log_category]}`)}>vpn_key</span>)}
                                                {entityLog.log_category === LogCategories.Maintenance && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[entityLog.log_category]}`)}>construction</span>)}
                                                {entityLog.log_category === LogCategories.Cleaning && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[entityLog.log_category]}`)}>mop</span>)}
                                                {entityLog.log_category === LogCategories.Rounding && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[entityLog.log_category]}`)}>shield_person</span>)}
                                                {entityLog.log_category === LogCategories.Elevator && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[entityLog.log_category]}`)}>elevator</span>)}
                                                {entityLog.log_category === LogCategories.Ventilation && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[entityLog.log_category]}`)}>mode_fan</span>)}

                                            </td>
                                            <td
                                                className="py-2 pr-2 pl-1 sm:pl-2 text-xs first-letter:capitalize font-semibold sm:font-normal max-w-[9rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                onClick={() => handleSelectedLogTemplate(entityLog)}
                                                title={entityLog.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${entityLog.log_template_name.split('_').join('')}`) :
                                                    entityLog.log_template_name.split('_').join(' ')}
                                            >
                                                {
                                                    entityLog.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${entityLog.log_template_name.split('_').join('')}`) :
                                                        entityLog.log_template_name.split('_').join(' ')
                                                }
                                            </td>
                                            <td
                                                className="py-2 pr-2 pl-1 sm:pl-2 text-xs capitalize font-semibold sm:font-normal max-w-[9rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                onClick={() => handleSelectedLogTemplate(entityLog)}>
                                                {
                                                    entityLog.property_object_id ? <span className="" title={entityLog.property_object_name}>{entityLog.property_object_name}</span> :
                                                        entityLog.property_building_id ? <span className="" title={entityLog.property_building_label}>{entityLog.property_building_label}</span> :
                                                            <span title={entityLog.property_label}>{entityLog.property_label}</span>
                                                }
                                            </td>
                                            <td className="text-center md:px-2 lg:px-1">
                                                <button
                                                    onClick={() => setShowEditModal(entityLog)}
                                                    className="align-middle text-black material-symbols-outlined pb-0.5 disabled:text-gray-300"
                                                    style={{ fontSize: '1.2rem' }}
                                                    title={t('common:editTemplate')}
                                                >edit_square</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    {
                        isFetching && (
                            <div className="mx-auto mb-5 mt-1">
                                <Loader show={isFetching} size='small' />
                            </div>
                        )
                    }

                    {
                        !entityLogs || entityLogs.data.length < 1 && (
                            <div className="mt-5 mb-5">
                                <p className="text-sm ml-5 text-black font-semibold">{t('page:properties.noLogsFound')}...</p>
                            </div>
                        )
                    }
                    {
                        entityLogs?.meta && (
                            <div className="flex justify-center pt-4">
                                <Pagination
                                    count={pageCount}
                                    page={page}
                                    defaultPage={1}
                                    showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                                    showLastButton={pageCount ? pageCount > 5 : false}
                                    hidePrevButton={!hasPrevPage}
                                    hideNextButton={!hasNextPage}
                                    setPage={setPage}
                                />
                            </div>
                        )
                    }
                </div >
            )}

            <Modal isOpen={showEditModal ? true : false} close={() => setShowEditModal(null)} title={displayName?.logName + ' - ' + displayName?.pboName} size='md' titleAlign='center'>
                <UpdateEntityLogForm entityLog={showEditModal} />
            </Modal>
        </>
    )
}

export default EntityLogTable;