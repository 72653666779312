import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { UserTenantDto } from "../../services/tenant/user/dto/user-tenant.dto";
import { UserState } from "../../services/dto/user-state.dto";
import { Roles } from "../../services/enum/roles";



const initialState: UserState = {
    currentUser: {
        id: '',
        tenant_id: '',
        name: '',
        email: '',
        phone: '',
        role: Roles.None,
        active: false,
        address: '',
        city: '',
        zip: '',
        country: '',
        phone_verified: false,
        email_verified: false,
        subsidiary_id: '',
        profile_picture: '',
    }
}

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        // Set current user state
        setCurrentUser: (state: UserState, { payload }: PayloadAction<UserTenantDto>) => {
            // state.currentUser = payload.currentUser;
            state.currentUser = {
                id: payload.user.id,
                tenant_id: payload.user.tenant_id,
                name: payload.user.name,
                email: payload.user.email,
                phone: payload.user.phone,
                role: payload.user.role,
                active: payload.user.active,
                address: payload.user.address,
                city: payload.user.city,
                zip: payload.user.zip,
                country: payload.user.country,
                phone_verified: payload.user.phone_verified,
                email_verified: payload.user.email_verified,
                subsidiary_id: payload.user.subsidiary_id,
                profile_picture: payload.user.profile_picture,
            }
        },
    },
})

export const { setCurrentUser } = userSlice.actions;
export const userReducer = userSlice.reducer;
export const getCurrentUser = (state: RootState) => state.rootReducer.user;