import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../redux/hooks';
import { getTenant } from '../../../../redux/slices/tenant.slice';
import { getCurrentUser } from '../../../../redux/slices/user.slice';
import { useTranslation } from 'react-i18next';
import { Roles } from '../../../../services/enum/roles';
import { TenantTypes } from '../../../../services/enum/tenant-types';
import { Grow } from '@mui/material';
import FindCompaniesTable from '../../../organisms/friendship/general/find-companies-table';
import RelatedCompaniesTable from '../../../organisms/friendship/general/related-companies-table';
import IncomingRequestsTable from '../../../organisms/friendship/general/incoming-requests-table';
import SentRequestsTable from '../../../organisms/friendship/general/sent-requests-table';
import { useGetIncomingRequestsFriendshipCountQuery, useGetSentRequestsFriendshipCountQuery } from '../../../../services/catalog/friendship/friendship.service';

type Props = {}

const ConnectionPage = (props: Props) => {
    const { t } = useTranslation();

    const { currentUser } = useAppSelector(getCurrentUser);
    const { tenant } = useAppSelector(getTenant);

    const [selectedTab, setSelectedTab] = useState<string>('related');

    const { data: incomingCount } = useGetIncomingRequestsFriendshipCountQuery();
    const { data: sentCount } = useGetSentRequestsFriendshipCountQuery();

    return (
        <Grow in={currentUser.id ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
            <div className="w-full md:w-11/12 mx-auto">
                <ul className="flex flex-wrap justify-center sm:justify-normal w-11/12 md:w-full mx-auto mb-3">
                    <li onClick={() => setSelectedTab('related')} className={`${selectedTab === 'related' ? 'border-blue-600 active text-blue-600 border-b-2' : 'border-gray-300 border-b-2 hover:text-gray-600 hover:border-b-2 hover:border-gray-300 text-black'} pl-2 pr-4 pb-4 pt-4 rounded-t-lg mr-2 cursor-pointer`}>
                        <span className="material-symbols-outlined align-sub">person_check</span>
                        <span className="inline-block pl-1 text-sm">
                            {tenant.type === TenantTypes.Property_Owner ? (
                                t('page:friendship.relatedContractors')
                            ) : (
                                t('page:friendship.relatedPropertyOwners')
                            )}
                        </span>
                    </li>
                    {(incomingCount !== undefined && incomingCount > 0) && (
                        <li onClick={() => setSelectedTab('incoming')} className={`${selectedTab === 'incoming' ? 'border-blue-600 active text-blue-600 border-b-2' : 'border-gray-300 border-b-2 hover:text-gray-600 hover:border-b-2 hover:border-gray-300 text-black'} pl-2 pr-4 pb-4 pt-4 rounded-t-lg mr-2 cursor-pointer relative`}>
                            <span className="material-symbols-outlined align-sub pt-1" style={{ fontSize: '1.4rem' }}>mail</span>
                            <span className="inline-block pl-1 text-sm">
                                {tenant.type === TenantTypes.Property_Owner ? (
                                    t('page:friendship.incomingRequests')
                                ) : (
                                    t('page:friendship.incomingRequests')
                                )}
                            </span>
                            <span className="ml-1 py-0.5 px-1.5 rounded-full bg-secondary-blue text-black text-xs font-semibold">{incomingCount}</span>
                        </li>
                    )}
                    {(sentCount !== undefined && sentCount > 0) && (
                        <li onClick={() => setSelectedTab('sent')} className={`${selectedTab === 'sent' ? 'border-blue-600 active text-blue-600 border-b-2' : 'border-gray-300 border-b-2 hover:text-gray-600 hover:border-b-2 hover:border-gray-300 text-black'} pl-2 pr-4 pb-4 pt-4 rounded-t-lg mr-2 cursor-pointer relative`}>
                            <span className="material-symbols-outlined align-sub pt-1" style={{ fontSize: '1.3rem' }}>hourglass_empty</span>
                            <span className="inline-block pl-1 text-sm">
                                {tenant.type === TenantTypes.Property_Owner ? (
                                    t('page:friendship.sentRequests')
                                ) : (
                                    t('page:friendship.sentRequests')
                                )}
                            </span>
                            <span className="ml-1 py-0.5 px-1.5 rounded-full bg-secondary-blue text-black text-xs font-semibold">{sentCount}</span>
                        </li>
                    )}
                    <li onClick={() => setSelectedTab('find')} className={`${selectedTab === 'find' ? 'border-blue-600 active text-blue-600 border-b-2' : 'border-gray-300 border-b-2 hover:text-gray-600 hover:border-b-2 hover:border-gray-300 text-black'} pl-2 pr-4 pb-4 pt-4 rounded-t-lg mr-2 cursor-pointer`}>
                        <span className="material-symbols-outlined align-sub">person_search</span>
                        <span className="inline-block pl-1 text-sm">
                            {tenant.type === TenantTypes.Property_Owner ? (
                                t('page:friendship.findContractors')
                            ) : (
                                t('page:friendship.findPropertyOwners')
                            )}
                        </span>
                    </li>
                </ul>

                {selectedTab === 'related' && (
                    <>
                        {
                            (currentUser.role === Roles.Superadmin || currentUser.role === Roles.Admin) && (
                                <Grow in={selectedTab === 'related' ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                                    <div>
                                        <RelatedCompaniesTable />
                                    </div>
                                </Grow>
                            )
                        }
                    </>
                )}
                {selectedTab === 'incoming' && (
                    <>
                        {
                            (currentUser.role === Roles.Superadmin || currentUser.role === Roles.Admin) && (
                                <Grow in={selectedTab === 'incoming' ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                                    <div>
                                        <IncomingRequestsTable />
                                    </div>
                                </Grow>
                            )
                        }
                    </>
                )}
                {selectedTab === 'sent' && (
                    <>
                        {
                            (currentUser.role === Roles.Superadmin || currentUser.role === Roles.Admin) && (
                                <Grow in={selectedTab === 'sent' ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                                    <div>
                                        <SentRequestsTable />
                                    </div>
                                </Grow>
                            )
                        }
                    </>
                )}
                {selectedTab === 'find' && (
                    <>
                        {
                            (currentUser.role === Roles.Superadmin || currentUser.role === Roles.Admin) && (
                                <Grow in={selectedTab === 'find' ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                                    <div>
                                        <FindCompaniesTable />
                                    </div>
                                </Grow>
                            )
                        }
                    </>
                )}
            </div>
        </Grow>
    )
}

export default ConnectionPage;