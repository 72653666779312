import { Fragment, useEffect, useRef, useState } from "react";
import { dateToLocaleDate } from "../../../../utils/date-time";

import Modal from "../../../molecules/modal";
import ConfirmModal from "../../../molecules/confirm-modal";
import { propertyBuildingAPI, useDeletePropertyBuildingMutation, useGetPropertyBuildingsQuery, useUpdatePropertyBuildingMutation } from "../../../../services/tenant/property-building/property-building.service";
import { PropertyBuildingDto } from "../../../../services/tenant/property-building/dto/property-building.dto";
import UpdatePropertyBuildingForm from "../../forms/property-building/update-property-building-form";
import { NotificationManager } from 'react-notifications';
import PropertyBuildingUserAccess from "../property-building-user-access";
import PropertyBuildingObjectAccess from "../property-building-object-access";
import PropertyBuildingLogAccess from "../property-building-log-access";
import { useAppSelector } from "../../../../redux/hooks";
import { getCurrentUser } from "../../../../redux/slices/user.slice";
import { useTranslation } from "react-i18next";

import { Input } from "@mui/material";
import { debounce } from "../../../../utils/debounce";
import { Order } from "../../../../services/enum/order";
import Loader from "../../../atoms/loader/loader";
import { useDispatch } from "react-redux";
import { UpdatePropertyBuildingDto } from "../../../../services/tenant/property-building/dto/update-property-building.dto";
import { useGetAllPropertyBuildingUserAccessByIdsQuery, useUpdateManyPropertyBuildingUserAccessMutation } from "../../../../services/tenant/property-building-user-access/property-building-user-access.service";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { UpdatePropertyBuildingUserAccessDto } from "../../../../services/tenant/property-building-user-access/dto/update-property-building-user-access.dto";
import CreatePropertyBuildingForm from "../../forms/property-building/create-property-building-form";
import { PropertyDto } from "../../../../services/tenant/property/dto/property.dto";
import { UserDto } from "../../../../services/tenant/user/dto/user.dto";
import { LogTemplateDto } from "../../../../services/tenant/log-template/dto/log-template.dto";
import { LogTypes } from "../../../../services/enum/log-types";
import PrimaryButton from "../../../atoms/primary-button";
import Checkbox from "../../../atoms/checkbox";
import Pagination from "../../../molecules/pagination";
import Table from "../../../molecules/table";
import { useGetAllPropertyBuildingLogAccessByIdsQuery, useUpdateManyPropertyBuildingLogAccessMutation } from "../../../../services/tenant/entity-log/entity-log.service";
import { UpdatePropertyBuildingLogAccessDto } from "../../../../services/tenant/entity-log/dto/building-log-access/update-property-building-log-access.dto";

type Props = {
    properties?: PropertyDto[];
    users?: UserDto[];
    logTemplates?: LogTemplateDto[];
}

const PropertyBuildingTableEdit = ({
    properties,
    users,
    logTemplates,
}: Props) => {
    const { t } = useTranslation();
    const { currentUser } = useAppSelector(getCurrentUser);
    const dispatch = useDispatch();

    // Delete property building
    const [deletePropertyBuilding, { isLoading: deleteIsLoading, error: deleteError }] = useDeletePropertyBuildingMutation();
    const [updatePropertyBuilding, { isLoading: updatePropertyBuildingIsLoading, error: updatePropertyBuildingError }] = useUpdatePropertyBuildingMutation();
    const [updatePropertyBuildingUserAccess] = useUpdateManyPropertyBuildingUserAccessMutation();
    const [updatePropertyBuildingLogAccess] = useUpdateManyPropertyBuildingLogAccessMutation();


    const [showCreateModal, setShowCreateModal] = useState<boolean>(false); // Toggle create modal
    const [showCopyModal, setShowCopyModal] = useState<boolean>(false); // Toggle copy modal
    const [showModal, setShowModal] = useState<boolean>(false); // Toggle modal
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false); // Toggle confirm modal
    const [selectedPropertyBuilding, setSelectedPropertyBuilding] = useState<PropertyBuildingDto>();
    const [tableHeaders, setTableHeaders] = useState<string[]>();

    // Pagination
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>();
    const [hasNextPage, setHasNextPage] = useState<boolean>();
    const [hasPrevPage, setHasPrevPage] = useState<boolean>();

    // Table sort and search
    const [search, setSearch] = useState<string | undefined>('');
    const [searchOperation, setSearchOperation] = useState<string | undefined>();
    const [sortBy, setSortBy] = useState<string | undefined>('label'); // Column to sort by
    const [sortOrder, setSortOrder] = useState<Order>(Order.ASC); // Sort order
    const [showSortMenu, setShowSortMenu] = useState<boolean>(false); // Sort menu on small screens

    const debouncedSearch = useRef(debounce((search: string) => setSearchOperation(search), 500)).current;

    // Get property buildings paginated
    const { data: propertyBuildings, isFetching } = useGetPropertyBuildingsQuery({
        page: page,
        take: 25,
        search: searchOperation,
        sortBy: sortBy,
        order: sortOrder
    });

    const [skipTokenUserIds, setSkipTokenUserIds] = useState<any>(skipToken);
    const { data: propertyBuildingUserAccessByIds } = useGetAllPropertyBuildingUserAccessByIdsQuery(skipTokenUserIds);

    const [skipTokenLogTemplateIds, setSkipTokenLogTemplateIds] = useState<any>(skipToken);
    const { data: propertyBuildingLogAccessByIds } = useGetAllPropertyBuildingLogAccessByIdsQuery(skipTokenLogTemplateIds);

    useEffect(() => {
        if (users) {
            setSkipTokenUserIds(users.map(e => e.id).join(','));
        }
    }, [users]);

    useEffect(() => {
        if (logTemplates) {
            setSkipTokenLogTemplateIds(logTemplates.map(e => e.id).join(','));
        }
    }, [logTemplates]);

    useEffect(() => {
        if (propertyBuildings) {
            setPage(propertyBuildings.meta.page);
            setPageCount(propertyBuildings.meta.pageCount);
            setHasNextPage(propertyBuildings.meta.hasNextPage);
            setHasPrevPage(propertyBuildings.meta.hasPreviousPage);
        }
    }, [propertyBuildings])

    const handlePagination = (page: number) => {
        setPage(page);
    }

    // Handle sort by column and sort order
    const handleSort = (column: string, order: Order) => {
        setSortBy(column);
        setSortOrder(order === Order.ASC ? Order.DESC : Order.ASC)
    }

    // If selectedPropertyBuilding changes, set updated propertyBuilding
    useEffect(() => {
        if (selectedPropertyBuilding) {
            const match = propertyBuildings?.data.find((e) => e.id === selectedPropertyBuilding.id);
            if (match) {
                setSelectedPropertyBuilding(match);
            }
        }
    }, [propertyBuildings]);

    // Edit property building
    const handleEditPropertyBuilding = (propertyBuilding: PropertyBuildingDto) => {
        setShowModal(true); // Show modal
        setSelectedPropertyBuilding(propertyBuilding);
    }

    // Copy property building
    const handleCopyPropertyBuilding = (propertyBuilding: PropertyBuildingDto) => {
        setShowCopyModal(true);
        setSelectedPropertyBuilding(propertyBuilding);
    }

    // Toggle modal and setSelected user
    const handleDeletePropertyBuilding = (propertyBuilding: PropertyBuildingDto) => {
        setSelectedPropertyBuilding(propertyBuilding); // Set selected property building to be deleted if confirmed in modal
        setShowConfirmModal(true); // Show confirm modal
    }

    // Delete property building after confirmation
    const deletePropertyBuildingConfirmed = async () => {
        const propertyBuildingToBeDeleted = selectedPropertyBuilding;
        if (propertyBuildingToBeDeleted?.id) {

            const payload = {
                property_building_id: propertyBuildingToBeDeleted.id,
            }

            try {
                const response = await deletePropertyBuilding(payload).unwrap();
                if (response.success) {
                    NotificationManager.success(t('page:properties.form.buildingDeleted'));
                    setShowConfirmModal(false);
                }
                else {
                    NotificationManager.error(t('page:properties.form.buildingNotDeleted'));
                }
            }
            catch (error) {
                NotificationManager.error(t('page:properties.form.buildingNotDeleted'));
            }
        }
    }

    const handlePropertyBuilding = () => {

    }

    const handleUpdatePropertyBuilding = async (updatePropertyBuildingDto: UpdatePropertyBuildingDto) => {
        try {
            const response = await updatePropertyBuilding(updatePropertyBuildingDto).unwrap();
            if (response.success) {
                NotificationManager.success(t('page:properties.form.buildingUpdated'));
                dispatch(propertyBuildingAPI.util.invalidateTags(['PropertyBuilding']));
            }
            else {
                NotificationManager.error(t('page:properties.form.buildingNotUpdated'))
            }
        }
        catch (error) {
            NotificationManager.error(t('page:properties.form.buildingNotUpdated'));
        }
    }

    const handleUpdatePropertyBuildingUserAccess = async (updatePropertyBuildingUserAccessDto: UpdatePropertyBuildingUserAccessDto[]) => {
        // Update property building user access
        try {
            const response = await updatePropertyBuildingUserAccess(updatePropertyBuildingUserAccessDto).unwrap();
            if (response.success) {
                NotificationManager.success(t('page:properties.form.buildingUpdated'));
            }
            else {
                NotificationManager.error(t('page:properties.form.buildingNotUpdated'))
            }
        }
        catch (error) {
            NotificationManager.error(t('page:properties.form.buildingNotUpdated'));
        }
    }

    const handleUpdatePropertyBuildingLogAccess = async (updatePropertyBuildingLogAccessDto: UpdatePropertyBuildingLogAccessDto[]) => {
        // Update property building log access
        try {
            const response = await updatePropertyBuildingLogAccess(updatePropertyBuildingLogAccessDto).unwrap();
            if (response.success) {
                NotificationManager.success(t('page:properties.form.buildingUpdated'));
            }
            else {
                NotificationManager.error(t('page:properties.form.buildingNotUpdated'))
            }
        }
        catch (error) {
            NotificationManager.error(t('page:properties.form.buildingNotUpdated'));
        }
    }

    const handleAccess = async (propertyBuilding: PropertyBuildingDto, id: string) => {
        if (properties) {
            let updatePropertyBuildingDto: UpdatePropertyBuildingDto = {
                id: propertyBuilding.id
            }
            const existingAccess = propertyBuilding.property_id === id;
            if (existingAccess) {
            }
            else {
                updatePropertyBuildingDto.property_id = id;
                await handleUpdatePropertyBuilding(updatePropertyBuildingDto);

            }
        }
        else if (users && propertyBuildingUserAccessByIds) {
            const propertyBuildingUserAccessMatch = propertyBuildingUserAccessByIds.find(e => e.property_building_id === propertyBuilding.id && e.user_id === id);
            if (propertyBuildingUserAccessMatch) {
                // Remove access
                let updateObj: UpdatePropertyBuildingUserAccessDto = {
                    access: false,
                    user_id: id,
                    property_building_id: propertyBuilding.id,
                    property_building_user_access_id: propertyBuildingUserAccessMatch.id
                }
                handleUpdatePropertyBuildingUserAccess([updateObj]);
            }
            else {
                // Give access
                let updateObj: UpdatePropertyBuildingUserAccessDto = {
                    access: true,
                    user_id: id,
                    property_building_id: propertyBuilding.id,
                }
                handleUpdatePropertyBuildingUserAccess([updateObj]);
            }
        }

        else if (logTemplates && propertyBuildingLogAccessByIds) {
            const propertyBuildingLogAccessMatch = propertyBuildingLogAccessByIds.find(e => e.property_building_id === propertyBuilding.id && e.log_template_id === id);
            if (propertyBuildingLogAccessMatch) {
                // Remove access
                let updateObj: UpdatePropertyBuildingLogAccessDto = {
                    access: false,
                    log_template_id: id,
                    property_building_id: propertyBuilding.id,
                    entity_log_id: propertyBuildingLogAccessMatch.id
                }
                handleUpdatePropertyBuildingLogAccess([updateObj]);
            }
            else {
                // Give access
                let updateObj: UpdatePropertyBuildingLogAccessDto = {
                    access: true,
                    log_template_id: id,
                    property_building_id: propertyBuilding.id,
                }
                handleUpdatePropertyBuildingLogAccess([updateObj]);
            }
        }
    }

    return (
        <div className="w-full mx-auto pb-5">
            <div className="relative">
                <div className="flex items-center">
                    <h5 className="text-base font-semibold ml-2 w-5/12">{t('common:buildings')}</h5>
                    <div className="w-7/12 flex justify-end">
                        <PrimaryButton
                            text={t('page:properties.createBuilding')}
                            size={'medium'}
                            startAdornment={'add'}
                            onClick={() => setShowCreateModal(true)}
                        />
                    </div>
                </div>
                {(propertyBuildings && propertyBuildings.data.length < 1 && !searchOperation) ? (
                    <div className="w-10 xl:w-8/12 max-w-2xl mx-auto bg-primary-blue-5 text-black rounded-md px-2 py-4 text-center">
                        <p className="text-base font-semibold">{t('page:properties.noBuildingsFound')}...</p>
                    </div>
                ) : (
                    <>
                        <div className="sm:w-6/12 max-w-2xl mt-2 text-center sm:text-left">
                            <Input
                                type="search"
                                name="search_name"
                                placeholder={t('common:search')}
                                disableUnderline={true}
                                value={search}
                                className="w-full border rounded-md text-base md:text-sm px-2 pt-2.5 pb-1 mt-3 bg-white"
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                    debouncedSearch(e.target.value);
                                }}
                                endAdornment={
                                    <span
                                        className="material-symbols-outlined pb-1 text-gray-500 cursor-pointer"
                                        style={{ fontSize: '1.3rem' }}
                                        onClick={() => {
                                            if (search) {
                                                setSearch('')
                                                debouncedSearch('')
                                            }
                                        }}
                                    >
                                        {search ? 'close' : 'search'}
                                    </span>
                                }
                                size="small"
                            />
                        </div>
                        <div className="overflow-y-auto max-h-[45vh]">
                            {propertyBuildings && propertyBuildings.data.length > 0 && !isFetching && (
                                <Table>
                                    <Table.Thead>
                                        <tr>
                                            <th scope="col" className="table-auto sticky top-0 bg-white z-10">
                                                <span className="sr-only">Building image</span>
                                            </th>
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[8rem] max-w-[8rem] text-xs sticky top-0 bg-white z-10"
                                                onClick={() => handleSort('label', sortOrder)}>
                                                {t('common:label')}
                                                {(sortBy === 'label') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </th>
                                            {properties && [...properties]
                                                .sort((a, b) => a.label.localeCompare(b.label))
                                                .map(property => {
                                                    return (
                                                        <th
                                                            key={property.id}
                                                            scope="col"
                                                            className="py-1 pl-2 md:py-2 pr-2 sm:table-cell cursor-pointer font-bold min-w-[8rem] max-w-[8rem] text-xs sticky top-0 bg-white z-10">
                                                            {property.label}
                                                        </th>
                                                    )
                                                })}
                                            {users && [...users]
                                                .sort((a, b) => a.name.localeCompare(b.name))
                                                .map(user => {
                                                    return (
                                                        <th
                                                            key={user.id}
                                                            scope="col"
                                                            className="py-1 pl-2 md:py-2 pr-2 sm:table-cell cursor-pointer font-bold min-w-[8rem] max-w-[8rem] text-xs sticky top-0 bg-white z-10">
                                                            {user.name}
                                                        </th>
                                                    )
                                                })}

                                            {logTemplates && [...logTemplates]
                                                .sort((a, b) => a.name.localeCompare(b.name))
                                                .map(logTemplate => {
                                                    return (
                                                        <th
                                                            key={logTemplate.id}
                                                            scope="col"
                                                            className="py-1 pl-2 md:py-2 pr-2 sm:table-cell cursor-pointer font-bold min-w-[8rem] max-w-[8rem] text-xs sticky top-0 bg-white z-10">
                                                            {logTemplate.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${logTemplate.name.split('_').join('')}`) : logTemplate.name.split('_').join(' ')}
                                                        </th>
                                                    )
                                                })}
                                            <th scope="col" className="sticky top-0 bg-white z-10">
                                                <span className="sr-only">Copy</span>
                                            </th>
                                            <th scope="col" className="sticky top-0 bg-white z-10">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                            <th scope="col" className="sticky top-0 bg-white z-10">
                                                <span className="sr-only">Delete</span>
                                            </th>
                                        </tr>
                                    </Table.Thead>
                                    <Table.Tbody>
                                        {propertyBuildings?.data.map((propertyBuilding, idx) => {
                                            return (
                                                <tr key={idx} className={`odd:hover:bg-primary-blue-10 even:hover:bg-gray-50 cursor-pointer text-xs text-black even:bg-white odd:bg-primary-blue-5`}>
                                                    <td scope="row" className="w-14 md:w-20 pl-2 capitalize py-1">
                                                        {propertyBuilding.image ? (
                                                            <div className="flex items-center w-8 h-8 rounded-full bg-white overflow-hidden">
                                                                <img
                                                                    src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${propertyBuilding.tenant_id}/${propertyBuilding.image}`}
                                                                    alt="Profile picture"
                                                                    className="w-8 h-8 rounded-full object-cover" />
                                                            </div>
                                                        ) : (
                                                            <div className="flex items-center justify-center w-8 h-8 rounded-full border border-gray-100">
                                                                <span
                                                                    className="material-symbols-outlined"
                                                                    style={{ fontSize: '1.2rem' }}
                                                                >
                                                                    home
                                                                </span>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td
                                                        className="py-2 pr-2 pl-1 sm:pl-2 text-xs capitalize font-semibold max-w-[5rem] min-w-[5rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                        title={propertyBuilding?.label}
                                                        onClick={handlePropertyBuilding}>
                                                        {propertyBuilding.label}
                                                    </td>
                                                    {properties && [...properties]
                                                        .sort((a, b) => a.label.localeCompare(b.label))
                                                        .map(property => {
                                                            return (
                                                                <td
                                                                    key={property.id}
                                                                    className="pl-4 text-xs capitalize font-semibold sm:font-normal max-w-[3rem] min-w-[3rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                                                    <Checkbox
                                                                        size={'sm'}
                                                                        disabled={propertyBuilding.property_id === property.id}
                                                                        checked={property.id === propertyBuilding.property_id}
                                                                        onChange={() => handleAccess(propertyBuilding, property.id)}
                                                                    />
                                                                </td>
                                                            )
                                                        })}
                                                    {users && propertyBuildingUserAccessByIds && [...users]
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map(user => {
                                                            return (
                                                                <td
                                                                    key={user.id}
                                                                    className="pl-4 text-xs capitalize font-semibold sm:font-normal max-w-[3rem] min-w-[3rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                                                    <Checkbox
                                                                        size={'sm'}
                                                                        checked={propertyBuildingUserAccessByIds.find(e => e.property_building_id === propertyBuilding.id && e.user_id === user.id) ? true : false}
                                                                        onChange={() => handleAccess(propertyBuilding, user.id)}
                                                                    />
                                                                </td>
                                                            )
                                                        })}

                                                    {logTemplates && propertyBuildingLogAccessByIds && [...logTemplates]
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map(logTemplate => {
                                                            return (
                                                                <td
                                                                    key={logTemplate.id}
                                                                    className="pl-4 text-xs capitalize font-semibold sm:font-normal max-w-[3rem] min-w-[3rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                                                    <Checkbox
                                                                        size={'sm'}
                                                                        checked={propertyBuildingLogAccessByIds.find(e => e.property_building_id === propertyBuilding.id && e.log_template_id === logTemplate.id) ? true : false}
                                                                        onChange={() => handleAccess(propertyBuilding, logTemplate.id)}
                                                                    />
                                                                </td>
                                                            )
                                                        })}
                                                    <td className="text-right">
                                                        <button
                                                            onClick={() => handleCopyPropertyBuilding(propertyBuilding)}
                                                            className="text-black material-symbols-outlined"
                                                            style={{ fontSize: '1.2rem' }}
                                                            title={t('common:duplicate')}
                                                        >content_copy</button>
                                                    </td>
                                                    <td className="pb-1 text-center px-2">
                                                        <button
                                                            onClick={() => handleEditPropertyBuilding(propertyBuilding)}
                                                            className="text-black material-symbols-outlined"
                                                            style={{ fontSize: '1.2rem' }}
                                                            title={t('common:edit')}
                                                        >edit_square</button>
                                                    </td>
                                                    <td className="text-left">
                                                        <button
                                                            onClick={() => handleDeletePropertyBuilding(propertyBuilding)}
                                                            className="material-symbols-outlined text-status-critical"
                                                            style={{ fontSize: '1.3rem' }}
                                                            title={t('common:delete')}
                                                        >delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </Table.Tbody>
                                </Table>
                            )}
                            {isFetching && (
                                <div className="mx-auto mb-5 mt-1">
                                    <Loader show={isFetching} size='small' />
                                </div>
                            )}

                            {!propertyBuildings || propertyBuildings.data.length < 1 && (
                                <div className="my-2">
                                    <p className="text-sm ml-7 md:ml-5 font-semibold text-black">{t('page:properties.noBuildingsFound')}...</p>
                                </div>
                            )}
                        </div>
                    </>
                )}


                {propertyBuildings?.meta && propertyBuildings?.meta?.hasNextPage && (
                    <div className="flex justify-center py-2">
                        <Pagination
                            count={pageCount}
                            page={page}
                            defaultPage={1}
                            showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                            showLastButton={pageCount ? pageCount > 5 : false}
                            hidePrevButton={!hasPrevPage}
                            hideNextButton={!hasNextPage}
                            onChange={handlePagination}
                        />
                    </div>
                )}
            </div>

            <Modal isOpen={showCreateModal} close={() => setShowCreateModal(false)} title={`${t('page:properties.createBuilding')}`} size='sm' titleAlign='center'>
                <CreatePropertyBuildingForm />
            </Modal>

            <Modal isOpen={showCopyModal} close={() => setShowCopyModal(false)} title={`${t('page:properties.createBuilding')} (${t('common:duplicate')})`} size='sm' titleAlign='center'>
                <CreatePropertyBuildingForm propertyBuilding={selectedPropertyBuilding} />
            </Modal>

            <Modal isOpen={showModal} close={() => setShowModal(false)} title={`${selectedPropertyBuilding?.label}`} size='sm' titleAlign='center'>
                <div>
                    <div className="flex flex-wrap justify-center mx-auto mb-5">
                        <div className="w-10/12 sm:w-full">
                            <UpdatePropertyBuildingForm propertyBuilding={selectedPropertyBuilding} showPropertyBuildingModal={setShowModal} />

                            <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                            <PropertyBuildingLogAccess propertyBuilding={selectedPropertyBuilding} />

                            <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                            <PropertyBuildingUserAccess propertyBuilding={selectedPropertyBuilding} />

                            <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                            <PropertyBuildingObjectAccess propertyBuilding={selectedPropertyBuilding} />
                        </div>
                    </div>
                </div>
            </Modal>

            <ConfirmModal isOpen={showConfirmModal} close={() => setShowConfirmModal(false)} title={t('page:properties.deleteBuilding')} titleAlign='center' type='warning'>
                <div className="bg-red-200 rounded p-1">
                    <p className="text-xs font-semibold my-1 text-black">{t('page:properties.deleteBuildingInfo')}</p>
                    <p className="text-xs font-semibold mt-2 mb-1 text-black">{t('page:properties.deleteBuildingLogInfo')}</p>
                </div>
                <p className="text-lg font-semibold mt-3">{t('common:confirmDelete')} {selectedPropertyBuilding?.label ? selectedPropertyBuilding.label : 'the selected building'}?</p>
                <button
                    onClick={deletePropertyBuildingConfirmed}
                    className="bg-status-critical hover:bg-status-critical-h py-2 px-2 sm:px-6 mt-3 rounded mx-auto text-sm sm:text-base text-white font-semibold">{t('common:delete')}</button>
            </ConfirmModal>
        </div>
    )
}

export default PropertyBuildingTableEdit;