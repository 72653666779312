import React, { useEffect, useRef, useState } from 'react'
import Loader from '../../../atoms/loader/loader';
import { NotificationManager } from 'react-notifications';
import { CreateLogEntryCustomDto, LogData } from '../../../../services/tenant/log-entries/dto/create-log-entry-custom.dto';
import { useCreateCustomLogEntryMutation } from '../../../../services/tenant/log-entries/log.service';
import { LogTemplateDto } from '../../../../services/tenant/log-template/dto/log-template.dto';
import { useUploadFilesMutation } from '../../../../services/tenant/media/media.service';
import { useTranslation } from 'react-i18next';
import { compressImage } from '../../../../utils/compress-image';
import { FileLimits } from '../../../../constants/file-limits';
import { LogTypes } from '../../../../services/enum/log-types';
import Checkbox from '../../../atoms/checkbox';
import { EntityLogDto } from '../../../../services/tenant/entity-log/dto/entity-log.dto';
import { ProximityEnforcement } from '../../../../services/enum/proximity-enforcement';
import { ProximityRange } from '../../../../services/enum/proximity-range';
import Proximity from '../../maps/proximity';
import ConfirmModal from '../../../molecules/confirm-modal';
import PrimaryButton from '../../../atoms/primary-button';
import SecondaryButton from '../../../atoms/secondary-button';

type Props = {
    setShowCreateLog: (flag: boolean) => void;
    entityLog: EntityLogDto;
    logTemplate?: LogTemplateDto;
}

const CreateCustomLogEntryForm = ({
    setShowCreateLog,
    entityLog,
    logTemplate
}: Props) => {
    const { t } = useTranslation();
    const [uploadFiles, uploadFileResponse] = useUploadFilesMutation();
    const [createLogEntry, response] = useCreateCustomLogEntryMutation();
    const [logData, setLogData] = useState<LogData[]>([]);
    const [fileError, setFileError] = useState<string>('');

    const [showMap, setShowMap] = useState<boolean>(false);
    const [locationError, setLocationError] = useState<boolean>(false);
    const [userWithinRange, setUserWithinRange] = useState<boolean>(false);
    const [distanceFromBoundary, setDistanceFromBoundary] = useState<number | null>(null);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false); // If proximity enforcement = Request
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false); // If proximity enforcement = Require

    // Set initial state for the form data
    useEffect(() => {
        if (logTemplate) {
            setInitialState();
        }
    }, [logTemplate]);

    useEffect(() => {
        if (entityLog.proximity_detection) {
            if (entityLog.property_object_id) {
                if (entityLog.property_object_address || entityLog.property_object_longitude || entityLog.property_object_latitude) {
                    setShowMap(true);
                    return;
                }
            }
            else if (entityLog.property_building_id) {
                if (entityLog.property_building_address || entityLog.property_building_longitude || entityLog.property_building_latitude) {
                    setShowMap(true);
                    return;
                }
            }
            else if (entityLog.property_id) {
                if (entityLog.property_address || entityLog.property_longitude || entityLog.property_latitude) {
                    setShowMap(true);
                    return;
                }
            }
        }
        setShowMap(false);
    }, [])

    // Initial state
    const setInitialState = () => {
        if (logTemplate) {
            const newData = logTemplate.field_definitions.map((field) => {
                switch (field.type) {
                    case 'text':
                        return {
                            type: field.type,
                            name: field.name,
                            data: '',
                            active: field.active,
                        };
                    case 'date':
                        return {
                            type: field.type,
                            name: field.name,
                            data: new Date(),
                            active: field.active,
                        };
                    case 'file':
                        return {
                            type: field.type,
                            name: field.name,
                            data: [],
                            active: field.active,
                        };
                    case 'checkbox':
                        return {
                            type: field.type,
                            name: field.name,
                            data: false,
                            active: field.active,
                        };
                    case 'select':
                        return {
                            type: field.type,
                            name: field.name,
                            data: field.options?.[0],
                            active: field.active,
                        };
                    default:
                        return {
                            type: '',
                            name: '',
                            data: '',
                            active: true,
                        }
                }
            });
            setLogData(newData);
        }
    }

    // Handle proximity options before submitting form
    const preSubmitChecks = async (event: any) => {
        event.preventDefault();

        if (entityLog.proximity_detection) {
            if (userWithinRange) {
                // Submit form
                handleSubmit();
            }
            else {
                if (entityLog.proximity_enforcement === ProximityEnforcement.Request) {
                    // Show modal to ask user to continue even though out of range
                    setShowConfirmModal(true);
                    return;
                }
                else if (entityLog.proximity_enforcement === ProximityEnforcement.Require) {
                    // Show modal to inform user they need to be in range to create log
                    setShowInfoModal(true);
                    return;
                }
            }
        }
        handleSubmit();
    }

    // Submit form
    const handleSubmit = async () => {
        // event.preventDefault();
        setShowConfirmModal(false); // Close confirm modal

        setShowCreateLog(false);

        // Check if any files is present in form
        const logFiles = logData.find(e => e.type === 'file');
        if (logFiles && logFiles.data && logFiles.data.length > 0) {
            // Call method to upload files
            const fileNames = await submitFiles(logData);

            // Submit form after files are uploaded
            if (fileNames && fileNames.length > 0) {
                submitForm(fileNames);
            }
        }
        else {
            // Submit form if no files were found
            submitForm();
        }
    }

    // Upload files
    const submitFiles = async (logData: LogData[]) => {
        let filesValid = true;
        let counter = 0;

        const formData = new FormData();
        formData.append('uploadType', 'logs');
        // Loop through files and append to formData obj
        await Promise.all(logData.map(async (field) => {
            if (field.type === 'file') {
                for (let i = 0; i < field.data.length; i++) {
                    if ((field.data[i].size / 1024 / 1024) > FileLimits.maxFileSize) {
                        setFileError(`${t('common:file')} '${field.data[i].name}' ${t('common:form.exceedsFileSize')} ${FileLimits.maxFileSize}MB`);
                        filesValid = false;
                    }

                    if (field.data[i].type.split('/')[0] === 'image') {
                        // Compress image
                        const result = await compressImage(field.data[i], 'default', 0.8, 'contain');
                        if (result.success) {
                            formData.append('files', result.data);
                            counter += 1;
                        }
                        else {
                            formData.append('files', field.data[i]);
                            counter += 1;
                        }
                    }
                    else {
                        formData.append('files', field.data[i])
                        counter += 1;
                    }
                }
            }
        }));
        if (counter > 3) {
            setFileError(t('common:form.maxFiles3'));
            filesValid = false;
        }

        if (!filesValid) {
            return null;
        }

        // Reset file error if passed validation
        setFileError('');

        // Upload files
        try {
            const response = await uploadFiles(formData).unwrap();

            if (response[0].success) {
                // Extract fileNames from response object
                return response.map(obj => obj.data);
            }
            return null;
        }
        catch (error) {
            return null;
        }
    }

    const submitForm = async (fileNames?: string[]) => {
        // Check if any files are present
        if (logData.some((e) => e.type === 'file') && fileNames) {
            logData.forEach((field) => {
                if (field.type === 'file') {
                    field.data = fileNames; // Set files to hold array of file names
                }
            })
        }

        if (logTemplate) {
            const payload: CreateLogEntryCustomDto = {
                log_template_id: logTemplate.id,
                property_id: entityLog.property_id,
                property_building_id: entityLog.property_building_id,
                property_object_id: entityLog.property_object_id,
                proximity_valid: userWithinRange,
                log_data: logData,
            }

            try {
                const response = await createLogEntry(payload).unwrap();

                if (response.success) {
                    // event.target.reset(); // Reset form state // FIX: HANDLE MANUALLY?
                    NotificationManager.success(t('page:logs.form.logCreated'));
                }
            }
            catch (error) {
                NotificationManager.error(t('common:server.log_not_created'));
            }
        }
    }

    const handleInputChange = (value: string | boolean, fieldName: string, fieldType: string, index: number) => {
        // Get existing logData
        const updatedLogData = [...logData];
        // Update data
        updatedLogData[index] = {
            ...updatedLogData[index],
            data: value,
        }
        setLogData(updatedLogData);
    }

    const handleFileChange = (files: FileList | null, fieldName: string, fieldType: string, index: number) => {
        // Get existing logData
        const updatedLogData = [...logData];
        // Update data
        updatedLogData[index] = {
            ...updatedLogData[index],
            data: files,
        }
        setLogData(updatedLogData);
    }


    return (
        <>
            {showMap && (
                <>
                    {locationError ? (
                        <div className="flex flex-col justify-center mx-auto w-11/12 md:w-8/12 my-5 bg-red-100 rounded-md p-2 text-sm">
                            <p className="text-center font-bold">{t('page:logs.proximityLocationNotFound')}</p>
                            <p className="my-1">
                                {t('page:logs.proximityLocationNotFoundInfo1')} {t('page:logs.proximityLocationNotFoundInfo2')}
                            </p>
                        </div>
                    ) : (
                        <div className="flex justify-center mx-auto h-96 w-11/12 md:w-8/12 my-5">
                            <Proximity
                                entityLog={entityLog}
                                setUserWithinRange={setUserWithinRange}
                                setDistanceFromBoundary={setDistanceFromBoundary}
                                setLocationError={setLocationError}
                            />
                        </div>
                    )}
                </>
            )}

            <div className="mx-auto w-11/12 md:w-8/12">
                <form className="space-y-3" onSubmit={preSubmitChecks}>
                    {logTemplate?.field_definitions.map((field, idx) => {
                        if ((field.type === 'text' || field.type === 'date') && field.active) {
                            return (
                                <div key={idx}>
                                    <label htmlFor={field.name} className="block text-sm font-semibold leading-6 text-gray-600">
                                        <span className="">{(logTemplate.log_type === LogTypes.Default) ? t(`page:logs.defaultLogs.${field.name.toLowerCase()}`) : field.name.split('_').join(' ')}
                                            {field.required && (<span className="text-status-critical">*</span>)}
                                        </span>
                                    </label>
                                    <input
                                        type={field.type}
                                        required={field.required}
                                        placeholder={(logTemplate.log_type === LogTypes.Default) ? t(`page:logs.defaultLogs.${field.name.toLowerCase()}`) : field.name}
                                        spellCheck={true}
                                        autoComplete='off'
                                        defaultValue={field.type === 'date' ? new Date().toISOString().split('T')[0] : ''}
                                        minLength={2}
                                        onChange={(e) => handleInputChange(e.target.value, field.name, field.type, idx)}
                                        className="placeholder:capitalize w-full text-base rounded-md border-0 p-2.5 sm:py-2 h-12 sm:h-10 text-gray-900 bg-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            )
                        }
                        else if (field.type === 'file' && field.active) {
                            return (
                                <div key={idx}>
                                    <label htmlFor={field.name} className="block text-sm font-semibold leading-6 text-gray-600">
                                        <span className="">{field.name.split('_').join(' ')}
                                            {field.required && (<span className="text-status-critical">*</span>)}
                                            <span className="text-xs ml-1 normal-case">({t('common:form.maxFiles3')})</span>
                                        </span>
                                    </label>
                                    <input
                                        type={field.type}
                                        accept={'image/*, video/*'}
                                        // capture='environment'
                                        multiple={true} // Allow multiple file uploads
                                        required={field.required}
                                        placeholder={field.name}
                                        onChange={(e) => handleFileChange(e.target.files, field.name, field.type, idx)}
                                        className={`${fileError ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} placeholder:capitalize w-full rounded-md border-0 p-2.5 sm:py-1.5 h-12 sm:h-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                    />
                                    {fileError && (<span className="text-status-critical font-semibold text-xs">{fileError}</span>)}
                                </div>
                            )
                        }
                        else if (field.type === 'checkbox' && field.active) {
                            return (
                                <div key={idx} className="flex items-center h-10">
                                    <Checkbox
                                        size={'md'}
                                        onChange={(e) => handleInputChange(e.target.checked, field.name, field.type, idx)}
                                    />
                                    <label htmlFor={field.name} className="text-sm font-semibold leading-6 text-gray-600">
                                        <span className="">{field.name.split('_').join(' ')}</span>
                                    </label>
                                </div>
                            )
                        }
                        else if (field.type === 'select' && field.active) {
                            return (
                                <div key={idx}>
                                    <label htmlFor={field.name} className="text-sm font-semibold leading-6 text-gray-600">
                                        <span className="">{field.name.split('_').join(' ')}
                                            {field.required && (<span className="text-status-critical">*</span>)}
                                        </span>
                                    </label>
                                    <select
                                        name={field.name}
                                        required={field.required}
                                        onChange={(e) => handleInputChange(e.target.value, field.name, field.type, idx)}
                                        className="w-full rounded-md border h-12 sm:h-10 p-2.5 sm:py-2 text-gray-900 bg-white shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        {field.options?.map((option, idx) => {
                                            return (
                                                <option key={idx} value={option}>
                                                    {option}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            )
                        }
                    })}

                    {/* @ts-ignore */}
                    {(response.error && response.error.data.message) && (<p className="font-bold text-center text-status-critical">{t(`common:server.${response.error.data.message}`)}</p>)}
                    {/* @ts-ignore */}
                    {(uploadFileResponse.error && uploadFileResponse.error.data.message) && (<p className="font-bold text-center text-status-critical">{t(`common:server.${uploadFileResponse.error.data.message}`)}</p>)}

                    <div>
                        {uploadFileResponse.isLoading && (
                            <div className="my-3 text-center">
                                <span className="text-sm font-semibold text-black">{t('common:form.filesBeingUploaded')}</span>
                                <span className="material-symbols-outlined animate-bounce text-primary-blue">cloud_upload</span>
                            </div>
                        )}
                        <button
                            type="submit"
                            className={`${(response.isLoading || uploadFileResponse.isLoading) && 'hidden'} flex w-full justify-center rounded-md bg-primary-blue hover:bg-primary-blue-h px-3 py-2 text-sm font-bold leading-6 text-secondary-blue shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                        >
                            {t('common:create')}
                        </button>
                        {response.isLoading && (<Loader show={response.isLoading} size='small' />)}
                    </div>
                </form>
            </div>

            {/* Proximity enforcement = Request */}
            <ConfirmModal isOpen={showConfirmModal} close={() => setShowConfirmModal(false)} title={''} titleAlign='center' type='info' textAlign='left'>
                <div>
                    <div>
                        {distanceFromBoundary ? (
                            <p className="my-2">
                                {t('page:logs.proximityRightNowYouAre')}
                                <span className="font-bold"> {distanceFromBoundary > 10000 ? (distanceFromBoundary / 10000).toFixed(1) : distanceFromBoundary > 1000 ? (distanceFromBoundary / 1000).toFixed(1) : distanceFromBoundary}</span>
                                <span className="font-bold"> {distanceFromBoundary > 10000 ? 'mil' : distanceFromBoundary > 1000 ? 'km' : 'meter'} </span>
                                {t('page:logs.proximityFromTheValidArea')}
                            </p>
                        ) : (
                            <p>{t('page:logs.proximityNotInTheValidArea')}</p>
                        )}

                        <p className="my-2">{t('page:logs.proximityInfo1')}</p>
                        <p className="my-2">{t('page:logs.proximityInfo2')}</p>
                        <p className="mt-4 text-xs">
                            <span>{t('page:logs.proximityMapInfo1')}</span>
                            <span> {t('page:logs.proximityMapInfo2')}</span>
                        </p>
                    </div>
                    <div className="flex justify-center gap-x-3 mt-5">
                        <PrimaryButton
                            text={t('common:create')}
                            size='medium'
                            wide={true}
                            onClick={() => handleSubmit()}
                        />
                        <SecondaryButton
                            text={t('common:cancel')}
                            size='medium'
                            wide={true}
                            onClick={() => setShowConfirmModal(false)}
                        />
                    </div>
                </div>
            </ConfirmModal>
            {/* Proximity enforcement = Require */}
            <ConfirmModal isOpen={showInfoModal} close={() => setShowInfoModal(false)} title={''} type='warning' textAlign='left'>
                <div>
                    <div>
                        {distanceFromBoundary ? (
                            <p className="my-2">
                                {t('page:logs.proximityRightNowYouAre')}
                                <span className="font-bold"> {distanceFromBoundary > 10000 ? (distanceFromBoundary / 10000).toFixed(1) : distanceFromBoundary > 1000 ? (distanceFromBoundary / 1000).toFixed(1) : distanceFromBoundary}</span>
                                <span className="font-bold"> {distanceFromBoundary > 10000 ? 'mil' : distanceFromBoundary > 1000 ? 'km' : 'meter'} </span>
                                {t('page:logs.proximityFromTheValidArea')}
                            </p>
                        ) : (
                            <p>{t('page:logs.proximityNotInTheValidArea')}</p>
                        )}

                        <p className="my-2">{t('page:logs.proximityInfo3')}</p>
                        <p className="mt-4 text-xs">
                            <span>{t('page:logs.proximityMapInfo1')}</span>
                            <span> {t('page:logs.proximityMapInfo2')}</span>
                        </p>
                    </div>
                    <div className="flex justify-center mt-5">
                        <SecondaryButton
                            text={t('cancel')}
                            size='medium'
                        />
                    </div>
                </div>
            </ConfirmModal>
        </>
    )
}

export default CreateCustomLogEntryForm;