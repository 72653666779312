
import { Fragment, useEffect, useRef, useState } from "react";
import { UserDto } from "../../../../services/tenant/user/dto/user.dto";
import { dateToLocaleDate, dateToLocaleDateAndHour } from "../../../../utils/date-time";
import { useDeleteUserMutation, useGetUsersQuery, useUpdateUserMutation, userAPI } from "../../../../services/tenant/user/user.service";
import { UpdateUserDto } from "../../../../services/tenant/user/dto/update-user.dto";
import { NotificationManager } from 'react-notifications';

import Modal from "../../../molecules/modal";
import UpdateUserForm from "../../forms/user/update-user-form";
import { getCurrentUser } from "../../../../redux/slices/user.slice";
import ConfirmModal from "../../../molecules/confirm-modal";
import { useAppSelector } from "../../../../redux/hooks";
import UserPropertyObjectAccess from "../user-property-object-access";
import UserPropertyBuildingAccess from "../user-property-building-access";
import UserPropertyAccess from "../user-property-access";
import RevokeUserLogAccess from "../revoke-user-log-access";
import { useTranslation } from "react-i18next";
import { Input } from "@mui/material";
import { debounce } from "../../../../utils/debounce";
import { Roles } from "../../../../services/enum/roles";
import { Order } from "../../../../services/enum/order";
import Loader from "../../../atoms/loader/loader";
import FilterTableColumns from "../../../molecules/filter-table-columns";
import { Menu, Transition } from '@headlessui/react';
import CreateUserFormAdmin from "../../forms/user/create-user-form-admin";
import { RollerShadesClosed } from "@mui/icons-material";
import Pagination from "../../../molecules/pagination";
import Table from "../../../molecules/table";

type Props = {}

const UserTable = ({ }: Props) => {
    const { t } = useTranslation();

    const { currentUser } = useAppSelector(getCurrentUser);

    const [updateUser, { isLoading: updateIsLoading, error: updateError }] = useUpdateUserMutation();
    const [deleteUser, { isLoading: deleteIsLoading, error: deleteError }] = useDeleteUserMutation();

    const [showCopyModal, setShowCopyModal] = useState<boolean>(false); // Toggle copy modal
    const [showModal, setShowModal] = useState<boolean>(false); // Toggle modal
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false); // Toggle confirm modal
    const [selectedUser, setSelectedUser] = useState<UserDto>();
    const [tableHeaders, setTableHeaders] = useState<string[]>();

    // Pagination
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>();
    const [hasNextPage, setHasNextPage] = useState<boolean>();
    const [hasPrevPage, setHasPrevPage] = useState<boolean>();

    // Table sort and search
    const [search, setSearch] = useState<string | undefined>('');
    const [searchOperation, setSearchOperation] = useState<string | undefined>();
    const [sortBy, setSortBy] = useState<string | undefined>('name'); // Column to sort by
    const [sortOrder, setSortOrder] = useState<Order>(Order.ASC); // Sort order
    const [showSortMenu, setShowSortMenu] = useState<boolean>(false); // Sort menu on small screens

    const debouncedSearch = useRef(debounce((search: string) => setSearchOperation(search), 500)).current;

    // Get users paginated
    const { data: users, isFetching } = useGetUsersQuery({
        page: page,
        take: 25,
        search: searchOperation,
        sortBy: sortBy,
        order: sortOrder
    });

    useEffect(() => {
        if (users) {
            setPage(users.meta.page);
            setPageCount(users.meta.pageCount);
            setHasNextPage(users.meta.hasNextPage);
            setHasPrevPage(users.meta.hasPreviousPage);
        }
    }, [users])

    const handlePagination = (page: number) => {
        setPage(page);
    }

    useEffect(() => {
        if (users && users.data) {
            // Set state on update
            setUserRoles(Object.fromEntries(users.data.map((user) => [user.id, user.role])))
        }
    }, [users])

    const [userRoles, setUserRoles] = useState(
        // Map the users arr and create an object with user id as key and role as value
        (users && users.data) && Object.fromEntries(users.data.map((user) => [user.id, user.role]))
    )

    // If selectedUser changes, set updated user
    useEffect(() => {
        if (selectedUser) {
            const match = users?.data.find((e) => e.id === selectedUser.id);
            if (match) {
                setSelectedUser(match);
            }
        }
    }, [users]);

    // Define available column headers to be sent to FilterTableColumns
    const columnHeaders = ['name', 'email', 'phone', 'role', 'address', 'created_at'];

    // Gets triggered by FilterTableColumns when columns change
    const handleColumnsToDisplay = (columns: string[]) => {
        setTableHeaders(columns);
    }

    // Handle sort by column and sort order
    const handleSort = (column: string, order: Order) => {
        setSortBy(column);
        setSortOrder(order === Order.ASC ? Order.DESC : Order.ASC)

    }

    const handleRoleChange = async (userId: string, newRole: any) => {
        const payload: UpdateUserDto = {
            id: userId,
            role: newRole,
        }

        // Only superadmins can assign someone the role Superadmin
        if (newRole === Roles.Superadmin && currentUser.role === Roles.Admin) {
            NotificationManager.info(t('page:users.cantUpdateRoleSuperadmin'));
            return;
        }

        // Update role
        try {
            const response = await updateUser(payload).unwrap();
            if (response.success) {
                // Update state for passed userId and newRole
                setUserRoles((prevUserRoles) => ({
                    ...prevUserRoles,
                    [userId]: newRole
                }));
                NotificationManager.success(t('page:users.form.roleUpdated'));
            }
            else {
                NotificationManager.error(t('page:users.form.roleNotUpdated'))
            }
        }
        catch (error: any) {
            if ('data' in error) {
                NotificationManager.error(t(`common:server.${error.data.message}`));
            }
        }
    }

    // Edit user
    const handleEditUser = (user: UserDto) => {
        setShowModal(true); // Show modal
        setSelectedUser(user);
    }

    // Copy user
    const handleCopyUser = (user: UserDto) => {
        setShowCopyModal(true);
        setSelectedUser(user);
    }

    // Toggle modal and setSelected user
    const handleDeleteUser = (user: UserDto) => {
        setSelectedUser(user); // Set selected user to be deleted if confirmed in modal
        setShowConfirmModal(true); // Show confirm modal
    }

    // Delete user after confirmation
    const deleteUserConfirmed = async () => {
        const userToBeDeleted = selectedUser;
        if (userToBeDeleted?.id) {

            const payload = {
                user_id: userToBeDeleted.id,
            }

            try {
                const response = await deleteUser(payload).unwrap();
                if (response.success) {
                    NotificationManager.success(t('page:users.form.userDeleted'));
                    setShowConfirmModal(false);
                }
                else {
                    NotificationManager.error(t('page:users.form.userNotDeleted'));
                }

            }
            catch (error) {
                NotificationManager.error(t('page:users.form.userNotDeleted'));
            }

        }
    }

    return (
        <div className="w-full mx-auto md:mt-1">
            <div className="hidden md:block">
                <div className="sm:w-6/12 max-w-2xl mt-2 text-center sm:text-left">
                    <Input
                        type="search"
                        name="search_name"
                        placeholder={t('common:search')}
                        disableUnderline={true}
                        value={search}
                        className="w-full border rounded-md text-base md:text-sm px-2 pt-2.5 pb-1 mt-3 bg-white"
                        onChange={(e) => {
                            setSearch(e.target.value)
                            debouncedSearch(e.target.value)
                        }}
                        endAdornment={
                            <span
                                className="material-symbols-outlined pb-1 text-gray-500 cursor-pointer"
                                style={{ fontSize: '1.3rem' }}
                                onClick={() => {
                                    if (search) {
                                        setSearch('')
                                        debouncedSearch('')
                                    }
                                }}
                            >
                                {search ? 'close' : 'search'}
                            </span>
                        }
                        size="small"
                    />
                </div>
                <div className="w-full mt-1 mb-2">
                    <FilterTableColumns
                        tableColumns={columnHeaders}
                        tableType='ut_filter'
                        handleColumnsToDisplay={handleColumnsToDisplay}
                    />
                </div>
                <div className="overflow-x-scroll">
                    <Table>
                        <Table.Thead>
                            <tr>
                                <th scope="col" className="w-20 hidden sm:table-cell">
                                    <span className="sr-only">Profile picture</span>
                                </th>
                                {tableHeaders && tableHeaders.includes('name') && (
                                    <th scope="col" className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]" onClick={() => handleSort('name', sortOrder)}>
                                        {t('common:name')}
                                        {(sortBy === 'name') ? (
                                            <>
                                                {sortOrder === Order.ASC ? (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                ) : (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                            </>
                                        )}
                                    </th>
                                )}
                                {tableHeaders && tableHeaders.includes('email') && (
                                    <th scope="col" className="py-1 md:py-2 pr-2 sm:table-cell cursor-pointer font-bold min-w-[6rem]" onClick={() => handleSort('email', sortOrder)} >
                                        {t('common:email')}
                                        {(sortBy === 'email') ? (
                                            <>
                                                {sortOrder === Order.ASC ? (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                ) : (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                            </>
                                        )}
                                    </th>
                                )}
                                {tableHeaders && tableHeaders.includes('phone') && (
                                    <th scope="col" className="py-1 md:py-2 pr-2 cursor-pointer font-bold min-w-[1.75rem]">
                                        {t('common:phone')}
                                    </th>
                                )}

                                {tableHeaders && tableHeaders.includes('role') && (
                                    <>
                                        {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                                            <th scope="col" className="py-1 md:py-2 pr-2 cursor-pointer font-bold min-w-[7rem]" onClick={() => handleSort('role', sortOrder)}>
                                                {t('common:role')}
                                                {(sortBy === 'role') ? (
                                            <>
                                                {sortOrder === Order.ASC ? (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                ) : (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                            </>
                                        )}
                                            </th>
                                        )}
                                    </>
                                )}

                                {tableHeaders && tableHeaders.includes('address') && (
                                    <th scope="col" className="py-1 md:py-2 cursor-pointer font-bold min-w-[6rem]">
                                        {t('common:address')}
                                    </th>
                                )}

                                {tableHeaders && tableHeaders.includes('created_at') && (
                                    <th scope="col" className="py-1 md:py-2 cursor-pointer font-bold min-w-[6rem]" onClick={() => handleSort('created_at', sortOrder)}>
                                        {t('common:createdAt')}
                                        {(sortBy === 'created_at') ? (
                                            <>
                                                {sortOrder === Order.ASC ? (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                ) : (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                            </>
                                        )}
                                    </th>
                                )}
                                <th scope="col" className="">
                                    <span className="sr-only">Duplicate</span>
                                </th>
                                <th scope="col" className="">
                                    <span className="sr-only">Edit</span>
                                </th>
                                <th scope="col" className="">
                                    <span className="sr-only">Delete</span>
                                </th>
                            </tr>
                        </Table.Thead>
                        {users?.data && users.data.length > 0 && !isFetching && (
                            <Table.Tbody>
                                {users?.data?.map((user, idx) => {
                                    return (
                                        <tr key={idx} className={`odd:hover:bg-primary-blue-10 even:hover:bg-gray-50 cursor-pointer text-xs text-black even:bg-white odd:bg-primary-blue-5`}>
                                            <td scope="row" className="pl-0.5 capitalize hidden sm:table-cell py-1">
                                                {user.profile_picture ? (
                                                    <div className="flex items-center w-9 lg:w-12 h-9 lg:h-12 rounded-full bg-white overflow-hidden">
                                                        <img
                                                            src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${user.tenant_id}/${user.profile_picture}`}
                                                            // onError={e => e.currentTarget.src = DefaultPersonImg}
                                                            alt="Profile picture"
                                                            className="w-9 md:w-12 h-9 md:h-12 rounded-full object-cover lg:p-0.5" />
                                                    </div>
                                                ) : (
                                                    <div className="flex items-center justify-center w-9 lg:w-12 h-9 lg:h-12 rounded-full border border-gray-100">
                                                        <span
                                                            className="material-symbols-outlined"
                                                            style={{ fontSize: '1.4rem' }}
                                                        >
                                                            person
                                                        </span>
                                                    </div>
                                                )}
                                            </td>
                                            {tableHeaders && tableHeaders.includes('name') && (
                                                <td
                                                    className="py-2 pl-1 sm:pl-2 text-xs capitalize font-semibold sm:font-normal max-w-[8rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                    title={user?.name}>
                                                    {user.name ? user.name : '-'}
                                                </td>
                                            )}
                                            {tableHeaders && tableHeaders.includes('email') && (
                                                <td
                                                    className="py-2 pr-2 max-w-[8rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                    title={user?.email}>
                                                    {user.email ? user.email : '-'}
                                                </td>
                                            )}
                                            {tableHeaders && tableHeaders.includes('phone') && (
                                                <td
                                                    className="py-2 pr-2 capitalize max-w-[2rem] min-w-[1.75rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                    title={user?.phone}>
                                                    {user.phone ? user.phone : '-'}
                                                </td>
                                            )}
                                            {tableHeaders && tableHeaders.includes('role') && (
                                                <>
                                                    {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                                                        <td className="pr-2 min-w-[7rem] ">
                                                            <select
                                                                disabled={currentUser.id === user.id || (currentUser.role === Roles.Admin && user.role === Roles.Superadmin)}
                                                                value={userRoles && userRoles[user.id]}
                                                                onChange={(e) => handleRoleChange(user.id, e.target.value)}
                                                                className={`p-1 rounded-md border text-gray-900 bg-white shadow-sm focus:ring-0 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:text-gray-400 ${user.role === Roles.None && 'border-2 border-red-300'}`}
                                                            >
                                                                {(Object.values(Roles) as Array<keyof typeof Roles>)
                                                                .map((val, idx) => {
                                                                    return (
                                                                        <Fragment key={idx}>
                                                                            {(typeof val === 'string') && (
                                                                                <option key={idx} value={val}>{t(`common:enums.roles.${Roles[val]}`)}</option>
                                                                            )}
                                                                        </Fragment>
                                                                    )
                                                                })}
                                                            </select>
                                                        </td>
                                                    )}
                                                </>
                                            )}
                                            {tableHeaders && tableHeaders.includes('address') && (
                                                <td
                                                    className="py-2 pr-2 max-w-[7rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                    title={user?.address}>
                                                    {user.address ? user.address : '-'}
                                                </td>
                                            )}
                                            {tableHeaders && tableHeaders.includes('created_at') && (
                                                <td
                                                    className="py-2 max-w-[7rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                    title={user.created_at && dateToLocaleDate(user.created_at)}>
                                                    {user.created_at ? dateToLocaleDate(user.created_at) : '-'}
                                                </td>
                                            )}
                                            <td className="px-2 lg:px-1">
                                                <button
                                                    onClick={() => handleCopyUser(user)}
                                                    className="text-black material-symbols-outlined"
                                                    style={{ fontSize: '1.2rem' }}
                                                    title={t('common:duplicate')}
                                                >content_copy</button>
                                            </td>
                                            <td className="pb-1 px-2 lg:px-1">
                                                <button
                                                    onClick={() => handleEditUser(user)}
                                                    className="text-black material-symbols-outlined"
                                                    style={{ fontSize: '1.2rem' }}
                                                    title={t('common:edit')}
                                                >edit_square</button>
                                            </td>
                                            <td className="px-2 lg:px-1">
                                                <button
                                                    disabled={currentUser.id === user.id || (currentUser.role !== Roles.Superadmin && user.role === Roles.Superadmin)}
                                                    onClick={() => handleDeleteUser(user)}
                                                    className="material-symbols-outlined text-status-critical disabled:text-gray-300"
                                                    style={{ fontSize: '1.3rem' }}
                                                    title={t('common:delete')}
                                                >delete</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </Table.Tbody>
                        )}
                    </Table>
                    {isFetching && (
                        <div className="mx-auto mb-5 mt-1">
                            <Loader show={isFetching} size='small' />
                        </div>
                    )}
                    {!users || users.data.length < 1 && (
                        <div className="my-5">
                            <p className="text-sm ml-8 md:ml-5 font-semibold text-black">{t('page:properties.noUsersFound')}...</p>
                        </div>
                    )}
                </div>
                {users && users.meta && (
                    <div className="flex justify-center py-2">
                        <Pagination
                            count={pageCount}
                            page={page}
                            defaultPage={1}
                            showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                            showLastButton={pageCount ? pageCount > 5 : false}
                            hidePrevButton={!hasPrevPage}
                            hideNextButton={!hasNextPage}
                            onChange={handlePagination}
                        />
                    </div>
                )}

            </div>

            {/* Mobile screen  */}
            <div className="block md:hidden w-full mx-auto mt-1">
                <div className="pt-1">
                    <Input
                        type="search"
                        name="search_name"
                        placeholder={t('common:search')}
                        disableUnderline={true}
                        className="border rounded-md px-2 pt-2.5 pb-1 mt-3 w-10/12 bg-white"
                        onChange={(e) => debouncedSearch(e.target.value)}
                        endAdornment={<span className="material-symbols-outlined text-gray-500 pb-1" style={{ fontSize: '1.3rem' }}>search</span>}
                        size="small"
                    />
                    <Menu as="div" className="relative inline-block text-left cursor-pointer">
                        <div>
                            <Menu.Button
                                className="material-symbols-outlined text-black align-sub ml-3 rotate-90 border rounded-md px-1 py-1"
                                style={{ fontSize: '1.4rem' }}
                                onClick={() => setShowSortMenu(!showSortMenu)}>
                                sync_alt
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items
                                className="absolute right-0 z-10 mt-2 w-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="">
                                    <Menu.Item>
                                        <div className="flex cursor-pointer hover:bg-primary-blue-5"
                                            onClick={(e) => handleSort('name', sortOrder)}>
                                            <span className="w-1/2 block px-2 py-2 text-sm">{t('common:name')}</span>
                                            <div className="w-1/2 py-2 pr-1">
                                                {(sortBy === 'name') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <div className="flex cursor-pointer hover:bg-primary-blue-5" onClick={() => handleSort('email', sortOrder)}>
                                            <span className="w-1/2 block px-2 py-2 text-sm">{t('common:email')}</span>
                                            <div className="w-1/2 py-2 pr-1">
                                                {(sortBy === 'email') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <div className="flex cursor-pointer hover:bg-primary-blue-5" onClick={() => handleSort('created_at', sortOrder)}>
                                            <span className="w-1/2 block px-2 py-2 text-sm">{t('common:createdAt')}</span>
                                            <div className="w-1/2 py-2 pr-1">
                                                {(sortBy === 'created_at') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
                {users?.data && users.data.length > 0 && !isFetching && (
                    <div>
                        {users?.data?.map((user, idx) => (
                            <div key={idx} className="my-2 p-2 text-xs relative odd:bg-primary-blue-5 odd:hover:bg-primary-blue-10 even:border border-primary-blue-5 hover:even:bg-gray-50 rounded-md">
                                <div className="flex flex-col flex-wrap gap-x-5 my-1">
                                    <div>
                                        <button
                                            onClick={() => handleCopyUser(user)}
                                            className="text-black material-symbols-outlined absolute right-[4.5rem] top-2"
                                            style={{ fontSize: '1.2rem' }}
                                            title={t('common:duplicate')}
                                        >content_copy</button>
                                        <button
                                            onClick={() => handleEditUser(user)}
                                            className="text-black material-symbols-outlined absolute right-10 top-1.5"
                                            style={{ fontSize: '1.2rem' }}
                                            title={t('common:edit')}
                                        >edit_square</button>
                                        <button
                                            disabled={currentUser.id === user.id || (currentUser.role !== Roles.Superadmin && user.role === Roles.Superadmin)}
                                            onClick={() => handleDeleteUser(user)}
                                            className="material-symbols-outlined text-status-critical disabled:text-gray-300 absolute right-2 top-2"
                                            style={{ fontSize: '1.3rem' }}
                                            title={t('common:delete')}
                                        >delete</button>
                                    </div>

                                    <div className="flex items-center">
                                        <div className="sm:ml-5">
                                            {user.profile_picture ? (
                                                <div className="flex items-center rounded-full overflow-hidden">
                                                    <img
                                                        src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${user.tenant_id}/${user.profile_picture}`}
                                                        // onError={e => e.currentTarget.src = DefaultPersonImg}
                                                        alt="Profile picture"
                                                        className="rounded-full w-12 sm:w-14 h-12 sm:h-14 object-cover" />
                                                </div>
                                            ) : (
                                                <div className="flex items-center w-12 sm:w-14 h-12 sm:h-14 justify-center rounded-full border border-gray-100">
                                                    <span
                                                        className="material-symbols-outlined"
                                                        style={{ fontSize: '1.4rem' }}
                                                    >
                                                        person
                                                    </span>
                                                </div>
                                            )}
                                        </div>

                                        <div className="ml-3 sm:ml-10">
                                            <p className="font-semibold text-sm capitalize">{user.name}</p>
                                            <p>{user.email ? user.email : '-'}</p>
                                            <p>{user.phone ? user.phone : '-'}</p>
                                            <p>{user.address ? user.address : '-'}</p>

                                            <select
                                                disabled={currentUser.id === user.id || (currentUser.role === Roles.Admin && user.role === Roles.Superadmin)}
                                                value={userRoles && userRoles[user.id]}
                                                onChange={(e) => handleRoleChange(user.id, e.target.value)}
                                                className="p-1 w-10/12 bg-white rounded-md mt-2 h-8 text-gray-900 shadow-sm border focus:ring-2 focus:ring-inset focus:outline-none focus:ring-indigo-600 sm:leading-6 disabled:bg-gray-100 disabled:text-gray-400"

                                            >
                                                {(Object.values(Roles) as Array<keyof typeof Roles>)
                                                .map((val, idx) => {
                                                    return (
                                                        <Fragment key={idx}>
                                                            {(typeof val === 'string') && (
                                                                <option key={idx} value={val}>{t(`common:enums.roles.${Roles[val]}`)}</option>
                                                            )}
                                                        </Fragment>
                                                    )
                                                })}
                                            </select>
                                        </div>

                                        <p className="absolute right-2 bottom-2">{dateToLocaleDate(user.created_at)}</p>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                )}
                {isFetching && (
                    <div className="mx-auto mb-5 mt-1">
                        <Loader show={isFetching} size='small' />
                    </div>
                )}
                {!users || users.data.length < 1 && (
                    <div className="my-2">
                        <p className="text-sm ml-4 md:ml-5 font-semibold text-black">{t('page:properties.noUsersFound')}...</p>
                    </div>
                )}
                {users && users.meta && (
                    <div className="flex justify-center py-2">
                        <Pagination
                            count={pageCount}
                            page={page}
                            defaultPage={1}
                            showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                            showLastButton={pageCount ? pageCount > 5 : false}
                            hidePrevButton={!hasPrevPage}
                            hideNextButton={!hasNextPage}
                            onChange={handlePagination}
                        />
                    </div>
                )}
            </div>

            <Modal isOpen={showCopyModal} close={() => setShowCopyModal(false)} title={`${t('common:createUser')} (${t('common:duplicate')})`} size='sm' titleAlign='center'>
                <div>
                    <div className="w-10/12 sm:w-11/12 flex flex-wrap justify-center mx-auto mb-5">
                        <CreateUserFormAdmin user={selectedUser} />
                    </div>
                </div>
            </Modal>

            <Modal isOpen={showModal} close={() => setShowModal(false)} title={selectedUser ? selectedUser.name : t('common:edit')} size='sm' titleAlign='center'>
                <div>
                    <div className="w-10/12 sm:w-11/12 flex flex-wrap justify-center mx-auto mb-5">
                        <UpdateUserForm user={selectedUser} />

                        <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                        <UserPropertyAccess user={selectedUser} />

                        <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                        <UserPropertyBuildingAccess user={selectedUser} />

                        <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                        <UserPropertyObjectAccess user={selectedUser} />

                        <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                        <RevokeUserLogAccess user={selectedUser} />
                    </div>
                </div>
            </Modal>

            <ConfirmModal isOpen={showConfirmModal} close={() => setShowConfirmModal(false)} title={t('page:users.deleteUser')} titleAlign='center' type='warning'>
                <p className="text-lg font-semibold">{t('page:users.confirmDeleteUser')} {selectedUser?.name}?</p>
                <button
                    onClick={deleteUserConfirmed}
                    className="bg-status-critical hover:bg-status-critical-h py-2 px-6 mt-3 rounded mx-auto text-white font-semibold">{t('common:delete')}</button>
            </ConfirmModal>
        </div>
    )
}

export default UserTable;