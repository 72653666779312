import React, { useEffect, useRef, useState } from 'react'
import { useGetUsersQuery, useUpdateUserMutation, useUpdateUsersMutation } from '../../../../services/tenant/user/user.service';
import { UserDto } from '../../../../services/tenant/user/dto/user.dto';
import { UpdateUserDto } from '../../../../services/tenant/user/dto/update-user.dto';
import { NotificationManager } from 'react-notifications';
import { SubsidiaryDto } from '../../../../services/tenant/subsidiary/dto/subsidiary.dto';
import Select, { components } from 'react-select';
import InputCheckbox from '../../../atoms/select-checkbox';
import { useTranslation } from 'react-i18next';
import { PageDto } from '../../../../services/dto/page.dto';
import { Order } from '../../../../services/enum/order';
import { debounce } from '../../../../utils/debounce';
import { SelectCustomStyles } from '../../../atoms/select-custom-styles/select-custom-styles';
import PrimaryButton from '../../../atoms/primary-button';
import SecondaryButton from '../../../atoms/secondary-button';


type Props = {
    subsidiary?: SubsidiaryDto
}

type SelectOption = {
    label: string;
    value: string;
    selected: boolean;
    user_id: string;
}

const SubsidiaryUserAccess = ({ subsidiary }: Props) => {
    const { t } = useTranslation();

    const [updateUsers, response] = useUpdateUsersMutation();

    const [allOptions, setAllOptions] = useState<SelectOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
    const [search, setSearch] = useState<string>();
    const [page, setPage] = useState<number>(1);

    const debouncedSearch = useRef(debounce((search: string) => setSearch(search), 500)).current;

    // Get all users for current tenant
    const { data: users } = useGetUsersQuery({
        take: 15,
        page: page,
        order: Order.ASC,
        sortBy: 'name',
        search: search
    });

    useEffect(() => {
        if (users) {
            // INIT
            if (allOptions.length === 0) {
                initOptions(users);
            }
            // SEARCH
            else if (search) {
                handleSearchOptions(search, users);
            }
            // PAGINATION
            else {
                handlePagination(users);
            }
        }
    }, [users])

    // Set initial options and selected options
    const initOptions = (users: PageDto<UserDto>) => {
        const allOptions: SelectOption[] = [];
        const selectedOptions: SelectOption[] = [];
        if (users && allOptions.length === 0) {
            users.data.forEach(user => {
                let tempObj: SelectOption = {
                    label: user.name,
                    value: user.id,
                    selected: user.subsidiary_id === subsidiary?.id ? true : false,
                    user_id: user.id,
                };
                allOptions.push(tempObj);
                // When match between user and subsidiary id, push to selectedOptions
                if (tempObj.selected) {
                    selectedOptions.push(tempObj);
                }
            })
            setAllOptions(allOptions);
            setSelectedOptions(selectedOptions);
        }
    }

    // Handle options when search query is provided
    const handleSearchOptions = (search: string, users: PageDto<UserDto>) => {
        if (users && search) {
            const allOptions: SelectOption[] = [];
            const selectedOptions: SelectOption[] = [];
            setPage(1);
            users.data.forEach(user => {
                let tempObj: SelectOption = {
                    label: user.name,
                    value: user.id,
                    selected: user.subsidiary_id === subsidiary?.id ? true : false,
                    user_id: user.id,
                };
                allOptions.push(tempObj);
                // When match between user and subsidiary id, push to selectedOptions
                if (tempObj.selected) {
                    selectedOptions.push(tempObj);
                }
            })
            setAllOptions([...allOptions]);
            setSelectedOptions([...selectedOptions]);
        }
    }

    // Handling options with pagination
    const handlePagination = (users: PageDto<UserDto>) => {
        if (users) {
            // Get existing options
            const existingOptions = [...allOptions];
            // Get existing selected options
            const existingSelected = [...selectedOptions];

            // Remove duplicates
            const uniqueArr = existingOptions.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.user_id === value.user_id
                ))
            )

            // Add options
            users.data.forEach(user => {
                const obj = {
                    label: user.name,
                    value: user.id,
                    selected: user.subsidiary_id === subsidiary?.id ? true : false,
                    user_id: user.id,
                }
                // Make sure no duplicates are added
                if (!uniqueArr.some(e => e.user_id === obj.user_id)) {
                    uniqueArr.push(obj);
                    if (obj.selected && !existingSelected.some(e => e.user_id == obj.user_id)) {
                        existingSelected.push(obj);
                    }
                }
            });
            setAllOptions(uniqueArr);
            setSelectedOptions(existingSelected);
        }
    }

    // Handle scroll event to fetch more options
    const handleScroll = (event: any) => {
        if (users && users.meta.hasNextPage) {
            if (!search) {
                setPage(page ? page + 1 : 1);
            }
        }
    }

    // Selected option(s) will get passed
    const handleSelectChange = (options: any) => {

        const allSelectedOptions = [...selectedOptions]; // All selected options

        if (options.length > 0) {
            // If options array is shorter than selectedOptions array, a user has been removed
            if (options.length < allSelectedOptions.length) {

                allSelectedOptions.forEach((option: SelectOption) => {
                    // If current option not in passed options arr, remove user from selectedOptions array
                    if (!options.includes(option)) {
                        const index = allSelectedOptions.findIndex((e) => e.value === option.value);
                        if (index !== -1) {
                            allSelectedOptions.splice(index, 1);
                        }
                    }
                })
                setSelectedOptions(allSelectedOptions); // Update selectedOptions state
            }
            // If options array is not shorter than selectedOptions array, add option to selectedOptions array
            else {
                options.forEach((option: SelectOption) => {
                    // Add option if not already exists in allSelectedOptions array
                    if (!allSelectedOptions.includes(option)) {
                        let tempObj: SelectOption = {
                            label: option.label,
                            value: option.value,
                            // selected: true,
                            selected: option.selected,
                            user_id: option.user_id
                        };
                        allSelectedOptions.push(tempObj);
                    }
                });
                setSelectedOptions(allSelectedOptions);
            }
        }
        else {
            setSelectedOptions([])
        }
    }

    const prepareUpdate = () => {
        /* 
            Since we set an initial state of the selected option (true/false) we want to find
            all options with an initial state of true that are not in selectedOptions array
            since they will have an existing record in the database that needs to be removed
         */

        let updateArray: SelectOption[] = [];

        allOptions.forEach((option) => {
            // Check if selectedOptions includes an option from allOptions
            // If it does and option.selected = false, set selected = true so we know it should be added
            const includes = selectedOptions.some((e) => e.user_id === option.user_id);
            if (includes) {
                if (option.selected === false) {
                    option.selected = true; // Set selected = true
                    updateArray.push(option); // Add to updateArray
                }
            }

            // If current option.selected = true and it's not included in selectedOptions we set it to false
            // so we know it should be removed since an existing relation exists for this option
            else if (option.selected === true) {
                // If option.selected is true and it's not included in selectedOptions we want to set selected = false
                if (!selectedOptions.includes(option)) {
                    option.selected = false; // Set selected = false
                    updateArray.push(option); // Add to updatedArray
                }
            }
        })
        return updateArray;
    }

    const handleUpdateUserAccess = async () => {
        const updateArray = prepareUpdate();

        let userUpdateDtos: UpdateUserDto[] = [];

        // Loop through each user to be updated
        // If selected is true => set subsidiary_id
        // If selected if false => set subsidiary null
        updateArray.forEach((user) => {
            let tempObj: UpdateUserDto = {
                id: user.user_id,
                subsidiary_id: user.selected ? subsidiary?.id : null,
            }
            userUpdateDtos.push(tempObj);
        });

        try {
            const response = await updateUsers(userUpdateDtos).unwrap();
            setAllOptions([]);
            setSelectedOptions([]);
            setPage(1);

            if (response.success) {
                NotificationManager.success(t('common:notifications.updated'));
            }
            else {
                NotificationManager.info(t('common:notifications.updateFailed'));
            }

        }
        catch (error) {
            NotificationManager.error(t('common:notifications.updateFailed'))
        }
    }


    return (
        <div className="rounded-lg w-full mx-auto mb-2">
            <>
                <p className="my-2 text-xs sm:text-sm font-semibold">{t('page:properties.usersBelongsTo')} <span className="uppercase font-extrabold">{subsidiary?.company_name}</span></p>
                <Select
                    className="z-50 capitalize mb-2"
                    value={selectedOptions}
                    options={allOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(options) => handleSelectChange(options)}
                    controlShouldRenderValue={true}
                    isClearable={false}
                    backspaceRemovesValue={false}
                    maxMenuHeight={200}
                    placeholder={t('common:select')}
                    noOptionsMessage={() => t('page:properties.noUsersFound')}
                    components={{
                        // @ts-ignore
                        Option: InputCheckbox
                    }}
                    onInputChange={(value) => debouncedSearch(value)}
                    captureMenuScroll={true}
                    onMenuScrollToBottom={(val) => handleScroll(val)}
                    filterOption={null}
                    styles={SelectCustomStyles}
                />
                <SecondaryButton
                    text={t('common:update')}
                    size={'medium'}
                    onClick={handleUpdateUserAccess}
                    wide={true}
                    align={'center'}
                />
            </>
        </div>
    )
}

export default SubsidiaryUserAccess;