import { PageOptionsDto } from "../../dto/page-options.dto";
import { PageDto } from "../../dto/page.dto";
import { reAuthAPI } from "../../re-auth/re-auth";
import { ContractorLogAccessDto } from "../contractor-log-user-access/dto/contractor-log-access.dto";
import { CreatePropertyOwnerContractorLogAccessDto } from "./dto/create-property-owner-contractor-log-access.dto";
import { PropertyOwnerContractorLogAccessDto } from "./dto/property-owner-contractor-log-access.dto";
import { UniquePropertyOwnerEntitiesDto } from "./dto/unique-property-owner-entities.dto";



export const propertyOwnerContractorLogAccessAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Create propertyOwnerContractorLogAccess
        createPropertyOwnerContractorLogAccess: builder.mutation<any, CreatePropertyOwnerContractorLogAccessDto>({
            query: (record) => ({
                url: '/property-owner-contractor-log-access',
                method: 'POST',
                body: record,
            }),
            invalidatesTags: ['PropertyOwnerContractorLogAccess']
        }),
        // Get all propertyOwnerContractorLogAccess relations
        getAllPropertyOwnerContractorLogAccess: builder.query<PropertyOwnerContractorLogAccessDto[], void>({
            query: () => ({
                url: `/property-owner-contractor-log-access`,
                method: 'GET',
            }),
            providesTags: ['PropertyOwnerContractorLogAccess']
        }),
        // Get all propertyOwnerContractorLogAccess relations by contractor ids
        getAllPropertyOwnerContractorLogAccessByIds: builder.query<PropertyOwnerContractorLogAccessDto[], string[]>({
            query: (ids) => ({
                url: '/property-owner-contractor-log-access/contractors/ids?ids=' + ids,
                method: 'GET',
            }),
            providesTags: ['PropertyOwnerContractorLogAccess']
        }),
        // Delete propertyOwnerContractorLogAccess by given id
        deletePropertyOwnerContractorLogAccess: builder.mutation<any, string>({
            query: (id) => ({
                url: '/property-owner-contractor-log-access/' + id,
                method: 'DELETE',
                body: id
            }),
            invalidatesTags: ['PropertyOwnerContractorLogAccess']
        }),

        /* As contractor */

        // Get all property log templates by access
        getAllLogAccessByTenantId: builder.query<PageDto<ContractorLogAccessDto>, { pageOptions: PageOptionsDto, tenantId: string }>({
            query: (options) => {
                const queryParamsPageOptions = [];
                if (options.pageOptions.page !== undefined) queryParamsPageOptions.push(`page=${options.pageOptions.page}`);
                if (options.pageOptions.take !== undefined) queryParamsPageOptions.push(`take=${options.pageOptions.take}`);
                if (options.pageOptions.order !== undefined) queryParamsPageOptions.push(`order=${options.pageOptions.order}`);
                if (options.pageOptions.search !== undefined) queryParamsPageOptions.push(`search=${options.pageOptions.search}`);
                if (options.pageOptions.sortBy !== undefined) queryParamsPageOptions.push(`sort_by=${options.pageOptions.sortBy}`);
                if (options.pageOptions.filter !== undefined) queryParamsPageOptions.push(`filter=${options.pageOptions.filter}`);

                const queryStringPageOptions = queryParamsPageOptions.length > 0 ? `?${queryParamsPageOptions.join('&')}` : '';

                return {
                    url: `/property-owner-contractor-log-access/logs/all/${options.tenantId}${queryStringPageOptions}`,
                    method: 'GET',
                }
            },
            providesTags: ['PropertyOwnerContractorLogAccess']
        }),
        // Get all unique entities by log access (properties, buildings, objects)
        getUniqueEntitiesLogAccessByTenantId: builder.query<UniquePropertyOwnerEntitiesDto[], string>({
            query: (tenantId) => ({
                url: `/property-owner-contractor-log-access/logs/entities/${tenantId}`,
                method: 'GET',
            }),
            providesTags: ['PropertyOwnerContractorLogAccess']
        }),

    })
})

export const {
    useCreatePropertyOwnerContractorLogAccessMutation,
    useGetAllPropertyOwnerContractorLogAccessQuery,
    useGetAllPropertyOwnerContractorLogAccessByIdsQuery,
    useDeletePropertyOwnerContractorLogAccessMutation,
    /* As contractor */
    useGetAllLogAccessByTenantIdQuery,
    useGetUniqueEntitiesLogAccessByTenantIdQuery,


} = propertyOwnerContractorLogAccessAPI;