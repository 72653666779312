import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';

type LanguageInfo = {
    nativeName: string;
}

type Languages = {
    [key: string]: LanguageInfo
}

type Props = {
    position?: string;
}

const LanguageSelector = ({
    position = 'absolute'
}: Props) => {

    const { t, i18n } = useTranslation();

    const currentLanguage = i18n.language;

    const lngs: Languages = {
        en: { nativeName: 'English' },
        se: { nativeName: 'Swedish' }
    }

    const [expandLanguages, setExpandLanguages] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleChangeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        setExpandLanguages(false);
    }

    const handleClickOutsideDropdown = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setExpandLanguages(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideDropdown);

        return () => {
            document.removeEventListener('click', handleClickOutsideDropdown);
        }
    }, [])

    return (
        <div className={`${position === 'absolute' ? 'absolute right-0 top-0' : ''} rounded-sm bg-white px-1 z-[100]`} ref={dropdownRef}>
            <div className="flex justify-center py-1 cursor-pointer" onClick={() => setExpandLanguages(!expandLanguages)}>
                <img src={require(`../../../assets/flags/flag-${i18n.resolvedLanguage}.png`)} alt='Language' width='24' />
            </div>
            {expandLanguages && (
                <div className="flex flex-col border-t py-1 rounded-br rounded-bl">
                    {
                        Object.keys(lngs).map((lng) => (
                            <button key={lng} type="submit" onClick={() => handleChangeLanguage(lng)} className={`${i18n.resolvedLanguage === lng ? 'font-bold' : 'font-normal'} my-1 mx-auto`}>
                                <img src={require(`../../../assets/flags/flag-${lng}.png`)} alt={lngs[lng].nativeName} width='24' />
                            </button>
                        ))

                    }
                </div>
            )}

            {/* <div className="flex flex-col float-right mr-5" >
                {
                    Object.keys(lngs).map((lng) => (
                        <button key={lng} type="submit" onClick={() => i18n.changeLanguage(lng)} className={`${i18n.resolvedLanguage === lng ? 'font-bold' : 'font-normal'}`}>
                            {lngs[lng].nativeName}
                        </button>
                    ))
                }
            </div> */}
        </div>
    )
}

export default LanguageSelector;