import React, { Fragment, useEffect, useState } from 'react'
import { dateToLocaleDateAndHour } from '../../../../../utils/date-time';
import { getFile } from "../../../../../services/tenant/media/media.service";
import Modal from '../../../../molecules/modal';
import Loader from '../../../../atoms/loader/loader';
import { ContractorLogAccessDto } from '../../../../../services/tenant/contractor-log-user-access/dto/contractor-log-access.dto';
import { useTranslation } from 'react-i18next';
import { LogEntryDto } from '../../../../../services/tenant/log-entries/dto/log-entry.dto';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetExternalUserByIdQuery } from '../../../../../services/external/common/common.service';
import { useAppSelector } from '../../../../../redux/hooks';
import { getCurrentUser } from '../../../../../redux/slices/user.slice';
import { getTenant } from '../../../../../redux/slices/tenant.slice';
import { TenantTypes } from '../../../../../services/enum/tenant-types';
import SecondaryButton from '../../../../atoms/secondary-button';
import PrimaryButton from '../../../../atoms/primary-button';
import { Roles } from '../../../../../services/enum/roles';
import ConfirmModal from '../../../../molecules/confirm-modal';
import { useArchiveLogEntryMutation, useGetLogEntryDetailsQuery, useRestoreLogEntryMutation, useUpdateCustomLogEntryMutation } from '../../../../../services/tenant/log-entries/log.service';
import { NotificationManager } from 'react-notifications';
import { LogTemplateDto } from '../../../../../services/tenant/log-template/dto/log-template.dto';
import { Controller, useForm } from 'react-hook-form';
import Checkbox from '../../../../atoms/checkbox';
import { EntityLogDto } from '../../../../../services/tenant/entity-log/dto/entity-log.dto';

type FileType = {
    fileType: string;
    url: string;
}

type Props = {
    log: LogEntryDto;
    setShowExpandLog: (flag: boolean) => void;
    logTemplate: LogTemplateDto;
    tenantLog?: ContractorLogAccessDto; // When contractor is accessing logs
    entityLog?: EntityLogDto;
}

const ExpandLog = ({
    log,
    setShowExpandLog,
    logTemplate,
    tenantLog,
    entityLog,
}: Props) => {
    const { t } = useTranslation();
    const { currentUser } = useAppSelector(getCurrentUser);
    const { tenant } = useAppSelector(getTenant);

    const [updateLog] = useUpdateCustomLogEntryMutation();

    const [archiveLog, { isLoading: archiveIsLoading }] = useArchiveLogEntryMutation();
    const [restoreLog, { isLoading: restoreIsLoading }] = useRestoreLogEntryMutation();

    const [logEntry, setLogEntry] = useState<LogEntryDto>(log);

    // Hold all file names
    const [files, setFiles] = useState<string[]>([]);
    const [fetchedFiles, setFetchedFiles] = useState<FileType[]>([]);

    const [expandImage, setExpandImage] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<FileType>();
    // Don't try to render image or video if no files are present
    const [noFilesToFetch, setNoFilesToFetch] = useState<boolean>(false);
    const [containFiles, setContainFiles] = useState<boolean>(false);
    const [externalUserDisplay, setExternalUserDisplay] = useState<string>();
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [isEditable, setIsEditable] = useState<boolean>(false);

    const [skipTokenTenantIdUserId, setSkipTokenTenantIdUserId] = useState<any>(skipToken);
    const { data: externalUser } = useGetExternalUserByIdQuery(skipTokenTenantIdUserId);

    // Only fetch log entry with details if log is editable (set in entity log)
    const [skipTokenLogIsEditable, setSkipTokenLogIsEditable] = useState<any>(skipToken);
    const { data: logEntryWithDetails } = useGetLogEntryDetailsQuery(skipTokenLogIsEditable);

    useEffect(() => {
        if (logEntryWithDetails && logEntryWithDetails.id) {
            setLogEntry(logEntryWithDetails);
        }
        else if (!logEntryWithDetails && log) {
            setLogEntry(log)
        }

    }, [log, logEntryWithDetails])

    // Fetch files if log contains any files
    useEffect(() => {
        let fileNames: string[] = [];

        log.log_data.forEach((item) => {
            if (item.type === 'file') {
                setContainFiles(true);
                item.data.forEach((file: string) => {
                    fileNames.push(file);
                })
            }
        })

        if (fetchedFiles.length < 1 && fileNames.length > 0) {
            handleGetFiles(fileNames);
        }
        else {
            setNoFilesToFetch(true);
        }

    }, [log])


    // If external user created log, fetch user name from contractor db
    useEffect(() => {
        if (
            log &&
            log.tenant_id_contractor &&
            log.user_id &&
            !log.user_name
        ) {
            setSkipTokenTenantIdUserId({
                tenantId: log.tenant_id_contractor,
                userId: log.user_id
            })
        }
    }, [log])

    // Fetch detailed logs if log is editable
    useEffect(() => {
        if (entityLog && entityLog.is_editable) {
            setSkipTokenLogIsEditable(log.id);
        }

    }, [entityLog])

    const handleGetFiles = async (files: string[]) => {
        const allFetchedFiles: FileType[] = [];
        await Promise.all(files.map(async (file) => {
            try {
                let fileResponse;
                if (tenantLog) {
                    fileResponse = await getFile(file, tenantLog.tenant_id_property_owner);
                }
                else {
                    fileResponse = await getFile(file);
                }

                if (fileResponse.data) {
                    const fileUrl = URL.createObjectURL(fileResponse.data);
                    allFetchedFiles.push({
                        fileType: fileResponse.data.type,
                        url: fileUrl
                    });
                }
                else if (fileResponse.response && fileResponse.response.status === 404) {
                    // FIX: Handle
                }
            }
            catch (error) {
                // FIX: Handle
            }
        }))
        setFetchedFiles(allFetchedFiles);
    }

    useEffect(() => {
        if (externalUser) {
            if (tenant.type === TenantTypes.Property_Owner) {
                setExternalUserDisplay(`
                ${externalUser.name} (${externalUser.company_name ?? t('common:external')})
                `)
            }
            else if (tenant.type === TenantTypes.Contractor && externalUser.tenant_id !== tenant.id) {
                // We are showing user from external contractor company
                setExternalUserDisplay(`
                    ${externalUser.company_name ? `${externalUser.company_name} (${t('common:external')})` : t('common:external')}
                    `)
            }
        }

    }, [externalUser])

    const handleArchiveLog = async () => {
        if (currentUser.tenant_id && log.id) {
            try {
                const response = await archiveLog(log.id).unwrap();
                if (response.success) {
                    NotificationManager.success(t('page:logs.logArchived'));
                    setShowConfirmModal(false);
                    setShowExpandLog(false);
                }
                else {
                    NotificationManager.error(t('page:logs.logNotArchived'));
                }
            }
            catch (error) {
                NotificationManager.error(t('page:logs.logNotArchived'));
            }
        }
    };

    const handleRestoreLog = async () => {
        if (currentUser.tenant_id && log.id) {
            try {
                const response = await restoreLog(log.id).unwrap();
                if (response.success) {
                    NotificationManager.success(t('page:logs.logRestored'));
                    setShowConfirmModal(false);
                    setShowExpandLog(false);
                }
                else {
                    NotificationManager.error(t('page:logs.logNotRestored'));
                }
            }
            catch (error) {
                NotificationManager.error(t('page:logs.logNotRestored'));
            }
        }
    };

    const { control, handleSubmit, setValue, getValues, watch } = useForm({
        defaultValues: log.log_data.reduce((acc: any, entry: any) => {
            acc[entry.name] = {
                data: entry.data,
                updated_at: entry.updated_at ?? null,
                updated_by: entry.updated_by ?? null
            }
            return acc;
        }, {})
    });

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === 'change') {
                // @ts-ignore
                // Retrieve the entire current object for the field
                const currentFieldData = getValues(name);

                // Check if the actual data has changed and not just the metadata
                const originalData = log.log_data.find(entry => entry.name === name)?.data;

                if ((currentFieldData.data !== originalData) && name) {
                    setValue(name, {
                        ...currentFieldData,
                        updated_at: new Date().toISOString(),
                        updated_by: currentUser.id
                    })
                }
            }
        });

        return () => subscription.unsubscribe();

    }, [watch, setValue, getValues, currentUser.id, log.log_data])

    const onSubmit = async (formData: any) => {
        const reconstructredLogData = logTemplate.field_definitions.map(definition => {
            const fieldData = formData[definition.name];

            const updatedData = {
                ...definition,
                data: fieldData.data
            }

            // Only add updated_at and updated_by if they are not null
            if (fieldData.updated_at !== null) {
                // @ts-ignore
                updatedData.updated_at = fieldData.updated_at;
            }
            if (fieldData.updated_by !== null) {
                // @ts-ignore
                updatedData.updated_by = fieldData.updated_by;
            }

            return updatedData
        });

        try {
            const response = await updateLog({ id: log.id, log_data: reconstructredLogData }).unwrap();
            if (response.success) {
                NotificationManager.success(t('page:logs.logUpdated'));
            }
            else {
                NotificationManager.error(t('page:logs.logNotUpdated'));
            }
        }
        catch (error) {
            NotificationManager.error(t('page:logs.logNotUpdated'));
        }

    }

    // Determine what log to render
    const logDataToDisplay = logEntryWithDetails || log

    return (
        <>
            <div className="flex flex-col min-h-[25vh] text-black">
                <div className={`flex flex-col md:flex-row md:gap-x-10 p-5`}>
                    <div className="w-full md:w-6/12 mt-2">
                        {isEditable ? (
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {logTemplate.field_definitions.map((item, idx) => (
                                    <div key={idx} className="my-3">
                                        <Controller
                                            name={item.name}
                                            control={control}
                                            // @ts-ignore
                                            render={({ field }) => {
                                                const handleChange = (event: any) => {
                                                    const newValue = {
                                                        ...field.value,
                                                        data: event.target.value,
                                                    };
                                                    field.onChange(newValue);
                                                }

                                                switch (item.type) {
                                                    case 'text':
                                                        return (
                                                            <div className="flex flex-col">
                                                                <label>{field.name}</label>
                                                                <input {...field} onChange={handleChange} value={field.value.data || ''} type="text" className="placeholder:capitalize w-full text-base rounded-md border-0 p-2.5 sm:py-2 h-12 sm:h-10 text-gray-900 bg-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                                            </div>
                                                        )
                                                    case 'checkbox':
                                                        return <Checkbox checked={field.value.data} onChange={(e) => handleChange({ target: { value: e.target.checked } })} label={field.name} size='lg' />
                                                    case 'select':
                                                        return (
                                                            <>
                                                                <label>{field.name}</label>
                                                                <select {...field} onChange={handleChange} value={field.value.data || ''} className="w-full rounded-md border h-12 sm:h-10 p-2.5 sm:py-2 text-gray-900 bg-white shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                                    {item.options?.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </>
                                                        );
                                                    case 'date':
                                                        return (
                                                            <div className="flex flex-col">
                                                                <label>{field.name}</label>
                                                                <input {...field} onChange={handleChange} value={field.value.data || ''} type="date" className="placeholder:capitalize w-full text-base rounded-md border-0 p-2.5 sm:py-2 h-12 sm:h-10 text-gray-900 bg-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                                            </div>
                                                        )
                                                    case 'file':
                                                        return (
                                                            <>
                                                                <label>{field.name}</label>
                                                                <input type='file' id='file' accept={'image/*, video/*'} multiple={true} hidden />
                                                                <label htmlFor='file' className="block w-fit bg-yellow text-black p-2 rounded-md">
                                                                    Välj filer
                                                                </label>
                                                            </>
                                                        )
                                                    default:
                                                        return null;
                                                }
                                            }}
                                        />
                                    </div>
                                ))}
                                <PrimaryButton
                                    text={t('common:update')}
                                    size='medium'
                                    align='center'
                                    wide={true}
                                    font='semibold'
                                />

                            </form>
                        ) : (
                            <>
                                {logDataToDisplay.log_data.map((item, idx) => {
                                    if (item.type === 'text' && item.name !== 'created_by') {
                                        return (
                                            <div key={idx} className="flex w-full py-3">
                                                <div className="flex flex-col">
                                                    <div className="flex items-center gap-x-2">
                                                        <span
                                                            className="font-semibold capitalize">
                                                            {item.name}:
                                                        </span>
                                                        <span>
                                                            {item.data ? item.data : '-'}
                                                        </span>
                                                    </div>
                                                    <div className="mt-[-5px]">
                                                        {entityLog?.is_editable && item.updated_at && (
                                                            <span
                                                                className="text-xs">
                                                                ({t('common:updated')} {dateToLocaleDateAndHour(item.updated_at)}, {item.updated_by_name})
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else if (item.type === 'checkbox') {
                                        return (
                                            <div key={idx} className="flex w-full py-3">
                                                <div className="flex flex-col">
                                                    <div className="flex items-center gap-x-2">
                                                        <span className="font-semibold capitalize">{item.name}:</span>
                                                        <span className={`${item.data ? 'text-green-600' : 'text-red-700'} material-symbols-outlined `} style={{ fontSize: '1.4rem' }}>
                                                            {item.data === true ? 'check_circle' : 'cancel'}
                                                        </span>
                                                    </div>
                                                    <div className="mt-[-5px]">
                                                        {entityLog?.is_editable && item.updated_at && (
                                                            <span
                                                                className="text-xs">
                                                                ({t('common:updated')} {dateToLocaleDateAndHour(item.updated_at)}, {item.updated_by_name})
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else if (item.type === 'select') {
                                        return (
                                            <div key={idx} className="flex w-full py-3">
                                                <div className="flex flex-col">
                                                    <div className="flex items-center gap-x-2">
                                                        <span className="font-semibold capitalize">{item.name}:</span>
                                                        <span>{item.data}</span>
                                                    </div>
                                                    <div className="mt-[-5px]">
                                                        {entityLog?.is_editable && item.updated_at && (
                                                            <span
                                                                className="text-xs">
                                                                ({t('common:updated')} {dateToLocaleDateAndHour(item.updated_at)}, {item.updated_by_name})
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else if (item.type === 'date' && item.name !== 'created_at' && item.name !== 'deleted_at') {
                                        return (
                                            <div key={idx} className="flex w-full py-3">
                                                <div className="flex flex-col">
                                                    <div className="flex items-center gap-x-2">
                                                        <span
                                                            className="font-semibold capitalize">
                                                            {item.name.split('_').join(' ')}:
                                                        </span>
                                                        <span>{dateToLocaleDateAndHour(item.data)}</span>
                                                    </div>
                                                    <div className="mt-[-5px]">
                                                        {entityLog?.is_editable && item.updated_at && (
                                                            <span
                                                                className="text-xs">
                                                                ({t('common:updated')} {dateToLocaleDateAndHour(item.updated_at)} {item.updated_by_name && ', ' + item.updated_by_name})
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </>
                        )}

                    </div>

                    {containFiles && (
                        // Render images
                        <div className="flex flex-col w-full md:w-6/12 mt-2">
                            {log.log_data.map((item, idx) => {
                                if (item.type === 'file' && !noFilesToFetch) {
                                    return (
                                        <div key={idx} className="">
                                            <p className="font-semibold">{item.name}</p>
                                            <div className="flex flex-wrap gap-x-1 w-full">
                                                {/* File urls exist and finished loading */}
                                                {item.data.length > 0 && fetchedFiles.length > 0} {
                                                    fetchedFiles.map((file, idx) => {
                                                        if (file.fileType.startsWith('video/')) {
                                                            return (
                                                                <video key={idx} controls className="border-2 w-5/12 rounded-lg border-gray-200 my-1 cursor-zoom-in" onClick={() => setSelectedImage(file)}>
                                                                    <source src={file.url} type={file.fileType} />
                                                                    {/* Your browser does not support the video tag. */}
                                                                </video>
                                                            )
                                                        }
                                                        else if (file.fileType.startsWith('image/')) {
                                                            return (
                                                                <img key={idx} src={file.url} className="w-5/12 rounded-lg my-1 cursor-zoom-in" onClick={() => setSelectedImage(file)} />
                                                            )
                                                        }
                                                    })
                                                }
                                                {/* File urls exist but haven't fetched yet */}
                                                {item.data.length > 0 && fetchedFiles.length < 1} {
                                                    <Loader show={fetchedFiles.length < 1} size='small' />
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    )}
                </div>

                <div className="flex grow items-end px-5 sm:px-0">
                    <div className="w-full flex flex-col lg:flex-row text-sm border-t-2 border-primary-blue-5 py-3">
                        <div className="flex flex-col items-center lg:items-start lg:flex-row w-full lg:gap-x-3 gap-y-3">
                            <div className="flex flex-col items-center lg:items-start gap-y-1">
                                <span className="font-bold">{t('common:createdBy')}</span>
                                <span className="font-semibold">
                                    {
                                        externalUser ?
                                            externalUserDisplay :
                                            log.log_data.find(e => e.name === 'created_by')?.data
                                    }
                                </span>
                            </div>
                            <div className="flex flex-col items-center lg:items-start gap-y-1">
                                <span className="font-bold">{t('page:logs.logDate')}</span>
                                <span className="font-semibold">{dateToLocaleDateAndHour(log.log_data.find(e => e.name === 'created_at')?.data)}</span>
                            </div>
                            {log.log_data.some(e => e.name === 'deleted_at' && e.data !== null) && (
                                <div className="flex flex-col items-center lg:items-start gap-y-1">
                                    <span className="font-bold">{t('common:archived')}</span>
                                    <span className="font-semibold">{dateToLocaleDateAndHour(log.log_data.find(e => e.name === 'deleted_at')?.data)}</span>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col lg:flex-row items-center gap-y-1.5 w-full justify-center lg:justify-end mt-5 lg:mt-0 lg:gap-x-1">
                            {entityLog?.is_editable && (
                                <SecondaryButton
                                    text={isEditable ? t('common:cancel') : t('common:edit')}
                                    size={'medium'}
                                    wide={true}
                                    font={'semibold'}
                                    onClick={() => setIsEditable(!isEditable)}
                                />
                            )}

                            {/* CONTRACTOR SKA INTE KUNNA TA BORT LOGG SOM TILLHÖR PROPERTY OWNER  */}

                            {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin && currentUser.tenant_id === log.tenant_id) && (
                                <>
                                    {log.deleted_at ? (
                                        <PrimaryButton
                                            text={t('common:restore')}
                                            size={'medium'}
                                            wide={true}
                                            font={'semibold'}
                                            onClick={() => handleRestoreLog()}
                                        />
                                    ) : (
                                        <PrimaryButton
                                            text={t('common:archive')}
                                            size={'medium'}
                                            wide={true}
                                            font={'semibold'}
                                            color={'red'}
                                            onClick={() => setShowConfirmModal(true)}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <Modal isOpen={selectedImage ? true : false} close={() => setSelectedImage(undefined)} size='xl' title='' titleAlign='center'>
                    <div className="flex justify-center">
                        {selectedImage?.fileType.startsWith('video/') && (
                            // <video key={idx} src={file.url} className="border-2 w-5/12 rounded-lg border-gray-200 my-1 cursor-zoom-in" onClick={() => setSelectedImage(file)} />
                            <video controls className="cursor-zoom-out" onClick={() => setSelectedImage(undefined)}>
                                <source src={selectedImage?.url} type={selectedImage?.fileType} />
                                Your browser does not support the video tag.
                            </video>
                        )}
                        {selectedImage?.fileType.startsWith('image/') && (
                            <img src={selectedImage.url} className="cursor-zoom-out" onClick={() => setSelectedImage(undefined)} />
                        )}
                    </div>
                </Modal>

                <ConfirmModal isOpen={showConfirmModal} close={() => setShowConfirmModal(false)} title={''} titleAlign='center' type='warning'>
                    <div>
                        <h3 className="font-semibold text-lg">{t('common:areYouSureYouWantTo')} {t('common:archive')} {t('page:logs.theLog')}?</h3>
                        <div className="flex justify-center gap-x-2 mt-10">
                            <PrimaryButton
                                text={t('common:archive')}
                                size={'medium'}
                                color={'red'}
                                font={'semibold'}
                                wide={true}
                                onClick={() => handleArchiveLog()}
                            />
                            <SecondaryButton
                                text={t('common:cancel')}
                                size={'medium'}
                                font={'semibold'}
                                wide={true}
                                onClick={() => setShowConfirmModal(false)}
                            />
                        </div>
                    </div>
                </ConfirmModal>
            </div>
        </>
    )
}

export default ExpandLog;