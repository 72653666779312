import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { getAuthenticatedUser } from '../../../../../redux/slices/auth.slice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { managementAPI, useLogoutQuery } from '../../../../../services/management/management.service';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import logo from '../../../../../assets/logo/logo-pink.png';
import { getCurrentUser } from '../../../../../redux/slices/user.slice';
import { getTenant } from '../../../../../redux/slices/tenant.slice';
import { useTranslation } from 'react-i18next';
import { Roles } from '../../../../../services/enum/roles';

type Props = {
    showNav: boolean,
    setShowNav: (val: boolean) => void;
}

const SideNav = ({
    showNav,
    setShowNav
}: Props) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const [logoutTrigger, setLogoutTrigger] = useState<any>(skipToken);
    const { data: logout } = useLogoutQuery(logoutTrigger);

    const { authUser } = useAppSelector(getAuthenticatedUser);
    const { currentUser } = useAppSelector(getCurrentUser);
    const { tenant } = useAppSelector(getTenant);

    const location = useLocation();

    // Reload page after logout result is returned
    useEffect(() => {
        if (logout) {
            if (logout.success) {
                window.location.reload();
            }
        }
    }, [logout])

    const handleLogout = () => {
        localStorage.setItem('user', ''); // Reset user in localstorage 
        localStorage.setItem('path', ''); // Reset path in localstorage
        setLogoutTrigger(true); // Call logout function
        dispatch({ type: 'RESET_APP' }); // Reset redux state
    }

    return (
        <div className={`w-full h-full z-[99] bg-white rounded-lg text-sm`}>
            <button
                className={`transition-all material-symbols-outlined absolute bg-primary-blue-15 rounded-tr-md rounded-br-md text-white h-20 w-7 mt-[calc(44dvh)] ${showNav ? 'ml-[258px] xl:ml-[308px]' : 'ml-[58px] xl:ml-[83px]'}`}
                style={{ fontSize: '2rem' }}
                onClick={() => setShowNav(!showNav)}>
                {showNav ? 'chevron_left' : 'chevron_right'}
            </button>

            <div className="flex flex-col h-full">
                {/* AUTHENTICATED */}
                {(authUser && authUser.user_id) ? (
                    <>
                        <div className="flex flex-col items-center pt-10">
                            <NavLink to="/profile" className="">
                                {(currentUser && tenant && tenant.logo) ? (
                                    <img
                                        src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${tenant.id}/${tenant.logo}`}
                                        alt="Company logo"
                                        className="w-10 h-10 object-contain rounded-md"
                                    />
                                ) : (
                                    <span
                                        className="material-symbols-outlined"
                                        style={{ fontSize: '2.5rem' }}>
                                        work
                                    </span>
                                )}
                            </NavLink>
                            {showNav && (
                                <p className="font-bold">{tenant?.name}</p>
                            )}
                        </div>

                        <div className="flex flex-col items-center gap-y-2 pt-14">
                            <NavLink
                                to="/home"
                                className={`flex items-center gap-x-4 w-10/12 text-center py-2 rounded-md ${location.pathname === '/home' && 'text-primary-blue bg-secondary-blue'} ${showNav ? 'pl-5 pr-2' : 'justify-center'}`}
                                title={t('common:homePage')}>
                                <button className="material-symbols-outlined">dashboard</button>
                                {showNav && (
                                    <span>{t('common:homePage')}</span>
                                )}
                            </NavLink>

                            {/* USER ROLE  */}
                            {currentUser.role === Roles.User && (
                                <>
                                    <NavLink
                                        to="/logs"
                                        className={`flex items-center gap-x-4 w-10/12 py-2 rounded-md ${location.pathname === '/logs' && 'text-primary-blue bg-secondary-blue'} ${showNav ? 'pl-5 pr-2' : 'justify-center'}`}
                                        title={t('common:logs')}
                                    >
                                        <button className="material-symbols-outlined">note_stack</button>
                                        {showNav && (
                                            <span>{t('common:logs')}</span>
                                        )}
                                    </NavLink>
                                </>
                            )}


                            {/* ADMIN AND SUPERADMIN  */}
                            {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                                <>
                                    <NavLink
                                        to="/connections"
                                        className={`flex items-center gap-x-4 w-10/12 py-2 rounded-md ${location.pathname === '/connections' && 'text-primary-blue bg-secondary-blue'} ${showNav ? 'pl-5 pr-2' : 'justify-center'}`}
                                        title={t('common:connections')}
                                    >
                                        <button className="material-symbols-outlined">person_search</button>
                                        {showNav && (
                                            <span>{t('common:connections')}</span>
                                        )}
                                    </NavLink>
                                    <NavLink
                                        to="/rights"
                                        className={`flex items-center gap-x-4 w-10/12 py-2 rounded-md ${location.pathname === '/rights' && 'text-primary-blue bg-secondary-blue'} ${showNav ? 'pl-5 pr-2' : 'justify-center'}`}
                                        title={t('common:rights')}>
                                        <button className="material-symbols-outlined">account_tree</button>
                                        {showNav && (
                                            <span>{t('common:rights')}</span>
                                        )}
                                    </NavLink>
                                    <NavLink
                                        to="/logs"
                                        className={`flex items-center gap-x-4 w-10/12 py-2 rounded-md ${location.pathname === '/logs' && 'text-primary-blue bg-secondary-blue'} ${showNav ? 'pl-5 pr-2' : 'justify-center'}`}
                                        title={t('common:logs')}>
                                        <button className="material-symbols-outlined">note_stack</button>
                                        {showNav && (
                                            <span>{t('common:logs')}</span>
                                        )}
                                    </NavLink>
                                    <NavLink
                                        to="/properties"
                                        className={`flex items-center gap-x-4 w-10/12 py-2 rounded-md ${location.pathname.startsWith('/properties') && 'text-primary-blue bg-secondary-blue'} ${showNav ? 'pl-5 pr-2' : 'justify-center'}`}
                                        title={t('common:properties')}>
                                        <button className="material-symbols-outlined">home_work</button>
                                        {showNav && (
                                            <span>{t('common:properties')}</span>
                                        )}
                                    </NavLink>
                                    <NavLink
                                        to="/users"
                                        className={`flex items-center gap-x-4 w-10/12 py-2 rounded-md ${location.pathname === '/users' && 'text-primary-blue bg-secondary-blue'} ${showNav ? 'pl-5 pr-2' : 'justify-center'}`}
                                        title={t('common:users')}>
                                        <button className="material-symbols-outlined">group</button>
                                        {showNav && (
                                            <span>{t('common:users')}</span>
                                        )}
                                    </NavLink>
                                    <NavLink
                                        to="/company"
                                        className={`flex items-center gap-x-4 w-10/12 py-2 rounded-md ${location.pathname === '/company' && 'text-primary-blue bg-secondary-blue'} ${showNav ? 'pl-5 pr-2' : 'justify-center'}`}
                                        title={t('common:company')}>
                                        <button className="material-symbols-outlined">work</button>
                                        {showNav && (
                                            <span>{t('common:company')}</span>
                                        )}
                                    </NavLink>
                                </>
                            )}
                        </div>

                        {/* ALL AUTHENTICATED */}
                        <div className="flex flex-col items-center flex-1 pt-10">
                            <NavLink
                                to="/profile"
                                className={`flex items-center gap-x-4 w-10/12 py-2 rounded-md ${location.pathname === '/profile' && 'text-primary-blue bg-secondary-blue'} ${showNav ? 'pl-5 pr-2' : 'justify-center'}`}
                                title={t('common:profile')}>
                                <button className="material-symbols-outlined">settings</button>
                                {showNav && (
                                    <span>{t('common:settings')}</span>
                                )}
                            </NavLink>
                        </div>

                        <div className={`flex pb-3 mx-auto text-sm ${showNav ? 'w-11/12' : 'w-10/12'}`}>
                            <div className={`rounded-md w-full flex box-border ${showNav ? 'bg-primary-blue-5 flex-row p-2' : 'flex-col items-center gap-y-3'}`}>
                                <NavLink to="/profile" className="">
                                    {currentUser && currentUser.profile_picture ? (
                                        <img
                                            src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${currentUser.tenant_id}/${currentUser.profile_picture}`}
                                            alt="Profile picture"
                                            className="w-10 h-10 object-cover rounded-md"
                                        />
                                    ) : (
                                        <span
                                            className="material-symbols-outlined mt-1"
                                            style={{ fontSize: '2rem' }}>
                                            person
                                        </span>
                                    )}
                                </NavLink>
                                {showNav && (
                                    <NavLink to="/profile" className="flex-grow ml-5">
                                        {(currentUser && currentUser.name.split(' ').length > 0) ? (
                                            <div className="flex flex-col justify-center h-full">
                                                <p className="font-normal">{currentUser.name.split(' ')[0]}</p>
                                                <p className="font-normal">{currentUser.name.split(' ').slice(1)}</p>
                                            </div>
                                        ) : (
                                            <span className="font-normal">{currentUser.name}</span>
                                        )}
                                    </NavLink>
                                )}

                                <div className="flex flex-1 justify-end">
                                    <button
                                        className="material-symbols-outlined bg-primary-blue-10 hover:bg-primary-blue-15 p-2 rounded-md"
                                        title={t('common:logout')}
                                        onClick={() => handleLogout()}
                                    >logout</button>
                                </div>
                            </div>
                        </div>
                    </>
                    // NOT AUTHENTICATED
                ) : (
                    <>
                        <div className="w-full">
                            <NavLink to="/home" className="" onClick={() => setShowNav(false)}>
                                <div className="flex flex-col items-center justify-center pt-14 gap-y-1">
                                    <img src={logo} width='' height='' className="w-12" />
                                    {showNav && (
                                        <span className="text-lg font-bold">Loggello</span>
                                    )}
                                </div>
                            </NavLink>
                        </div>

                        <div className="flex flex-col justify-center items-center gap-y-4 pt-14">
                            <NavLink
                                to="/login"
                                className={`flex items-center gap-x-4 w-10/12 py-2 rounded-md ${location.pathname === '/login' && 'text-primary-blue bg-secondary-blue'} ${showNav ? 'pl-5 pr-2' : 'justify-center'}`}
                                title={t('common:login')}>
                                <button className="material-symbols-outlined">login</button>
                                {showNav && (
                                    <span>{t('common:login')}</span>
                                )}
                            </NavLink>
                            <NavLink
                                to="/register"
                                className={`flex items-center gap-x-4 w-10/12 py-2 rounded-md ${location.pathname === '/register' && 'text-primary-blue bg-secondary-blue'} ${showNav ? 'pl-5 pr-2' : 'justify-center'}`}
                                title={t('common:register')}>
                                <button className="material-symbols-outlined">assignment</button>
                                {showNav && (
                                    <span>{t('common:register')}</span>
                                )}
                            </NavLink>
                        </div>

                        <div className="flex flex-col items-center flex-1 pt-10">
                            <NavLink
                                to={process.env.REACT_APP_LOGGELLO_LANDING_URL || 'https://loggello.com'}
                                className={`flex items-center gap-x-4 w-10/12 py-2 rounded-md ${showNav ? 'pl-5 pr-2' : 'justify-center'}`}
                                title={t('common:information')}>
                                <button className="material-symbols-outlined">info</button>
                                {showNav && (
                                    <span>{t('common:information')}</span>
                                )}
                            </NavLink>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default SideNav;