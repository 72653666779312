import React, { useEffect, useState } from 'react'
import clsx from 'clsx';

type Props = {
    checked?: boolean,
    label?: string;
    labelLineThrough?: boolean;
    disabled?: boolean;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({
    checked,
    label,
    labelLineThrough = false,
    disabled = false,
    size = 'md',
    onChange,
}: Props) => {

    return (
        <label className="flex items-center">
            <input
                type="checkbox"
                disabled={disabled}
                checked={checked}
                onChange={onChange}
                readOnly={!onChange}
                className={clsx(`
                    checkbox appearance-none inline-block bg-primary-blue-15 hover:bg-primary-blue-20 rounded-md relative cursor-pointer transition-all
                    checked:after:absolute checked:after:rotate-45 checked:after:border-primary-blue checked:after:border-l-0 checked:after:border-t-0
                    ${disabled && 'bg-primary-blue-5 hover:bg-primary-blue-5 checked:after:border-primary-blue-25'}
                `, {
                    "w-4 h-4 checked:after:h-2.5 checked:after:w-1.5 checked:after:left-[0.32rem] checked:after:top-[0.085rem] checked:after:border-2": size === 'xs',
                    "w-[1.125rem] h-[1.125rem] checked:after:h-3 checked:after:w-1.5 checked:after:left-1.5 checked:after:top-[0.075rem] checked:after:border-2": size === 'sm',
                    "w-5 h-5 checked:after:h-3 checked:after:w-1.5 checked:after:left-[0.4rem] checked:after:top-[0.2rem] checked:after:border-2": size === 'md',
                    "w-6 h-6 checked:after:h-4 checked:after:w-2 checked:after:left-2 checked:after:top-0.5 checked:after:border-[2.5px]": size === 'lg',
                }
                )}
            />
            <span className={clsx("ml-2 font-semibold", {
                "text-xs align-super": size === 'xs' || size === 'sm' || size === 'md',
                "text-sm": size === 'lg',
                "line-through": labelLineThrough === true,
            }
            )}>{label}</span>
        </label>
    )
}

export default Checkbox