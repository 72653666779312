import { combineReducers, configureStore} from "@reduxjs/toolkit";
import { authReducer, resetAuthState } from "./slices/auth.slice";
import { catalogTenantAPI } from "../services/catalog/catalog-tenant/catalog-tenant.service";
import { managementAPI } from "../services/management/management.service";
import { tenantReducer } from "./slices/tenant.slice";
import { reAuthAPI } from "../services/re-auth/re-auth";
import { generalReducer } from "./slices/general.slice";
import { userReducer } from "./slices/user.slice";
import { authAPI } from "../services/catalog/auth/auth.service";
import { notificationReducer } from "./slices/notification.slice";


const rootReducer = (state: any, action: any) => {
    if (action.type === 'RESET_APP') {
        state = undefined;
    }

    return allReducers(state, action);
}

const allReducers = combineReducers({
    auth: authReducer,
    user: userReducer,
    tenant: tenantReducer,
    general: generalReducer,
    notification: notificationReducer
})

export const store = configureStore({
    reducer: {
        rootReducer,
        [reAuthAPI.reducerPath]: reAuthAPI.reducer,
        [catalogTenantAPI.reducerPath]: catalogTenantAPI.reducer,
        [managementAPI.reducerPath]: managementAPI.reducer,
        [authAPI.reducerPath]: authAPI.reducer,

    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat([reAuthAPI.middleware])
    .concat([catalogTenantAPI.middleware])
    .concat([managementAPI.middleware])
    .concat([authAPI.middleware])
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch