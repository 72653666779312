import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../atoms/loader/loader';
import { useCreateUserInvitedMutation } from '../../../../../services/tenant/user/user.service';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CreateUserInviteForm from '../../../../organisms/forms/user/create-user-invite-form';

type Props = {}

const RegisterInvitedPage = (props: Props) => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const [tokenExists, setTokenExists] = useState<boolean>(false);

    useEffect(() => {
        if (token && token.length > 150) {
            setTokenExists(true);
        }
        else {
            setTokenExists(false);
            navigate('/login')
        }
    }, [])

    return (
        <div className="w-3/4 mx-auto mt-5 rounded">
            {tokenExists ? (
                <div className="flex w-11/12 mx-auto flex-1 flex-col justify-center px-6 py-12">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <h2 className="text-center text-2xl font-extrabold text-black">{t('common:createUser')}</h2>
                        <CreateUserInviteForm token={token} />
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default RegisterInvitedPage;