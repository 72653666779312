import { PageOptionsDto } from '../../dto/page-options.dto';
import { PageDto } from '../../dto/page.dto';
import { reAuthAPI } from '../../re-auth/re-auth';
import { CreatePropertyObjectDto } from './dto/create-property-object.dto';
import { PropertyObjectDto } from './dto/property-object.dto';
import { UpdatePropertyObjectDto } from './dto/update-property-object.dto';

export const propertyObjectAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({

        // Get all property objects for given tenant
        getAllPropertyObjects: builder.query<PropertyObjectDto[], void>({
            query: () => ({
                url: '/property-objects',
                method: 'GET',
            }),
            providesTags: ['PropertyObject']
        }),

        // Create new property object for given tenant
        createPropertyObject: builder.mutation<any, CreatePropertyObjectDto>({
            query: (propertyObject) => ({
                url: '/property-objects',
                method: 'POST',
                body: propertyObject,
            }),
        }),

        // Update one property object
        updatePropertyObject: builder.mutation<any, UpdatePropertyObjectDto>({
            query: (propertyObject) => ({
                url: '/property-objects/' + propertyObject.id,
                method: 'PUT',
                body: propertyObject
            }),
        }),

        // Update many property objects
        updateManyPropertyObjects: builder.mutation<any, UpdatePropertyObjectDto[]>({
            query: (propertyObject) => ({
                url: '/property-objects',
                method: 'PUT',
                body: propertyObject
            }),
            invalidatesTags: ['PropertyObject', 'PropertyBuilding']
        }),

        // Delete property object with given id
        deletePropertyObject: builder.mutation<any, { property_object_id: string }>({
            query: (propertyObjectId) => ({
                url: '/property-objects',
                method: 'DELETE',
                body: propertyObjectId,
            }),
            invalidatesTags: ['PropertyObject']
        }),

        // Get property objects by property id
        getAllPropertyObjectsByPropertyId: builder.query<PageDto<PropertyObjectDto>, { propertyId: string, pageOptionsDto: PageOptionsDto }>({
            query: (params) => {

                const queryParams = [];
                if (params.pageOptionsDto.page !== undefined) queryParams.push(`page=${params.pageOptionsDto.page}`);
                if (params.pageOptionsDto.take !== undefined) queryParams.push(`take=${params.pageOptionsDto.take}`);
                if (params.pageOptionsDto.order !== undefined) queryParams.push(`order=${params.pageOptionsDto.order}`);
                if (params.pageOptionsDto.search !== undefined && params.pageOptionsDto.search.length > 0) queryParams.push(`search=${params.pageOptionsDto.search}`);
                if (params.pageOptionsDto.sortBy !== undefined) queryParams.push(`sort_by=${params.pageOptionsDto.sortBy}`)

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    url: `/property-objects/${params.propertyId}${queryString}`,
                    method: 'GET',
                }
            },
            providesTags: ['PropertyObject']
        }),

        // Get property objects by joinig on property_id from PropertyLogAccess relation where logTemplateId
        getAllPropertyObjectsByPropertyLogAccess: builder.query<PropertyObjectDto[], string>({
            query: (logTemplateId) => ({
                url: '/property-objects/property-log-access/' + logTemplateId,
                method: 'GET',
            }),
            providesTags: ['PropertyObject', 'PropertyLogAccess']
        }),

        // Get property objects by user id joined with property object user access table
        getAllPropertyObjectsByUserId: builder.query<PropertyObjectDto[], string>({
            query: (userId) => ({
                url: '/property-objects/user/' + userId,
                method: 'GET',
            }),
            providesTags: ['PropertyObject']
        }),

        /* 
            Get all property objects where property_building_id is null OR
            property_building_id (building) belongs to the given property_id
        */
        // getAllByPropertyBuildingIdNullOrMatchPropertyId: builder.query<PropertyObjectDto[], string>({
        //     query: (propertyId) => ({
        //         url: '/property-objects/property/' + propertyId,
        //         method: 'GET',
        //     }),
        //     providesTags: ['PropertyObject']
        // }),

        // Get property objects by user id paginated
        getAllByPropertyBuildingIdNullOrMatchPropertyId: builder.query<PageDto<PropertyObjectDto>, { pageOptionsDto: PageOptionsDto, propertyId: string }>({
            query: (params) => {
                const queryParams = [];
                if (params.pageOptionsDto.page !== undefined) queryParams.push(`page=${params.pageOptionsDto.page}`);
                if (params.pageOptionsDto.take !== undefined) queryParams.push(`take=${params.pageOptionsDto.take}`);
                if (params.pageOptionsDto.order !== undefined) queryParams.push(`order=${params.pageOptionsDto.order}`);
                if (params.pageOptionsDto.search !== undefined && params.pageOptionsDto.search.length > 0) queryParams.push(`search=${params.pageOptionsDto.search}`);
                if (params.pageOptionsDto.sortBy !== undefined) queryParams.push(`sort_by=${params.pageOptionsDto.sortBy}`)

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    url: `/property-objects/property/${params.propertyId}${queryString}`,
                    method: 'GET',
                }
            },
            providesTags: ['PropertyObject'],
        }),

        // Get property objects paginated
        getPropertyObjects: builder.query<PageDto<PropertyObjectDto>, PageOptionsDto>({
            query: (options) => {
                const queryParams = [];
                if (options.page !== undefined) queryParams.push(`page=${options.page}`);
                if (options.take !== undefined) queryParams.push(`take=${options.take}`);
                if (options.order !== undefined) queryParams.push(`order=${options.order}`);
                if (options.search !== undefined && options.search.length > 0) queryParams.push(`search=${options.search}`);
                if (options.sortBy !== undefined) queryParams.push(`sort_by=${options.sortBy}`)

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    url: `/property-objects/${queryString}`,
                    method: 'GET',
                }
            },
            providesTags: ['PropertyObject'],
        }),

        // Get property objects by user id paginated
        getPropertyObjectsByUserId: builder.query<PageDto<PropertyObjectDto>, { pageOptionsDto: PageOptionsDto, userId: string }>({
            query: (params) => {
                const queryParams = [];
                if (params.pageOptionsDto.page !== undefined) queryParams.push(`page=${params.pageOptionsDto.page}`);
                if (params.pageOptionsDto.take !== undefined) queryParams.push(`take=${params.pageOptionsDto.take}`);
                if (params.pageOptionsDto.order !== undefined) queryParams.push(`order=${params.pageOptionsDto.order}`);
                if (params.pageOptionsDto.search !== undefined && params.pageOptionsDto.search.length > 0) queryParams.push(`search=${params.pageOptionsDto.search}`);
                if (params.pageOptionsDto.sortBy !== undefined) queryParams.push(`sort_by=${params.pageOptionsDto.sortBy}`)

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    url: `/property-objects/user/${params.userId}/${queryString}`,
                    method: 'GET',
                }
            },
            providesTags: ['PropertyObject'],
        }),
    })
})

export const {
    useGetAllPropertyObjectsQuery,
    useCreatePropertyObjectMutation,
    useUpdatePropertyObjectMutation,
    useUpdateManyPropertyObjectsMutation,
    useDeletePropertyObjectMutation,
    useGetAllPropertyObjectsByPropertyIdQuery,
    useGetAllPropertyObjectsByPropertyLogAccessQuery,
    useGetAllPropertyObjectsByUserIdQuery,
    useGetAllByPropertyBuildingIdNullOrMatchPropertyIdQuery,
    useGetPropertyObjectsByUserIdQuery,
    useGetPropertyObjectsQuery,
} = propertyObjectAPI;