import { reAuthAPI } from '../../re-auth/re-auth';
import { UpdateTenantDto } from './dto/update-tenant.dto';

export const tenantAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Update tenant
        updateTenant: builder.mutation<any, UpdateTenantDto>({
            query: (tenant) => ({
                url: '/tenants/' + tenant.id,
                method: 'PUT',
                body: tenant
            }),
            invalidatesTags: ['Tenants', 'Users']
        }),
    })
})

export const {
    useUpdateTenantMutation,
} = tenantAPI;