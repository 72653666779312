import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../redux/hooks';
import { getTenant } from '../../../../redux/slices/tenant.slice';
import { getCurrentUser } from '../../../../redux/slices/user.slice';
import InviteUsers from '../../../organisms/user/invite-users';
import UsersTable from '../../../organisms/user/user-table';
import { Grow } from '@mui/material';
import Modal from '../../../molecules/modal';
import { UserFormType } from '../../../organisms/forms/user/create-user-form';
import CreateUserFormAdmin from '../../../organisms/forms/user/create-user-form-admin';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../atoms/primary-button';

type Props = {}

const UsersPage = (props: Props) => {
    const { t } = useTranslation();

    const { currentUser } = useAppSelector(getCurrentUser);
    const { tenant } = useAppSelector(getTenant);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showInviteUsers, setShowInviteUsers] = useState<boolean>(false);

    return (
        <Grow in={currentUser ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
            <div className="w-full mx-auto">
                <div className="p-1">
                    <div className="mb-5 pt-3">
                        <InviteUsers
                            showInviteUsers={showInviteUsers}
                            setShowInviteUsers={setShowInviteUsers}
                            tenant={tenant}
                        />
                    </div>

                    <div className="flex justify-end mb-5 w-full mx-auto">
                        <PrimaryButton
                            text={t('page:users.createUser')}
                            size={'medium'}
                            onClick={() => setShowModal(true)}
                            startAdornment={'add'}
                        />
                    </div>
                    <UsersTable />
                </div>
                <Modal isOpen={showModal} close={() => setShowModal(false)} title={t('page:users.createUser')} size='sm' titleAlign='center'>
                    <div className="w-11/12 mx-auto mb-5">
                        <CreateUserFormAdmin />
                    </div>
                </Modal>
            </div>
        </Grow>
    )
}

export default UsersPage;