import { useEffect, useRef, useState } from "react";
import { useDeleteUserInviteMutation, useGetUserInvitesByTenantIdQuery } from "../../../../../services/catalog/user-invite/user-invite.service";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { dateToLocaleDateAndHour } from "../../../../../utils/date-time";
import { TenantStateType } from "../../../../../services/dto/tenant-state-type";
import { UserInviteStatus } from "../../../../../services/enum/user-invite-status";
import { useTranslation } from "react-i18next";
import { Order } from "../../../../../services/enum/order";
import { debounce } from "../../../../../utils/debounce";
import { SendUserInviteDto } from "../../../../../services/tenant/user/dto/send-user-invite.dto";
import { UserInviteDto } from "../../../../../services/catalog/user-invite/dto/user-invite.dto";
import { useAppSelector } from "../../../../../redux/hooks";
import { getCurrentUser } from "../../../../../redux/slices/user.slice";
import { useInviteUserMutation } from "../../../../../services/tenant/user/user.service";
import { NotificationManager } from 'react-notifications';


type Props = {
    tenant?: TenantStateType
}

const InvitedUsersTable = ({ tenant }: Props) => {
    const { t } = useTranslation();

    const { currentUser } = useAppSelector(getCurrentUser);

    const [inviteUser] = useInviteUserMutation();
    const [deleteUserInvite] = useDeleteUserInviteMutation();

    // Pagination
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>();
    const [hasNextPage, setHasNextPage] = useState<boolean>();
    const [hasPrevPage, setHasPrevPage] = useState<boolean>();

    // Table sort and search
    const [search, setSearch] = useState<string | undefined>('');
    const [searchOperation, setSearchOperation] = useState<string | undefined>();
    const [sortBy, setSortBy] = useState<string | undefined>('created_at'); // Column to sort by
    const [sortOrder, setSortOrder] = useState<Order>(Order.DESC); // Sort order
    const [showSortMenu, setShowSortMenu] = useState<boolean>(false); // Sort menu on small screens

    const debouncedSearch = useRef(debounce((search: string) => setSearchOperation(search), 500)).current;

    const { data: users, isLoading, error } = useGetUserInvitesByTenantIdQuery(
        tenant ? {
            tenantId: tenant.id,
            pageOptions: {
                page: page,
                take: 25,
                search: searchOperation,
                sortBy: sortBy,
                order: sortOrder
            }
        } : skipToken
    );

    const handleSort = (column: string, order: Order) => {
        setSortBy(column);
        setSortOrder(order === Order.ASC ? Order.DESC : Order.ASC);
    }

    const handleResendInvitation = async (inviteData: UserInviteDto) => {
        if (currentUser && tenant && inviteData) {
            const currentUserDomain = currentUser.email.split('@')[1];
            const inviteUserDomain = inviteData.receiver_email.split('@')[1];
            const externalDomain = currentUserDomain === inviteUserDomain ? true : false;

            const sendUserInviteDto: SendUserInviteDto = {
                sender_tenant_id: inviteData.sender_tenant_id,
                sender_user_id: inviteData.sender_user_id,
                sender_email: currentUser.email,
                sender_name: currentUser.name,
                sender_company_name: tenant.name,
                receiver_email: inviteData.receiver_email,
                external_domain: externalDomain
            }

            try {
                const response = await inviteUser(sendUserInviteDto).unwrap();

                if (response.success) {
                    NotificationManager.success(t('page:users.form.userInviteSent'));
                }
                else {
                    if (response.message && response.message === 'user_already_exists') {
                        NotificationManager.error(t('page:users.userAlreadyExists'));
                    }
                    else {
                        NotificationManager.error(t('page:users.form.userInviteNotSent'));
                    }
                }
            }
            catch (error) {
                NotificationManager.error(t('page:users.form.userInviteNotSent'));
            }
        }
    }

    const handleDeleteInvitation = async (inviteData: UserInviteDto) => {
        if (inviteData) {
            try {
                const response = await deleteUserInvite(inviteData.id).unwrap();

                if (response.success) {
                    NotificationManager.success(t('page:users.form.userInviteDeleted'));
                }
                else {
                    NotificationManager.error(t('page:users.form.userInviteNotDeleted'));
                }
            }
            catch (error) {
                NotificationManager.error(t('page:users.form.userInviteNotDeleted'));
            }
        }
    }

    return (
        <>
            {(users && users.data.length > 0) ? (
                <div className="relative overflow-x-hidden rounded-md border-t border-gray-100 bg-white">
                    <div className="overflow-x-scroll max-h-[25vh]">
                        <table className="w-full text-left text-black">
                            <thead className="text-xs lg:text-sm text-black border-b-2 border-gray-100 capitalize">
                                <tr>
                                    <th scope="col" className="pl-3 py-1 md:py-2 pr-2 cursor-pointer font-bold min-w-[6rem]" onClick={() => handleSort('receiver_email', sortOrder)}>
                                        {t('common:email')}
                                        {(sortBy === 'receiver_email') ? (
                                            <>
                                                {sortOrder === Order.ASC ? (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                ) : (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                            </>
                                        )}
                                    </th>
                                    <th scope="col" className="py-1 md:py-2 pr-2 cursor-pointer font-bold min-w-[6rem]" onClick={() => handleSort('status', sortOrder)}>
                                        {t('common:status')}
                                        {(sortBy === 'status') ? (
                                            <>
                                                {sortOrder === Order.ASC ? (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                ) : (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                            </>
                                        )}
                                    </th>
                                    <th scope="col" className="py-1 md:py-2 pr-2 cursor-pointer font-bold min-w-[6rem]" onClick={() => handleSort('created_at', sortOrder)}>
                                        {t('common:inviteSent')}
                                        {(sortBy === 'created_at') ? (
                                            <>
                                                {sortOrder === Order.ASC ? (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                ) : (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                            </>
                                        )}
                                    </th>
                                    <th scope="col" className="py-1 md:py-2 pr-2 cursor-pointer font-bold min-w-[6rem]" onClick={() => handleSort('invite_expiry', sortOrder)}>
                                        {t('common:inviteExpiry')}
                                        {(sortBy === 'invite_expiry') ? (
                                            <>
                                                {sortOrder === Order.ASC ? (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                ) : (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                            </>
                                        )}
                                    </th>
                                    <th scope="col" className="">
                                        <span className="sr-only">Resend</span>
                                    </th>
                                    <th scope="col" className="">
                                        <span className="sr-only">Delete</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {users && (
                                    <>
                                        {users.data.map((user, idx) => {
                                            return (
                                                <tr key={idx} className="bg-white border-b last:border-b-0 hover:bg-zinc-100">
                                                    <td scope="row" className="py-2 pl-3 text-xs font-semibold sm:font-normal max-w-[8rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                                        {user.receiver_email}
                                                    </td>
                                                    <td className="py-2 text-xs capitalize font-semibold sm:font-normal max-w-[8rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                                        {t(`common:enums.userInviteStatus.${UserInviteStatus[user.status]}`)}
                                                    </td>
                                                    <td className="py-2 text-xs capitalize font-semibold sm:font-normal max-w-[8rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                                        {dateToLocaleDateAndHour(user.created_at)}
                                                    </td>
                                                    <td className="py-2 text-xs capitalize font-semibold sm:font-normal max-w-[8rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                                        {dateToLocaleDateAndHour(user.invite_expiry)}
                                                    </td>
                                                    <td className="px-2 lg:px-1 py-1.5">
                                                        <button
                                                            onClick={() => handleResendInvitation(user)}
                                                            title={t('common:sendNew')}
                                                            className="material-symbols-outlined"
                                                            style={{ fontSize: '1.4rem' }}
                                                        >
                                                            send
                                                        </button>
                                                    </td>
                                                    <td className="px-2 lg:px-1">
                                                        <button
                                                            onClick={() => handleDeleteInvitation(user)}
                                                            title={t('common:delete')}
                                                            className="material-symbols-outlined text-status-critical"
                                                            style={{ fontSize: '1.3rem' }}
                                                        >delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <></>
            )}

        </>
    )
}

export default InvitedUsersTable;