import React, { useEffect, useState } from 'react'
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { PropertyDto } from '../../../../services/tenant/property/dto/property.dto';
import { propertyAPI, useUpdatePropertyMutation } from '../../../../services/tenant/property/property.service';
import Loader from '../../../atoms/loader/loader';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import PropertyFileUpload from '../../property/property-file-upload';
import { PropertyTypes } from '../../../../services/enum/property-types';
import SubsidiarySelect from '../../subsidiary/subsidiary-select';
import { useDispatch } from 'react-redux';
import { SelectCustomStyles } from '../../../atoms/select-custom-styles/select-custom-styles';
import PrimaryButton from '../../../atoms/primary-button';
import { getLatLng } from '../../../../utils/getLatLng';
import { ServiceStatus } from '../../../../services/enum/service-status';
import { ProximityRange } from '../../../../services/enum/proximity-range';
import { ProximityEnforcement } from '../../../../services/enum/proximity-enforcement';
import { EntityLogDto } from '../../../../services/tenant/entity-log/dto/entity-log.dto';
import Checkbox from '../../../atoms/checkbox';
import { useUpdateEntityLogMutation } from '../../../../services/tenant/entity-log/entity-log.service';

export type UpdateEntityLogType = {
    id: string;
    is_editable?: boolean | false;
    status?: ServiceStatus;
    proximity_detection?: boolean | false;
    proximity_range?: ProximityRange;
    proximity_enforcement?: ProximityEnforcement;
}

type Props = {
    entityLog?: EntityLogDto | null;
}

const UpdateEntityLogForm = ({
    entityLog
}: Props) => {
    const { t } = useTranslation();
    
    const [updateEntityLog, response] = useUpdateEntityLogMutation();

    const { control, handleSubmit, formState: { errors } } = useForm<UpdateEntityLogType>({
        defaultValues: {
            id: entityLog?.id || '',
            is_editable: entityLog?.is_editable || false,
            status: entityLog?.status || ServiceStatus.Ok,
            proximity_detection: entityLog?.proximity_detection || false,
            proximity_enforcement: entityLog?.proximity_enforcement || ProximityEnforcement.Request,
            proximity_range: entityLog?.proximity_range || ProximityRange.Close,
        }
    });

    const onSubmit = async (formData: UpdateEntityLogType) => {
        if (entityLog) {
            try {
                const response = await updateEntityLog(formData).unwrap();

                if (response.success) {
                    NotificationManager.success(t('page:entityLog.logUpdated'));
                }
                else {
                    NotificationManager.error(t('page:entityLog.logNotUpdated'));
                }
            }
            catch (error) {
                NotificationManager.error(t('page:entityLog.logNotUpdated'));
            }
        }
    }

    return (
        <>
            <div className="sm:mx-auto sm:w-full">
                <form className="space-y-2" onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                        <Controller
                            name="is_editable"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <>
                                    <Checkbox label={t('page:entityLog.editable')} checked={field.value} onChange={field.onChange} />
                                    <span className="text-xs">{t('page:entityLog.editableInfo')}</span>
                                </>
                            )}
                        />
                        {errors.is_editable && <p className="text-status-critical text-xs my-1 ml-1">{errors.is_editable.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='status' className="text-xs sm:text-sm font-semibold leading-6 text-black">
                            {t('page:entityLog.serviceStatus')}
                        </label>
                        <Controller
                            name="status"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <>
                                    <Select
                                        name="status"
                                        className={`${errors.status ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical rounded-md' : ''} z-50`}
                                        onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                                        styles={SelectCustomStyles}
                                        options={Object.values(ServiceStatus)
                                            .map(value => ({
                                                label: t(`common:enums.serviceStatus.${value}`),
                                                value
                                            }))
                                        }
                                        value={Object.values(ServiceStatus)
                                            .map(value => ({
                                                label: t(`common:enums.serviceStatus.${value}`),
                                                value
                                            }))
                                            .find(option => option.value === field.value)
                                        }
                                        placeholder={t('common:select')}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        controlShouldRenderValue={true}
                                        isClearable={false}
                                        backspaceRemovesValue={false}
                                    />
                                    <span className="text-xs">{t('page:entityLog.serviceStatusInfo')}</span>
                                </>
                            )}
                        />
                        {errors.status && <p className="text-status-critical text-xs my-1 ml-1">{errors.status.message}</p>}
                    </div>
                    <div className="py-2">
                        <Controller
                            name="proximity_detection"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <>
                                    <Checkbox label={t('page:entityLog.proximityDetection')} checked={field.value} onChange={field.onChange} />
                                    <span className="text-xs">{t('page:entityLog.proximityDetectionInfo')}</span>
                                </>
                            )}
                        />
                        {errors.proximity_detection && <p className="text-status-critical text-xs my-1 ml-1">{errors.proximity_detection.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='proximity_enforcement' className="block text-xs sm:text-sm font-semibold leading-6 text-black">
                            {t('page:entityLog.proximityEnforcement')}
                        </label>
                        <Controller
                            name="proximity_enforcement"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <>
                                    <Select
                                        name="proximity_enforcement"
                                        className={`${errors.proximity_enforcement ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical rounded-md' : ''} z-40`}
                                        onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                                        styles={SelectCustomStyles}
                                        options={Object.values(ProximityEnforcement)
                                            .map(value => ({
                                                label: t(`common:enums.proximityEnforcement.${value}`),
                                                value
                                            }))
                                        }
                                        value={Object.values(ProximityEnforcement)
                                            .map(value => ({
                                                label: t(`common:enums.proximityEnforcement.${value}`),
                                                value
                                            }))
                                            .find(option => option.value === field.value)
                                        }
                                        placeholder={t('common:select')}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        controlShouldRenderValue={true}
                                        isClearable={false}
                                        backspaceRemovesValue={false}
                                    />
                                    <span className="text-xs">{t('page:entityLog.proximityEnforcementInfo')}</span>
                                </>
                            )}
                        />
                        {errors.proximity_enforcement && <p className="text-status-critical text-xs my-1 ml-1">{errors.proximity_enforcement.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='proximity_range' className="block text-xs sm:text-sm font-semibold leading-6 text-black">
                            {t('page:entityLog.proximityRange')}
                        </label>
                        <Controller
                            name="proximity_range"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <>
                                    <Select
                                        name="proximity_range"
                                        className={`${errors.proximity_range ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical rounded-md' : ''} z-30`}
                                        onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                                        styles={SelectCustomStyles}
                                        options={Object.keys(ProximityRange)
                                            .map(key => ({
                                                label: t(`common:enums.proximityRange.${key}`),
                                                value: ProximityRange[key as keyof typeof ProximityRange]

                                            }))
                                        }
                                        value={Object.keys(ProximityRange)
                                            .map(key => ({
                                                label: t(`common:enums.proximityRange.${key}`),
                                                value: ProximityRange[key as keyof typeof ProximityRange]

                                            }))
                                            .find(option => option.value === field.value)
                                        }
                                        placeholder={t('common:select')}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        controlShouldRenderValue={true}
                                        isClearable={false}
                                        backspaceRemovesValue={false}
                                    />
                                    <span className="text-xs">{t('page:entityLog.proximityRangeInfo')}</span>
                                </>
                            )}
                        />
                        {errors.proximity_range && <p className="text-status-critical text-xs my-1 ml-1">{errors.proximity_range.message}</p>}
                    </div>

                    <div className="py-5">
                        <PrimaryButton
                            text={t('common:update')}
                            size={'medium'}
                            fullWidth={true}
                        />
                        {response.isLoading && (<Loader show={response.isLoading} size='small' />)}
                    </div>
                </form>
            </div>
        </>
    )
}

export default UpdateEntityLogForm;