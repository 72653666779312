import { PageOptionsDto } from '../../dto/page-options.dto';
import { PageDto } from '../../dto/page.dto';
import { reAuthAPI } from '../../re-auth/re-auth';
import { CreateFriendshipDto } from './dto/create-friendship.dto';
import { FriendshipTenantDto } from './dto/friendship-tenant.dto';
import { UpdateFriendshipDto } from './dto/update-friendship.dto';


export const friendshipAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Get all external companies
        getAllFriendshipTenants: builder.query<PageDto<FriendshipTenantDto>, PageOptionsDto>({
            query: (options) => {
                const queryParams = [];
                if (options.page !== undefined) queryParams.push(`page=${options.page}`);
                if (options.take !== undefined) queryParams.push(`take=${options.take}`);
                if (options.order !== undefined) queryParams.push(`order=${options.order}`);
                if (options.search !== undefined && options.search.length > 0) queryParams.push(`search=${options.search}`);
                if (options.sortBy !== undefined) queryParams.push(`sort_by=${options.sortBy}`)

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    url: `catalog/friendships/tenants/all${queryString}`,
                    method: 'GET',
                }
            },
            providesTags: ['Friendships']
        }),
        // Get external companies with relation
        getMyFriendshipTenants: builder.query<PageDto<FriendshipTenantDto>, PageOptionsDto>({
            query: (options) => {
                const queryParams = [];
                if (options.page !== undefined) queryParams.push(`page=${options.page}`);
                if (options.take !== undefined) queryParams.push(`take=${options.take}`);
                if (options.order !== undefined) queryParams.push(`order=${options.order}`);
                if (options.search !== undefined && options.search.length > 0) queryParams.push(`search=${options.search}`);
                if (options.sortBy !== undefined) queryParams.push(`sort_by=${options.sortBy}`)

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    url: `catalog/friendships/tenants/my${queryString}`,
                    method: 'GET',
                }
            },
            providesTags: ['Friendships']
        }),
        // // Get external companies with relation that's pending
        // getPendingFriendshipTenants: builder.query<PageDto<FriendshipTenantDto>, PageOptionsDto>({
        //     query: (options) => {
        //         const queryParams = [];
        //         if (options.page !== undefined) queryParams.push(`page=${options.page}`);
        //         if (options.take !== undefined) queryParams.push(`take=${options.take}`);
        //         if (options.order !== undefined) queryParams.push(`order=${options.order}`);
        //         if (options.search !== undefined && options.search.length > 0) queryParams.push(`search=${options.search}`);
        //         if (options.sortBy !== undefined) queryParams.push(`sort_by=${options.sortBy}`)

        //         // Contruct query string
        //         const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

        //         return {
        //             url: `catalog/friendships/tenants/pending${queryString}`,
        //             method: 'GET',
        //         }
        //     },
        //     providesTags: ['Friendships']
        // }),
        // Get incoming requests from external companies
        getIncomingRequestsFriendship: builder.query<PageDto<FriendshipTenantDto>, PageOptionsDto>({
            query: (options) => {
                const queryParams = [];
                if (options.page !== undefined) queryParams.push(`page=${options.page}`);
                if (options.take !== undefined) queryParams.push(`take=${options.take}`);
                if (options.order !== undefined) queryParams.push(`order=${options.order}`);
                if (options.search !== undefined && options.search.length > 0) queryParams.push(`search=${options.search}`);
                if (options.sortBy !== undefined) queryParams.push(`sort_by=${options.sortBy}`)

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    url: `catalog/friendships/tenants/incoming${queryString}`,
                    method: 'GET',
                }
            },
            providesTags: ['Friendships']
        }),
        getIncomingRequestsFriendshipCount: builder.query<number, void>({
            query: () => ({
                url: `catalog/friendships/tenants/incoming/count`,
                method: 'GET'
            }),
            providesTags: ['Friendships']
        }),
         // Get sent requests to external companies
         getSentRequestsFriendship: builder.query<PageDto<FriendshipTenantDto>, PageOptionsDto>({
            query: (options) => {
                const queryParams = [];
                if (options.page !== undefined) queryParams.push(`page=${options.page}`);
                if (options.take !== undefined) queryParams.push(`take=${options.take}`);
                if (options.order !== undefined) queryParams.push(`order=${options.order}`);
                if (options.search !== undefined && options.search.length > 0) queryParams.push(`search=${options.search}`);
                if (options.sortBy !== undefined) queryParams.push(`sort_by=${options.sortBy}`)

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    url: `catalog/friendships/tenants/sent${queryString}`,
                    method: 'GET',
                }
            },
            providesTags: ['Friendships']
        }),
        getSentRequestsFriendshipCount: builder.query<number, void>({
            query: () => ({
                url: `catalog/friendships/tenants/sent/count`,
                method: 'GET'
            }),
            providesTags: ['Friendships']
        }),
        // Create friendship relation
        createFriendship: builder.mutation<any, CreateFriendshipDto>({
            query: (data) => ({
                url: 'catalog/friendships',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Friendships']
        }),
        // Update friendship relation
        updateFriendship: builder.mutation<any, UpdateFriendshipDto>({
            query: (data) => ({
                url: 'catalog/friendships/' + data.id,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Friendships']
        }),
        // Delete friendship relation
        deleteFriendship: builder.mutation<any, string>({
            query: (id) => ({
                url: 'catalog/friendships/' + id,
                method: 'DELETE',
            }),
            invalidatesTags: ['Friendships']
        })
    })
})

export const {
    useGetAllFriendshipTenantsQuery,
    useGetMyFriendshipTenantsQuery,
    useGetIncomingRequestsFriendshipQuery,
    useGetIncomingRequestsFriendshipCountQuery,
    useGetSentRequestsFriendshipQuery,
    useGetSentRequestsFriendshipCountQuery,
    useCreateFriendshipMutation,
    useUpdateFriendshipMutation,
    useDeleteFriendshipMutation,
} = friendshipAPI;