
import React, { useCallback, useEffect, useState } from 'react'
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FriendshipTenantDto } from '../../../../../services/catalog/friendship/dto/friendship-tenant.dto';
import { useAppSelector } from '../../../../../redux/hooks';
import { getTenant } from '../../../../../redux/slices/tenant.slice';
import { getCurrentUser } from '../../../../../redux/slices/user.slice';
import ExternalLogAccessContractorTable from './external-log-access-contractor-table';
import ExternalCompaniesContractorSelect from '../external-companies-contractor-select';
import UserSelectMulti from '../../../user/user-select-multi';
import { UserDto } from '../../../../../services/tenant/user/dto/user.dto';
import { useTranslation } from 'react-i18next';
import { ContractorLogAccessDto } from '../../../../../services/tenant/contractor-log-user-access/dto/contractor-log-access.dto';
import { useGetLogTemplateByIdContractorQuery } from '../../../../../services/external/contractor/contractor.service';
import LogEntriesContractor from '../log-entries-contractor';
import { DisplayNameType } from '../../../log-components/log-structure/log-entries';
import { LogTemplateDto } from '../../../../../services/tenant/log-template/dto/log-template.dto';

type SelectTypeCompany = {
    label: string;
    value: string;
    data: FriendshipTenantDto | null;
}

type SelectTypeUsers = {
    label: string;
    value: string;
    data: UserDto;
}

type Props = {
    preSelectedCompany?: FriendshipTenantDto;
    selectedTenantLog?: ContractorLogAccessDto | null;
    setSelectedTenantLog: (val: ContractorLogAccessDto | null) => void;
    displayName?: DisplayNameType;
};

const ExternalLogAccessContractor = ({
    preSelectedCompany,
    selectedTenantLog,
    displayName,
    setSelectedTenantLog,
}: Props) => {

    const { t } = useTranslation();
    const { currentUser } = useAppSelector(getCurrentUser);
    const { tenant } = useAppSelector(getTenant);

    const [selectedCompany, setSelectedCompany] = useState<FriendshipTenantDto | undefined>(undefined);
    const [selectedUsers, setSelectedUsers] = useState<UserDto[]>();

    const [skipTokenTenantIdLogTemplateId, setSkipTokenTenantIdLogTemplateId] = useState<any>(skipToken);
    const { data: logTemplate } = useGetLogTemplateByIdContractorQuery(skipTokenTenantIdLogTemplateId);

    const [selectedLogTemplate, setSelectedLogTemplate] = useState<LogTemplateDto | null>();

    useEffect(() => {
        if (selectedTenantLog) {
            setSkipTokenTenantIdLogTemplateId({
                tenantId: selectedTenantLog.tenant_id_property_owner,
                logTemplateId: selectedTenantLog.log_template_id
            })
        }
    }, [selectedTenantLog])

    const handleSelectedCompany = useCallback((selectedCompany: SelectTypeCompany) => {
        if (selectedCompany && selectedCompany.data) {
            setSelectedCompany(selectedCompany.data);
        }
        else {
            setSelectedCompany(undefined)
        }
    }, [setSelectedCompany])

    const handleSelectedUsers = useCallback((selectedUsers: SelectTypeUsers[]) => {
        if (selectedUsers.length > 0) {
            const userData = selectedUsers.map(e => e.data);
            setSelectedUsers(userData);
        }
        else {
            setSelectedUsers([])
        }
    }, [setSelectedUsers])

    useEffect(() => {
        if (logTemplate && logTemplate.id) {
            setSelectedLogTemplate(logTemplate);
        }
        else {
            setSelectedLogTemplate(null);
        }

    }, [logTemplate])

    return (
        <>
            {!selectedTenantLog && (
                <>
                    <div className="w-10/12 sm:w-8/12 md:w-6/12 xl:w-4/12 mx-auto sm:mx-0">
                        <div className="">
                            <p className="text-xs font-medium p-1">{t('page:relationAccess.selectCompanyHandleLogs')}</p>
                            <ExternalCompaniesContractorSelect handleSelectedCompany={handleSelectedCompany} preSelectedCompany={preSelectedCompany} />
                        </div>

                        {selectedCompany && (
                            <div className="mt-2">
                                <p className="text-xs font-medium p-1">{t('page:relationAccess.selectUserLogAccess')}</p>
                                <UserSelectMulti handleSelectedUsers={handleSelectedUsers} />
                            </div>
                        )}
                    </div>

                    {selectedCompany && (
                        <ExternalLogAccessContractorTable
                            company={selectedCompany}
                            users={selectedUsers}
                            setSelectedLog={setSelectedTenantLog}
                            selectedLog={selectedTenantLog}
                        />
                    )}
                </>
            )}

            {(selectedTenantLog && selectedLogTemplate) && (
                <LogEntriesContractor
                    selectedTenantLog={selectedTenantLog}
                    setSelectedTenantLog={setSelectedTenantLog}
                    logTemplate={selectedLogTemplate}
                    displayName={displayName}

                />

            )}
        </>
    )
}

export default ExternalLogAccessContractor