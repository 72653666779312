import React, { useEffect, useState } from 'react';
import Footer from './templates/footer';
import SideNav from './templates/navigation/side-nav';
import ProfileHeader from './templates/profile-header';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getAuthenticatedUser } from '../../redux/slices/auth.slice';
import { getCurrentUser } from '../../redux/slices/user.slice';
import { getTenant } from '../../redux/slices/tenant.slice';
import { useLocation } from 'react-router-dom';
import { Roles } from '../../services/enum/roles';
import './layout.css';
import useWindowWidth from '../../redux/hooks/useWindowWidth';
import MobileNav from './templates/navigation/mobile-nav';
import Notifications from '../atoms/notifications';
import LanguageSelector from '../atoms/language-selector';

type Props = {
    children: React.ReactNode,
}

const Layout = ({ children }: Props) => {

    const dispatch = useAppDispatch();
    const location = useLocation();
    const { authUser, access_token } = useAppSelector(getAuthenticatedUser);
    const { currentUser } = useAppSelector(getCurrentUser);
    const { tenant } = useAppSelector(getTenant);

    const [expandSideNav, setExpandSideNav] = useState<boolean>(true);
    const [expandMobileNav, setExpandMobileNav] = useState<boolean>(false);

    const isMobile = useWindowWidth();

    /*
        <SideNav showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
        <ProfileHeader />
        <main className={`${showSidebar ? 'hidden md:block' : ''} w-12/12 ml-[50px]`}>{children}</main>
         <Footer />

         Fix profile header
            - Notification and language selector should exist in header (rename)
            - absolute position

    */


    return (
        <>
            {isMobile ? (
                <>
                    {/* <ProfileHeader /> */}
                    <div className="layout-mobile bg-gradient-to-b from-primary-blue-10 to-primary-blue-20 lg:p-[24px] overflow-y-scroll">
                        <main className={`${expandMobileNav ? 'hidden lg:block' : ''} w-full h-[calc(90dvh)] sm:h-[calc(94dvh)] overflow-y-scroll px-3 sm:px-5 py-2 sm:py-5 bg-white`}>{children}</main>
                    </div>
                    <MobileNav
                        showNav={expandMobileNav}
                        setShowNav={setExpandMobileNav}
                    />
                </>
            ) : (

                <div className={`layout-desktop w-full h-full absolute bg-gradient-to-b from-primary-blue-10 to-primary-blue-20 lg:p-[16px] overflow-y-scroll transition-all ${expandSideNav ? '' : 'small'}`}>
                    <div className={`bg-white rounded-lg  max-w-[20rem]`}>
                        <SideNav
                            showNav={expandSideNav}
                            setShowNav={setExpandSideNav}
                        />
                    </div>

                    <div className="bg-white rounded-lg overflow-y-scroll">
                        <div className="w-full flex gap-x-2 pr-4 pt-1.5 justify-end h-[calc(2dvh)]">
                            <Notifications position='relative' />
                            <LanguageSelector position='relative' />
                        </div>
                        <main className={`${expandSideNav ? 'hidden lg:block' : ''} w-full max-w-[120rem] mx-auto h-[calc(94dvh)] px-10 py-5 rounded-lg bg-white`}>{children}</main>
                    </div>
                </div>

            )}
        </>
    )
}

export default Layout;