import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Organisationsnummer from 'organisationsnummer';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TenantTypes } from '../../../../services/enum/tenant-types';
import { Plans } from '../../../../services/enum/plans';
import PlanSelector from '../../../molecules/plan-selector';
import PrimaryButton from '../../../atoms/primary-button';

export type TenantFormType = {
    company_name: string;
    tenant_type: TenantTypes;
    org_number: string;
    domain: string;
    plan: Plans;
    active: boolean;
    address?: string;
    city?: string;
    zip?: string;
    country?: string;
    contact_phone?: string;
    contact_email?: string;
    logo?: string;
    // email_confirmed: boolean;
}

type Props = {
    onSubmitTenantForm: (data: TenantFormType) => void;
    error: string;
}

const CreateTenantForm = ({ onSubmitTenantForm, error }: Props) => {
    const { t } = useTranslation();

    const [selectedPlan, setSelectedPlan] = useState<Plans>(Plans.Free);
    const [orgNumberError, setOrgNumberError] = useState<string>('');
    const [domainError, setDomainError] = useState<string>('');

    // const { register, handleSubmit, setError, control, formState: { errors } } = form;

    const { register, handleSubmit, setError, setValue, control, formState: { errors } } = useForm<TenantFormType>({
        defaultValues: {
            company_name: '',
            tenant_type: TenantTypes.Property_Owner,
            org_number: '',
            domain: '',
            plan: Plans.Pro,
            active: true,
            logo: '',
        }
    });

    // Update value for plan when selectedPlan changes
    useEffect(() => {
        setValue('plan', selectedPlan);
    }, [selectedPlan])

    const validateOrgNumber = (value: string) => {
        const orgNumberValid = Organisationsnummer.valid(value);

        if (!orgNumberValid) {
            setOrgNumberError(t('page:company.invalidOrgNumber'))
            return false;
        }
        setOrgNumberError('');
        return true;
    }

    const validateCharactersDomain = (value: string) => {
        const invalidCharsRegex = /[^a-zA-Z0-9@.-]/;
        if (invalidCharsRegex.test(value)) {
            setDomainError(t('common:form.invalidCharacters'));
            return false
        }
        setDomainError('');
        return true;
    }

    return (
        <>
            <div className="w-full md:mx-auto">
                <form className="space-y-2" onSubmit={handleSubmit(onSubmitTenantForm)}>
                    <div className="my-5">
                        <label htmlFor='plan' className="block text-xs sm:text-sm font-semibold leading-6 text-black">
                            {t('common:plan')}
                        </label>
                        <PlanSelector
                            setSelectedPlan={setSelectedPlan}
                            selectedPlan={selectedPlan}
                        />
                        {/* Register the plan property but don't display the input field */}
                        <input
                            type='hidden'
                            {...register('plan')}
                        />
                    </div>
                    {/* <p className="text-status-critical">- Fill in payment details?</p> */}

                    <div>
                        <label htmlFor='tenant_type' className="block text-xs sm:text-sm font-semibold leading-6 text-black">
                            {t('common:companyType')}
                        </label>
                        <div className="mt-0.5 text-base sm:text-sm">
                            <select
                                {...register('tenant_type')}
                                id='tenant_type'
                                className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                {(Object.values(TenantTypes) as Array<keyof typeof TenantTypes>).map((val, idx) => {
                                    return (
                                        <Fragment key={idx}>
                                            {(typeof val === 'string' && val !== TenantTypes.None) && (
                                                <option className="capitalize" key={val} value={val}>{t(`common:enums.tenantTypes.${val.split(' ').join('_')}`)}</option>
                                            )}
                                        </Fragment>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div>
                        <label htmlFor='org_number' className="block text-xs sm:text-sm font-semibold leading-6 text-black">
                            {t('common:orgNumber')}
                        </label>
                        <div className="mt-0.5 text-base sm:text-sm">
                            <Controller
                                name="org_number"
                                control={control}
                                rules={{
                                    required: t('page:company.form.orgNumberNotEmpty'),
                                    validate: validateOrgNumber,
                                    minLength: {
                                        value: 10,
                                        message: t('page:company.form.orgNumberMinLength10')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={11}
                                            placeholder='123456-7890'
                                            className={`${errors.org_number && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                        />
                                        {/* {errors.org_number && <p className="text-status-critical text-xs">Please enter organisation number.</p>} */}
                                        {errors.org_number && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.org_number.message ? errors.org_number.message : orgNumberError}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='company_name' className="block text-xs sm:text-sm font-semibold leading-6 text-black">
                            {t('common:companyName')}
                        </label>
                        <div className="mt-0.5 text-base sm:text-sm">
                            <Controller
                                name="company_name"
                                control={control}
                                rules={{
                                    required: t('page:company.form.companyNameNotEmpty'),
                                    minLength: {
                                        value: 3,
                                        message: t('page:company.form.companyNameMinLength3')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={32}
                                            placeholder={t('common:companyName')}
                                            className={`${errors.company_name && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                        />
                                        {errors.company_name && (<p className="text-status-critical mt-1 ml-1 text-xs">{errors.company_name.message}</p>)}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='domain' className="block text-xs sm:text-sm font-semibold leading-6 text-black mb-[-20px]">
                            {t('common:domain')}
                            <span
                                className="material-symbols-outlined text-black ml-1 align-sub"
                                style={{ fontSize: '1.2rem' }}
                                title={t('page:company.domainInfo')}
                            >help</span>
                        </label>

                        <span className="relative left-0.5 top-[30px] border-r-2 p-1.5 text-black font-bold">@</span>
                        <div className="text-base sm:text-sm">
                            <Controller
                                name="domain"
                                control={control}
                                rules={{
                                    required: t('page:company.form.domainNotEmpty'),
                                    validate: validateCharactersDomain,
                                    minLength: {
                                        value: 3,
                                        message: t('page:company.form.domainMinLength3')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={32}
                                            placeholder={t('page:company.domainPlaceholder')}
                                            className={`${errors.domain && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} pl-10 w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                        />
                                        {errors.domain && (<p className="text-status-critical mt-1 ml-1 text-xs">{errors.domain.message ? errors.domain.message : domainError}</p>)}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    {/* <div>
                        <label htmlFor='contact_phone' className="block text-sm font-semibold leading-6 text-black">
                            Phone
                        </label>
                        <div className="mt-0.5">
                            <input {...register('contact_phone', {
                                required: true
                            })}
                                type='text'
                                placeholder='Phone'
                                required
                                className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6'
                            />
                            {errors.contact_phone && <p className="text-status-critical text-xs">Please enter Phone.</p>}

                        </div>
                    </div>
                    <div>
                        <label htmlFor='address' className="block text-sm font-semibold leading-6 text-black">
                            Address
                        </label>
                        <div className="mt-0.5">
                            <input {...register('address', {
                                required: true
                            })}
                                type='text'
                                placeholder='Address'
                                minLength={3}
                                required
                                className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6'
                            />
                            {errors.address && <p className="text-status-critical text-xs">Please enter Address.</p>}

                        </div>
                    </div>
                    <div>
                        <label htmlFor='city' className="block text-sm font-semibold leading-6 text-black">
                            City
                        </label>
                        <div className="mt-0.5">
                            <input {...register('city', {
                                required: true
                            })}
                                type='text'
                                placeholder='City'
                                minLength={2}
                                required
                                className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6'
                            />
                            {errors.city && <p className="text-status-critical text-xs">Please enter city.</p>}

                        </div>
                    </div>
                    <div>
                        <label htmlFor='zip' className="block text-sm font-semibold leading-6 text-black">
                            Zipcode
                        </label>
                        <div className="mt-0.5">
                            <input {...register('zip', {
                                required: true
                            })}
                                type='text'
                                placeholder='Zipcode'
                                minLength={5}
                                required
                                className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6'
                            />
                            {errors.zip && <p className="text-status-critical text-xs">Please enter zip.</p>}

                        </div>
                    </div>
                    <div>
                        <label htmlFor='country' className="block text-sm font-semibold leading-6 text-black">
                            Country
                        </label>
                        <div className="mt-0.5">
                            <input {...register('country', {
                                required: true
                            })}
                                type='text'
                                placeholder='Country'
                                minLength={3}
                                required
                                className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6'
                            />
                            {errors.country && <p className="text-status-critical text-xs">Please enter country.</p>}

                        </div>
                    </div> */}
                    {error && (<p className="font-bold text-sm text-center text-status-critical">{error}</p>)}
                    <div className="pt-2">
                        <PrimaryButton
                            text={t('common:register')}
                            size={'medium'}
                            fullWidth={true}
                            font={'medium'}
                        />
                    </div>
                </form>
            </div>

        </>
    )
}

export default CreateTenantForm;