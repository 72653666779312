import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Organisationsnummer from 'organisationsnummer';
import { Controller, useForm } from 'react-hook-form';
import { useCreateSubsidiaryMutation } from '../../../../services/tenant/subsidiary/subsidiary.service';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from 'react-i18next';
import SubsidiarySelect from '../../subsidiary/subsidiary-select';
import { useDispatch } from 'react-redux';
import { SubsidiaryDto } from '../../../../services/tenant/subsidiary/dto/subsidiary.dto';
import PrimaryButton from '../../../atoms/primary-button';

export type SubsidiaryFormType = {
    company_name: string;
    org_number: string;
    active: boolean;
    address?: string;
    city?: string;
    zip?: string;
    country?: string;
    contact_phone?: string;
    contact_email?: string;
    logo?: string;
    subsidiary_parent_id?: string | null;
    copy_subsidiary_id?: string;
}

type Props = {
    setShowCreateSubsidiary?: (flag: boolean) => void;
    subsidiary?: SubsidiaryDto;
}

const CreateSubsidiaryForm = ({
    setShowCreateSubsidiary,
    subsidiary
}: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [createSubsidiary, response] = useCreateSubsidiaryMutation();
    const [error, setError] = useState<string>('');
    const [orgNumberError, setOrgNumberError] = useState<string>('');

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm<SubsidiaryFormType>({
        defaultValues: {
            company_name: subsidiary ? subsidiary.company_name : '',
            org_number: subsidiary ? subsidiary.org_number : '',
            active: subsidiary ? subsidiary.active : true,
            address: subsidiary ? subsidiary.address : '',
            city: subsidiary ? subsidiary.city : '',
            zip: subsidiary ? subsidiary.zip : '',
            country: subsidiary ? subsidiary.country : '',
            logo: '',
            subsidiary_parent_id: subsidiary ? subsidiary.subsidiary_parent_id : null,
            copy_subsidiary_id: subsidiary ? subsidiary.id : ''
        }
    });

    const validateOrgNumber = (value: string) => {
        const orgNumberValid = Organisationsnummer.valid(value);
        if (!orgNumberValid) {
            setOrgNumberError(t('page:company.invalidOrgNumber'))
            return false;
        }
        setOrgNumberError('');
        return true;
    }

    const handleSelectedSubsidiary = (selectOption: { label: string, value: string }) => {
        if (selectOption.value && selectOption.value.length > 1) {
            setValue('subsidiary_parent_id', selectOption.value);
        }
        else {
            setValue('subsidiary_parent_id', null);
        }
    }

    // Create subsidiary
    const submitSubsidiaryForm = async (formData: SubsidiaryFormType) => {
        try {
            const response = await createSubsidiary(formData).unwrap();

            if (response.success) {
                setError(''); // Reset error state
                NotificationManager.success(t('page:company.form.subsidiaryCreated'));
            }
            else {
                setError(t(`common:server.${response.message}`));
                NotificationManager.error(t('page:company.form.subsidiaryNotCreated'));
            }
        }
        catch (error) {
            setError('');
            NotificationManager.error(t('page:company.form.subsidiaryNotCreated'));
        }
    }

    return (
        <>
            <div className="md:mx-auto">
                <form className="space-y-2" onSubmit={handleSubmit(submitSubsidiaryForm)}>
                    <div>
                        <label htmlFor='company_name' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:companyName')}<span className="text-status-critical text-base">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="company_name"
                                control={control}
                                rules={{
                                    required: t('page:company.form.companyNameNotEmpty'),
                                    minLength: {
                                        value: 3,
                                        message: t('page:company.form.companyNameMinLength3')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={32}
                                            placeholder={t('common:companyName')}
                                            className={`${errors.company_name && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {errors.company_name && (<p className="text-status-critical mt-1 ml-1 text-xs">{errors.company_name.message}</p>)}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='org_number' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:orgNumber')}<span className="text-status-critical text-base">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="org_number"
                                control={control}
                                rules={{
                                    required: t('page:company.form.orgNumberNotEmpty'),
                                    validate: validateOrgNumber,
                                    minLength: {
                                        value: 10,
                                        message: t('page:company.form.orgNumberMinLength10')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={11}
                                            placeholder='123456-7890'
                                            className={`${errors.org_number && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {/* {errors.org_number && <p className="text-status-critical text-xs">Please enter organisation number.</p>} */}
                                        {errors.org_number && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.org_number.message ? errors.org_number.message : orgNumberError}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='subsidiary_id' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('page:company.subsidiaryBelongsToSubsidiary')}
                            <span
                                className="material-symbols-outlined text-gray-600 ml-1 align-sub"
                                style={{ fontSize: '1.2rem' }}
                                title={t('page:company.subsidiaryInfoSubsidiary')}
                            >help</span>
                        </label>
                        <SubsidiarySelect
                            handleSelectedSubsidiary={handleSelectedSubsidiary}
                            id={subsidiary?.subsidiary_parent_id}
                        />
                        {errors.subsidiary_parent_id && <p className="text-status-critical text-xs my-1 ml-1">{errors.subsidiary_parent_id.message}</p>}
                    </div>
                    <div>
                        <label htmlFor='address' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:address')}
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="address"
                                control={control}
                                rules={{
                                    minLength: {
                                        value: 3,
                                        message: t('common:form.addressMinLength3')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={32}
                                            placeholder={t('common:address')}
                                            className={`${errors.address && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {errors.address && (<p className="text-status-critical mt-1 ml-1 text-xs">{errors.address.message}</p>)}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='city' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:city')}
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="city"
                                control={control}
                                rules={{
                                    minLength: {
                                        value: 3,
                                        message: t('common:form.cityMinLength3')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={32}
                                            placeholder={t('common:city')}
                                            className={`${errors.city && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {errors.city && (<p className="text-status-critical mt-1 ml-1 text-xs">{errors.city.message}</p>)}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='zip' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:zip')}
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="zip"
                                control={control}
                                rules={{
                                    minLength: {
                                        value: 5,
                                        message: t('common:form.zipMinLength5')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={32}
                                            placeholder={t('common:zip')}
                                            className={`${errors.zip && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {errors.zip && (<p className="text-status-critical mt-1 ml-1 text-xs">{errors.zip.message}</p>)}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='country' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:country')}
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="country"
                                control={control}
                                rules={{
                                    minLength: {
                                        value: 2,
                                        message: t('common:form.countryMinLength2')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={32}
                                            placeholder={t('common:country')}
                                            className={`${errors.country && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {errors.country && (<p className="text-status-critical mt-1 ml-1 text-xs">{errors.country.message}</p>)}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    {error && (<p className="font-bold text-xs sm:text-sm pt-2 text-center text-status-critical">{error}</p>)}
                    <div>
                        <PrimaryButton
                            text={t('common:create')}
                            size={'medium'}
                            fullWidth={true}
                        />
                    </div>
                </form>
            </div>

        </>
    )
}

export default CreateSubsidiaryForm;