import React, { useEffect, useState } from 'react'
import { Grow } from '@mui/material';
import PrimaryButton from '../../../../atoms/primary-button';
import CreateCustomLogEntryForm from '../../../forms/log-entry-form/create-custom-log-entry';
import { LogTemplateDto } from '../../../../../services/tenant/log-template/dto/log-template.dto';
import LogsTable from '../../log-entries/logs-table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LogTypes } from '../../../../../services/enum/log-types';
import Modal from '../../../../molecules/modal';
import { EntityLogDto } from '../../../../../services/tenant/entity-log/dto/entity-log.dto';

type Props = {
    setSelectedEntityLog: (entityLog: EntityLogDto | null) => void;
    selectedEntityLog?: EntityLogDto | null;
    selectedLogTemplate: LogTemplateDto;
}

export type DisplayNameType = {
    logName: string;
    pboName: string;
}

const LogEntries = ({
    setSelectedEntityLog,
    selectedEntityLog,
    selectedLogTemplate,
}: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [showCreateLog, setShowCreateLog] = useState<boolean>(false);
    // Display name based on property / building / object name and log name
    const [displayName, setDisplayName] = useState<DisplayNameType>({ logName: '', pboName: '' });

    useEffect(() => {
        if (selectedEntityLog) {
            let logName = '';
            let pboName = '';

            // Get correct property / building / object name
            if (selectedEntityLog.property_object_name) {
                pboName = selectedEntityLog.property_object_name
            }
            else if (selectedEntityLog.property_building_label) {
                pboName = selectedEntityLog.property_building_label;
            }
            if (selectedEntityLog.property_label) {
                pboName = selectedEntityLog.property_label;
            }

            // Get correct log name
            logName = selectedEntityLog.log_type === LogTypes.Default ?
                t(`page:logs.defaultLogs.${selectedEntityLog.log_template_name.split('_').join('')}`) :
                selectedEntityLog.log_template_name.split('_').join(' ')

            setDisplayName({
                logName,
                pboName
            })
        }
    }, [selectedEntityLog])

    return (
        <>
            {selectedEntityLog && (
                <Grow in={selectedEntityLog ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                    <div className="w-full text-black">

                        <div className="border-b border-primary-blue-5 pb-2">
                            <div className="">
                                <div className="flex items-center gap-x-8">
                                    <button
                                        className="material-symbols-outlined p-0.5 bg-primary-blue-5 rounded-md"
                                        style={{ fontSize: '1.5rem' }}
                                        onClick={() => setSelectedEntityLog(null)}
                                    >chevron_left</button>
                                    <h2 className="text-xl sm:text-2xl xl:text-3xl font-bold capitalize">
                                        {displayName?.logName + ' - ' + displayName?.pboName}
                                    </h2>
                                </div>
                            </div>

                            <div className="flex items-center gap-x-3 mt-10 text-xs font-semibold">
                                <button
                                    className="pb-0.5"
                                    onClick={() => setSelectedEntityLog(null)}>
                                    {t('common:logs')}
                                </button>
                                <span
                                    className="material-symbols-outlined py-1"
                                    style={{ fontSize: '1rem' }}>
                                    chevron_right
                                </span>
                                <button
                                    className="pb-0.5">
                                    {displayName?.pboName}
                                </button>
                                <span
                                    className="material-symbols-outlined py-1"
                                    style={{ fontSize: '1rem' }}>
                                    chevron_right
                                </span>
                                <button
                                    className="pb-0.5 capitalize text-primary-blue">
                                    {selectedEntityLog.log_template_name.split('_').join(' ')}
                                </button>
                            </div>
                        </div>


                        <div className="flex justify-end mt-5">
                            <PrimaryButton
                                text={t('page:logs.createLog')}
                                size={'medium'}
                                wide={true}
                                startAdornment={'add'}
                                onClick={() => setShowCreateLog(!showCreateLog)}
                            />
                        </div>
                        <div>
                            <LogsTable
                                selectedEntityLog={selectedEntityLog}
                                displayName={displayName}
                                selectedLogTemplate={selectedLogTemplate}
                            />
                        </div>

                        <Modal isOpen={showCreateLog} close={() => setShowCreateLog(false)} title={displayName?.logName + ' - ' + displayName?.pboName} size='md' titleAlign='center'>
                            <CreateCustomLogEntryForm
                                setShowCreateLog={setShowCreateLog}
                                entityLog={selectedEntityLog}
                                logTemplate={selectedLogTemplate} />
                        </Modal>
                    </div>
                </Grow>
            )}
        </>
    )
}

export default LogEntries