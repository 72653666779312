import { reAuthAPI } from "../../re-auth/re-auth";
import { PropertyObjectUserAccessDto } from "./dto/property-object-user-access.dto";
import { UpdatePropertyObjectUserAccessDto } from "./dto/update-property-object-user-access.dto";

export const propertyObjectUserAccessAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Get all property object user access relations
        getAllPropertyObjectUserAccess: builder.query<PropertyObjectUserAccessDto[], void>({
            query: () => ({
                url: '/property-object-user-access/users',
                method: 'GET',
            }),
            providesTags: ['PropertyObjectUserAccess']
        }),
        // Get all property object user access relations by ids
        getAllPropertyObjectUserAccessByIds: builder.query<PropertyObjectUserAccessDto[], string[]>({
            query: (ids) => ({
                url: '/property-object-user-access/users/ids?ids=' + ids,
                method: 'GET',
            }),
            providesTags: ['PropertyObjectUserAccess']
        }),
        // Get all property object user access relations by given id
        getAllPropertyObjectUserAccessById: builder.query<PropertyObjectUserAccessDto[], string>({
            query: (id) => ({
                url: '/property-object-user-access/users/id/' + id,
                method: 'GET',
            }),
            providesTags: ['PropertyObjectUserAccess']

        }),
        // Update many property object user access relations
        updateManyPropertyObjectUserAccess: builder.mutation<any, UpdatePropertyObjectUserAccessDto[]>({
            query: (propertyObjectUserAccess) => ({
                url: '/property-object-user-access/users',
                method: 'PUT',
                body: propertyObjectUserAccess
            }),
            invalidatesTags: ['PropertyObjectUserAccess']
        }),
    })
})

export const {
    useGetAllPropertyObjectUserAccessQuery,
    useGetAllPropertyObjectUserAccessByIdsQuery,
    useGetAllPropertyObjectUserAccessByIdQuery,
    useUpdateManyPropertyObjectUserAccessMutation,

} = propertyObjectUserAccessAPI;