import { Fragment, useEffect, useRef, useState } from "react";
import { dateToLocaleDate } from "../../../../utils/date-time";

import Modal from "../../../molecules/modal";
import ConfirmModal from "../../../molecules/confirm-modal";
import { useDeletePropertyBuildingMutation, useGetPropertyBuildingsQuery } from "../../../../services/tenant/property-building/property-building.service";
import { PropertyBuildingDto } from "../../../../services/tenant/property-building/dto/property-building.dto";
import UpdatePropertyBuildingForm from "../../forms/property-building/update-property-building-form";
import { NotificationManager } from 'react-notifications';
import PropertyBuildingUserAccess from "../property-building-user-access";
import PropertyBuildingObjectAccess from "../property-building-object-access";
import PropertyBuildingLogAccess from "../property-building-log-access";
import { useAppSelector } from "../../../../redux/hooks";
import { getCurrentUser } from "../../../../redux/slices/user.slice";
import { useTranslation } from "react-i18next";

import { Input } from "@mui/material";
import { debounce } from "../../../../utils/debounce";
import { Order } from "../../../../services/enum/order";
import Loader from "../../../atoms/loader/loader";
import FilterTableColumns from "../../../molecules/filter-table-columns";
import { Menu, Transition } from "@headlessui/react";
import CreatePropertyBuildingForm from "../../forms/property-building/create-property-building-form";
import Pagination from "../../../molecules/pagination";
import Table from "../../../molecules/table";

// PropertyBuilding type with property label added for display in table and sort
type PropertyBuildingTableType = {
    id: string;
    tenant_id: string;
    property_id: string;
    property_label: string;
    label: string;
    name?: string;
    object_number?: string;
    created_at: Date;
    updated_at: Date;
    address?: string;
    image?: string;
}

type Props = {}

const PropertyBuildingTable = ({ }: Props) => {
    const { t } = useTranslation();
    const { currentUser } = useAppSelector(getCurrentUser);

    // Delete property building
    const [deletePropertyBuilding, { isLoading: deleteIsLoading, error: deleteError }] = useDeletePropertyBuildingMutation();

    const [showCopyModal, setShowCopyModal] = useState<boolean>(false); // Toggle copy modal
    const [showModal, setShowModal] = useState<boolean>(false); // Toggle modal
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false); // Toggle confirm modal
    const [selectedPropertyBuilding, setSelectedPropertyBuilding] = useState<PropertyBuildingDto>();
    const [tableHeaders, setTableHeaders] = useState<string[]>();

    // Pagination
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>();
    const [hasNextPage, setHasNextPage] = useState<boolean>();
    const [hasPrevPage, setHasPrevPage] = useState<boolean>();

    // Table sort and search
    const [search, setSearch] = useState<string | undefined>('');
    const [searchOperation, setSearchOperation] = useState<string | undefined>();
    const [sortBy, setSortBy] = useState<string | undefined>('label'); // Column to sort by
    const [sortOrder, setSortOrder] = useState<Order>(Order.ASC); // Sort order
    const [showSortMenu, setShowSortMenu] = useState<boolean>(false); // Sort menu on small screens

    const debouncedSearch = useRef(debounce((search: string) => setSearchOperation(search), 500)).current;

    // Get property buildings paginated
    const { data: propertyBuildings, isFetching } = useGetPropertyBuildingsQuery({
        page: page,
        take: 25,
        search: searchOperation,
        sortBy: sortBy,
        order: sortOrder
    });

    useEffect(() => {
        if (propertyBuildings) {
            setPage(propertyBuildings.meta.page);
            setPageCount(propertyBuildings.meta.pageCount);
            setHasNextPage(propertyBuildings.meta.hasNextPage);
            setHasPrevPage(propertyBuildings.meta.hasPreviousPage);
        }
    }, [propertyBuildings])

    const handlePagination = (page: number) => {
        setPage(page);
    }

    // Handle sort by column and sort order
    const handleSort = (column: string, order: Order) => {
        setSortBy(column);
        setSortOrder(order === Order.ASC ? Order.DESC : Order.ASC)
    }

    // If selectedPropertyBuilding changes, set updated propertyBuilding
    useEffect(() => {
        if (selectedPropertyBuilding) {
            const match = propertyBuildings?.data.find((e) => e.id === selectedPropertyBuilding.id);
            if (match) {
                setSelectedPropertyBuilding(match);
            }
        }
    }, [propertyBuildings]);

    // Edit property building
    const handleEditPropertyBuilding = (propertyBuilding: PropertyBuildingTableType) => {
        setShowModal(true); // Show modal
        setSelectedPropertyBuilding(propertyBuilding);
    }

    // Copy property building
    const handleCopyPropertyBuilding = (propertyBuilding: PropertyBuildingTableType) => {
        setShowCopyModal(true);
        setSelectedPropertyBuilding(propertyBuilding);
    }

    // Toggle modal and setSelected user
    const handleDeletePropertyBuilding = (propertyBuilding: PropertyBuildingTableType) => {
        setSelectedPropertyBuilding(propertyBuilding); // Set selected property building to be deleted if confirmed in modal
        setShowConfirmModal(true); // Show confirm modal
    }

    // Delete property building after confirmation
    const deletePropertyBuildingConfirmed = async () => {
        const propertyBuildingToBeDeleted = selectedPropertyBuilding;
        if (propertyBuildingToBeDeleted?.id) {

            const payload = {
                property_building_id: propertyBuildingToBeDeleted.id,
            }

            try {
                const response = await deletePropertyBuilding(payload).unwrap();
                if (response.success) {
                    NotificationManager.success(t('page:properties.form.buildingDeleted'));
                    setShowConfirmModal(false);
                }
                else {
                    NotificationManager.error(t('page:properties.form.buildingNotDeleted'));
                }
            }
            catch (error) {
                NotificationManager.error(t('page:properties.form.buildingNotDeleted'));
            }
        }
    }

    const handlePropertyBuilding = () => {

    }

    // Define available column headers to be sent to FilterTableColumns
    const columnHeaders = ['label', 'object_number', 'name', 'property', 'address', 'created_at'];

    // Gets triggered by FilterTableColumns when columns change
    const handleColumnsToDisplay = (columns: string[]) => {
        setTableHeaders(columns);
    }

    return (
        <>
            {(propertyBuildings && propertyBuildings?.data.length < 1 && !searchOperation) ? (
                <>
                    <div className="w-10/12 xl:w-8/12 max-w-2xl mx-auto bg-primary-blue-5 text-black rounded-md px-2 py-4 mt-10 text-center">
                        <p className="text-lg font-semibold">{t('page:properties.noBuildingsFound')}...</p>
                        <p className="mt-2">{t('page:properties.noBuildingsFoundInfo')}</p>
                    </div>
                </>
            ) : (
                <div className="w-full mx-auto md:mt-1">
                    <div className="hidden md:block">
                        <div className="sm:w-6/12 max-w-2xl mt-2 text-center sm:text-left">
                            <Input
                                type="search"
                                name="search_name"
                                placeholder={t('common:search')}
                                disableUnderline={true}
                                value={search}
                                className="w-full border rounded-md text-base md:text-sm px-2 pt-2.5 pb-1 mt-3 bg-white"
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                    debouncedSearch(e.target.value);
                                }}
                                endAdornment={
                                    <span
                                        className="material-symbols-outlined pb-1 text-gray-500 cursor-pointer"
                                        style={{ fontSize: '1.3rem' }}
                                        onClick={() => {
                                            if (search) {
                                                setSearch('')
                                                debouncedSearch('')
                                            }
                                        }}
                                    >
                                        {search ? 'close' : 'search'}
                                    </span>
                                }
                                size="small"
                            />
                        </div>
                        <div className="w-full mt-1 mb-2">
                            <FilterTableColumns
                                tableColumns={columnHeaders}
                                tableType='pbt_filter'
                                handleColumnsToDisplay={handleColumnsToDisplay}
                            />
                        </div>
                        <div className="overflow-x-scroll">
                            <Table>
                                <Table.Thead>
                                    <tr>
                                        <th scope="col" className="w-20 hidden sm:table-cell">
                                            <span className="sr-only">Building image</span>
                                        </th>
                                        {tableHeaders?.includes('label') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]"
                                                onClick={() => handleSort('label', sortOrder)}>
                                                {t('common:label')}
                                                {(sortBy === 'label') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </th>
                                        )}
                                        {tableHeaders?.includes('object_number') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 pr-2 cursor-pointer font-bold min-w-[8rem]"
                                                onClick={() => handleSort('object_number', sortOrder)}>
                                                {t('common:objectNumber')}
                                                {(sortBy === 'object_number') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </th>
                                        )}
                                        {tableHeaders?.includes('name') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 pr-2 cursor-pointer font-bold min-w-[6rem]">
                                                {t('common:name')}
                                            </th>
                                        )}
                                        {tableHeaders?.includes('property') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]">
                                                {t('common:property')}
                                            </th>
                                        )}
                                        {tableHeaders?.includes('address') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 cursor-pointer font-bold min-w-[6rem]">
                                                {t('common:address')}
                                            </th>
                                        )}
                                        {tableHeaders?.includes('created_at') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 cursor-pointer font-bold min-w-[6rem]"
                                                onClick={() => handleSort('created_at', sortOrder)}>
                                                {t('common:createdAt')}
                                                {(sortBy === 'created_at') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </th>
                                        )}
                                        <th scope="col" className="">
                                            <span className="sr-only">Copy</span>
                                        </th>
                                        <th scope="col" className="">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                        <th scope="col" className="">
                                            <span className="sr-only">Delete</span>
                                        </th>
                                    </tr>
                                </Table.Thead>
                                {propertyBuildings && propertyBuildings.data.length > 0 && !isFetching && (
                                    <Table.Tbody>
                                        {propertyBuildings?.data.map((propertyBuilding, idx) => {
                                            return (
                                                <tr key={idx} className={`odd:hover:bg-primary-blue-10 even:hover:bg-gray-50 cursor-pointer text-xs text-black even:bg-white odd:bg-primary-blue-5`}>
                                                    <td scope="row" className="pl-0.5 capitalize hidden sm:table-cell py-1">
                                                        {propertyBuilding.image ? (
                                                            <div className="flex items-center w-9 lg:w-12 h-9 lg:h-12 rounded-full bg-white overflow-hidden">
                                                                <img
                                                                    src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${propertyBuilding.tenant_id}/${propertyBuilding.image}`}
                                                                    // onError={e => e.currentTarget.src = DefaultBuilding}
                                                                    alt="Profile picture"
                                                                    className="w-9 md:w-12 h-9 md:h-12 rounded-full object-cover lg:p-0.5" />
                                                            </div>
                                                        ) : (
                                                            <div className="flex items-center justify-center w-9 lg:w-12 h-9 lg:h-12 rounded-full border border-gray-100">
                                                                <span
                                                                    className="material-symbols-outlined"
                                                                    style={{ fontSize: '1.3rem' }}
                                                                >
                                                                    home
                                                                </span>
                                                            </div>
                                                        )}
                                                    </td>
                                                    {tableHeaders?.includes('label') && (
                                                        <td
                                                            className="py-2 pr-2 pl-1 sm:pl-2 text-xs capitalize font-semibold sm:font-normal max-w-[9rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            title={propertyBuilding?.label}
                                                            onClick={handlePropertyBuilding}>
                                                            {propertyBuilding.label}
                                                        </td>
                                                    )}
                                                    {tableHeaders?.includes('object_number') && (
                                                        <td
                                                            className="py-2 pr-2 capitalize max-w-[8rem] min-w-[8rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            title={propertyBuilding?.object_number}
                                                            onClick={handlePropertyBuilding}>
                                                            {propertyBuilding.object_number ? propertyBuilding.object_number : '-'}
                                                        </td>
                                                    )}
                                                    {tableHeaders?.includes('name') && (
                                                        <td
                                                            className="py-2 pr-2 capitalize max-w-[7rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            title={propertyBuilding?.name}
                                                            onClick={handlePropertyBuilding}>
                                                            {propertyBuilding.name ? propertyBuilding.name : '-'}
                                                        </td>
                                                    )}

                                                    {tableHeaders?.includes('property') && (
                                                        <td
                                                            className="py-2 pr-2 pl-1 sm:pl-2 text-xs capitalize font-semibold sm:font-normal max-w-[9rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            title={propertyBuilding?.property_label}
                                                            onClick={handlePropertyBuilding}>
                                                            {propertyBuilding.property_label ? propertyBuilding.property_label : '-'}
                                                        </td>
                                                    )}
                                                    {tableHeaders?.includes('address') && (
                                                        <td
                                                            className="py-2 pr-2 max-w-[7rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            title={propertyBuilding?.address}
                                                            onClick={handlePropertyBuilding}>
                                                            {propertyBuilding.address ? propertyBuilding.address : '-'}
                                                        </td>
                                                    )}
                                                    {tableHeaders?.includes('created_at') && (
                                                        <td
                                                            className="py-2 max-w-[7rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            title={propertyBuilding.created_at && dateToLocaleDate(propertyBuilding.created_at)}
                                                            onClick={handlePropertyBuilding}>
                                                            {propertyBuilding.created_at ? dateToLocaleDate(propertyBuilding.created_at) : '-'}
                                                        </td>
                                                    )}
                                                    <td className="px-2 lg:px-1">
                                                        <button
                                                            onClick={() => handleCopyPropertyBuilding(propertyBuilding)}
                                                            className="text-black material-symbols-outlined"
                                                            style={{ fontSize: '1.2rem' }}
                                                            title={t('common:duplicate')}
                                                        >content_copy</button>
                                                    </td>
                                                    <td className="pb-1 px-2 lg:px-1">
                                                        <button
                                                            onClick={() => handleEditPropertyBuilding(propertyBuilding)}
                                                            className="text-black material-symbols-outlined"
                                                            style={{ fontSize: '1.2rem' }}
                                                            title={t('common:edit')}
                                                        >edit_square</button>
                                                    </td>
                                                    <td className="px-2 lg:px-1">
                                                        <button
                                                            onClick={() => handleDeletePropertyBuilding(propertyBuilding)}
                                                            className="material-symbols-outlined text-status-critical"
                                                            style={{ fontSize: '1.3rem' }}
                                                            title={t('common:delete')}
                                                        >delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </Table.Tbody>
                                )}
                            </Table>
                            {isFetching && (
                                <div className="mx-auto mb-5 mt-1">
                                    <Loader show={isFetching} size='small' />
                                </div>
                            )}

                            {!propertyBuildings || propertyBuildings.data.length < 1 && (
                                <div className="my-5">
                                    <p className="text-sm ml-7 md:ml-5 font-semibold text-black">{t('page:properties.noBuildingsFound')}...</p>
                                </div>
                            )}
                        </div>
                        {propertyBuildings && propertyBuildings.meta && (
                            <div className="flex justify-center py-2">
                                <Pagination
                                    count={pageCount}
                                    page={page}
                                    defaultPage={1}
                                    showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                                    showLastButton={pageCount ? pageCount > 5 : false}
                                    hidePrevButton={!hasPrevPage}
                                    hideNextButton={!hasNextPage}
                                    onChange={handlePagination}
                                />
                            </div>
                        )}
                    </div>

                    {/* Mobile screen  */}
                    <div className="block md:hidden w-full mx-auto mt-1">
                        <div className="pt-1">
                            <Input
                                type="search"
                                name="search_name"
                                placeholder={t('common:search')}
                                disableUnderline={true}
                                className="border rounded-md px-2 pt-2.5 pb-1 mt-3 w-10/12 bg-white"
                                onChange={(e) => debouncedSearch(e.target.value)}
                                endAdornment={<span className="material-symbols-outlined text-gray-500 pb-1" style={{ fontSize: '1.3rem' }}>search</span>}
                                size="small"
                            />
                            <Menu as="div" className="relative inline-block text-left cursor-pointer">
                                <div>
                                    <Menu.Button
                                        className="material-symbols-outlined text-black align-sub ml-3 rotate-90 border rounded-md px-1 py-1"
                                        style={{ fontSize: '1.4rem' }}
                                        onClick={() => setShowSortMenu(!showSortMenu)}>
                                        sync_alt
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className="absolute right-0 z-10 mt-2 w-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="">
                                            <Menu.Item>
                                                <div className="flex hover:bg-primary-blue-5"
                                                    onClick={(e) => handleSort('label', sortOrder)}>
                                                    <span className="w-1/2 block px-2 py-2 text-sm">{t('common:label')}</span>
                                                    <div className="w-1/2 py-2 pr-1">
                                                        {(sortBy === 'label') ? (
                                                            <>
                                                                {sortOrder === Order.ASC ? (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                                ) : (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <div className="flex hover:bg-primary-blue-5" onClick={() => handleSort('object_number', sortOrder)}>
                                                    <span className="w-1/2 block px-2 py-2 text-sm">{t('common:objectNumber')}</span>
                                                    <div className="w-1/2 py-2 pr-1">
                                                        {(sortBy === 'object_number') ? (
                                                            <>
                                                                {sortOrder === Order.ASC ? (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                                ) : (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <div className="flex hover:bg-primary-blue-5" onClick={() => handleSort('created_at', sortOrder)}>
                                                    <span className="w-1/2 block px-2 py-2 text-sm">{t('common:createdAt')}</span>
                                                    <div className="w-1/2 py-2 pr-1">
                                                        {(sortBy === 'created_at') ? (
                                                            <>
                                                                {sortOrder === Order.ASC ? (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                                ) : (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                        {propertyBuildings?.data && propertyBuildings.data.length > 0 && !isFetching && (
                            <div>
                                {propertyBuildings?.data?.map((propertyBuilding, idx) => (
                                    <div key={idx} className="my-2 p-2 text-xs relative odd:bg-primary-blue-5 odd:hover:bg-primary-blue-10 even:border border-primary-blue-5 hover:even:bg-gray-50 rounded-md">
                                        <div className="flex flex-col flex-wrap gap-x-5 my-1">
                                            <div>
                                                <button
                                                    onClick={() => handleCopyPropertyBuilding(propertyBuilding)}
                                                    className="text-black material-symbols-outlined absolute right-[4.5rem] top-2"
                                                    style={{ fontSize: '1.2rem' }}
                                                    title={t('common:duplicate')}
                                                >content_copy</button>
                                                <button
                                                    onClick={() => handleEditPropertyBuilding(propertyBuilding)}
                                                    className="text-black material-symbols-outlined absolute right-10 top-1.5"
                                                    style={{ fontSize: '1.2rem' }}
                                                    title={t('common:edit')}
                                                >edit_square</button>
                                                <button
                                                    onClick={() => handleDeletePropertyBuilding(propertyBuilding)}
                                                    className="material-symbols-outlined text-status-critical disabled:text-gray-300 absolute right-2 top-2"
                                                    style={{ fontSize: '1.3rem' }}
                                                    title={t('common:delete')}
                                                >delete</button>
                                            </div>

                                            <div className="flex items-center">
                                                <div className="sm:ml-5">
                                                    {propertyBuilding.image ? (
                                                        <div className="flex items-center rounded-full bg-white overflow-hidden">
                                                            <img
                                                                src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${propertyBuilding.tenant_id}/${propertyBuilding.image}`}
                                                                // onError={e => e.currentTarget.src = DefaultPersonImg}
                                                                alt="Profile picture"
                                                                className="rounded-full w-12 sm:w-14 h-12 sm:h-14 object-cover" />
                                                        </div>
                                                    ) : (
                                                        <div className="flex items-center w-12 sm:w-14 h-12 sm:h-14 justify-center rounded-full border border-gray-100">
                                                            <span
                                                                className="material-symbols-outlined"
                                                                style={{ fontSize: '1.4rem' }}
                                                            >
                                                                home
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="ml-3 sm:ml-10">
                                                    <p className="font-semibold text-sm capitalize">{propertyBuilding.label}</p>
                                                    <p>{t('common:belongsTo')}: {propertyBuilding.property_label ? propertyBuilding.property_label : '-'}</p>
                                                    <p>{propertyBuilding.object_number ? propertyBuilding.object_number : '-'}</p>
                                                    <p>{propertyBuilding.name ? propertyBuilding.name : ''}</p>
                                                    <p>{propertyBuilding.address ? propertyBuilding.address : ''}</p>
                                                </div>

                                                <p className="absolute right-2 bottom-2">{dateToLocaleDate(propertyBuilding.created_at)}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        )}
                        {isFetching && (
                            <div className="mx-auto mb-5 mt-1">
                                <Loader show={isFetching} size='small' />
                            </div>
                        )}

                        {!propertyBuildings || propertyBuildings.data.length < 1 && (
                            <div className="my-2">
                                <p className="text-sm ml-7 md:ml-5 font-semibold text-black">{t('page:properties.noBuildingsFound')}...</p>
                            </div>
                        )}
                        {propertyBuildings && propertyBuildings.meta && (
                            <div className="flex justify-center py-2">
                                <Pagination
                                    count={pageCount}
                                    page={page}
                                    defaultPage={1}
                                    showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                                    showLastButton={pageCount ? pageCount > 5 : false}
                                    hidePrevButton={!hasPrevPage}
                                    hideNextButton={!hasNextPage}
                                    onChange={handlePagination}
                                />
                            </div>
                        )}
                    </div>

                    <Modal isOpen={showCopyModal} close={() => setShowCopyModal(false)} title={`${t('page:properties.createBuilding')} (${t('common:duplicate')})`} size='sm' titleAlign='center'>
                        <CreatePropertyBuildingForm propertyBuilding={selectedPropertyBuilding} />
                    </Modal>

                    <Modal isOpen={showModal} close={() => setShowModal(false)} title={`${selectedPropertyBuilding?.label}`} size='sm' titleAlign='center'>
                        <div>
                            <div className="flex flex-wrap justify-center mx-auto mb-5">
                                <div className="w-10/12 sm:w-full">
                                    <UpdatePropertyBuildingForm propertyBuilding={selectedPropertyBuilding} showPropertyBuildingModal={setShowModal} />

                                    <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                                    <PropertyBuildingLogAccess propertyBuilding={selectedPropertyBuilding} />

                                    <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                                    <PropertyBuildingUserAccess propertyBuilding={selectedPropertyBuilding} />

                                    <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                                    <PropertyBuildingObjectAccess propertyBuilding={selectedPropertyBuilding} />
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <ConfirmModal isOpen={showConfirmModal} close={() => setShowConfirmModal(false)} title={t('page:properties.deleteBuilding')} titleAlign='center' type='warning'>
                        <div className="bg-red-200 rounded p-1">
                            <p className="text-xs font-semibold my-1 text-black">{t('page:properties.deleteBuildingInfo')}</p>
                            <p className="text-xs font-semibold mt-2 mb-1 text-black">{t('page:properties.deleteBuildingLogInfo')}</p>
                        </div>
                        <p className="text-lg font-semibold mt-3">{t('common:confirmDelete')} {selectedPropertyBuilding?.label ? selectedPropertyBuilding.label : 'the selected building'}?</p>
                        <button
                            onClick={deletePropertyBuildingConfirmed}
                            className="bg-status-critical hover:bg-status-critical-h py-2 px-2 sm:px-6 mt-3 rounded mx-auto text-sm sm:text-base text-white font-semibold">{t('common:delete')}</button>
                    </ConfirmModal>
                </div>
            )}
        </>
    )
}

export default PropertyBuildingTable;