import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import InputCheckbox from '../../../../atoms/select-checkbox';

type SelectOption = {
    label?: string;
    value?: string;
}

type Props = {
    allPropertyLabels: string[];
    allBuildingLabels: string[];
    allObjectNames: string[];

    setSelectedProperties: (labels: string[]) => void;
    selectedProperties: string[];
    setSelectedBuildings: (labels: string[]) => void;
    selectedBuildings: string[];
    setSelectedObjects: (names: string[]) => void;
    selectedObjects: string[];
}

const LogFilterByEntities = ({
    allPropertyLabels,
    allBuildingLabels,
    allObjectNames,

    setSelectedProperties,
    selectedProperties,
    setSelectedBuildings,
    selectedBuildings,
    setSelectedObjects,
    selectedObjects
}: Props) => {
    const { t } = useTranslation();

    // Filter based on property, building or object
    const [allProperties, setAllProperties] = useState<string[]>();
    const [allBuildings, setAllBuildings] = useState<string[]>();
    const [allObjects, setAllObjects] = useState<string[]>();

    useEffect(() => {
        if (allPropertyLabels || allBuildingLabels || allObjectNames) {
            setInitialFilters(allPropertyLabels, allBuildingLabels, allObjectNames)
        }
    }, [allPropertyLabels, allBuildingLabels, allObjectNames])

    const setInitialFilters = (
        propertyLabels?: string[],
        buildingLabels?: string[],
        objectNames?: string[]
    ) => {
        const uniquePropertyLabels: string[] = [];
        const uniqueBuildingLabels: string[] = [];
        const uniqueObjectNames: string[] = [];
        propertyLabels?.forEach(pl => {
            if (!uniquePropertyLabels.includes(pl)) {
                uniquePropertyLabels.push(pl)
            }
        })
        buildingLabels?.forEach(bl => {
            if (!uniqueBuildingLabels.includes(bl)) {
                uniqueBuildingLabels.push(bl);
            }
        })
        objectNames?.forEach(on => {
            if (!uniqueObjectNames.includes(on)) {
                uniqueObjectNames.push(on)
            }
        })

        setAllProperties(uniquePropertyLabels);
        setAllBuildings(uniqueBuildingLabels);
        setAllObjects(uniqueObjectNames);
    }

    const handleSelectedFilter = (option: SelectOption | null, type: string) => {
        if (type === 'property') {
            if (option && option.value) {
                if (!selectedProperties.includes(option.value)) {
                    setSelectedProperties([...selectedProperties, option.value]);
                }
                else {
                    const allSelectedProperties = [...selectedProperties];
                    const index = allSelectedProperties.indexOf(option.value);
                    if (index !== -1) {
                        allSelectedProperties.splice(index, 1);
                        setSelectedProperties(allSelectedProperties);
                    }
                }
            }
        }
        else if (type === 'building') {
            if (option && option.value) {
                if (!selectedBuildings.includes(option.value)) {
                    setSelectedBuildings([...selectedBuildings, option.value]);
                }
                else {
                    const allselectedBuildings = [...selectedBuildings];
                    const index = allselectedBuildings.indexOf(option.value);
                    if (index !== -1) {
                        allselectedBuildings.splice(index, 1);
                        setSelectedBuildings(allselectedBuildings);
                    }
                }
            }
        }
        else if (type === 'object') {
            if (option && option.value) {
                if (!selectedObjects.includes(option.value)) {
                    setSelectedObjects([...selectedObjects, option.value]);
                }
                else {
                    const allSelectedObjects = [...selectedObjects];
                    const index = allSelectedObjects.indexOf(option.value);
                    if (index !== -1) {
                        allSelectedObjects.splice(index, 1);
                        setSelectedObjects(allSelectedObjects);
                    }
                }
            }
        }
    }

    return (
        <div>
            <div className="w-full flex flex-col sm:flex-row gap-y-1 sm:gap-y-0 sm:gap-x-1 md:gap-x-2 py-2 items-center sm:items-start">
                {(allProperties && allProperties.length > 0) && (
                    <div className="w-full 2xl:w-3/12">
                        <Select
                            className="text-base sm:text-xs xl:text-sm z-50"
                            options={allProperties.map(p => ({
                                label: p,
                                value: p
                            }))}
                            onChange={(e) => handleSelectedFilter(e, 'property')}
                            value={selectedProperties.map(sp => ({
                                label: sp,
                                value: sp
                            }))}
                            closeMenuOnSelect={false}
                            placeholder={t('common:properties')}
                            components={{
                                // @ts-ignore
                                Option: InputCheckbox
                            }}
                            noOptionsMessage={() => t('page:properties.noPropertiesFound')}
                            controlShouldRenderValue={false}

                        />
                    </div>
                )}
                {(allBuildings && allBuildings.length > 0) && (
                    <div className="w-full 2xl:w-3/12">
                        <Select
                            className="text-base sm:text-xs xl:text-sm z-40"
                            options={allBuildings.map(b => ({
                                label: b,
                                value: b
                            }))}
                            onChange={(e) => handleSelectedFilter(e, 'building')}
                            // isMulti={true}
                            value={selectedBuildings.map(sb => ({
                                label: sb,
                                value: sb
                            }))}
                            closeMenuOnSelect={false}
                            // controlShouldRenderValue={true}
                            placeholder={t('common:buildings')}
                            components={{
                                // @ts-ignore
                                Option: InputCheckbox
                            }}
                            noOptionsMessage={() => t('page:properties.noBuildingsFound')}
                            controlShouldRenderValue={false}

                        />
                    </div>
                )}
                {(allObjects && allObjects.length > 0) && (
                    <div className="w-full 2xl:w-3/12">
                        <Select
                            className="text-base sm:text-xs xl:text-sm z-30"
                            options={allObjects.map(o => ({
                                label: o,
                                value: o
                            }))}
                            onChange={(e) => handleSelectedFilter(e, 'object')}
                            // isMulti={true}
                            value={selectedObjects.map(so => ({
                                label: so,
                                value: so
                            }))}
                            closeMenuOnSelect={false}
                            // controlShouldRenderValue={true}
                            placeholder={t('common:objects')}
                            components={{
                                // @ts-ignore
                                Option: InputCheckbox
                            }}
                            noOptionsMessage={() => t('page:properties.noObjectsFound')}
                            controlShouldRenderValue={false}

                        />
                    </div>
                )}
            </div>
            <div className="mx-auto w-full text-xs text-black flex flex-col px-1">
                <div className="flex flex-wrap">
                    {selectedProperties.map((property) => {
                        return (
                            <span className="flex items-center m-1 bg-green-100 text-black py-1 pl-1 pr-2 font-semibold rounded w-fit" key={property}>
                                <span
                                    className="material-symbols-outlined mb-[-2px] cursor-pointer text-black  mr-1"
                                    style={{ fontSize: '1.3rem' }}
                                    onClick={() => handleSelectedFilter({ label: property, value: property }, 'property')}
                                >close</span>
                                {property}
                            </span>
                        )
                    })}
                </div>

                <div className="flex flex-wrap">
                    {selectedBuildings.map((building) => {
                        return (
                            <span className="flex items-center m-1 bg-blue-100 text-black py-1 pl-1 pr-2 font-semibold rounded w-fit" key={building}>
                                <span
                                    className="material-symbols-outlined mb-[-2px] cursor-pointer text-black  mr-1"
                                    style={{ fontSize: '1.3rem' }}
                                    onClick={() => handleSelectedFilter({ label: building, value: building }, 'building')}
                                >close</span>
                                {building}
                            </span>
                        )
                    })}
                </div>

                <div className="flex flex-wrap">
                    {selectedObjects.map((object) => {
                        return (
                            <span className="flex items-center m-1 bg-orange-100 text-black py-1 pl-1 pr-2 font-semibold rounded w-fit" key={object}>
                                <span
                                    className="material-symbols-outlined mb-[-2px] cursor-pointer text-black  mr-1"
                                    style={{ fontSize: '1.3rem' }}
                                    onClick={() => handleSelectedFilter({ label: object, value: object }, 'object')}
                                >close</span>
                                {object}
                            </span>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default LogFilterByEntities;