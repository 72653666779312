import { Switch } from '@mui/material';
import React, { BaseSyntheticEvent, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useInviteUserMutation } from '../../../../services/tenant/user/user.service';
import { useAppSelector } from '../../../../redux/hooks';
import { getCurrentUser } from '../../../../redux/slices/user.slice';
import { TenantStateType } from '../../../../services/dto/tenant-state-type';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from 'react-i18next';
import { getTenant } from '../../../../redux/slices/tenant.slice';
import { SendUserInviteDto } from '../../../../services/tenant/user/dto/send-user-invite.dto';
import PrimaryButton from '../../../atoms/primary-button';

type Props = {
    tenant?: TenantStateType
}

export type InviteUserFormType = {
    email: string;
    external_domain: boolean;
}

const InviteUserForm = ({ tenant }: Props) => {
    const { t } = useTranslation();
    const { currentUser } = useAppSelector(getCurrentUser);

    const { control, setValue, handleSubmit, formState: { errors } } = useForm<InviteUserFormType>({
        defaultValues: {
            email: '',
            external_domain: false,
        }
    });

    const [inviteUser] = useInviteUserMutation();

    // Toggle external/internal domain
    const [externalDomain, setExternalDomain] = useState<boolean>(false);

    const onSubmit = async (formData: InviteUserFormType) => {
        if (currentUser.name && tenant?.name) {
            if (!formData.external_domain) {
                const emailSplit = formData.email.split('@')[0];
                formData.email = emailSplit + '@' + tenant?.domain;
            }

            const sendUserInviteDto: SendUserInviteDto = {
                sender_tenant_id: tenant.id,
                sender_user_id: currentUser.id,
                sender_email: currentUser.email,
                sender_name: currentUser.name,
                sender_company_name: tenant.name,
                receiver_email: formData.email,
                external_domain: formData.external_domain
            }

            try {
                const response = await inviteUser(sendUserInviteDto).unwrap();

                if (response.success) {
                    NotificationManager.success(t('page:users.form.userInviteSent'));
                }
                else {
                    if (response.message && response.message === 'user_already_exists') {
                        NotificationManager.error(t('page:users.userAlreadyExists'));
                    }
                    else {
                        NotificationManager.error(t('page:users.form.userInviteNotSent'));
                    }
                }
            }
            catch (error) {
                NotificationManager.error(t('page:users.form.userInviteNotSent'));
            }
        }
    }

    // If internal domain, append tenant email to end of email input
    const handleEmailInput = (value: string) => {
        if (externalDomain) {
            setValue('email', value);
        }
        else {
            const emailInput = value.split('@')[0];
            setValue('email', emailInput);
        }
    }

    return (
        <>
            <div className="w-full xl:w-6/12 mb-2">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col w-full">
                        <div className="flex items-center w-2/4">
                            <Controller
                                name="external_domain"
                                control={control}
                                rules={{

                                }}
                                render={({ field }) => (
                                    <Switch
                                        {...field}
                                        color='primary'
                                        onChange={() => setExternalDomain(!externalDomain)}
                                    />

                                )}
                            />
                            <p className="text-sm text-black font-semibold">{externalDomain ? t('page:users.externalUser') : t('page:users.internalUser')}</p>
                        </div>
                        <div className="w-full">
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: t('common:form.emailNotEmpty')
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            onChange={(e) => handleEmailInput(e.target.value)}
                                            value={externalDomain ? field.value : field.value + '@' + currentUser.email.split('@')[1]}
                                            type={externalDomain ? 'email' : 'text'}
                                            placeholder={t('common:email')}
                                            className={`${errors.email && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} w-full sm:w-6/12 md:w-7/12 text-xs sm:text-sm rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:leading-6 mb-1`}
                                        />
                                    </>
                                )}
                            />

                            {/* <button
                                type="submit"
                                className="bg-primary-blue hover:bg-primary-blue-h py-2 px-4 ml-2 rounded-md text-secondary-blue font-semibold text-sm disabled:bg-gray-300 disabled:text-gray-400">
                                <span className="">{t('common:invite')}</span>
                            </button> */}
                            <PrimaryButton
                                text={t('common:invite')}
                                size={'medium'}
                            />
                        </div>
                        {errors.email && (<span className="text-red text-xs text-status-critical mt-1 ml-1">{errors.email.message}</span>)}
                    </div>
                </form>
            </div>
        </>
    )
}

export default InviteUserForm;