import { createApi, fetchBaseQuery, skipToken } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../redux/store';
import { setAuthenticatedUser } from '../../redux/slices/auth.slice';
import { AuthState } from '../dto/auth-state.dto';

/*
    API base to re-authenticate if Authorization header has expired.

    1. First check if Authorization header is valid
    2. If Authorization header is not valid, issue new access token with refresh token
    3. If refresh token has expired, sign out to re authenticate
*/

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    // Attach authorization header if token exists in state
    const token = (getState() as RootState).rootReducer.auth.access_token; // Get access token from state
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

const baseQueryWithReAuth = async (args: any, api: any, extraOptions: any) => {
  /* 
    Try to make a request, if access token is invalid the if statement below will fire.
    If everything is fine, return the result.
  */
  let result = await baseQuery(args, api, extraOptions);

  // If access token has expired
  if (result?.error?.status === 403) {
    // Send refresh token to get new access token
    const refreshResult: any = await baseQuery('/catalog/auth/refresh', api, extraOptions);
    if (refreshResult?.data) {
      try {
        const usr = api.getState().authSlice.user; // Get user obj from state
      }
      catch (err) {
        // FIX: Handle

      }

      const authState: AuthState = {
        authUser: refreshResult.data.user,
        access_token: refreshResult.data.access_token
      }

      // Store the new token
      api.dispatch(setAuthenticatedUser({ ...authState }));
      // Retry the original query with new access token
      result = await baseQuery(args, api, extraOptions)
    }
    // Access token expired, reload page to try to get new access token
    else {
      api.dispatch({ type: 'RESET_APP' });
      window.location.reload();
    }
  }
  return result;
}

export const reAuthAPI = createApi({
  baseQuery: baseQueryWithReAuth,
  endpoints: builder => ({}),
  tagTypes: [
    'Users',
    'UserMe',
    'Properties',
    'Logs',
    'Subsidiaries',
    'PropertyObject',
    'PropertyBuilding',
    'PropertyUserAccess',
    'PropertyLogAccess',
    'PropertyBuildingLogAccess',
    'PropertyBuildingUserAccess',
    'PropertyObjectLogAccess',
    'PropertyObjectUserAccess',
    'LogTemplate',
    'Tenants',
    'RevokeLogUserAccess',
    'Friendships',
    'PropertyOwnerContractorLogAccess',
    'ContractorLogUserAccess',
    'ExternalCommon',
    'ExternalPropertyOwner',
    'ExternalContractorLogs',
    'Notifications',
    'UserInvite',
    'EntityLogs',
  ]
})