import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../redux/hooks';
import { getTenant } from '../../../../redux/slices/tenant.slice';
import { getCurrentUser } from '../../../../redux/slices/user.slice';
import Modal from '../../../molecules/modal';
import { Grow } from '@mui/material';
import CreateSubsidiaryForm from '../../../organisms/forms/subsidiary/create-subsidiary-form';
import UpdateTenantForm from '../../../organisms/forms/tenant/update-tenant-form';
import SubsidiariesTable from '../../../organisms/subsidiary/subsidiary-table';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../atoms/primary-button';

type Props = {}

const CompanyPage = (props: Props) => {
    const { t } = useTranslation();

    const { currentUser } = useAppSelector(getCurrentUser);
    const { tenant } = useAppSelector(getTenant);

    // Toggle modal to create new subsidiary
    const [showCreateSubsidiary, setShowCreateSubsidiary] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<string>('company');

    return (
        <>
            {
                tenant ? (
                    <Grow in={tenant.id ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                        <div className="w-full p-1 mx-auto mb-5">
                            <ul className="w-full flex flex-wrap -mb-px justify-center sm:justify-normal mx-auto">
                                <li
                                    onClick={() => setSelectedTab('company')}
                                    className={`${selectedTab === 'company' ? 'border-blue-600 active text-blue-600 border-b-2' : 'border-gray-300 border-b-2 hover:text-gray-600 hover:border-b-2 hover:border-gray-300 text-black'} pl-2 pr-4 pb-4 pt-4 rounded-t-lg mr-2 cursor-pointer`}>
                                    <span className="material-symbols-outlined align-sub">work</span>
                                    <span className="inline-block pl-1 text-sm">{t('common:company')}</span>
                                </li>
                                <li onClick={() => setSelectedTab('subsidiary')} className={`${selectedTab === 'subsidiary' ? 'border-blue-600 active text-blue-600 border-b-2' : 'border-gray-300 border-b-2 hover:text-gray-600 hover:border-b-2 hover:border-gray-300 text-black'} pl-2 pr-4 pb-4 pt-4 rounded-t-lg mr-2 cursor-pointer`}>
                                    <span className="material-symbols-outlined align-sub">cases</span>
                                    <span className="inline-block pl-1 text-sm">{t('common:subsidiary')}</span>
                                </li>
                            </ul>

                            {selectedTab === 'company' && (
                                <div className="w-full md:w-8/12 xl:w-6/12 max-w-2xl mx-auto text-sm md:text-base mt-5">
                                    <Grow in={selectedTab === 'company' ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                                        <div>
                                            <UpdateTenantForm tenant={tenant} />
                                        </div>
                                    </Grow>

                                </div>
                            )}

                            {selectedTab === 'subsidiary' && (
                                <div>
                                    <div className="flex justify-end mt-5 mb-2 w-full mx-auto">
                                        <PrimaryButton
                                            text={t('page:company.createSubsidiary')}
                                            size={'medium'}
                                            onClick={() => setShowCreateSubsidiary(true)}
                                            startAdornment={'add'}
                                        />
                                    </div>

                                    <SubsidiariesTable />

                                    <Modal isOpen={showCreateSubsidiary} close={() => setShowCreateSubsidiary(false)} title={t('page:company.createSubsidiary')} size='sm' titleAlign='center'>
                                        <div>
                                            <div className="w-11/12 mx-auto mb-5">
                                                <CreateSubsidiaryForm setShowCreateSubsidiary={setShowCreateSubsidiary} />
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                            )}

                        </div>
                    </Grow>

                ) : (
                    <>
                        <p>Not found</p>
                    </>
                )
            }
        </>
    )
}

export default CompanyPage;