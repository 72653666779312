
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Select, { MultiValue } from 'react-select';
import { useGetLogTemplatesQuery, useGetLogTemplateQuery } from '../../../../../services/tenant/log-template/log-template.service';
import { Order } from '../../../../../services/enum/order';
import { PageDto } from '../../../../../services/dto/page.dto';
import { LogTemplateDto } from '../../../../../services/tenant/log-template/dto/log-template.dto';
import { LogTypes } from '../../../../../services/enum/log-types';
import { SelectCustomStyles } from '../../../../atoms/select-custom-styles/select-custom-styles';
import InputCheckbox from '../../../../atoms/select-checkbox';
import { debounce } from '../../../../../utils/debounce';

type SelectOption = {
    label: string;
    value: string;
    data: LogTemplateDto;
}

type Props = {
    handleSelectedLogTemplates: (value: SelectOption[]) => void;
    noneSelected: boolean;
    id?: string; // Id passed to match logTemplate;
    zIndex?: string;
    reset?: boolean;
}

const LogTemplateSelectMulti = ({
    handleSelectedLogTemplates,
    noneSelected,
    id,
    zIndex,
    reset
}: Props) => {
    const { t } = useTranslation();

    const [allOptions, setAllOptions] = useState<SelectOption[]>([]);
    const [search, setSearch] = useState<string>();
    const [page, setPage] = useState<number>(1);

    const debouncedSearch = useRef(debounce((search: string) => setSearch(search), 500)).current;

    // Get log structure templates
    const { data: logTemplates } = useGetLogTemplatesQuery({
        take: 15,
        page: page,
        order: Order.ASC,
        sortBy: 'name',
        search: search,
    });

    const [selectKey, setSelectKey] = useState<number>(0);

    useEffect(() => {
        if (reset) {
            handleSelectedLogTemplates([]);
            setSelectKey(prevKey => prevKey + 1); // Reset select component
        }
    }, [reset])

    useEffect(() => {
        if (logTemplates) {
            // INIT
            if (allOptions.length === 0) {
                initOptions(logTemplates);
            }
            // SEARCH
            else if (search) {
                handleSearchOptions(search, logTemplates);
            }
            // PAGINATION
            else {
                handlePagination(logTemplates);
            }
        }
    }, [logTemplates])

    // Set initial options and selected options
    const initOptions = (logTemplates: PageDto<LogTemplateDto>) => {
        const allOptions: SelectOption[] = [];
        if (logTemplates && allOptions.length === 0) {
            logTemplates.data.forEach(template => {
                let tempObj: SelectOption;
                if (id) {
                    if (template.log_type === LogTypes.Custom) {
                        tempObj = {
                            label: template.name.split('_').join(' '),
                            value: template.id,
                            data: template
                        }
                        allOptions.push(tempObj);
                    }
                }
                else {
                    tempObj = {
                        label: template.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${template.name.split('_').join('')}`) : template.name.split('_').join(' '),
                        value: template.id,
                        data: template
                    }
                    allOptions.push(tempObj);
                }
            })
            setAllOptions(allOptions);
        }
    }

    // Handle options when search query is provided
    const handleSearchOptions = (search: string, logTemplates: PageDto<LogTemplateDto>) => {
        if (logTemplates && search) {
            setPage(1);
            let options: SelectOption[] = [];
            logTemplates.data.forEach(template => {
                if (id) {
                    if (template.log_type === LogTypes.Custom) {
                        options.push({
                            label: template.name.split('_').join(' '),
                            value: template.id,
                            data: template
                        });
                    }
                }
                else {
                    options.push({
                        label: template.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${template.name.split('_').join('')}`) : template.name.split('_').join(' '),
                        value: template.id,
                        data: template
                    });
                }
            })
            setAllOptions([...options]);
        }
    }

    // Handling options with pagination
    const handlePagination = (logTemplates: PageDto<LogTemplateDto>) => {
        if (logTemplates) {
            // Get existing options
            const existingOptions = [...allOptions];

            // Remove duplicates
            const uniqueArr = existingOptions.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.value === value.value
                ))
            )

            // let options: SelectOption[];
            logTemplates.data.forEach(template => {
                if (id) {
                    if (template.log_type === LogTypes.Custom) {
                        if (!uniqueArr.some(e => e.value === template.id)) {
                            uniqueArr.push({
                                label: template.name.split('_').join(' '),
                                value: template.id,
                                data: template
                            });
                        }
                    }
                }
                else {
                    if (!uniqueArr.some(e => e.value === template.id)) {
                        uniqueArr.push({
                            label: template.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${template.name.split('_').join('')}`) : template.name.split('_').join(' '),
                            value: template.id,
                            data: template
                        });
                    }
                }
            })

            setAllOptions(uniqueArr);
        }
    }

    // Handle scroll event to fetch more options
    const handleScroll = (event: any) => {
        if (logTemplates && logTemplates.meta.hasNextPage) {
            if (!search) {
                setPage(page ? page + 1 : 1);
            }
        }
    }

    const handleChange = (selectOptions: MultiValue<SelectOption>) => {
        // Make copy since passed array is readonly
        const selectOptionsArr: SelectOption[] = [...selectOptions];
        handleSelectedLogTemplates(selectOptionsArr);
    }

    return (
        <Select
            key={selectKey}
            className={`${zIndex ? `z-${zIndex}` : ''} capitalize md:text-sm ${(reset === false || noneSelected) ? 'bg-white' : 'bg-primary-blue-5' }`}
            options={allOptions}
            hideSelectedOptions={false}
            onChange={(val) => handleChange(val)}
            maxMenuHeight={200}
            placeholder={t('common:select')}
            noOptionsMessage={() => t('page:properties.noLogsFound')}
            onInputChange={(value) => debouncedSearch(value)}
            captureMenuScroll={true}
            onMenuScrollToBottom={(val) => handleScroll(val)}
            filterOption={null}
            styles={SelectCustomStyles}
            isMulti={true}
            closeMenuOnSelect={false}
            components={{
                // @ts-ignore
                Option: InputCheckbox
            }}
        />
    )
}

export default LogTemplateSelectMulti;