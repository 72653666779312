import React, { useEffect, useRef, useState } from 'react'
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { LogTemplateDto } from '../../../../../services/tenant/log-template/dto/log-template.dto';
import InputCheckbox from '../../../../atoms/select-checkbox'
import { useGetPropertyObjectsQuery } from '../../../../../services/tenant/property-object/property-object.service';
import { PropertyObjectDto } from '../../../../../services/tenant/property-object/dto/property-object.dto';
import { useTranslation } from 'react-i18next';
import { debounce } from '../../../../../utils/debounce';
import { Order } from '../../../../../services/enum/order';
import { PageDto } from '../../../../../services/dto/page.dto';
import { LogTypes } from '../../../../../services/enum/log-types';
import { SelectCustomStyles } from '../../../../atoms/select-custom-styles/select-custom-styles';
import SecondaryButton from '../../../../atoms/secondary-button';
import { useGetAllPropertyObjectLogAccessByIdQuery, useUpdateManyPropertyObjectLogAccessMutation } from '../../../../../services/tenant/entity-log/entity-log.service';
import { UpdatePropertyObjectLogAccessDto } from '../../../../../services/tenant/entity-log/dto/object-log-access/update-property-object-log-access.dto';


type Props = {
    logTemplate?: LogTemplateDto;
}

type SelectOption = {
    label: string;
    value: string;
    existing_access: boolean; // Access when the records is fetched
    entity_log_id?: string;
    property_object_id: string;
}

const LogPropertyObjectAccess = ({ logTemplate }: Props) => {
    const { t } = useTranslation();

    const [allOptions, setAllOptions] = useState<SelectOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
    const [search, setSearch] = useState<string>();
    const [page, setPage] = useState<number>(1);

    const debouncedSearch = useRef(debounce((search: string) => setSearch(search), 500)).current;

    const [updatePropertyLogAccess, response] = useUpdateManyPropertyObjectLogAccessMutation();

    // Get all property objects for current tenant
    const { data: propertyObjects, isFetching } = useGetPropertyObjectsQuery({
        take: 15,
        page: page,
        order: Order.ASC,
        sortBy: 'name',
        search: search,
    });

    const [skipTokenPropertyObjectLogAccess, setSkipTokenPropertyObjectLogAccess] = useState<any>(skipToken);
    // Get all PropertyObjectLogAccess relations
    const { data: propertyObjectLogAccess } = useGetAllPropertyObjectLogAccessByIdQuery(skipTokenPropertyObjectLogAccess);

    useEffect(() => {
        if (logTemplate) {
            setSkipTokenPropertyObjectLogAccess(logTemplate.id);
        }
    }, [logTemplate])

    useEffect(() => {
        if (propertyObjects && propertyObjectLogAccess) {
            // INIT
            if (allOptions.length === 0) {
                initOptions(propertyObjects);
            }
            // SEARCH
            else if (search) {
                handleSearchOptions(search, propertyObjects);
            }
            // PAGINATION
            else {
                handlePagination(propertyObjects);
            }
        }
    }, [propertyObjects, propertyObjectLogAccess])

    // Set initial options and selected options
    const initOptions = (propertyObjects: PageDto<PropertyObjectDto>) => {
        if (propertyObjectLogAccess && allOptions.length === 0) {
            const options = propertyObjects.data.map(object => ({
                value: object.id,
                label: object.name,
                existing_access: propertyObjectLogAccess.find(e => e.property_object_id === object.id) ? true : false,
                entity_log_id: propertyObjectLogAccess.find(e => e.property_object_id === object.id)?.id,
                property_object_id: object.id
            }))
            setAllOptions(options);
        }

        if (propertyObjectLogAccess) {
            const selectOptions: SelectOption[] = propertyObjectLogAccess.map(pola => ({
                value: pola.property_object_id,
                label: pola.property_object_name,
                existing_access: true,
                entity_log_id: pola.id,
                property_object_id: pola.property_object_id
            }))
            setSelectedOptions(selectOptions);
        }
    }

    // Handle options when search query is provided
    const handleSearchOptions = (search: string, propertyObjects: PageDto<PropertyObjectDto>) => {
        if (propertyObjectLogAccess && search) {
            const allOptions: SelectOption[] = [];
            const selectedOptions: SelectOption[] = [];
            setPage(1);
            propertyObjects.data.forEach(object => {
                let tempObj: SelectOption = {
                    value: object.id,
                    label: object.name,
                    existing_access: propertyObjectLogAccess.find(e => e.property_object_id === object.id) ? true : false,
                    entity_log_id: propertyObjectLogAccess.find(e => e.property_object_id === object.id)?.id,
                    property_object_id: object.id
                };
                allOptions.push(tempObj);
                if (tempObj.existing_access) {
                    selectedOptions.push(tempObj);
                }
            })
            setAllOptions([...allOptions]);
            setSelectedOptions([...selectedOptions]);
        }
    }

    // Handling options with pagination
    const handlePagination = (propertyObjects: PageDto<PropertyObjectDto>) => {
        if (propertyObjectLogAccess) {
            // Get existing options
            const existingOptions = [...allOptions];
            // Get existing selected options
            const existingSelected = [...selectedOptions];

            // Remove duplicates
            const uniqueArr = existingOptions.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.property_object_id === value.property_object_id
                ))
            )

            // Add options
            propertyObjects.data.forEach(object => {
                const obj = {
                    value: object.id,
                    label: object.name,
                    existing_access: propertyObjectLogAccess.find(e => e.property_object_id === object.id) ? true : false,
                    entity_log_id: propertyObjectLogAccess.find(e => e.property_object_id === object.id)?.id,
                    property_object_id: object.id
                }
                // Make sure no duplicates are added
                if (!uniqueArr.some(e => e.property_object_id === obj.property_object_id)) {
                    uniqueArr.push(obj)
                    if (obj.existing_access && !existingSelected.some(e => e.property_object_id === obj.property_object_id)) {
                        existingSelected.push(obj);
                    }
                }
            });
            setAllOptions(uniqueArr);
            setSelectedOptions(existingSelected);
        }
    }

    // Handle scroll event to fetch more options
    const handleScroll = (event: any) => {
        if (propertyObjects && propertyObjects.meta.hasNextPage) {
            if (!search) {
                setPage(page ? page + 1 : 1);
            }
        }
    }

    // Selected option(s) will get passed
    const handleSelectChange = (options: any) => {

        const allSelectedOptions = [...selectedOptions]; // All selected options

        if (options.length > 0) {
            // If options array is shorter than selectedOptions array, a building has been removed
            if (options.length < allSelectedOptions.length) {

                allSelectedOptions.forEach((option: SelectOption) => {
                    // If current option not in passed options arr, remove object from selectedOptions array
                    if (!options.includes(option)) {
                        const index = allSelectedOptions.findIndex((e) => e.value === option.value);
                        if (index !== -1) {
                            allSelectedOptions.splice(index, 1);
                        }
                    }
                })
                setSelectedOptions(allSelectedOptions); // Update selectedOptions state
            }
            // If options array is not shorter than selectedOptions array, add option to selectedOptions array
            else {
                options.forEach((option: SelectOption) => {
                    // Add option if not already exists in allSelectedOptions array
                    if (!allSelectedOptions.includes(option)) {
                        let tempObj: SelectOption = {
                            label: option.label,
                            value: option.value,
                            existing_access: option.existing_access,
                            entity_log_id: option.entity_log_id,
                            property_object_id: option.property_object_id,
                        };
                        allSelectedOptions.push(tempObj);
                    }
                });
                setSelectedOptions(allSelectedOptions);
            }
        }
        else {
            setSelectedOptions([])
        }
    }

    const handleUpdateUserAccess = async () => {
        let recordsToBeUpdated: UpdatePropertyObjectLogAccessDto[] = [];
        let existingRecords: SelectOption[] = [];

        // Loop through selectedOptions to get records to be created
        selectedOptions.forEach((record) => {

            // If the property existing_access is not true, it's a completely new record to be added
            if (record.existing_access === false) {
                let tempObj: UpdatePropertyObjectLogAccessDto = {
                    access: true,
                    entity_log_id: record.entity_log_id,
                    log_template_id: logTemplate!.id,
                    property_object_id: record.property_object_id,
                }
                recordsToBeUpdated.push(tempObj);
            }
            else if (record.existing_access === true) {
                existingRecords.push(record);
            }
        })

        // Loop through all options and find records with property existing_access true
        // If the records does not exist in selectedOptions, it means it has been removed and should be deleted
        allOptions.forEach((option) => {
            if (option.existing_access === true) {
                if (!existingRecords.some((e) => e.entity_log_id === option.entity_log_id)) {
                    let tempObj: UpdatePropertyObjectLogAccessDto = {
                        access: false,
                        log_template_id: logTemplate!.id,
                        entity_log_id: option.entity_log_id,
                        property_object_id: option.property_object_id,
                    }
                    recordsToBeUpdated.push(tempObj);
                }
            }
        });

        if (recordsToBeUpdated.length > 0) {
            try {
                const response = await updatePropertyLogAccess(recordsToBeUpdated).unwrap();

                if (response.success) {
                    NotificationManager.success(t('common:notifications.updated'));
                }
                else {
                    NotificationManager.info(t('common:notifications.updateFailed'));
                }

            }
            catch (error) {
                NotificationManager.error(t('common:notifications.updateFailed'))
            }
        }
    }


    return (
        <>
            <div className="rounded-lg w-full mx-auto mb-2">
                <p className="my-2 text-xs sm:text-sm font-semibold">
                    {t('page:properties.objectAccess')}
                    <span className="font-extrabold uppercase"> {logTemplate?.log_type == LogTypes.Default ?
                        t(`page:logs.defaultLogs.${logTemplate.name.split('_').join('')}`) :
                        logTemplate?.name.split('_').join(' ')}
                    </span>
                </p>
                <Select
                    className="z-30 text-sm capitalize mb-2"
                    value={selectedOptions}
                    options={allOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(options) => handleSelectChange(options)}
                    controlShouldRenderValue={true}
                    isClearable={false}
                    backspaceRemovesValue={false}
                    maxMenuHeight={200}
                    placeholder={t('common:select')}
                    noOptionsMessage={() => t('page:properties.noObjectsFound')}
                    components={{
                        // @ts-ignore
                        Option: InputCheckbox
                    }}
                    onInputChange={(value) => debouncedSearch(value)}
                    captureMenuScroll={true}
                    onMenuScrollToBottom={(val) => handleScroll(val)}
                    filterOption={null}
                    styles={SelectCustomStyles}
                />
                <SecondaryButton
                    text={t('common:update')}
                    size={'medium'}
                    align={'center'}
                    wide={true}
                    onClick={handleUpdateUserAccess}
                />
            </div>
        </>
    )
}

export default LogPropertyObjectAccess;