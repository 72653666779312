import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { CreateUserDto } from '../tenant/user/dto/create-user.dto'
import { CreateTenantDto } from '../tenant/tenant/dto/create-tenant.dto'

export const managementAPI = createApi({
    reducerPath: 'tenantAPI',
    tagTypes: ['Post'],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BACKEND_URL,
    }),
    endpoints: (builder) => ({
        // Create new tenant
        createTenant: builder.mutation<any, CreateTenantDto>({
            query: (tenant) => ({
                url: '/manage/tenants',
                method: 'POST',
                body: tenant,
            })
        }),
         // Create new user from signup
         registerUser: builder.mutation<any, CreateUserDto>({
            query: (user) => ({
                url: '/manage/users/register',
                method: 'POST',
                body: user,
            }),
        }),
        // Validate tenant before creating
        validateTenant: builder.mutation<any, CreateTenantDto>({
            query: (tenant) => ({
                url: '/manage/tenants/validate',
                method: 'POST',
                body: tenant
            })
        }),
        // Validate user before creating
        validateUser: builder.mutation<any, CreateUserDto>({
            query: (user) => ({
                url: '/manage/users/validate',
                method: 'POST',
                body: user
            })
        }),
        // Logout user
        logout: builder.query<any, any>({
            query: () => ({
                credentials: 'include',
                url: '/manage/logout',
                method: 'GET'
            })
        }),
    }),
})

export const {
    useCreateTenantMutation,
    useRegisterUserMutation,
    useValidateTenantMutation,
    useValidateUserMutation,
    useLogoutQuery,
} = managementAPI