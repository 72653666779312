import React, { Fragment, useEffect, useState } from 'react'
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { TenantFormType } from '../tenant/create-tenant-form';
import { propertyAPI, useCreatePropertyMutation } from '../../../../services/tenant/property/property.service';
import Loader from '../../../atoms/loader/loader';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { PropertyTypes } from '../../../../services/enum/property-types';
import SubsidiarySelect from '../../subsidiary/subsidiary-select';
import { useDispatch } from 'react-redux';
import { SelectCustomStyles } from '../../../atoms/select-custom-styles/select-custom-styles';
import { PropertyDto } from '../../../../services/tenant/property/dto/property.dto';
import PrimaryButton from '../../../atoms/primary-button';

export type PropertyFormType = {
    name: string;
    label: string;
    object_number?: string;
    property_type: PropertyTypes;
    address?: string;
    city?: string;
    zip?: string;
    country?: string;
    property_image?: string;
    subsidiary_id?: string | null;
    copy_property_id?: string;
}

type Props = {
    property?: PropertyDto
}

const CreatePropertyForm = ({
    property
}: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [createProperty, response] = useCreatePropertyMutation();
    const [options, setOptions] = useState<{ label: string, value: string, subsidiary_id: string | null }[]>();
    const [error, setError] = useState<string>();

    const { control, handleSubmit, setValue, formState: { errors } } = useForm<PropertyFormType>({
        defaultValues: {
            label: property ? property.label : '',
            name: property ? property.name : '',
            object_number: property ? property.object_number : '',
            property_type: property ? property.property_type : PropertyTypes['Apartment'],
            address: property ? property.address : '',
            city: property ? property.address : '',
            zip: property ? property.zip : '',
            country: property ? property.country : 'Sverige',
            property_image: '',
            subsidiary_id: property ? property.subsidiary_id : null,
            copy_property_id: property ? property.id : ''
        }
    });

    const handleSelectedSubsidiary = (selectOption: { label: string, value: string }) => {
        if (selectOption.value && selectOption.value.length > 1) {
            setValue('subsidiary_id', selectOption.value);
        }
        else {
            setValue('subsidiary_id', null);
        }
    }

    const onSubmit = async (formData: PropertyFormType) => {
        try {
            const response = await createProperty(formData).unwrap();

            if (response.success) {
                setError('');
                NotificationManager.success(t('page:properties.form.propertyCreated'));
                dispatch(propertyAPI.util.invalidateTags(['Properties']));
            }
            else {
                setError(t(`common:server.${response.message}`));
                NotificationManager.error(t('page:properties.form.propertyNotCreated'));
            }
        }
        catch (error) {
            setError('');
            NotificationManager.error(t('page:properties.form.propertyNotCreated'))
        }
    }

    return (
        <>
            <div className="sm:mx-auto sm:w-full">
                <form className="space-y-2" onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                        <label htmlFor='label' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:label')}<span className="text-status-critical ">*</span>
                        </label >
                        <Controller
                            name="label"
                            control={control}
                            rules={{
                                required: t('page:properties.form.labelNotEmpty'),
                                minLength: {
                                    value: 3,
                                    message: t('page:properties.form.labelMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:label')}
                                        className={`${errors.label ? 'ring-status-critical ring-2 outline-status-critical focus:ring-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.label && <p className="text-status-critical text-xs my-1 ml-1">{errors.label.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='property_type' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('page:properties.propertyType')}<span className="text-status-critical ">*</span>
                        </label>
                        <Controller
                            name="property_type"
                            control={control}
                            rules={{
                                // required: 'You need to select a property type',
                                // validate: (value) => value in PropertyTypes || 'You need to select a property type.',
                            }}
                            render={({ field }) => (
                                <>
                                    <Select
                                        name="property_type"
                                        className={`${errors.property_type ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical rounded-md' : ''} z-50`}
                                        onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                                        styles={SelectCustomStyles}
                                        value={(Object.entries(PropertyTypes) as [string, string][])
                                            .filter(([key, value]) => typeof value === 'string')
                                            .map(([key, value]) => ({ label: t(`common:enums.propertyTypes.${key}`), value: value.toString() }))
                                            .find(option => option.value === field.value?.toString())}
                                        options={(Object.values(PropertyTypes) as Array<keyof typeof PropertyTypes>)
                                            .filter((val) => typeof val === 'string' && val !== PropertyTypes.None)
                                            .map((val) => ({
                                                label: t(`common:enums.propertyTypes.${PropertyTypes[val]}`),
                                                value: val,
                                            }))
                                        }
                                        placeholder={t('common:select')}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        controlShouldRenderValue={true}
                                        isClearable={false}
                                        backspaceRemovesValue={false}
                                        noOptionsMessage={() => t('page:properties.noPropertyTypesFound')}
                                    />
                                </>
                            )}
                        />
                        {errors.property_type && <p className="text-status-critical text-xs my-1 ml-1">{errors.property_type.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='subsidiary_id' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('page:properties:propertyBelongsToSubsidiary')}
                            <span
                                className="material-symbols-outlined text-gray-600 ml-1 align-sub"
                                style={{ fontSize: '1.2rem' }}
                                title={t('page:properties.propertySubsidiaryInfo')}
                            >help</span>
                        </label>
                        <SubsidiarySelect
                            handleSelectedSubsidiary={handleSelectedSubsidiary}
                            id={property?.subsidiary_id ? property.subsidiary_id : undefined}
                        />
                        {errors.subsidiary_id && <p className="text-status-critical text-xs my-1 ml-1">{errors.subsidiary_id.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='name' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:name')}
                        </label >
                        <Controller
                            name="name"
                            control={control}
                            rules={{
                                // required: 'Name cannot be empty.',
                                minLength: {
                                    value: 3,
                                    message: t('common:form.nameMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:name')}
                                        className={`${errors.name ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.name && <p className="text-status-critical text-xs my-1 ml-1">{errors.name.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='object_number' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:objectNumber')}
                        </label >
                        <Controller
                            name="object_number"
                            control={control}
                            rules={{
                                // required: 'Object number cannot be empty.',
                                minLength: {
                                    value: 3,
                                    message: t('page:properties.form.objectNumberMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:objectNumber')}
                                        className={`${errors.object_number ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.object_number && <p className="text-status-critical text-xs my-1 ml-1">{errors.object_number.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='address' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:address')}
                        </label >
                        <Controller
                            name="address"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 3,
                                    message: t('common:form.addressMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:address')}
                                        className={`${errors.address ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.address && <p className="text-status-critical text-xs my-1 ml-1">{errors.address.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='city' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:city')}
                        </label >
                        <Controller
                            name="city"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 3,
                                    message: t('common:form.cityMinLength3')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:city')}
                                        className={`${errors.city ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.city && <p className="text-status-critical text-xs my-1 ml-1">{errors.city.message}</p>}
                    </div>
                    <div className="">
                        <label htmlFor='zip' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('common:zip')}
                        </label >
                        <Controller
                            name="zip"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 5,
                                    message: t('common:form.zipMinLength5')
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <input
                                        {...field}
                                        type='string'
                                        placeholder={t('common:zip')}
                                        className={`${errors.zip ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical' : ''} w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                    />
                                </>
                            )}
                        />
                        {errors.zip && <p className="text-status-critical text-xs my-1 ml-1">{errors.zip.message}</p>}
                    </div>

                    {error && (<p className="font-bold text-sm mt-2 text-center text-status-critical">{error}</p>)}
                    <div className="">
                        <PrimaryButton
                            text={t('common:create')}
                            size={'medium'}
                            fullWidth={true}
                        />
                        {response.isLoading && (<Loader show={response.isLoading} size='small' />)}
                    </div>
                </form>
            </div >
        </>
    )
}

export default CreatePropertyForm;