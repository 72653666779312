import React, { useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form';

export type VerificationFormType = {
    code: string[];
}

export type OtpVerificationFormType = {
    value: string;
    onChange: (value: string) => void;
}

const OTPVerificationForm = ({ value, onChange }: OtpVerificationFormType) => {

    const RE_DIGIT = new RegExp(/^\d+$/); // Validate so only input of numbers are allowed
    const valueLength = 6; // OTP Code length

    const valueItems = useMemo(() => {

        const valueArray = value.split('');
        const items: Array<string> = [];

        for (let i = 0; i < valueLength; i++) {
            const char = valueArray[i];

            if (RE_DIGIT.test(char)) {
                items.push(char);
            } else {
                items.push('');
            }
        }

        return items;
    }, [value, valueLength]);

    const focusToNextInput = (target: HTMLElement) => {
        const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;
        if (nextElementSibling) {
            nextElementSibling.focus();
        }
    };

    const focusToPrevInput = (target: HTMLElement) => {
        const previousElementSibling = target.previousElementSibling as HTMLInputElement | null;
        if (previousElementSibling) {
            previousElementSibling.focus();
        }
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const target = e.target;
        let targetValue = target.value.trim();
        const isTargetValueDigit = RE_DIGIT.test(targetValue);

        if (!isTargetValueDigit && targetValue !== '') {
            return;
        }

        const nextInputEl = target.nextElementSibling as HTMLInputElement | null;

        // Only delete digit if next input element has no value
        if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
            return;
        }

        targetValue = isTargetValueDigit ? targetValue : '';

        const targetValueLength = targetValue.length;

        if (targetValueLength === 1) {
            const newValue = value.substring(0, index) + targetValue + value.substring(index + 1);

            onChange(newValue);

            if (!isTargetValueDigit) {
                return;
            }

            focusToNextInput(target);
        } else if (targetValueLength === valueLength) {
            onChange(targetValue);
            target.blur();
        }
    }

    const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = e;
        const target = e.target as HTMLInputElement;

        if (key === 'ArrowRight' || key === 'ArrowDown') {
            e.preventDefault();
            return focusToNextInput(target);
        }

        if (key === 'ArrowLeft' || key === 'ArrowUp') {
            e.preventDefault();
            return focusToPrevInput(target);
        }

        const targetValue = target.value;

        // Keep the selection range position if the same digit was typed
        target.setSelectionRange(0, targetValue.length);

        if (e.key !== 'Backspace' || targetValue !== '') {
            return;
        }

        focusToPrevInput(target);
    }

    const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const { target } = e;

        // Keep focusing back until previous input element has value
        const prevInputEl = target.previousElementSibling as HTMLInputElement | null;

        if (prevInputEl && prevInputEl.value === '') {
            return prevInputEl.focus();
        }

        target.setSelectionRange(0, target.value.length);
    }

    return (
        <>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="flex flex-row w-full justify-center">
                    {valueItems.map((digit, index) => {
                        return (
                            <input
                                className="w-8 rounded mx-3 p-2 text-xl text-center ring-4 ring-primary-blue focus:outline-none focus:ring-primary-pink"
                                type='text'
                                key={index}
                                inputMode='numeric'
                                autoComplete='one-time-code'
                                pattern='\d{1}' // Match the pattern of a one digit string
                                maxLength={valueLength}
                                value={digit}
                                onChange={(e) => onInputChange(e, index)}
                                onKeyDown={inputOnKeyDown}
                                onFocus={inputOnFocus}
                            />
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default OTPVerificationForm;