
import { Fragment, useEffect, useState } from 'react';
import DynamicInputField, { InputFieldType } from '../../organisms/log-components/custom-log/dynamic-input-field';
import DynamicCheckbox, { CheckboxFieldType } from '../../organisms/log-components/custom-log/dynamic-checkbox';
import DynamicSelect, { SelectFieldType } from '../../organisms/log-components/custom-log/dynamic-select';
import DynamicRenderPreview from '../../organisms/log-components/custom-log/dynamic-render-preview';
import { useCreateCustomLogTemplateMutation, useGetLogTemplatesQuery } from '../../../services/tenant/log-template/log-template.service';
import { CreateLogTemplateDto } from '../../../services/tenant/log-template/dto/create-log-template.dto';
import { NotificationManager } from 'react-notifications';
import ConfirmModal from '../confirm-modal';
import { useTranslation } from 'react-i18next';
import { LogTemplateDto } from '../../../services/tenant/log-template/dto/log-template.dto';
import { LogAccess } from '../../../services/enum/log-access';
import { LogCategories } from '../../../services/enum/log-categories';
import { LogTypes } from '../../../services/enum/log-types';
import { FieldDefinitions } from '../../../services/interface/field-definitions.interface';
import LogTemplateSelect from '../../organisms/log-components/log-structure/log-template-select';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Select from 'react-select';
import { SelectCustomStyles } from '../../atoms/select-custom-styles/select-custom-styles';
import PrimaryButton from '../../atoms/primary-button';

export type DynamicFieldTypes = InputFieldType | CheckboxFieldType | SelectFieldType;

export type FixedFieldProperties = {
    id: number;
    type: string;
    fieldName: string;
    isRequired: boolean;
    isActive: boolean;
    existing_record?: boolean;
    existing_name?: string;
}

export type ErrorInputType = {
    id: number,
    nameField: string,
    typeField: string;
}

export type ErrorCheckboxType = {
    id: number,
    nameField: string,
}

export type ErrorSelectType = {
    id: number,
    nameField: string,
    selectValues: string;
}

type Props = {
    setShowCreateLogTemplate: (flag: boolean) => void;
}

const CreateCustomLog = ({ setShowCreateLogTemplate }: Props) => {
    const { t } = useTranslation();

    const [selectedTemplate, setSelectedTemplate] = useState<LogTemplateDto>();
    const [logName, setLogName] = useState<string>(''); // Name of the log getting created
    const [selectedAccess, setSelectedAccess] = useState<LogAccess>(LogAccess.Private); // Log access
    const [selectedFieldType, setSelectedFieldType] = useState<string>('input'); // Type of field getting created
    const [selectedCategory, setSelectedCategory] = useState<LogCategories>(LogCategories.Access); // Log category
    const [generatedFields, setGeneratedFields] = useState<DynamicFieldTypes[]>([]); // Input fields that's been created
    const [showFormPreview, setShowFormPreview] = useState<boolean>(false);
    const [createLogError, setCreateLogError] = useState<string>('');

    const [createCustomLog, response] = useCreateCustomLogTemplateMutation();

    const [errorInput, setErrorInput] = useState<ErrorInputType>({
        id: -1,
        nameField: '',
        typeField: ''
    })

    const [errorCheckbox, setErrorCheckbox] = useState<ErrorCheckboxType>({
        id: -1,
        nameField: '',
    })

    const [errorSelect, setErrorSelect] = useState<ErrorSelectType>({
        id: -1,
        nameField: '',
        selectValues: ''
    })

    // General errors
    const [error, setError] = useState<{ templateError: string, logNameError: string, accessError: string, categoryError: string, fieldTypeError: string, generalError: string }>(
        { templateError: '', logNameError: '', accessError: '', categoryError: '', fieldTypeError: '', generalError: '', }
    );

    // Type of fields that can be created
    const fieldTypes = [
        {
            key: 'input',
            value: 'Input field'
        },
        {
            key: 'checkbox',
            value: 'True / False checkbox'
        },
        {
            key: 'select',
            value: 'Multi select',
        }
    ];

    // Types of input that can be selected when choosing input field
    const inputTypes = [
        {
            key: 'string',
            value: 'text'
        },
        {
            key: 'number',
            value: 'number',
        },
        {
            key: 'date',
            value: 'date'
        },
        {
            key: 'file',
            value: 'fileUpload',
        }
    ];

    const handleLogName = (value: string) => {
        if (!/^(?![0-9\-_])[a-zA-ZåäöÅÄÖ0-9_-\s]*$/.test(value)) {
            setError({ ...error, logNameError: t('page:logs.form.logNameOnlyLetters') });
        }
        else if (value.length > 32) {
            setError({ ...error, logNameError: t('page:logs.form.logMaxLength') });
        }
        else {
            setError({ ...error, logNameError: '' });
            setLogName(value);
        }
    }

    // Add new field
    const handleAddGeneratedField = (fieldType: string) => {
        // Reset error state
        setError({ ...error, fieldTypeError: '' });
        // Get largest id from array
        const largestId = [...generatedFields].reduce((maxId, obj) => (obj.id > maxId ? obj.id : maxId), -1);

        // Create field based on selected field type
        switch (fieldType) {
            case 'input':
                const inputFieldObj: InputFieldType = {
                    id: largestId + 1,
                    type: 'input',
                    fieldName: '',
                    isRequired: false,
                    isActive: true,
                    inputType: 'text',
                };
                setGeneratedFields([...generatedFields, inputFieldObj]);
                break;
            case 'select':
                const selectFieldObj: SelectFieldType = {
                    id: largestId + 1,
                    type: 'select',
                    fieldName: '',
                    isRequired: false,
                    isActive: true,
                    selectValues: [],
                };
                setGeneratedFields([...generatedFields, selectFieldObj])
                break;
            case 'checkbox':
                const checkboxFieldObj: CheckboxFieldType = {
                    id: largestId + 1,
                    type: 'checkbox',
                    fieldName: '',
                    isRequired: false, // To maintain structure (not used)
                    isActive: true,
                    flag: false,
                };
                setGeneratedFields([...generatedFields, checkboxFieldObj])
                break;
            default:
                setError({ ...error, fieldTypeError: t('page:logs.form.selectTypeOfFieldToCreate') });
                break;
        }
    }

    // Update generated field
    const handleUpdateGeneratedField = (field: DynamicFieldTypes) => {
        const allInputsToBeCreated = [...generatedFields]; // Get all existing objects
        const index = allInputsToBeCreated.findIndex((e) => e.id === field.id); // Try to find index of passed field
        if (index !== -1) {
            allInputsToBeCreated[index] = field; // Update object if existing index was found
            setGeneratedFields(allInputsToBeCreated); // Update state
        }
    }

    // Delete generated field
    const handleDeleteGeneratedField = (id: number) => {
        const allInputsToBeCreated = [...generatedFields]; // Get all existing objects
        const index = allInputsToBeCreated.findIndex((e) => e.id === id); // Try to find index by passed id
        if (index !== -1) {
            allInputsToBeCreated.splice(index, 1); // Delete object
            setGeneratedFields(allInputsToBeCreated); // Update state
        }
    }

    // Validate input and create log if successful
    const handleCreateLog = () => {
        setError({ ...error, generalError: '' }); // Reset general error state
        let success = true; // Flag to control if form is valid
        if (logName.length < 3) {
            // Log name is too short, show error
            setError({ ...error, logNameError: t('page:logs.form.logNameMinLength3') });
            success = false;
        }
        else if (selectedCategory === LogCategories.None) {
            // Category type not selected, show error
            setError({ ...error, categoryError: t('page:logs.form.categoryTypeNotEmpty') });
            success = false;
        }
        else {
            if (generatedFields.length > 0) {
                const nameSet = new Set<string>(); // Set to keep track of duplicate names
                generatedFields.forEach((field, idx) => {
                    // If not first iteration and success is false, skip rest of loop
                    if (idx !== 0 && success === false) {
                        return;
                    }

                    // Input field
                    if ((field.type === 'input' || field.type === 'text' || field.type === 'date' || field.type === 'number' || field.type === 'file') && 'inputType' in field) {
                        if (field.fieldName.length < 3) {
                            setErrorInput({ ...errorInput, nameField: t('common:form.nameMinLength3'), id: field.id });
                            success = false;
                        }
                        else if (nameSet.has(field.fieldName.toLowerCase())) {
                            setErrorInput({ ...errorInput, nameField: t('common:form.nameAlreadyExists'), id: field.id });
                            success = false;
                        }
                        else {
                            setErrorInput({ ...errorInput, nameField: '', id: field.id });
                            nameSet.add(field.fieldName.toLowerCase());
                        }
                    }
                    // Checkbox field
                    else if (field.type === 'checkbox' && 'flag' in field) {
                        if (field.fieldName.length < 3) {
                            setErrorCheckbox({ ...errorCheckbox, nameField: t('common:form.nameMinLength3'), id: field.id });
                            success = false;
                        }
                        else if (nameSet.has(field.fieldName.toLowerCase())) {
                            setErrorCheckbox({ ...errorCheckbox, nameField: t('common:form.nameAlreadyExists'), id: field.id });
                            success = false;
                        }
                        else {
                            setErrorCheckbox({ ...errorCheckbox, nameField: '', id: field.id });
                            nameSet.add(field.fieldName.toLowerCase());
                        }
                    }
                    // Select field
                    else if (field.type === 'select' && 'selectValues' in field) {
                        if (field.fieldName.length < 3) {
                            setErrorSelect({ ...errorSelect, nameField: t('common:form.nameMinLength3'), id: field.id });
                            success = false;
                        }
                        else if (field.selectValues.length < 1) {
                            setErrorSelect({ ...errorSelect, selectValues: t('page:logs.form.selectNotEmpty'), id: field.id });
                            success = false;
                        }
                        else if (nameSet.has(field.fieldName.toLowerCase())) {
                            setErrorSelect({ ...errorSelect, nameField: t('common:form.nameAlreadyExists'), selectValues: '', id: field.id });
                            success = false;
                        }
                        else {
                            setErrorSelect({ ...errorSelect, nameField: '', selectValues: '', id: field.id });
                            nameSet.add(field.fieldName.toLowerCase());
                        }
                    }
                });
                setError({ ...error, categoryError: '' }); // Reset category error
            }
            // No fields has been added, show error
            else {
                setError({ ...error, generalError: t('page:logs.form.noFieldsAdded') });
                success = false;
            }
        }

        // Form validation successful
        if (success) {
            setError({ ...error, generalError: '', logNameError: '', categoryError: '', fieldTypeError: '' })
            // Create log
            createLog(generatedFields);
        }
    }

    // Create log
    const createLog = async (generatedFields: DynamicFieldTypes[]) => {
        let fieldDefinitions: FieldDefinitions[] = [];

        generatedFields.forEach((field) => {
            let tempObj: FieldDefinitions = { name: '', type: 'text', required: false, options: [], active: true };

            // InputField
            if ('inputType' in field) {
                tempObj.name = field.fieldName;
                tempObj.type = field.inputType;
                tempObj.required = field.isRequired;
                tempObj.active = field.isActive;
                tempObj.options = [];
                fieldDefinitions.push(tempObj);
            }
            // CheckboxField
            else if ('flag' in field) {
                tempObj.name = field.fieldName;
                tempObj.type = field.type;
                tempObj.required = field.isRequired;
                tempObj.active = field.isActive;
                tempObj.options = [];
                fieldDefinitions.push(tempObj);
            }
            // SelectField
            else if ('selectValues' in field) {
                tempObj.name = field.fieldName;
                tempObj.type = field.type;
                tempObj.required = field.isRequired;
                tempObj.active = field.isActive;
                tempObj.options = field.selectValues;
                fieldDefinitions.push(tempObj);
            }
        })

        const payload: CreateLogTemplateDto = {
            name: logName,
            category: selectedCategory,
            field_definitions: fieldDefinitions,
            access: selectedAccess,
            log_type: LogTypes.Custom,
        };

        try {
            const response = await createCustomLog(payload).unwrap();

            if (response.success) {
                setCreateLogError(''); // Reset error message
                setShowCreateLogTemplate(false); // Close modal
                NotificationManager.success(t('page:logs.form.logCreated'))
            }
            else {
                NotificationManager.info(t('page:logs.form.logNotCreated'))
            }
        }
        catch (error: any) {
            if (error.data && error.data.message) {
                setCreateLogError(t(`common:server.${error.data.message}`));
            }
            NotificationManager.error(t('page:logs.form.logNotCreated'))
        }
    }

    const handleSelectedLogTemplate = (logTemplate: LogTemplateDto | undefined) => {
        if (logTemplate && selectedTemplate?.id !== logTemplate.id) {
            addGeneratedFieldsFromExistingTemplate(logTemplate);
            setSelectedTemplate(logTemplate)
        }
        else {
            setSelectedTemplate(undefined)
            setGeneratedFields([])
        }
    }

    const addGeneratedFieldsFromExistingTemplate = (logTemplate: LogTemplateDto) => {

        // Hold objects to be updated
        const objectsToBeUpdated: DynamicFieldTypes[] = [];

        // Get largest id from array of generated fields
        const largestId = [...generatedFields].reduce((maxId, obj) => (obj.id > maxId ? obj.id : maxId), -1);

        setGeneratedFields([]); // Empty array before looping through a new template

        // Reset error state
        setError({ ...error, fieldTypeError: '' });

        logTemplate.field_definitions.forEach((field, idx) => {
            // Make sure every field gets a unique id
            const index = largestId + idx + 1;

            // Create field based on selected field type
            switch (field.type) {
                case 'text':
                    const inputTextFieldObj: InputFieldType = {
                        id: index + idx,
                        type: 'text',
                        fieldName: field.name.split('_').join(''),
                        isRequired: field.required,
                        isActive: field.active,
                        inputType: 'text',
                    };
                    // setGeneratedFields([...generatedFields, inputTextFieldObj]);
                    objectsToBeUpdated.push(inputTextFieldObj);
                    break;
                case 'date':
                    const inputDateFieldObj: InputFieldType = {
                        id: index + idx,
                        type: 'date',
                        fieldName: field.name.split('_').join(' '),
                        isRequired: field.required,
                        isActive: field.active,
                        inputType: 'date',
                    };
                    // setGeneratedFields([...generatedFields, inputDateFieldObj]);
                    objectsToBeUpdated.push(inputDateFieldObj);
                    break;
                case 'number':
                    const inputNumberFieldObj: InputFieldType = {
                        id: index + idx,
                        type: 'number',
                        fieldName: field.name.split('_').join(' '),
                        isRequired: field.required,
                        isActive: field.active,
                        inputType: 'number',
                    };
                    // setGeneratedFields([...generatedFields, inputFileFieldObj]);
                    objectsToBeUpdated.push(inputNumberFieldObj);
                    break;
                case 'file':
                    const inputFileFieldObj: InputFieldType = {
                        id: index + idx,
                        type: 'file',
                        fieldName: field.name.split('_').join(' '),
                        isRequired: field.required,
                        isActive: field.active,
                        inputType: 'file',
                    };
                    // setGeneratedFields([...generatedFields, inputFileFieldObj]);
                    objectsToBeUpdated.push(inputFileFieldObj);
                    break;
                case 'select':
                    const selectFieldObj: SelectFieldType = {
                        id: index + idx,
                        type: 'select',
                        fieldName: field.name.split('_').join(' '),
                        isRequired: field.required,
                        isActive: field.active,
                        selectValues: field.options ? field.options : [],
                    };
                    // setGeneratedFields([...generatedFields, selectFieldObj])
                    objectsToBeUpdated.push(selectFieldObj);
                    break;
                case 'checkbox':
                    const checkboxFieldObj: CheckboxFieldType = {
                        id: index + idx,
                        type: 'checkbox',
                        fieldName: field.name.split('_').join(' '),
                        isRequired: field.required, // To maintain structure (not used)
                        isActive: field.active,
                        flag: false,
                    };
                    // setGeneratedFields([...generatedFields, checkboxFieldObj])
                    objectsToBeUpdated.push(checkboxFieldObj);
                    break;
                default:
                    setError({ ...error, fieldTypeError: t('page:properties.selectTypeOfFieldToCreate') });
                    break;
            }
        })

        setGeneratedFields(objectsToBeUpdated);
    }

    return (
        <div className="w-full sm:w-10/12 md:w-8/12 mx-auto mb-5">
            <div className="w-10/12 mx-auto mt-1">
                <label className="text-xs sm:text-sm font-semibold leading-6 text-gray-600" htmlFor="log_template">{t('page:logs.logTemplate')}</label>
                <span className="material-symbols-outlined text-gray-600 align-sub ml-1 cursor-default" style={{ fontSize: '1.2rem' }} title={t('page:logs.selectExistingBase')}>help</span>
                <div className="">
                    <LogTemplateSelect
                        handleSelectedLogTemplate={handleSelectedLogTemplate}
                    />
                </div>
                {/* Show info message when generated fields exist */}
                {generatedFields.length > 0 && (<span className="flex text-xs ml-1 my-1 font-semibold text-status-warning-h">{t('page:logs.form.selectNewTemplateInfo')}</span>)}
            </div>

            <div className="w-10/12 mx-auto">
                <label className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600" htmlFor="log_name">{t('common:logName')}</label>
                <input
                    type="text"
                    placeholder={t('common:name')}
                    required
                    name="log_name"
                    value={logName}
                    // onChange={(e) => setLogName(e.target.value)}
                    onChange={(e) => handleLogName(e.target.value)}
                    className={`${error.logNameError && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} w-full rounded-md border-0 p-2.5 sm:py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:text-sm focus:ring-2 focus:ring-indigo-600 text-base sm:leading-6`}
                />
                {error.logNameError && (<span className="text-xs text-status-critical">{error.logNameError}</span>)}
            </div>

            {/* <div className="w-10/12 mx-auto mt-1">
                <label className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600" htmlFor="log_access">{t('page:logs.logAccess')}</label>
                <div className="">
                    <Select
                        name="log_access"
                        className="md:text-sm"
                        styles={ SelectCustomStyles }
                        options={
                            (Object.entries(LogAccess) as [string, string][])
                                .filter(([key, value]) => typeof value === 'string' && value !== LogAccess.None)
                                .map((([key, value]) => ({ label: t(`common:enums.logAccess.${key}`), value: value.toString() })))
                        }
                        onChange={(option) => setSelectedAccess(option ? option.value as LogAccess : LogAccess.Private)}
                        noOptionsMessage={() => t('common:noResultsFound')}
                        placeholder={t('common:select')}
                    />
                </div>
                {error.accessError && (<span className="text-xs text-status-critical">{error.accessError}</span>)}
            </div> */}

            <div className="w-10/12 mx-auto mt-1">
                <label className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600" htmlFor="log_category">{t('page:logs.logCategory')}</label>
                <div className="">
                    <Select
                        name="log_category"
                        className="md:text-sm"
                        options={
                            (Object.entries(LogCategories) as [string, string][])
                                .filter(([key, value]) => typeof value === 'string' && value !== 'None')
                                .map((([key, value]) => ({ label: t(`common:enums.logCategories.${key}`), value: value.toString() })))
                        }
                        styles={ SelectCustomStyles }
                        onChange={(option) => setSelectedCategory(option ? option.value as LogCategories : LogCategories.Access)}
                        noOptionsMessage={() => t('common:noResultsFound')}
                        placeholder={t('common:select')}
                    />
                </div>
                {error.categoryError && (<span className="text-xs text-status-critical">{error.categoryError}</span>)}
            </div>

            <div className="w-10/12 mx-auto mt-1">
                <label className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600" htmlFor="input_type">{t('page:logs.selectField')}</label>
                <div className="flex items-center">
                    <Select
                        name="input_type"
                        className="w-10/12 md:text-sm"
                        options={
                            fieldTypes.map((option) => ({ label: t(`page:logs.${option.key}`), value: option.key }))
                        }
                        onChange={(option) => setSelectedFieldType(option ? option.value : 'input')}
                        noOptionsMessage={() => t('common:noResultsFound')}
                        placeholder={t('common:select')}
                        styles={ SelectCustomStyles }
                    />
                    <div className="w-2/12 text-center">
                        <button
                            className="material-symbols-outlined ml-2 bg-primary-blue hover:bg-primary-blue-h p-1 rounded h-8 text-white"
                            onClick={() => handleAddGeneratedField(selectedFieldType)}
                        >
                            add
                        </button>
                    </div>
                </div>
                {error.fieldTypeError && (<span className="text-xs text-status-critical">{error.fieldTypeError}</span>)}
            </div>

            {/* Generated fields */}
            {generatedFields.length > 0 && (
                <>
                    <hr className="w-8/12 h-[2px] mt-8 mb-5 mx-auto bg-gray-300 border-0 rounded" />

                    <div className="mx-auto p-1">
                        {generatedFields.length > 0 && (<h3 className="text-base text-black text-center font-semibold">{t('page:logs.createdFields')}</h3>)}
                        {generatedFields.map((field, idx: number) => {
                            return (
                                <div key={field.id}>
                                    {((field.type === 'input' || 'text' || 'date' || 'number' || 'file') && 'inputType' in field) && (<DynamicInputField field={field} inputTypes={inputTypes} handleUpdateGeneratedField={handleUpdateGeneratedField} handleDeleteGeneratedField={handleDeleteGeneratedField} error={errorInput} newTemplate={true} />)}
                                    {(field.type === 'checkbox' && 'flag' in field) && (<DynamicCheckbox field={field} handleUpdateGeneratedField={handleUpdateGeneratedField} handleDeleteGeneratedField={handleDeleteGeneratedField} error={errorCheckbox} newTemplate={true} />)}
                                    {(field.type === 'select' && 'selectValues' in field) && (<DynamicSelect field={field} handleUpdateGeneratedField={handleUpdateGeneratedField} handleDeleteGeneratedField={handleDeleteGeneratedField} error={errorSelect} newTemplate={true} />)}
                                </div>
                            )
                        })}
                    </div>
                </>
            )}

            {createLogError && (
                <div className="text-center my-2 w-6/12 py-3 mx-auto">
                    <span className="text-status-critical font-semibold">{createLogError}</span>
                </div>
            )}

            <div className="text-center mt-2">
                {/* Display error message */}
                {error.generalError && (<span className="text-status-critical text-sm font-semibold block">{error.generalError}</span>)}
                {error.logNameError && (<span className="text-status-critical text-sm font-semibold block">{error.logNameError}</span>)}
                {error.categoryError && (<span className="text-status-critical text-sm font-semibold block">{error.categoryError}</span>)}
                {error.fieldTypeError && (<span className="text-status-critical text-sm font-semibold block">{error.fieldTypeError}</span>)}
                {error.accessError && (<span className="text-status-critical text-sm font-semibold block">{error.accessError}</span>)}

                {(errorInput && !error) && (<span className="text-status-critical text-sm font-semibold block">{errorInput.nameField}</span>)}
                {(errorCheckbox && !error) && (<span className="text-status-critical text-sm font-semibold block">{errorCheckbox.nameField}</span>)}
                {(errorSelect && !error) && (<span className="text-status-critical text-sm font-semibold block">{errorSelect.nameField}</span>)}

                <button
                    className="bg-primary-blue w-6/12 hover:bg-primary-blue-h rounded-md px-4 sm:px-8 py-2 mt-2 text-sm font-semibold text-white disabled:bg-gray-300 disabled:text-gray-400"
                    onClick={handleCreateLog}
                    disabled={generatedFields.length < 1}
                >
                    {t('common:create')}
                </button>
            </div>

            {/* Form preview */}
            {generatedFields.length > 0 && (
                <>
                    <hr className="w-8/12 h-[2px] my-10 mx-auto bg-gray-300 border-0 rounded" />
                    <div className="flex items-center justify-center gap-x-2 mt-2 flex-wrap">
                        <span className="text-sm font-semibold text-black">{showFormPreview ? t('page:logs.form.hideFormPreview') : t('page:logs.form.showFormPreview')}</span>
                        <button
                            onClick={() => setShowFormPreview(!showFormPreview)}
                            className="p-1 bg-primary-blue hover:bg-primary-blue-h text-white rounded material-symbols-outlined"
                        >{showFormPreview ? 'expand_less' : 'expand_more'}</button>
                    </div>

                    {showFormPreview && (
                        <div className="mb-10">
                            <h4 className="text-sm font-semibold text-center mt-2 text-black">{t('common:logName')}: {logName} </h4>
                            {generatedFields.map((field) => {
                                return (
                                    <div className="w-11/12 mx-auto" key={field.id}>
                                        <DynamicRenderPreview field={field} />
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default CreateCustomLog;