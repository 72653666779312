import { UserDto } from "../../../../services/tenant/user/dto/user.dto";
import { NotificationManager } from 'react-notifications';
import { useState } from "react";
import { useDeleteFileMutation, useUploadFileMutation } from "../../../../services/tenant/media/media.service";
import { compressImage } from "../../../../utils/compress-image";
import { ImageCompressSettings } from "../../../../constants/image-compress-settings";
import { UpdateUserFormType } from "../../forms/user/update-user-form";
import { useUpdateUserMutation, userAPI } from "../../../../services/tenant/user/user.service";
import { useTranslation } from "react-i18next";
import { UpdateUserDto } from "../../../../services/tenant/user/dto/update-user.dto";
import Modal from "../../../molecules/modal";
import ConfirmModal from "../../../molecules/confirm-modal";
import getImageDimensions from "../../../../utils/get-image-dimensions";
import { useDispatch } from "react-redux";

type Props = {
  user?: UpdateUserDto
}

const UserFileUpload = ({ user }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [uploadFile, response] = useUploadFileMutation();
  const [updateUser, { isLoading, error }] = useUpdateUserMutation();
  const [deleteFile] = useDeleteFileMutation();

  const [selectedFile, setSelectedFile] = useState<FileList | null>();
  const [fileError, setFileError] = useState<string>('');

  const [showDeleteBtn, setShowDeleteBtn] = useState<boolean>(false);
  const [showUploadBtn, setShowUploadBtn] = useState<boolean>(false);
  // const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  // Upload profile picture
  const submitFile = async (files: FileList) => {

    if (user && user.id) {
      if (files && files.length > 0) {
        setSelectedFile(undefined);
        const formData = new FormData();
        const file = files[0];
        if ((file.size / 1024 / 1024) > 10) {
          setFileError(t('common:form.selectedFileMax10MB'));
        }
        else {
          setFileError('');

          const result = await compressImage(file, 'avatar', 0.8, 'contain');
          if (result.success) {
            formData.append('uploadType', 'profile_picture');
            formData.append('file', result.data);
            formData.append('existingFileName', user.profile_picture ? user.profile_picture : '');
            formData.append('originalName', file.name);

            // Upload file
            try {
              const response = await uploadFile(formData).unwrap();
              if (response.success) {

                // Update user data with new image name
                const updateUserDto: UpdateUserDto = {
                  id: user.id,
                  profile_picture: response.data, // File path to be saved
                };

                const userUpdateResponse = await updateUser(updateUserDto).unwrap();
                if (userUpdateResponse && userUpdateResponse.success) {
                  NotificationManager.success(t('common:server.file_uploaded'));
                  dispatch(userAPI.util.invalidateTags(['Users', 'UserMe']));
                }
                else {
                  NotificationManager.error(t('common_server.file-not_uploaded'));
                }
              }
            }
            catch (error) {
              NotificationManager.error(t('common_server.file-not_uploaded'));
            }
          }
          else {
            setFileError(t(`common:${result.data}`));
          }
        }
      }
    }
  }


  const handleDeleteImg = async (filePath?: string) => {
    if (!filePath || !user)
      return

    try {
      const response = await deleteFile({ filePath }).unwrap();
      if (response.success) {
        // Remove filepath from user
        const updateUserDto: UpdateUserFormType = {
          id: user.id,
          profile_picture: ''
        };

        const userUpdateResponse = await updateUser(updateUserDto).unwrap();
        if (userUpdateResponse && userUpdateResponse.success) {
          NotificationManager.success(t(`common:server.file_deleted`));
          dispatch(userAPI.util.invalidateTags(['Users', 'UserMe']));
        }
        else {
          NotificationManager.error(t(`common:server.file_not_deleted`))
        }
      }
    }
    catch (error) {
      NotificationManager.error(t(`common:server.file_not_deleted`))
    }
  };

  return (
    <div className="w-full text-center">
      <div
        className="flex w-20 md:w-28 h-20 md:h-28 rounded-full items-center mx-auto border relative"
        onMouseOver={() => setShowDeleteBtn(true)}
        onMouseLeave={() => setShowDeleteBtn(false)}>
        {user && user.profile_picture ? (
          <>
            <label htmlFor='profile_picture' className="cursor-pointer">
              <input
                type="file"
                multiple={false}
                accept={'.png, .jpeg, .jpg, .svg'}
                id="profile_picture"
                className="hidden"
                onChange={(e) => setSelectedFile(e.target.files)}
              />
              <img
                src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${user.tenant_id}/${user.profile_picture}`}
                // onError={e => e.currentTarget.src = DefaultPersonImg}
                alt="Profile picture"
                className={`${showDeleteBtn ? 'opacity-80' : ''} w-20 md:w-28 h-20 md:h-28 object-cover rounded-full`} />
            </label>
          </>
        ) : (
          <div className="flex items-center justify-center w-20 md:w-28 h-20 md:h-28">
            <span
              className="material-symbols-outlined"
              style={{ fontSize: '2.5rem' }}>
              person
            </span>
          </div>
        )}

        {!user?.profile_picture && (
          <label htmlFor='profile_picture' className="cursor-pointer">
            <input
              type="file"
              multiple={false}
              accept={'.png, .jpeg, .jpg, .svg'}
              id="profile_picture"
              className="hidden"
              onChange={(e) => setSelectedFile(e.target.files)}
            />
            <span className="material-symbols-outlined absolute mt-5 md:mt-7 ml-[-15px] md:ml-[-20px] p-0.5 rounded-full cursor-pointer text-primary-blue bg-secondary-blue hover:bg-secondary-blue-h self-center"
              style={{ fontSize: '1.1rem' }}>
              add
            </span>
          </label>
        )}

        {(showDeleteBtn && user?.profile_picture) && (
          <span
            className="material-symbols-outlined absolute cursor-pointer bg-white rounded-full p-1.5 text-status-critical hover:text-status-critical-h ml-14 md:ml-20 mb-14 md:mb-20"
            onClick={() => handleDeleteImg(user?.profile_picture)}
            style={{ fontSize: '1.4rem' }}
          >delete</span>
        )}
      </div>

      {(selectedFile && !response.isLoading) && (
        <>
          <p className="text-xs mt-1">({selectedFile[0].name})</p>
          <button
            onClick={() => submitFile(selectedFile)}
            className="rounded-md bg-primary-blue hover:bg-primary-blue-h px-2 mt-1 text-xs font-bold leading-6 text-secondary-blue shadow-sm"
          >{t('common:upload')}</button>
        </>
      )}

      {response.isLoading && (
        <div className="my-3 text-center">
          <span className="text-xs font-semibold text-black">{t('common:form.fileBeingUploaded')}</span>
          <span className="material-symbols-outlined animate-bounce text-primary-blue">cloud_upload</span>
        </div>
      )}

      {/* <ConfirmModal isOpen={showDeleteModal} close={() => setShowDeleteModal(false)} title='Confirm delete' size='xs' titleAlign='center' type='info' titleSize="xs">
        <p className="text-sm font-semibold">Are you sure you want to delete the image?</p>
        <button
          // onClick={deletePropertyConfirmed}
          className="bg-status-critical hover:bg-status-critical-h py-1 px-1 text-xs sm:px-6 mt-3 rounded mx-auto text-white font-semibold">Delete</button>
      </ConfirmModal> */}
    </div>
  )
}

export default UserFileUpload;