import React from 'react'
import { useTranslation } from 'react-i18next';

type Props = {
    selectableTabs: string[];
    setSelectedSubTab: (val: string) => void;
    selectedSubTab: string;
}

const SubTabs = ({
    selectableTabs,
    setSelectedSubTab,
    selectedSubTab,
}: Props) => {
    const { t } = useTranslation();


    return (
        <div className="bg-primary-blue-5 w-fit mx-auto sm:mx-0 p-1 rounded-md text-xs font-semibold my-3">
            {selectableTabs.map((tab, idx) => {
                return (
                    <button key={idx} onClick={() => setSelectedSubTab(tab)} className={`transition-all py-2 px-4 rounded-md cursor-pointer ${selectedSubTab === tab ? 'bg-white' : ''}`}>
                        {t(`common:${tab}`)}
                    </button>
                )
            })}
        </div>
    )
}

export default SubTabs