import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { TenantDto } from '../../tenant/tenant/dto/tenant.dto'

export const catalogTenantAPI = createApi({
    reducerPath: 'catalogTenantAPI',
    tagTypes: ['Post'],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BACKEND_URL + '/catalog/tenant-info/',
        headers: { 'Access-Control-Allow-Credentials': 'true'},
        credentials: 'include'
    }),
    endpoints: (build) => ({
        // Create new tenant
        createTenant: build.mutation<any, TenantDto>({
            query: (tenant) => ({
                url: '',
                method: 'POST',
                body: tenant,
            }),
        }),
        getTenants: build.query<any, void>({
            query: () => ({
                url: '',
                method: 'GET',
                credentials: 'include',
            })
        })
    }),
})

export const { 
    useCreateTenantMutation,
    useGetTenantsQuery

} = catalogTenantAPI