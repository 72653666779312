import { PageOptionsDto } from '../../dto/page-options.dto';
import { PageDto } from '../../dto/page.dto';
import { reAuthAPI } from '../../re-auth/re-auth';
import { CreateLogEntryCustomDto } from './dto/create-log-entry-custom.dto';
import { LogEntryQueryParams } from './dto/log-entry-query-params.dto';
import { LogEntryDto } from './dto/log-entry.dto';
import { UpdateLogEntryCustomDto } from './dto/update-log-entry-custom.dto';

export const logAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Get log entries by propertyId, propertyBuildingId, propertyObjectId and logTemplateId
        getLogEntries: builder.query<PageDto<LogEntryDto>, { pageOptions: PageOptionsDto, queryParamsLogEntry: LogEntryQueryParams }>({
            query: (options) => {
                const queryParamsPaginated = [];
                if (options.pageOptions.page !== undefined) queryParamsPaginated.push(`page=${options.pageOptions.page}`);
                if (options.pageOptions.take !== undefined) queryParamsPaginated.push(`take=${options.pageOptions.take}`);
                if (options.pageOptions.order !== undefined) queryParamsPaginated.push(`order=${options.pageOptions.order}`);
                if (options.pageOptions.search !== undefined && options.pageOptions.search.length > 0) queryParamsPaginated.push(`search=${options.pageOptions.search}`);
                if (options.pageOptions.sortBy !== undefined) queryParamsPaginated.push(`sort_by=${options.pageOptions.sortBy}`);

                // Contruct query string for paginated request
                const queryStringPaginated = queryParamsPaginated.length > 0 ? `?${queryParamsPaginated.join('&')}` : '';

                // Construct query for log entry
                const queryParamsLogEntry = [];
                if (options.queryParamsLogEntry.log_template_id) queryParamsLogEntry.push(`log_template_id=${options.queryParamsLogEntry.log_template_id}`)
                if (options.queryParamsLogEntry.property_id) queryParamsLogEntry.push(`property_id=${options.queryParamsLogEntry.property_id}`);
                if (options.queryParamsLogEntry.property_building_id) queryParamsLogEntry.push(`property_building_id=${options.queryParamsLogEntry.property_building_id}`);
                if (options.queryParamsLogEntry.property_object_id) queryParamsLogEntry.push(`property_object_id=${options.queryParamsLogEntry.property_object_id}`);
                if (options.queryParamsLogEntry.archived) queryParamsLogEntry.push(`archived=${options.queryParamsLogEntry.archived}`);
                const queryStringLogEntry = queryParamsLogEntry.length > 0 ? `&${queryParamsLogEntry.join('&')}` : '';

                return {
                    url: `/logs${queryStringPaginated}${queryStringLogEntry}`,
                    method: 'GET',
                }
            },
            providesTags: ['Logs'],
        }),
        // Create custom log entry
        createCustomLogEntry: builder.mutation<any, CreateLogEntryCustomDto>({
            query: (data) => ({
                url: '/logs',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Logs']
        }),
        // Update custom log entry
        updateCustomLogEntry: builder.mutation<any, UpdateLogEntryCustomDto>({
            query: (data) => ({
                url: '/logs',
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Logs']
        }),
        // Get number of logs for given tenant
        getLogsCount: builder.query<number, void>({
            query: () => ({
                url: '/logs/count',
                method: 'GET',
            }),
            providesTags: ['Logs'],
        }),
        // Get number of logs by given log template id
        getLogsCountByLogTemplateId: builder.query<number, string>({
            query: (logTemplateId) => ({
                url: '/logs/count/' + logTemplateId,
                method: 'GET',
            }),
            providesTags: ['Logs'],
        }),
        // Get number of logs by userId
        getLogsCountByUserId: builder.query<number, string>({
            query: (userId) => ({
                url: '/logs/user/count/' + userId,
                method: 'GET',
            }),
            providesTags: ['Logs'],
        }),
        // Archive log entry
        archiveLogEntry: builder.mutation<any, string>({
            query: (id) => ({
                url: '/logs/' + id,
                method: 'DELETE',
            }),
            invalidatesTags: ['Logs']
        }),
        // Restore log entry
        restoreLogEntry: builder.mutation<any, string>({
            query: (id) => ({
                url: '/logs/restore/' + id,
                method: 'POST',
            }),
            invalidatesTags: ['Logs']
        }),
        // Get log entry details
        getLogEntryDetails: builder.query<LogEntryDto, string>({
            query: (logId) => ({
                url: '/logs/' + logId,
                method: 'GET',
            }),
            providesTags: ['Logs'],
        }),
    })
})

export const {
    useGetLogEntriesQuery,
    useCreateCustomLogEntryMutation,
    useUpdateCustomLogEntryMutation,
    useGetLogsCountQuery,
    useGetLogsCountByLogTemplateIdQuery,
    useGetLogsCountByUserIdQuery,
    useArchiveLogEntryMutation,
    useRestoreLogEntryMutation,
    useGetLogEntryDetailsQuery
} = logAPI;