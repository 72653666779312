import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useUpdateUserMutation, userAPI } from '../../../../services/tenant/user/user.service';
import { UpdateUserDto } from '../../../../services/tenant/user/dto/update-user.dto';
import { useAppSelector } from '../../../../redux/hooks';
import { getAuthenticatedUser } from '../../../../redux/slices/auth.slice';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import { getCurrentUser, setCurrentUser } from '../../../../redux/slices/user.slice';
import { useTranslation } from 'react-i18next';
import UserFileUpload from '../../user/user-file-upload';
import { Roles } from '../../../../services/enum/roles';
import SubsidiarySelect from '../../subsidiary/subsidiary-select';
import { useGetSubsidiariesQuery } from '../../../../services/tenant/subsidiary/subsidiary.service';
import { useDispatch } from 'react-redux';
import { SelectCustomStyles } from '../../../atoms/select-custom-styles/select-custom-styles';
import PrimaryButton from '../../../atoms/primary-button';


type Props = {
    user?: UpdateUserDto;
}

export type UpdateUserFormType = {
    id: string;
    name?: string;
    phone?: string;
    email?: string;
    password?: string;
    role?: Roles;
    address?: string;
    city?: string;
    zip?: string;
    country?: string;
    active?: boolean;
    two_fa_enabled?: boolean;
    two_fa_expiry_date?: Date;
    phone_verified?: boolean;
    email_verified?: boolean;
    profile_picture?: string;
    subsidiary_id?: string | null;
}

const UpdateUserForm = ({ user }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { currentUser } = useAppSelector(getCurrentUser);
    const [updateUser, response] = useUpdateUserMutation();

    const [error, setError] = useState<string>();

    // Options array for subsidiaries
    const [options, setOptions] = useState<{ label: string, value: string, subsidiary_id: string | null }[]>();

    const { control, handleSubmit, setValue, formState: { errors } } = useForm<UpdateUserFormType>({
        defaultValues: {
            name: user?.name || '',
            email: user?.email || '',
            phone: user?.phone || '',
            address: user?.address || '',
            city: user?.city || '',
            zip: user?.zip || '',
            country: user?.country || '',
            role: user?.role ?? Roles.None,
            profile_picture: user?.profile_picture || '',
            subsidiary_id: user?.subsidiary_id || null,
        }
    });

    const handleSelectedSubsidiary = (selectOption: { label: string, value: string }) => {
        if (selectOption.value && selectOption.value.length > 1) {
            setValue('subsidiary_id', selectOption.value);
        }
        else {
            setValue('subsidiary_id', null);
        }
    }

    // Submit form
    const onSubmit = async (formData: UpdateUserFormType) => {
        if (user?.id) {
            // Set user id
            formData.id = user.id;
            if (formData.role) {
                formData.role = formData.role;
            }

            try {
                const response = await updateUser(formData).unwrap();
                if (response.success) {
                    NotificationManager.success(t('page:users.form.userUpdated'));
                    setError('');
                    dispatch(userAPI.util.invalidateTags(['Users']));
                }
                else {
                    NotificationManager.error(t('page:users.form.userNotUpdated'));
                    setError(t(`common:server.${response.message}`));
                }
            }
            catch (error: any) {
                NotificationManager.error(t('page:users.form.userNotUpdated'));
                setError('');
            }
        }
    }

    return (
        <>
            <UserFileUpload user={user} />

            <div className="sm:mx-auto w-full">
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="flex flex-col gap-y-2 w-full mx-auto">
                        <div className="">
                            <label htmlFor='name' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                                {t('common:name')}<span className="text-status-critical ">*</span>
                            </label>
                            <div className="mt-0.5">
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: t('common:form.nameNotEmpty'),
                                        minLength: {
                                            value: 3,
                                            message: t('common:form.nameMinLength3')
                                        },
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                {...field}
                                                type='text'
                                                maxLength={64}
                                                placeholder={t('common:name')}
                                                className={`${errors.name && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                            />
                                            {errors.name && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.name.message}</p>}
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="">
                            <label htmlFor='email' className={`block text-xs sm:text-sm font-semibold leading-6 text-gray-600`}>
                                {t('common:email')}<span className="text-status-critical ">*</span>
                            </label>
                            <div className="mt-0.5">
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: t('common:form.emailNotEmpty'),
                                        minLength: {
                                            value: 3,
                                            message: t('common:form.emailMinLength3')
                                        },
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                {...field}
                                                type='email'
                                                maxLength={32}
                                                placeholder={t('common:email')}
                                                className={`${errors.email && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                            />
                                            {errors.email && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.email.message}</p>}
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                            <div>
                                <label htmlFor='role' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                                    {t('common:role')}<span className="text-status-critical ">*</span>
                                </label>
                                <Controller
                                    name="role"
                                    control={control}
                                    rules={{}}
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                name="role"
                                                isDisabled={currentUser.id === user?.id || (currentUser.role !== Roles.Superadmin && user?.role === Roles.Superadmin)}
                                                className={`${errors.role ? 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical rounded-md' : ''} text-sm z-50`}
                                                value={(Object.entries(Roles) as [string, string][])
                                                    .filter(([key, value]) => typeof value === 'string')
                                                    .map(([key, value]) => ({ label: t(`common:enums.roles.${key}`), value: value.toString() }))
                                                    .find(option => option.value === field.value?.toString())}
                                                onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                                                styles={SelectCustomStyles}
                                                options={(Object.values(Roles) as Array<keyof typeof Roles>)
                                                    .filter((val) => typeof val === 'string' && (currentUser.role === Roles.Superadmin || val !== Roles.Superadmin))
                                                    .map((val) => ({
                                                        label: t(`common:enums.roles.${Roles[val]}`),
                                                        value: val,
                                                    }))
                                                }
                                                closeMenuOnSelect={true}
                                                hideSelectedOptions={false}
                                                controlShouldRenderValue={true}
                                                isClearable={false}
                                                backspaceRemovesValue={false}
                                                placeholder={t('common:select')}
                                                noOptionsMessage={() => t('page:users.noRolesFound')}
                                            />
                                        </>
                                    )}
                                />
                                {errors.role && <p className="text-status-critical text-xs my-1 ml-1">{errors.role.message}</p>}
                            </div>
                        )}
                        <div className="">
                            <label htmlFor='phone' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                                {t('common:phone')}<span className="text-status-critical ">*</span>
                            </label>
                            <div className="mt-0.5">
                                <Controller
                                    name="phone"
                                    control={control}
                                    rules={{
                                        required: t('page:users.form.phoneNotEmpty'),
                                        minLength: {
                                            value: 10,
                                            message: t('page:users.form.phoneMinLength10')
                                        },
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                {...field}
                                                type='text'
                                                maxLength={16}
                                                placeholder={t('common:phone')}
                                                className={`${errors.phone && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                            />
                                            {errors.phone && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.phone.message}</p>}
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                            <div>
                                <label htmlFor='subsidiary_id' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                                    {t('page:company:userBelongsToSubsidiary')}
                                    <span
                                        className="material-symbols-outlined text-gray-600 ml-1 align-sub"
                                        style={{ fontSize: '1.2rem' }}
                                        title={t('page:company.userSubsidiaryInfo')}
                                    >help</span>
                                </label>
                                <SubsidiarySelect
                                    handleSelectedSubsidiary={handleSelectedSubsidiary}
                                    id={user?.subsidiary_id ? user.subsidiary_id : undefined}
                                />
                                {errors.subsidiary_id && <p className="text-status-critical text-xs my-1 ml-1">{errors.subsidiary_id.message}</p>}
                            </div>
                        )}
                        <div className="">
                            <label htmlFor='address' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                                {t('common:address')}
                            </label>
                            <Controller
                                name="address"
                                control={control}
                                rules={{
                                    minLength: {
                                        value: 3,
                                        message: t('common:form.addressMinLength3')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={32}
                                            placeholder={t('common:address')}
                                            className={`${errors.address && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {errors.address && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.address.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                        <div className="">
                            <label htmlFor='city' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                                {t('common:city')}
                            </label>
                            <Controller
                                name="city"
                                control={control}
                                rules={{
                                    minLength: {
                                        value: 3,
                                        message: t('common:form.cityMinLength3')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={32}
                                            placeholder={t('common:city')}
                                            className={`${errors.city && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {errors.city && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.city.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                        <div className="">
                            <label htmlFor='zip' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                                {t('common:zip')}
                            </label>
                            <Controller
                                name="zip"
                                control={control}
                                rules={{
                                    minLength: {
                                        value: 5,
                                        message: t('common:form.zipMinLength5')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={16}
                                            placeholder={t('common:zip')}
                                            className={`${errors.zip && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {errors.zip && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.zip.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                        <div className="">
                            <label htmlFor='country' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                                {t('common:country')}
                            </label>
                            <Controller
                                name="country"
                                control={control}
                                rules={{
                                    minLength: {
                                        value: 2,
                                        message: t('common:form.countryMinLength2')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={32}
                                            placeholder={t('common:country')}
                                            className={`${errors.country && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        {errors.country && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.country.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                        {/* <div className="">
                            <label htmlFor='role' className="block text-sm font-semibold leading-6 text-gray-600">
                                Role
                            </label>
                            <Controller
                                name="role"
                                control={control}
                                rules={{
                                    // required: 'Some message',
                                    // validate: validateInput,
                                }}
                                render={({ field }) => (
                                    <>
                                        <select
                                            {...field}
                                            disabled={authUser.role < 4} // Disable field if authUser is not admin or higher
                                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        >
                                            {(Object.values(Roles) as Array<keyof typeof Roles>).map((val) => {
                                                return (
                                                    <>
                                                        {(typeof val === 'number') && (
                                                            <option key={user?.id} value={val}>{Roles[val]}</option>
                                                        )}
                                                    </>
                                                )
                                            })}
                                        </select>
                                    </>
                                )}
                            />
                        </div> */}

                        {error && (
                            <p className="font-bold text-sm mt-2 text-center text-status-critical">{error}</p>
                        )}
                        <PrimaryButton
                            text={t('common:update')}
                            size={'medium'}
                            fullWidth={true}
                        />
                    </div>
                </form>
            </div>
        </>
    )
}

export default UpdateUserForm;