import { useEffect, useRef, useState } from "react";
import { Grow, Input } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LogCategories } from "../../../../../services/enum/log-categories";
import { Order } from "../../../../../services/enum/order";
import { debounce } from "../../../../../utils/debounce";
import Loader from "../../../../atoms/loader/loader";
import AllLogCategories from "../../../log-components/log-structure/all-log-categories";
import { LogTypes } from "../../../../../services/enum/log-types";
import { useAppSelector } from "../../../../../redux/hooks";
import { getCurrentUser } from "../../../../../redux/slices/user.slice";
import { ContractorLogAccessDto } from "../../../../../services/tenant/contractor-log-user-access/dto/contractor-log-access.dto";
import { useGetUniqueEntitiesLogAccessByTenantIdQuery } from "../../../../../services/tenant/property-owner-contractor-log-access/property-owner-contractor-log-access.service";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FriendshipTenantDto } from "../../../../../services/catalog/friendship/dto/friendship-tenant.dto";
import { useGetTenantLogsByUserIdQuery, useGetUniqueLogCategoriesByAccessQuery } from "../../../../../services/tenant/contractor-log-user-access/contractor-log-user-access.service";
import LogFilterByEntities from "../../../log-components/log-structure/log-filter-by-entities";
import Table from "../../../../molecules/table";
import Pagination from "../../../../molecules/pagination";

type FilterType = {
    properties?: string;
    buildings?: string;
    objects?: string;
    category?: LogCategories;
}

type Props = {
    selectedCompany: FriendshipTenantDto,
    setSelectedTenantLog: (log: ContractorLogAccessDto | null) => void;
    selectedTenantLog?: ContractorLogAccessDto | null;
    tenantIdPropertyOwner?: string;
}

const PropertyLogsContractorTable = ({
    selectedCompany,
    setSelectedTenantLog,
    selectedTenantLog,
    tenantIdPropertyOwner
}: Props) => {
    const { t } = useTranslation();
    const { currentUser } = useAppSelector(getCurrentUser);

    // Pagination
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>();
    const [hasNextPage, setHasNextPage] = useState<boolean>();
    const [hasPrevPage, setHasPrevPage] = useState<boolean>();

    // Table sort and search
    const [search, setSearch] = useState<string | undefined>('');
    const [searchOperation, setSearchOperation] = useState<string | undefined>();
    const [sortBy, setSortBy] = useState<string | undefined>('belongs_to'); // Column to sort by
    const [sortOrder, setSortOrder] = useState<Order>(Order.ASC); // Sort order

    // State for filters
    const [filterObject, setFilterObject] = useState<FilterType>();
    const [filterString, setFilterString] = useState<string>();

    // Filter based on property, building or object
    const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
    const [selectedBuildings, setSelectedBuildings] = useState<string[]>([]);
    const [selectedObjects, setSelectedObjects] = useState<string[]>([]);

    // State for selected category
    const [selectedCategory, setSelectedCategory] = useState<LogCategories>();

    const debouncedSearch = useRef(debounce((search: string) => setSearchOperation(search), 500)).current;

    const [skipTokenUserIdTenantId, setSkipTokenUserIdTenantId] = useState<any>(skipToken);
    const { data: tenantLogs, isFetching } = useGetTenantLogsByUserIdQuery(
        selectedCompany && currentUser ?
            {
                userId: currentUser.id,
                tenantId: selectedCompany.tenant_id,
                pageOptions: {
                    page: page,
                    take: 25,
                    search: searchOperation,
                    sortBy: sortBy,
                    order: sortOrder,
                    filter: filterString
                }
            } : skipTokenUserIdTenantId
    );

    // Get unique log categories by access
    const { data: logCategories } = useGetUniqueLogCategoriesByAccessQuery(skipTokenUserIdTenantId);

    // Get unique entities by log access
    const [skipTokenPropertyOwnerTenantId, setSkipTokenPropertyOwnerId] = useState<any>(skipToken);
    const { data: uniqueEntities } = useGetUniqueEntitiesLogAccessByTenantIdQuery(skipTokenPropertyOwnerTenantId);

    useEffect(() => {
        if (currentUser && selectedCompany) {
            setSkipTokenUserIdTenantId({
                userId: currentUser.id,
                tenantId: selectedCompany.tenant_id
            })
        }
    }, [selectedCompany])

    useEffect(() => {
        if (tenantIdPropertyOwner) {
            setSkipTokenPropertyOwnerId(tenantIdPropertyOwner)
        }
    }, [tenantIdPropertyOwner])

    useEffect(() => {
        if (tenantLogs) {
            setPage(tenantLogs.meta.page);
            setPageCount(tenantLogs.meta.pageCount);
            setHasNextPage(tenantLogs.meta.hasNextPage);
            setHasPrevPage(tenantLogs.meta.hasPreviousPage);
        }
    }, [tenantLogs])

    // Construct filterQuery
    useEffect(() => {
        let tempObj: FilterType = {};

        if (selectedProperties && selectedProperties.length > 0) {
            tempObj.properties = selectedProperties.map(e => e).join(',');
        }
        if (selectedBuildings && selectedBuildings.length > 0) {
            tempObj.buildings = selectedBuildings.map(e => e).join(',');
        }
        if (selectedObjects && selectedObjects.length > 0) {
            tempObj.objects = selectedObjects.map(e => e).join(',');
        }
        if (selectedCategory !== undefined && selectedCategory !== LogCategories.None) {
            tempObj.category = selectedCategory;
        }

        // Check if there's a change in the filter
        if (JSON.stringify(filterObject) !== JSON.stringify(tempObj)) {
            setFilterObject(tempObj);
            const encoded = encodeURIComponent(JSON.stringify(tempObj));
            setFilterString(encoded);
        }

    }, [selectedProperties, selectedBuildings, selectedObjects, selectedCategory])

    const handlePagination = (page: number) => {
        setPage(page);
    }

    const handleSort = (column: string, order: Order) => {
        setSortBy(column);
        setSortOrder(order === Order.ASC ? Order.DESC : Order.ASC);
    }

    return (
        <Grow in={selectedCompany ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
            <div className="w-full mx-auto rounded-md overflow-x-hidden">
                <div className="flex items-center justify-center sm:justify-normal mt-5">
                    <h5 className="text-base font-semibold ml-2">
                        {selectedCompany ? (
                            <>
                                {t('page:logs.showingLogsFor')} {selectedCompany?.company_name}
                            </>
                        ) : (
                            <>
                                {t('common:logs')}
                            </>
                        )}
                    </h5>
                </div>
                <div className="sm:w-6/12 max-w-2xl text-center sm:text-left">
                    <Input
                        type="search"
                        name="search_name"
                        placeholder={t('common:search')}
                        disableUnderline={true}
                        className="w-full border rounded-md text-base md:text-sm px-2 pt-2.5 pb-1 mt-3 bg-white"
                        onChange={(e) => {
                            setSearch(e.target.value)
                            debouncedSearch(e.target.value)
                        }
                        }
                        value={search}
                        endAdornment={
                            <span
                                className="material-symbols-outlined pb-1 text-gray-500 cursor-pointer"
                                style={{ fontSize: '1.3rem' }}
                                onClick={() => {
                                    if (search) {
                                        setSearch('')
                                        debouncedSearch('')
                                    }
                                }}
                            >
                                {search ? 'close' : 'search'}
                            </span>
                        }
                        size="small"
                    />
                </div>
                <div className="w-full">
                    <AllLogCategories logCategories={logCategories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} alignCenter={true} />
                </div>
                <div className="w-full mt-1 mb-2">
                    {uniqueEntities && uniqueEntities.length > 0 && (
                        <LogFilterByEntities
                            allPropertyLabels={uniqueEntities.filter(e => e.property_label !== undefined).map(e => e.property_label as string)}
                            allBuildingLabels={uniqueEntities.filter(e => e.property_building_label !== undefined).map(e => e.property_building_label as string)}
                            allObjectNames={uniqueEntities.filter(e => e.property_object_name !== undefined).map(e => e.property_object_name as string)}
                            setSelectedProperties={setSelectedProperties}
                            selectedProperties={selectedProperties}
                            setSelectedBuildings={setSelectedBuildings}
                            selectedBuildings={selectedBuildings}
                            setSelectedObjects={setSelectedObjects}
                            selectedObjects={selectedObjects}
                        />
                    )}
                </div>

                <div>
                    <Table>
                        <Table.Thead>
                            <tr className="">
                                <th scope="col" className="px-1 sm:px-6 py-2 sm:py-3 text-left font-bold min-w-[4rem]">
                                    {t('common:category')}
                                </th>
                                <th
                                    scope="col"
                                    className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]"
                                    onClick={() => handleSort('log_template_name', sortOrder)}>
                                    {t('common:logName')}
                                    {(sortBy === 'log_template_name') ? (
                                        <>
                                            {sortOrder === Order.ASC ? (
                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                            ) : (
                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                        </>
                                    )}
                                </th>
                                <th
                                    scope="col"
                                    className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]"
                                    onClick={() => handleSort('belongs_to', sortOrder)}>
                                    {t('common:belongsTo')}
                                    {(sortBy === 'belongs_to') ? (
                                        <>
                                            {sortOrder === Order.ASC ? (
                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                            ) : (
                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                        </>
                                    )}
                                </th>
                            </tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {tenantLogs && tenantLogs.data.length > 0 && (
                                <>
                                    {tenantLogs?.data.map((record, idx) => {
                                        return (
                                            <tr
                                                key={idx}
                                                className={`odd:hover:bg-primary-blue-10 even:hover:bg-gray-50 cursor-pointer text-xs text-black even:bg-white odd:bg-primary-blue-5`}
                                                onClick={() => setSelectedTenantLog(record)}
                                            >

                                                <td
                                                    scope="row"
                                                    className="px-1 py-2 sm:px-6 capitalize text-left">
                                                    {record.log_category === LogCategories.Other && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[record.log_category]}`)}>home_repair_service</span>)}
                                                    {record.log_category === LogCategories.Fire && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[record.log_category]}`)}>local_fire_department</span>)}
                                                    {record.log_category === LogCategories.Security && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[record.log_category]}`)}>encrypted</span>)}
                                                    {record.log_category === LogCategories.Camera && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[record.log_category]}`)}>photo_camera</span>)}
                                                    {record.log_category === LogCategories.Access && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[record.log_category]}`)}>vpn_key</span>)}
                                                    {record.log_category === LogCategories.Maintenance && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[record.log_category]}`)}>construction</span>)}
                                                    {record.log_category === LogCategories.Cleaning && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[record.log_category]}`)}>mop</span>)}
                                                    {record.log_category === LogCategories.Rounding && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[record.log_category]}`)}>shield_person</span>)}
                                                    {record.log_category === LogCategories.Elevator && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[record.log_category]}`)}>elevator</span>)}
                                                    {record.log_category === LogCategories.Ventilation && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[record.log_category]}`)}>mode_fan</span>)}

                                                </td>
                                                <td
                                                    className="py-2 pr-2 pl-1 sm:pl-2 text-xs capitalize font-semibold sm:font-normal max-w-[9rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                    title={record.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${record.log_template_name.split('_').join('')}`) :
                                                        record.log_template_name.split('_').join(' ')}
                                                >
                                                    {
                                                        record.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${record.log_template_name.split('_').join('')}`) :
                                                            record.log_template_name.split('_').join(' ')
                                                    }
                                                </td>

                                                <td
                                                    className="py-2 pr-2 pl-1 sm:pl-2 text-xs capitalize font-semibold sm:font-normal max-w-[9rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                                    {
                                                        record.property_object_id ? <span className="" title={record.property_object_name}>{record.property_object_name}</span> :
                                                            record.property_building_id ? <span className="" title={record.property_building_label}>{record.property_building_label}</span> :
                                                                <span title={record.property_label}>{record.property_label}</span>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </>
                            )}
                        </Table.Tbody>
                    </Table>
                </div>


                {isFetching && (
                    <div className="mx-auto mb-5 mt-1">
                        <Loader show={isFetching} size='small' />
                    </div>
                )}

                {!tenantLogs || tenantLogs.data.length < 1 && (
                    <div className="mt-5 mb-5">
                        <p className="text-sm ml-5 text-black font-semibold">{t('page:properties.noLogsFound')}...</p>
                    </div>
                )}
                {tenantLogs?.meta && tenantLogs?.meta?.hasNextPage && (
                    <div className="flex justify-center py-2 border-t">
                        <Pagination
                            count={pageCount}
                            page={page}
                            defaultPage={1}
                            showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                            showLastButton={pageCount ? pageCount > 5 : false}
                            hidePrevButton={!hasPrevPage}
                            hideNextButton={!hasNextPage}
                            onChange={handlePagination}
                        />
                    </div>
                )}
            </div>
        </Grow>
    )
}

export default PropertyLogsContractorTable;