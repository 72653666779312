import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import SubsidiarySelectMulti from '../../subsidiary/subsidiary-select-multi';
import PropertySelectMulti from '../../property/property-select-multi';
import UserSelectMulti from '../../user/user-select-multi';
import LogTemplateSelectMulti from '../../log-components/log-structure/log-template-select-multi';
import { SubsidiaryDto } from '../../../../services/tenant/subsidiary/dto/subsidiary.dto';
import { PropertyDto } from '../../../../services/tenant/property/dto/property.dto';
import { UserDto } from '../../../../services/tenant/user/dto/user.dto';
import { LogTemplateDto } from '../../../../services/tenant/log-template/dto/log-template.dto';

type SelectType = {
    label: string;
    value: string;
    data: SubsidiaryDto | PropertyDto | UserDto | LogTemplateDto
}

type Props = {
    setSelectedSubsidiaries: (subsidiaries: SubsidiaryDto[]) => void;
    selectedSubsidiaries?: SubsidiaryDto[];
    setSelectedProperties: (properties: PropertyDto[]) => void;
    selectedProperties?: PropertyDto[];
    setSelectedUsers: (users: UserDto[]) => void;
    selectedUsers?: UserDto[];
    setSelectedLogTemplates: (logTemplates: LogTemplateDto[]) => void;
    selectedLogTemplates?: LogTemplateDto[];
};

const RightsSelect = ({
    setSelectedSubsidiaries,
    selectedSubsidiaries,
    setSelectedProperties,
    selectedProperties,
    setSelectedUsers,
    selectedUsers,
    setSelectedLogTemplates,
    selectedLogTemplates,
}: Props) => {
    const { t } = useTranslation();

    const [noneSelected, setNoneSelected] = useState<boolean>(true);

    useEffect(() => {
        if (
            selectedSubsidiaries && selectedSubsidiaries.length === 0 &&
            selectedProperties && selectedProperties.length === 0 &&
            selectedUsers && selectedUsers.length === 0 &&
            selectedLogTemplates && selectedLogTemplates.length === 0
        ) {
            setNoneSelected(true);
        }
        else {
            setNoneSelected(false);
        }

    }, [selectedSubsidiaries, selectedProperties, selectedUsers, selectedLogTemplates])

    const handleSelectedSubsidiaries = useCallback((selectedSubsidiaries: SelectType[]) => {
        if (selectedSubsidiaries.length > 0) {
            const subsidiariesData = selectedSubsidiaries.map(e => e.data as SubsidiaryDto);
            setSelectedSubsidiaries(subsidiariesData);

            setSelectedProperties([]);
            setSelectedUsers([]);
            setSelectedLogTemplates([]);
        }
        else {
            setSelectedSubsidiaries([]);
        }
    }, [setSelectedSubsidiaries])

    const handleSelectedProperties = useCallback((selectedProperties: SelectType[]) => {
        if (selectedProperties.length > 0) {
            const propertiesData = selectedProperties.map(e => e.data as PropertyDto);
            setSelectedProperties(propertiesData);

            setSelectedSubsidiaries([]);
            setSelectedUsers([]);
            setSelectedLogTemplates([]);
        }
        else {
            setSelectedProperties([]);
        }
    }, [setSelectedProperties])

    const handleSelectedUsers = useCallback((selectedUsers: SelectType[]) => {
        if (selectedUsers.length > 0) {
            const usersData = selectedUsers.map(e => e.data as UserDto);
            setSelectedUsers(usersData);

            setSelectedSubsidiaries([]);
            setSelectedProperties([]);
            setSelectedLogTemplates([]);
        }
        else {
            setSelectedUsers([]);
        }
    }, [setSelectedUsers])

    const handleSelectedLogTemplates = useCallback((selectedLogTemplates: SelectType[]) => {
        if (selectedLogTemplates.length > 0) {
            const logTemplatesData = selectedLogTemplates.map(e => e.data as LogTemplateDto);
            setSelectedLogTemplates(logTemplatesData);

            setSelectedSubsidiaries([]);
            setSelectedProperties([]);
            setSelectedUsers([]);
        }
        else {
            setSelectedLogTemplates([]);
        }
    }, [setSelectedLogTemplates])

    return (
        <div className="flex flex-wrap flex-col md:flex-row mx-auto gap-x-3 justify-center mt-5">
            <div className="md:w-5/12">
                <p className="text-sm">{t('common:subsidiary')}</p>
                <SubsidiarySelectMulti handleSelectedSubsidiaries={handleSelectedSubsidiaries} zIndex='50' reset={(selectedSubsidiaries && selectedSubsidiaries.length > 0) ? false : true} noneSelected={noneSelected} />
            </div>
            <div className="md:w-5/12">
                <p className="text-sm">{t('common:property')}</p>
                <PropertySelectMulti handleSelectedProperties={handleSelectedProperties} zIndex='40' reset={(selectedProperties && selectedProperties.length > 0) ? false : true} noneSelected={noneSelected} />
            </div>
            <div className="md:w-5/12">
                <p className="text-sm">{t('common:user')}</p>
                <UserSelectMulti handleSelectedUsers={handleSelectedUsers} zIndex='30' reset={(selectedUsers && selectedUsers.length > 0) ? false : true} noneSelected={noneSelected} />
            </div>
            <div className="md:w-5/12">
                <p className="text-sm">{t('page:logs.logTemplate')}</p>
                <LogTemplateSelectMulti handleSelectedLogTemplates={handleSelectedLogTemplates} zIndex='20' reset={(selectedLogTemplates && selectedLogTemplates.length > 0) ? false : true} noneSelected={noneSelected} />
            </div>
        </div>
    )
}

export default RightsSelect;