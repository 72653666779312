
import { Fragment, useEffect, useRef, useState } from "react";
import { dateToLocaleDate, dateToLocaleDateAndHour } from "../../../../../utils/date-time";

import Modal from "../../../../molecules/modal";
import PropertyObjectLogAccess from "../log-property-object-access";
import LogPropertyAccess from "../log-property-access";
import LogAccessPropertyBuilding from "../log-property-building-access";
import RevokeLogUserAccess from "../revoke-log-user-access";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../redux/hooks";
import { getCurrentUser } from "../../../../../redux/slices/user.slice";
import { LogTemplateDto } from "../../../../../services/tenant/log-template/dto/log-template.dto";
import { LogCategories } from "../../../../../services/enum/log-categories";
import { LogTypes } from "../../../../../services/enum/log-types";
import { LogAccess } from "../../../../../services/enum/log-access";
import { Order } from "../../../../../services/enum/order";
import { debounce } from "../../../../../utils/debounce";
import { useGetLogTemplatesByTemplateIdsQuery, useGetLogTemplatesQuery, useGetLogTemplatesUniqueCategoriesQuery } from "../../../../../services/tenant/log-template/log-template.service";
import { Input } from "@mui/material";
import AllLogCategories from "../all-log-categories";
import Loader from "../../../../atoms/loader/loader";
import { Menu, Transition } from "@headlessui/react";
import Checkbox from "../../../../atoms/checkbox";
import Pagination from "../../../../molecules/pagination";
import Table from "../../../../molecules/table";

type FilterType = {
    category: LogCategories;
    showAllTemplates: boolean;
}

type Props = {
    setSelectedLogTemplate: (propertyLogAccess: LogTemplateDto) => void;
    selectedLogTemplate?: LogTemplateDto;
    setShowEditLogTemplate: (logTemplate: LogTemplateDto) => void;
}

const AllLogTemplatesTable = ({
    setSelectedLogTemplate,
    selectedLogTemplate,
    setShowEditLogTemplate,
}: Props) => {
    const { t } = useTranslation();
    const { currentUser } = useAppSelector(getCurrentUser);

    // Pagination
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>();
    const [hasNextPage, setHasNextPage] = useState<boolean>();
    const [hasPrevPage, setHasPrevPage] = useState<boolean>();

    // Table sort and search
    const [search, setSearch] = useState<string | undefined>('');
    const [searchOperation, setSearchOperation] = useState<string | undefined>();
    const [sortBy, setSortBy] = useState<string | undefined>('created_at'); // Column to sort by
    const [sortOrder, setSortOrder] = useState<Order>(Order.ASC); // Sort order
    const [showSortMenu, setShowSortMenu] = useState<boolean>(false); // Sort menu on small screens

    const debouncedSearch = useRef(debounce((search: string) => setSearchOperation(search), 500)).current;

    // State for filters
    const [filterObject, setFilterObject] = useState<FilterType>();
    const [filterString, setFilterString] = useState<string>();
    // State for selected category
    const [selectedCategory, setSelectedCategory] = useState<LogCategories>();

    // State to show only the log templates created by the tenant
    const [showAllTemplates, setShowAllTemplates] = useState<boolean>(true);

    // Get log templates
    const { data: logTemplates, isFetching } = useGetLogTemplatesQuery({
        page: page,
        take: 25,
        search: searchOperation,
        sortBy: sortBy,
        order: sortOrder,
        filter: filterString
    });

    // Get all unique log categories for log templates
    const { data: logCategories } = useGetLogTemplatesUniqueCategoriesQuery();

    const [showModal, setShowModal] = useState<boolean>(false); // Toggle modal
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false); // Toggle confirm modal

    useEffect(() => {
        if (logTemplates) {
            setPage(logTemplates.meta.page);
            setPageCount(logTemplates.meta.pageCount);
            setHasNextPage(logTemplates.meta.hasNextPage);
            setHasPrevPage(logTemplates.meta.hasPreviousPage);
        }
    }, [logTemplates])

    useEffect(() => {
        const tempObj: FilterType = {
            category: LogCategories.None,
            showAllTemplates: showAllTemplates,
        };
        if (selectedCategory !== undefined && selectedCategory !== LogCategories.None) {
            tempObj.category = selectedCategory;
        }
        if (filterObject || (tempObj.category !== LogCategories.None || tempObj.showAllTemplates === false)) {
            setFilterObject(tempObj);
            const encoded = encodeURIComponent(JSON.stringify(tempObj));
            setFilterString(encoded);
        }
    }, [selectedCategory, showAllTemplates])

    // Edit property
    const handleEditLog = (log: LogTemplateDto) => {
        setShowModal(true); // Show modal
        setSelectedLogTemplate(log);
    }

    const handleSort = (column: string, order: Order) => {
        setSortBy(column);
        setSortOrder(order === Order.ASC ? Order.DESC : Order.ASC);
    }

    const handlePagination = (page: number) => {
        setPage(page);
    }

    return (
        <div className="w-full mx-auto rounded-md overflow-x-hidden">
            <div className="hidden md:block">
                <div className="sm:w-6/12 max-w-2xl text-center sm:text-left">
                    <Input
                        type="search"
                        name="search_name"
                        placeholder={t('common:search')}
                        disableUnderline={true}
                        className="w-full border rounded-md text-base md:text-sm px-2 pt-2.5 pb-1 mt-3 bg-white"
                        onChange={(e) => {
                            setSearch(e.target.value)
                            debouncedSearch(e.target.value)
                        }
                        }
                        value={search}
                        endAdornment={
                            <span
                                className="material-symbols-outlined pb-1 text-gray-500 cursor-pointer"
                                style={{ fontSize: '1.3rem' }}
                                onClick={() => {
                                    if (search) {
                                        setSearch('')
                                        debouncedSearch('')
                                    }
                                }}
                            >
                                {search ? 'close' : 'search'}
                            </span>
                        }
                        size="small"
                    />
                </div>
                <div className="mb-2">
                    <AllLogCategories logCategories={logCategories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
                </div>

                {setShowAllTemplates && (
                    <div
                        onClick={() => setShowAllTemplates(!showAllTemplates)}
                        className="inline-block relative mt-1 md:mt-2 cursor-pointer">
                        <Checkbox
                            label={t('page:logs.showOnlyMyLogs')}
                            size={'md'}
                            checked={!showAllTemplates}
                        />
                    </div>
                )}

                <div className="overflow-x-auto">
                    <Table>
                        <Table.Thead>
                            <tr className="">
                                <th
                                    scope="col"
                                    className="px-1 sm:px-6 py-2 sm:py-3 text-left font-bold min-w-[6rem]"
                                    onClick={() => handleSort('category', sortOrder)}>
                                    {t('common:category')}
                                    {(sortBy === 'category') ? (
                                        <>
                                            {sortOrder === Order.ASC ? (
                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                            ) : (
                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                        </>
                                    )}
                                </th>
                                <th
                                    scope="col"
                                    className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]"
                                    onClick={() => handleSort('name', sortOrder)}>
                                    {t('common:name')}
                                    {(sortBy === 'name') ? (
                                        <>
                                            {sortOrder === Order.ASC ? (
                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                            ) : (
                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                        </>
                                    )}
                                </th>
                                {/* <th
                                    scope="col"
                                    className="py-1 md:py-2 pr-2 cursor-pointer font-bold min-w-[6rem] hidden sm:table-cell"
                                    onClick={() => handleSort('access', sortOrder)}>
                                    {t('common:access')}
                                    {(sortBy === 'access') ? (
                                        <>
                                            {sortOrder === Order.ASC ? (
                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                            ) : (
                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                        </>
                                    )}
                                </th> */}
                                <th
                                    scope="col"
                                    className="py-1 md:py-2 pr-2 cursor-pointer font-bold min-w-[6rem] hidden sm:table-cell"
                                    onClick={() => handleSort('log_type', sortOrder)}>
                                    {t('common:logType')}
                                    {(sortBy === 'log_type') ? (
                                        <>
                                            {sortOrder === Order.ASC ? (
                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                            ) : (
                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                        </>
                                    )}
                                </th>
                                <th
                                    scope="col"
                                    className="py-1 md:py-2 cursor-pointer font-bold min-w-[6rem]"
                                    onClick={() => handleSort('created_at', sortOrder)}>
                                    {t('common:createdAt')}
                                    {(sortBy === 'created_at') ? (
                                        <>
                                            {sortOrder === Order.ASC ? (
                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                            ) : (
                                                <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                        </>
                                    )}
                                </th>
                                <th scope="col" className="">
                                    <span className="sr-only">Edit Access</span>
                                </th>
                                <th scope="col" className="">
                                    <span className="sr-only">Edit Log</span>
                                </th>
                                <th scope="col" className="">
                                    <span className="sr-only">Delete</span>
                                </th>
                            </tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {logTemplates?.data.map((template, idx) => {
                                return (
                                    <tr
                                        key={idx}
                                        className={`odd:hover:bg-primary-blue-10 even:hover:bg-gray-50 cursor-pointer text-xs text-black even:bg-white odd:bg-primary-blue-5`}
                                    // onClick={() => handleSelectedProperty(log)}
                                    >
                                        <td
                                            className="px-1 py-2 sm:px-6 capitalize text-left"
                                            onClick={() => setSelectedLogTemplate(template)}>
                                            {template.category === LogCategories.Other && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>home_repair_service</span>)}
                                            {template.category === LogCategories.Fire && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>local_fire_department</span>)}
                                            {template.category === LogCategories.Security && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>encrypted</span>)}
                                            {template.category === LogCategories.Camera && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>photo_camera</span>)}
                                            {template.category === LogCategories.Access && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>vpn_key</span>)}
                                            {template.category === LogCategories.Maintenance && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>construction</span>)}
                                            {template.category === LogCategories.Cleaning && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>mop</span>)}
                                            {template.category === LogCategories.Rounding && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>shield_person</span>)}
                                            {template.category === LogCategories.Elevator && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>elevator</span>)}
                                            {template.category === LogCategories.Ventilation && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[template.category]}`)}>mode_fan</span>)}
                                        </td>
                                        <td
                                            scope="row"
                                            className="py-2 pr-2 pl-1 sm:pl-2 text-xs first-letter:capitalize font-semibold sm:font-normal max-w-[9rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                            onClick={() => setSelectedLogTemplate(template)}>
                                            {
                                                template.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${template.name.split('_').join('')}`) :
                                                    template.name.split('_').join(' ')
                                            }
                                        </td>
                                        {/* <td
                                            className="py-2 pr-2 capitalize max-w-[6rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis hidden sm:table-cell"
                                            onClick={() => setSelectedLogTemplate(template)}>
                                            {t(`common:enums.logAccess.${LogAccess[template.access]}`)}
                                        </td> */}
                                        <td
                                            className="py-2 pr-2 capitalize max-w-[6rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis hidden sm:table-cell"
                                            onClick={() => setSelectedLogTemplate(template)}>
                                            {t(`common:enums.logTypes.${LogTypes[template.log_type]}`)}
                                        </td>
                                        <td
                                            className="py-2 max-w-[7rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                            onClick={() => setSelectedLogTemplate(template)}>
                                            {dateToLocaleDate(template.created_at)}
                                        </td>

                                        <td className="text-right md:px-2 lg:px-1">
                                            <button
                                                onClick={() => handleEditLog(template)}
                                                className="align-middle text-black material-symbols-outlined"
                                                title={t('common:editAccess')}
                                                style={{ fontSize: '1.3rem ' }}
                                            >room_preferences</button>
                                        </td>
                                        <td className="text-center md:px-2 lg:px-1">
                                            <button
                                                onClick={() => setShowEditLogTemplate(template)}
                                                disabled={template.log_type === LogTypes.Default || currentUser.tenant_id !== template.tenant_id}
                                                className="align-middle text-black material-symbols-outlined pb-0.5 disabled:text-gray-300"
                                                style={{ fontSize: '1.2rem' }}
                                                title={t('common:editTemplate')}
                                            >edit_square</button>
                                        </td>
                                        <td className="text-left md:px-2 lg:px-1">
                                            <button
                                                disabled={true}
                                                className="align-middle material-symbols-outlined text-status-critical disabled:text-gray-300"
                                                title={t('common:delete')}
                                                style={{ fontSize: '1.3rem' }}
                                            >delete</button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </Table.Tbody>
                    </Table>
                </div>

                {isFetching && (
                    <div className="mx-auto mb-5 mt-1">
                        <Loader show={isFetching} size='small' />
                    </div>
                )}

                {!logTemplates || logTemplates.data.length < 1 && (
                    <div className="my-5">
                        <p className="text-sm ml-5 text-black font-semibold">{t('page:properties.noLogsFound')}...</p>
                    </div>
                )}
                {logTemplates?.meta && logTemplates?.meta?.hasNextPage && (
                    <div className="flex justify-center py-2 border-t">
                        <Pagination
                            count={pageCount}
                            page={page}
                            defaultPage={1}
                            showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                            showLastButton={pageCount ? pageCount > 5 : false}
                            hidePrevButton={!hasPrevPage}
                            hideNextButton={!hasNextPage}
                            onChange={handlePagination}
                        />
                    </div>
                )}
            </div>

            {/* Mobile screen  */}
            <div className="block md:hidden w-full mx-auto mt-1">
                <div className="pt-1">
                    <Input
                        type="search"
                        name="search_name"
                        placeholder={t('common:search')}
                        disableUnderline={true}
                        className="border rounded-md px-2 pt-2.5 pb-1 mt-3 w-10/12 bg-white"
                        onChange={(e) => debouncedSearch(e.target.value)}
                        endAdornment={<span className="material-symbols-outlined text-gray-500 pb-1" style={{ fontSize: '1.3rem' }}>search</span>}
                        size="small"
                    />
                    <Menu as="div" className="relative inline-block text-left cursor-pointer">
                        <div>
                            <Menu.Button
                                className="material-symbols-outlined text-black align-sub ml-3 rotate-90 border rounded-md px-1 py-1"
                                style={{ fontSize: '1.4rem' }}
                                onClick={() => setShowSortMenu(!showSortMenu)}>
                                sync_alt
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items
                                className="absolute right-0 z-10 mt-2 bg-white w-auto origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="">
                                    <Menu.Item>
                                        <div className="flex hover:bg-primary-blue-5"
                                            onClick={(e) => handleSort('name', sortOrder)}>
                                            <span className="w-1/2 block px-2 py-2 text-sm">{t('common:name')}</span>
                                            <div className="w-1/2 py-2 pr-1">
                                                {(sortBy === 'name') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <div className="flex hover:bg-primary-blue-5" onClick={() => handleSort('category', sortOrder)}>
                                            <span className="w-1/2 block px-2 py-2 text-sm">{t('common:category')}</span>
                                            <div className="w-1/2 py-2 pr-1">
                                                {(sortBy === 'category') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <div className="flex hover:bg-primary-blue-5" onClick={() => handleSort('access', sortOrder)}>
                                            <span className="w-1/2 block px-2 py-2 text-sm">{t('common:access')}</span>
                                            <div className="w-1/2 py-2 pr-1">
                                                {(sortBy === 'access') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <div className="flex hover:bg-primary-blue-5" onClick={() => handleSort('log_type', sortOrder)}>
                                            <span className="w-1/2 block px-2 py-2 text-sm">{t('common:type')}</span>
                                            <div className="w-1/2 py-2 pr-1">
                                                {(sortBy === 'log_type') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <div className="flex hover:bg-primary-blue-5" onClick={() => handleSort('created_at', sortOrder)}>
                                            <span className="w-1/2 block px-2 py-2 text-sm">{t('common:createdAt')}</span>
                                            <div className="w-1/2 py-2 pr-1">
                                                {(sortBy === 'created_at') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    <div className="mb-2">
                        <AllLogCategories logCategories={logCategories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
                    </div>

                    {setShowAllTemplates && (
                        <div
                            onClick={() => setShowAllTemplates(!showAllTemplates)}
                            className="inline-block relative my-2 mb-4 cursor-pointer">
                            <Checkbox
                                label={t('page:logs.showOnlyMyLogs')}
                                size={'md'}
                                checked={!showAllTemplates}
                            />
                        </div>
                    )}
                </div>
                {logTemplates?.data && logTemplates.data.length > 0 && !isFetching && (
                    <div>
                        {logTemplates?.data?.map((logTemplate, idx) => (
                            <div key={idx} className="my-2 p-2 text-xs relative odd:bg-primary-blue-5 odd:hover:bg-primary-blue-10 even:border border-primary-blue-5 hover:even:bg-gray-50 rounded-md">
                                <div className="flex flex-col flex-wrap gap-x-5 my-1">
                                    <div>
                                        <button
                                            onClick={() => handleEditLog(logTemplate)}
                                            className="text-black material-symbols-outlined absolute right-16 pr-3 top-2"
                                            title={t('common:editAccess')}
                                            style={{ fontSize: '1.3rem ' }}
                                        >room_preferences</button>
                                        <button
                                            onClick={() => setShowEditLogTemplate(logTemplate)}
                                            className="text-black material-symbols-outlined absolute right-10 top-1.5"
                                            style={{ fontSize: '1.2rem' }}
                                        >edit_square</button>
                                        <button
                                            disabled={true}
                                            className="material-symbols-outlined text-status-critical disabled:text-gray-300 absolute right-2 top-2"
                                            style={{ fontSize: '1.3rem' }}
                                        >delete</button>
                                    </div>

                                    <div className="flex items-center">
                                        <div className="sm:ml-5">
                                            <div className="flex items-center rounded-full bg-white overflow-hidden">
                                                {logTemplate.category === LogCategories.Other && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[logTemplate.category]}`)}>home_repair_service</span>)}
                                                {logTemplate.category === LogCategories.Fire && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[logTemplate.category]}`)}>local_fire_department</span>)}
                                                {logTemplate.category === LogCategories.Security && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[logTemplate.category]}`)}>encrypted</span>)}
                                                {logTemplate.category === LogCategories.Camera && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[logTemplate.category]}`)}>photo_camera</span>)}
                                                {logTemplate.category === LogCategories.Access && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[logTemplate.category]}`)}>vpn_key</span>)}
                                                {logTemplate.category === LogCategories.Maintenance && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[logTemplate.category]}`)}>construction</span>)}
                                                {logTemplate.category === LogCategories.Cleaning && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[logTemplate.category]}`)}>mop</span>)}
                                                {logTemplate.category === LogCategories.Rounding && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[logTemplate.category]}`)}>shield_person</span>)}
                                                {logTemplate.category === LogCategories.Elevator && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[logTemplate.category]}`)}>elevator</span>)}
                                                {logTemplate.category === LogCategories.Ventilation && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[logTemplate.category]}`)}>mode_fan</span>)}
                                            </div>
                                        </div>

                                        <div className="ml-3 sm:ml-10">
                                            <p className="font-semibold text-sm capitalize">
                                                {logTemplate.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${logTemplate.name.split('_').join('')}`) :
                                                    logTemplate.name.split('_').join(' ')}
                                            </p>
                                            <p>{t(`common:enums.logAccess.${LogAccess[logTemplate.access]}`)}</p>
                                            <p>{t(`common:enums.logTypes.${LogTypes[logTemplate.log_type]}`)}</p>
                                        </div>

                                        <p className="absolute right-2 bottom-2">{dateToLocaleDate(logTemplate.created_at)}</p>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                )}
                {isFetching && (
                    <div className="mx-auto mb-5 mt-1">
                        <Loader show={isFetching} size='small' />
                    </div>
                )}

                {!logTemplates || logTemplates.data.length < 1 && (
                    <div className="my-3 pt-3 border-t">
                        <p className="text-sm ml-5 text-black font-semibold">{t('page:properties.noLogsFound')}...</p>
                    </div>
                )}
                {logTemplates && logTemplates.meta && (
                    <div className="flex justify-center py-2">
                        <Pagination
                            count={pageCount}
                            page={page}
                            defaultPage={1}
                            showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                            showLastButton={pageCount ? pageCount > 5 : false}
                            hidePrevButton={!hasPrevPage}
                            hideNextButton={!hasNextPage}
                            onChange={handlePagination}
                        />
                    </div>
                )}
            </div>
            <Modal isOpen={showModal} close={() => setShowModal(false)} title={t('page:logs.editLogAccess')} size='sm' titleAlign='center'>
                <div className="w-10/12 flex flex-wrap justify-center mx-auto">
                    <h5 className="font-semibold mb-3 capitalize">
                        ({selectedLogTemplate?.log_type === LogTypes.Default ?
                            t(`page:logs.defaultLogs.${selectedLogTemplate.name.split('_').join('')}`) :
                            selectedLogTemplate?.name.split('_').join(' ')})
                    </h5>

                    <LogPropertyAccess logTemplate={selectedLogTemplate} />

                    <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                    <LogAccessPropertyBuilding logTemplate={selectedLogTemplate} />

                    <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                    <PropertyObjectLogAccess logTemplate={selectedLogTemplate} />

                    <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                    <RevokeLogUserAccess logTemplate={selectedLogTemplate} />

                </div>
            </Modal>
        </div>
    )
}

export default AllLogTemplatesTable