import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Order } from '../../../../../services/enum/order';
import { PageDto } from '../../../../../services/dto/page.dto';
import { SubsidiaryDto } from '../../../../../services/tenant/subsidiary/dto/subsidiary.dto';
import Select, { MultiValue } from 'react-select';
import { SelectCustomStyles } from '../../../../atoms/select-custom-styles/select-custom-styles';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetSubsidiariesQuery, useGetSubsidiaryQuery } from '../../../../../services/tenant/subsidiary/subsidiary.service';
import InputCheckbox from '../../../../atoms/select-checkbox';
import { useGetMyFriendshipTenantsQuery } from '../../../../../services/catalog/friendship/friendship.service';
import { FriendshipTenantDto } from '../../../../../services/catalog/friendship/dto/friendship-tenant.dto';
import { debounce } from '../../../../../utils/debounce';

type SelectOption = {
    label: string;
    value: string;
    data: FriendshipTenantDto;
    selected?: boolean
}

type Props = {
    handleSelectedCompanies: (value: SelectOption[]) => void;
    preSelectedCompany?: FriendshipTenantDto;
    zIndex?: string;
    reset?: boolean;
}

const ExternalCompaniesPropertyOwnerSelectMulti = ({
    handleSelectedCompanies,
    preSelectedCompany,
    zIndex,
    reset
}: Props) => {
    const { t } = useTranslation();

    const [selectedCompany, setSelectedCompany] = useState<FriendshipTenantDto>();

    const [allOptions, setAllOptions] = useState<SelectOption[]>([]);
    const [search, setSearch] = useState<string>();
    const [page, setPage] = useState<number>(1);

    const debouncedSearch = useRef(debounce((search: string) => setSearch(search), 500)).current;

    // Get external companies
    const { data: companies } = useGetMyFriendshipTenantsQuery({
        take: 15,
        page: page,
        order: Order.ASC,
        sortBy: 'company_name',
        search: search
    });

    // Used to reset select component
    const [selectKey, setSelectKey] = useState<number>(0);

    useEffect(() => {
        if (reset) {
            handleSelectedCompanies([])
            setSelectKey(prevKey => prevKey + 1); // Reset select component
        }

    }, [reset])

    useEffect(() => {
        if (companies) {
            // INIT
            if (allOptions.length === 0 || selectedCompany) {
                initOptions(companies.data);
            }
            // SEARCH
            else if (search) {
                handleSearchOptions(search, companies.data);
            }
            // PAGINATION
            else {
                handlePagination(companies.data);
            }
        }
    }, [companies, selectedCompany])

    useEffect(() => {
        if (preSelectedCompany) {
            setSelectedCompany(preSelectedCompany)
        }
    }, [preSelectedCompany])

    useEffect(() => {
        if (selectedCompany && allOptions) {
            handleSelectedCompanies(allOptions.filter(e => e.selected))
        }
    }, [selectedCompany, allOptions])
    
    // Set initial options and selected options
    const initOptions = (companies: FriendshipTenantDto[]) => {
        const options: SelectOption[] = [];
        if (companies && allOptions.length === 0 || selectedCompany) {
            companies.forEach(company => {
                let tempObj: SelectOption;
                if (company.tenant_id === selectedCompany?.tenant_id) {
                    tempObj = {
                        label: company.company_name,
                        value: company.tenant_id,
                        data: company,
                        selected: true,
                    };
                }
                else {
                    tempObj = {
                        label: company.company_name,
                        value: company.tenant_id,
                        data: company,
                        selected: allOptions?.find(e => e.value === company.tenant_id)?.selected
                    };
                }
                options.push(tempObj);
            })
            setAllOptions(options);
        }
    }

    // Handle options when search query is provided
    const handleSearchOptions = (search: string, companies: FriendshipTenantDto[]) => {
        if (companies && search) {
            setPage(1);
            const options: SelectOption[] = [];
            companies.forEach(company => {
                let tempObj: SelectOption;
                if (company.tenant_id === selectedCompany?.tenant_id) {
                    tempObj = {
                        label: company.company_name,
                        value: company.tenant_id,
                        data: company,
                        selected: true
                    }
                }
                else {
                    tempObj = {
                        label: company.company_name,
                        value: company.tenant_id,
                        data: company
                    }
                }
                options.push(tempObj)
            })
            setAllOptions([...options]);
        }
    }

    // Handling options with pagination
    const handlePagination = (companies: FriendshipTenantDto[]) => {
        if (companies) {
            // Get existing options
            const existingOptions = [...allOptions];

            // Remove duplicates
            const uniqueArr = existingOptions.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.value === value.value
                ))
            )

            // Add options
            companies.forEach(company => {
                let tempObj: SelectOption;
                if (company.tenant_id === selectedCompany?.tenant_id) {
                    tempObj = {
                        label: company.company_name,
                        value: company.tenant_id,
                        data: company,
                        selected: true
                    }
                }
                else {
                    tempObj = {
                        label: company.company_name,
                        value: company.tenant_id,
                        data: company
                    }
                }
                // Make sure no duplicates are added
                if (!uniqueArr.some(e => e.value === tempObj.value)) {
                    uniqueArr.push(tempObj);
                }
            });
            setAllOptions(uniqueArr);
        }
    }

    // Handle scroll event to fetch more options
    const handleScroll = (event: any) => {
        if (companies && companies.meta.hasNextPage) {
            if (!search) {
                setPage(page ? page + 1 : 1);
            }
        }
    }
    
    const handleChange = (selectOptions: MultiValue<SelectOption>) => {
        // Make copy since passed array is readonly
        const selectOptionsArr: SelectOption[] = [...selectOptions];
        // Set selected prop
        selectOptionsArr.forEach(option => {
            option.selected = true;
        })
        handleSelectedCompanies(selectOptionsArr)

        // Set selected = false if option not found in selectOptions
        const allOptionsArr = [...allOptions];
        allOptionsArr.forEach(opt => {
            const index = selectOptions.findIndex(e => e.value === opt.value);
            if (index === -1) {
                opt.selected = false;
            }
        })

        // preSelectedCompany has been unselected
        if (!selectOptions.some(e => e.value === selectedCompany?.tenant_id)) {
            const index = allOptionsArr.findIndex(e => e.value === selectedCompany?.tenant_id);

            if (index !== -1) {
                allOptionsArr[index].selected = false;
                setSelectedCompany(undefined);
            }
        }
        setAllOptions(allOptionsArr);
    }

    return (
        <Select
            key={selectKey}
            className={`${zIndex ? `z-${zIndex}` : ''} capitalize md:text-sm`}
            options={allOptions}
            hideSelectedOptions={false}
            onChange={(val) => handleChange(val)}
            maxMenuHeight={200}
            placeholder={t('page:friendship.selectCompanies')}
            noOptionsMessage={() => t('page:friendship.noCompaniesFound')}
            onInputChange={(value) => debouncedSearch(value)}
            captureMenuScroll={true}
            onMenuScrollToBottom={(val) => handleScroll(val)}
            filterOption={null}
            styles={SelectCustomStyles}
            isMulti={true}
            closeMenuOnSelect={false}
            value={allOptions.filter(e => e.selected)}
            backspaceRemovesValue={false}
            components={{
                // @ts-ignore
                Option: InputCheckbox
            }}
        />
    )
};

export default ExternalCompaniesPropertyOwnerSelectMulti;