import React, { useState } from 'react'
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useChangePasswordMutation } from '../../../../services/tenant/user/user.service';
import { NotificationManager } from 'react-notifications';
import { UserStateType } from '../../../../services/dto/user-state-type.dto';
import PrimaryButton from '../../../atoms/primary-button';

export type ChangePasswordFormType = {
    tenant_id: string;
    id: string;
    current_password: string;
    new_password: string;
    confirm_password: string;
}

type Props = {
    user?: UserStateType
}

const ChangePasswordForm = ({ user }: Props) => {
    const { t } = useTranslation();

    const [changePassword, response] = useChangePasswordMutation();

    const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const { control, handleSubmit, formState: { errors }, getValues } = useForm<ChangePasswordFormType>({
        defaultValues: {
            id: user?.id,
            current_password: '',
            new_password: '',
            confirm_password: '',
        }
    });

    const onSubmit = async (formData: ChangePasswordFormType) => {

        if (user?.id) {
            try {
                const response = await changePassword(formData).unwrap();
                
                if (response && response.success) {
                    NotificationManager.success(t('common:notifications.passwordUpdated'));
                    setError('');
                }
                else {
                    setError(t(`common:server.${response.data}`))
                }
            } catch (error) {
                NotificationManager.error(t('common:notifications.updateFailed'))
            }
        }
    }

    const validateConfirmPassword = () => {
        const confirmPassword = getValues().confirm_password;
        const newPassword = getValues().new_password;

        if (!confirmPassword)
            return true;

        if (confirmPassword.length > 7 && confirmPassword !== newPassword)
            return t('page:users.form.passwordNotSame')
    }

    return (
        <>
            <div className="mx-auto">
                <h4 className="text-black text-lg font-semibold text-center mb-2">{t('common:changePassword')}</h4>
                <form className="space-y-2" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label htmlFor='current_password' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('page:users.form.currentPassword')}<span className="text-status-critical">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="current_password"
                                control={control}
                                rules={{
                                    required: t('common:form.fieldNotEmpty'),
                                }}
                                render={({ field }) => (
                                    <div className="">
                                        <input
                                            {...field}
                                            type={showCurrentPassword ? 'text' : 'password'}
                                            maxLength={64}
                                            placeholder={t('page:users.form.currentPassword')}
                                            className={`${errors.current_password && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        <span
                                            onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                            className="material-symbols-outlined text-black cursor-pointer hover:text-gray-600 float-right mr-2 mt-[-33px]">
                                            {showCurrentPassword ? 'visibility' : 'visibility_off'}
                                        </span>

                                        {errors.current_password && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.current_password.message}</p>}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='new_password' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('page:users.form.newPassword')}<span className="text-status-critical">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="new_password"
                                control={control}
                                rules={{
                                    required: t('common:form.fieldNotEmpty'),
                                    minLength: {
                                        value: 8,
                                        message: t('page:users.form.passwordMinLength8')
                                    },
                                    // validate: validateNewPassword
                                }}
                                render={({ field }) => (
                                    <div className="">
                                        <input
                                            {...field}
                                            type={showNewPassword ? 'text' : 'password'}
                                            maxLength={32}
                                            placeholder={t('page:users.form.newPassword')}
                                            className={`${errors.new_password && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        <span
                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                            className="material-symbols-outlined text-black cursor-pointer hover:text-gray-600 float-right mr-2 mt-[-33px]">
                                            {showNewPassword ? 'visibility' : 'visibility_off'}
                                        </span>

                                        {errors.new_password && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.new_password.message}</p>}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='confirm_password' className="block text-xs sm:text-sm font-semibold leading-6 text-gray-600">
                            {t('page:users.form.confirmPassword')}<span className="text-status-critical">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="confirm_password"
                                control={control}
                                rules={{
                                    required: t('common:form.fieldNotEmpty'),
                                    minLength: {
                                        value: 8,
                                        message: t('page:users.form.passwordMinLength8')
                                    },
                                    validate: validateConfirmPassword
                                }}
                                render={({ field }) => (
                                    <div className="">
                                        <input
                                            {...field}
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            maxLength={32}
                                            placeholder={t('page:users.form.confirmPassword')}
                                            className={`${errors.confirm_password && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-sm`}
                                        />
                                        <span
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            className="material-symbols-outlined text-black cursor-pointer hover:text-gray-600 float-right mr-2 mt-[-33px]">
                                            {showConfirmPassword ? 'visibility' : 'visibility_off'}
                                        </span>

                                        {errors.confirm_password && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.confirm_password.message}</p>}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    {error && (<p className="font-bold text-center text-sm text-status-critical">{error}</p>)}
                    <div>
                        <PrimaryButton
                            text={t('common:update')}
                            size={'medium'}
                            align={'center'}
                            wide={true}
                        />
                    </div>
                </form>
            </div>

        </>
    )
}

export default ChangePasswordForm;