import { Grow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { TenantTypes } from '../../../../../services/enum/tenant-types';
import SubTabs from '../../../../molecules/sub-tabs';
import LogEntries from '../../../log-components/log-structure/log-entries';
import ExternalLogAccessContractor from '../external-log-access-contractor';
import ExternalLogsContractorUser from '../external-logs-contractor-user';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../redux/hooks';
import { getCurrentUser } from '../../../../../redux/slices/user.slice';
import { getTenant } from '../../../../../redux/slices/tenant.slice';
import { useLocation } from 'react-router-dom';
import { LogTemplateDto } from '../../../../../services/tenant/log-template/dto/log-template.dto';
import { FriendshipTenantDto } from '../../../../../services/catalog/friendship/dto/friendship-tenant.dto';
import { Roles } from '../../../../../services/enum/roles';
import { ContractorLogAccessDto } from '../../../../../services/tenant/contractor-log-user-access/dto/contractor-log-access.dto';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetLogTemplateQuery } from '../../../../../services/tenant/log-template/log-template.service';
import { LogTypes } from '../../../../../services/enum/log-types';
import { EntityLogDto } from '../../../../../services/tenant/entity-log/dto/entity-log.dto';
import EntityLogTable from '../../../log-components/log-structure/entity-log-table';

type Props = {
    selectedTab: string;
    setSelectedTab: (val: string) => void;
    showTabs: boolean;
    setShowTabs: (flag: boolean) => void;
}

export type DisplayNameType = {
    logName: string;
    pboName: string;
}

const LogsContractor = ({
    selectedTab,
    setSelectedTab,
    showTabs,
    setShowTabs
}: Props) => {
    const { t } = useTranslation()
    const { currentUser } = useAppSelector(getCurrentUser);
    const { tenant } = useAppSelector(getTenant);
    const location = useLocation();

    const [selectedSubTab, setSelectedSubTab] = useState<string>('internalLogs');

    // INTERNAL LOGS

    const [skipTokenLogTemplateId, setSkipTokenLogTemplateId] = useState<any>(skipToken);
    const { data: logTemplate } = useGetLogTemplateQuery(skipTokenLogTemplateId);

    // State for selected log template id to be fetched
    const [selectedLogTemplateId, setSelectedLogTemplateId] = useState<string>();

    // State for fetched log template
    const [selectedLogTemplate, setSelectedLogTemplate] = useState<LogTemplateDto>();

    // Select Entity log
    const [selectedEntityLog, setSelectedEntityLog] = useState<EntityLogDto | null>();

    // EXTERNAL LOGS
    const [selectedTenantLog, setSelectedTenantLog] = useState<ContractorLogAccessDto | null>();

    // Company passed as prop
    const [selectedCompany, setSelectedCompany] = useState<FriendshipTenantDto>();

    // Display name based on property / building / object name and log name
    const [displayName, setDisplayName] = useState<DisplayNameType>({ logName: '', pboName: '' });

    // // If external company is passed as prop
    // useEffect(() => {
    //     if (location.state) {
    //         const { company } = location.state;
    //         if (company) {
    //             setSelectedCompany(company);
    //             setSelectedTab('logs');
    //             setSelectedSubTab('externalLogs')
    //         }
    //     }
    // }, [])

    // Set logTemplateId when selected
    useEffect(() => {
        if (selectedLogTemplateId) {
            setSkipTokenLogTemplateId(selectedLogTemplateId);
        }
    }, [selectedLogTemplateId])

    // Set state for selectedLogTemplate
    useEffect(() => {
        if (logTemplate) {
            setSelectedLogTemplate(logTemplate);
        }
    }, [logTemplate])

    // Show/Hide tabs working with internal logs
    useEffect(() => {
        if (selectedEntityLog) setShowTabs(false);
        else setShowTabs(true)
    }, [selectedEntityLog])

    // Show hide tabs working with external logs
    useEffect(() => {
        if (selectedTenantLog) setShowTabs(false);
        else setShowTabs(true)
    }, [selectedTenantLog])

    useEffect(() => {
        if (selectedTenantLog) {
            let logName = '';
            let pboName = '';

            // Get correct property / building / object name
            if (selectedTenantLog.property_object_name) {
                pboName = selectedTenantLog.property_object_name
            }
            else if (selectedTenantLog.property_building_label) {
                pboName = selectedTenantLog.property_building_label;
            }
            if (selectedTenantLog.property_label) {
                pboName = selectedTenantLog.property_label;
            }

            // Get correct log name
            logName = selectedTenantLog.log_type === LogTypes.Default ?
                t(`page:logs.defaultLogs.${selectedTenantLog.log_template_name.split('_').join('')}`) :
                selectedTenantLog.log_template_name.split('_').join(' ')

            setDisplayName({
                logName,
                pboName
            })
        }
    }, [selectedTenantLog])

    return (
        <div className="w-full p-1 mx-auto mb-10">
            <Grow in={selectedTab ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                <div>
                    {tenant.type === TenantTypes.Contractor && selectedTab === 'logs' && (
                        <>
                            {(!selectedEntityLog && !selectedTenantLog) && (
                                <SubTabs
                                    selectableTabs={['internalLogs', 'externalLogs']}
                                    setSelectedSubTab={setSelectedSubTab}
                                    selectedSubTab={selectedSubTab}
                                />
                            )}

                            {selectedSubTab === 'internalLogs' && (
                                <>
                                    {!selectedEntityLog && (
                                        <EntityLogTable
                                            setSelectedEntityLog={setSelectedEntityLog}
                                            selectedEntityLog={selectedEntityLog}
                                            setSelectedLogTemplateId={setSelectedLogTemplateId}
                                        />
                                    )}

                                    {(selectedLogTemplate && selectedEntityLog) && (
                                        <LogEntries
                                            setSelectedEntityLog={setSelectedEntityLog}
                                            selectedEntityLog={selectedEntityLog}
                                            selectedLogTemplate={selectedLogTemplate}
                                        />
                                    )}

                                </>
                            )}
                            {selectedSubTab === 'externalLogs' && (
                                <>
                                    {currentUser && (currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                                        <ExternalLogAccessContractor
                                            preSelectedCompany={selectedCompany}
                                            selectedTenantLog={selectedTenantLog}
                                            setSelectedTenantLog={setSelectedTenantLog}
                                            displayName={displayName}
                                        />
                                    )}
                                    {currentUser && currentUser.role === Roles.User && (
                                        <ExternalLogsContractorUser
                                            selectedTenantLog={selectedTenantLog}
                                            setSelectedTenantLog={setSelectedTenantLog}
                                            displayName={displayName}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </Grow>
        </div>
    )
}

export default LogsContractor