import Compressor from 'compressorjs';
import { ImageCompressSettings } from '../constants/image-compress-settings';
import getImageDimensions from './get-image-dimensions';

export const compressImage = async (
    file: File,
    type: string,
    quality: number = ImageCompressSettings.quality,
    resize: 'cover' | 'contain' | 'none' = 'cover',
    mimeType: string = ImageCompressSettings.mimeType,
): Promise<{ success: boolean, data: File | Blob }> => {

    let options: { width?: number, height?: number } = {};
    // Get image size to determine aspect ratio
    const imgSize = await getImageDimensions(file);
    // Calculate aspect ratio
    const aspectRatio = imgSize.width / imgSize.height;

    if (aspectRatio > 1) {
        // Image is wider than it's tall
        if (type === 'default')
            options = { width: ImageCompressSettings.defaultW };
        else if (type === 'avatar')
            options = { width: ImageCompressSettings.avatarW };
    }
    else {
        // Image is taller than it's wide
        if (type === 'default')
            options = { height: ImageCompressSettings.defaultH };
        else if (type === 'avatar')
            options = { height: ImageCompressSettings.avatarH };
    }


    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: quality,
            width: options?.width,
            height: options?.height,
            resize: resize,
            mimeType: mimeType,
            success(result) {
                resolve({ success: true, data: result });
            },
            error(err) {
                reject({ success: false, data: 'something_went_wrong' });
            }
        })
    })
}