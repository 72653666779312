import { useEffect, useState } from "react";
import { useResendEmailVerificationMutation } from "../../../../services/management/re-auth/management.service";
import { NotificationManager } from 'react-notifications'
import { useTranslation } from "react-i18next";
import Loader from "../../../atoms/loader/loader";

type Props = {
    email: string;
    name: string;
    tenant_id?: string;
    user_id?: string;
    type: string;
}

const ConfirmEmail = ({ email, name, tenant_id, user_id, type }: Props) => {
    const { t } = useTranslation();

    const [resendEmail, response] = useResendEmailVerificationMutation();

    const [newEmailSent, setNewEmailSent] = useState<boolean>(false);

    const handleResendEmail = async () => {

        try {
            const response = await resendEmail({ email, name, tenant_id, user_id }).unwrap();
            // const response = await resendEmail({ email: tenant.email, tenant_id: tenant.id }).unwrap();

            if (response.success) {
                NotificationManager.success(t('common:notifications.confirmationEmailSent'))
                setNewEmailSent(true);
            }

        }
        catch (error) {
            NotificationManager.error(t('common:notifications.confirmEmailNotSent'))
        }
    }

    return (
        <div className="mt-[10vh] w-11/12 lg:w-8/12 xl:w-6/12 mx-auto bg-primary-blue-5 rounded-lg shadow-md p-6 text-center min-h-[35vh] flex flex-col justify-center">
            <span className="material-symbols-outlined text-black" style={{ fontSize: '2.2rem' }} > mail</span >
            <h3 className="text-2xl text-primary-blue font-extrabold">{t('common:emails.verify.verifyEmail')}</h3>
            <p className="text-black mt-5">
                {t('common:emails.verify.emailSentTo')} {email}.
            </p>
            <p>
            {t('common:emails.verify.pleaseVerify')}
            </p>
            <p className="text-sm text-black font-bold my-5">{t('common:emails.verify.cantFindEmail')}</p>

            {response.isLoading ? (
                <Loader show={response.isLoading} size='small' />
            ) : (
                <button
                    onClick={handleResendEmail}
                    disabled={newEmailSent}
                    className="font-bold text-secondary-blue bg-primary-blue hover:bg-primary-blue-h rounded py-2 px-4 mx-auto disabled:bg-gray-400 disabled:text-gray-300"
                >{t('common:emails.verify.sendNewCode')}
                </button>
            )}
            {
                newEmailSent && (
                    <>
                        <p className="text-primary-blue font-bold my-5">{t('common:emails.verify.emailResent')}</p>
                    </>
                )
            }
        </div >
    )

}

export default ConfirmEmail