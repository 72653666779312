import React, { useEffect, useRef, useState } from 'react'
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { LogTemplateDto } from '../../../../services/tenant/log-template/dto/log-template.dto';
import InputCheckbox from '../../../atoms/select-checkbox'
import { PropertyBuildingDto } from '../../../../services/tenant/property-building/dto/property-building.dto';
import { useGetLogTemplatesQuery } from '../../../../services/tenant/log-template/log-template.service';
import { useTranslation } from 'react-i18next';
import { Order } from '../../../../services/enum/order';
import { debounce } from '../../../../utils/debounce';
import { PageDto } from '../../../../services/dto/page.dto';
import { LogTypes } from '../../../../services/enum/log-types';
import { SelectCustomStyles } from '../../../atoms/select-custom-styles/select-custom-styles';
import SecondaryButton from '../../../atoms/secondary-button';
import { useGetAllPropertyBuildingLogAccessByIdQuery, useUpdateManyPropertyBuildingLogAccessMutation } from '../../../../services/tenant/entity-log/entity-log.service';
import { UpdatePropertyBuildingLogAccessDto } from '../../../../services/tenant/entity-log/dto/building-log-access/update-property-building-log-access.dto';


type Props = {
    propertyBuilding?: PropertyBuildingDto;
}

type SelectOption = {
    label: string;
    value: string;
    existing_access: boolean; // Access when the records is fetched
    entity_log_id?: string;
    log_template_id: string;
}

const PropertyBuildingLogAccess = ({ propertyBuilding }: Props) => {
    const { t } = useTranslation();

    const [allOptions, setAllOptions] = useState<SelectOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
    const [search, setSearch] = useState<string>();
    const [page, setPage] = useState<number>(1);

    const debouncedSearch = useRef(debounce((search: string) => setSearch(search), 500)).current;

    const { data: logTemplates } = useGetLogTemplatesQuery({
        take: 15,
        page: page,
        order: Order.ASC,
        sortBy: 'name',
        search: search,
    });

    const [updatePropertyLogAccess, response] = useUpdateManyPropertyBuildingLogAccessMutation();

    const [skipTokenPropertyBuildingLogAccess, setSkipTokenPropertyBuildingLogAccess] = useState<any>(skipToken);
    // Get all PropertyBuildingLogAccess relations by propertyBuildingId
    const { data: propertyBuildingLogAccess } = useGetAllPropertyBuildingLogAccessByIdQuery(skipTokenPropertyBuildingLogAccess);

    useEffect(() => {
        if (propertyBuilding) {
            setSkipTokenPropertyBuildingLogAccess(propertyBuilding.id);
        }
    }, [propertyBuilding])

    useEffect(() => {
        if (logTemplates && propertyBuildingLogAccess) {
            // INIT
            if (allOptions.length === 0) {
                initOptions(logTemplates);
            }
            // SEARCH
            else if (search) {
                handleSearchOptions(search, logTemplates);
            }
            // PAGINATION
            else {
                handlePagination(logTemplates);
            }
        }
    }, [logTemplates, propertyBuildingLogAccess])

    // Set initial options and selected options
    const initOptions = (logTemplates: PageDto<LogTemplateDto>) => {
        if (propertyBuildingLogAccess && allOptions.length === 0) {
            const options = logTemplates.data.map(lst => ({
                value: lst.id,
                label: lst.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${lst.name.split('_').join('')}`) : lst.name.split('_').join(' '),
                existing_access: propertyBuildingLogAccess.find(e => e.log_template_id === lst.id) ? true : false,
                entity_log_id: propertyBuildingLogAccess.find(e => e.log_template_id === lst.id)?.id,
                log_template_id: lst.id
            }))
            setAllOptions(options);
        }

        if (propertyBuildingLogAccess) {
            const selectOptions: SelectOption[] = propertyBuildingLogAccess.map(pbla => ({
                value: pbla.log_template_id,
                label: (() => {
                    const match = logTemplates.data.find(e => e.id === pbla.log_template_id);
                    return (match && match.log_type === LogTypes.Default) ? t(`page:logs.defaultLogs.${match.name.split('_').join('')}`) : pbla.log_name.split('_').join(' ')
                })(),
                existing_access: true,
                entity_log_id: pbla.id,
                log_template_id: pbla.log_template_id
            }))
            setSelectedOptions(selectOptions);
        }
    }

    // Handle options when search query is provided
    const handleSearchOptions = (search: string, logTemplates: PageDto<LogTemplateDto>) => {
        if (propertyBuildingLogAccess && search) {
            const allOptions: SelectOption[] = [];
            const selectedOptions: SelectOption[] = [];
            setPage(1);
            logTemplates.data.forEach(lst => {
                let tempObj: SelectOption = {
                    value: lst.id,
                    label: lst.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${lst.name.split('_').join('')}`) : lst.name.split('_').join(' '),
                    existing_access: propertyBuildingLogAccess.find(e => e.log_template_id === lst.id) ? true : false,
                    entity_log_id: propertyBuildingLogAccess.find(e => e.log_template_id === lst.id)?.id,
                    log_template_id: lst.id
                };
                allOptions.push(tempObj);
                // When match between user and subsidiary id, push to selectedOptions
                if (tempObj.existing_access) {
                    selectedOptions.push(tempObj);
                }
            })
            setAllOptions([...allOptions]);
            setSelectedOptions([...selectedOptions]);
        }
    }

    // Handling options with pagination
    const handlePagination = (logTemplates: PageDto<LogTemplateDto>) => {
        if (propertyBuildingLogAccess) {
            // Get existing options
            const existingOptions = [...allOptions];
            // Get existing selected options
            const existingSelected = [...selectedOptions];

            // Remove duplicates
            const uniqueArr = existingOptions.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.log_template_id === value.log_template_id
                ))
            )

            // Add options
            logTemplates.data.forEach(lst => {
                const obj = {
                    value: lst.id,
                    label: lst.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${lst.name.split('_').join('')}`) : lst.name.split('_').join(' '),
                    existing_access: propertyBuildingLogAccess.find(e => e.log_template_id === lst.id) ? true : false,
                    entity_log_id: propertyBuildingLogAccess.find(e => e.log_template_id === lst.id)?.id,
                    log_template_id: lst.id
                }
                // Make sure no duplicates are added
                if (!uniqueArr.some(e => e.log_template_id === obj.log_template_id)) {
                    uniqueArr.push(obj)
                    if (obj.existing_access && !existingSelected.some(e => e.log_template_id === obj.log_template_id)) {
                        existingSelected.push(obj);
                    }
                }
            });
            setAllOptions(uniqueArr);
            setSelectedOptions(existingSelected);
        }
    }

    // Handle scroll event to fetch more options
    const handleScroll = (event: any) => {
        if (logTemplates && logTemplates.meta.hasNextPage) {
            if (!search) {
                setPage(page ? page + 1 : 1);
            }
        }
    }

    // Selected option(s) will get passed
    const handleSelectChange = (options: any) => {

        const allSelectedOptions = [...selectedOptions]; // All selected options

        if (options.length > 0) {
            // If options array is shorter than selectedOptions array, a user has been removed
            if (options.length < allSelectedOptions.length) {

                allSelectedOptions.forEach((option: SelectOption) => {
                    // If current option not in passed options arr, remove object from selectedOptions array
                    if (!options.includes(option)) {
                        const index = allSelectedOptions.findIndex((e) => e.value === option.value);
                        if (index !== -1) {
                            allSelectedOptions.splice(index, 1);
                        }
                    }
                })
                setSelectedOptions(allSelectedOptions); // Update selectedOptions state
            }
            // If options array is not shorter than selectedOptions array, add option to selectedOptions array
            else {
                options.forEach((option: SelectOption) => {
                    // Add option if not already exists in allSelectedOptions array
                    if (!allSelectedOptions.includes(option)) {
                        let tempObj: SelectOption = {
                            label: option.label,
                            value: option.value,
                            existing_access: option.existing_access,
                            entity_log_id: option.entity_log_id,
                            log_template_id: option.log_template_id
                        };
                        allSelectedOptions.push(tempObj);
                    }
                });
                setSelectedOptions(allSelectedOptions);
            }
        }
        else {
            setSelectedOptions([])
        }
    }

    const handleUpdatePropertyLogAccess = async () => {
        let recordsToBeUpdated: UpdatePropertyBuildingLogAccessDto[] = [];
        let existingRecords: SelectOption[] = [];

        // Loop through selectedOptions to get records to be created
        selectedOptions.forEach((record) => {
            // If the property existing_access is not true, it's a completely new record to be added
            if (record.existing_access === false) {
                let tempObj: UpdatePropertyBuildingLogAccessDto = {
                    access: true,
                    property_building_id: propertyBuilding!.id,
                    entity_log_id: record.entity_log_id,
                    log_template_id: record.log_template_id,
                }
                recordsToBeUpdated.push(tempObj);
            }
            else if (record.existing_access === true) {
                existingRecords.push(record);
            }
        })

        // Loop through all options and find records with property existing_access true
        // If the records does not exist in selectedOptions, it means it has been removed has should be deleted
        allOptions.forEach((option) => {
            if (option.existing_access === true) {
                if (!existingRecords.some((e) => e.entity_log_id === option.entity_log_id)) {
                    let tempObj: UpdatePropertyBuildingLogAccessDto = {
                        access: false,
                        property_building_id: propertyBuilding!.id,
                        log_template_id: option.log_template_id,
                        entity_log_id: option.entity_log_id,
                    }
                    recordsToBeUpdated.push(tempObj);
                }
            }
        });

        if (recordsToBeUpdated.length > 0) {
            try {
                const response = await updatePropertyLogAccess(recordsToBeUpdated).unwrap();

                if (response.success) {
                    NotificationManager.success(t('common:notifications.updated'));
                }
                else {
                    NotificationManager.info(t('common:notifications.updateFailed'));
                }

            }
            catch (error) {
                NotificationManager.error(t('common:notifications.updateFailed'))
            }
        }
    }


    return (
        <>
            <div className="rounded-lg w-full mx-auto mb-2">
                <p className="my-2 text-xs sm:text-sm font-semibold">{t('page:properties.logAccess')} <span className="font-extrabold uppercase">{propertyBuilding?.label}</span></p>
                <Select
                    className="z-50 capitalize mb-2"
                    value={selectedOptions}
                    options={allOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(options) => handleSelectChange(options)}
                    controlShouldRenderValue={true}
                    isClearable={false}
                    backspaceRemovesValue={false}
                    maxMenuHeight={200}
                    placeholder={t('common:select')}
                    noOptionsMessage={() => t('page:properties.noLogsFound')}
                    components={{
                        // @ts-ignore
                        Option: InputCheckbox
                    }}
                    onInputChange={(value) => debouncedSearch(value)}
                    captureMenuScroll={true}
                    onMenuScrollToBottom={(val) => handleScroll(val)}
                    filterOption={null}
                    styles={SelectCustomStyles}
                />
                <SecondaryButton
                    text={t('common:update')}
                    size={'medium'}
                    align={'center'}
                    wide={true}
                    onClick={handleUpdatePropertyLogAccess}
                />
            </div>
        </>
    )
}

export default PropertyBuildingLogAccess;