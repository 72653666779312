import { LogCategoriesDto } from "../../dto/log-categories.dto";
import { PageOptionsDto } from "../../dto/page-options.dto";
import { PageDto } from "../../dto/page.dto";
import { reAuthAPI } from "../../re-auth/re-auth";
import { CreateLogEntryCustomExternalDto } from "../log-entries/dto/create-log-entry-custom-external.dto";
import { LogEntryQueryParams } from "../log-entries/dto/log-entry-query-params.dto";
import { LogEntryDto } from "../log-entries/dto/log-entry.dto";
import { LogTemplateDto } from "../log-template/dto/log-template.dto";
import { ContractorLogAccessDto } from "./dto/contractor-log-access.dto";
import { ContractorLogUserAccessDto } from "./dto/contractor-log-user-access.dto";
import { CreateContractorLogUserAccessDto } from "./dto/create-contractor-log-user-access.dto";



export const ContractorLogUserAccessAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Create contractorLogUserAccess record
        createContractorLogUserAccess: builder.mutation<any, CreateContractorLogUserAccessDto>({
            query: (record) => ({
                url: '/contractor-log-user-access',
                method: 'POST',
                body: record,
            }),
            invalidatesTags: ['ContractorLogUserAccess']
        }),
        // Get logUserAccess records by ids
        getContractorLogUserAccessByIds: builder.query<ContractorLogUserAccessDto[], string[]>({
            query: (ids) => ({
                url: `/contractor-log-user-access/logs/users/ids?ids=${ids}`,
                method: 'GET'
            }),
            providesTags: ['ContractorLogUserAccess']
        }),
        // Get propertyOwnerIds by user access
        getPropertyOwnerIdsByUserId: builder.query<{ id: string }[], string>({
            query: (id) => ({
                url: `/contractor-log-user-access/property-owners/user/${id}`,
                method: 'GET'
            }),
            providesTags: ['ContractorLogUserAccess']
        }),
        // Get tenant logs by user id
        getTenantLogsByUserId: builder.query<PageDto<ContractorLogAccessDto>, { pageOptions: PageOptionsDto, userId: string, tenantId: string }>({
            query: (options) => {
                const queryParams = [];
                if (options.userId !== undefined) queryParams.push(`user_id=${options.userId}`);
                if (options.tenantId !== undefined) queryParams.push(`company_id=${options.tenantId}`);

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                const queryParamsPageOptions = [];
                if (options.pageOptions.page !== undefined) queryParamsPageOptions.push(`page=${options.pageOptions.page}`);
                if (options.pageOptions.take !== undefined) queryParamsPageOptions.push(`take=${options.pageOptions.take}`);
                if (options.pageOptions.order !== undefined) queryParamsPageOptions.push(`order=${options.pageOptions.order}`);
                if (options.pageOptions.search !== undefined) queryParamsPageOptions.push(`search=${options.pageOptions.search}`);
                if (options.pageOptions.sortBy !== undefined) queryParamsPageOptions.push(`sort_by=${options.pageOptions.sortBy}`);
                if (options.pageOptions.filter !== undefined) queryParamsPageOptions.push(`filter=${options.pageOptions.filter}`);

                const queryStringPageOptions = queryParamsPageOptions.length > 0 ? `&${queryParamsPageOptions.join('&')}` : '';

                return {
                    url: `/contractor-log-user-access/logs/user${queryString}${queryStringPageOptions}`,
                    method: 'GET'
                }
            },
            providesTags: ['ContractorLogUserAccess']
        }),
        // Get unique log categories by access
        getUniqueLogCategoriesByAccess: builder.query<LogCategoriesDto[], { userId: string, tenantId: string }>({
            query: (options) => ({
                url: `/contractor-log-user-access/logs/categories?company_id=${options.tenantId}&user_id=${options.userId}`,
                method: 'GET'
            }),
            providesTags: ['ContractorLogUserAccess']
        }),
        // Delete propertyOwnerContractorLogAccess by given id
        deleteContractorLogUserAccess: builder.mutation<any, string>({
            query: (id) => ({
                url: '/contractor-log-user-access/' + id,
                method: 'DELETE',
                body: id
            }),
            invalidatesTags: ['ContractorLogUserAccess']
        }),
    })
})

export const {
    useCreateContractorLogUserAccessMutation,
    useGetContractorLogUserAccessByIdsQuery,
    useGetPropertyOwnerIdsByUserIdQuery,
    useGetTenantLogsByUserIdQuery,
    useGetUniqueLogCategoriesByAccessQuery,
    useDeleteContractorLogUserAccessMutation,
} = ContractorLogUserAccessAPI;