
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetUserInvitesByTenantIdQuery } from "../../../../services/catalog/user-invite/user-invite.service";
import { Fragment, useEffect, useRef, useState } from "react";
import { dateToLocaleDate, dateToLocaleDateAndHour } from "../../../../utils/date-time";
import { useDeleteUserMutation, useUpdateUserMutation } from "../../../../services/tenant/user/user.service";
import { NotificationManager } from 'react-notifications';

import Modal from "../../../molecules/modal";
import UpdateUserForm from "../../forms/user/update-user-form";
import { getCurrentUser } from "../../../../redux/slices/user.slice";
import ConfirmModal from "../../../molecules/confirm-modal";
import { PropertyDto } from "../../../../services/tenant/property/dto/property.dto";
import { useDeletePropertyMutation, useGetAllPropertiesQuery } from "../../../../services/tenant/property/property.service";
import UpdatePropertyForm from "../../forms/property/update-property-form";
import PropertyUserAccess from "../property-user-access";
import PropertyBuildingAccess from "../property-building-access";
import PropertyObjectAccess from "../property-object-access";
import PropertyLogAccess from "../property-log-access";
import { useAppSelector } from "../../../../redux/hooks";
import { useTranslation } from "react-i18next";

import { Input } from "@mui/material";
import { debounce } from "../../../../utils/debounce";
import { PropertyTypes } from "../../../../services/enum/property-types";
import { Order } from "../../../../services/enum/order";
import Loader from "../../../atoms/loader/loader";
import FilterTableColumns from "../../../molecules/filter-table-columns";
import { Roles } from "../../../../services/enum/roles";
import { Menu, Transition } from "@headlessui/react";
import CreatePropertyForm from "../../forms/property/create-property-form";
import Pagination from "../../../molecules/pagination";
import Table from "../../../molecules/table";
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {}

const PropertyTable = ({ }: Props) => {

    const { t } = useTranslation();
    const { currentUser } = useAppSelector(getCurrentUser);

    const [deleteProperty, { isLoading: deleteIsLoading, error: deleteError }] = useDeletePropertyMutation();

    const [showCopyModal, setShowCopyModal] = useState<boolean>(false); // Toggle copy modal
    const [showModal, setShowModal] = useState<boolean>(false); // Toggle modal
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false); // Toggle confirm modal
    const [selectedProperty, setSelectedProperty] = useState<PropertyDto>();
    const [tableHeaders, setTableHeaders] = useState<string[]>();

    // Pagination
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>();
    const [hasNextPage, setHasNextPage] = useState<boolean>();
    const [hasPrevPage, setHasPrevPage] = useState<boolean>();

    // Table sort and search
    const [search, setSearch] = useState<string | undefined>('');
    const [searchOperation, setSearchOperation] = useState<string | undefined>();
    const [sortBy, setSortBy] = useState<string | undefined>('label'); // Column to sort by
    const [sortOrder, setSortOrder] = useState<Order>(Order.ASC); // Sort order
    const [showSortMenu, setShowSortMenu] = useState<boolean>(false); // Sort menu on small screens

    const debouncedSearch = useRef(debounce((search: string) => setSearchOperation(search), 500)).current;

    // Get properties paginated
    const { data: properties, isFetching } = useGetAllPropertiesQuery({
        page: page,
        take: 25,
        search: searchOperation,
        sortBy: sortBy,
        order: sortOrder
    });

    useEffect(() => {
        if (properties) {
            setPage(properties.meta.page);
            setPageCount(properties.meta.pageCount);
            setHasNextPage(properties.meta.hasNextPage);
            setHasPrevPage(properties.meta.hasPreviousPage);
        }
    }, [properties])

    const handlePagination = (page: number) => {
        setPage(page);
    }

    // If selectedProperty changes, set updated property
    useEffect(() => {
        if (selectedProperty) {
            const match = properties?.data.find((e) => e.id === selectedProperty.id);
            if (match) {
                setSelectedProperty(match);
            }
        }
    }, [properties]);

    // Handle sort by column and sort order
    const handleSort = (column: string, order: Order) => {
        setSortBy(column);
        setSortOrder(order === Order.ASC ? Order.DESC : Order.ASC);
    }

    // Edit property
    const handleEditProperty = (property: PropertyDto) => {
        setShowModal(true); // Show modal
        setSelectedProperty(property);
    }

    // Copy property
    const handleCopyProperty = (property: PropertyDto) => {
        setShowCopyModal(true);
        setSelectedProperty(property)
    }

    // Toggle modal and setSelected user
    const handleDeleteProperty = (property: PropertyDto) => {
        setSelectedProperty(property); // Set selected property to be deleted if confirmed in modal
        setShowConfirmModal(true); // Show confirm modal
    }

    // Delete property after confirmation
    const deletePropertyConfirmed = async () => {
        const propertyToBeDeleted = selectedProperty;
        if (propertyToBeDeleted?.id) {

            const payload = {
                property_id: propertyToBeDeleted.id,
            }

            try {
                const response = await deleteProperty(payload).unwrap();
                if (response.success) {
                    NotificationManager.success(t('page:properties.form.propertyDeleted'));
                    setShowConfirmModal(false);
                }
                else {
                    NotificationManager.error(t('page:properties.form.propertyNotDeleted'));
                }
            }
            catch (error) {
                NotificationManager.error(t('page:properties.form.propertyNotDeleted'));
            }
        }
    }

    const handleProperty = () => {

    }

    // Define available column headers to be sent to FilterTableColumns
    const columnHeaders = ['label', 'type', 'object_number', 'name', 'address', 'created_at'];

    // Gets triggered by FilterTableColumns when columns change
    const handleColumnsToDisplay = (columns: string[]) => {
        setTableHeaders(columns);
    }

    return (
        <>
            {(properties && properties?.data.length < 1 && !searchOperation) ? (
                <>
                    <div className="w-10/12 xl:w-8/12 max-w-2xl mx-auto bg-primary-blue-5 text-black rounded-md px-2 py-4 mt-10 text-center">
                        <p className="text-lg font-semibold">{t('page:properties.noPropertiesFound')}...</p>
                        <p className="mt-2">{t('page:properties.noPropertiesFoundInfo')}</p>
                    </div>
                </>
            ) : (
                <div className="w-full mx-auto md:mt-1">
                    <div className="hidden md:block">
                        <div className="sm:w-6/12 max-w-2xl mt-2 text-center sm:text-left">
                            <Input
                                type="search"
                                name="search_name"
                                placeholder={t('common:search')}
                                disableUnderline={true}
                                value={search}
                                className="w-full border rounded-md text-base md:text-sm px-2 pt-2.5 pb-1 mt-3 bg-white"
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                    debouncedSearch(e.target.value);
                                }}
                                endAdornment={
                                    <span
                                        className="material-symbols-outlined pb-1 text-gray-500 cursor-pointer"
                                        style={{ fontSize: '1.3rem' }}
                                        onClick={() => {
                                            if (search) {
                                                setSearch('')
                                                debouncedSearch('')
                                            }
                                        }}
                                    >
                                        {search ? 'close' : 'search'}
                                    </span>
                                }
                                size="small"
                            />
                        </div>
                        <div className="w-full mt-1 mb-2">
                            <FilterTableColumns
                                tableColumns={columnHeaders}
                                tableType='pt_filter'
                                handleColumnsToDisplay={handleColumnsToDisplay}
                            />
                        </div>
                        <div className="overflow-x-scroll">
                            <Table>
                                <Table.Thead>
                                    <tr>
                                        <th scope="col" className="w-20 hidden sm:table-cell">
                                            <span className="sr-only">Property image</span>
                                        </th>
                                        {tableHeaders?.includes('label') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[6rem]"
                                                onClick={() => handleSort('label', sortOrder)}>
                                                {t('common:label')}
                                                {(sortBy === 'label') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </th>
                                        )}

                                        {tableHeaders?.includes('type') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 pr-2 cursor-pointer font-bold min-w-[4rem]">
                                                {t('common:type')}
                                            </th>
                                        )}

                                        {tableHeaders?.includes('object_number') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 pr-2 cursor-pointer font-bold min-w-[8rem]"
                                                onClick={() => handleSort('object_number', sortOrder)}>
                                                {t('common:objectNumber')}
                                                {(sortBy === 'object_number') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </th>
                                        )}

                                        {tableHeaders?.includes('name') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 pr-2 cursor-pointer font-bold min-w-[6rem]"
                                                onClick={() => handleSort('name', sortOrder)}>
                                                {t('common:name')}
                                                {(sortBy === 'name') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </th>
                                        )}

                                        {tableHeaders?.includes('address') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 cursor-pointer font-bold min-w-[6rem]">
                                                {t('common:address')}
                                            </th>
                                        )}
                                        {tableHeaders?.includes('created_at') && (
                                            <th
                                                scope="col"
                                                className="py-1 md:py-2 cursor-pointer font-bold min-w-[6rem]"
                                                onClick={() => handleSort('created_at', sortOrder)}>
                                                {t('common:createdAt')}
                                                {(sortBy === 'created_at') ? (
                                                    <>
                                                        {sortOrder === Order.ASC ? (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                        ) : (
                                                            <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                                    </>
                                                )}
                                            </th>
                                        )}

                                        {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                                            <>
                                                <th scope="col" className="">
                                                    <span className="sr-only">Copy</span>
                                                </th>
                                                <th scope="col" className="">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                                <th scope="col" className="">
                                                    <span className="sr-only">Delete</span>
                                                </th>
                                            </>
                                        )}

                                    </tr>
                                </Table.Thead>
                                {properties?.data && properties.data.length > 0 && !isFetching && (
                                    <Table.Tbody>
                                        {properties?.data.map((property, idx) => {
                                            return (
                                                <tr key={idx} className={`odd:hover:bg-primary-blue-10 even:hover:bg-gray-50 cursor-pointer text-xs text-black even:bg-white odd:bg-primary-blue-5`}>
                                                    <td scope="row" className="pl-1 capitalize hidden sm:table-cell py-2 lg:py-1">
                                                        {property.image ? (
                                                            <div className="flex items-center w-9 lg:w-12 h-9 lg:h-12 rounded-full bg-white overflow-hidden">
                                                                <img
                                                                    src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${property.tenant_id}/${property.image}`}
                                                                    // onError={e => e.currentTarget.src = DefaultProperty}
                                                                    alt="Property image"
                                                                    className="w-9 md:w-12 h-9 md:h-12 rounded-full object-cover lg:p-0.5" />
                                                            </div>
                                                        ) : (
                                                            <div className="flex items-center justify-center w-9 lg:w-12 h-9 lg:h-12 rounded-full border border-gray-100">
                                                                <span
                                                                    className="material-symbols-outlined"
                                                                    style={{ fontSize: '1.3rem' }}
                                                                >
                                                                    home_work
                                                                </span>
                                                            </div>
                                                        )}
                                                    </td>

                                                    {tableHeaders?.includes('label') && (
                                                        <td
                                                            className="py-2 pr-2 pl-1 sm:pl-2 text-xs capitalize font-semibold sm:font-normal max-w-[9rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            title={property?.label}
                                                            onClick={handleProperty}>
                                                            {property.label ? property.label : '-'}
                                                        </td>
                                                    )}

                                                    {tableHeaders?.includes('type') && (
                                                        <td
                                                            className="py-2 pr-2 capitalize max-w-[4rem] min-w-[4rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            title={property.property_type && t(`common:enums.propertyTypes.${PropertyTypes[property.property_type]}`)}
                                                            onClick={handleProperty}>
                                                            {t(`common:enums.propertyTypes.${PropertyTypes[property.property_type]}`) ?? '-'}
                                                        </td>
                                                    )}

                                                    {tableHeaders?.includes('object_number') && (
                                                        <td
                                                            className="py-2 pr-2 capitalize max-w-[8rem] min-w-[8rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            title={property?.object_number}
                                                            onClick={handleProperty}>
                                                            {property.object_number ? property.object_number : '-'}
                                                        </td>
                                                    )}

                                                    {tableHeaders?.includes('name') && (
                                                        <td
                                                            className="py-2 pr-2 capitalize max-w-[7rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            title={property?.name}
                                                            onClick={handleProperty}>
                                                            {property.name ? property.name : '-'}
                                                        </td>
                                                    )}

                                                    {tableHeaders?.includes('address') && (
                                                        <td
                                                            className="py-2 pr-2 max-w-[7rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            title={property?.address}
                                                            onClick={handleProperty}>
                                                            {property.address ? property.address : '-'}
                                                        </td>
                                                    )}

                                                    {tableHeaders?.includes('created_at') && (
                                                        <td
                                                            className="py-2 max-w-[7rem] min-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                            title={property.created_at && dateToLocaleDate(property.created_at)}
                                                            onClick={handleProperty}>
                                                            {property.created_at ? dateToLocaleDate(property.created_at) : '-'}
                                                        </td>
                                                    )}
                                                    {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                                                        <>
                                                            <td className="md:px-2 lg:px-1">
                                                                <button
                                                                    onClick={() => handleCopyProperty(property)}
                                                                    className="text-black material-symbols-outlined"
                                                                    style={{ fontSize: '1.2rem' }}
                                                                    title={t('common:duplicate')}
                                                                >content_copy</button>
                                                            </td>
                                                            <td className="pb-1 md:px-2 lg:px-1">
                                                                <button
                                                                    onClick={() => handleEditProperty(property)}
                                                                    className="text-black material-symbols-outlined"
                                                                    style={{ fontSize: '1.2rem' }}
                                                                    title={t('common:edit')}
                                                                >edit_square</button>
                                                            </td>
                                                            <td className="md:px-2 lg:px-1">
                                                                <button
                                                                    onClick={() => handleDeleteProperty(property)}
                                                                    className="material-symbols-outlined text-status-critical"
                                                                    style={{ fontSize: '1.3rem' }}
                                                                    title={t('common:delete')}
                                                                >delete</button>
                                                            </td>
                                                        </>
                                                    )}
                                                </tr>
                                            )
                                        })}
                                    </Table.Tbody>
                                )}
                            </Table>
                            {isFetching && (
                                <div className="mx-auto mb-5 mt-1">
                                    <Loader show={isFetching} size='small' />
                                </div>
                            )}

                            {!properties || properties.data.length < 1 && (
                                <div className="my-5">
                                    <p className="text-sm ml-7 md:ml-5 font-semibold text-black">{t('page:properties.noPropertiesFound')}...</p>
                                </div>
                            )}
                        </div>
                        {properties && properties.meta && (
                            <div className="flex justify-center py-2">
                                <Pagination
                                    count={pageCount}
                                    page={page}
                                    defaultPage={1}
                                    showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                                    showLastButton={pageCount ? pageCount > 5 : false}
                                    hidePrevButton={!hasPrevPage}
                                    hideNextButton={!hasNextPage}
                                    onChange={handlePagination}
                                />
                            </div>
                        )}
                    </div>
                    {/* Mobile screen  */}
                    <div className="block md:hidden w-full mx-auto mt-1">
                        <div className="pt-1">
                            <Input
                                type="search"
                                name="search_name"
                                placeholder={t('common:search')}
                                disableUnderline={true}
                                className="border rounded-md px-2 pt-2.5 pb-1 mt-3 w-10/12 bg-white"
                                onChange={(e) => debouncedSearch(e.target.value)}
                                endAdornment={<span className="material-symbols-outlined text-gray-500 pb-1" style={{ fontSize: '1.3rem' }}>search</span>}
                                size="small"
                            />
                            <Menu as="div" className="relative inline-block text-left cursor-pointer">
                                <div>
                                    <Menu.Button
                                        className="material-symbols-outlined text-black align-sub ml-3 rotate-90 border rounded-md px-1 py-1"
                                        style={{ fontSize: '1.4rem' }}
                                        onClick={() => setShowSortMenu(!showSortMenu)}>
                                        sync_alt
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className="absolute right-0 z-10 mt-2 w-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="">
                                            <Menu.Item>
                                                <div className="flex hover:bg-primary-blue-5"
                                                    onClick={(e) => handleSort('label', sortOrder)}>
                                                    <span className="w-1/2 block px-2 py-2 text-sm">{t('common:label')}</span>
                                                    <div className="w-1/2 py-2 pr-1">
                                                        {(sortBy === 'label') ? (
                                                            <>
                                                                {sortOrder === Order.ASC ? (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                                ) : (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <div className="flex hover:bg-primary-blue-5" onClick={() => handleSort('object_number', sortOrder)}>
                                                    <span className="w-1/2 block px-2 py-2 text-sm">{t('common:objectNumber')}</span>
                                                    <div className="w-1/2 py-2 pr-1">
                                                        {(sortBy === 'object_number') ? (
                                                            <>
                                                                {sortOrder === Order.ASC ? (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                                ) : (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <div className="flex hover:bg-primary-blue-5" onClick={() => handleSort('created_at', sortOrder)}>
                                                    <span className="w-1/2 block px-2 py-2 text-sm">{t('common:createdAt')}</span>
                                                    <div className="w-1/2 py-2 pr-1">
                                                        {(sortBy === 'created_at') ? (
                                                            <>
                                                                {sortOrder === Order.ASC ? (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_up</span>
                                                                ) : (
                                                                    <span className="material-symbols-outlined align-middle text-status-ok float-right">arrow_drop_down</span>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="material-symbols-outlined align-middle float-right">arrow_drop_down</span>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                        {properties?.data && properties.data.length > 0 && !isFetching && (
                            <div>
                                {properties?.data?.map((property, idx) => (
                                    <div key={idx} className="my-2 p-2 text-xs relative odd:bg-primary-blue-5 odd:hover:bg-primary-blue-10 even:border border-primary-blue-5 hover:even:bg-gray-50 rounded-md">
                                        <div className="flex flex-col flex-wrap gap-x-5 my-1">
                                            {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                                                <div>
                                                    <button
                                                        onClick={() => handleCopyProperty(property)}
                                                        className="text-black material-symbols-outlined absolute right-[4.5rem] top-2"
                                                        style={{ fontSize: '1.2rem' }}
                                                        title={t('common:duplicate')}
                                                    >content_copy</button>
                                                    <button
                                                        onClick={() => handleEditProperty(property)}
                                                        className="text-black material-symbols-outlined absolute right-10 top-1.5"
                                                        style={{ fontSize: '1.2rem' }}
                                                        title={t('common:edit')}
                                                    >edit_square</button>
                                                    <button
                                                        onClick={() => handleDeleteProperty(property)}
                                                        className="material-symbols-outlined text-status-critical disabled:text-gray-300 absolute right-2 top-2"
                                                        style={{ fontSize: '1.3rem' }}
                                                        title={t('common:delete')}
                                                    >delete</button>
                                                </div>
                                            )}

                                            <div className="flex items-center">
                                                <div className="sm:ml-5">
                                                    {property.image ? (
                                                        <div className="flex items-center rounded-full bg-white overflow-hidden">
                                                            <img
                                                                src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${property.tenant_id}/${property.image}`}
                                                                // onError={e => e.currentTarget.src = DefaultPersonImg}
                                                                alt="Profile picture"
                                                                className="rounded-full w-12 sm:w-14 h-12 sm:h-14 object-cover" />
                                                        </div>
                                                    ) : (
                                                        <div className="flex items-center w-12 sm:w-14 h-12 sm:h-14 justify-center rounded-full border border-gray-100">
                                                            <span
                                                                className="material-symbols-outlined"
                                                                style={{ fontSize: '1.4rem' }}
                                                            >
                                                                home_work
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="ml-3 sm:ml-10">
                                                    <p className="font-semibold text-sm capitalize">{property.label}</p>
                                                    <p>{t(`common:enums.propertyTypes.${PropertyTypes[property.property_type]}`) ?? '-'}</p>
                                                    <p>{property.object_number ? property.object_number : '-'}</p>
                                                    <p>{property.name ? property.name : ''}</p>
                                                    <p>{property.address ? property.address : ''}</p>
                                                </div>

                                                <p className="absolute right-2 bottom-2">{dateToLocaleDate(property.created_at)}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        )}

                        {isFetching && (
                            <div className="mx-auto mb-5 mt-1">
                                <Loader show={isFetching} size='small' />
                            </div>
                        )}

                        {!properties || properties.data.length < 1 && (
                            <div className="my-2">
                                <p className="text-sm ml-7 md:ml-5 font-semibold text-black">{t('page:properties.noPropertiesFound')}...</p>
                            </div>
                        )}
                        {properties && properties.meta && (
                            <div className="flex justify-center py-2">
                                <Pagination
                                    count={pageCount}
                                    page={page}
                                    defaultPage={1}
                                    showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                                    showLastButton={pageCount ? pageCount > 5 : false}
                                    hidePrevButton={!hasPrevPage}
                                    hideNextButton={!hasNextPage}
                                    onChange={handlePagination}
                                />
                            </div>
                        )}
                    </div>

                    <Modal isOpen={showCopyModal} close={() => setShowCopyModal(false)} title={`${t('page:properties.createProperty')} (${t('common:duplicate')})`} size='sm' titleAlign='center'>
                        <CreatePropertyForm property={selectedProperty} />
                    </Modal>

                    <Modal isOpen={showModal} close={() => setShowModal(false)} title={`${selectedProperty?.label}`} size='sm' titleAlign='center'>
                        <div>
                            <div className="flex flex-wrap justify-center mx-auto mb-5">
                                <div className="w-10/12 sm:w-full">
                                    <UpdatePropertyForm property={selectedProperty} />

                                    <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                                    <PropertyLogAccess property={selectedProperty} />

                                    <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                                    <PropertyUserAccess property={selectedProperty} />

                                    <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                                    <PropertyBuildingAccess property={selectedProperty} />

                                    <hr className="w-full h-px my-10 mx-auto bg-gray-300 border-0 rounded" />
                                    <PropertyObjectAccess property={selectedProperty} />
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <ConfirmModal isOpen={showConfirmModal} close={() => setShowConfirmModal(false)} title={t('page:properties.deleteProperty')} titleAlign='center' type='warning'>
                        <div className="bg-red-200 rounded p-1">
                            <p className="text-xs font-semibold my-1 text-black">{t('page:properties.deletePropertyInfo')}</p>
                            <p className="text-xs font-semibold mt-2 mb-1 text-black">{t('page:properties.deletePropertyLogInfo')}</p>
                        </div>
                        <p className="text-lg font-semibold mt-3">{t('common:confirmDelete')} {selectedProperty?.label ? selectedProperty.label : 'the selected property'}?</p>
                        <button
                            onClick={deletePropertyConfirmed}
                            className="bg-status-critical hover:bg-status-critical-h py-2 px-6 mt-3 rounded mx-auto text-sm sm:text-base text-white font-semibold">{t('common:delete')}</button>
                    </ConfirmModal>
                </div>
            )}
        </>
    )
}

export default PropertyTable;