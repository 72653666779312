import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../redux/hooks';
import { getTenant } from '../../../../redux/slices/tenant.slice';
import { getCurrentUser } from '../../../../redux/slices/user.slice';
import CreatePropertyForm from '../../../organisms/forms/property/create-property-form';
import Modal from '../../../molecules/modal';
import { Grow } from '@mui/material';
import CreatePropertyObjectForm from '../../../organisms/forms/property-object/create-property-object-form';
import PropertyObjectTable from '../../../organisms/property-object/property-object-table';
import PropertyBuildingTable from '../../../organisms/property-building/property-building-table';
import CreatePropertyBuildingForm from '../../../organisms/forms/property-building/create-property-building-form';
import { useTranslation } from 'react-i18next';
import PropertyTable from '../../../organisms/property/property-table';
import { Roles } from '../../../../services/enum/roles';
import PrimaryButton from '../../../atoms/primary-button';
import { useNavigate, useParams } from 'react-router-dom';
import MapsProperty from '../../../organisms/maps/property';

type Props = {
}

const PropertiesPage = (props: Props) => {

    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const { currentUser } = useAppSelector(getCurrentUser);
    const { tenant } = useAppSelector(getTenant);

    // Selected view (list or map)
    const [selectedView, setSelectedView] = useState<string>('list');
    // Selected tab
    const [selectedTab, setSelectedTab] = useState<string>('properties');
    // Modal to display forms
    const [showModal, setShowModal] = useState<boolean>(false);
    // Selected form type in modal
    const [selectedForm, setSelectedForm] = useState<string>(selectedTab);

    // Handle tab change
    const handleSelectTab = (type: string) => {
        if (type === 'properties') {
            navigate(`/properties`);
        }
        else {
            navigate(`/properties/${type}`);
        }
    }

    useEffect(() => {
        if (params) {
            if (params.path === 'buildings') {
                setSelectedTab('buildings');
                setSelectedForm('buildings');
            }

            else if (params.path === 'objects') {
                setSelectedTab('objects');
                setSelectedForm('objects');
            }
            else {
                setSelectedTab('properties');
                setSelectedForm('properties');
            }
        }
    }, [])



    return (
        <Grow in={currentUser.id ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
            <div className="w-full p-1 mx-auto">
                {/* <div>
                    <button
                        onClick={() => setSelectedView('list')}
                        className={`${selectedView === 'list' ? 'text-primary-blue' : 'text-primary-blue-15'} material-symbols-outlined`} style={{ fontSize: '2rem' }}>reorder</button>
                    <button
                        onClick={() => setSelectedView('map')}
                        className={`${selectedView === 'map' ? 'text-primary-blue' : 'text-primary-blue-20'} material-symbols-outlined`} style={{ fontSize: '2rem' }}>map</button>
                </div> */}


                <ul className="flex flex-wrap -mb-px justify-center sm:justify-normal w-full mx-auto">
                    <li onClick={() => handleSelectTab('properties')} className={`${selectedTab === 'properties' ? 'border-blue-600 active text-blue-600 border-b-2' : 'border-gray-300 border-b-2 hover:text-gray-600 hover:border-b-2 hover:border-gray-300 text-black'} pl-2 pr-4 pb-4 pt-4 rounded-t-lg mr-2 cursor-pointer`}>
                        <span className="material-symbols-outlined align-sub">home_work</span>
                        <span className="inline-block pl-1 text-sm">{t('common:properties')}</span>
                    </li>
                    <li onClick={() => handleSelectTab('buildings')} className={`${selectedTab === 'buildings' ? 'border-blue-600 active text-blue-600 border-b-2' : 'border-gray-300 border-b-2 hover:text-gray-600 hover:border-b-2 hover:border-gray-300 text-black'} pl-2 pr-4 pb-4 pt-4 rounded-t-lg mr-2 cursor-pointer`}>
                        <span className="material-symbols-outlined align-sub">home</span>
                        <span className="inline-block pl-1 text-sm">{t('common:buildings')}</span>
                    </li>
                    <li onClick={() => handleSelectTab('objects')} className={`${selectedTab === 'objects' ? 'border-blue-600 active text-blue-600 border-b-2' : 'border-gray-300 border-b-2 hover:text-gray-600 hover:border-b-2 hover:border-gray-300 text-black'} pl-2 pr-4 pb-4 pt-4 rounded-t-lg mr-2 cursor-pointer`}>
                        <span className="material-symbols-outlined align-sub">meeting_room</span>
                        <span className="inline-block pl-1 text-sm">{t('common:objects')}</span>
                    </li>
                </ul>

                {selectedTab === 'properties' && (
                    <>
                        {currentUser && (currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                            <div className="flex justify-end mt-5 mb-2 w-full mx-auto">
                                <PrimaryButton
                                    text={t('page:properties.createProperty')}
                                    size='medium'
                                    onClick={() => setShowModal(true)}
                                    startAdornment={'add'}
                                />
                            </div>
                        )}
                        <Grow in={selectedTab === 'properties' ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                            <div className="">
                                {selectedView === 'list' && (
                                    <PropertyTable />
                                )}

                                {/* {selectedView === 'map' && (
                                    <MapsProperty />
                                )} */}
                            </div>
                        </Grow>
                    </>
                )}

                {selectedTab === 'buildings' && (
                    <>
                        {currentUser && (currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                            <div className="flex justify-end mt-5 mb-2 w-full mx-auto">
                                <PrimaryButton
                                    text={t('page:properties.createBuilding')}
                                    size='medium'
                                    onClick={() => setShowModal(true)}
                                    startAdornment={'add'}
                                />
                            </div>
                        )}
                        <Grow in={selectedTab === 'buildings' ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                            <div className="">
                                <PropertyBuildingTable />
                            </div>
                        </Grow>
                    </>
                )}

                {selectedTab === 'objects' && (
                    <>
                        {currentUser && (currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
                            <div className="flex justify-end mt-5 mb-2 w-full mx-auto">
                                <PrimaryButton
                                    text={t('page:properties.createObject')}
                                    size='medium'
                                    onClick={() => setShowModal(true)}
                                    startAdornment={'add'}
                                />
                            </div>
                        )}
                        <Grow in={selectedTab === 'objects' ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                            <div className="">
                                <PropertyObjectTable />
                            </div>
                        </Grow>
                    </>
                )}

                {/* Modal to display forms */}
                <Modal isOpen={showModal} close={() => setShowModal(false)} title='' size='sm' titleAlign='center'>
                    <div>
                        <div className="w-11/12 mx-auto mb-5">
                            <div className="">
                                <ul className="flex flex-wrap mb-5 justify-center">
                                    <li onClick={() => setSelectedForm('properties')} className={`${selectedForm === 'properties' ? 'border-blue-600 active text-blue-600 border-b-2' : 'border-gray-300 border-b-2 hover:text-gray-600 hover:border-b-2 hover:border-gray-300 text-black'} pl-2 pr-4 pb-4 pt-4 rounded-t-lg mr-2 cursor-pointer`}>
                                        <span className="material-symbols-outlined align-sub">home_work</span>
                                        <span className="inline-block pl-1">{t('common:property')}</span>
                                    </li>
                                    <li onClick={() => setSelectedForm('buildings')} className={`${selectedForm === 'buildings' ? 'border-blue-600 active text-blue-600 border-b-2' : 'border-gray-300 border-b-2 hover:text-gray-600 hover:border-b-2 hover:border-gray-300 text-black'} pl-2 pr-4 pb-4 pt-4 rounded-t-lg mr-2 cursor-pointer`}>
                                        <span className="material-symbols-outlined align-sub">home</span>
                                        <span className="inline-block pl-1">{t('common:building')}</span>
                                    </li>
                                    <li onClick={() => setSelectedForm('objects')} className={`${selectedForm === 'objects' ? 'border-blue-600 active text-blue-600 border-b-2' : 'border-gray-300 border-b-2 hover:text-gray-600 hover:border-b-2 hover:border-gray-300 text-black'} pl-2 pr-4 pb-4 pt-4 rounded-t-lg mr-2 cursor-pointer`}>
                                        <span className="material-symbols-outlined align-sub">meeting_room</span>
                                        <span className="inline-block pl-1">{t('common:object')}</span>
                                    </li>
                                </ul>

                                {selectedForm === 'properties' && (
                                    <>
                                        <h4 className="text-center font-semibold mt-10">{t('page:properties.createProperty')}</h4>
                                        <CreatePropertyForm />
                                    </>
                                )}
                                {selectedForm === 'buildings' && (
                                    <>
                                        <h4 className="text-center font-semibold mt-10">{t('page:properties.createBuilding')}</h4>
                                        <CreatePropertyBuildingForm />
                                    </>
                                )}
                                {selectedForm === 'objects' && (
                                    <>
                                        <h4 className="text-center font-semibold mt-10">{t('page:properties.createObject')}</h4>
                                        <CreatePropertyObjectForm />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        </Grow>
    )
}

export default PropertiesPage;