import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TenantState } from "../../services/dto/tenant-state.dto";
import { TenantTypes } from "../../services/enum/tenant-types";
import { Plans } from "../../services/enum/plans";

const initialState: TenantState = {
    tenant: {
        id: '',
        name: '',
        org_number: '',
        domain: '',
        email: '',
        phone: '',
        type: TenantTypes.None,
        plan: Plans.None,
        address: '',
        city: '',
        zip: '',
        country: '',
        phone_verified: false,
        email_verified: false,
        visible: false,
    }
}

export const tenantSlice = createSlice({
    name: 'tenantSlice',
    initialState,
    reducers: {
        // Set tenant state
        setTenant: (state: TenantState, { payload }: PayloadAction<TenantState>) => {
            state.tenant = payload.tenant;
        },
    },
})

export const { setTenant } = tenantSlice.actions;
export const tenantReducer = tenantSlice.reducer;
export const getTenant = (state: RootState) => state.rootReducer.tenant;