import { reAuthAPI } from "../../re-auth/re-auth";
import { PropertyBuildingUserAccessDto } from "./dto/property-building-user-access.dto";
import { UpdatePropertyBuildingUserAccessDto } from "./dto/update-property-building-user-access.dto";

export const propertyBuildingUserAccessAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Get all property building user access relations
        getAllPropertyBuildingUserAccess: builder.query<PropertyBuildingUserAccessDto[], void>({
            query: () => ({
                url: '/property-building-user-access/users',
                method: 'GET',
            }),
            providesTags: ['PropertyBuildingUserAccess']
        }),
        // Get all property building user access relations by ids (propertyBuildingIds or userIds)
        getAllPropertyBuildingUserAccessByIds: builder.query<PropertyBuildingUserAccessDto[], string[]>({
            query: (ids) => ({
                url: '/property-building-user-access/users/ids?ids=' + ids,
                method: 'GET',
            }),
            providesTags: ['PropertyBuildingUserAccess']
        }),
        // Get all property building user access relations by given id
        getAllPropertyBuildingUserAccessById: builder.query<PropertyBuildingUserAccessDto[], string>({
            query: (id) => ({
                url: '/property-building-user-access/users/id/' + id,
                method: 'GET',
            }),
            providesTags: ['PropertyBuildingUserAccess']

        }),
        // Update many property building user access relations
        updateManyPropertyBuildingUserAccess: builder.mutation<any, UpdatePropertyBuildingUserAccessDto[]>({
            query: (propertyBuildingUserAccess) => ({
                url: '/property-building-user-access/users',
                method: 'PUT',
                body: propertyBuildingUserAccess
            }),
            invalidatesTags: ['PropertyBuildingUserAccess', 'PropertyObject', 'PropertyObjectUserAccess']
        }),
    })
})

export const {
    useGetAllPropertyBuildingUserAccessQuery,
    useGetAllPropertyBuildingUserAccessByIdsQuery,
    useGetAllPropertyBuildingUserAccessByIdQuery,
    useUpdateManyPropertyBuildingUserAccessMutation,

} = propertyBuildingUserAccessAPI;