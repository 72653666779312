import { useEffect, useState } from "react";
import { ErrorInputType, FixedFieldProperties } from "../../../../molecules/create-custom-log";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../../atoms/checkbox";

export type InputFieldType = FixedFieldProperties & {
    inputType: string;
}

type FieldProps = {
    field: InputFieldType;
    inputTypes: { key: string, value: string }[];
    handleUpdateGeneratedField: (inputField: InputFieldType) => void;
    handleDeleteGeneratedField: (id: number) => void;
    error: ErrorInputType;
    logCount?: number;
    newTemplate: boolean;
}

const DynamicInputField = ({
    field,
    inputTypes,
    handleUpdateGeneratedField,
    handleDeleteGeneratedField,
    error,
    logCount,
    newTemplate,
}: FieldProps) => {
    const { t } = useTranslation();

    const [inputField, setInputField] = useState<InputFieldType>({
        type: field.type,
        id: field.id,
        fieldName: field.fieldName,
        inputType: field.inputType,
        isRequired: field.isRequired,
        isActive: field.isActive,
        existing_record: field.existing_record,
        existing_name: field.existing_name
    });
    const [inputError, setInputError] = useState<{ nameField: string, typeField: string }>({ nameField: '', typeField: '' });

    useEffect(() => {
        handleUpdateGeneratedField(inputField);
    }, [inputField, field]);

    useEffect(() => {
        if (error.nameField && error.id === field.id) {
            setInputError({ ...inputError, nameField: error.nameField })
        }
    }, [error])

    const handleNameField = (value: string) => {
        if (!/^(?![0-9\-_])[a-zA-ZåäöÅÄÖ0-9_-\s]*$/.test(value)) {
            setInputError({ ...inputError, nameField: t('page:logs.form.fieldOnlyLetters') });
        }
        else if (value.length > 32) {
            setInputError({ ...inputError, nameField: t('page:logs.form.fieldMaxLength32') });
        }
        else {
            setInputError({ ...inputError, nameField: '' });
            setInputField({ ...inputField, fieldName: value });
        }
    }

    return (
        <>
            <div className="w-full flex flex-col lg:flex-row border p-2 rounded my-4">
                <div className="w-11/12 lg:w-2/6">
                    <label className="block text-xs lg:text-sm font-semibold leading-6 text-gray-600" htmlFor="input_type">{t('page:logs.nameOfField')}</label>
                    <input
                        type="text"
                        placeholder={t('common:name')}
                        required
                        name="field_name"
                        value={inputField.fieldName}
                        onChange={(e) => handleNameField(e.target.value)}
                        className={`${inputError.nameField && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} md:text-sm w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:text-sm focus:ring-2 focus:ring-indigo-600 lg:leading-6`}
                    />
                    {inputError.nameField && (<span className="text-xs text-status-critical">{inputError.nameField}</span>)}
                </div>
                <div className="w-11/12 lg:w-2/6 lg:mx-5">
                    <label className="block text-xs lg:text-sm font-semibold leading-6 text-gray-600" htmlFor="input_type">{t('page:logs.typeOfField')}</label>
                    <select
                        onChange={(e) => setInputField({ ...inputField, inputType: e.target.value })}
                        value={inputField.inputType}
                        name="input_type"
                        disabled={((logCount && logCount > 0) && field.existing_record) ? true : false}
                        className="w-full md:text-sm  rounded-md border-0 h-9 lg:p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 lg:leading-6">
                        {inputTypes.map((option) => (
                            <option key={option.key} value={option.key}>
                                {t(`page:logs.${option.value}`)}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="lg:w-1/6 flex flex-row lg:flex-col items-center gap-y-1 gap-x-2 md:gap-x-0 my-2 md:my-0">
                    <label className="block text-xs font-semibold mt-1 text-gray-600" htmlFor="input_type">{t('common:required')}</label>
                    <Checkbox
                        size={'sm'}
                        checked={inputField.isRequired}
                        onChange={(e) => setInputField({ ...inputField, isRequired: e.target.checked })}
                    />
                </div>

                {/* Show active checkbox if existing template is being edited */}
                {!newTemplate && (
                    <div className="lg:w-2/12 flex flex-row lg:flex-col items-center lg:border-l-2 lg:justify-center gap-y-1 mt-1.5 md:mt-0">
                        <label className="text-xs font-semibold text-gray-600" htmlFor="input_type">
                            {t('common:active')}
                            <span
                                className="material-symbols-outlined align-sub ml-0.5 mr-2"
                                title={t('page:logs.activeBoxHelp')}
                                style={{ fontSize: '1rem' }}
                            >help</span>
                        </label>
                            <Checkbox
                                size={'sm'}
                                checked={inputField.isActive}
                                onChange={(e) => setInputField({ ...inputField, isActive: e.target.checked })}
                            />
                    </div>
                )}
                <div className="flex flex-row flex-1 justify-end mt-[-1rem] lg:mt-0.5 ">
                    <button
                        className="material-symbols-outlined text-status-critical hover:text-red-600 disabled:text-zinc-300"
                        onClick={() => handleDeleteGeneratedField(field.id)}
                        disabled={((logCount && logCount > 0) && field.existing_record) ? true : false}
                    >delete</button>
                </div>
            </div>
        </>
    )
}

export default DynamicInputField;