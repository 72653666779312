import { PageOptionsDto } from '../../dto/page-options.dto';
import { PageDto } from '../../dto/page.dto';
import { reAuthAPI } from '../../re-auth/re-auth';
import { CreateSubsidiaryDto } from './dto/create-subsidiary.dto';
import { SubsidiaryDto } from './dto/subsidiary.dto';
import { UpdateSubsidiaryDto } from './dto/update-subsidiary.dto';

export const subsidiaryAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Create new subsidiary for given tenant
        createSubsidiary: builder.mutation<any, CreateSubsidiaryDto>({
            query: (subsidiary) => ({
                url: '/subsidiaries',
                method: 'POST',
                body: subsidiary,
            }),
            invalidatesTags: ['Subsidiaries']
        }),
        // Update subsidiary
        updateSubsidiary: builder.mutation<any, UpdateSubsidiaryDto>({
            query: (subsidiary) => ({
                url: '/subsidiaries',
                method: 'PUT',
                body: subsidiary
            }),
            invalidatesTags: ['Subsidiaries']
        }),
        // Get subsidiaries paginated
        getSubsidiaries: builder.query<PageDto<SubsidiaryDto>, PageOptionsDto>({
            query: (options) => {
                const queryParams = [];
                if (options.page !== undefined) queryParams.push(`page=${options.page}`);
                if (options.take !== undefined) queryParams.push(`take=${options.take}`);
                if (options.order !== undefined) queryParams.push(`order=${options.order}`);
                if (options.search !== undefined && options.search.length > 0) queryParams.push(`search=${options.search}`);
                if (options.sortBy !== undefined) queryParams.push(`sort_by=${options.sortBy}`)

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    url: `/subsidiaries/${queryString}`,
                    method: 'GET',
                }
            },
            providesTags: ['Subsidiaries'],
        }),
        // Get subsidiaries by given ids 
        getSubsidiariesByIds: builder.query<SubsidiaryDto[], string[]>({
            query: (subsidiaryIds) => ({
                url: '/subsidiaries/ids?ids=' + subsidiaryIds,
                method: 'GET',
            }),
            providesTags: ['Subsidiaries']
        }),
        // Get one subsidiary by given id 
        getSubsidiary: builder.query<SubsidiaryDto, string>({
            query: (subsidiaryId) => ({
                url: '/subsidiaries/' + subsidiaryId,
                method: 'GET',
            }),
            providesTags: ['Subsidiaries']
        }),
        // Delete subsidiary with given id
        deleteSubsidiary: builder.mutation<any, { subsidiary_id: string }>({
            query: (subsidiaryId) => ({
                url: '/subsidiaries',
                method: 'DELETE',
                body: subsidiaryId
            }),
            invalidatesTags: ['Subsidiaries']
        }),
    })
})

export const {
    useCreateSubsidiaryMutation,
    useUpdateSubsidiaryMutation,
    useGetSubsidiariesQuery,
    useGetSubsidiariesByIdsQuery,
    useDeleteSubsidiaryMutation,
    useGetSubsidiaryQuery,
} = subsidiaryAPI;