import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import Loader from '../../../../atoms/loader/loader';
import { useTranslation } from 'react-i18next';
import { ExceptionError } from '../../../../../services/interface/exception-error.interface';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { getAuthenticatedUser, setAuthenticatedUser } from '../../../../../redux/slices/auth.slice';
import { useAuthEmailTokenQuery } from '../../../../../services/catalog/auth/auth.service';
import { AuthState } from '../../../../../services/dto/auth-state.dto';

type Props = {}

const EmailVerificationPage = (props: Props) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const [authEmail, setAuthEmail] = useState<any>(skipToken);
    const { data, error, isLoading } = useAuthEmailTokenQuery(authEmail);
    const [verifyError, setVerifyError] = useState<string>('');


    useEffect(() => {
        if (token && token.length > 100) {
            setAuthEmail(token);
        }
        else {
            navigate('/login');
        }
    }, [])

    useEffect(() => {
        if (data) {
            setVerifyError('');

            // If data.success, the verification is valid but the user is not signed in so will be redirected to login
            if (data.success) {
                NotificationManager.success(t('common:notifications.emailConfirmed'));
            }
            // If data.user and data.access_token the user was signed in when verifying email, will be signed in
            else if (data.user && data.access_token) {
                NotificationManager.success(t('common:notifications.emailConfirmed'));

                const authStateObj: AuthState = {
                    authUser: {
                        name: data.user.name,
                        email: data.user.email,
                        role: data.user.role,
                        user_id: data.user.user_id,
                    },
                    access_token: data.access_token
                };
                // Sign in user
                localStorage.setItem('user', JSON.stringify(authStateObj.authUser.name));
                dispatch(setAuthenticatedUser(authStateObj));
                navigate('/home');
            }
        }
        if (error) {
            if ('data' in error) {
                const resError = error as ExceptionError;
                setVerifyError(resError.data.message);
            }
        }
    }, [data, error])

    const renderVerificationError = (error: string) => {
        switch (verifyError) {
            case 'invalid_token':
                return (
                    <>
                        <p className="font-bold">{t(`common:server.${error}`)}</p>
                        <p className="text-sm mt-3">{t('page:auth.email.invalidTokenInfo')}</p>
                        <p className="text-sm">{t('page:auth.email.tryAgainOrLogin')}</p>
                    </>
                )
            case 'token_expired':
                return (
                    <>
                        <p className="font-bold">{t(`common:server.${error}`)}</p>
                        <p className="text-sm mt-3">{t('page:auth.email.tokenExpiredInfo')}</p>
                        <button
                            onClick={() => navigate('/login')}
                            className="mt-5 font-bold text-secondary-blue bg-primary-blue hover:bg-primary-blue-h rounded py-2 px-4 mx-auto disabled:bg-gray-400 disabled:text-gray-300"
                        >{t('common:login')}
                        </button>
                    </>
                )
            default:
                return (
                    <p>{t('page:auth.email.somethingWentWrong')}</p>
                )
        }
    }

    return (
        <div className="w-11/12 lg:w-8/12 xl:w-6/12 mx-auto flex flex-col bg-primary-blue-5 rounded-lg shadow-md p-6 text-center mt-[10vh] min-h-[25vh] text-black">
            <h3 className="text-2xl text-primary-blue font-extrabold mb-5">{t('page:auth.email.emailVerification')}</h3>
            {isLoading ? (
                <>
                    <h5 className="text-lg text-black font-bold mb-10">{t('page:auth.email.verifyingEmail')}</h5>
                    <Loader show={isLoading} />
                </>
            ) : (
                <>
                    {error ? (
                        verifyError ?
                            renderVerificationError(verifyError) :
                            <p>{t('page:auth.email.somethingWentWrong')}</p>
                    ) : (
                        <>
                            {data && (data.success || data.user) && (
                                <>
                                    <p className="mb-5 text-lg font-bold">{t('page:auth.email.emailVerified')}</p>
                                    <p className="text-sm">{t('page:auth.email.signIn')}</p>
                                    <button
                                        onClick={() => navigate('/login')}
                                        className="mt-5 font-bold text-secondary-blue bg-primary-blue hover:bg-primary-blue-h rounded py-2 px-4 mx-auto disabled:bg-gray-400 disabled:text-gray-300"
                                    >{t('common:login')}
                                    </button>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default EmailVerificationPage;