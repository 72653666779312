import React, { useState } from 'react'
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Roles } from '../../../../services/enum/roles';
import PrimaryButton from '../../../atoms/primary-button';

export type UserFormType = {
    tenant_id: string; // Get from created tenant
    active: boolean;
    name: string;
    email: string;
    password: string;
    phone: string;
    role: Roles;
    first_user?: boolean;
    address?: string;
    city?: string;
    zip?: string;
    country?: string;
    profile_picture?: string;
    subsidiary_id?: string | null;
    confirm_password: string;
}

type Props = {
    onSubmitUserForm: (data: UserFormType) => void;
    error?: string;
    tenantDomain?: string;
}

const CreateUserForm = ({
    onSubmitUserForm,
    error = '',
    tenantDomain = ''
}: Props) => {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    const { control, getValues, handleSubmit, formState: { errors } } = useForm<UserFormType>({
        defaultValues: {
            tenant_id: '',
            active: true,
            name: '',
            email: '',
            password: '',
            phone: '',
            role: Roles.None,
            profile_picture: '',
            subsidiary_id: null,
            confirm_password: '',
        }
    });

    const validateConfirmPassword = () => {
        const password = getValues().password;
        const confirmPassword = getValues().confirm_password;

        if (!confirmPassword)
            return true;

        if (confirmPassword.length > 7 && confirmPassword !== password)
            return t('page:users.form.passwordNotSame');
    }

    return (
        <>
            <div className="sm:mx-auto sm:w-full">
                <form className="space-y-3" onSubmit={handleSubmit(onSubmitUserForm)}>
                    {/* Don't display role selection if this is first user getting created */}
                    {/* {!firstUser ? (
                        <div>
                            <label htmlFor='tenant_type' className="block text-sm font-semibold leading-6 text-gray-600">
                                Role
                            </label>
                            <div className="mt-0.5">
                                <select
                                    {...register('role')}
                                    id='role'
                                    className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                    {(Object.values(Roles) as Array<keyof typeof Roles>).map((val) => {
                                    return (
                                        <>
                                            { (typeof val === 'number' && val !== -1) &&  (
                                                <option key={val} value={val}>{Roles[val]}</option>
                                            )}
                                        </>
                                    )
                                })}
                                </select>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <label htmlFor='tenant_type' className="block text-sm font-semibold leading-6 text-gray-600">
                                <span>Role</span> 
                                
                                <span 
                                className="material-symbols-outlined ml-1 align-sub cursor-pointer" 
                                title="The admin role is automatically assigned to the first user getting created" 
                                style={{ fontSize: '1.1rem' }}>help</span>
                            </label>
                            <div className="mt-0.5">
                                <select
                                    {...register('role')}
                                    id='role'
                                    disabled
                                    className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                    <option key={Roles.admin} value={Roles.admin}>{Roles[1]}</option>
                                </select>
                            </div>
                        </div>
                    )} */}
                    <div>
                        <label htmlFor='name' className="block text-sm font-semibold leading-6 text-gray-600">
                            {t('common:name')}<span className="text-status-critical">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: t('common:form.nameNotEmpty'),
                                    minLength: {
                                        value: 3,
                                        message: t('common:form.nameMinLength3')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={64}
                                            placeholder={t('common:name')}
                                            className={`${errors.name && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                        />
                                        {errors.name && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.name.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='email' className={`block text-sm font-semibold leading-6 text-gray-600 ${tenantDomain && 'mb-[-20px]'}`}>
                            {t('common:email')}<span className="text-status-critical">*</span>
                        </label>
                        {tenantDomain && (<span className="relative left-32 sm:left-[200px] top-[32px] border-l-2 p-1.5 text-gray-500 text-xs sm:text-sm font-bold">@{tenantDomain}</span>)}
                        <div className="mt-0.5">
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: t('common:form.emailNotEmpty'),
                                    minLength: {
                                        value: 3,
                                        message: t('common:form.emailMinLength3')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={32}
                                            placeholder={t('common:email')}
                                            className={`${errors.email && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                        />
                                        {errors.email && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.email.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='password' className="block text-sm font-semibold leading-6 text-gray-600">
                            {t('common:password')}<span className="text-status-critical">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="password"
                                control={control}
                                rules={{
                                    required: t('page:users.form.passwordNotEmpty'),
                                    minLength: {
                                        value: 8,
                                        message: t('page:users.form.passwordMinLength8')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type={showPassword ? 'text' : 'password'}
                                            maxLength={32}
                                            placeholder={t('common:password')}
                                            className={`${errors.password && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                        />
                                        <span
                                            onClick={() => setShowPassword(!showPassword)}
                                            className="material-symbols-outlined text-black cursor-pointer hover:text-gray-600 float-right mr-2 mt-[-33px]">
                                            {showPassword ? 'visibility' : 'visibility_off'}
                                        </span>

                                        {errors.password && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.password.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='confirm_password' className="block text-sm font-semibold leading-6 text-gray-600">
                            {t('page:users.form.confirmPassword')}<span className="text-status-critical">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="confirm_password"
                                control={control}
                                rules={{
                                    required: t('page:users.form.passwordNotEmpty'),
                                    minLength: {
                                        value: 8,
                                        message: t('page:users.form.passwordMinLength8')
                                    },
                                    validate: validateConfirmPassword
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            maxLength={32}
                                            placeholder={t('common:password')}
                                            className={`${errors.confirm_password && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                        />
                                        <span
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            className="material-symbols-outlined text-black cursor-pointer hover:text-gray-600 float-right mr-2 mt-[-33px]">
                                            {showConfirmPassword ? 'visibility' : 'visibility_off'}
                                        </span>

                                        {errors.confirm_password && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.confirm_password.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor='phone' className="block text-sm font-semibold leading-6 text-gray-600">
                            {t('common:phone')}<span className="text-status-critical">*</span>
                        </label>
                        <div className="mt-0.5">
                            <Controller
                                name="phone"
                                control={control}
                                rules={{
                                    required: t('page:users.form.phoneNotEmpty'),
                                    minLength: {
                                        value: 10,
                                        message: t('page:users.form.phoneMinLength10')
                                    },
                                }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type='text'
                                            maxLength={16}
                                            placeholder={t('common:phone')}
                                            className={`${errors.phone && 'ring-text-status-critical ring-2 outline-text-status-critical focus:ring-text-status-critical'} block w-full rounded-md border-0 px-1.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                        />
                                        {errors.phone && <p className="text-status-critical mt-1 ml-1 text-xs">{errors.phone.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    {/* <div>
                        <label htmlFor='address' className="block text-sm font-semibold leading-6 text-gray-600">
                            Address
                        </label>
                        <div className="mt-0.5">
                            <input {...register('address', {
                                required: true
                            })}
                                type='text'
                                placeholder='Address'
                                required
                                className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6'
                            />
                            {errors.address && <p className="text-status-critical text-xs">Please enter Address.</p>}

                        </div>
                    </div>
                    <div>
                        <label htmlFor='city' className="block text-sm font-semibold leading-6 text-gray-600">
                            City
                        </label>
                        <div className="mt-0.5">
                            <input {...register('city', {
                                required: true
                            })}
                                type='text'
                                placeholder='City'
                                required
                                className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6'
                            />
                            {errors.city && <p className="text-status-critical text-xs">Please enter city.</p>}

                        </div>
                    </div>
                    <div>
                        <label htmlFor='zip' className="block text-sm font-semibold leading-6 text-gray-600">
                            Zipcode
                        </label>
                        <div className="mt-0.5">
                            <input {...register('zip', {
                                required: true
                            })}
                                type='text'
                                placeholder='Zipcode'
                                required
                                className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6'
                            />
                            {errors.zip && <p className="text-status-critical text-xs">Please enter zip.</p>}

                        </div>
                    </div>
                    <div>
                        <label htmlFor='country' className="block text-sm font-semibold leading-6 text-gray-600">
                            Country
                        </label>
                        <div className="mt-0.5">
                            <input {...register('country', {
                                required: true
                            })}
                                type='text'
                                placeholder='Country'
                                required
                                className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6'
                            />
                            {errors.country && <p className="text-status-critical text-xs">Please enter country.</p>}

                        </div>
                    </div> */}
                    {error && (<p className="font-bold text-sm text-center text-status-critical">{error}</p>)}
                    <div>
                        <PrimaryButton
                            text={t('common:register')}
                            size={'medium'}
                            fullWidth={true}
                            font={'medium'}
                        />
                    </div>
                </form>
            </div>

        </>
    )
}

export default CreateUserForm;