import React, { useEffect, useState } from 'react'
import { Grow } from '@mui/material';
import PrimaryButton from '../../../../atoms/primary-button';
import { LogTemplateDto } from '../../../../../services/tenant/log-template/dto/log-template.dto';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LogTypes } from '../../../../../services/enum/log-types';
import Modal from '../../../../molecules/modal';
import { ContractorLogAccessDto } from '../../../../../services/tenant/contractor-log-user-access/dto/contractor-log-access.dto';
import CreateCustomLogEntryExternalForm from '../../../forms/log-entry-form/create-custom-log-entry-external';
import LogsTableContractor from '../../../log-components/log-entries/logs-table-contractor';
import { DisplayNameType } from '../../../log-components/log-structure/log-entries';

type Props = {
    setSelectedTenantLog: (template: ContractorLogAccessDto | null) => void;
    selectedTenantLog: ContractorLogAccessDto;
    logTemplate: LogTemplateDto;
    displayName?: DisplayNameType;
}

const LogEntriesContractor = ({
    setSelectedTenantLog,
    selectedTenantLog,
    logTemplate,
    displayName,
}: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [showCreateLog, setShowCreateLog] = useState<boolean>(false);

    return (
        <>
            {selectedTenantLog && (
                <Grow in={selectedTenantLog ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                    <div className="w-full text-black">

                        <div className="border-b border-primary-blue-5 pb-2">
                            <div className="">
                                <div className="flex items-center gap-x-8">
                                    <button
                                        className="material-symbols-outlined p-0.5 bg-primary-blue-5 rounded-md"
                                        style={{ fontSize: '1.5rem' }}
                                        onClick={() => setSelectedTenantLog(null)}
                                    >chevron_left</button>
                                    <h2 className="text-xl sm:text-2xl xl:text-3xl font-bold capitalize">
                                        {displayName?.logName + ' - ' + displayName?.pboName}
                                    </h2>
                                </div>
                            </div>

                            <div className="flex items-center gap-x-3 mt-10 text-xs font-semibold">
                                <button
                                    className="pb-0.5"
                                    onClick={() => setSelectedTenantLog(null)}>
                                    {t('common:logs')}
                                </button>
                                <span
                                    className="material-symbols-outlined py-1"
                                    style={{ fontSize: '1rem' }}>
                                    chevron_right
                                </span>
                                <button
                                    className="pb-0.5">
                                    {displayName?.pboName}
                                </button>
                                <span
                                    className="material-symbols-outlined py-1"
                                    style={{ fontSize: '1rem' }}>
                                    chevron_right
                                </span>
                                <button
                                    className="pb-0.5 capitalize text-primary-blue">
                                    {selectedTenantLog.log_template_name.split('_').join(' ')}
                                </button>
                            </div>
                        </div>


                        <div className="flex justify-end mt-5">
                            <PrimaryButton
                                text={t('page:logs.createLog')}
                                size={'medium'}
                                wide={true}
                                startAdornment={'add'}
                                onClick={() => setShowCreateLog(!showCreateLog)}
                            />
                        </div>
                        <div>
                            <LogsTableContractor
                                selectedTenantLog={selectedTenantLog}
                                displayName={displayName}
                                logTemplate={logTemplate}
                            />
                        </div>

                        <Modal isOpen={showCreateLog} close={() => setShowCreateLog(false)} title={displayName?.logName + ' - ' + displayName?.pboName} size='md' titleAlign='center'>
                            <CreateCustomLogEntryExternalForm tenantLog={selectedTenantLog} logTemplate={logTemplate} />
                        </Modal>
                    </div>
                </Grow>
            )}
        </>
    )
}

export default LogEntriesContractor