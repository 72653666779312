import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';

type Props = {}

const NotFoundPage = (props: Props) => {
    const { t } = useTranslation();

    useEffect(() => {

    }, [])

    return (
        <div>
            <div className="text-center my-2 mt-10 w-10/12 mx-auto h-[50vh] bg-primary-blue-5 rounded-xl p-3">
                <span className="material-symbols-outlined text-yellow-600 mt-10" style={{ fontSize: '4rem' }}>assistant_direction</span>
                <div className="sm:px-10">
                    <p className="text-2xl my-5 font-bold text-gray-700">{t('common:pageNotFound')}</p>
                    <p className="text-base my-5 font-bold text-gray-700">{t('common:pageNotFoundInfo')}</p>
                </div>
            </div>

        </div>
    )
}

export default NotFoundPage;