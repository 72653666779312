import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../../redux/store'
import { reAuthAPI } from '../../re-auth/re-auth';
import { LogTemplateDto } from './dto/log-template.dto';
import { PageDto } from '../../dto/page.dto';
import { PageOptionsDto } from '../../dto/page-options.dto';
import { LogCategoriesDto } from '../../dto/log-categories.dto';
import { CreateLogTemplateDto } from './dto/create-log-template.dto';
import { UpdateLogTemplateDto } from './dto/update-log-template.dto';
import { UserLogTemplateDto } from './dto/user-log-template.dto';


export const logTemplateAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Create custom log template
        createCustomLogTemplate: builder.mutation<any, CreateLogTemplateDto>({
            query: (data) => ({
                url: '/logs/log-templates',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['LogTemplate']
        }),
        // Get logTemplate records
        getLogTemplates: builder.query<PageDto<LogTemplateDto>, PageOptionsDto>({
            query: (options) => {
                const queryParams = [];
                if (options.page !== undefined) queryParams.push(`page=${options.page}`);
                if (options.take !== undefined) queryParams.push(`take=${options.take}`);
                if (options.order !== undefined) queryParams.push(`order=${options.order}`);
                if (options.search !== undefined && options.search.length > 0) queryParams.push(`search=${options.search}`);
                if (options.sortBy !== undefined) queryParams.push(`sort_by=${options.sortBy}`)
                if (options.filter !== undefined) queryParams.push(`filter=${options.filter}`);

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    url: `/logs/log-templates${queryString}`,
                    method: 'GET',
                }
            },
            providesTags: ['LogTemplate']
        }),
        // Get one logTemplate by id
        getLogTemplate: builder.query<LogTemplateDto, string>({
            query: (id) => ({
                url: '/logs/log-templates/' + id,
                method: 'GET',
            }),
            providesTags: ['LogTemplate']
        }),
        // Get logTemplates by ids
        getLogTemplatesByIds: builder.query<LogTemplateDto[], string[]>({
            query: (logTemplateIds) => ({
                url: '/logs/log-templates/ids?ids=' + logTemplateIds,
                method: 'GET',
            }),
            providesTags: ['LogTemplate']
        }),
        // Get all logTemplate records by property id joined with property log access
        getLogTemplatesByPropertyId: builder.query<LogTemplateDto[], string>({
            query: (propertyId) => ({
                url: '/logs/log-templates/property/' + propertyId,
                method: 'GET',
            }),
            providesTags: ['LogTemplate']
        }),
        // Get unique log categories for found log templates
        getLogTemplatesUniqueCategories: builder.query<LogCategoriesDto[], void>({
            query: () => ({
                url: '/logs/log-templates/categories/unique',
                method: 'GET',
            }),
            providesTags: ['LogTemplate']
        }),
        // Update custom log template
        updateCustomLogTemplate: builder.mutation<any, UpdateLogTemplateDto>({
            query: (data) => ({
                url: '/logs/log-templates/' + data.id,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['LogTemplate', 'Logs']
        }),
        // Get logTemplates by users
        getLogTemplatesByUserIds: builder.query<UserLogTemplateDto[], string[]>({
            query: (userIds) => ({
                url: `/logs/log-templates/users?ids=${userIds}`,
                method: 'GET',
            }),
            providesTags: ['LogTemplate'],
        }),
        // Get logTemplates by users
        getLogTemplatesByTemplateIds: builder.query<UserLogTemplateDto[], string[]>({
            query: (logTemplateIds) => ({
                url: `/logs/log-templates/templates?ids=${logTemplateIds}`,
                method: 'GET',
            }),
            providesTags: ['LogTemplate'],
        }),
    })
})

export const {
    useCreateCustomLogTemplateMutation,
    useGetLogTemplatesQuery,
    useGetLogTemplateQuery,
    useGetLogTemplatesByIdsQuery,
    useGetLogTemplatesByPropertyIdQuery,
    useGetLogTemplatesUniqueCategoriesQuery,
    useUpdateCustomLogTemplateMutation,
    useGetLogTemplatesByUserIdsQuery,
    useGetLogTemplatesByTemplateIdsQuery,
} = logTemplateAPI;