import { debounce } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Order } from '../../../../services/enum/order';
import { PageDto } from '../../../../services/dto/page.dto';
import Select, { MultiValue } from 'react-select';
import { SelectCustomStyles } from '../../../atoms/select-custom-styles/select-custom-styles';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetUserQuery, useGetUsersQuery } from '../../../../services/tenant/user/user.service';
import { UserDto } from '../../../../services/tenant/user/dto/user.dto';
import InputCheckbox from '../../../atoms/select-checkbox';

type SelectOption = {
    label: string;
    value: string;
    data: UserDto;
}

type Props = {
    handleSelectedUsers: (value: SelectOption[]) => void;
    noneSelected?: boolean;
    id?: string; // Id passed to match on user
    zIndex?: string;
    reset?: boolean;
}

const UserSelectMulti = ({
    handleSelectedUsers,
    noneSelected,
    id,
    zIndex,
    reset = false
}: Props) => {
    const { t } = useTranslation();

    const [allOptions, setAllOptions] = useState<SelectOption[]>([]);
    const [search, setSearch] = useState<string>();
    const [page, setPage] = useState<number>(1);

    const debouncedSearch = useRef(debounce((search: string) => setSearch(search), 500)).current;

    // Get users
    const { data: users } = useGetUsersQuery({
        take: 15,
        page: page,
        order: Order.ASC,
        sortBy: 'name',
        search: search
    });

    const [selectKey, setSelectKey] = useState<number>(0);

    useEffect(() => {
        if (reset) {
            handleSelectedUsers([]);
            setSelectKey(prevKey => prevKey + 1); // Reset select component
        }
    }, [reset])

    useEffect(() => {
        if (users) {
            // INIT
            if (allOptions.length === 0) {
                initOptions(users);
            }
            // SEARCH
            else if (search) {
                handleSearchOptions(search, users);
            }
            // PAGINATION
            else {
                handlePagination(users);
            }
        }
    }, [users])

    // Set initial options and selected options
    const initOptions = (users: PageDto<UserDto>) => {
        const allOptions: SelectOption[] = [];
        if (users && allOptions.length === 0) {
            users.data.forEach(user => {
                let tempObj: SelectOption = {
                    label: user.name,
                    value: user.id,
                    data: user,
                };
                allOptions.push(tempObj);
            })
            setAllOptions(allOptions);
        }
    }

    // Handle options when search query is provided
    const handleSearchOptions = (search: string, users: PageDto<UserDto>) => {
        if (users && search) {
            setPage(1);
            const options: SelectOption[] = users.data.map(user => ({
                label: user.name,
                value: user.id,
                data: user,
            }));
            setAllOptions([...options]);
        }
    }

    // Handling options with pagination
    const handlePagination = (users: PageDto<UserDto>) => {
        if (users) {
            // Get existing options
            const existingOptions = [...allOptions];

            // Remove duplicates
            const uniqueArr = existingOptions.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.value === value.value
                ))
            )

            // Add options
            users.data.forEach(user => {
                const obj = {
                    label: user.name,
                    value: user.id,
                    data: user,
                }
                // Make sure no duplicates are added
                if (!uniqueArr.some(e => e.value === obj.value)) {
                    uniqueArr.push(obj);
                }
            });

            setAllOptions(uniqueArr);
        }
    }

    // Handle scroll event to fetch more options
    const handleScroll = (event: any) => {
        if (users && users.meta.hasNextPage) {
            if (!search) {
                setPage(page ? page + 1 : 1);
            }
        }
    }

    const handleChange = (selectOptions: MultiValue<SelectOption>) => {
        // Make copy since passed array is readonly
        const selectOptionsArr: SelectOption[] = [...selectOptions];
        handleSelectedUsers(selectOptionsArr)
    }    

    return (
        <Select
            key={selectKey}
            className={`${zIndex ? `z-${zIndex}` : ''} capitalize md:text-sm ${(reset === false || noneSelected) ? 'bg-white' : 'bg-primary-blue-5' }`}
            options={allOptions}
            hideSelectedOptions={false}
            onChange={(val) => handleChange(val)}
            maxMenuHeight={200}
            placeholder={t('common:select')}
            noOptionsMessage={() => t('page:properties.noUsersFound')}
            onInputChange={(value) => debouncedSearch(value)}
            captureMenuScroll={true}
            onMenuScrollToBottom={(val) => handleScroll(val)}
            filterOption={null}
            styles={SelectCustomStyles}
            isMulti={true}
            closeMenuOnSelect={false}
            components={{
                // @ts-ignore
                Option: InputCheckbox
            }}
        />
    )
}

export default UserSelectMulti;