import { UserInviteDto } from './dto/user-invite.dto'
import { reAuthAPI } from '../../re-auth/re-auth';
import { PageDto } from '../../dto/page.dto';
import { PageOptionsDto } from '../../dto/page-options.dto';

export const userInviteAPI = reAuthAPI.injectEndpoints({
    endpoints: (builder) => ({
        // Get user invites by tenant id
        getUserInvitesByTenantId: builder.query<PageDto<UserInviteDto>, { tenantId: string, pageOptions: PageOptionsDto }>({
            query: (options) => {
                const queryParams = [];
                if (options.pageOptions.page !== undefined) queryParams.push(`page=${options.pageOptions.page}`);
                if (options.pageOptions.take !== undefined) queryParams.push(`take=${options.pageOptions.take}`);
                if (options.pageOptions.order !== undefined) queryParams.push(`order=${options.pageOptions.order}`);
                if (options.pageOptions.search !== undefined && options.pageOptions.search.length > 0) queryParams.push(`search=${options.pageOptions.search}`);
                if (options.pageOptions.sortBy !== undefined) queryParams.push(`sort_by=${options.pageOptions.sortBy}`)

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    credentials: 'include',
                    url: `/catalog/user-invite/${options.tenantId}${queryString}`,
                    method: 'GET'
                }
            },
            providesTags: ['UserInvite']
        }),
        // Delete user invite record
        deleteUserInvite: builder.mutation<any, string>({
            query: (id) => ({
                url: '/catalog/user-invite',
                method: 'DELETE',
                body: { id: id },
            }),
            invalidatesTags: ['UserInvite']
        }),
    }),
})

export const {
    useGetUserInvitesByTenantIdQuery,
    useDeleteUserInviteMutation
} = userInviteAPI