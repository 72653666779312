import { reAuthAPI } from "../../re-auth/re-auth"

export const managementReAuthApiSlice = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Resend email token to verify account
        resendEmailVerification: builder.mutation<any, { email: string, name: string, tenant_id?: string, user_id?: string}>({
            query: (payload) => ({
                url: '/manage/resend-email-verification',
                method: 'POST',
                body: payload,
            })
        }),
    })
})

export const {
    useResendEmailVerificationMutation,
} = managementReAuthApiSlice;