import { UserDto } from './dto/user.dto';
import { reAuthAPI } from '../../re-auth/re-auth';
import { UserTenantDto } from './dto/user-tenant.dto';
import { CreateUserInviteDto } from './dto/create-user-invite.dto';
import { CreateUserDto } from './dto/create-user.dto';
import { UpdateUserDto } from './dto/update-user.dto';
import { ChangePasswordDto } from './dto/change-password.dto';
import { PageOptionsDto } from '../../dto/page-options.dto';
import { PageDto } from '../../dto/page.dto';
import { SendUserInviteDto } from './dto/send-user-invite.dto';

export const userAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Create new user
        createUser: builder.mutation<any, CreateUserDto>({
            query: (user) => ({
                url: '/users',
                method: 'POST',
                body: user,
            }),
        }),
        // Create new user as admin
        createUserAsAdmin: builder.mutation<any, CreateUserDto>({
            query: (user) => ({
                url: '/users/admin',
                method: 'POST',
                body: user,
            }),
        }),
        // Get user and related tenant data
        getUserMe: builder.query<UserTenantDto, void>({
            query: () => ({
                url: '/users/current/me',
                method: 'GET',
            }),
            providesTags: ['UserMe'],
        }),
        // Get users paginated
        getUsers: builder.query<PageDto<UserDto>, PageOptionsDto>({
            query: (options) => {
                const queryParams = [];
                if (options.page !== undefined) queryParams.push(`page=${options.page}`);
                if (options.take !== undefined) queryParams.push(`take=${options.take}`);
                if (options.order !== undefined) queryParams.push(`order=${options.order}`);
                if (options.search !== undefined && options.search.length > 0) queryParams.push(`search=${options.search}`);
                if (options.sortBy !== undefined) queryParams.push(`sort_by=${options.sortBy}`)

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    url: `/users/${queryString}`,
                    method: 'GET',
                }
            },
            providesTags: ['Users'],
        }),
         // Get users by given ids 
         getUsersByIds: builder.query<UserDto[], string[]>({
            query: (userIds) => ({
                url: '/users/ids?ids=' + userIds,
                method: 'GET',
            }),
            providesTags: ['Users']
        }),
        // Get one by id
        getUser: builder.query<UserDto, string>({
            query: (userId) => ({
                url: '/users/' + userId,
                method: 'GET',
            })
        }),
        // Get all user records by property id joined with property user access
        getUsersByPropertyId: builder.query<UserDto[], string>({
            query: (propertyId) => ({
                url: '/users/property/' + propertyId,
                method: 'GET',
            }),
            providesTags: ['Users']
        }),
        // Get number of users for given tenant
        getUsersCount: builder.query<number, void>({
            query: () => ({
                url: 'users/count',
                method: 'GET',
            }),
            providesTags: ['Users'],
        }),
        // Update one user
        updateUser: builder.mutation<any, UpdateUserDto>({
            query: (userData) => ({
                url: 'users/' + userData.id,
                method: 'PUT',
                body: userData,
            }),
        }),
        // Update many users
        updateUsers: builder.mutation<any, UpdateUserDto[]>({
            query: (userData) => ({
                url: '/users',
                method: 'PUT',
                body: userData,
            }),
            invalidatesTags: ['Users']
        }),
        // Delete user
        deleteUser: builder.mutation<any, { user_id: string }>({
            query: (payload) => ({
                url: 'users',
                method: 'DELETE',
                body: payload,
            }),
            invalidatesTags: ['Users']
        }),
        // Get user and related tenant data
        inviteUser: builder.mutation<any, SendUserInviteDto>({
            query: (data) => ({
                url: 'users/invite',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['UserInvite']
        }),
        // Register invited user
        createUserInvited: builder.mutation<any, { userData: CreateUserInviteDto, token: string }>({
            query: ({ userData, token }) => ({
                url: 'users/invite/create/' + token,
                method: 'POST',
                body: userData,
            }),
            // invalidatesTags: ['Users']
        }),
        // Change user password
        changePassword: builder.mutation<any, ChangePasswordDto>({
            query: (payload) => ({
                url: 'users/password/' + payload.id,
                method: 'PUT',
                body: payload,
            }),
        }),
        // Get users by logAccess
        getUsersByLogAccess: builder.query<PageDto<UserDto>, { options: PageOptionsDto, logTemplateId: string }>({
            query: ({ options, logTemplateId }) => {
                const queryParams = [];
                if (options.page !== undefined) queryParams.push(`page=${options.page}`);
                if (options.take !== undefined) queryParams.push(`take=${options.take}`);
                if (options.order !== undefined) queryParams.push(`order=${options.order}`);
                if (options.search !== undefined && options.search.length > 0) queryParams.push(`search=${options.search}`);
                if (options.sortBy !== undefined) queryParams.push(`sort_by=${options.sortBy}`)

                // Contruct query string
                const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

                return {
                    url: `/users/log-access/${logTemplateId}${queryString}`,
                    method: 'GET',
                }
            },
            providesTags: ['Users'],
        }),
    })
})

export const {
    useCreateUserMutation,
    useCreateUserAsAdminMutation,
    useGetUsersQuery,
    useGetUsersByIdsQuery,
    useGetUserQuery,
    useGetUserMeQuery,
    useGetUsersByPropertyIdQuery,
    useGetUsersCountQuery,
    useUpdateUserMutation,
    useUpdateUsersMutation,
    useDeleteUserMutation,
    useInviteUserMutation,
    useCreateUserInvitedMutation,
    useChangePasswordMutation,
    useGetUsersByLogAccessQuery,
} = userAPI;