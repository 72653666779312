import { reAuthAPI } from "../../re-auth/re-auth";
import { RevokeLogUserAccessDto } from "./dto/revoke-log-user-access.dto";
import { UpdateRevokeLogUserAccessDto } from "./dto/update-revoke-log-user-access.dto";


export const revokeLogUserAccessAPI = reAuthAPI.injectEndpoints({
    endpoints: builder => ({
        // Get all revoke log user access relations by given id (logTemplateId or userId)
        getAllRevokeLogUserAccessById: builder.query<RevokeLogUserAccessDto[], string>({
            query: (id) => ({
                url: '/revoke-log-user-access/id/' + id,
                method: 'GET',
            }),
            providesTags: ['RevokeLogUserAccess']
        }),
        // Get all revoke log user access relations by ids (logTemplateIds or userIds)
        getAllRevokeLogUserAccessByIds: builder.query<RevokeLogUserAccessDto[], string[]>({
            query: (ids) => ({
                url: '/revoke-log-user-access/ids?ids=' + ids,
                method: 'GET',
            }),
            providesTags: ['RevokeLogUserAccess']
        }),
        // Update many revoke log user access relations
        updateManyRevokeLogUserAccess: builder.mutation<any, UpdateRevokeLogUserAccessDto[]>({
            query: (revokeLogUserAccess) => ({
                url: '/revoke-log-user-access',
                method: 'PUT',
                body: revokeLogUserAccess
            }),
            invalidatesTags: ['RevokeLogUserAccess', 'Logs', 'LogTemplate']
        }),
    })
})

export const {
    /* Revoke Log User Access */
    useGetAllRevokeLogUserAccessByIdQuery,
    useGetAllRevokeLogUserAccessByIdsQuery,
    useUpdateManyRevokeLogUserAccessMutation,

} = revokeLogUserAccessAPI;