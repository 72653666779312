import { debounce } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Order } from '../../../../services/enum/order';
import { PageDto } from '../../../../services/dto/page.dto';
import { SubsidiaryDto } from '../../../../services/tenant/subsidiary/dto/subsidiary.dto';
import Select from 'react-select';
import { SelectCustomStyles } from '../../../atoms/select-custom-styles/select-custom-styles';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetSubsidiariesQuery, useGetSubsidiaryQuery } from '../../../../services/tenant/subsidiary/subsidiary.service';

type SelectOption = {
    label: string;
    value: string;
}

type Props = {
    handleSelectedSubsidiary: (value: { label: string, value: string }) => void;
    id?: string; // Id passed to match on subsidiary
    currentSubsidiaryId?: string; // When handling subsidiary_parent, don't show current subsidiary in select
    zIndex?: string;
    reset?: boolean;
}

const SubsidiarySelect = ({
    handleSelectedSubsidiary,
    id,
    currentSubsidiaryId,
    zIndex,
    reset
}: Props) => {
    const { t } = useTranslation();

    const [allOptions, setAllOptions] = useState<SelectOption[]>([]);
    const [search, setSearch] = useState<string>();
    const [page, setPage] = useState<number>(1);

    const debouncedSearch = useRef(debounce((search: string) => setSearch(search), 500)).current;

    // Get subsidiaries
    const { data: subsidiaries } = useGetSubsidiariesQuery({
        take: 15,
        page: page,
        order: Order.ASC,
        sortBy: 'company_name',
        search: search
    });

    // If id is passed, get subsidiary
    const [skip, setSkip] = useState<any>(skipToken);
    const { data: matchedSubsidiary } = useGetSubsidiaryQuery(skip);
    const [selected, setSelected] = useState<SelectOption>();

    useEffect(() => {
        if (id) setSkip(id);
    }, [id])

    useEffect(() => {
        if (reset)
            setSelected({ label: t('common:select'), value: ''})
        
    }, [reset])

    useEffect(() => {
        if (subsidiaries) {
            // Filter out current subsidiary when handling subsidiary_parent
            let filteredSubsidiaries: SubsidiaryDto[] = [];
            if (currentSubsidiaryId) {
                filteredSubsidiaries = subsidiaries.data.filter(e => e.id !== currentSubsidiaryId);
            }
            else {
                filteredSubsidiaries = subsidiaries.data;
            }

            // INIT
            if (allOptions.length === 0) {
                initOptions(filteredSubsidiaries);
            }
            // SEARCH
            else if (search) {
                handleSearchOptions(search, filteredSubsidiaries);
            }
            // PAGINATION
            else {
                handlePagination(filteredSubsidiaries);
            }
        }
    }, [subsidiaries])

    // Set initial options and selected options
    const initOptions = (subsidiaries: SubsidiaryDto[]) => {
        const allOptions: SelectOption[] = [];
        if (subsidiaries && allOptions.length === 0) {
            allOptions.push({
                label: '-',
                value: ''
            })
            subsidiaries.forEach(subsidiary => {
                let tempObj: SelectOption = {
                    label: subsidiary.company_name,
                    value: subsidiary.id,
                };
                allOptions.push(tempObj);
            })
            setAllOptions(allOptions);
        }
    }

    // Handle options when search query is provided
    const handleSearchOptions = (search: string, subsidiaries: SubsidiaryDto[]) => {
        if (subsidiaries && search) {
            setPage(1);
            const options: SelectOption[] = subsidiaries.map(subsidiary => ({
                label: subsidiary.company_name,
                value: subsidiary.id,
            }));
            setAllOptions([...options]);
        }
    }

    // Handling options with pagination
    const handlePagination = (subsidiaries: SubsidiaryDto[]) => {
        if (subsidiaries) {
            // Get existing options
            const existingOptions = [...allOptions];
            existingOptions.push({
                label: '-',
                value: ''
            })

            // Remove duplicates
            const uniqueArr = existingOptions.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.value === value.value
                ))
            )

            // Add options
            subsidiaries.forEach(subsidiary => {
                const obj = {
                    label: subsidiary.company_name,
                    value: subsidiary.id,
                }
                // Make sure no duplicates are added
                if (!uniqueArr.some(e => e.value === obj.value)) {
                    uniqueArr.push(obj);
                }
            });
            setAllOptions(uniqueArr);
        }
    }

    // Handle scroll event to fetch more options
    const handleScroll = (event: any) => {
        if (subsidiaries && subsidiaries.meta.hasNextPage) {
            if (!search) {
                setPage(page ? page + 1 : 1);
            }
        }
    }

    const handleChange = (selectOption: { value: string, label: string }) => {
        setSelected(selectOption);
        handleSelectedSubsidiary(selectOption);
    }

    return (
        <Select
            className={`${zIndex ?? zIndex} capitalize md:text-sm`}
            value={{
                label: selected ? selected.label : matchedSubsidiary ? matchedSubsidiary.company_name : t('common:select'),
                value: selected ? selected.value : matchedSubsidiary ? matchedSubsidiary.id : ''
            }}
            options={allOptions}
            hideSelectedOptions={false}
            onChange={(val) => handleChange(val ? val : { label: '', value: '' })}
            maxMenuHeight={200}
            placeholder={t('common:select')}
            noOptionsMessage={() => t('page:company.noSubsidiariesFound')}
            onInputChange={(value) => debouncedSearch(value)}
            captureMenuScroll={true}
            onMenuScrollToBottom={(val) => handleScroll(val)}
            filterOption={null}
            styles={SelectCustomStyles}
        />
    )
};

export default SubsidiarySelect;