import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import toCamelCase from '../../../utils/to-camel-case';
import Checkbox from '../../atoms/checkbox';

type ColumnType = {
    id: string;
    label: string;
    selected: boolean;
}

type Props = {
    tableColumns: string[];
    tableType: string;
    handleColumnsToDisplay: (columns: string[]) => void;
}

const FilterTableColumns = ({
    tableColumns,
    tableType,
    handleColumnsToDisplay
}: Props) => {
    const { t } = useTranslation();

    const [columns, setColumns] = useState<ColumnType[]>();

    useEffect(() => {
        if (tableColumns && tableColumns.length > 0 && tableType) {
            setInitialState();
        }
    }, [tableColumns, tableType])

    const setInitialState = () => {
        const storedItem = localStorage.getItem(tableType);
        const storedColumns: ColumnType[] = storedItem ? JSON.parse(storedItem) : null;

        // If col length is the same in LocalStorage no change in cols have been made, proceed using the cols from LS
        if (storedColumns && storedColumns.length === tableColumns.length) {
            const tempCols: ColumnType[] = [];
            storedColumns.forEach((col, idx) => {
                tempCols.push({
                    id: col.id,
                    label: col.label,
                    selected: col.selected
                })
            })
            setColumns(tempCols);
        }
        else {
            const tempCols: ColumnType[] = [];
            tableColumns.forEach((col, idx) => {
                tempCols.push({
                    id: col,
                    label: col,
                    selected: idx < 4 ? true : false
                })
            })
            setColumns(tempCols);
            localStorage.setItem(tableType, JSON.stringify(tempCols));
        }
    }

    useEffect(() => {
        const storedItem = localStorage.getItem(tableType);
        const storedColumns: ColumnType[] = storedItem ? JSON.parse(storedItem) : null;

        // If col length is the same in LocalStorage no change in cols have been made, proceed using the cols from LS
        if (storedColumns && storedColumns.length === tableColumns.length) {
            const tempCols: ColumnType[] = [];
            storedColumns.forEach((col, idx) => {
                tempCols.push({
                    id: col.id,
                    label: col.label,
                    selected: col.selected
                })
            })
            handleColumnsToDisplay(tempCols.filter(col => col.selected).map(col => col.label));
        }
        else {
            const tempCols: ColumnType[] = [];
            tableColumns.forEach((col, idx) => {
                tempCols.push({
                    id: col,
                    label: col,
                    selected: idx < 4 ? true : false
                })
            })
            handleColumnsToDisplay(tempCols.filter(col => col.selected).map(col => col.label));
        }
    }, [])

    const handleToggleSelectedColumn = (columnId: string) => {
        const updatedColumns = columns?.map(column => {
            if (column.id === columnId) {
                return { ...column, selected: !column.selected }
            }
            return column;
        })
        setColumns(updatedColumns);
        localStorage.setItem(tableType, JSON.stringify(updatedColumns));
        if (updatedColumns) handleColumnsToDisplay(updatedColumns.filter(col => col.selected).map(col => col.label));
    }

    return (
        <div>
            {columns && columns.length > 0 && (
                columns.map((col, idx) => {
                    return (
                        <div
                            key={idx}
                            onClick={() => handleToggleSelectedColumn(col.id)}
                            className="inline-block relative rounded-md md:p-1 cursor-pointer my-1 mx-1 first:ml-0">
                            <Checkbox
                                label={col.label === 'created_at' ? t('common:createdAt') : t(`common:${toCamelCase(col.label)}`)}
                                size={'md'}
                                checked={col.selected ? col.selected : false}
                            />
                            {/* <span key={idx} className="text-xs font-semibold capitalize ml-[-3px]">{col.label === 'created_at' ? t('common:createdAt') : t(`common:${toCamelCase(col.label)}`)}</span> */}
                        </div>
                    )
                })
            )}
        </div>
    )
}

export default FilterTableColumns