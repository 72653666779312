import { NotificationManager } from 'react-notifications';
import { useState } from "react";
import { useDeleteFileMutation, useUploadFileMutation } from "../../../../services/tenant/media/media.service";
import { compressImage } from "../../../../utils/compress-image";
import { useTranslation } from "react-i18next";
import { UpdateTenantDto } from "../../../../services/tenant/tenant/dto/update-tenant.dto";
import { useUpdateTenantMutation } from "../../../../services/tenant/tenant/tenant.service";
import { getCurrentUser } from "../../../../redux/slices/user.slice";
import { useAppSelector } from "../../../../redux/hooks";
import { TenantStateType } from '../../../../services/dto/tenant-state-type';
import { Roles } from '../../../../services/enum/roles';
import { useDispatch } from 'react-redux';
import { userAPI } from '../../../../services/tenant/user/user.service';

type Props = {
  tenant?: TenantStateType
}

const CompanyFileUpload = ({ tenant }: Props) => {
  const { t } = useTranslation();
  const { currentUser } = useAppSelector(getCurrentUser);
  const dispatch = useDispatch();

  const [uploadFile, response] = useUploadFileMutation();
  const [updateTenant, { isLoading, error }] = useUpdateTenantMutation();
  const [deleteFile] = useDeleteFileMutation();

  const [selectedFile, setSelectedFile] = useState<FileList | null>();
  const [fileError, setFileError] = useState<string>('');

  const [showDeleteBtn, setShowDeleteBtn] = useState<boolean>(false);
  const [showUploadBtn, setShowUploadBtn] = useState<boolean>(false);
  // const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  // Upload tenant logo
  const submitFile = async (files: FileList) => {

    if (tenant && tenant.id) {
      if (files && files.length > 0) {
        setSelectedFile(undefined);
        const formData = new FormData();
        const file = files[0];
        if ((file.size / 1024 / 1024) > 10) {
          setFileError(t('common:form.selectedFileMax10MB'));
        }
        else {
          setFileError('');

          const result = await compressImage(file, 'avatar', 0.8, 'contain');
          if (result.success) {
            formData.append('uploadType', 'logo');
            formData.append('file', result.data);
            formData.append('existingFileName', tenant.logo ? tenant.logo : '');
            formData.append('originalName', file.name);

            // Upload file
            try {
              const response = await uploadFile(formData).unwrap();
              if (response.success) {

                // Update tenant data with new file name
                const updateTenantDto: UpdateTenantDto = {
                  id: tenant.id,
                  logo: response.data, // File path to be saved
                };

                const tenantUpdateResponse = await updateTenant(updateTenantDto).unwrap();
                if (tenantUpdateResponse && tenantUpdateResponse.success) {
                  NotificationManager.success(t('common:server.file_uploaded'));
                  dispatch(userAPI.util.invalidateTags(['UserMe']));
                }
                else {
                  NotificationManager.error(t('comon:server.file_not_uploaded'));
                }
              }
            }
            catch (error) {
              NotificationManager.error(t('comon:server.file_not_uploaded'));
            }
          }
          else {
            setFileError(t(`common:${result.data}`));
          }
        }
      }
    }
  }


  const handleDeleteImg = async (filePath?: string) => {
    if (!filePath || !tenant)
      return

    try {
      const response = await deleteFile({ filePath }).unwrap();
      if (response.success) {
        // Remove filepath from user
        const updateTenantDto: UpdateTenantDto = {
          id: tenant.id,
          logo: ''
        };

        const tenantUpdateResponse = await updateTenant(updateTenantDto).unwrap();
        if (tenantUpdateResponse && tenantUpdateResponse.success) {
          NotificationManager.success(t(`common:server.file_deleted`));
          dispatch(userAPI.util.invalidateTags(['UserMe']));
        }
        else {
          NotificationManager.error(t(`common:server.file_not_deleted`))
        }
      }
    }
    catch (error) {
      NotificationManager.error(t(`common:server.file_not_deleted`))
    }
  };

  return (
    <div className="w-full text-center">
      {(currentUser.role === Roles.Admin || currentUser.role === Roles.Superadmin) && (
        <div
          className="flex justify-center items-center w-20 md:w-28 h-20 md:h-28 rounded-full mx-auto border relative"
          onMouseOver={() => setShowDeleteBtn(true)}
          onMouseLeave={() => setShowDeleteBtn(false)}>
          {tenant && tenant.logo ? (
            <>
              <label htmlFor='logo' className="cursor-pointer">
                <input
                  type="file"
                  multiple={false}
                  accept={'.png, .jpeg, .jpg, .svg'}
                  id="logo"
                  className="hidden"
                  onChange={(e) => setSelectedFile(e.target.files)}
                />
                <img
                  src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${tenant.id}/${tenant.logo}`}
                  // onError={e => e.currentTarget.src = DefaultPersonImg}
                  alt="Logo"
                  className="w-20 md:w-24 h-20 md:h-24 object-contain rounded-full" />
              </label>
            </>
          ) : (
            <div className="flex items-center justify-center w-20 md:w-28 h-20 md:h-28">
              <span
                className="material-symbols-outlined"
                style={{ fontSize: '2.5rem' }}>
                work
              </span>
            </div>
          )}

          {!tenant?.logo && (
            <label htmlFor='logo' className="cursor-pointer">
              <input
                type="file"
                multiple={false}
                accept={'.png, .jpeg, .jpg, .svg'}
                id="logo"
                className="hidden"
                onChange={(e) => setSelectedFile(e.target.files)}
              />
              <span className="material-symbols-outlined absolute mt-5 md:mt-7 ml-[-15px] md:ml-[-20px] p-0.5 rounded-full cursor-pointer text-primary-blue bg-secondary-blue hover:bg-secondary-blue-h self-center"
                style={{ fontSize: '1.1rem' }}>
                add
              </span>
            </label>
          )}

          {(showDeleteBtn && tenant?.logo) && (
            <span
              className="material-symbols-outlined absolute cursor-pointer bg-white rounded-full p-1.5 text-status-critical hover:text-status-critical-h ml-14 md:ml-20 mb-14 md:mb-20"
              onClick={() => handleDeleteImg(tenant?.logo)}
              style={{ fontSize: '1.4rem' }}
            >delete</span>
          )}
        </div>
      )}

      {(currentUser.role === Roles.None || currentUser.role === Roles.User) && (
        <div className="relative w-20 md:w-28 h-20 md:h-28 rounded-full flex mx-auto border">
          {tenant && tenant.logo ? (
            <>
              <label htmlFor='logo' className="cursor-pointer">
                <input
                  type="file"
                  multiple={false}
                  accept={'.png, .jpeg, .jpg, .svg'}
                  id="logo"
                  className="hidden"
                />
                <img
                  src={`${process.env.REACT_APP_PUBLIC_BUCKET}/${tenant.id}/${tenant.logo}`}
                  alt="Logo"
                  className="object-cover rounded-full" />
              </label>
            </>
          ) : (
            <>
              <span
                className={`${showDeleteBtn && 'text-gray-200'} material-symbols-outlined text-gray-600 mx-auto mt-4 md:mt-7`}
                style={{ fontSize: '3rem' }}
              >work</span>
            </>
          )}
        </div>
      )}

      {(selectedFile && !response.isLoading) && (
        <>
          <p className="text-xs mt-1">({selectedFile[0].name})</p>
          <button
            onClick={() => submitFile(selectedFile)}
            className="rounded-md bg-primary-blue hover:bg-primary-blue-h px-2 mt-1 text-xs font-bold leading-6 text-secondary-blue shadow-sm"
          >{t('common:upload')}</button>
        </>
      )}

      {response.isLoading && (
        <div className="my-3 text-center">
          <span className="text-xs font-semibold text-black">{t('common:form.fileBeingUploaded')}</span>
          <span className="material-symbols-outlined animate-bounce text-primary-blue">cloud_upload</span>
        </div>
      )}

      {/* <ConfirmModal isOpen={showDeleteModal} close={() => setShowDeleteModal(false)} title='Confirm delete' size='xs' titleAlign='center' type='info' titleSize="xs">
        <p className="text-sm font-semibold">Are you sure you want to delete the image?</p>
        <button
          // onClick={deletePropertyConfirmed}
          className="bg-status-critical hover:bg-status-critical-h py-1 px-1 text-xs sm:px-6 mt-3 rounded mx-auto text-white font-semibold">Delete</button>
      </ConfirmModal> */}
    </div>
  )
}

export default CompanyFileUpload;