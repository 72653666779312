import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import 'material-symbols';
import { useLoginMutation, useVerifyOtpMutation } from '../../../../services/catalog/auth/auth.service';
import LoginForm from '../../../organisms/forms/auth/login-form';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { Link, useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { setAuthenticatedUser } from '../../../../redux/slices/auth.slice';
import Modal from '../../../molecules/modal';
import OTPVerificationForm, { OtpVerificationFormType } from '../../../organisms/forms/auth/otp-verification-form';
import Loader from '../../../atoms/loader/loader';
import { useTranslation } from 'react-i18next';

const LoginPage = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [verifyOtp, { data: verifyOtpData, error: verifyOtpError, isLoading: verifyOtpIsLoading }] = useVerifyOtpMutation();

    const [showOtpModal, setShowOtpModal] = useState<boolean>(false); // State for showing OTP verification modal
    const [otp, setOtp] = useState(''); // State for OTP Code
    const onChange = (value: string) => setOtp(value); // Handle OTP Input
    const [error, setError] = useState<string>('');
    // Email used we need to use 2FA for login
    const [email, setEmail] = useState<string>('');
    const [forgotPassword, setForgotPassword] = useState<boolean>(false);

    const handleSubmitOtpCode = async () => {
        setError('');

        if (otp.length === 6 && email) {
            try {
                const response = await verifyOtp({ otp, email }).unwrap();
                if (response.success) {
                    // const { success, ...authState } = response; // Create authState obj by omitting success property from response obj
                    const authStateObj = {
                        authUser: {
                            name: response.user.name,
                            email: response.user.email,
                            role: response.user.role,
                            user_id: response.user.user_id,
                        },
                        access_token: response.access_token
                    };

                    localStorage.setItem('user', authStateObj.authUser.name);
                    dispatch(setAuthenticatedUser(authStateObj));
                    navigate('/home');
                    // MAKE SURE THIS WORKS ON SUCCESSFUL AUTH
                }
                else {
                    // FIX: Handle
                }
            }
            catch (error: any) {
                setError(t(`common:server.${error.data.message}`));
            }
        }
    }

    const handleForgotPassword = () => {
        console.log('handleForgotPassword');

    }

    return (
        <>
            {/* {loginIsLoading ? (
                <Loader show={loginIsLoading} size='large' top='xlarge' />
            ) : ( */}
                {/* <> */}
                    <div className="flex mt-[15vh] items-center">
                        {/* <button onClick={() => setShowOtpModal(true)}>Show Modal</button> */}
                        <div className="mt-5 rounded-md w-full md:w-6/12 md:max-w-xl mx-auto">
                            <div className="flex mx-auto flex-1 flex-col justify-center">
                                <div className="flex flex-col justify-center items-center gap-x-1 ">
                                    <img src={require('../../../../assets/logo/logo-pink.png')} width="60" className="" />
                                    <h2 className="text-center text-xl sm:text-2xl font-bold text-black">Loggello</h2>
                                </div>
                                <LoginForm
                                    setForgotPassword={setForgotPassword}
                                    setEmail={setEmail}
                                    setShowOtpModal={setShowOtpModal}
                                />
                            </div>
                            <div className="mx-auto mt-4 flex items-center justify-center">
                                <span className="text-xs text-black">{t('page:login.noAccount')}
                                    <Link to="/register">
                                        <span className="text-xs text-primary-blue hover:text-primary-blue-h font-semibold"> {t('page:login.createAccount')}</span>
                                    </Link>
                                </span>
                            </div>
                        </div>
                    </div>
                    <Modal isOpen={showOtpModal} close={() => setShowOtpModal(!showOtpModal)} title='Verification' size='sm' type='auth' titleAlign='center'>
                        <div>
                            <p className="my-2 font-bold">A verification code has been sent to your phone, please enter the code below to proceed.</p>
                            <p className="text-sm my-2 font-semibold">This verification is needed since you last verified your account 30 days ago OR if you haven't verified your phone yet.</p>
                            <div className="flex flex-wrap justify-center">
                                <OTPVerificationForm value={otp} onChange={onChange} />
                                {error && (<p className="text-lg mt-5 font-bold text-status-critical text-cennter">{error}, try again</p>)}
                                <div className="w-full text-center">
                                    <button
                                        onClick={handleSubmitOtpCode}
                                        disabled={otp.length !== 6}
                                        className="px-4 py-2 mt-8 align-center mb-2 rounded-md bg-status-ok hover:bg-status-ok text-white text-lg font-bold disabled:bg-gray-400"
                                    >Verify
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                {/* </>
            )} */}

        </>
    )
}

export default LoginPage;