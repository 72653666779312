import React, { useEffect, useState } from 'react'
import UserTableEdit from '../../../user/user-table-edit';
import { Grow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropertyTableEdit from '../../../property/property-table-edit';
import { SubsidiaryDto } from '../../../../../services/tenant/subsidiary/dto/subsidiary.dto';
import Checkbox from '../../../../atoms/checkbox';

type Props = {
    selectedSubsidiaries: SubsidiaryDto[];
};

const SubsidiaryTables = ({
    selectedSubsidiaries,
}: Props) => {
    const { t } = useTranslation();

    // Entities that can belong to a subsidiary
    const availableSelects = ['users', 'properties'];
    const [selectedEntities, setSelectedEntities] = useState<string[]>(availableSelects);

    const handleSelectedEntity = (entity: string) => {
        const allSelectedEntities = [...selectedEntities];

        const index = allSelectedEntities.findIndex(e => e === entity);
        if (index !== -1) {
            allSelectedEntities.splice(index, 1);
        }
        else {
            allSelectedEntities.push(entity);
        }
        setSelectedEntities(allSelectedEntities);
    }

    return (
        <div className="p-2 text-black rounded-lg">
            <div className="flex flex-wrap justify-center gap-x-5 mt-5">
                {availableSelects.map((entity, idx) => {
                    return (
                        <div key={idx} className="flex items-center">
                            <Checkbox
                                label={t(`common:${entity}`)}
                                size={'md'}
                                checked={selectedEntities?.find(e => e === entity) ? true : false}
                                onChange={() => handleSelectedEntity(entity)}
                            />
                        </div>
                    )
                })}
            </div>

            {selectedEntities.length > 0 ? (
                <>
                    {selectedEntities.includes('users') && (
                        <Grow in={selectedEntities.includes('users') ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                            <div>
                            <hr className="w-6/12 mx-auto my-5" />
                                <UserTableEdit subsidiaries={selectedSubsidiaries} />
                            </div>
                        </Grow>
                    )}

                    {selectedEntities.includes('properties') && (
                        <Grow in={selectedEntities.includes('properties') ? true : false} timeout={400} style={{ transformOrigin: '0 0 0' }}>
                            <div className="mt-5">
                                <PropertyTableEdit subsidiaries={selectedSubsidiaries} />
                            </div>
                        </Grow>
                    )}
                </>
            ) : (
                <p className="font-semibold text-sm text-center my-3">{t('page:rights:noEntitiesSelected')}</p>
            )}
        </div>
    )
}

export default SubsidiaryTables