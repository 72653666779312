import React, { useEffect, useState } from 'react'
import AllLogTemplatesTable from '../../organisms/log-components/log-structure/all-log-templates-table';
import { LogTemplateDto } from '../../../services/tenant/log-template/dto/log-template.dto';
import { useTranslation } from 'react-i18next';

type Props = {
    selectedLogTemplate?: LogTemplateDto;
    setSelectedLogTemplate: (logTemplate: LogTemplateDto) => void;
    setShowEditLogTemplate: (logTemplate: LogTemplateDto) => void;
};

const LogTemplates = ({
    selectedLogTemplate,
    setSelectedLogTemplate,
    setShowEditLogTemplate,
}: Props) => {

    return (
        <div>
            <AllLogTemplatesTable
                setSelectedLogTemplate={setSelectedLogTemplate}
                selectedLogTemplate={selectedLogTemplate}
                setShowEditLogTemplate={setShowEditLogTemplate}
            />
        </div>
    )
}

export default LogTemplates;