
import React, { useCallback, useEffect, useState } from 'react'
import { FriendshipTenantDto } from '../../../../../services/catalog/friendship/dto/friendship-tenant.dto';
import ExternalLogAccessPropertyOwnerTable from './external-log-access-property-owner-table';
import { useAppSelector } from '../../../../../redux/hooks';
import { getTenant } from '../../../../../redux/slices/tenant.slice';
import { getCurrentUser } from '../../../../../redux/slices/user.slice';
import ExternalCompaniesPropertyOwnerSelectMulti from '../external-companies-property-owner-select-multi';
import { useTranslation } from 'react-i18next';

type SelectType = {
    label: string;
    value: string;
    data: FriendshipTenantDto;
}

type Props = {
    preSelectedCompany?: FriendshipTenantDto;
};

const ExternalLogAccessPropertyOwner = ({
    preSelectedCompany
}: Props) => {
    const { t } = useTranslation();
    const { currentUser } = useAppSelector(getCurrentUser);
    const { tenant } = useAppSelector(getTenant);

    const [selectedCompanies, setSelectedCompanies] = useState<FriendshipTenantDto[]>([]);

    const handleSelectedCompanies = useCallback((selectedCompanies: SelectType[]) => {
        if (selectedCompanies.length > 0) {
            const selectedCompaniesData = selectedCompanies.map(e => e.data);
            setSelectedCompanies(selectedCompaniesData);
        }
        else {
            setSelectedCompanies([]);
        }
    }, [setSelectedCompanies])

    return (
        <>
            <div className="w-6/12">
                <p className="text-sm p-1">{t('page:relationAccess.selectCompaniesLogAccess')}</p>
                <ExternalCompaniesPropertyOwnerSelectMulti handleSelectedCompanies={handleSelectedCompanies} preSelectedCompany={preSelectedCompany} />
            </div>

            {selectedCompanies && selectedCompanies.length > 0 && (
                <ExternalLogAccessPropertyOwnerTable companies={selectedCompanies} />
            )}
        </>
    )
}

export default ExternalLogAccessPropertyOwner