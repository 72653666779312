
import { Fragment, useEffect, useRef, useState } from "react";
import { NotificationManager } from 'react-notifications';

import { useTranslation } from "react-i18next";
import { Input } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FriendshipTenantDto } from "../../../../../../services/catalog/friendship/dto/friendship-tenant.dto";
import { useAppSelector } from "../../../../../../redux/hooks";
import { getTenant } from "../../../../../../redux/slices/tenant.slice";
import { getCurrentUser } from "../../../../../../redux/slices/user.slice";
import { debounce } from "../../../../../../utils/debounce";
import { Order } from "../../../../../../services/enum/order";
import Loader from "../../../../../atoms/loader/loader";
import { LogTypes } from "../../../../../../services/enum/log-types";
import { LogCategories } from "../../../../../../services/enum/log-categories";
import { TenantTypes } from "../../../../../../services/enum/tenant-types";
import AllLogCategories from "../../../../log-components/log-structure/all-log-categories";
import { useCreatePropertyOwnerContractorLogAccessMutation, useDeletePropertyOwnerContractorLogAccessMutation, useGetAllPropertyOwnerContractorLogAccessByIdsQuery } from "../../../../../../services/tenant/property-owner-contractor-log-access/property-owner-contractor-log-access.service";
import { CreatePropertyOwnerContractorLogAccessDto } from "../../../../../../services/tenant/property-owner-contractor-log-access/dto/create-property-owner-contractor-log-access.dto";
import { PropertyOwnerContractorLogAccessDto } from "../../../../../../services/tenant/property-owner-contractor-log-access/dto/property-owner-contractor-log-access.dto";
import LogFilterByEntities from "../../../../log-components/log-structure/log-filter-by-entities";
import Checkbox from "../../../../../atoms/checkbox";
import Pagination from "../../../../../molecules/pagination";
import Table from "../../../../../molecules/table";
import { useGetAllEntityLogsQuery, useGetAllPropertyBuildingLogAccessQuery, useGetAllPropertyLogAccessQuery, useGetAllPropertyObjectLogAccessQuery, useGetEntityLogsUniqueCategoriesQuery } from "../../../../../../services/tenant/entity-log/entity-log.service";
import { EntityLogDto } from "../../../../../../services/tenant/entity-log/dto/entity-log.dto";

type FilterType = {
    properties?: string;
    buildings?: string;
    objects?: string;
    category?: LogCategories;
}


type Props = {
    companies?: FriendshipTenantDto[];
}

const ExternalLogAccessPropertyOwnerTable = ({
    companies,
}: Props) => {
    const { t } = useTranslation();

    const { currentUser } = useAppSelector(getCurrentUser);
    const { tenant } = useAppSelector(getTenant);

    const [createPropertyOwnerContractorLogAccess] = useCreatePropertyOwnerContractorLogAccessMutation();
    const [deletePropertyOwnerContractorLogAccess] = useDeletePropertyOwnerContractorLogAccessMutation();

    // Pagination
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>();
    const [hasNextPage, setHasNextPage] = useState<boolean>();
    const [hasPrevPage, setHasPrevPage] = useState<boolean>();

    // Table sort and search
    const [search, setSearch] = useState<string | undefined>('');
    const [searchOperation, setSearchOperation] = useState<string | undefined>();
    const [sortBy, setSortBy] = useState<string | undefined>('name'); // Column to sort by
    const [sortOrder, setSortOrder] = useState<Order>(Order.ASC); // Sort order
    const [showSortMenu, setShowSortMenu] = useState<boolean>(false); // Sort menu on small screens

    const [filterObject, setFilterObject] = useState<FilterType>();
    const [filterString, setFilterString] = useState<string>();

    // Filter based on property, building or object
    const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
    const [selectedBuildings, setSelectedBuildings] = useState<string[]>([]);
    const [selectedObjects, setSelectedObjects] = useState<string[]>([]);

    // State for selected category
    const [selectedCategory, setSelectedCategory] = useState<LogCategories>();

    const debouncedSearch = useRef(debounce((search: string) => setSearchOperation(search), 500)).current;

    // Get entity logs paginated
    const { data: logs, isFetching } = useGetAllEntityLogsQuery({
        page: page,
        take: 25,
        search: searchOperation,
        sortBy: sortBy,
        order: sortOrder,
        filter: filterString
    });
    
    // Get all access records to display in filter lists
    const { data: propertyLogAccess } = useGetAllPropertyLogAccessQuery();
    const { data: propertyBuildingLogAccess } = useGetAllPropertyBuildingLogAccessQuery();
    const { data: propertyObjectLogAccess } = useGetAllPropertyObjectLogAccessQuery();

    // Get all unique log categories for propertyLogTemplates
    const { data: logCategories } = useGetEntityLogsUniqueCategoriesQuery();

    const [skipTokenContractorIds, setSkipTokenContractorIds] = useState<any>(skipToken);
    const { data: propertyOwnerContractorLogAccessByIds } = useGetAllPropertyOwnerContractorLogAccessByIdsQuery(skipTokenContractorIds);

    useEffect(() => {
        if (logs) {
            setPage(logs.meta.page);
            setPageCount(logs.meta.pageCount);
            setHasNextPage(logs.meta.hasNextPage);
            setHasPrevPage(logs.meta.hasPreviousPage);
        }
    }, [logs])

    useEffect(() => {
        if (companies) {
            setSkipTokenContractorIds(companies.map(e => e.tenant_id))
        }
    }, [companies])

    // Construct filterQuery
    useEffect(() => {
        let tempObj: FilterType = {};

        if (selectedProperties && selectedProperties.length > 0) {
            tempObj.properties = selectedProperties.map(e => e).join(',');
        }
        if (selectedBuildings && selectedBuildings.length > 0) {
            tempObj.buildings = selectedBuildings.map(e => e).join(',');
        }
        if (selectedObjects && selectedObjects.length > 0) {
            tempObj.objects = selectedObjects.map(e => e).join(',');
        }
        if (selectedCategory !== undefined && selectedCategory !== LogCategories.None) {
            tempObj.category = selectedCategory;
        }

        // Check if there's a change in the filter
        if (JSON.stringify(filterObject) !== JSON.stringify(tempObj)) {
            setFilterObject(tempObj);
            const encoded = encodeURIComponent(JSON.stringify(tempObj));
            setFilterString(encoded);
        }

    }, [selectedProperties, selectedBuildings, selectedObjects, selectedCategory])

    const handlePagination = (page: number) => {
        setPage(page);
    }

    // Handle sort by column and sort order
    const handleSort = (column: string, order: Order) => {
        setSortBy(column);
        setSortOrder(order === Order.ASC ? Order.DESC : Order.ASC)

    }

    const handleCreatePropertyOwnerContractorLogAccess = async (createPropertyOwnerContractorLogAccessDto: CreatePropertyOwnerContractorLogAccessDto) => {
        // Create propertyOwnerContractorLogAccessDto
        try {
            const response = await createPropertyOwnerContractorLogAccess(createPropertyOwnerContractorLogAccessDto).unwrap();
            if (response.success) {
                NotificationManager.success(t('common:notifications.accessUpdated'));
                // dispatch(userAPI.util.invalidateTags(['Users']));
            }
            else {
                NotificationManager.error(t('common:notifications.updateFailed'))
            }
        }
        catch (error) {
            NotificationManager.error(t('common:notifications.updateFailed'))
        }
    }

    const handleDeletePropertyOwnerContractorLogAccess = async (id: string) => {
        // Delete propertyOwnerContractorLogAccessDto
        try {
            const response = await deletePropertyOwnerContractorLogAccess(id).unwrap();
            if (response.success) {
                NotificationManager.success(t('common:notifications.accessRemoved'));
                // dispatch(userAPI.util.invalidateTags(['Users']));
            }
            else {
                NotificationManager.error(t('common:notifications.updateFailed'))
            }
        }
        catch (error) {
            NotificationManager.error(t('common:notifications.updateFailed'))
        }
    }

    const handleAccess = async (log: EntityLogDto, tenantIdContractor: string, existingRecord: PropertyOwnerContractorLogAccessDto | null) => {
        if (tenant.id) {
            // Remove access
            if (existingRecord) {
                // Delete record by id
                await handleDeletePropertyOwnerContractorLogAccess(existingRecord.id);
            }
            // Give access
            else {
                // Create new record
                const createPropertyOwnerContractorLogAccessDto: CreatePropertyOwnerContractorLogAccessDto = {
                    tenant_id_property_owner: tenant.id,
                    tenant_id_contractor: tenantIdContractor,
                    log_template_id: log.log_template_id,
                    property_id: log.property_id,
                    property_building_id: log.property_building_id,
                    property_object_id: log.property_object_id,
                }
                await handleCreatePropertyOwnerContractorLogAccess(createPropertyOwnerContractorLogAccessDto);
            }
        }
    }

    const checkAccess = (log: EntityLogDto, company: FriendshipTenantDto) => {
        if (propertyOwnerContractorLogAccessByIds) {
            const matchedRecord = propertyOwnerContractorLogAccessByIds
                .find(e => {
                    return (e.tenant_id_contractor === company.sender_tenant_id || e.tenant_id_contractor === company.receiver_tenant_id) &&
                        e.log_template_id === log.log_template_id &&
                        e.property_id === log.property_id &&
                        e.property_building_id === log.property_building_id &&
                        e.property_object_id === log.property_object_id;
                })

            if (!matchedRecord) {
                return null
            }
            return matchedRecord
        }
        return null;
    }

    return (
        <div className="w-full mx-auto">
            <div className="relative">
                {/* <div className="flex items-center">
                    <h5 className="text-base font-semibold ml-2 w-5/12">
                        {
                            tenant.type === TenantTypes.Property_Owner ?
                                t('page:friendship.contractors') :
                                t('page:friendship.propertyOwners')
                        }
                    </h5>
                </div> */}
                <div className="sm:w-6/12 max-w-2xl mt-2 text-center sm:text-left">
                    <Input
                        type="search"
                        name="search_name"
                        placeholder={t('common:search')}
                        disableUnderline={true}
                        value={search}
                        className="w-full border rounded-md text-base md:text-sm px-2 pt-2.5 pb-1 mt-3 bg-white"
                        onChange={(e) => {
                            setSearch(e.target.value)
                            debouncedSearch(e.target.value)
                        }}
                        endAdornment={
                            <span
                                className="material-symbols-outlined pb-1 text-gray-500 cursor-pointer"
                                style={{ fontSize: '1.3rem' }}
                                onClick={() => {
                                    if (search) {
                                        setSearch('')
                                        debouncedSearch('')
                                    }
                                }}
                            >
                                {search ? 'close' : 'search'}
                            </span>
                        }
                        size="small"
                    />
                </div>
                <div className="w-full">
                    <AllLogCategories logCategories={logCategories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} alignCenter={true} />
                </div>
                <div className="w-full mt-1 mb-2">
                    <LogFilterByEntities
                        allPropertyLabels={propertyLogAccess ? propertyLogAccess.filter(e => e.property_label !== undefined).map(e => e.property_label as string) : []}
                        allBuildingLabels={propertyBuildingLogAccess ? propertyBuildingLogAccess.filter(e => e.property_building_label !== undefined).map(e => e.property_building_label as string) : []}
                        allObjectNames={propertyObjectLogAccess ? propertyObjectLogAccess.filter(e => e.property_object_name !== undefined).map(e => e.property_object_name as string) : []}
                        setSelectedProperties={setSelectedProperties}
                        selectedProperties={selectedProperties}
                        setSelectedBuildings={setSelectedBuildings}
                        selectedBuildings={selectedBuildings}
                        setSelectedObjects={setSelectedObjects}
                        selectedObjects={selectedObjects}
                    />
                </div>

                <div className="relative overflow-x-scroll max-h-[50vh]">
                    {logs?.data && logs.data.length > 0 && !isFetching && (
                        <Table>
                            <Table.Thead>
                                <tr className="text-xs">
                                    <th scope="col" className="sticky top-0 bg-white z-10">
                                        <span className="sr-only">Icon</span>
                                    </th>
                                    <th
                                        scope="col"
                                        className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[8rem] max-w-[8rem] sticky top-0 bg-white z-10"
                                        onClick={() => handleSort('name', sortOrder)}>
                                        {t('common:name')}
                                        {(sortBy === 'name') ? (
                                            <>
                                                {sortOrder === Order.ASC ? (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                ) : (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                            </>
                                        )}
                                    </th>
                                    <th
                                        scope="col"
                                        className="py-1 md:py-2 pl-1 sm:pl-2 cursor-pointer font-bold min-w-[8rem] max-w-[8rem] sticky top-0 bg-white z-10"
                                        onClick={() => handleSort('belongs_to', sortOrder)}
                                    >
                                        {t('common:belongsTo')}
                                        {(sortBy === 'belongs_to') ? (
                                            <>
                                                {sortOrder === Order.ASC ? (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_up</span>
                                                ) : (
                                                    <span className="material-symbols-outlined align-middle text-status-ok">arrow_drop_down</span>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <span className="material-symbols-outlined align-middle">arrow_drop_down</span>
                                            </>
                                        )}
                                    </th>
                                    {companies && [...companies]
                                        .sort((a, b) => a.company_name.localeCompare(b.company_name))
                                        .map(company => {
                                            return (
                                                <th
                                                    key={company.id}
                                                    scope="col"
                                                    className="py-1 pl-2 md:py-2 pr-2 sm:table-cell cursor-pointer font-bold min-w-[8rem] max-w-[8rem] sticky top-0 bg-white z-10">
                                                    {company.company_name}
                                                </th>
                                            )
                                        })}
                                </tr>
                            </Table.Thead>
                            <Table.Tbody>
                                {logs?.data?.map((log, idx) => {
                                    return (
                                        <tr key={idx} className={`odd:hover:bg-primary-blue-10 even:hover:bg-gray-50 cursor-pointer text-xs text-black even:bg-white odd:bg-primary-blue-5`}>
                                            <td scope="row" className="px-1 py-2 sm:px-6 capitalize text-left">
                                                {log.log_category === LogCategories.Other && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>home_repair_service</span>)}
                                                {log.log_category === LogCategories.Fire && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>local_fire_department</span>)}
                                                {log.log_category === LogCategories.Security && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>encrypted</span>)}
                                                {log.log_category === LogCategories.Camera && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>photo_camera</span>)}
                                                {log.log_category === LogCategories.Access && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>vpn_key</span>)}
                                                {log.log_category === LogCategories.Maintenance && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>construction</span>)}
                                                {log.log_category === LogCategories.Cleaning && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>mop</span>)}
                                                {log.log_category === LogCategories.Rounding && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>shield_person</span>)}
                                                {log.log_category === LogCategories.Elevator && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>elevator</span>)}
                                                {log.log_category === LogCategories.Ventilation && (<span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }} title={t(`common:enums.logCategories.${LogCategories[log.log_category]}`)}>mode_fan</span>)}
                                            </td>
                                            <td
                                                className="py-2 pl-1 sm:pl-2 text-xs capitalize font-semibold max-w-[5rem] min-w-[5rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                title={log?.log_template_name}>
                                                {log.log_template_name ? log.log_type === LogTypes.Default ? t(`page:logs.defaultLogs.${log.log_template_name.split('_').join('')}`) : log.log_template_name.split('_').join(' ') : '-'}
                                            </td>
                                            <td
                                                className="py-2 pl-1 sm:pl-2 text-xs capitalize font-semibold max-w-[5rem] min-w-[5rem] overflow-hidden whitespace-nowrap text-ellipsis"
                                                title={log.property_object_id ? log.property_object_name : log.property_building_id ? log.property_building_label : log.property_id ? log.property_label : '-'}>
                                                {log.property_object_id ? log.property_object_name : log.property_building_id ? log.property_building_label : log.property_id ? log.property_label : '-'}
                                            </td>

                                            {companies && propertyOwnerContractorLogAccessByIds && [...companies]
                                                .sort((a, b) => a.company_name.localeCompare(b.company_name))
                                                .map(company => {
                                                    const isChecked = checkAccess(log, company);
                                                    return (
                                                        <td
                                                            key={company.id}
                                                            className="pl-4 text-xs capitalize font-semibold sm:font-normal max-w-[3rem] min-w-[3rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                                            <Checkbox
                                                                size={'sm'}
                                                                checked={isChecked ? true : false}
                                                                onChange={() => handleAccess(log, company.tenant_id, isChecked)}
                                                            />
                                                        </td>
                                                    )
                                                })}
                                        </tr>
                                    )
                                })}
                            </Table.Tbody>
                        </Table>
                    )}
                    {isFetching && (
                        <div className="mx-auto mb-5 mt-1">
                            <Loader show={isFetching} size='small' />
                        </div>
                    )}
                    {!logs || logs.data.length < 1 && (
                        <div className="mt-5">
                            <p className="text-sm ml-8 md:ml-5 font-semibold text-black">{t('page:friendship.noCompaniesFound')}...</p>
                        </div>
                    )}
                </div>
                {logs && logs.data && (
                    <div className="flex justify-center py-2">
                        <Pagination
                            count={pageCount}
                            page={page}
                            defaultPage={1}
                            showFirstButton={(page !== 1 && pageCount && pageCount > 5) ? true : false}
                            showLastButton={pageCount ? pageCount > 5 : false}
                            hidePrevButton={!hasPrevPage}
                            hideNextButton={!hasNextPage}
                            onChange={handlePagination}
                        />
                    </div>
                )}

            </div>
        </div>
    )
}

export default ExternalLogAccessPropertyOwnerTable;